import {
	Cloud,
	createCloudHostnameWithWww,
	createCloudUrlWithWww,
	createCloudStudioUrl,
} from './http-client';

export enum Env {
	Test = 'test',
	Staging = 'staging',
	Alpha = 'alpha',
	Beta = 'beta',
	Delta = 'delta',
	Gamma = 'gamma',
	Render = 'render',
	Animations = 'animations',
	WebXR = 'webxr',
	Preprod = 'preprod',
	ComW = 'comw',
	Prod = 'www',
	PrfmTest = 'prfm-test',
	Manuel = 'manuel',
}

// TODO add getEnvByHostname

export const LOCALHOST_ENV = 'localhost';

/** Environments that use production database */
export const PROD_LIKE_ENVS: Env[] = [
	Env.Prod,
	Env.Beta,
	Env.Delta,
	Env.Preprod,
	Env.Render,
	Env.Alpha,
	Env.Gamma,
	Env.Manuel,
	Env.WebXR,
];

/** Environments that have cookie consent app (OneTrust) */
export const COOKIE_CONSENT_ENVS = [
	Env.Prod,
	Env.Alpha,
	Env.Beta,
	Env.Gamma,
	Env.Preprod,
	Env.Delta,
	Env.Manuel,
];

/**
 * Tells if the environment should use cookie consent app (OneTrust)
 *
 * @returns {boolean}
 */
export function isCookiesConsentEnv(): boolean {
	const cookieConsentUrls = [
		...COOKIE_CONSENT_ENVS.map(env => createCloudUrlWithWww(env)),
		...COOKIE_CONSENT_ENVS.map(env => createCloudStudioUrl(env)),
	];
	return cookieConsentUrls.includes(`https://${document.location.hostname}`);
}

/**
 * Tells if the current browser's address is considered a sandbox environment.
 *
 * @returns {boolean}
 */
export const isSandboxEnvByHostname = (): boolean => {
	const isSandboxEnvByHostname = !(PROD_LIKE_ENVS as Cloud[]).some(env =>
		document.location.href.includes(createCloudUrlWithWww(env)),
	);

	return isSandboxEnvByHostname;
};

export const isProductionSite = (): boolean => {
	// TODO change to Env enum
	return ['www.vectary.com', 'app.vectary.com', 'admin.vectary.com'].includes(
		window.location.hostname,
	);
};

export const isEnvByHostname = (env: Env): boolean => {
	return (
		typeof window !== 'undefined' &&
		window.location.hostname.indexOf(createCloudHostnameWithWww(env)) > -1
	);
};

/**
 * Returns current environment based on current location hostname.
 */
export const getCurrentEnvironment = (): string | undefined => {
	const currentEnv = Object.values(Env).find(env => isEnvByHostname(env));

	if (
		!currentEnv &&
		typeof window !== 'undefined' &&
		window.location.hostname.indexOf('app.vectary.com') > -1
	) {
		return Env.Prod;
	}

	if (
		!currentEnv &&
		typeof window !== 'undefined' &&
		window.location.hostname.includes(LOCALHOST_ENV)
	) {
		return LOCALHOST_ENV;
	}

	return currentEnv;
};
