import styled from 'styled-components';
import { DashboardUkoThemeVariables } from '../../ui/themes/dashboardUkoTheme';

const $containerPadding: number = DashboardUkoThemeVariables.gridGap;
export const $workspaceSettingsMaxWidth: number = 680 + $containerPadding * 2;

export const WorkspaceSettings__Container = styled.div`
	display: block;
	box-sizing: border-box;
	// width: 100%;
	width: ${$workspaceSettingsMaxWidth}px;
	margin: auto;
	padding-left: ${$containerPadding}px;
	padding-right: ${$containerPadding}px;
`;
