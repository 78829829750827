import React from 'react';
import { TextField } from '@mui/material';

interface VeTextProps {
	type?: React.InputHTMLAttributes<unknown>['type'];
	name?: string;
	label: string;
	required?: boolean;
	error?: boolean;
	helperText?: string;
	disabled?: boolean;
	readonly?: boolean;
	value?: string;
	hidden?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	multiline?: boolean;
	maxRows?: number;
	minRows?: number;
}

export function VeText({
	type = 'text',
	name,
	label,
	error,
	helperText,
	value,
	disabled,
	readonly,
	required = true,
	hidden,
	multiline,
	maxRows = 4,
	minRows = 4,
	onChange,
}: VeTextProps) {
	return (
		<TextField
			InputProps={{ disableUnderline: true, readOnly: readonly }}
			type={type}
			size="small"
			name={name}
			label={label}
			variant="filled"
			margin="normal"
			fullWidth
			required={required}
			error={error}
			helperText={helperText}
			value={value}
			disabled={disabled}
			sx={hidden ? { display: 'none' } : {}}
			onChange={onChange}
			multiline={multiline}
			maxRows={maxRows}
			minRows={minRows}
		/>
	);
}
