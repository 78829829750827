import React, {
	FC,
	useEffect,
	useState,
	ChangeEvent,
	FocusEvent,
	KeyboardEvent,
	useRef,
} from 'react';
import styled from 'styled-components';

import { _Input as InputC, InputEnumType } from '../_common';

const MAX_COUNT_TAGS = 15;
const MAX_INPUT_CHARACTER = 15;

const Wrapper = styled.ul`
	display: flex;
	flex-wrap: wrap;

	li {
		background-color: ${({ theme }) => theme.colors.c_off_white};
		margin: 0 8px 8px 0;
		border: 1px solid transparent;
		overflow: hidden;
		border-radius: ${({ theme }) => theme.borderRadius[1]};
		position: relative;
		height: 24px;
		display: inline-flex;

		&:hover {
			border: 1px solid ${({ theme }) => theme.colors.c_soft_grey};
		}
	}
`;

const AddTag = styled.li`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
`;

const Tag = styled.li`
	color: transparent;
	align-items: center;
	padding: 4px 28px 4px 8px;
`;

const Title = styled.span`
	font-size: ${({ theme }) => theme.fontSize.font_size_small};
	font-family: ${({ theme }) => theme.fontFamily};
	font-weight: normal;
	color: transparent;
	padding-left: 1px;
	letter-spacing: -0.3px;
`;

const Input = styled(InputC)`
	position: absolute;
	display: inline-block;
	color: ${({ theme }) => theme.colors.c_almost_black};
	width: calc(100% - 24px);
	font-size: inherit;
	border: 0;
	height: 100%;
	left: 0;
	top: 0;
	padding: 4px 4px 4px 8px;

	&:not([disabled]):hover,
	&:not([disabled]):focus {
		color: ${({ theme }) => theme.colors.c_almost_black};
		box-shadow: none;
		border: 0;
	}
`;

const ButtonRemoveTag = styled.button`
	position: absolute;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.c_almost_black};
	background-color: inherit;
	border: 0;
	width: 24px;
	height: 24px;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
`;

const IconRemoveTag = styled.img`
	position: absolute;
	color: ${({ theme }) => theme.colors.c_almost_black};
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const PlusIcon = styled.img`
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
`;

export type TagsProps = {
	assetsPath?: string;
	handleBlur: (value: string[]) => void;
	keywords: string[];
	onChange?: (value: string[]) => void;
	readOnly?: boolean;
	refreshValue?: boolean;
};

export const Tags: FC<TagsProps> = ({
	assetsPath = '.',
	keywords,
	handleBlur,
	readOnly = false,
	onChange,
	refreshValue = false,
}) => {
	const [arrayTags, setArrayTags] = useState<string[]>([]);
	const [addRef, setAddRef] = useState(false);
	const ref = useRef<HTMLInputElement>(null);

	// load tags from props
	// TODO: Why not to set is as default value in useState statement (??)
	useEffect(() => {
		setArrayTags(keywords);
		return () => {
			setArrayTags([]);
		};
	}, []);

	useEffect(() => {
		if (!refreshValue) {
			return;
		}

		setArrayTags(keywords);
	}, [keywords]);

	// handling focus on the last input after add new tag
	useEffect(() => {
		addRef && ref.current?.focus();
		return () => {
			setAddRef(false);
		};
	}, [addRef]);

	const handleAddTag = (): void => {
		const newArrayTags = [...arrayTags, ''];
		setArrayTags(newArrayTags);
		setAddRef(true);
	};

	const handleRemoveTag = (position: number): void => {
		const newArrayTags = arrayTags?.filter((_, index) => index !== position);
		handleBlur(newArrayTags);
		setArrayTags(newArrayTags);
	};

	const handleInputChange = (
		e: ChangeEvent<HTMLInputElement>,
		position: number,
	): void => {
		const value = (e.target as HTMLInputElement).value;
		const newArrayTags = [
			...arrayTags.map((item, index) => {
				if (index === position) {
					return value.replace(/ {2}/g, ' ');
				}
				return item;
			}),
		];
		setArrayTags(newArrayTags);

		if (onChange) {
			onChange(newArrayTags);
		}
	};

	const handleKeyUp = (
		event:
			| KeyboardEvent<HTMLInputElement>
			| { key: string; target: { blur: () => void } },
	): void => {
		if (readOnly) {
			return;
		}

		// handling keyboard enter
		if (event.key === 'Enter') {
			(event.target as HTMLElement).blur();

			handleAddTag();
		}
	};

	const handleKeyDown = async (
		event: KeyboardEvent<HTMLInputElement>,
		index: number,
	) => {
		if (readOnly) {
			return;
		}

		const isCtrlPressed = event.ctrlKey || event.metaKey;

		// Handle Ctrl/Cmd+V
		if (event.key === 'v' && isCtrlPressed) {
			const pastedText = await navigator.clipboard.readText();
			const pastedTags = pastedText
				.split(/\r?\n/)
				.map(tag => tag.replace(/[\r\n]/g, ''));
			const newArrayTags = [...arrayTags];

			newArrayTags.splice(index, 0, ...pastedTags);
			const newArrayTagsFiltered = newArrayTags.filter(item => item); // Removes falsy values, like ''.

			setArrayTags(newArrayTagsFiltered);

			if (onChange) {
				onChange(newArrayTagsFiltered);
			}

			event.preventDefault();
			event.stopPropagation();
		}
	};

	const handleInputBlur = (e: FocusEvent, position: number): void => {
		if (readOnly) {
			return;
		}

		// remove last input reference
		const value = (e.target as HTMLInputElement).value;
		// handling empty value
		if (value.length === 0) {
			const newArrayTags = arrayTags.filter(item => item !== '');
			setArrayTags(newArrayTags);
		}
		// handling repeats value
		if (arrayTags.filter(item => item === value).length > 1) {
			const newArrayTags = arrayTags.filter((_, index) => index !== position);
			setArrayTags(newArrayTags);
		}
		!addRef && !arrayTags.includes('') && handleBlur(arrayTags);
		setAddRef(false);
	};

	return (
		<Wrapper>
			{!readOnly && (
				<AddTag
					onClick={handleAddTag}
					style={{
						display: arrayTags.length === MAX_COUNT_TAGS ? 'none' : 'flex',
					}}
				>
					<PlusIcon src={`${assetsPath}/images/icon-plus.svg`} />
				</AddTag>
			)}
			{arrayTags?.map((title, index) => (
				<Tag key={index}>
					<Title>
						{title}
						<Input
							value={title}
							ref={ref}
							type={InputEnumType.TEXT}
							maxLength={MAX_INPUT_CHARACTER}
							onKeyUp={handleKeyUp}
							onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
								handleKeyDown(event, index)
							}
							onChange={(event: ChangeEvent<HTMLInputElement>) =>
								handleInputChange(event, index)
							}
							onBlur={(event: FocusEvent) => {
								handleInputBlur(event, index);
							}}
							readOnly={readOnly}
						/>
					</Title>
					{!readOnly && (
						<ButtonRemoveTag onClick={() => handleRemoveTag(index)}>
							<IconRemoveTag src={`${assetsPath}/images/icon-cross.svg`} />
						</ButtonRemoveTag>
					)}
				</Tag>
			))}
		</Wrapper>
	);
};
