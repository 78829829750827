import { EventLogger } from '@vctr-libs/vctr-analytics';
import { getCookie, isProductionSite } from '@vctr-libs/vctr-utils';
import { UserBase } from '@vctr-libs/vctr-api-client';
import {
	isTestEventsEnabled,
	logWebsiteEvent,
	setTestEventTracking,
} from './helpers';
import {
	clearbitRevealCompany,
	trackCustomerIoEvent,
} from '@vctr-libs/vctr-integrations';
import { honeypotSpamCheck } from '../businessContactForm';

declare global {
	interface Window {
		dataLayer: Record<string, any>[];
	}
}

export enum Ga4Events {
	CtaClickPricing = 'cta_click_pricing',
	CtaClickPro = 'cta_click_pro',
	Header = 'header',
	Footer = 'footer',
	BusinessContactSend = 'business_contact_send',
	ScheduleACallClicked = 'schedule_a_call_clicked',
	ScheduleACallBooked = 'schedule_a_call_booked',
	FlowhuntBotButtonClick = 'flowhunt_bot_button_click',
	FlowhuntBotUserSentMessage = 'flowhunt_bot_user_sent_message',
}

enum DataEvents {
	abm_automotive_slide_to_form = 'abm_automotive_slide_to_form',
	abm_automotive_open_studio = 'abm_automotive_open_studio',
}

export function handleGADataLayerEvents(
	eventLogger: EventLogger,
	user: UserBase,
) {
	setTestEventTracking();
	handleDataEventComponents(eventLogger);
	handlePricingComponents();
	handleNavbarComponent();
	handleFooterComponent();
	handleSpecificProButtons();
	handleBusinessContactComponent(eventLogger, user);
	handleHubspotMeetingFormSubmitted(eventLogger);
}

export function pushGTMEvent(data: Record<string, any>) {
	if (!isProductionSite() && !isTestEventsEnabled()) return;

	window.dataLayer.push(data);
}

function handleDataEventComponents(eventLogger: EventLogger) {
	const dataLayerElements = document.querySelectorAll(`[data-event]`);
	Array.from(dataLayerElements).forEach((element: HTMLElement) => {
		element.addEventListener('click', () => {
			const data = element.dataset;
			pushGTMEvent(data);

			if (Object.values(DataEvents).includes(data.event as DataEvents))
				logWebsiteEvent([data.event as DataEvents, undefined], eventLogger);
		});
	});
}

function handlePricingComponents() {
	const pricingLinks = [
		...document.querySelectorAll(`[data-component=pricing] [data-tier]`),
		...document.querySelectorAll(`[data-component=pricing_table] [data-tier]`),
	] as HTMLElement[];

	pricingLinks.forEach(el => {
		el.addEventListener('click', () => {
			const data = {
				event: Ga4Events.CtaClickPricing,
				tier: el.dataset.tier,
				billing: el.dataset.billing,
			};
			pushGTMEvent(data);
		});
	});
}

function handleNavbarComponent() {
	const navbarLinks = [
		...document.querySelectorAll(`[data-component=navbar] a[href]`),
	] as HTMLElement[];

	navbarLinks.forEach((el: HTMLAnchorElement) => {
		el.addEventListener('click', () => {
			const data = {
				event: Ga4Events.Header,
				url: el.href,
			};
			pushGTMEvent(data);
		});
	});
}

function handleSpecificProButtons() {
	const proButtonLinks = [
		...document.querySelectorAll(
			`[data-page=discover_pro] [data-component=sections_title] a[href]`,
		),
		...document.querySelectorAll(
			`[data-page=interactions_and_animations] [data-component=sections_title] a[href]`,
		),
		...document.querySelectorAll(
			`[data-page=interactions_and_animations] [data-component=pro_button]`,
		),
	] as HTMLElement[];

	proButtonLinks.forEach((el: HTMLAnchorElement) => {
		el.addEventListener('click', () => {
			const data = {
				event: Ga4Events.CtaClickPro,
				button_name: el.innerText,
			};
			pushGTMEvent(data);
		});
	});
}

function handleFooterComponent() {
	const footerLinks = [
		...document.querySelectorAll(`[data-component=footer] a[href]`),
	] as HTMLElement[];

	footerLinks.forEach((el: HTMLAnchorElement) => {
		el.addEventListener('click', () => {
			const data = {
				event: Ga4Events.Footer,
				url: el.href,
			};
			pushGTMEvent(data);
		});
	});
}

function handleBusinessContactComponent(
	eventLogger: EventLogger,
	user: UserBase,
) {
	const form = document.querySelector(
		'[data-component=business_contact_form] form',
	);
	if (!form) return;

	form.addEventListener('submit', () => {
		if (honeypotSpamCheck()) {
			logWebsiteEvent(['submit_business_contact_spam', undefined], eventLogger);
			return;
		}

		const data = {
			event: Ga4Events.BusinessContactSend,
			previous_page: document.referrer,
		};
		pushGTMEvent(data);
		logWebsiteEvent(['submit_business_contact', undefined], eventLogger);
		clearbitRevealCompany(eventLogger, undefined, true);
		trackCustomerIoEvent('submit_business_contact');
	});

	const addHiddenField = (name: string, value: string) => {
		const input = document.createElement('input');
		input.type = 'text';
		input.name = name;
		input.value = value;
		input.setAttribute('class', 'wf-hidden');
		form.appendChild(input);
	};

	addHiddenField('gid', getCookie('_gid'));
	addHiddenField('sid', getCookie('vctr-sid'));
	addHiddenField('uuid', user?.uuid ?? '');

	const scheduleACallButton = document.querySelector(
		'[data-component=schedule_a_call]',
	);
	scheduleACallButton?.addEventListener('click', async ev => {
		ev.preventDefault();
		scheduleACallButton.setAttribute('disabled', 'true');
		pushGTMEvent({
			event: Ga4Events.ScheduleACallClicked,
		});

		try {
			await logWebsiteEvent(['schedule_a_call', undefined], eventLogger);
			const url = scheduleACallButton.getAttribute('href');
			document.location.href = url;
		} catch (error) {
			console.error('There was an error logging schedule_a_call event', error);
			scheduleACallButton.removeAttribute('disabled');
			throw new Error('There was an error logging schedule_a_call event');
		}
	});
}

export function handleHomepageReadyToDiveIn(eventLogger: EventLogger) {
	if (!window.location.pathname || window.location.pathname !== '/') {
		return;
	}

	const buttons = [
		...document.querySelectorAll(
			'[data-component=home_ready_to_dive_in] a.btn--primary',
		),
	];
	buttons.forEach((button: HTMLAnchorElement, i) => {
		button.addEventListener('click', () => {
			const type =
				i === 0
					? 'get_started'
					: i === 1
						? 'start_a_free_pro_trial'
						: 'book_a_demo';

			logWebsiteEvent(
				['home_ready_to_dive_in', { type: type, link: button.href }],
				eventLogger,
			);
		});
	});
}

export function handleHubspotMeetingFormSubmitted(eventLogger: EventLogger) {
	window.addEventListener('message', event => {
		const data = event?.data;
		if (data.meetingBookSucceeded === true) {
			const email =
				data.meetingsPayload?.bookingResponse?.postResponse?.contact?.email;
			logWebsiteEvent(['schedule_a_call_booked', { email }], eventLogger);
			clearbitRevealCompany(eventLogger, undefined, true);
			pushGTMEvent({
				event: Ga4Events.ScheduleACallBooked,
			});
		}
	});
}
