/**
 * GetPro component states
 *
 * SETUP and EDUCATION are introduced instead of Starter as of 2024-05-26,
 * but logic of upgrading to Pro is the same
 */
export const GetProComponentStates = {
	/** initial state for data loading, modal is hidden */
	LOADING: 'loading',
	/** modal is hidden per user action or state validation */
	HIDDEN: 'hidden',

	// --------------
	// Get Pro

	/** Non–educational
	 * users: get Trial for Setup (new plan) and Starter (archived plans)
	 * */
	GETPRO_TRIAL_FOR_STARTER_AND_SETUP: 'getpro_trial_for_starter_and_setup',

	/** Non–educational users:
	 * get Pro for Setup (new plan) and Starter (archived plans)
	 * */
	GETPRO_PRO_FOR_STARTER_AND_SETUP: 'getpro_pro_for_starter_and_setup',

	/** shown for locked workspace when only Pro is available, and Trial is not */
	GETPRO_PRO_FOR_LOCKED: 'getpro_pro_for_locked',

	/** Students and Educators:
	 * get Trial for Setup or Education workspace
	 * */
	GETPRO_STUDENTS_EDUCATORS_TRIAL_FOR_SETUP:
		'getpro_students_educators_trial_for_setup',

	/** Students and Educators:
	 * get Pro for Setup or Education workspace — when they are not eligible for Trial
	 * */
	GETPRO_STUDENTS_EDUCATORS_PRO_FOR_SETUP:
		'getpro_students_educators_pro_for_setup',

	/** Students and Educators:
	 * get Trial for Starter (legacy)
	 * */
	GETPRO_STUDENTS_EDUCATORS_TRIAL_FOR_STARTER:
		'getpro_students_educators_trial_for_starter',

	/** Students and Educators:
	 * get Pro for Setup or Education workspace — when they are not eligible for Trial
	 * */
	GETPRO_STUDENTS_EDUCATORS_PRO_FOR_STARTER:
		'getpro_students_educators_pro_for_starter',

	GETPRO_STUDENTS_EDUCATORS_EDUCATION_FOR_SETUP:
		'getpro_students_educators_education_for_setup',

	/** Get Pro for Education plan */
	GETPRO_STUDENTS_EDUCATORS_TRIAL_FOR_EDUCATION:
		'getpro_students_educators_trial_for_education',

	/** Get Pro for Education plan */
	GETPRO_STUDENTS_EDUCATORS_PRO_FOR_EDUCATION:
		'getpro_students_educators_pro_for_education',

	// --------------
	// Offers out of Get Pro or Payment flows

	/** Pro for educators — no actual upgrade offer, but a contact form */
	OFFER_PRO_FOR_EDUCATORS: 'offer_pro_for_educators',
	/** Pro for educators — after message has been sent */
	OFFER_PRO_FOR_EDUCATORS_MESSAGE_SENT: 'offer_pro_for_educators_message_sent',

	// --------------
	//  Payments: select plan

	/** previously "initial" in Payments */
	PAYMENTS_INITIAL_TRIAL: 'payments_initial_trial',
	/** previously "initial" in Payments */
	PAYMENTS_INITIAL_PRO: 'payments_initial_pro',
	PAYMENTS_INITIAL_PRO_STUDENTS: 'payments_initial_pro_students',
	PAYMENTS_INITIAL_TRIAL_STUDENTS: 'payments_initial_trial_students',

	// --------------
	//  Payments: Paddle widget

	/** previously "widget" in Payments */
	PAYMENTS_WIDGET: 'payments_widget',
} as const;

export type GetProComponentStates =
	(typeof GetProComponentStates)[keyof typeof GetProComponentStates];
