import { EventLogger } from '@vctr-libs/vctr-analytics';
import { Env } from '@vctr-libs/vctr-utils';

export function initDocumentationTracking() {
	const targetNode = document.querySelector('head');

	const mutationCallback: MutationCallback = mutationList => {
		const titleMutation = mutationList.find(mutation => {
			if (mutation?.type !== 'childList' || mutation?.addedNodes.length <= 0)
				return false;

			const node = [...mutation.addedNodes][0];
			return node?.parentNode?.nodeName === 'TITLE';
		});

		if (titleMutation) logPageView();
	};

	const observer = new MutationObserver(mutationCallback);
	const config = {
		attributes: true,
		childList: true,
		subtree: true,
		characterData: true,
	};
	observer.observe(targetNode, config);
}

export function logPageView() {
	const path = document.location.pathname
		.split('/')
		.filter(item => item !== '');
	const eventType = ('documentation-' + path.join('-')).substring(0, 50);
	new EventLogger(eventType, null, undefined, undefined, undefined, Env.Prod);
}
