import React, { FC, useEffect, useRef } from 'react';

import { PlanIds, UserBase } from '@vctr-libs/vctr-api-client';

import { useOnClickOutside } from '../../hooks';

import {
	PaymentsModalContent,
	PaymentsModalOverlay,
	PaymentsContent,
	PaymentsContentProps,
	PaddlePrice,
} from '../payments';

import {
	TitleBlockComp as TitleBlock,
	SubtitleTrial,
	ProFeatures,
	EducationFeatures,
	// StudentOfferButtonBlock,
} from './components';

import {
	GetProContent,
	LinkUnderline,
	GetProSubtitle,
	GetProButton,
	GetProButtonsBlock,
	GetProTitle,
	GetProTitleBlock as TitleBlockC,
	GetProButtonDefault,
	GetEducationPlanButton,
	GetProHighlight,
	GetProBadge,
	GetProButtonOutlined,
} from './styledComponents';

import { GiveProAccountsContent } from './GiveProAccountsContent';
import { GetProComponentStates } from './componentState/GetProComponentStates';

export type GetProModalProps = PaymentsContentProps & {
	user: UserBase;
	componentState: GetProComponentStates;
	handleClose: () => void;
	/** Show Get Pro */
	handleClickGetPro: () => void;
	/** Show Get Trial */
	handleClickGetTrial: () => void;
	/** Show Get Education state */
	handleClickGetEducation: () => void;
	/** Upgrade to Education via API call */
	/** Show Payments Trial */
	handleClickUpgradeToTrial: () => void;
	/** Show Payments Pro */
	handleClickUpgradeToPro: () => void;
	handleClickUpgradeToProWithDiscount: () => void;
	handleClickUpgradeToEducation: () => void;
	handleCloseOfferProForEducators: () => void;
	handleSubmitProForEducators: (event: React.FormEvent) => void;
	onModalUnmount: () => void;
	showMaybeLater?: boolean;
	isCheckoutLoaded: boolean;
	isCouponLoaded: boolean;
	precalculatedPlanPrices: Array<PaddlePrice & { planId: PlanIds}>;
};

export const GetProModal: FC<GetProModalProps> = props => {
	const {
		user,
		componentState,
		handleClose,
		handleClickUpgradeToTrial,
		handleClickGetPro,
		handleClickUpgradeToPro,
		handleClickUpgradeToProWithDiscount,
		handleClickGetEducation,
		handleClickUpgradeToEducation,
		handleCloseOfferProForEducators,
		handleSubmitProForEducators,
		onModalUnmount,
		selectedPlan,
		studentDiscount,
		setSelectedPlan,
		handleClickUpgrade,
		handleBackPayments,
		couponPrice,
		couponCode,
		WidgetComponent,
		sortedPlanList,
		showMaybeLater = true,
		isCheckoutLoaded,
		isCouponLoaded,
		precalculatedPlanPrices
	} = props;

	const contentRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(contentRef, handleClose, {
		ignoreSelector: '#onetrust-consent-sdk',
	});

	useEffect(() => {
		// TODO this sets "shown" as a cleanup function, but need to figure out how to do it properly and handle rerenders
		return () => onModalUnmount();
	}, [componentState]);

	switch (componentState) {
		case GetProComponentStates.HIDDEN:
			return null;
		case GetProComponentStates.GETPRO_STUDENTS_EDUCATORS_TRIAL_FOR_SETUP:
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<GetProContent>
							<TitleBlock />
							<GetProSubtitle>
								Boost your productivity and get more projects done faster. {` `}
								<SubtitleTrial />
							</GetProSubtitle>
							<ProFeatures />
							<GetProButtonsBlock>
								<GetProBadge>
									LIMITED OFFER: Education discount $249 OFF
								</GetProBadge>
								{/* This means that we allow students discount on Trial plans */}
								<GetProButton onClick={handleClickUpgradeToTrial}>
									Start my 14-day Pro trial
								</GetProButton>
								<LinkUnderline onClick={handleClickGetEducation}>
									Continue with free Education plan
								</LinkUnderline>
							</GetProButtonsBlock>
						</GetProContent>
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);
		case GetProComponentStates.GETPRO_STUDENTS_EDUCATORS_TRIAL_FOR_EDUCATION:
		case GetProComponentStates.GETPRO_STUDENTS_EDUCATORS_TRIAL_FOR_STARTER:
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<GetProContent>
							<TitleBlock />
							<GetProSubtitle>
								Boost your productivity and get more projects done faster. {` `}
								<SubtitleTrial />
							</GetProSubtitle>
							<ProFeatures />
							<GetProButtonsBlock>
								<GetProBadge>
									LIMITED OFFER: Education discount $249 OFF
								</GetProBadge>
								{/* This means that we allow students discount on Trial plans */}
								<GetProButton onClick={handleClickUpgradeToTrial}>
									Start my 14-day Pro trial
								</GetProButton>
								<GetProButton onClick={handleClickUpgradeToProWithDiscount}>
									Get Pro now (50% off)
								</GetProButton>
								{/* <LinkUnderline onClick={handleClose}>Maybe later</LinkUnderline> */}
							</GetProButtonsBlock>
						</GetProContent>
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);
		// NOTE: Get Education Plan isn't shown for students when Trial isn't available
		case GetProComponentStates.GETPRO_STUDENTS_EDUCATORS_PRO_FOR_SETUP:
		case GetProComponentStates.GETPRO_STUDENTS_EDUCATORS_PRO_FOR_STARTER:
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<GetProContent>
							<TitleBlock />
							<GetProSubtitle>
								Boost your productivity and get more projects done faster. {` `}
							</GetProSubtitle>
							<ProFeatures />
							<GetProButtonsBlock>
								<GetProBadge>
									LIMITED OFFER: Education discount $249 OFF
								</GetProBadge>
								<GetProButton onClick={handleClickUpgradeToPro}>
									Upgrade now
								</GetProButton>
								<LinkUnderline onClick={handleClickGetEducation}>
									Continue with free Education plan
								</LinkUnderline>
								{/* <LinkUnderline onClick={handleClose}>Maybe later</LinkUnderline> */}
							</GetProButtonsBlock>
						</GetProContent>
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);
		case GetProComponentStates.GETPRO_STUDENTS_EDUCATORS_PRO_FOR_EDUCATION:
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<GetProContent>
							<TitleBlock />
							<GetProSubtitle>
								Boost your productivity and get more projects done faster. {` `}
							</GetProSubtitle>
							<ProFeatures />
							<GetProButtonsBlock>
								<GetProBadge>
									LIMITED OFFER: Education discount $249 OFF
								</GetProBadge>
								<GetProButton onClick={handleClickUpgradeToPro}>
									Upgrade now
								</GetProButton>
								{/* <LinkUnderline onClick={handleClose}>Maybe later</LinkUnderline> */}
							</GetProButtonsBlock>
						</GetProContent>
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);

		case GetProComponentStates.GETPRO_STUDENTS_EDUCATORS_EDUCATION_FOR_SETUP:
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<GetProContent>
							<TitleBlock title={'Free for Education'} />
							<GetProSubtitle>
								Getting started with 3D designs, sharing and embedding. {` `}
								<GetProHighlight>
									Free, no credit card required.
								</GetProHighlight>
							</GetProSubtitle>
							<EducationFeatures />
							<GetProButtonsBlock>
								<GetEducationPlanButton onClick={handleClickUpgradeToEducation}>
									Continue with free Education plan
								</GetEducationPlanButton>
								<LinkUnderline onClick={handleClickGetPro}>
									Get Vectary Pro (discounted)
								</LinkUnderline>
							</GetProButtonsBlock>
						</GetProContent>
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);

		case GetProComponentStates.GETPRO_TRIAL_FOR_STARTER_AND_SETUP:
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<GetProContent>
							<TitleBlock />
							<GetProSubtitle>
								Boost your productivity and get more projects done faster. {` `}
								<SubtitleTrial />
							</GetProSubtitle>
							<ProFeatures />
							<GetProButtonsBlock>
								<GetProButtonOutlined onClick={handleClickUpgradeToTrial}>
									Start my 14-day Pro trial
								</GetProButtonOutlined>
								<GetProBadge>
									LIMITED OFFER: skip trial and get 50% off
								</GetProBadge>
								<GetProButton onClick={handleClickUpgradeToProWithDiscount}>
									Get Pro now (50% off)
								</GetProButton>
								{/* <LinkUnderline onClick={handleClose}>Maybe later</LinkUnderline> */}
							</GetProButtonsBlock>
						</GetProContent>
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);
		case GetProComponentStates.GETPRO_PRO_FOR_STARTER_AND_SETUP:
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<GetProContent>
							<TitleBlock />
							<GetProSubtitle>
								Boost your productivity and get more projects done faster.
							</GetProSubtitle>
							<ProFeatures />
							<GetProButtonsBlock>
								<GetProButton onClick={handleClickUpgradeToPro}>
									Upgrade now
								</GetProButton>
								{/* <LinkUnderline onClick={handleClose}>Maybe later</LinkUnderline> */}
							</GetProButtonsBlock>
						</GetProContent>
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);
		case GetProComponentStates.GETPRO_PRO_FOR_LOCKED:
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<GetProContent>
							<TitleBlock />
							<GetProSubtitle>
								Boost your productivity and get more projects done faster.
							</GetProSubtitle>
							<ProFeatures />
							<GetProButtonsBlock>
								<GetProButton onClick={handleClickUpgradeToPro}>
									Upgrade now
								</GetProButton>
							</GetProButtonsBlock>
						</GetProContent>
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);
		case GetProComponentStates.OFFER_PRO_FOR_EDUCATORS:
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<GetProContent>
							<GiveProAccountsContent
								handleClose={handleCloseOfferProForEducators}
								handleSubmit={event => handleSubmitProForEducators(event)}
								userEmail={user?.email}
							/>
						</GetProContent>
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);
		case GetProComponentStates.OFFER_PRO_FOR_EDUCATORS_MESSAGE_SENT:
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<GetProContent>
							<TitleBlockC>
								<GetProTitle>Message Sent!</GetProTitle>
								<GetProContent>
									<div>
										Thank you for reaching out to us! Please check your inbox
										for updates from us. Our team aims to respond within 1-3
										business days. For urgent matters, contact us at{' '}
										<a href="mailto:support@vectary.com">
											<strong>support@vectary.com</strong>
										</a>
										{'. '}
										We appreciate your patience and look forward to assisting
										you.
									</div>
								</GetProContent>
								<GetProButtonsBlock widthVariant="full">
									<GetProButtonDefault onClick={handleClose}>
										Close
									</GetProButtonDefault>
								</GetProButtonsBlock>
							</TitleBlockC>
						</GetProContent>
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);
		case GetProComponentStates.PAYMENTS_INITIAL_TRIAL:
		case GetProComponentStates.PAYMENTS_INITIAL_TRIAL_STUDENTS:
		case GetProComponentStates.PAYMENTS_INITIAL_PRO_STUDENTS:
		case GetProComponentStates.PAYMENTS_INITIAL_PRO:
		case GetProComponentStates.PAYMENTS_WIDGET:
			const props: PaymentsContentProps = {
				user,
				handleClosePayments: handleClose,
				componentState,
				// isTrialAllowed,
				// isUserStudent,
				studentDiscount,
				selectedPlan,
				setSelectedPlan,
				handleClickUpgrade,
				handleBackPayments,
				couponPrice,
				couponCode,
				WidgetComponent,
				sortedPlanList,
				showMaybeLater,
				isCheckoutLoaded,
				isCouponLoaded,
				precalculatedPlanPrices
			};
			return (
				<PaymentsModalOverlay>
					<PaymentsModalContent ref={contentRef}>
						<PaymentsContent {...props} />
					</PaymentsModalContent>
				</PaymentsModalOverlay>
			);
		default:
			return null;
	}
};
