/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		permissionsUser:{
			id:"NonNegativeInt"
		},
		allPermissionsUsers:{
			id:"NonNegativeIntFilter",
			name:"StringFilter",
			role:"UserRoleFilter",
			workspaceLimit:"NonNegativeIntFilter",
			internalNote:"StringFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		permissionsWorkspace:{
			id:"NonNegativeInt"
		},
		allPermissionsWorkspaces:{
			id:"NonNegativeIntFilter",
			name:"StringFilter",
			internalNote:"StringFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		user:{
			id:"UUID"
		},
		allUsers:{
			id:"StringFilter",
			username:"StringFilter",
			biography:"StringFilter",
			picture:"StringFilter",
			permissionsUserId:"NonNegativeIntFilter",
			trialUsed:"BooleanFilter",
			proUsed:"BooleanFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		userByEmailOrHash:{

		},
		workspace:{
			id:"UUID"
		},
		allWorkspaces:{
			id:"StringFilter",
			name:"StringFilter",
			picture:"StringFilter",
			ownerId:"StringFilter",
			permissionsWorkspaceId:"NonNegativeIntFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		invitation:{
			id:"UUID"
		},
		allInvitations:{
			id:"StringFilter",
			permission:"MemberPermissionFilter",
			workspaceId:"StringFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		emailInvitation:{
			id:"UUID"
		},
		allEmailInvitations:{
			id:"StringFilter",
			email:"StringFilter",
			permission:"MemberPermissionFilter",
			status:"EmailInvitationStatusFilter",
			workspaceId:"StringFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		folder:{
			id:"UUID"
		},
		allFoldersPaginated:{
			id:"StringFilter",
			name:"StringFilter",
			isHomeFolder:"BooleanFilter",
			workspaceId:"StringFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		allFolders:{
			filters:"FolderFiltersArgsWithLists",
			scope:"QueryScopeEnum"
		},
		project:{
			id:"UUID",
			version:"DateTime"
		},
		allProjectsPaginated:{
			id:"StringFilter",
			name:"StringFilter",
			description:"StringFilter",
			picture:"StringFilter",
			studioVersion:"StringFilter",
			folderId:"StringFilter",
			readonly:"BooleanFilter",
			deleted:"BooleanFilter",
			updatedAt:"DateTimeFilter",
			createdAt:"DateTimeFilter",
			sort:"ProjectSort",
			limit:"NonNegativeInt"
		},
		allProjects:{
			filters:"ProjectFiltersArgsWithLists",
			scope:"QueryScopeEnum"
		},
		allProjectsCount:{
			filters:"ProjectFiltersArgsWithLists",
			scope:"QueryScopeEnum"
		},
		projectState:{
			projectId:"UUID"
		},
		nsProject:{
			id:"UUID"
		},
		allNSProjects:{
			id:"StringFilter",
			name:"StringFilter",
			description:"StringFilter",
			picture:"StringFilter",
			updatedAt:"DateTimeFilter",
			createdAt:"DateTimeFilter",
			sort:"NSProjectSort",
			limit:"NonNegativeInt"
		},
		sharedProject:{
			id:"UUID"
		},
		node:{
			id:"UUID",
			version:"DateTime"
		},
		allNodesPaginated:{
			id:"StringFilter",
			name:"StringFilter",
			description:"StringFilter",
			category:"NodeCategoryFilter",
			projectId:"StringFilter",
			libraryItem:"BooleanFilter",
			createdAt:"DateTimeFilter",
			folder:"StringFilter",
			workspace:"StringFilter",
			deleted:"BooleanFilter",
			limit:"NonNegativeInt"
		},
		allNodes:{
			filters:"NodeFiltersArgsWithLists",
			scope:"QueryScopeEnum"
		},
		allNodesCount:{
			filters:"NodeFiltersArgsWithLists",
			scope:"QueryScopeEnum"
		},
		token:{
			id:"UUID"
		},
		emails:{
			limit:"NonNegativeInt"
		},
		rolePermission:{
			id:"NonNegativeInt"
		},
		memberRole:{
			id:"NonNegativeInt"
		},
		workspaceRolePermission:{
			workspaceId:"UUID"
		},
		workspacePermissions:{
			id:"UUID"
		}
	},
	PermissionsUserNode:{
		users:{
			id:"StringFilter",
			username:"StringFilter",
			biography:"StringFilter",
			picture:"StringFilter",
			permissionsUserId:"NonNegativeIntFilter",
			trialUsed:"BooleanFilter",
			proUsed:"BooleanFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		}
	},
	NonNegativeInt: `scalar.NonNegativeInt` as const,
	UserRoleEnum: "enum" as const,
	DateTime: `scalar.DateTime` as const,
	UserNode:{
		workspaces:{
			id:"StringFilter",
			name:"StringFilter",
			picture:"StringFilter",
			ownerId:"StringFilter",
			permissionsWorkspaceId:"NonNegativeIntFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		}
	},
	UUID: `scalar.UUID` as const,
	JSONObject: `scalar.JSONObject` as const,
	NonNegativeFloat: `scalar.NonNegativeFloat` as const,
	UserWorkspaceNode:{
		folders:{
			id:"StringFilter",
			name:"StringFilter",
			isHomeFolder:"BooleanFilter",
			workspaceId:"StringFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		invitations:{
			id:"StringFilter",
			workspaceId:"StringFilter",
			permission:"MemberPermissionFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		emailInvitations:{
			id:"StringFilter",
			workspaceId:"StringFilter",
			permission:"MemberPermissionFilter",
			status:"EmailInvitationStatusFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		}
	},
	PermissionsWorkspaceNode:{
		workspaces:{
			id:"StringFilter",
			name:"StringFilter",
			picture:"StringFilter",
			ownerId:"StringFilter",
			permissionsWorkspaceId:"NonNegativeIntFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		}
	},
	WorkspaceNode:{
		folders:{
			id:"StringFilter",
			name:"StringFilter",
			isHomeFolder:"BooleanFilter",
			workspaceId:"StringFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		invitations:{
			id:"StringFilter",
			workspaceId:"StringFilter",
			permission:"MemberPermissionFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		},
		emailInvitations:{
			id:"StringFilter",
			workspaceId:"StringFilter",
			permission:"MemberPermissionFilter",
			status:"EmailInvitationStatusFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		}
	},
	Currency: `scalar.Currency` as const,
	Duration: `scalar.Duration` as const,
	URL: `scalar.URL` as const,
	WorkspaceFolderNode:{
		projects:{
			id:"StringFilter",
			name:"StringFilter",
			description:"StringFilter",
			picture:"StringFilter",
			studioVersion:"StringFilter",
			folderId:"StringFilter",
			readonly:"BooleanFilter",
			deleted:"BooleanFilter",
			updatedAt:"DateTimeFilter",
			createdAt:"DateTimeFilter",
			sort:"ProjectSort",
			limit:"NonNegativeInt"
		},
		nsProjects:{
			id:"StringFilter",
			name:"StringFilter",
			description:"StringFilter",
			picture:"StringFilter",
			updatedAt:"DateTimeFilter",
			createdAt:"DateTimeFilter",
			sort:"NSProjectSort",
			limit:"NonNegativeInt"
		}
	},
	FolderProjectNode:{
		nodes:{
			id:"StringFilter",
			name:"StringFilter",
			description:"StringFilter",
			category:"NodeCategoryFilter",
			projectId:"StringFilter",
			libraryItem:"BooleanFilter",
			createdAt:"DateTimeFilter",
			folder:"StringFilter",
			workspace:"StringFilter",
			deleted:"BooleanFilter",
			limit:"NonNegativeInt"
		}
	},
	PositiveInt: `scalar.PositiveInt` as const,
	SemVer: `scalar.SemVer` as const,
	FolderNode:{
		projects:{
			id:"StringFilter",
			name:"StringFilter",
			description:"StringFilter",
			picture:"StringFilter",
			studioVersion:"StringFilter",
			folderId:"StringFilter",
			readonly:"BooleanFilter",
			deleted:"BooleanFilter",
			updatedAt:"DateTimeFilter",
			createdAt:"DateTimeFilter",
			sort:"ProjectSort",
			limit:"NonNegativeInt"
		},
		nsProjects:{
			id:"StringFilter",
			name:"StringFilter",
			description:"StringFilter",
			picture:"StringFilter",
			updatedAt:"DateTimeFilter",
			createdAt:"DateTimeFilter",
			sort:"NSProjectSort",
			limit:"NonNegativeInt"
		}
	},
	StringFilter:{
		filter:"TextFilterEnum"
	},
	TextFilterEnum: "enum" as const,
	BooleanFilter:{
		filter:"BooleanFilterEnum"
	},
	BooleanFilterEnum: "enum" as const,
	DateTimeFilter:{
		filter:"SortableFilterEnum",
		value:"DateTime"
	},
	SortableFilterEnum: "enum" as const,
	ProjectSort: "enum" as const,
	BigInt: `scalar.BigInt` as const,
	NSProjectSort: "enum" as const,
	NodeCategoryEnum: "enum" as const,
	ProjectNode:{
		nodes:{
			id:"StringFilter",
			name:"StringFilter",
			description:"StringFilter",
			category:"NodeCategoryFilter",
			projectId:"StringFilter",
			libraryItem:"BooleanFilter",
			createdAt:"DateTimeFilter",
			folder:"StringFilter",
			workspace:"StringFilter",
			deleted:"BooleanFilter",
			limit:"NonNegativeInt"
		}
	},
	NodeCategoryFilter:{
		filter:"TextFilterEnum",
		value:"NodeCategoryEnum"
	},
	WorkspaceMemberNode:{
		workspaces:{
			id:"StringFilter",
			name:"StringFilter",
			picture:"StringFilter",
			ownerId:"StringFilter",
			permissionsWorkspaceId:"NonNegativeIntFilter",
			createdAt:"DateTimeFilter",
			limit:"NonNegativeInt"
		}
	},
	NonNegativeIntFilter:{
		filter:"SortableFilterEnum",
		value:"NonNegativeInt"
	},
	TokenTypeColumnEnum: "enum" as const,
	MemberPermissionEnum: "enum" as const,
	EmailInvitationStatusEnum: "enum" as const,
	MemberPermissionFilter:{
		filter:"TextFilterEnum",
		value:"MemberPermissionEnum"
	},
	EmailInvitationStatusFilter:{
		filter:"TextFilterEnum",
		value:"EmailInvitationStatusEnum"
	},
	PermissionEnum: "enum" as const,
	UserRoleFilter:{
		filter:"TextFilterEnum",
		value:"UserRoleEnum"
	},
	FolderFiltersArgsWithLists:{
		id:"StringListFilter",
		name:"StringListFilter",
		isHomeFolder:"BooleanFilter",
		workspaceId:"StringListFilter",
		createdAt:"DateTimeFilter"
	},
	StringListFilter:{

	},
	QueryScopeEnum: "enum" as const,
	ProjectFiltersArgsWithLists:{
		id:"StringListFilter",
		name:"StringListFilter",
		description:"StringListFilter",
		folderId:"StringListFilter",
		workspaceId:"StringListFilter",
		readonly:"BooleanFilter",
		archived:"BooleanFilter",
		updatedAt:"DateTimeFilter",
		createdAt:"DateTimeFilter"
	},
	NodeFiltersArgsWithLists:{
		id:"StringListFilter",
		name:"StringListFilter",
		description:"StringListFilter",
		category:"NodeCategoryListFilter",
		projectId:"StringListFilter",
		libraryItem:"BooleanFilter",
		createdAt:"DateTimeFilter",
		folderId:"StringListFilter",
		workspaceId:"StringListFilter",
		inArchivedProject:"BooleanFilter"
	},
	NodeCategoryListFilter:{
		values:"NodeCategoryEnum"
	},
	Mutation:{
		createPermissionsUser:{
			id:"NonNegativeInt",
			role:"UserRoleEnum",
			workspaceLimit:"NonNegativeInt"
		},
		deletePermissionsUser:{
			id:"NonNegativeInt"
		},
		updatePermissionsUser:{
			id:"NonNegativeInt",
			role:"UserRoleEnum",
			workspaceLimit:"NonNegativeInt"
		},
		createPermissionsWorkspace:{
			id:"NonNegativeInt",
			properties:"JSONObject"
		},
		deletePermissionsWorkspace:{
			id:"NonNegativeInt"
		},
		updatePermissionsWorkspace:{
			id:"NonNegativeInt",
			properties:"JSONObject"
		},
		deleteUser:{
			id:"UUID"
		},
		updateUser:{
			id:"UUID",
			questionnaire:"QuestionnaireInputObjectType",
			permissionsUserId:"NonNegativeInt"
		},
		updateUserEmailDetails:{
			userId:"UUID",
			verificationToken:"UUID"
		},
		updateUserLocationDetails:{
			userId:"UUID",
			geo:"JSONObject"
		},
		acceptGdprAndTou:{
			id:"UUID"
		},
		addEmailAuthenticationMethod:{
			userId:"UUID"
		},
		createWorkspace:{
			id:"UUID",
			ownerId:"UUID",
			permissionsWorkspaceId:"NonNegativeInt"
		},
		deleteWorkspace:{
			id:"UUID"
		},
		updateWorkspace:{
			id:"UUID",
			ownerId:"UUID",
			permissionsWorkspaceId:"NonNegativeInt",
			overwrite:"JSONObject",
			customDomainId:"UUID",
			ordering:"NonNegativeInt"
		},
		addMember:{
			workspaceId:"UUID",
			userId:"UUID"
		},
		updateMember:{
			workspaceId:"UUID",
			userId:"UUID",
			permission:"MemberPermissionEnum"
		},
		removeMember:{
			workspaceId:"UUID",
			userId:"UUID"
		},
		cancelPaddleSubscription:{
			workspaceId:"UUID"
		},
		acceptInvitation:{
			invitationId:"UUID"
		},
		createInvitation:{
			id:"UUID",
			permission:"MemberPermissionEnum",
			workspaceId:"UUID"
		},
		deleteInvitation:{
			id:"UUID"
		},
		updateInvitation:{
			id:"UUID",
			permission:"MemberPermissionEnum"
		},
		createEmailInvitation:{
			items:"EmailInvitationInput"
		},
		deleteEmailInvitation:{
			id:"UUID"
		},
		updateEmailInvitation:{
			id:"UUID",
			permission:"MemberPermissionEnum",
			status:"EmailInvitationStatusEnum"
		},
		acceptEmailInvitation:{
			invitationId:"UUID"
		},
		reactivateEmailInvitation:{
			id:"UUID"
		},
		createFolder:{
			id:"UUID",
			workspaceId:"UUID"
		},
		deleteFolder:{
			id:"UUID"
		},
		updateFolder:{
			id:"UUID",
			workspaceId:"UUID",
			ordering:"NonNegativeInt"
		},
		createCustomDomain:{

		},
		deleteCustomDomain:{
			id:"UUID"
		},
		createProject:{
			id:"UUID",
			picture:"SHA1HashOrUpload",
			scene:"JSONObject",
			view:"JSONObject",
			credits:"JSONObject",
			settings:"JSONObject",
			studioVersion:"SemVer",
			folderId:"UUID"
		},
		updateProject:{
			id:"UUID",
			picture:"SHA1HashOrUpload",
			files:"JSONObject",
			scene:"JSONObject",
			view:"JSONObject",
			credits:"JSONObject",
			settings:"JSONObject",
			studioVersion:"SemVer",
			folderId:"UUID",
			ordering:"NonNegativeInt"
		},
		createOrUpdateProject:{
			id:"UUID",
			picture:"SHA1HashOrUpload",
			files:"JSONObject",
			scene:"JSONObject",
			view:"JSONObject",
			credits:"JSONObject",
			settings:"JSONObject",
			studioVersion:"SemVer",
			folderId:"UUID",
			ordering:"NonNegativeInt",
			activeNodes:"UUID",
			nodes:"NodeTypeBaseInput"
		},
		deleteProject:{
			id:"UUID"
		},
		cloneProject:{
			id:"UUID",
			newId:"UUID",
			workspaceId:"UUID"
		},
		copyProject:{
			id:"UUID",
			newId:"UUID",
			folderId:"UUID",
			workspaceId:"UUID"
		},
		createProjectState:{
			projectId:"UUID",
			state:"JSONObject"
		},
		createNSProject:{
			id:"UUID",
			picture:"SHA1HashOrUpload",
			binaries:"SHA1HashOrUpload",
			folderId:"UUID"
		},
		updateNSProject:{
			id:"UUID",
			picture:"SHA1HashOrUpload",
			binaries:"SHA1HashOrUpload",
			folderId:"UUID"
		},
		deleteNSProject:{
			id:"UUID"
		},
		createSharedProject:{
			id:"UUID",
			picture:"SHA1HashOrUpload",
			scene:"JSONObject",
			credits:"JSONObject",
			settings:"JSONObject",
			studioVersion:"SemVer",
			nodes:"SharedProjectNodeNode"
		},
		createNode:{
			id:"UUID",
			category:"NodeCategoryEnum",
			data:"JSONObject",
			binaries:"SHA1HashOrUpload",
			files:"JSONObject",
			projectId:"UUID"
		},
		createOrUpdateNode:{
			id:"UUID",
			category:"NodeCategoryEnum",
			data:"JSONObject",
			binaries:"SHA1HashOrUpload",
			files:"JSONObject",
			projectId:"UUID"
		},
		deleteNode:{
			id:"UUID"
		},
		updateNode:{
			id:"UUID",
			category:"NodeCategoryEnum",
			data:"JSONObject",
			binaries:"SHA1HashOrUpload",
			files:"JSONObject",
			projectId:"UUID"
		},
		createCommentMessage:{
			projectId:"UUID",
			nodeId:"UUID",
			commentId:"UUID",
			user:"JSONObject"
		},
		updateCommentMessage:{
			projectId:"UUID",
			nodeId:"UUID",
			commentId:"UUID",
			messageId:"UUID",
			user:"JSONObject"
		},
		deleteCommentMessage:{
			projectId:"UUID",
			nodeId:"UUID",
			commentId:"UUID",
			messageId:"UUID"
		},
		uploadBinary:{
			binary:"SHA1HashOrUpload"
		},
		createToken:{
			type:"TokenTypeColumnEnum"
		},
		createApiToken:{

		},
		updateToken:{
			id:"UUID",
			type:"TokenTypeColumnEnum"
		},
		deleteToken:{
			id:"UUID"
		},
		createRolePermission:{
			id:"NonNegativeInt"
		},
		deleteRolePermission:{
			id:"NonNegativeInt"
		},
		createMemberRole:{
			id:"NonNegativeInt"
		},
		deleteMemberRole:{
			id:"NonNegativeInt"
		},
		updateWorkspaceRolePermission:{
			workspaceId:"UUID"
		},
		deleteWorkspaceRolePermission:{
			workspaceId:"UUID"
		}
	},
	QuestionnaireInputObjectType:{
		occupation:"QuestionnaireDataInputObjectType",
		reasonToLeave:"QuestionnaireDataInputObjectType"
	},
	QuestionnaireDataInputObjectType:{
		subscribedForDays:"NonNegativeFloat"
	},
	EmailInvitationInput:{
		permission:"MemberPermissionEnum",
		status:"EmailInvitationStatusEnum",
		workspaceId:"UUID"
	},
	SHA1HashOrUpload: `scalar.SHA1HashOrUpload` as const,
	NodeTypeBaseInput:{
		id:"UUID",
		category:"NodeCategoryEnum",
		data:"JSONObject",
		files:"JSONObject",
		createdAt:"DateTime"
	},
	OperationResultType: "enum" as const,
	SharedProjectNodeNode:{
		id:"UUID",
		category:"NodeCategoryEnum",
		data:"JSONObject",
		binaries:"SHA1HashOrUpload",
		files:"JSONObject"
	}
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		permissionsUser:"PermissionsUserNode",
		allPermissionsUsers:"PermissionsUserNodePaginated",
		permissionsWorkspace:"PermissionsWorkspaceNode",
		allPermissionsWorkspaces:"PermissionsWorkspaceNodePaginated",
		user:"UserNode",
		allUsers:"UserNodePaginated",
		userByEmailOrHash:"UserEmailDetailsNode",
		workspace:"WorkspaceNode",
		allWorkspaces:"WorkspaceNodePaginated",
		invitation:"InvitationNode",
		allInvitations:"InvitationNodePaginated",
		emailInvitation:"EmailInvitationNode",
		allEmailInvitations:"EmailInvitationNodePaginated",
		folder:"FolderNode",
		allFoldersPaginated:"FolderNodePaginated",
		allFolders:"FolderNodeBase",
		allCustomDomains:"CustomDomainNode",
		project:"ProjectNode",
		allProjectsPaginated:"ProjectNodePaginated",
		allProjects:"ProjectNode",
		allProjectsCount:"Int",
		projectState:"ProjectStateNode",
		nsProject:"NSProjectNode",
		allNSProjects:"NSProjectNodePaginated",
		sharedProject:"SharedProjectNode",
		node:"NodeNode",
		allNodesPaginated:"NodeNodePaginated",
		allNodes:"NodeNodeBase",
		allNodesCount:"Int",
		token:"TokenNode",
		allTokens:"TokenNode",
		emails:"UserEmailDetailsNodePaginated",
		rolePermission:"RolePermissionNode",
		allRolePermissions:"RolePermissionNode",
		memberRole:"MemberRoleType",
		allMemberRoles:"MemberRoleType",
		workspaceRolePermission:"WorkspaceRolePermissionType",
		workspacePermissions:"JSONObject"
	},
	PermissionsUserNode:{
		id:"NonNegativeInt",
		name:"String",
		role:"UserRoleEnum",
		workspaceLimit:"NonNegativeInt",
		internalNote:"String",
		createdAt:"DateTime",
		users:"UserNodePaginated"
	},
	NonNegativeInt: `scalar.NonNegativeInt` as const,
	DateTime: `scalar.DateTime` as const,
	UserNodePaginated:{
		items:"UserNode",
		cursor:"String",
		remaining:"BigInt"
	},
	UserNode:{
		id:"UUID",
		username:"String",
		firstName:"String",
		lastName:"String",
		biography:"String",
		picture:"String",
		questionnaire:"JSONObject",
		gdpr:"NonNegativeFloat",
		isGdprCurrent:"Boolean",
		tou:"NonNegativeFloat",
		isTouCurrent:"Boolean",
		trialUsed:"Boolean",
		proUsed:"Boolean",
		internalNotes:"String",
		createdAt:"DateTime",
		emailDetails:"UserEmailDetailsNode",
		locationDetails:"UserLocationDetailsNode",
		accessDetails:"UserAccessDetailsNode",
		permissions:"PermissionsUserNode",
		workspaces:"UserWorkspaceNodePaginated",
		tokens:"TokenNode",
		isCiamUser:"Boolean",
		emailInvitations:"EmailInvitationNode"
	},
	UUID: `scalar.UUID` as const,
	JSONObject: `scalar.JSONObject` as const,
	NonNegativeFloat: `scalar.NonNegativeFloat` as const,
	UserEmailDetailsNode:{
		id:"UUID",
		email:"String",
		newsletter:"Boolean",
		notifyComments:"String",
		undeliverable:"Boolean",
		verificationToken:"UUID"
	},
	UserLocationDetailsNode:{
		location:"String",
		geo:"JSONObject"
	},
	UserAccessDetailsNode:{
		accessToken:"UUID",
		accessTokenExpiration:"String",
		refreshToken:"UUID",
		refreshTokenExpiration:"String",
		passwordIdentifier:"String",
		passwordResetToken:"UUID",
		facebookIdentifier:"String",
		googleIdentifier:"String",
		ciamIdentifier:"String"
	},
	UserWorkspaceNodePaginated:{
		items:"UserWorkspaceNode",
		cursor:"String",
		remaining:"BigInt"
	},
	UserWorkspaceNode:{
		id:"UUID",
		name:"String",
		picture:"String",
		overwrite:"JSONObject",
		createdAt:"DateTime",
		owner:"UserNode",
		customDomain:"String",
		workspaceRolePermissions:"String",
		permissions:"PermissionsWorkspaceNode",
		paddleDetails:"PaddleDetailsNode",
		folders:"WorkspaceFolderNodePaginated",
		members:"WorkspaceMemberNode",
		invitations:"InvitationNodePaginated",
		emailInvitations:"EmailInvitationNodePaginated",
		permission:"PermissionEnum",
		ordering:"NonNegativeInt"
	},
	PermissionsWorkspaceNode:{
		id:"NonNegativeInt",
		name:"String",
		properties:"JSONObject",
		internalNote:"String",
		createdAt:"DateTime",
		workspaces:"WorkspaceNodePaginated"
	},
	WorkspaceNodePaginated:{
		items:"WorkspaceNode",
		cursor:"String",
		remaining:"BigInt"
	},
	WorkspaceNode:{
		id:"UUID",
		name:"String",
		picture:"String",
		overwrite:"JSONObject",
		createdAt:"DateTime",
		owner:"UserNode",
		customDomain:"String",
		workspaceRolePermissions:"String",
		permissions:"PermissionsWorkspaceNode",
		paddleDetails:"PaddleDetailsNode",
		folders:"WorkspaceFolderNodePaginated",
		members:"WorkspaceMemberNode",
		invitations:"InvitationNodePaginated",
		emailInvitations:"EmailInvitationNodePaginated"
	},
	PaddleDetailsNode:{
		customerId:"NonNegativeInt",
		subscriptionId:"NonNegativeInt",
		linkedSubscriptionId:"NonNegativeInt",
		status:"String",
		price:"NonNegativeFloat",
		currency:"Currency",
		recurringInterval:"Duration",
		nextPaymentAmount:"NonNegativeFloat",
		nextPaymentDate:"DateTime",
		cancellationEffectiveDate:"DateTime",
		cancelUrl:"URL",
		updateUrl:"URL",
		receipts:"JSONObject",
		latestEventTime:"DateTime"
	},
	Currency: `scalar.Currency` as const,
	Duration: `scalar.Duration` as const,
	URL: `scalar.URL` as const,
	WorkspaceFolderNodePaginated:{
		items:"WorkspaceFolderNode",
		cursor:"String",
		remaining:"BigInt"
	},
	WorkspaceFolderNode:{
		id:"UUID",
		name:"String",
		isHomeFolder:"Boolean",
		createdAt:"DateTime",
		workspace:"WorkspaceNode",
		projects:"FolderProjectNodePaginated",
		nsProjects:"FolderNSProjectNodePaginated",
		ordering:"NonNegativeInt"
	},
	FolderProjectNodePaginated:{
		items:"FolderProjectNode",
		cursor:"String",
		remaining:"BigInt"
	},
	FolderProjectNode:{
		id:"UUID",
		name:"String",
		description:"String",
		picture:"String",
		files:"File",
		keywords:"String",
		scene:"JSONObject",
		view:"JSONObject",
		credits:"JSONObject",
		published:"String",
		imports:"String",
		settings:"JSONObject",
		studioVersion:"SemVer",
		forkedFromId:"String",
		readonly:"Boolean",
		deleted:"Boolean",
		folderId:"UUID",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		folder:"FolderNode",
		nodes:"NodeNodePaginated",
		history:"DateTime",
		ordering:"NonNegativeInt"
	},
	File:{
		purpose:"String",
		urls:"String",
		sizes:"PositiveInt",
		status:"String"
	},
	PositiveInt: `scalar.PositiveInt` as const,
	SemVer: `scalar.SemVer` as const,
	FolderNode:{
		id:"UUID",
		name:"String",
		isHomeFolder:"Boolean",
		createdAt:"DateTime",
		workspace:"WorkspaceNode",
		projects:"FolderProjectNodePaginated",
		nsProjects:"FolderNSProjectNodePaginated"
	},
	FolderNSProjectNodePaginated:{
		items:"FolderNSProjectNode",
		cursor:"String",
		remaining:"BigInt"
	},
	FolderNSProjectNode:{
		id:"UUID",
		name:"String",
		description:"String",
		picture:"String",
		keywords:"String",
		binaries:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		folder:"FolderNode",
		ordering:"NonNegativeInt"
	},
	BigInt: `scalar.BigInt` as const,
	NodeNodePaginated:{
		items:"NodeNode",
		cursor:"String",
		remaining:"BigInt"
	},
	NodeNode:{
		id:"UUID",
		name:"String",
		description:"String",
		category:"NodeCategoryEnum",
		data:"JSONObject",
		binaries:"String",
		files:"JSONObject",
		libraryItem:"Boolean",
		forkedFromId:"String",
		createdAt:"DateTime",
		projectId:"String",
		project:"ProjectNode",
		history:"DateTime"
	},
	ProjectNode:{
		id:"UUID",
		name:"String",
		description:"String",
		picture:"String",
		files:"File",
		keywords:"String",
		scene:"JSONObject",
		view:"JSONObject",
		credits:"JSONObject",
		published:"String",
		imports:"String",
		settings:"JSONObject",
		studioVersion:"SemVer",
		forkedFromId:"String",
		readonly:"Boolean",
		deleted:"Boolean",
		folderId:"UUID",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		folder:"FolderNode",
		nodes:"NodeNodePaginated",
		history:"DateTime"
	},
	WorkspaceMemberNode:{
		id:"UUID",
		username:"String",
		firstName:"String",
		lastName:"String",
		biography:"String",
		picture:"String",
		questionnaire:"JSONObject",
		gdpr:"NonNegativeFloat",
		isGdprCurrent:"Boolean",
		tou:"NonNegativeFloat",
		isTouCurrent:"Boolean",
		trialUsed:"Boolean",
		proUsed:"Boolean",
		internalNotes:"String",
		createdAt:"DateTime",
		emailDetails:"UserEmailDetailsNode",
		locationDetails:"UserLocationDetailsNode",
		accessDetails:"UserAccessDetailsNode",
		permissions:"PermissionsUserNode",
		workspaces:"UserWorkspaceNodePaginated",
		tokens:"TokenNode",
		isCiamUser:"Boolean",
		emailInvitations:"EmailInvitationNode",
		permission:"MemberPermissionEnum",
		rolePermissions:"String"
	},
	TokenNode:{
		id:"UUID",
		name:"String",
		type:"TokenTypeColumnEnum",
		token:"String",
		createdAt:"DateTime"
	},
	EmailInvitationNode:{
		id:"UUID",
		email:"String",
		permission:"MemberPermissionEnum",
		status:"EmailInvitationStatusEnum",
		createdAt:"DateTime",
		workspace:"WorkspaceNode"
	},
	InvitationNodePaginated:{
		items:"InvitationNode",
		cursor:"String",
		remaining:"BigInt"
	},
	InvitationNode:{
		id:"UUID",
		permission:"MemberPermissionEnum",
		createdAt:"DateTime",
		workspace:"WorkspaceNode"
	},
	EmailInvitationNodePaginated:{
		items:"EmailInvitationNode",
		cursor:"String",
		remaining:"BigInt"
	},
	PermissionsUserNodePaginated:{
		items:"PermissionsUserNode",
		cursor:"String",
		remaining:"BigInt"
	},
	PermissionsWorkspaceNodePaginated:{
		items:"PermissionsWorkspaceNode",
		cursor:"String",
		remaining:"BigInt"
	},
	FolderNodePaginated:{
		items:"FolderNode",
		cursor:"String",
		remaining:"BigInt"
	},
	FolderNodeBase:{
		id:"UUID",
		name:"String",
		isHomeFolder:"Boolean",
		createdAt:"DateTime"
	},
	CustomDomainNode:{
		id:"UUID",
		hostname:"String",
		createdAt:"DateTime"
	},
	ProjectNodePaginated:{
		items:"ProjectNode",
		cursor:"String",
		remaining:"BigInt"
	},
	ProjectStateNode:{
		id:"String",
		projectId:"UUID",
		state:"JSONObject"
	},
	NSProjectNode:{
		id:"UUID",
		name:"String",
		description:"String",
		picture:"String",
		keywords:"String",
		binaries:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		folder:"FolderNode"
	},
	NSProjectNodePaginated:{
		items:"NSProjectNode",
		cursor:"String",
		remaining:"BigInt"
	},
	SharedProjectNode:{
		id:"UUID",
		name:"String",
		description:"String",
		picture:"String",
		files:"JSONObject",
		keywords:"String",
		scene:"JSONObject",
		credits:"JSONObject",
		imports:"String",
		settings:"JSONObject",
		studioVersion:"SemVer",
		nodes:"NodeNode",
		createdAt:"DateTime"
	},
	NodeNodeBase:{
		id:"UUID",
		name:"String",
		description:"String",
		category:"NodeCategoryEnum",
		data:"JSONObject",
		binaries:"String",
		files:"JSONObject",
		libraryItem:"Boolean",
		forkedFromId:"String",
		createdAt:"DateTime",
		projectId:"String"
	},
	UserEmailDetailsNodePaginated:{
		items:"UserEmailDetailsNode",
		cursor:"String",
		remaining:"BigInt"
	},
	RolePermissionNode:{
		id:"NonNegativeInt",
		name:"String",
		displayName:"String",
		description:"String",
		createdAt:"DateTime"
	},
	MemberRoleType:{
		id:"NonNegativeInt",
		name:"String",
		displayName:"String",
		createdAt:"DateTime"
	},
	WorkspaceRolePermissionType:{
		workspaceId:"UUID",
		memberRole:"String",
		rolePermission:"String",
		isAllowed:"Boolean",
		createdAt:"DateTime"
	},
	Mutation:{
		createPermissionsUser:"PermissionsUserNode",
		deletePermissionsUser:"NonNegativeInt",
		updatePermissionsUser:"PermissionsUserNode",
		createPermissionsWorkspace:"PermissionsWorkspaceNode",
		deletePermissionsWorkspace:"NonNegativeInt",
		updatePermissionsWorkspace:"PermissionsWorkspaceNode",
		deleteUser:"NonNegativeInt",
		updateUser:"UserNode",
		updateUserEmailDetails:"UserEmailDetailsNode",
		updateUserLocationDetails:"UserLocationDetailsNode",
		acceptGdprAndTou:"UserNode",
		addEmailAuthenticationMethod:"Boolean",
		createWorkspace:"WorkspaceNode",
		deleteWorkspace:"NonNegativeInt",
		updateWorkspace:"WorkspaceNode",
		addMember:"Boolean",
		updateMember:"Boolean",
		removeMember:"Boolean",
		cancelPaddleSubscription:"Boolean",
		acceptInvitation:"InvitationNode",
		createInvitation:"InvitationNode",
		deleteInvitation:"NonNegativeInt",
		updateInvitation:"InvitationNode",
		createEmailInvitation:"EmailInvitationPossibleErrorNode",
		deleteEmailInvitation:"NonNegativeInt",
		updateEmailInvitation:"EmailInvitationNode",
		acceptEmailInvitation:"EmailInvitationNode",
		reactivateEmailInvitation:"EmailInvitationNode",
		createFolder:"FolderNode",
		deleteFolder:"NonNegativeInt",
		updateFolder:"FolderNode",
		createCustomDomain:"CustomDomainNode",
		deleteCustomDomain:"NonNegativeInt",
		createProject:"ProjectNode",
		updateProject:"ProjectNode",
		createOrUpdateProject:"ProjectNode",
		deleteProject:"NonNegativeInt",
		cloneProject:"OperationResult",
		copyProject:"OperationResult",
		createProjectState:"ProjectStateNode",
		createNSProject:"NSProjectNode",
		updateNSProject:"NSProjectNode",
		deleteNSProject:"NonNegativeInt",
		createSharedProject:"ProjectNode",
		createNode:"NodeNode",
		createOrUpdateNode:"NodeNode",
		deleteNode:"NonNegativeInt",
		updateNode:"NodeNode",
		createCommentMessage:"NodeNode",
		updateCommentMessage:"NodeNode",
		deleteCommentMessage:"NodeNode",
		uploadBinary:"String",
		createToken:"TokenNode",
		createApiToken:"TokenNode",
		updateToken:"TokenNode",
		deleteToken:"TokenNode",
		createRolePermission:"RolePermissionNode",
		deleteRolePermission:"NonNegativeInt",
		createMemberRole:"MemberRoleType",
		deleteMemberRole:"NonNegativeInt",
		updateWorkspaceRolePermission:"WorkspaceRolePermissionType",
		deleteWorkspaceRolePermission:"Int"
	},
	EmailInvitationPossibleErrorNode:{
		id:"UUID",
		email:"String",
		permission:"MemberPermissionEnum",
		status:"EmailInvitationStatusEnum",
		createdAt:"DateTime",
		workspace:"WorkspaceNode",
		error:"JSONObject"
	},
	SHA1HashOrUpload: `scalar.SHA1HashOrUpload` as const,
	OperationResult:{
		result:"OperationResultType"
	},
	Subscription:{
		countDown:"Int"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const,
	subscription: "Subscription" as const
}