import { MemberPermission, Permission } from './constants/enums';
import { MembersPermissionSetProperty } from './constants/types';

export const PermissionType = {
	PROJECT_ARCHIVE: 'project_archive',
	PROJECT_DELETE: 'project_delete', // used
	PROJECT_CREATE: 'project_create', // used
	PROJECT_DUPLICATE: 'project_duplicate', // used, but we call it COPY
	PROJECT_CLONE: 'project_clone', // used
	PROJECT_CLONE_TOGGLE: 'project_clone_toggle', // used
	PROJECT_UPDATE: 'project_update', // used
	PROJECT_IMPORT: 'project_import', // used
	PROJECT_EXPORT: 'project_export', // used
	PROJECT_EXPORT_TOGGLE: 'project_export_toggle', // ?
	PROJECT_MOVE: 'project_move',
	PROJECT_HISTORY: 'project_history', // used
	PROJECT_PRIVATE_LINK_VIEW: 'project_private_link_view', // ?
	PROJECT_PRIVATE_LINK_TOGGLE: 'project_private_link_toggle', // used
	PROJECT_LIST: 'project_list', // used
	NSPROJECT_CREATE: 'nsproject_create', // used
	NSPROJECT_READ: 'nsproject_read', // used
	NSPROJECT_UPDATE: 'nsproject_update', // used
	NSPROJECT_DELETE: 'nsproject_delete', // used
	COMMENT_MODE: 'comment_mode', // used
	COMMENT_CREATE: 'comment_create', // used
	COMMENT_UPDATE: 'comment_update', // used
	COMMENT_DELETE: 'comment_delete', // used
	COMMENT_DELETE_OWN: 'comment_delete_own', // not implemented
	COMMENT_RESOLVE: 'comment_resolve', // not implemented
	COMMENT_GET_NOTIFICATIONS: 'comment_get_notifications', // used
	MEMBER_READ: 'member_read', // used in invitation and in getMembersOfWorkspace
	MEMBER_CREATE: 'member_create', // used in invitation
	MEMBER_UPDATE: 'member_update', // used also used in invitation
	MEMBER_DELETE: 'member_delete', // used also used in invitation
	MEMBER_DELETE_OWN: 'member_delete_own', // used
	MEMBER_LIST: 'member_list', // used
	WORKSPACE_READ: 'workspcae_read', // used
	WORKSPACE_REORDER: 'workspace_reorder', // used
	WORKSPACE_UPDATE: 'workspace_update', // used
	WORKSPACE_CREATE: 'workspace_create', // any user can create LOCKED workspace
	WORKSPACE_DELETE: 'workspace_delete', // used
	WORKSPACE_UPGRADE: 'workspace_upgrade',
	WORKSPACE_BILLING_READ: 'workspace_billing_read', // used
	/** used for WORKSPACE_DOWNGRADE in dashboard */
	WORKSPACE_BILLING_EDIT: 'workspace_billing_edit', // used
	FOLDER_CREATE: 'folder_create', // used
	FOLDER_DELETE: 'folder_delete', // used
	FOLDER_UPDATE: 'folder_update', // used
	FOLDER_READ: 'folder_read', // used
	FOLDER_REORDER: 'folder_reorder', // used
	FOLDER_LIST: 'folder_list', // used
	USER_READ: 'user_read', // used
	USER_UPDATE: 'user_update', // used
	USER_DELETE: 'user_delete', // used
	TOKEN_READ: 'token_read', // used // should this be USER_READ???
	TOKEN_UPDATE: 'token_update', // used // should this be USER_UPDATE???
	TOKEN_DELETE: 'token_delete', // used // should this be USER_DELETE or UPDATE???
	PERMISSIONS_WORKSPACE_CREATE: 'permissions_workspace_create', // only administrators can create
	PERMISSIONS_WORKSPACE_UPDATE: 'permissions_workspace_update', // used
	PERMISSIONS_WORKSPACE_DELETE: 'permissions_workspace_delete', // used
	PERMISSIONS_WORKSPACE_READ: 'permissions_workspace_read', // used
	PERMISSIONS_USER_CREATE: 'permissions_user_create', // only administrators can create
	PERMISSIONS_USER_UPDATE: 'permissions_user_update', // used
	PERMISSIONS_USER_DELETE: 'permissions_user_delete', // used
	PERMISSIONS_USER_READ: 'permissions_user_read', // every user is allowed
} as const;

export type PermissionType =
	(typeof PermissionType)[keyof typeof PermissionType];

export const rolePermissions: Record<Permission, PermissionType[]> = {
	[Permission.VIEW]: [
		PermissionType.PROJECT_DUPLICATE,
		PermissionType.PROJECT_HISTORY,
		PermissionType.NSPROJECT_READ,
		PermissionType.COMMENT_CREATE,
		PermissionType.COMMENT_UPDATE,
		PermissionType.COMMENT_DELETE,
		PermissionType.COMMENT_GET_NOTIFICATIONS,
		PermissionType.MEMBER_READ,
		PermissionType.MEMBER_DELETE_OWN,
		PermissionType.WORKSPACE_READ,
		PermissionType.WORKSPACE_REORDER,
		PermissionType.WORKSPACE_BILLING_READ,
		PermissionType.FOLDER_READ,
		PermissionType.FOLDER_REORDER,
		PermissionType.USER_READ,
		PermissionType.TOKEN_READ,
		PermissionType.PERMISSIONS_USER_READ,
		PermissionType.PERMISSIONS_WORKSPACE_READ,
	],
	[Permission.EDIT]: [
		PermissionType.PROJECT_DUPLICATE,
		PermissionType.PROJECT_UPDATE,
		PermissionType.PROJECT_DELETE,
		PermissionType.PROJECT_HISTORY,
		PermissionType.PROJECT_CREATE,
		PermissionType.PROJECT_LIST,
		PermissionType.NSPROJECT_CREATE,
		PermissionType.NSPROJECT_READ,
		PermissionType.NSPROJECT_UPDATE,
		PermissionType.NSPROJECT_DELETE,
		PermissionType.COMMENT_CREATE,
		PermissionType.COMMENT_UPDATE,
		PermissionType.COMMENT_DELETE,
		PermissionType.COMMENT_GET_NOTIFICATIONS,
		PermissionType.MEMBER_READ,
		PermissionType.MEMBER_UPDATE,
		PermissionType.MEMBER_CREATE,
		PermissionType.MEMBER_DELETE_OWN,
		PermissionType.MEMBER_DELETE,
		PermissionType.MEMBER_LIST,
		PermissionType.WORKSPACE_READ,
		PermissionType.WORKSPACE_REORDER,
		PermissionType.WORKSPACE_UPDATE,
		PermissionType.WORKSPACE_DELETE,
		PermissionType.WORKSPACE_BILLING_READ,
		PermissionType.FOLDER_CREATE,
		PermissionType.FOLDER_READ,
		PermissionType.FOLDER_REORDER,
		PermissionType.FOLDER_UPDATE,
		PermissionType.FOLDER_LIST,
		PermissionType.USER_READ,
		PermissionType.USER_UPDATE,
		PermissionType.TOKEN_READ,
		PermissionType.TOKEN_UPDATE,
		PermissionType.TOKEN_DELETE,
		PermissionType.PERMISSIONS_USER_READ,
		PermissionType.PERMISSIONS_USER_UPDATE,
		PermissionType.PERMISSIONS_WORKSPACE_READ,
		PermissionType.PERMISSIONS_WORKSPACE_UPDATE,
	],
	[Permission.VIEW_2024]: [
		PermissionType.MEMBER_DELETE_OWN,
		PermissionType.WORKSPACE_READ,
		PermissionType.WORKSPACE_REORDER,
		PermissionType.PROJECT_PRIVATE_LINK_VIEW,
		PermissionType.NSPROJECT_READ,
		PermissionType.USER_READ,
		PermissionType.TOKEN_READ,
		PermissionType.PERMISSIONS_USER_READ,
		PermissionType.PERMISSIONS_WORKSPACE_READ,
	],
	[Permission.COLLABORATE_2024]: [
		PermissionType.PROJECT_PRIVATE_LINK_VIEW,
		PermissionType.PROJECT_LIST,
		PermissionType.NSPROJECT_READ,
		PermissionType.COMMENT_MODE,
		PermissionType.COMMENT_CREATE,
		PermissionType.COMMENT_UPDATE,
		PermissionType.COMMENT_GET_NOTIFICATIONS,
		PermissionType.COMMENT_RESOLVE,
		PermissionType.COMMENT_DELETE_OWN,
		PermissionType.MEMBER_READ,
		PermissionType.MEMBER_DELETE_OWN,
		PermissionType.MEMBER_LIST,
		PermissionType.WORKSPACE_READ,
		PermissionType.WORKSPACE_REORDER,
		PermissionType.FOLDER_READ,
		PermissionType.FOLDER_REORDER,
		PermissionType.FOLDER_LIST,
		PermissionType.USER_READ,
		PermissionType.TOKEN_READ,
		PermissionType.PERMISSIONS_USER_READ,
		PermissionType.PERMISSIONS_WORKSPACE_READ,
	],
	[Permission.EDIT_2024]: [
		PermissionType.PROJECT_DUPLICATE,
		PermissionType.PROJECT_UPDATE,
		PermissionType.PROJECT_ARCHIVE,
		PermissionType.PROJECT_PRIVATE_LINK_VIEW,
		PermissionType.PROJECT_IMPORT,
		PermissionType.PROJECT_CREATE,
		PermissionType.PROJECT_CLONE,
		PermissionType.PROJECT_HISTORY,
		PermissionType.PROJECT_LIST,
		PermissionType.NSPROJECT_CREATE,
		PermissionType.NSPROJECT_READ,
		PermissionType.NSPROJECT_UPDATE,
		PermissionType.COMMENT_MODE,
		PermissionType.COMMENT_CREATE,
		PermissionType.COMMENT_UPDATE,
		PermissionType.COMMENT_GET_NOTIFICATIONS,
		PermissionType.COMMENT_RESOLVE,
		PermissionType.COMMENT_DELETE_OWN,
		PermissionType.MEMBER_READ,
		PermissionType.MEMBER_DELETE_OWN,
		PermissionType.MEMBER_LIST,
		PermissionType.WORKSPACE_READ,
		PermissionType.WORKSPACE_REORDER,
		PermissionType.FOLDER_CREATE,
		PermissionType.FOLDER_REORDER,
		PermissionType.FOLDER_UPDATE,
		PermissionType.FOLDER_READ,
		PermissionType.FOLDER_LIST,
		PermissionType.USER_READ,
		PermissionType.TOKEN_READ,
		PermissionType.PERMISSIONS_USER_READ,
		PermissionType.PERMISSIONS_USER_UPDATE,
		PermissionType.PERMISSIONS_WORKSPACE_READ,
	],
	[Permission.MANAGE_2024]: [
		PermissionType.PROJECT_ARCHIVE,
		PermissionType.PROJECT_DELETE,
		PermissionType.PROJECT_CREATE,
		PermissionType.PROJECT_DUPLICATE,
		PermissionType.PROJECT_CLONE,
		PermissionType.PROJECT_CLONE_TOGGLE,
		PermissionType.PROJECT_UPDATE,
		PermissionType.PROJECT_IMPORT,
		PermissionType.PROJECT_EXPORT,
		PermissionType.PROJECT_EXPORT_TOGGLE,
		PermissionType.PROJECT_MOVE,
		PermissionType.PROJECT_HISTORY,
		PermissionType.PROJECT_PRIVATE_LINK_VIEW,
		PermissionType.PROJECT_PRIVATE_LINK_TOGGLE,
		PermissionType.PROJECT_LIST,
		PermissionType.NSPROJECT_CREATE,
		PermissionType.NSPROJECT_READ,
		PermissionType.NSPROJECT_UPDATE,
		PermissionType.NSPROJECT_DELETE,
		PermissionType.COMMENT_MODE,
		PermissionType.COMMENT_CREATE,
		PermissionType.COMMENT_UPDATE,
		PermissionType.COMMENT_GET_NOTIFICATIONS,
		PermissionType.COMMENT_DELETE,
		PermissionType.COMMENT_RESOLVE,
		PermissionType.MEMBER_READ,
		PermissionType.MEMBER_CREATE,
		PermissionType.MEMBER_UPDATE,
		PermissionType.MEMBER_DELETE_OWN,
		PermissionType.MEMBER_DELETE,
		PermissionType.MEMBER_LIST,
		PermissionType.WORKSPACE_READ,
		PermissionType.WORKSPACE_REORDER,
		PermissionType.WORKSPACE_UPDATE,
		PermissionType.FOLDER_CREATE,
		PermissionType.FOLDER_REORDER,
		PermissionType.FOLDER_DELETE,
		PermissionType.FOLDER_UPDATE,
		PermissionType.FOLDER_READ,
		PermissionType.FOLDER_LIST,
		PermissionType.USER_READ,
		PermissionType.USER_UPDATE,
		PermissionType.USER_DELETE,
		PermissionType.TOKEN_READ,
		PermissionType.TOKEN_UPDATE,
		PermissionType.TOKEN_DELETE,
		PermissionType.PERMISSIONS_USER_READ,
		PermissionType.PERMISSIONS_USER_UPDATE,
		PermissionType.PERMISSIONS_USER_DELETE,
		PermissionType.PERMISSIONS_WORKSPACE_READ,
		PermissionType.PERMISSIONS_WORKSPACE_UPDATE,
		PermissionType.PERMISSIONS_WORKSPACE_DELETE,
	],
	[Permission.OWNER]: [
		PermissionType.PROJECT_ARCHIVE,
		PermissionType.PROJECT_DELETE,
		PermissionType.PROJECT_CREATE,
		PermissionType.PROJECT_DUPLICATE,
		PermissionType.PROJECT_CLONE,
		PermissionType.PROJECT_CLONE_TOGGLE,
		PermissionType.PROJECT_UPDATE,
		PermissionType.PROJECT_IMPORT,
		PermissionType.PROJECT_EXPORT,
		PermissionType.PROJECT_EXPORT_TOGGLE,
		PermissionType.PROJECT_MOVE,
		PermissionType.PROJECT_HISTORY,
		PermissionType.PROJECT_PRIVATE_LINK_VIEW,
		PermissionType.PROJECT_PRIVATE_LINK_TOGGLE,
		PermissionType.PROJECT_LIST,
		PermissionType.NSPROJECT_CREATE,
		PermissionType.NSPROJECT_READ,
		PermissionType.NSPROJECT_UPDATE,
		PermissionType.NSPROJECT_DELETE,
		PermissionType.COMMENT_MODE,
		PermissionType.COMMENT_CREATE,
		PermissionType.COMMENT_UPDATE,
		PermissionType.COMMENT_GET_NOTIFICATIONS,
		PermissionType.COMMENT_DELETE,
		PermissionType.COMMENT_RESOLVE,
		PermissionType.MEMBER_READ,
		PermissionType.MEMBER_CREATE,
		PermissionType.MEMBER_UPDATE,
		PermissionType.MEMBER_DELETE_OWN,
		PermissionType.MEMBER_DELETE,
		PermissionType.MEMBER_LIST,
		PermissionType.WORKSPACE_READ,
		PermissionType.WORKSPACE_REORDER,
		PermissionType.WORKSPACE_UPDATE,
		PermissionType.WORKSPACE_CREATE,
		PermissionType.WORKSPACE_DELETE,
		PermissionType.WORKSPACE_UPGRADE,
		PermissionType.WORKSPACE_BILLING_READ,
		PermissionType.WORKSPACE_BILLING_EDIT,
		PermissionType.FOLDER_CREATE,
		PermissionType.FOLDER_REORDER,
		PermissionType.FOLDER_DELETE,
		PermissionType.FOLDER_UPDATE,
		PermissionType.FOLDER_READ,
		PermissionType.FOLDER_LIST,
		PermissionType.USER_READ,
		PermissionType.USER_UPDATE,
		PermissionType.USER_DELETE,
		PermissionType.TOKEN_READ,
		PermissionType.TOKEN_UPDATE,
		PermissionType.TOKEN_DELETE,
		PermissionType.PERMISSIONS_USER_READ,
		PermissionType.PERMISSIONS_USER_UPDATE,
		PermissionType.PERMISSIONS_USER_DELETE,
		PermissionType.PERMISSIONS_WORKSPACE_READ,
		PermissionType.PERMISSIONS_WORKSPACE_UPDATE,
		PermissionType.PERMISSIONS_WORKSPACE_DELETE,
	],
};

export type WorkspacePermissions = typeof rolePermissions;

export const hasDefaultPermission = (
	userRole: Permission,
	permission: PermissionType,
): boolean => rolePermissions[userRole].includes(permission);

export const getPlanPermission = (
	permissions: MembersPermissionSetProperty | undefined,
	permission: MemberPermission,
	// TODO remove the seat_ prefix
) => permissions?.[permission] || permissions?.[`seat_${permission}`];
