import styled, { css } from 'styled-components';
import { Modal } from '../_common';

export const ButtonWrapper = styled.div<{ outline?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	width: fit-content;
	height: 24px;
	white-space: nowrap;
	border-radius: 0.4rem;
	padding: 0 0.8rem;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme }) => theme.colors.c_light_grey};
	}
	${({ outline, theme }) =>
		outline &&
		css`
			border: 1px solid ${theme.colors.c_light_grey};
			border-radius: 20px;
			gap: 8px;
		`}
`;

export const Text = styled.span<{
	highlighted?: boolean;
	highlightVariant?: 'primary-gradient' | 'blue-gradient';
}>`
	display: flex;
	font-family: ${({ theme }) => theme.fontFamily};
	font-weight: ${({ theme }) => theme.fontWeights.font_weight_small};
	font-size: ${({ theme }) => theme.fontSize.font_size_small};
	color: ${({ theme }) => theme.colors.c_almost_black};

	${({ highlighted, theme }) =>
		highlighted &&
		css`
			align-items: center;
			background: linear-gradient(
				251.37deg,
				${theme.colors.c_dark_mode_primary} 0%,
				${theme.colors.c_light_mode_primary} 100%
			);
			border-radius: 4px;
			color: ${theme.colors.c_white};
			padding: 1px 4px;
			height: 16px;
			margin: 0px;
		`}

	${({ highlightVariant }) =>
		highlightVariant === 'blue-gradient' &&
		css`
			background: linear-gradient(225deg, #a4aaff 0%, #0010e5 100%);
		`}
`;

export const CarouselModal = styled(Modal)`
	padding: 0;
	border-radius: 8px;
`;

export const CarouselWrapper = styled.div`
	max-width: 384px;
`;

export const ModalButtons = styled.div`
	display: flex;
	margin-left: 24px;
	margin-right: 24px;
	margin-bottom: 24px;
	margin-top: 20px;

	& button + button {
		margin-left: 8px;
	}
`;

export const ProUpsellingButtons = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
`;
