import { colors } from '../../../styles';
import { VeLink } from '../_base';
import React from 'react';

export type CloseOrLogoutProps = {
	handleLogout: () => void;
};

export function CloseOrLogout(props: CloseOrLogoutProps) {
	const { handleLogout } = props;
	return (
		<>
			Feel free to close this message window once your email is successfully
			verified or{' '}
			<VeLink
				color={colors.c_mid_grey}
				onClick={handleLogout}
				underline="always"
			>
				log out
			</VeLink>{' '}
			and try again when you have made a mistake.
		</>
	);
}
