const analyticsID = "UA-55961095-1";
declare const _dcq: any;
export class GoogleAnalytics {

    private ga: any;

    constructor(userId: string) {
        // ga('create', 'UA-55961095-1', 'auto');
        this.ga = (self as any).ga;
        this.ga ? this.ga("create", analyticsID, userId) : null;
    }

    sendEvent(category: string, action: string, label: string): void {
        this.ga ? this.ga("send", "event", {
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
        }) : null;
    }

    setDimension(dimensionName: string, dimensionValue: string): void {
        this.ga ? this.ga("set", dimensionName, dimensionValue) : null;
    }

    sendPageview() {
        this.ga ? this.ga("send", "pageview") : null;
    }
}
