import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { Button as ButtonC } from '..';
import {
	$smallText,
	$bodyText,
	$bodyLineHeight,
	Muted,
} from '../_base/typography';

export const LinkUnderline = styled.a`
	${$bodyText}
	color: ${({ theme }) => theme.colors.c_mid_grey};
	text-decoration: underline;
`;

export const LogoC = styled.img`
	display: block;
	width: 32px;
	height: 26px;
	border: none;
`;

export const Logo = () => <LogoC src="./images/logo.svg" />;

export const GetProContent = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 20px;
	${$bodyText}
`;

export const GetProTitleBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
`;

export const GetProTitle = styled.h1`
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 100%;
	align-items: center;
	text-align: center;
	letter-spacing: -0.03em;
	color: ${({ theme }) => theme.colors.c_black};
`;

export const GetProSubtitle = styled.h2`
	color: ${({ theme }) => theme.colors.c_mid_grey};
`;

export const GetProHighlight = styled.span`
	color: ${({ theme }) => theme.colors.c_light_mode_primary};
`;

export const Check = styled.span`
	color: ${({ theme }) => theme.colors.c_light_mode_primary};
	display: inline-block;
	width: 1em;
	height: 1em;
	margin-right: 0.5em;
	background-image: url('./images/text-check-primary.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	vertical-align: text-bottom;
`;

export const TextList = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
	li {
		padding: 0;
		margin: 0;
	}
`;

export const ListItem: FC = ({ children }) => (
	<li>
		<Check />
		{children}
	</li>
);

export const ListItemMuted: FC = ({ children }) => (
	<li>
		<Muted>{children}</Muted>
	</li>
);

export const GetProButton = styled(ButtonC)`
	${$bodyText}
	padding: 8.5px 16px;
	border-radius: 8px;
`;

export const GetProButtonOutlined = styled(ButtonC)`
	${$bodyText}
	padding: 8.5px 16px;
	border-radius: 8px;
	background-color: #fff;
	color: ${({ theme }) => theme.colors.c_light_mode_primary};
	&:hover {
		color: #fff;
		background-color: ${({ theme }) => theme.colors.c_light_mode_primary};
	}
`;

// TODO move to theme
// export const $educationColor = '#14ae5c';

export const $educationColorLight = '#009c76';
export const $educationColorDark = '#31d0aa';

/* Button */

export const GetEducationPlanButton = styled(ButtonC)`
	${$bodyText}
	padding: 8.5px 16px;
	border-radius: 8px;
	border-color: transparent;
	background: linear-gradient(
			251.37deg,
			${$educationColorDark} 0%,
			${$educationColorLight} 100%
		),
		#ffffff;
	&:hover,
	&:focus {
		background: #fff;
		color: ${$educationColorDark};
		border-color: ${$educationColorDark};
	}
`;

export const GetProButtonBlack = styled(GetProButton)`
	background-color: ${({ theme }) => theme.colors.c_almost_black};
	border-color: ${({ theme }) => theme.colors.c_almost_black};
	&:hover,
	&:focus {
		border-color: ${({ theme }) => theme.colors.c_almost_black};
		color: ${({ theme }) => theme.colors.c_almost_black};
	}
`;

export const GetProButtonDefault = styled(ButtonC)`
	${$bodyText}
	padding: 8.5px 16px;
	border-radius: 8px;
	&,
	&:hover,
	&:focus {
		border: none;
		color: ${({ theme }) => theme.colors.c_almost_black};
		background-color: ${({ theme }) => theme.colors.c_light_grey};
	}
`;

export const GetProButtonsBlock = styled.div<{
	widthVariant?: 'auto' | 'full';
}>`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	${({ widthVariant }) =>
		widthVariant === 'full' &&
		css`
			width: 100%;
		`};
`;

export const RadioGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const RadioDot = styled.div`
	width: 24px;
	height: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const RadioItemDot = styled.span<{ active: boolean }>`
	display: inline-block;
	width: 9px;
	height: 9px;
	border-radius: 50%;
	flex: 0 0 auto;
	box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.c_mid_grey};
	${({ active }) =>
		active &&
		css`
			width: 10px;
			height: 10px;
			box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.c_soft_grey};
			background-color: ${({ theme }) => theme.colors.c_light_mode_primary};
		`};
`;

export const RadioItemButton = styled.div`
	padding: 0;
	margin: 0;
	&,
	&:focus {
		appearance: none;
		border: none;
		background: none;
	}
	display: flex;
	flex-direction: row;
	text-align: left;
	cursor: pointer;
`;

export const RadioItemTitleBlock = styled.div`
	${$bodyLineHeight}
	display: block;
	font-size: 18px;
	line-height: 136%;
	color: ${({ theme }) => theme.colors.c_black};
`;

export const RadioItemTitle = styled.div`
	margin-right: 10px;
	font-weight: 600;
`;

export const RadioItemBadge = styled.span`
	align-self: center;
	padding: 1px 4px;
	background: linear-gradient(0deg, #fa6c80, #fa6c80),
		linear-gradient(251.37deg, #9d50ff 0%, #6100ff 100%);
	border-radius: 4px;
	font-size: 11px;
	font-weight: 600;
	line-height: 130%;
	letter-spacing: 0;
	text-transform: uppercase;
	color: #fff;
`;

export const GetProBadge = styled.div`
	${$smallText}
	font-weight: 600;
	padding: 1px 15px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 4px;
	background: linear-gradient(0deg, #fa6c80 0%, #fa6c80 100%),
		linear-gradient(225deg, #9d50ff 0%, #6100ff 100%);
	color: ${({ theme }) => theme.colors.c_white};
	margin-bottom: -14px;
`;
