import { Permission } from '../interface/permissionRoles';

/**
 * Resolves given permission to a human-friendly, readable text.
 *
 * @param {Permission} permission - Permission to be resolved.
 * @returns {string} - Resolved text.
 */
export const getReadablePermission = (permission: Permission): string | undefined => {
	if (!permission) return undefined;
	const defaultPermissionName =
		permission.charAt(0).toUpperCase() + permission.toLowerCase().slice(1);
	switch (permission) {
		case Permission.EDIT_2024:
			return 'Editor';
		case Permission.VIEW_2024:
			return 'Viewer';
		case Permission.EDIT:
			return 'Editor (legacy)';
		case Permission.VIEW:
			return 'Viewer (legacy)';
		case Permission.OWNER:
			return 'Owner';
		case Permission.MANAGE_2024:
			return 'Manager';
		case Permission.COLLABORATE_2024:
			return 'Collaborator';
		default:
			return defaultPermissionName;
	}
};

