import { Cloud, Env, PROD_LIKE_ENVS } from '@vctr-libs/vctr-utils';

import { Plan, BillingPeriod } from './interface';

import {
	STARTER_10_product_list,
	LOCKED_product_list,
	PRO_2023_06_product_list,
	PRO_2022_product_list,
	ENTERPRISE_product_list,
	CUSTOM_product_list,
	STARTER_product_list,
	PRO_product_list,
	BUSINESS_product_list,
	FREE_product_list,
	PREMIUM_product_list,
	COMPANY_product_list,
	OLD_PREMIUM_product_list,
	OLD_UNLIMITTED_product_list,
	EDUCATION_product_list,
	SETUP_product_list,
	PRO_2024_06_product_list,
} from './product_list';
import { PlanIds } from './plans';

export const selectCloud = (cloud: Cloud) => {
	// Works for the internal LoadBalancer "process.env.VCTR_PLAN" and "apiUrl"
	for (const currentEnv of Object.values(Env)) {
		if (cloud.indexOf(currentEnv) >= 0) {
			return currentEnv;
		}
	}
	return Env.Prod;
};

// why `prod`, not `www`?
export const isProdPaddle = (cloud: Cloud) =>
	cloud === 'prod' || (PROD_LIKE_ENVS as Cloud[]).includes(cloud);

export const getPlans = (cloud: Cloud): Plan[] => {
	if (!Object.values(Env).includes(cloud as Env)) {
		cloud = selectCloud(cloud);
	}

	const prodPaddle = isProdPaddle(cloud);

	const activePlans: Plan[] = [
		{
			id: PlanIds.EDUCATION,
			name: 'Education',
			price: 0,
			billing: null,
			product_list: EDUCATION_product_list,
		},
		{
			id: PlanIds.SETUP,
			name: 'Setup',
			price: 0,
			billing: null,
			product_list: SETUP_product_list,
		},

		{
			id: PlanIds.LOCKED,
			name: 'Locked',
			price: 0,
			billing: null,
			product_list: LOCKED_product_list,
		},
		{
			id: PlanIds.PRO_2024_06_MONTHLY_29_TRIAL,
			name: 'Pro Trial',
			price: 29,
			billing: BillingPeriod.MONTH,
			product_list: PRO_2024_06_product_list,
			paddleId: prodPaddle ? '882769' : '69286',
		},
		{
			id: PlanIds.PRO_2024_06_YEARLY_288_TRIAL,
			name: 'Pro (Annual) Trial',
			price: 288,
			billing: BillingPeriod.YEAR,
			product_list: PRO_2024_06_product_list,
			paddleId: prodPaddle ? '882770' : '69287',
		},
		{
			id: PlanIds.PRO_2024_06_MONTHLY_29,
			name: 'Pro',
			price: 29,
			billing: BillingPeriod.MONTH,
			product_list: PRO_2024_06_product_list,
			paddleId: prodPaddle ? '882771' : '69288',
		},
		{
			id: PlanIds.PRO_2024_06_YEARLY_288,
			name: 'Pro (Annual)',
			price: 288,
			billing: BillingPeriod.YEAR,
			product_list: PRO_2024_06_product_list,
			paddleId: prodPaddle ? '882772' : '69289',
		},
		// Enterprise plan — subscriptions are handled by sales
		{
			id: PlanIds.ENTERPRISE,
			name: 'Enterprise',
			price: 0,
			billing: BillingPeriod.MONTH,
			product_list: ENTERPRISE_product_list,
			paddleId: prodPaddle ? '750924' : '741085',
		},
		{
			id: PlanIds.CUSTOM,
			name: 'Custom',
			price: 0,
			billing: BillingPeriod.MONTH,
			product_list: CUSTOM_product_list,
		},
	];

	const archivedPlans: Plan[] = [
		{
			id: PlanIds.STARTER_10,
			name: 'Starter',
			price: 0,
			billing: null,
			product_list: STARTER_10_product_list,
		},
		{
			id: PlanIds.PRO_2023_06_MONTHLY_19,
			name: 'Pro',
			price: 19,
			billing: BillingPeriod.MONTH,
			product_list: PRO_2023_06_product_list,
			paddleId: prodPaddle ? '836566' : '38601',
		},
		{
			id: PlanIds.PRO_2023_06_YEARLY_180,
			name: 'Pro (Annual)',
			price: 180,
			billing: BillingPeriod.YEAR,
			product_list: PRO_2023_06_product_list,
			paddleId: prodPaddle ? '836567' : '38602',
		},
		// Trial plan introduced in 2023-06
		{
			id: PlanIds.PRO_2023_06_MONTHLY_19_TRIAL,
			name: 'Pro Trial',
			price: 19,
			billing: BillingPeriod.MONTH,
			product_list: PRO_2023_06_product_list,
			paddleId: prodPaddle ? '833761' : '52953',
		},
		// Trial plan introduced in 2023-06
		{
			id: PlanIds.PRO_2023_06_YEARLY_180_TRIAL,
			name: 'Pro (Annual) Trial',
			price: 180,
			billing: BillingPeriod.YEAR,
			product_list: PRO_2023_06_product_list,
			paddleId: prodPaddle ? '833762' : '52954',
		},
		{
			id: PlanIds.STARTER_25,
			name: 'Starter',
			price: 0,
			billing: null,
			product_list: STARTER_product_list,
		},
		{
			id: PlanIds.PRO_19,
			name: 'Pro',
			price: 19,
			billing: BillingPeriod.MONTH,
			product_list: PRO_2022_product_list,
			paddleId: prodPaddle ? '797943' : '38601',
		},
		{
			id: PlanIds.PRO_180_YEARLY,
			name: 'Pro (Annual)',
			price: 180,
			billing: BillingPeriod.YEAR,
			product_list: PRO_2022_product_list,
			paddleId: prodPaddle ? '797946' : '38602',
		},
		{
			id: PlanIds.PRO,
			name: 'Pro',
			price: 12,
			billing: BillingPeriod.MONTH,
			product_list: PRO_product_list,
			paddleId: prodPaddle ? '750925' : '30513',
		},
		{
			id: PlanIds.PRO_YEARLY,
			name: 'Pro yearly',
			price: 99,
			billing: BillingPeriod.YEAR,
			product_list: PRO_product_list,
			paddleId: prodPaddle ? '750926' : '30545',
		},
		{
			id: PlanIds.BUSINESS,
			name: 'Business',
			price: 250,
			billing: BillingPeriod.MONTH,
			product_list: BUSINESS_product_list,
			paddleId: prodPaddle ? '757163' : '758059',
		},
		// {
		// 	id: PlanIds.BUSINESS_YEARLY,
		// 	name: 'Business',
		// 	price: 3000,
		// 	billing: BillingPeriod.YEAR,
		// 	product_list: BUSINESS_product_list,
		// 	paddleId: prodPaddle ? '757164' : '758060',
		// },

		// Old plans
		{
			id: PlanIds.FREE,
			name: 'Free',
			price: 0,
			billing: null,
			product_list: FREE_product_list,
		},
		{
			id: PlanIds.PREMIUM,
			name: 'Premium',
			price: 12,
			billing: BillingPeriod.MONTH,
			product_list: PRO_product_list,
			paddleId: prodPaddle ? '633635' : '633645',
			default: true,
		},
		{
			id: PlanIds.PREMIUM_YEARLY,
			name: 'Premium yearly',
			price: 99,
			billing: BillingPeriod.YEAR,
			product_list: PRO_product_list,
			paddleId: prodPaddle ? '633636' : '633646',
			default: true,
		},
		{
			id: PlanIds.COMPANY,
			name: 'Company',
			price: 0,
			billing: BillingPeriod.MONTH,
			product_list: COMPANY_product_list,
			paddleId: prodPaddle ? '594022' : '635426',
		},
		{
			id: PlanIds.INVOICE,
			name: 'Enterprise',
			price: 0,
			billing: BillingPeriod.MONTH,
			product_list: COMPANY_product_list,
		},
		{
			id: PlanIds.LINKED,
			name: 'Linked',
			price: 0,
			billing: BillingPeriod.MONTH,
			product_list: FREE_product_list,
			paddleId: prodPaddle ? '750927' : '631351',
		},
		// {
		// 	id: PlanIds.LINKED_OLD,
		// 	name: 'Linked',
		// 	price: 0,
		// 	billing: BillingPeriod.MONTH,
		// 	product_list: FREE_product_list,
		// 	paddleId: prodPaddle ? '639565' : '631351',
		// },
		// Older plans
		{
			id: PlanIds.LITE_9,
			name: 'Lite',
			price: 9,
			billing: BillingPeriod.MONTH,
			product_list: PREMIUM_product_list,
			paddleId: prodPaddle ? '584280' : '584085',
		},
		{
			id: PlanIds.LITE_9_YEARLY,
			name: 'Lite yearly',
			price: 90,
			billing: BillingPeriod.YEAR,
			product_list: PREMIUM_product_list,
			paddleId: prodPaddle ? '584281' : '584275',
		},
		{
			id: PlanIds.PREMIUM_25,
			name: 'Premium 25$',
			price: 25,
			billing: BillingPeriod.MONTH,
			product_list: OLD_PREMIUM_product_list,
			paddleId: prodPaddle ? '580487' : '580469',
		},
		{
			id: PlanIds.PREMIUM_25_YEARLY,
			name: 'Premium 25$ yearly',
			price: 210,
			billing: BillingPeriod.YEAR,
			product_list: OLD_PREMIUM_product_list,
			paddleId: prodPaddle ? '580488' : '580470',
		},
		{
			id: PlanIds.PREMIUM_15,
			name: 'Premium 15$',
			price: 15,
			billing: BillingPeriod.MONTH,
			product_list: OLD_PREMIUM_product_list,
			paddleId: prodPaddle ? '571529' : '571440',
		},
		{
			id: PlanIds.PREMIUM_15_YEARLY,
			name: 'Premium 15$ yearly',
			price: 144,
			billing: BillingPeriod.YEAR,
			product_list: OLD_PREMIUM_product_list,
			paddleId: prodPaddle ? '571531' : '571441',
		},
		{
			id: PlanIds.PREMIUM_12,
			name: 'Premium 12$',
			price: 12,
			billing: BillingPeriod.MONTH,
			product_list: OLD_PREMIUM_product_list,
			paddleId: prodPaddle ? '565048' : '565047',
		},
		{
			id: PlanIds.PREMIUM_1,
			name: 'Premium 9$',
			price: 9,
			billing: BillingPeriod.MONTH,
			product_list: OLD_PREMIUM_product_list,
			paddleId: prodPaddle ? '552550' : '558894',
		},
		{
			id: PlanIds.VECTARY_1, // e.g. Belanec
			name: '+Company vectary',
			price: 199,
			billing: BillingPeriod.MONTH,
			product_list: OLD_UNLIMITTED_product_list,
			paddleId: prodPaddle ? '555663' : '558899',
		},
	];

	return [...activePlans, ...archivedPlans];
};
