import { getHubspotFormDataFromFields } from './hubspot/hubspot';
import * as Sentry from '@sentry/browser';
import { pushGTMEvent } from './lib';
import { clearbitIdentify } from '@vctr-libs/vctr-integrations';

const TEXT_INPUTS = [
	...document.querySelectorAll<HTMLInputElement>(
		'[js="form-content"] [type=text]',
	),
	...document.querySelectorAll<HTMLInputElement>(
		'[js="form-content"] [type=email]',
	),
	...document.querySelectorAll<HTMLInputElement>(
		'[js="form-content"] textarea',
	),
];
const CHECKBOX_INPUTS = [
	...document.querySelectorAll<HTMLInputElement>(
		'[js="form-content"] [type=checkbox]',
	),
];
const CHECKBOX_DIV_INPUTS = [
	...document.querySelectorAll<HTMLInputElement>(
		'[js="form-content"] .w-checkbox-input',
	),
];
const SUBMIT_BUTTON = document.querySelector<HTMLInputElement>(
	'[js="form-content"] input[type=submit]',
);
const SUCCESS_BLOCK = document.querySelector<HTMLElement>(
	'[js="form-success"]',
);
const ERROR_BLOCK = document.querySelector<HTMLElement>('[js="form-error"]');

function setDisableInputs(isDisabled: boolean) {
	const inputs: HTMLInputElement[] = [
		...TEXT_INPUTS,
		...CHECKBOX_INPUTS,
		SUBMIT_BUTTON,
	];

	inputs.forEach(input => {
		input.disabled = isDisabled;
	});
}

function clearForm() {
	TEXT_INPUTS.forEach(input => {
		input.value = null;
	});

	CHECKBOX_INPUTS.forEach(checkbox => {
		checkbox.checked = false;
	});

	CHECKBOX_DIV_INPUTS.forEach(div => {
		const isCheckedClass = 'w--redirected-checked';
		div.classList.remove(isCheckedClass);
	});
}

function setState({
	showSubmit,
	showSuccess,
	showError,
}: {
	showSubmit: boolean;
	showSuccess: boolean;
	showError: boolean;
}) {
	SUBMIT_BUTTON.style.display = showSubmit ? 'block' : 'none';
	SUCCESS_BLOCK.style.display = showSuccess ? 'block' : 'none';
	ERROR_BLOCK.style.display = showError ? 'block' : 'none';
}

function showSubmit() {
	setState({ showSubmit: true, showSuccess: false, showError: false });
}

function showSuccess() {
	setState({ showSubmit: false, showSuccess: true, showError: false });
}

function showError() {
	setState({ showSubmit: false, showSuccess: false, showError: true });
}

const TOPIC_TO_HUBSPOT_TOPIC: { [key: string]: string } = {
	'request-sales-demo-or-talk-to-sales':
		'Request a sales demo or talk to sales',
	'billing-and-invoicing': 'Billing and invoicing',
	'technical-and-product-support': 'Technical and product support',
	'partnerships': 'Partnerships',
	'educators-and-students': 'Educators and students',
	'team-workspace-request': 'Team workspace - request',
	'industry-google-ad': 'Industry Google Ad',
	'abm-cycling-linkedin-ad': 'ABM Cycling LinkedIn Ad',
	'abm-automotive-page': 'ABM Automotive Page',
};

const TEAM_SIZE_TO_HUBSPOT_TEAM_SIZE: { [key: string]: string } = {
	'small-2-100': 'small (2-100)',
	'medium-100-500': 'medium (100-500)',
	'large-+500': 'large (+500)',
};

function getTopic(value: string) {
	return TOPIC_TO_HUBSPOT_TOPIC[value] || value;
}

function getTeamSize(value: string) {
	return TEAM_SIZE_TO_HUBSPOT_TEAM_SIZE[value] || value;
}

// spam protection by checking if the hidden fields are filled which user don't see
export function honeypotSpamCheck(): boolean {
	const organizationAddress = document.querySelector<HTMLInputElement>(
		'#organisation-home-field',
	)?.value;
	const urgentChecker = document.querySelector<HTMLInputElement>(
		'#urgent-checkbox input',
	)?.checked;

	const isFilledHiddenFields = organizationAddress !== '' || urgentChecker;
	if (isFilledHiddenFields) {
		document
			.querySelector('.error-message')
			?.setAttribute('style', 'display: block;');
		document.querySelector('input[type="submit"]')?.remove();
	}

	return isFilledHiddenFields;
}

export async function initBusinessContactForm() {
	const form = document.querySelector('[js="form-content"]');
	if (!form) return;

	form.addEventListener('submit', async event => {
		event.preventDefault();
		setDisableInputs(true);

		if (honeypotSpamCheck()) {
			return;
		}

		const firstName =
			document.querySelector<HTMLInputElement>('#first-name')?.value;
		const lastName =
			document.querySelector<HTMLInputElement>('#last-name')?.value;
		// renamed input name to prevent spam
		const email = document.querySelector<HTMLInputElement>(
			'#phone-number-field',
		)?.value;
		const message = document.querySelector<HTMLInputElement>('#message')?.value;
		const organizationName =
			document.querySelector<HTMLInputElement>('#organisation-name')?.value;
		const jobTitle =
			document.querySelector<HTMLInputElement>('#job-title')?.value;
		const topicKey = document.querySelector<HTMLInputElement>('#topic')?.value;
		const teamSize =
			document.querySelector<HTMLInputElement>('#team-size')?.value;

		let hubspotCookie: string = '';
		try {
			// @ts-ignore
			const cookies = await cookieStore.getAll();
			// hubspotutk is a Hubspot cookie we need to include into the form data
			// to support action history for contacts
			hubspotCookie = cookies.filter((c: any) => c.name === 'hubspotutk')[0]
				?.value;
		} catch (e) {
			console.log('Error getting browser cookies', e);
		}

		const baseFields = {
			email,
			firstName,
			lastName,
			organizationName,
			jobTitle,
			topic: getTopic(topicKey),
			teamSize: getTeamSize(teamSize),
		};
		const formData = getHubspotFormDataFromFields({
			...baseFields,
			message,
			hutk: hubspotCookie,
		});

		const url =
			'https://api.hsforms.com/submissions/v3/integration/submit/24079192/d5f32519-82e6-48e9-9be0-a3dd76e20b5b';
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const requestOptions = {
			method: 'POST',
			headers,
			body: JSON.stringify(formData),
		};

		await fetch(url, requestOptions)
			.then(async function (response) {
				const data = await response.json();

				if (data.status === 'error') {
					console.log(JSON.stringify(data?.errors));
					throw new Error(`Error posting Hubspot form data: ${message}`);
				}

				console.log(data);
				submitGTMEvent(topicKey);
				clearbitIdentify(data.email, {
					...baseFields,
					name: `${baseFields.firstName} ${baseFields.lastName}`,
				});
				setDisableInputs(false);
				showSuccess();
				clearForm();
			})
			.catch(function (error) {
				console.log('Error submitting enterprise contact form', error);
				setDisableInputs(false);
				showError();
				Sentry.captureException('Error submitting enterprise contact form');
			});
	});

	TEXT_INPUTS.forEach(input => {
		input.onclick = () => {
			showSubmit();
		};
	});
}

function submitGTMEvent(topic: string) {
	if (topic === 'industry-google-ad') {
		pushGTMEvent({ event: 'generate_lead', lead_source: 'industry-page' });
	}
}
