import React, { FC } from 'react';
import { ButtonWrapper, Text, DiscoverProButtonProps } from '..';

export const GetProForEducatorsButton: FC<DiscoverProButtonProps> = props => {
	const { handleButtonClick } = props;
	return (
		<ButtonWrapper onClick={handleButtonClick} outline={true}>
			<Text>Request</Text>
			<Text highlighted={true} highlightVariant={'blue-gradient'}>
				Education plan
			</Text>
		</ButtonWrapper>
	);
};
