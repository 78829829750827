export type HSBColor = { hue: number, saturation: number, brightness: number };

export function convertRGBToHSB(rgb: number[]): HSBColor {
    const rgb_255 = { r: rgb[0] * 255, g: rgb[1] * 255, b: rgb[2] * 255 };
    const hsb: HSBColor = { hue: 0, saturation: 0, brightness: 0 };
    const min = Math.min(rgb_255.r, rgb_255.g, rgb_255.b);
    const max = Math.max(rgb_255.r, rgb_255.g, rgb_255.b);
    const delta = max - min;

    hsb.brightness = max;
    hsb.saturation = max !== 0 ? 255 * delta / max : 0;

    if (hsb.saturation !== 0) {
        if (rgb_255.r === max) {
            hsb.hue = (rgb_255.g - rgb_255.b) / delta;
        } else if (rgb_255.g === max) {
            hsb.hue = 2 + (rgb_255.b - rgb_255.r) / delta;
        } else {
            hsb.hue = 4 + (rgb_255.r - rgb_255.g) / delta;
        }
        hsb.hue *= 60;
    } else {
        hsb.hue = -125;
    }

    if (hsb.hue < 0) {
        hsb.hue += 360;
    }
    hsb.saturation *= 100 / 255;
    hsb.brightness *= 100 / 255;

    return hsb;
}

export function convertHSBToRGB(hsb: HSBColor, normalize: boolean = false): number[] {
    const rgb = { r: 0, g: 0, b: 0 };
    const h = hsb.hue;
    const s = hsb.saturation * 255 / 100;
    const v = hsb.brightness * 255 / 100;
    if (s === 0) {
        rgb.r = rgb.g = rgb.b = v;
    } else {
        const t1 = v;
        const t2 = (255 - s) * v / 255;
        const t3 = (t1 - t2) * (h % 60) / 60;
        if (h < 60 || h === 360) {
            rgb.r = t1; rgb.b = t2; rgb.g = t2 + t3;
        } else if (h < 120) {
            rgb.g = t1; rgb.b = t2; rgb.r = t1 - t3;
        } else if (h < 180) {
            rgb.g = t1; rgb.r = t2; rgb.b = t2 + t3;
        } else if (h < 240) {
            rgb.b = t1; rgb.r = t2; rgb.g = t1 - t3;
        } else if (h < 300) {
            rgb.b = t1; rgb.g = t2; rgb.r = t2 + t3;
        } else if (h < 360) {
            rgb.r = t1; rgb.g = t2; rgb.b = t1 - t3;
        } else {
            rgb.r = 0; rgb.g = 0; rgb.b = 0;
        }
    }
    const result = [Math.round(rgb.r), Math.round(rgb.g), Math.round(rgb.b)];
    if (normalize) return result.map(channel => channel / 255);
    return result;
}

export const convertRGBToHEX = (rgb: number[]): string => {
    const hex = [
        Math.round(rgb[0] * 255).toString(16),
        Math.round(rgb[1] * 255).toString(16),
        Math.round(rgb[2] * 255).toString(16)
    ];

    for (let i = 0; i < 3; i++) {
        if (hex[i].length === 1) {
            hex[i] = `0${hex[i]}`;
        }
    }
    return `${hex.join("")}`; // No #
}

export const parseHEXValue = (hexString: string): string => {
    let value = ""; // No #
    hexString = hexString.split("#").join("");
    if (hexString.length === 1) {
        value = value + hexString.repeat(6);
    } else if (hexString.length === 2) {
        value = value + hexString.repeat(3);
    } else if (hexString.length === 3) {
        value = value + hexString[0].repeat(2) + hexString[1].repeat(2) + hexString[2].repeat(2);
    } else if (hexString.length < 6) {
        value = null;
    } else {
        value = value + hexString.slice(0, 6);
    }

    return value;
}

export const convertHEXToRGB = (hex: string): number[] => {

    const parsedHex = parseHEXValue(hex);
    if (!parsedHex) return null;

    const hexAsNumber = parseInt(((hex.indexOf("#") > -1) ? hex.substring(1) : hex), 16);
    return [
        (hexAsNumber >> 16),
        ((hexAsNumber & 0x00FF00) >> 8),
        (hexAsNumber & 0x0000FF)
    ];
}

export const convertRGBToLuminance = (rgb: number[]) => {
    // https://www.w3.org/TR/AERT/#color-contrast
    return 0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2];
}

export const compareHSBColors = (hsb1: HSBColor, hsb2: HSBColor): boolean => {
    return (hsb1.hue === hsb2.hue && hsb1.saturation === hsb2.saturation && hsb1.brightness === hsb2.brightness);
}

/*********************************/
/*            FILTERS            */
/*********************************/

export enum FilterType {
    NONE = "none",

    BASE_COLOR = "baseColor",
    EMISSION = "emission",
    LIGHT_MAP = "lightmap",
    REFLECTIVITY = "reflectivity",
    THINFILM = "thinfilm",

    OPACITY = "opacity", // CHANNEL_A = "channelA" or BRIGHTNESS = "brightness"

    FLIP_G = "flipG",

    CHANNEL_R = "channelR",
    CHANNEL_G = "channelG",
    CHANNEL_B = "channelB",
}

export const createFilterMatrix = (): number[] => {
    const matrixValues = new Array(4 * 5).fill(0);
    matrixValues[18] = 1;
    return matrixValues;
}

export const filterBrightness = (): number[] => {
    const matrixValues = createFilterMatrix();
    for (let i = 0; i < 3; i++) {
        matrixValues[0 + i * 5] = 0.299;
        matrixValues[1 + i * 5] = 0.587;
        matrixValues[2 + i * 5] = 0.114;
    }
    return matrixValues;
}

export const filterChannel = (filter: "channelR" | "channelG" | "channelB" | "channelA"): number[] => {
    let channel: number;
    switch (filter) {
        case "channelR": channel = 0; break;
        case "channelG": channel = 1; break;
        case "channelB": channel = 2; break;
        case "channelA": channel = 3; break;
    }
    const matrixValues = createFilterMatrix();
    for (let i = 0; i < 3; i++) {
        matrixValues[channel + i * 5] = 1;
    }
    return matrixValues;
}

export const mixWithColor = (color: { r: number, g: number, b: number }): number[] => {
    const matrixValues = createFilterMatrix();
    matrixValues[0] = Math.pow(color.r, 2.2);
    matrixValues[6] = Math.pow(color.g, 2.2);
    matrixValues[12] = Math.pow(color.b, 2.2);

    return matrixValues;
}

export const flipG = (): number[] => {
    const matrixValues = createFilterMatrix();
    matrixValues[0] = 1;
    matrixValues[6] = -1;
    matrixValues[9] = 0.5;
    matrixValues[12] = 1;

    return matrixValues;
}
