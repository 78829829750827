import React from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import useId from '@mui/material/utils/useId';

interface VeSelectProps {
	name: string;
	label: string;
	required?: boolean;
	options: { label: string; value: string }[];
	value?: string;
	disabled?: boolean;
	defaultValue?: string;
	onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
}

export function VeSelect({
	name,
	label,
	options,
	value,
	disabled,
	defaultValue,
	onChange,
	required = true,
}: VeSelectProps) {
	const ID = useId();

	return (
		<FormControl
			size="small"
			margin="normal"
			fullWidth
			variant="filled"
			required={required}
			disabled={disabled}
		>
			<InputLabel id={'option-label-' + ID}>{label}</InputLabel>
			<Select
				name={name}
				labelId={'option-label-' + ID}
				required={required}
				value={value}
				disableUnderline
				defaultValue={defaultValue}
				onChange={onChange}
			>
				{options.map(({ label, value }) => (
					<MenuItem key={value} value={value}>
						{label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
