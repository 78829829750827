import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

export function VeTypography({
	...props
}: TypographyProps & { component?: React.ElementType }) {
	const color =
		`${props.component}`.indexOf('h') === 0 ? undefined : 'text.secondary';

	return <Typography color={color} {...props}></Typography>;
}
