import React from 'react';
import { LinkUnderline } from './styledComponents';
import {
	VeSignupinContainer,
	VeBox,
	VeTypography,
	VeText,
	VeButton,
} from '../material-ui';

interface GiveProAccountsContentProps {
	handleClose: () => void;
	handleSubmit: (event: React.FormEvent) => void;
	userEmail: string;
}

export function GiveProAccountsContent({
	handleClose,
	handleSubmit,
	userEmail,
}: GiveProAccountsContentProps) {
	return (
		<VeSignupinContainer htmlFontSize={10}>
			{/* TODO htmlFontSize is bad, we need to follow a design system and global typography styles */}
			<VeBox mb={2}>
				<VeTypography align="center" variant="h4" component="h1" mb={2}>
					Give Pro accounts to your classroom
				</VeTypography>
				<VeTypography component="p" color="text.primary" align="center">
					Discover limitless creativity with Vectary's Education Plan for both
					educators and students!
				</VeTypography>
			</VeBox>

			<form onSubmit={handleSubmit}>
				<VeText name="name" label="Your name..." type="text" />

				<VeText name="role" label="Your role..." type="text" />

				<VeText
					name="institution"
					label="Educational institution name..."
					type="text"
				/>

				<VeText
					name="description"
					label="Describe your needs..."
					type="text"
					multiline={true}
					helperText="Please provide a detailed description of your needs, including the approximate size of your school or the number of students you work with..."
					maxRows={4}
				/>

				<VeButton variant="primary" type="submit">
					Send request
				</VeButton>
			</form>

			<VeTypography
				mb={2}
				component="p"
				align="center"
				color="text.primary"
				fontSize="11px"
			>
				You are logged in as{' '}
				<VeTypography
					component="span"
					fontWeight="700"
					color="text.primary"
					fontSize="11px"
				>
					{userEmail}
				</VeTypography>
			</VeTypography>

			<VeBox textAlign={'center'}>
				<LinkUnderline onClick={handleClose}>Maybe later</LinkUnderline>
			</VeBox>
		</VeSignupinContainer>
	);
}
