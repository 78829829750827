const sampleHubspotFormData = {
	fields: [
		{
			objectTypeId: '0-1',
			name: 'email',
			value: 'example@example.com',
		},
		{
			objectTypeId: '0-1',
			name: 'firstname',
			value: 'First Name',
		},
		{
			// https://developers.hubspot.com/docs/api/crm/crm-custom-objects
			objectTypeId: '0-2',
			name: 'name',
			value: 'Company name',
		},
		{
			objectTypeId: '0-1',
			name: 'organisation_name',
			// (same as company	name)
			value: 'Organisation name',
		},
		{
			objectTypeId: '0-1',
			name: 'topic',
			// see vectary.com/business-contact
			value: 'Technical and product support',
		},
		{
			objectTypeId: '0-1',
			name: 'source',
			value: 'test source',
		},
		{
			objectTypeId: '0-1',
			name: 'team_size',
			value: 'small (2-100)',
		},
	],
	context: {
		// hutk is Hubspot cookie, optional
		// "hutk": ":hutk",
		pageUri: 'vectary.com/business-contact',
		pageName: 'Vectary Business | Contact',
	},
};

/**
 * Hubspot form object type id
 * Contact: 0-1
 * Company: 0-2
 * Deal: 0-3
 * Ticket: 0-5
 */
type HubspotFormObjectTypeId = '0-1' | '0-2' | '0-3' | '0-5';
type HubspotFormField = {
	objectTypeId: HubspotFormObjectTypeId;
	name: string;
	value: string;
};

export type HubspotFormData = typeof sampleHubspotFormData & {
	fields: HubspotFormField[];
	context: {
		hutk?: string;
	};
};

type GetHubspotFormDataFromFieldsArgs = {
	email: string;
	firstName: string;
	lastName: string;
	organizationName: string;
	jobTitle: string;
	topic: string;
	message: string;
	hutk?: string;
	teamSize?: string;
};
/**
 * Prepare Hubspot form payload from html form data
 */
export function getHubspotFormDataFromFields(
	args: GetHubspotFormDataFromFieldsArgs,
): HubspotFormData {
	const {
		email,
		firstName,
		lastName,
		organizationName,
		jobTitle,
		topic,
		message,
		hutk = undefined,
		teamSize = undefined,
	} = args;
	let data: HubspotFormData = {
		fields: [
			{
				objectTypeId: '0-1',
				name: 'email',
				value: email,
			},
			{
				objectTypeId: '0-1',
				name: 'firstname',
				value: firstName,
			},
			{
				objectTypeId: '0-1',
				name: 'lastname',
				value: lastName,
			},
			{
				// https://developers.hubspot.com/docs/api/crm/crm-custom-objects
				objectTypeId: '0-2',
				name: 'name',
				value: organizationName,
			},
			{
				objectTypeId: '0-1',
				name: 'organisation_name',
				// (same as company	name)
				value: organizationName,
			},
			{
				objectTypeId: '0-1',
				name: 'jobtitle',
				value: jobTitle,
			},
			{
				objectTypeId: '0-1',
				name: 'topic',
				// see vectary.com/business-contact
				value: topic,
			},
			{
				objectTypeId: '0-1',
				name: 'message',
				value: message,
			},
		],
		context: {
			pageUri: `${location.host}${location.pathname}`,
			pageName: document.title,
		},
	};
	if (hutk && hutk.length > 0) {
		data.context.hutk = hutk;
	}
	if (teamSize) {
		data.fields.push({
			objectTypeId: '0-1',
			name: 'team_size',
			value: teamSize,
		} as HubspotFormField);
	}
	return data;
}
