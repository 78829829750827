import React, { FC, Fragment } from 'react';
import { Plan, BillingPeriod, CURRENT_PLANS } from '@vctr-libs/vctr-api-client';
import {
	Muted,
	Highlight,
	TermsText,
	Button,
	ButtonEnumVariant,
	Select,
	getSaveOnYearly,
	ButtonEnumType,
	GetProComponentStates,
	RadioItemBadge,
	StudentDiscount,
} from '..';
import {
	Mark,
	SelectWrapper,
	SelectWrapperEmpty,
	SelectWrapperEmptyText,
	SubscriptionDetailsList,
	Title,
	UpgradeButton,
} from './styledComponents';

export const PriceInfoText = (plan: Plan) => (
	<Fragment>
		<Mark>Billing</Mark>${plan.price}/{plan.billing.toLowerCase()}
	</Fragment>
);

export const RenewalText: FC<{ renewalDate: Date }> = ({ renewalDate }) => (
	<Fragment>
		{renewalDate && (
			<li>Your plan renews on {renewalDate.toLocaleDateString()}</li>
		)}
	</Fragment>
);

export const SubscriptionDetails: FC<{ plan: Plan }> = ({
	plan,
	plans,
}: {
	plan: Plan;
	plans: Plan[];
}) => {
	const renewalDate = new Date();
	if (plan.billing === BillingPeriod.MONTH) {
		renewalDate.setMonth(renewalDate.getMonth() + 1);
	}
	if (plan.billing === BillingPeriod.YEAR) {
		renewalDate.setFullYear(renewalDate.getFullYear() + 1);
	}

	const saveOnYearly = getSaveOnYearly({
		plans,
		// monthlyPlanId: CURRENT_PLANS.PRO_MONTHLY,
		// yearlyPlanId: CURRENT_PLANS.PRO_YEARLY,
	});

	switch (plan.id) {
		// TODO add/use isMonthly (?)
		case CURRENT_PLANS.PRO_MONTHLY:
		case CURRENT_PLANS.PRO_MONTHLY_TRIAL:
			return (
				<SubscriptionDetailsList>
					{saveOnYearly ? (
						<li>
							<Highlight>Save {`$${saveOnYearly}`}</Highlight> if you change to
							Annual billing
						</li>
					) : (
						// empty item to keep height the same when switching between monthly and yearly
						<li style={{ listStyle: 'none' }}>{` `}</li>
					)}
					<RenewalText renewalDate={renewalDate} />
				</SubscriptionDetailsList>
			);
		// TODO add/use isMonthly/isYearly (?)
		case CURRENT_PLANS.PRO_YEARLY:
		case CURRENT_PLANS.PRO_YEARLY_TRIAL:
			return (
				<SubscriptionDetailsList>
					<RenewalText renewalDate={renewalDate} />
				</SubscriptionDetailsList>
			);
		default:
			return null;
	}
};

export type WorkspaceOptions = {
	title: string;
	value: string;
};

export type WorkspaceToUpgradeSelectProps = {
	workspacesOptions: WorkspaceOptions[];
	handleOnWorkspaceChange: (selectedValue: string) => void;
	value: string;
};

export const WorkspaceToUpgradeSelect: FC<
	WorkspaceToUpgradeSelectProps
> = props => {
	const { workspacesOptions, handleOnWorkspaceChange, value } = props;
	return (
		<SelectWrapper>
			<div>Select workspace:</div>
			<Select
				options={workspacesOptions}
				onChange={handleOnWorkspaceChange}
				value={value}
			/>
		</SelectWrapper>
	);
};

export const EmptyWorkspaces: FC<{ handleClose: () => void }> = ({
	handleClose,
}) => {
	return (
		<Fragment>
			<Title>No workspaces to&nbsp;upgrade!</Title>
			<SelectWrapperEmpty>
				<SelectWrapperEmptyText>
					Create a new workspace first, using the plus button under the
					workspace square icons in the upper left-hand corner of&nbsp;your
					Vectary Dashboard area, then upgrade.
				</SelectWrapperEmptyText>
				<Button onClick={handleClose} variant={ButtonEnumVariant.DEFAULT}>
					Go to Dashboard
				</Button>
			</SelectWrapperEmpty>
		</Fragment>
	);
};

export const TermsBlock = () => (
	<TermsText>
		<Muted>
			By continuing, you agree to the{' '}
			<a href="/terms-of-service" target="_blank">
				Terms of Use
			</a>{' '}
			applicable to Vectary Pro subscription and confirm you have read our{' '}
			<a href="/privacy-policy" target="_blank">
				Privacy Policy
			</a>
			.
		</Muted>
	</TermsText>
);

type DiscountBadgeProps = {
	isStudentDiscountAllowed: boolean;
	studentDiscount: StudentDiscount;
	sortedPlanList?: Plan[];
	couponCode?: string;
};

export const DiscountBadge = (props: DiscountBadgeProps) => {
	const {
		isStudentDiscountAllowed,
		studentDiscount,
		// sortedPlanList,
		// couponCode,
	} = props;

	return (
		<RadioItemBadge>
			{/* {couponCode !== studentDiscount.COUPON_CODE_FLAT && */}
			{isStudentDiscountAllowed && studentDiscount.REAL_VALUE_FLAT ? (
				<>Student discount $249 off</>
			) : (
				// <>Best value — save ${price}</>
				<>Best value</>
			)}
		</RadioItemBadge>
	);
};

type UpgradeBlockProps = {
	selectedPlan: Plan;
	handleClickUpgrade: (plan: Plan) => void;
	handleClosePayments: () => void;
	componentState: GetProComponentStates;
	showMaybeLater?: boolean;
};

export const UpgradeBlock = (props: UpgradeBlockProps) => {
	const {
		selectedPlan,
		handleClickUpgrade,
		componentState,
		showMaybeLater = true,
	} = props;
	return (
		<>
			{/* TODO: Two paths for students */}
			{/* 1. standard trial - no changes */}
			{/* 2. PRO with 60% coupon and button is black */}
			<UpgradeButton
				onClick={() => handleClickUpgrade(selectedPlan)}
				type={ButtonEnumType.SUBMIT}
				variant={ButtonEnumVariant.PRIMARY}
				className="payments__UpgradeNowButton"
			>
				{componentState.toLocaleLowerCase().includes('trial')
					? 'Continue with 14 day free trial'
					: 'Next'}
			</UpgradeButton>
			{showMaybeLater && (
				<></>
				// <GetProButtonsBlock>
				// 	<LinkUnderline onClick={handleClosePayments}>
				// 		Maybe later
				// 	</LinkUnderline>
				// </GetProButtonsBlock>
			)}
			{/* <SubscriptionDetails plan={selectedPlan} /> */}
			<TermsBlock />
		</>
	);
};
