export function getFirstValueInSet<T>(set: Set<T>): T {
    return set.size ? set[Symbol.iterator]().next().value : null;
}

export function getLastValueInSet<T>(set: Set<T>): T {
    let value: T = null;
    set.forEach((t: T) => { value = t; });
    return value;
}

export function mergeSets<T>(set1: Set<T>, set2: Set<T>): Set<T> {
    let union = new Set<T>(set1);
    set2.forEach((el: T): void => {
        union.add(el);
    });
    return union;
}

export function subtractSets<T>(set1: Set<T>, set2: Set<T>): Set<T> {
    let subtranction = new Set<T>(set1);
    set2.forEach((el: T): void => {
        subtranction.delete(el);
    });
    return subtranction;
}

export function compareSets<T>(set1: Set<T>, set2: Set<T>): boolean {
    if (set1.size !== set2.size) {
        return false;
    }
    let result: boolean = true;
    set1.forEach((item: T): void => {
        if (!set2.has(item)) {
            result = false;
        }
    });
    return result;
}

export function compareArrays<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0, l = arr1.length; i < l; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}

export function reverseMap<T, U>(map: Map<T, U>): Map<U, T> {
    let result = new Map<U, T>();
    for (let it of map) {
        result.set(it[1], it[0]);
    }
    return result;
}