import React, { FC, ComponentProps } from 'react';
import { Box, Paper } from '@mui/material';

export const BoxContent: FC<ComponentProps<typeof Box>> = ({
	children,
	...props
}: {
	children: React.ReactNode;
	props: ComponentProps<typeof Box>;
}) => {
	return (
		<Box {...props}>
			<BoxContentPaper>
				<Box p={3}>{children}</Box>
			</BoxContentPaper>
		</Box>
	);
};

export const BoxContentPaper: FC<ComponentProps<typeof Paper>> = ({
	children,
	...props
}: {
	children: React.ReactNode;
	props: ComponentProps<typeof Paper>;
}) => {
	return (
		<Paper {...props} elevation={4}>
			{children}
		</Paper>
	);
};
