import { forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import React from 'react';

const FlexRowAlign = forwardRef<HTMLDivElement, BoxProps>(
	({ children, ...props }, ref) => (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			ref={ref}
			{...props}
		>
			{children}
		</Box>
	),
);

export default FlexRowAlign;
