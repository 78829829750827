import { EventLogger } from '@vctr-libs/vctr-analytics';
import { logWebsiteEvent } from './lib';
import { Ga4Events, pushGTMEvent } from './events/analyticsEvents';
import {
	clearbitRevealCompany,
	onCookieConsentClosedAndValid,
	trackCustomerIoEvent,
} from '@vctr-libs/vctr-integrations';
import { getGID } from '@vctr-libs/vctr-utils';
import { UserBase } from '@vctr-libs/vctr-api-client';
import md5 from 'md5';
import { INDUSTRY_PRODUCT_PAGES } from './ABMTracking';

export function initializeHubspotForms(user: UserBase) {
	const initializeHubspotForms = [
		...document.querySelectorAll(`iframe.hs-form-iframe`),
	] as HTMLElement[];

	initializeHubspotForms.forEach((hubspotIframe: HTMLIFrameElement) => {
		setTimeout(() => {
			onCookieConsentClosedAndValid(() => {
				const iframeDoc = hubspotIframe?.contentWindow?.document;

				const gidInput =
					iframeDoc?.querySelector<HTMLInputElement>('input[name=gid]');
				const uuidInput =
					iframeDoc?.querySelector<HTMLInputElement>('input[name=uuid]');
				if (!gidInput || !uuidInput) return;

				gidInput.value = getGID();
				uuidInput.value = user?.uuid;
			});
		}, 300);
	});
}

export function initializeHubspotFormsEvents(eventLogger: EventLogger) {
	(window as any).hubspotFormSubmitted = (formData: any) => {
		console.log('hubspotFormSubmitted', formData);

		try {
			const eventData = {
				event: Ga4Events.BusinessContactSend,
				previous_page: document.referrer,
			};
			pushGTMEvent(eventData);
			GTMGenerateLead();

			const email = formData?.submissionValues?.email;
			logWebsiteEvent(
				['submit_business_contact', { hash: md5(email) }],
				eventLogger,
			);
			clearbitRevealCompany(eventLogger, undefined, true);
			trackCustomerIoEvent('submit_business_contact');
		} catch (error) {
			throw Error(error);
		}
	};
}

function GTMGenerateLead() {
	const path = window.location.pathname.replace('/', '');
	if (!INDUSTRY_PRODUCT_PAGES.includes(path)) return;

	pushGTMEvent({ event: 'generate_lead', lead_source: 'industry-page' });
}
