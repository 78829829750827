export const showNoticeTemporary = (text: string) => {
    const container = document.querySelector(`[js="ui-notice__container"]`);
    const content = document.querySelector(`[js="ui-notice__text"]`);
    (container as HTMLElement).style.display = "flex";
    (container as HTMLElement).style.transitionDuration = "0.3s";
    (container as HTMLElement).style.transitionProperty = "all";
    (container as HTMLElement).style.transitionTimingFunction = "ease";
    (container as HTMLElement).style.transform = "translate3d(0,0,0)";
    (container as HTMLElement).style.opacity = "1";
    (content as HTMLElement).textContent = text;
    setTimeout(() => {
        (container as HTMLElement).style.transform = "translate3d(0,100px,0)";
        (container as HTMLElement).style.opacity = "0";
        setTimeout(() => {
            (container as HTMLElement).setAttribute("display", "none");
        }, 300);
    }, 3000);
};
