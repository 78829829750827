import React, { FC } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';

export type LeaveWorkspaceDialogProps = {
	workspaceName: string;
	open: boolean;
	handleCloseDialog: () => void;
	handleLeaveWorkspace: () => void;
	disabled: boolean;
};

export const LeaveWorkspaceDialog: FC<LeaveWorkspaceDialogProps> = props => {
	const { open, handleCloseDialog, handleLeaveWorkspace, disabled } = props;

	return (
		<Dialog open={open} onClose={handleCloseDialog}>
			<DialogTitle>Leave workspace</DialogTitle>
			<DialogContent>
				<DialogContentText mb={1.5}>
					Are you sure you want to leave this workspace?
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button color="secondary" onClick={handleCloseDialog}>
					Cancel
				</Button>
				<Button
					color="error"
					onClick={disabled ? null : handleLeaveWorkspace}
					disabled={disabled}
				>
					Leave workspace
				</Button>
			</DialogActions>
		</Dialog>
	);
};
