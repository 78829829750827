export const GET_WORKSPACES_ITEMS = {
	id: true,
	name: true,
	picture: true,
	createdAt: true,
	owner: {
		id: true,
	},
	customDomain: true,
	permission: true,
	permissions: {
		id: true,
		name: true,
		properties: true,
		internalNote: true,
	},
	paddleDetails: {
		customerId: true,
		subscriptionId: true,
		linkedSubscriptionId: true,
		status: true,
		price: true,
		currency: true,
		recurringInterval: true,
		nextPaymentAmount: true,
		nextPaymentDate: true,
		cancellationEffectiveDate: true,
		cancelUrl: true,
		updateUrl: true,
		receipts: true,
		latestEventTime: true,
	},
};
