
// Abolsute path to default images so it could be access from any app. 
// This function uses this solution so a shared function can be created to serve all other application
// TODO have a dedicated domain for shared assets instead of 'comments' 
const defaultProfilePictures = [
    `/comments/shared_assets/default_profile_picture_1.svg`,
    `/comments/shared_assets/default_profile_picture_2.svg`,
    `/comments/shared_assets/default_profile_picture_3.svg`,
    `/comments/shared_assets/default_profile_picture_4.svg`,
    `/comments/shared_assets/default_profile_picture_5.svg`,
    `/comments/shared_assets/default_profile_picture_6.svg`,
    `/comments/shared_assets/default_profile_picture_7.svg`,
    `/comments/shared_assets/default_profile_picture_8.svg`,
    `/comments/shared_assets/default_profile_picture_9.svg`,
]

/**
 * 
 * @param user - simple version of "UserBase" but vctr-utils should not depend on any packages 
 * @returns path to the profile_picture, if no profile picture is found it looks for defaults
 */
export function getProfilePicture(user: { profile_picture: string, uuid: string }) {

    if (user.profile_picture?.length > 0) {
        return user.profile_picture;
    }

    // If there is no profile picture set, follow this rule:
    // Find first number from range 1 to 9, from the end of the user's UUID.
    const index = user.uuid
        .split(/(.)/)
        .reverse()
        .find(item => item.match(/[1-9]/));

    return defaultProfilePictures[parseInt(index) - 1];
}