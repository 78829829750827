import { RefObject, useEffect } from 'react';

type UseOnClickOutsideOptions = {
	ignoreSelector?: string;
};

export const useOnClickOutside = (
	ref: RefObject<HTMLElement>,
	handler: (arg0: any) => void,
	options?: UseOnClickOutsideOptions,
) => {
	useEffect(
		() => {
			const listener = (event: { target: any }) => {
				let ignoreElement: HTMLElement;
				if (options?.ignoreSelector) {
					ignoreElement = document.querySelector(options.ignoreSelector);
				}
				// Do nothing if clicking ref's element or descendent elements
				if (
					!ref.current ||
					ref.current.contains(event.target) ||
					ignoreElement?.contains(event.target)
				) {
					return;
				}
				handler(event);
			};
			document.addEventListener('mousedown', listener);
			document.addEventListener('touchstart', listener);
			return () => {
				document.removeEventListener('mousedown', listener);
				document.removeEventListener('touchstart', listener);
			};
		},
		// Add ref and handler to effect dependencies
		// It's worth noting that because passed in handler is a new ...
		// ... function on every render that will cause this effect ...
		// ... callback/cleanup to run every render. It's not a big deal ...
		// ... but to optimize you can wrap handler in useCallback before ...
		// ... passing it into this hook.
		[ref, handler],
	);
};
