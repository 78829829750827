import { colors } from '@vctr-libs-ui/vctr-components-react';

function switchMonthYearContent(isPushedToTheRight: boolean) {
	const monthlyContent = document.querySelectorAll<HTMLElement>(
		"[js='pricing__block--monthly']",
	);
	const yearlyContent = document.querySelectorAll<HTMLElement>(
		"[js='pricing__block--yearly']",
	);

	yearlyContent.forEach(
		element => (element.style.display = isPushedToTheRight ? 'none' : 'flex'),
	);
	monthlyContent.forEach(
		element => (element.style.display = isPushedToTheRight ? 'flex' : 'none'),
	);
}

export const handlePricing = () => {
	const pricingSwitchers = document.querySelectorAll('.pricing-switcher');

	pricingSwitchers.forEach(switcher => {
		const toggle = switcher.querySelector<HTMLElement>(
			'.pricing-switcher-toggle',
		);
		const toggleSvg = toggle?.querySelector('svg');
		if (!toggleSvg || !toggleSvg?.dataset?.toggle) return;
		const leftText = switcher.querySelectorAll('.pricing-switcher-text')[0];
		const rightText = switcher.querySelectorAll('.pricing-switcher-text')[1];

		toggle.addEventListener('click', () => {
			const isPushedToTheRight = toggleSvg.dataset.toggle === 'true';
			toggleSvg.dataset.toggle = isPushedToTheRight ? 'false' : 'true';
			toggleSvg
				.querySelector('circle')
				.setAttribute('cx', isPushedToTheRight ? '6' : '18');
			toggleSvg
				.querySelector('rect')
				.setAttribute(
					'fill',
					isPushedToTheRight
						? colors.c_almost_black
						: colors.c_light_mode_primary,
				);

			if (isPushedToTheRight) {
				leftText.classList.remove('disabled');
				rightText.classList.add('disabled');
			} else {
				leftText.classList.add('disabled');
				rightText.classList.remove('disabled');
			}
			switchMonthYearContent(isPushedToTheRight);
		});
	});
};
