import React, { FC, Fragment } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Reset } from 'styled-reset';
import { theme, Theme } from './theme';

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>` 

  html, body, #root {
    overflow: hidden;
    font-family: ${({ theme }) => theme.fontFamily};
    // font-size: 62.5%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.c_white};
    color: ${({ theme }) => theme.colors.c_almost_black};
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
	
	* {
		outline: 0;
		box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 4px;
    }
     
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.c_soft_grey};
      border-radius: 2px;
    }
	}

  a {
    cursor: pointer;
    user-select: none;
    color: inherit;
    font-size: ${({ theme }) => theme.fontSize.font_size_small};
    font-weight: 600;
    display: inline-block;
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }
`;

export const GlobalStyler: FC = ({ children }) => (
	<ThemeProvider theme={theme.black}>
		<Fragment>
			<Reset />
			<GlobalStyle />
			{children}
		</Fragment>
	</ThemeProvider>
);

export const ThemeStyler: FC = ({ children }) => (
	<ThemeProvider theme={theme.black}>
		<Fragment>{children}</Fragment>
	</ThemeProvider>
);
