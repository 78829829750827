export const CommentTagClass = "comment_mention-tag"
export const ALL_MEMBER_MENTION = "All";

export type CommentChunk = {
    text: string,
    userId?: string
};

export function parseXML(domParser: DOMParser, xmlString: string): Document {
    try {
        const processedXmlString = xmlString.includes("<comment>") ? xmlString : `<comment>${xmlString}</comment>`;
        const xmlDoc = domParser.parseFromString(processedXmlString, "text/xml");
        if (xmlDoc.body?.querySelector("parsererror")) {
            return null;
        }
        return xmlDoc;
    }
    catch (error) {
        return null;
    }
}

export function getCommentChunks(domParser: DOMParser, xmlString: string): CommentChunk[] {
    const xmlDoc = parseXML(domParser, xmlString);
    if (!xmlDoc) return [{ text: xmlString }]; // if xmsString is not valid xml most likely it is just a textNode
    const comment = xmlDoc.querySelector("comment");
    const nodes = Array.from(comment.childNodes);
    const commentChunks: CommentChunk[] = nodes.map((node: any) => { // Need to set to "any" because I don't have types for Element in nodeJs
        const chunk: CommentChunk = { text: node.textContent };
        // node instanceof Element --> can't do in nodeJs
        if (/* node instanceof Element &&  */node.tagName === "mention" && node.getAttribute("uuid")) {
            chunk.userId = node.getAttribute("uuid");
        }
        return chunk;
    })
    return commentChunks;
}

export function createCommentFromChunks(chunks: CommentChunk[], cacheingCb?: (value: CommentChunk) => string): string {
    const content = chunks.map(chunk => {
        if (chunk.userId) {
            const defaultMention = `<span class="${CommentTagClass}" userId="${chunk.userId}"  >${chunk.text}</span>`;
            const result = cacheingCb ? cacheingCb(chunk) : defaultMention;
            return result
        }
        var re = new RegExp(String.fromCharCode(160), "g");
        return chunk.text.replace(re, " ").replace(/  +/g, ' ');

    })

    return content.join((""));
}

export function serializeComment(comment: HTMLDivElement): string {
    const serializer = new XMLSerializer();
    const nodes = Array.from(comment.childNodes);
    const xml = new Document();
    const commentNode = xml.createElement("comment");
    nodes.map(node => {
        if (node instanceof HTMLSpanElement) {
            if (!node.classList.contains(CommentTagClass)) return; // filter out queries if somehow would make their way here
            const mention = xml.createElement("mention");
            mention.setAttribute("uuid", node.getAttribute("userId"));
            mention.textContent = node.textContent;
            commentNode.appendChild(mention);
        } else {
            const textNode = xml.createTextNode(node.textContent);
            commentNode.appendChild(textNode);
        }
    })
    xml.appendChild(commentNode);
    return serializer.serializeToString(xml);
}
