import React, { FC, RefObject } from 'react';
import {
	DropdownItemDisabledWrapper,
	DropdownItemIcon,
	DropdownItemWithIcons,
	DropdownItemWithIconsTitle,
	DropdownItemsList,
	Link,
} from './styledComponents';
import { DropdownItemProps, DropdownStyleVariant } from '../Dropdown';
import {
	DropdownEnumPositionX,
	DropdownEnumPositionY,
	stylePosition,
} from '../utils/position';
import { ItemWithChildren } from './ItemWithChildren';

export type DropdownListProps = {
	listRef?: RefObject<HTMLUListElement>;
	positionX?: DropdownEnumPositionX;
	positionY?: DropdownEnumPositionY;
	autoPlacement?: boolean;
	wrapperRef?: RefObject<HTMLUListElement>;
	menu: DropdownItemProps[];
	handleOnShow: (isVisible: boolean) => void;
	childrenMenu?: DropdownItemProps[];
	variant?: DropdownStyleVariant;
	containerStyle?: React.CSSProperties;
};

export const DropdownList = (props: DropdownListProps) => {
	const {
		listRef,
		positionX,
		positionY,
		autoPlacement,
		wrapperRef,
		menu,
		handleOnShow,
		variant,
		containerStyle,
	} = props;

	return (
		<div style={containerStyle}>
			<DropdownItemsList
				ref={listRef}
				style={stylePosition(positionX, positionY, autoPlacement, wrapperRef)}
				variant={variant}
			>
				<DropddownItemsContent {...{ menu, handleOnShow }} />
			</DropdownItemsList>
		</div>
	);
};

type DropdownItemsContentProps = {
	menu: DropdownItemProps[];
	handleOnShow: (isVisible: boolean) => void;
};

export const DropddownItemsContent: FC<DropdownItemsContentProps> = props => {
	const { menu, handleOnShow } = props;
	return (
		<>
			{menu.map(
				({
					color,
					customRenderer,
					title,
					iconSrc,
					link,
					onClick,
					onMouseDown,
					childrenMenu,
					childrenMenuPositionX,
					childrenMenuPositionY,
					disabled,
				}: DropdownItemProps) => {
					const itemKey = `menuItem_${title}`;

					if (link) {
						return (
							<Link href={link} target="_blank" key={itemKey}>
								<DropdownItemWithIcons
									disabled={disabled}
									color={color}
									className="dropdown__Item"
									onClick={event => {
										if (onClick) {
											onClick(event);
										}
										handleOnShow(false);
									}}
									onMouseDown={onMouseDown}
								>
									{iconSrc && <DropdownItemIcon src={iconSrc} />}
									<DropdownItemWithIconsTitle>
										{title}
									</DropdownItemWithIconsTitle>
								</DropdownItemWithIcons>
							</Link>
						);
					}

					if (childrenMenu) {
						return (
							<ItemWithChildren
								color={color}
								disabled={disabled}
								key={itemKey}
								onClick={onClick}
								onMouseDown={onMouseDown}
								title={title}
								iconSrc={iconSrc}
								childrenMenu={childrenMenu}
								childrenMenuPositionX={childrenMenuPositionX}
								childrenMenuPositionY={childrenMenuPositionY}
							/>
						);
					}

					if (customRenderer) {
						return (
							<DropdownItemDisabledWrapper {...{ disabled }}>
								{customRenderer({
									onCloseDropdown: () => {
										handleOnShow(false);
									},
								})}
							</DropdownItemDisabledWrapper>
						);
					}

					return (
						<DropdownItemWithIcons
							disabled={disabled}
							color={color}
							key={itemKey}
							className="dropdown__Item"
							onClick={event => {
								onClick && onClick(event);
								handleOnShow(false);
							}}
							onMouseDown={onMouseDown}
						>
							{iconSrc && <DropdownItemIcon src={iconSrc} />}
							<DropdownItemWithIconsTitle>{title}</DropdownItemWithIconsTitle>
						</DropdownItemWithIcons>
					);
				},
			)}
		</>
	);
};
