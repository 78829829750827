import { UserBase } from "@vctr-libs/vctr-api-client";

const showForAuhtorisedSelector = "[js='show-for-authorised']";
const showForUnauthorisedSelector = "[js='show-for-unauthorised']";

export const showElement = (el: HTMLElement) => {
    if (el) {
        if (el.attributes.getNamedItem("js-display")?.value === "flex") {
            el.style.display = "flex";
        } else {
            el.style.display = "block";
        }
    }
};
export const hideElement = (el: HTMLElement) => {
    if (el) {
        el.style.display = "none";
    }
};

export const showElementsForAuhtorisedUsers = () => {
    const authElements = document.querySelectorAll(showForAuhtorisedSelector);
    Array.from(authElements).forEach(el => showElement(el as HTMLElement));
    const unauthElements = document.querySelectorAll(showForUnauthorisedSelector);
    Array.from(unauthElements).forEach(el => hideElement(el as HTMLElement));
}

export const showElementsForUnauhtorisedUsers = () => {
    const authElements = document.querySelectorAll(showForAuhtorisedSelector);
    Array.from(authElements).forEach(el => hideElement(el as HTMLElement));
    const unauthElements = document.querySelectorAll(showForUnauthorisedSelector);
    Array.from(unauthElements).forEach(el => showElement(el as HTMLElement));
}

export const showLoginForm = () => {
    try {
        const form = document.querySelector(`[js="homepageForm"]`)
        showElement(form as HTMLElement);
    } catch (e) {
        console.warn("login form", e);
    }
};

export const hideLoginForm = () => {
    try {
        const form = document.querySelector(`[js="homepageForm"]`)
        hideElement(form as HTMLElement);
    } catch (e) {
        console.warn("login form", e);
    }
};

export const setProfile = (user: UserBase) => {
    try {
        const els = document.querySelectorAll(`[js="navbarProfileAvatar"]`);
        if (user.profile_picture && user.profile_picture !== "") {
            const img = document.createElement("img");
            img.setAttribute("src", user.profile_picture);
            img.setAttribute("style", "position: absolute; top: 0; left: 0;");
            els.forEach((el) => {
                const hasImage: boolean = !Array.from(el.children).find((c: HTMLElement) => {
                    return (c.tagName === "img" && c.attributes.getNamedItem("src")?.toString() === user.profile_picture)
                });
                if (hasImage) {
                    el.appendChild(img);
                }
            });
        } else {
            els.forEach((el) => {
                el.innerHTML = user.name.length > 0 ? user.name[0].toLocaleUpperCase() : " ";
            })
        }
    } catch (e) {
        console.warn("set profile", e);
    }
};
