/**
 * DiscoverPro container component for studio
 */

import React, { FC } from 'react';
// TODO: Remove this ignore when all necessary packages like styled-component will support React 18 types.
// @ts-ignore
import { createRoot } from 'react-dom/client';
import { StudioReset } from '../../styles/studioReset';
import {
	DiscoverProEnterprise,
	DiscoverProEnterpriseProps,
	DiscoverProStudioProps,
} from '.';

export const DiscoverEnterpriseComponent: FC<DiscoverProStudioProps> = (
	props: DiscoverProStudioProps,
) => {
	const {
		showModalInitial,
		logEventOnShow,
		assetsPath = './',
		imagesPath = './images',
		autoplay = true,
		initialSlide = 0,
	} = props;
	return (
		<StudioReset>
			<DiscoverProEnterprise
				showModalInitial={showModalInitial}
				handleGetInTouch={() =>
					window.open(
						window.location.origin.replace('app', 'www') +
							'/business-contact-dashboard',
						'_blank',
					)
				}
				logEventOnShow={() => logEventOnShow()}
				modalZIndex={53}
				assetsPath={assetsPath}
				imagesPath={imagesPath}
				autoplay={autoplay}
				initialSlide={initialSlide}
			/>
		</StudioReset>
	);
};

export const DiscoverEnterpriseStudio = {
	render: (rootEl: HTMLElement, props: DiscoverProEnterpriseProps) => {
		const root = createRoot(rootEl);
		return root.render(<DiscoverEnterpriseComponent {...props} />);
	},
};
