import React, { FC } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';

export type DeleteWorkspaceDialogProps = {
	open: boolean;
	handleCloseDialog: () => void;
	handleDeleteWorkspace: () => void;
};

export const DeleteWorkspaceDialog: FC<DeleteWorkspaceDialogProps> = props => {
	const { open, handleCloseDialog, handleDeleteWorkspace } = props;

	return (
		<Dialog open={open} onClose={handleCloseDialog}>
			<DialogTitle>Delete workspace</DialogTitle>
			<DialogContent>
				<DialogContentText mb={1.5}>
					Are you sure you want to permanently delete this workspace?
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button color="secondary" onClick={handleCloseDialog}>
					Cancel
				</Button>
				<Button color="error" onClick={handleDeleteWorkspace}>
					Delete workspace
				</Button>
			</DialogActions>
		</Dialog>
	);
};
