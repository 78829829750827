import styled from 'styled-components';

import {
	Icon,
	Dropdown as DropdownC,
} from '..';

export const IconHelp = styled(Icon)`
	cursor: pointer;
`;

export const HelpDropdown = styled(DropdownC)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 64px;
	height: 64px;
	flex: 1 0 64px;
	border-radius: ${({ theme }) => theme.borderRadius[0]};
	&:hover {
		background-color: ${({ theme }) => theme.colors.c_light_grey};
	}
`;
