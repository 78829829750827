import {
	PlanIds,
	UserBase,
	isBusinessWorkspace,
	isCustomPlanWorkspace,
	isEducationWorkspace,
	isLockedWorkspace,
	isProWorkspace,
	isSetupWorkspace,
	isStarterWorkspace,
	updateWorkspace,
} from '@vctr-libs/vctr-api-client';
import { isCookiesConsentEnv } from '@vctr-libs/vctr-utils';
import { getUsetifulWorkTogetherData } from './data/usetifulWorkTogetherCompanies';

import {
	DashboardEventInfo,
	DashboardPaddleEventName,
	DashboardPaddleEventInfo,
	DashboardEventName,
} from '@vctr-libs/vctr-analytics';

declare global {
	interface Window {
		usetifulTags: Record<string, string | number | boolean>;
		USETIFUL: {
			tour: {
				close: () => void;
				nextStep: () => void;
				preStep: () => void;
				jumpTo: (step: number) => void; // starts from 1
				getState: () => {
					name: string;
					id: number;
					isAvailable: boolean;
					state: string;
					currentStep: number; // indexing from 0
				};
			};
			reinitialize: () => void;
		};
	}
}

type History = { push: (location: string) => void };

type InitUsetifulParams = {
	planId: string;
	plans: PlanIds[];
	user: UserBase;
	currentWorkspaceUUID: string;
	currentProjectsCount?: number;
	history?: History;
	logEvent?: (
		eventName: DashboardEventName | DashboardPaddleEventName | string,
		eventInfo?:
			| DashboardEventInfo
			| DashboardPaddleEventInfo
			| { [name: string]: string },
	) => void;
};

const closeButton = `
  <svg class="vctr-close-button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M4.5 4.5L11.5 11.5" stroke="white" stroke-linejoin="round"/>
    <path d="M11.5 4.5L4.5 11.5" stroke="white" stroke-linejoin="round"/>
  </svg>
`;

const goToPreviousStep = (ev: Event) => {
	ev.preventDefault();
	window.USETIFUL.tour.preStep();
	return false;
};

const goToNextStep = (ev: Event) => {
	ev.preventDefault();
	window.USETIFUL.tour.nextStep();
	return false;
};

const closeTour = (ev: Event) => {
	ev.preventDefault();
	window.USETIFUL.tour.close();
	return false;
};

export function initUsetiful({
	planId,
	plans,
	user,
	history,
	currentWorkspaceUUID,
	currentProjectsCount,
	logEvent,
}: InitUsetifulParams) {
	if (!isCookiesConsentEnv() || !user || !user?.email) return;

	const isProWorkspaceOrEnterprise =
		isProWorkspace(planId) ||
		isBusinessWorkspace(planId) ||
		isCustomPlanWorkspace(planId);

	const hasProOrEnterpriseWorkspace = plans
		.map(
			plan =>
				isProWorkspace(plan) ||
				isBusinessWorkspace(plan) ||
				isCustomPlanWorkspace(plan),
		)
		.includes(true);

	const hasProWorkspace = plans
		.map(plan => isProWorkspace(plan))
		.includes(true);

	const hasEnterpriseWorkspace = plans
		.map(plan => isBusinessWorkspace(plan) || isCustomPlanWorkspace(plan))
		.includes(true);

	// Usetiful user segmentation
	const created = Math.round(new Date(user.created).getTime() / 1000);
	const workTogetherTags = getWorkTogetherTags(user.email);
	const daysAfterRegistration = Math.floor(
		(new Date().getTime() - new Date(user.created).getTime()) /
			(1000 * 60 * 60 * 24),
	);
	window.usetifulTags = {
		userId: user.uuid,
		fullName: user.name,
		firstName: user.firstName,
		lastName: user.lastName,
		occupation: String(user.questionnaire?.occupation?.value),
		currentWorkspaceIsPro: String(isProWorkspaceOrEnterprise),
		created: created,
		userPassed30days: String(
			new Date().getTime() / 1000 > created + 30 * 24 * 60 * 60,
		),
		trialUsed: String(user.trial_used),
		isCurrentWorkspaceLocked: String(isLockedWorkspace(planId)),
		hasStarterWorkspace: String(
			!!plans.find(planId => isStarterWorkspace(planId)),
		),
		currentProjectsCount: currentProjectsCount,
		daysAfterRegistration: daysAfterRegistration,
		hasProOrEnterpriseWorkspace: String(hasProOrEnterpriseWorkspace),
		hasProWorkspace: String(hasProWorkspace),
		hasEnterpriseWorkspace: String(hasEnterpriseWorkspace),
		isEducationWorkspace: String(isEducationWorkspace(planId as PlanIds)),
		isSetupWorkspace: String(isSetupWorkspace(planId as PlanIds)),
		isStarterWorkspace: String(isStarterWorkspace(planId as PlanIds)),
		...workTogetherTags,
	};

	if (document.querySelector('#usetifulScript')) {
		window.USETIFUL?.reinitialize();
		return;
	}

	(function (d, s) {
		var a = d.getElementsByTagName('head')[0];
		var r = d.createElement('script');
		r.async = true;
		r.src = s;
		r.setAttribute('id', 'usetifulScript');
		r.dataset.token = '5e57c30a9eef9fcfba0dc8cabef11808';
		a.appendChild(r);
	})(document, 'https://www.usetiful.com/dist/usetiful.js');

	initMutationObserver(currentWorkspaceUUID, history);

	window.addEventListener('usetiful_send_work_together_data', () => {
		fetch(
			'https://eu.customerioforms.com/forms/submit_action?site_id=c5f48d52f53eec512b20&form_id=c6fb2e73e38d4e6&success_url=https://www.vectary.com/',
			{
				method: 'POST',
				mode: 'no-cors',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email: user.email }),
			},
		).then(() => {});
	});

	window.addEventListener('last_step_onboarding', (ev: CustomEvent) => {
		const modal = ev.detail.modal;
		const close = ev.detail.close;
		const coupon = ev.detail.coupon;
		const buy = ev.detail.buy;

		const onPrimaryButtonClick = () => {
			const urlParams = new URLSearchParams(window.location.search);

			if (modal) urlParams.set('modal', modal);
			if (close) urlParams.set('close', close);
			if (coupon) urlParams.set('coupon', coupon);
			if (buy) urlParams.set('buy', buy);

			if (!history)
				throw Error('Missing history dependency for usetiful initialization');

			history.push(`${location.pathname}?${urlParams.toString()}`);
		};

		// we need to be sure the popup is already shown
		setTimeout(() => {
			document
				.querySelector("[data-uf-button='button-primary']")
				?.removeEventListener('click', onPrimaryButtonClick);

			document
				.querySelector("[data-uf-button='button-primary']")
				?.addEventListener('click', onPrimaryButtonClick);
		}, 100);
	});

	window.addEventListener('usetiful_internal_log', (ev: CustomEvent) => {
		if (!logEvent)
			throw Error('Missing logEvent dependency for usetiful initialization');

		const title = ev.detail.title;
		const data: { title: string; step?: string } = { title };

		const step = ev.detail.step;
		if (step) data.step = step;

		logEvent('usetiful_modal_open', data);
	});
}

function initMutationObserver(currentWorkspaceUUID: string, history?: History) {
	var mutationObserver = new MutationObserver(function (mutations) {
		mutations.forEach(function (mutation) {
			mutation.addedNodes.forEach((node: HTMLDivElement) => {
				const isUsetifulContainer = node?.dataset?.ufContent === 'modal';
				if (isUsetifulContainer) {
					onUsetifulModalIsShown(node, currentWorkspaceUUID, history);
				}
			});
		});
	});

	mutationObserver.observe(document.querySelector('body'), {
		attributes: false,
		characterData: false,
		childList: true,
		subtree: false,
		attributeOldValue: false,
		characterDataOldValue: false,
	});
}

function onUsetifulModalIsShown(
	node: HTMLDivElement,
	currentWorkspaceUUID: string,
	history?: History,
) {
	const iframeModal = node.querySelector('.iframe-modal');
	document
		.querySelector('.vctr-close-button')
		?.removeEventListener('click', goToPreviousStep);
	iframeModal?.removeEventListener('click', goToPreviousStep);

	if (!!iframeModal) {
		document.querySelector('[data-uf-button="close"]')?.remove();
		iframeModal.insertAdjacentHTML('afterbegin', closeButton);
		iframeModal.addEventListener('click', goToPreviousStep);
	}

	const twoColumnModalImageLink = document.querySelector(
		'.two-column-modal a.two-column-modal-section',
	);
	if (twoColumnModalImageLink) {
		const href = twoColumnModalImageLink.getAttribute('href')?.replace('#', '');
		if (href === 'tour-continue') {
			twoColumnModalImageLink.removeEventListener('click', goToNextStep);
			twoColumnModalImageLink.addEventListener('click', goToNextStep);
		}
	}

	document.querySelectorAll('[data-uf-type]').forEach(el => {
		const type = el.getAttribute('data-uf-type');

		if (type === 'tour-close') {
			el.removeEventListener('click', closeTour);
			el.addEventListener('click', closeTour);
		}
	});

	// Rename Workspace form
	const renameWorkspaceForm = document.querySelector(
		'[data-form-id="rename-workspace"]',
	);
	if (renameWorkspaceForm) {
		let workspaceName = renameWorkspaceForm.querySelector('input').value;
		renameWorkspaceForm.querySelector('input').addEventListener('input', ev => {
			workspaceName = (ev.currentTarget as HTMLInputElement).value;
		});

		document
			.querySelector('[data-uf-button="button"]')
			.addEventListener('click', () => {
				try {
					updateWorkspace(currentWorkspaceUUID, {
						name: workspaceName,
					});
				} catch (error) {
					console.error('Error occurred while updating workspace name', error);
					throw new Error('Error occurred while updating workspace name');
				}
			});
	}

	// Button classes that change url params
	// syntax: setUrlParam--key1-value1, setUrlParam--key1-value1--key2-value2, ...
	//   example: setUrlParam--modal-payments_initial_trial--close-false
	document
		.querySelectorAll('[data-uf-button="button"][class*=setUrlParam--]')
		.forEach(el => {
			const className = [...el.classList].filter(className =>
				className.includes('setUrlParam--'),
			)[0];
			if (!className) return;

			const urlParams = new URLSearchParams(window.location.search);
			className
				.split('--')
				.slice(1)
				.forEach(param => {
					const key = param.split('-')[0];
					const value = param.split('-')[1];
					urlParams.set(key, value);
				});

			const onClick = () => {
				if (!history)
					throw Error('Missing history dependency for usetiful initialization');

				history.push(`${location.pathname}?${urlParams.toString()}`);
			};

			el.removeEventListener('click', onClick);
			el.addEventListener('click', onClick);
		});
}

function calculateRange(registrations: number): string {
	if (registrations < 5)
		throw Error(
			'Usetiful: incorrect number of registrations in work together onboarding',
		);
	if (registrations === 5) return '5';
	if (registrations > 5 && registrations <= 9) return '5+';
	if (registrations >= 10 && registrations <= 19) return '10+';
	return '20+';
}

function getWorkTogetherTags(userEmail: string) {
	const workTogetherTags: { [key: string]: any } = {
		showWorkTogether: String(false),
	};
	const userDomain = userEmail.split('@')[1];
	const companyData = getUsetifulWorkTogetherData().find(
		item => item.d === userDomain,
	);
	if (companyData) {
		workTogetherTags.showWorkTogether = String(true);
		workTogetherTags.workTogetherCount = calculateRange(companyData.r);
		workTogetherTags.workTogetherDomain = companyData.d;
	}

	return workTogetherTags;
}
