export function getIsPricingLoading({
	isCheckoutLoaded,
	couponCode,
	isCouponLoaded,
}: {
	isCheckoutLoaded: boolean;
	couponCode: string;
	isCouponLoaded: boolean;
}): boolean {
	return !isCheckoutLoaded || (couponCode && !isCouponLoaded);
}
export function getDateDaysFromDate({
	startDate,
	days,
}: {
	startDate: Date;
	days: number;
}): Date {
	const newDate = new Date(startDate);
	newDate.setDate(startDate.getDate() + days);
	return newDate;
}
export function getDateMonthsFromDate({
	startDate,
	months,
}: {
	startDate: Date;
	months: number;
}): Date {
	const newDate = new Date(startDate);
	newDate.setMonth(startDate.getMonth() + months);
	return newDate;
}
export function getDateYearsFromDate({
	startDate,
	years,
}: {
	startDate: Date;
	years: number;
}): Date {
	const newDate = new Date(startDate);
	newDate.setFullYear(startDate.getFullYear() + years);
	return newDate;
}
