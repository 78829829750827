import styled, { css } from 'styled-components';
import { Button as ButtonC } from '../_common';

export const $modalOverlayBackground = 'rgba(0, 0, 0, 0.4)';

export const PaymentsModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: auto;
	z-index: 95;
	font-size: ${({ theme }) => theme.fontSize.font_size_medium};
	background-color: ${$modalOverlayBackground};
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;

export const PaymentsModalContent = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	/* 60px * 2 padding + 10px for text rendering */
	max-width: ${340 + 120 + 10}px;
	width: 100%;
	max-height: 90vh;
	overflow: auto;
	padding: 40px 60px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.colors.c_white};
	line-height: normal;
`;

export const PaymentsWidget = styled.div<{ show: boolean }>`
	display: flex;
	position: relative;
	width: 100%;
	${({ show }) =>
		show
			? css`
					height: auto;
					flex: 1 0 auto;
			  `
			: css`
					height: 0;
					overflow: hidden;
			  `}
`;

export const CloseButton = styled.a`
	position: absolute;
	right: 16px;
	top: 16px;
`;

export const BackButton = styled.a`
	position: absolute;
	left: 16px;
	top: 16px;
`;

export const ContentBlock = styled.div`
	padding: 0 1rem;
`;

export const Title = styled.h1`
	margin-bottom: 32px;
	/* TODO load font size from global variable theme */
	font-size: 32px;
`;

export const SelectWrapper = styled.div`
	display: flex;
	padding-bottom: 32px;
	align-items: center;
	font-size: ${({ theme }) => theme.fontSize.font_size_medium};
	& > div {
		padding-right: 10px;
	}
`;

export const SelectWrapperEmpty = styled.div`
	display: flex;
	flex-direction: column;
	font-size: ${({ theme }) => theme.fontSize.font_size_medium};
	line-height: normal;
`;

export const SelectWrapperEmptyText = styled.p`
	font-size: ${({ theme }) => theme.fontSize.font_size_medium};
	margin-bottom: 2.4rem;
`;

export const PricingWrapper = styled.div`
	display: flex;
`;

// I know it looks stupid, but it's another custom padding in desing
// TODO rework this
export const TotalPricingWrapperContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const TotalPricingWrapper = styled.div`
	display: flex;
`;

export const Mark = styled.span`
	display: block;
	font-size: ${({ theme }) => theme.fontSize.font_size_medium};
	color: ${({ theme }) => theme.colors.c_dark_grey};
`;

export const PriceInfo = styled.p`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1 0 60%;
	margin-right: 37px;
	font-size: ${({ theme }) => theme.fontSize.font_size_small};
	color: ${({ theme }) => theme.colors.c_mid_grey};
`;

export const PriceInfoTotal = styled(PriceInfo)`
	flex: 1 1 40%;
	margin-right: 0;
	text-align: right;
`;

// TODO rewrite margin/padding components into <ContentBlock size={MarginSize.small} />

export const UpgradeButton = styled(ButtonC)`
	font-size: ${1.5 * 0.625}rem;
	padding: ${1 * 0.625}rem;
`;

export const SubscriptionDetailsList = styled.ul`
	list-style-type: disc;
	padding-left: 1.1rem;
	font-size: 1.1rem;
	color: ${({ theme }) => theme.colors.c_grey};
	li {
		margin-bottom: 1rem;
	}
`;
