import { EventLogger, EventTypes } from '@vctr-libs/vctr-analytics';
import {
	isProductionSite,
	LocalStorage,
	userSettingsKeys,
} from '@vctr-libs/vctr-utils';

export async function logWebsiteEvent(
	event: EventTypes,
	eventLogger: EventLogger,
) {
	if (!isProductionSite() && !isTestEventsEnabled()) return;
	await eventLogger.logEvent(event);
}

export function addSourceForUpgradeToProLinks() {
	const links = [
		...document.querySelectorAll<HTMLLinkElement>('a[href*="/dashboard/?buy"]'),
	];
	links.forEach(link => {
		const source = encodeURIComponent(document.location.href);
		const symbol = link.href.includes('?') ? `&` : `?`;
		const newLink = `${link.href}${symbol}source=${source}`;
		link.href = newLink;
	});
}

export function setTestEventTracking() {
	if (isProductionSite()) return;

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	if (!urlParams.has(userSettingsKeys.TEST_EVENT_TRACKING)) return;

	const tracking = urlParams.get(userSettingsKeys.TEST_EVENT_TRACKING);
	if (tracking === 'true') {
		const timestamp = new Date().getTime();
		LocalStorage.setValueOfItem(userSettingsKeys.TEST_EVENT_TRACKING, {
			enabled: true,
			expire: new Date().setTime(timestamp + 60 * 60 * 1000),
		});
	}

	if (tracking === 'false') {
		LocalStorage.removeValueOfItem(userSettingsKeys.TEST_EVENT_TRACKING);
	}
}

export const isTestEventsEnabled = () => {
	const testEventTracking = LocalStorage.getValueOfItem(
		userSettingsKeys.TEST_EVENT_TRACKING,
	);
	return testEventTracking?.enabled && testEventTracking.expire > new Date();
};
