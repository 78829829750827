import React, { FC } from 'react';
import styled from 'styled-components';
// import "./icons.scss";

const IconWrapper = styled.svg`
	display: inline-block;
	position: relative;
	width: 16px;
	height: 16px;
	z-index: ${({ theme }) => theme.zIndex[1]};
`;

export enum IconEnumName {
	DEFORMTWIST = 'Deform-Twist',
	DEFORMSKEW = 'Deform-Skew',
	DEFORMBEND = 'Deform-Bend',
	DEFORMTAPER = 'Deform-Taper',
	DEFORMRANDOM = 'Deform-Random',
	DEFORMNOISE = 'Deform-Noise',
	DEFORMSTRETCH = 'Deform-Stretch',
	DEFORMSPHERIFY = 'Deform-Spherify',
	DEFORMSUBDIVISIONS = 'Deform-Subdivisions',
	DEFORMBEVELDEFORM = 'Deform-BevelDeform',
	DEFORMSIMPLIFY = 'Deform-Simplify',
	DEFORMMETABALLS = 'Deform-Metaballs',
	HEADERMENU = 'Header-Menu',
	HEADEROBJECT = 'Header-Object',
	HEADEREDIT = 'Header-Edit',
	HEADEREXPORT = 'Header-Export',
	HEADERSHARE = 'Header-Share',
	HEADEREMBED = 'Header-Embed',
	HEADERCOMMENT = 'Header-Comment',
	HEADERRENDER = 'Header-Render',
	HEADERLIBRARY = 'Header-Library',
	SECTIONACTIONS = 'Section-Actions',
	SECTIONENVIRONMENT = 'Section-Environment',
	SECTIONMATERIAL = 'Section-Material',
	SECTIONADDEMPTYOBJECT = 'Section-AddEmptyObject',
	SECTIONIMAGEPLANE = 'Section-ImagePlane',
	SECTION3DTEXT = 'Section-3DText',
	SECTIONLIGHT = 'Section-Light',
	SECTIONIMPORT = 'Section-Import',
	SECTIONFILTERS = 'Section-Filters',
	SECTIONCOMMENTS = 'Section-Comments',
	OBJECTLISTFOLDER = 'ObjectList-Folder',
	OBJECTLISTGEOMETRY = 'ObjectList-Geometry',
	OBJECTLISTTEXT = 'ObjectList-Text',
	OBJECTLISTIMAGE = 'ObjectList-Image',
	OBJECTLISTLIGHT = 'ObjectList-Light',
	OBJECTLISTCAMERA = 'ObjectList-Camera',
	OBJECTLISTSVG = 'ObjectList-SVG',
	LIBRARYCOLLECTIONS = 'Library-Collections',
	LIBRARYASSETS = 'Library-Assets',
	LIBRARYMATERIALS = 'Library-Materials',
	LIBRARYENVIRONMENTS = 'Library-Environments',
	LIBRARYFILTER = 'Library-Filter',
	PRIMITIVESCUBE = 'Primitives-Cube',
	PRIMITIVESSPHERE = 'Primitives-Sphere',
	PRIMITIVESCONE = 'Primitives-Cone',
	PRIMITIVESTORUS = 'Primitives-Torus',
	PRIMITIVESCAPSULE = 'Primitives-Capsule',
	PRIMITIVESCYLINDER = 'Primitives-Cylinder',
	PRIMITIVESTUBE = 'Primitives-Tube',
	PRIMITIVESPOLYHEDRON = 'Primitives-Polyhedron',
	PRIMITIVESSHADOWPLANE = 'Primitives-ShadowPlane',
	PRIMITIVESINFINITEPLANE = 'Primitives-InfinitePlane',
	PRIMITIVESSQUAREPLANE = 'Primitives-SquarePlane',
	PRIMITIVESCIRCLEPLANE = 'Primitives-CirclePlane',
	LIGHTSPHERE = 'Light-Sphere',
	LIGHTSPOT = 'Light-Spot',
	LIGHTTUBE = 'Light-Tube',
	LIGHTDIRECTIONAL = 'Light-Directional',
	LIGHTRECTANGLE = 'Light-Rectangle',
	SELECTINGVERTICES = 'Selecting-Vertices',
	SELECTINGEDGES = 'Selecting-Edges',
	SELECTINGFACES = 'Selecting-Faces',
	SELECTADD = 'Select-Add',
	SELECTSUBTRACT = 'Select-Subtract',
	SELECTJOG = 'Select-Jog',
	SELECTPOINT = 'Select-Point',
	SELECTSELECTOR = 'Select-Selector',
	SELECTPAINT = 'Select-Paint',
	SELECTPAINTADD = 'Select-PaintAdd',
	SELECTPAINTSUBTRACT = 'Select-PaintSubtract',
	SELECTALL = 'Select-All',
	SELECTINVERT = 'Select-Invert',
	SELECTMARQUEE = 'Select-Marquee',
	SELECTMARQUEEADD = 'Select-MarqueeAdd',
	SELECTMARQUEESUBTRACT = 'Select-MarqueeSubtract',
	SELECTLASSO = 'Select-Lasso',
	SELECTLASSOADD = 'Select-LassoAdd',
	SELECTLASSOSUBTRACT = 'Select-LassoSubtract',
	GENERATELINEAR = 'Generate-Linear',
	GENERATERADIAL = 'Generate-Radial',
	GENERATEGRID = 'Generate-Grid',
	GENERATEOBJECT = 'Generate-Object',
	GENERATESYMMETRY = 'Generate-Symmetry',
	BOOLEANUNION = 'Boolean-Union',
	BOOLEANSUBTRACT = 'Boolean-Subtract',
	BOOLEANINTERSECT = 'Boolean-Intersect',
	ACTIONSHOWN = 'Action-Shown',
	ACTIONHIDDEN = 'Action-Hidden',
	ACTIONLOCK = 'Action-Lock',
	ACTIONUNLOCK = 'Action-Unlock',
	ACTIONDUPLICATE = 'Action-Duplicate',
	ACTIONUNLINK = 'Action-Unlink',
	ACTIONBAKE = 'Action-Bake',
	ACTIONRESET = 'Action-Reset',
	ACTIONFITVIEW = 'Action-FitView',
	ACTIONCHAIN = 'Action-Chain',
	ACTIONUNCHAIN = 'Action-Unchain',
	ACTIONEDIT = 'Action-Edit',
	ACTIONDELETE = 'Action-Delete',
	ACTIONSEARCH = 'Action-Search',
	ACTIONUNDO = 'Action-Undo',
	ACTIONREDO = 'Action-Redo',
	ACTIONINVERT = 'Action-Invert',
	ACTIONFLIPNORMALMAP = 'Action-FlipNormalMap',
	ACTIONFLIPNORMALMAPACTIVE = 'Action-FlipNormalMapActive',
	ACTIONSWITCH = 'Action-Switch',
	SNAPDISABLED = 'Snap-Disabled',
	SNAPENABLED = 'Snap-Enabled',
	SNAPSURFACE = 'Snap-Surface',
	SNAPMIDDLE = 'Snap-Middle',
	SNAPEND = 'Snap-End',
	SNAPNEAR = 'Snap-Near',
	SNAPDIVISION = 'Snap-Division',
	SNAPGRID = 'Snap-Grid',
	SNAPANGLE = 'Snap-Angle',
	VIEWTYPETEXTURED = 'ViewType-Textured',
	VIEWTYPESHADED = 'ViewType-Shaded',
	VIEWTYPEWIRED = 'ViewType-Wired',
	VIEWTYPEBOX = 'ViewType-Box',
	CAMERADOF = 'Camera-DOF',
	CAMERAENTER = 'Camera-Enter',
	CAMERAEXIT = 'Camera-Exit',
	EDITBRIDGE = 'Edit-Bridge',
	EDITBEVEL = 'Edit-Bevel',
	EDITEXTRUDE = 'Edit-Extrude',
	EDITSLICE = 'Edit-Slice',
	EDITSLICEADD = 'Edit-SliceAdd',
	EDITCOLLAPSE = 'Edit-Collapse',
	EDITSLIDE = 'Edit-Slide',
	EDITCAP = 'Edit-Cap',
	EDITMAKECIRCLE = 'Edit-MakeCircle',
	EDITHAMMER = 'Edit-Hammer',
	EDITFLIPLINE = 'Edit-FlipLine',
	EDITDRAWHAND = 'Edit-DrawHand',
	EDITDRAW = 'Edit-Draw',
	EDITMODEORIGINAL = 'EditMode-Original',
	EDITMODEEDITED = 'EditMode-Edited',
	OBJECTGROUP = 'Object-Group',
	OBJECTUNGROUP = 'Object-Ungroup',
	OBJECTMERGE = 'Object-Merge',
	OBJECTMORE = 'Object-More',
	OBJECTCOPY = 'Object-Copy',
	OBJECTPASTE = 'Object-Paste',
	OBJECTBLANKPAGE = 'Object-BlankPage',
	TRANSFORMATIONSGIZMO = 'Transformations-Gizmo',
	TRANSFORMATIONSPIVOTTOP = 'Transformations-PivotTop',
	TRANSFORMATIONSPIVOTCENTER = 'Transformations-PivotCenter',
	TRANSFORMATIONSPIVOTBOTTOM = 'Transformations-PivotBottom',
	TRANSFORMATIONSGIZMOLOCAL = 'Transformations-GizmoLocal',
	TRANSFORMATIONSGIZMOGLOBAL = 'Transformations-GizmoGlobal',
	COLORCOLOR = 'Color-Color',
	COLORPIPETTE = 'Color-Pipette',
	RENDERSAMPLES = 'Render-Samples',
	RENDERTIME = 'Render-Time',
	RENDERPREVIEW = 'Render-Preview',
	RENDERREGION = 'Render-Region',
	SIGNSCHECK = 'Signs-Check',
	SIGNSCROSS = 'Signs-Cross',
	SIGNSPLUS = 'Signs-Plus',
	SIGNSMINUS = 'Signs-Minus',
	SIGNSARROWUPDOWN = 'Signs-ArrowUpDown',
	SIGNSARROWLEFT = 'Signs-ArrowLeft',
	SIGNSARROWRIGHT = 'Signs-ArrowRight',
	SIGNSARROWUP = 'Signs-ArrowUp',
	SIGNSARROWDOWN = 'Signs-ArrowDown',
	SIGNSARROWUPBOLD = 'Signs-ArrowUpBold',
	SIGNSARROWDOWNBOLD = 'Signs-ArrowDownBold',
	TEXTCENTER = 'Text-Center',
	TEXTRIGHT = 'Text-Right',
	TEXTLEFT = 'Text-Left',
	TEXTJUSTIFY = 'Text-Justify',
	TEXTCASENONE = 'Text-CaseNone',
	TEXTCASECAPITALIZE = 'Text-CaseCapitalize',
	TEXTCASEUPPERCASE = 'Text-CaseUppercase',
	TEXTCASELOWERCASE = 'Text-CaseLowercase',
	NORMALSFLIP = 'Normals-Flip',
	NORMALSNORMAL = 'Normals-Normal',
	NORMALSUNIFY = 'Normals-Unify',
	NORMALSSMOOTHTHRESHOLD = 'Normals-SmoothThreshold',
	NORMALSSMOOTHTRESHOLDOFF = 'Normals-SmoothTresholdOff',
	TEXTURESTEXTURE = 'Textures-Texture',
	TEXTURESUVPROJECTION = 'Textures-UVProjection',
	TEXTURESBAKER = 'Textures-Baker',
	TEXTURESUNWRAP = 'Textures-Unwrap',
	MISCBLANK = 'Misc-Blank',
	MISCPARAMETERS = 'Misc-Parameters',
	MISCOBJECTLIST = 'Misc-ObjectList',
	MISCSUBDSIMPLE = 'Misc-SubdSimple',
	MISCSUBDCOMPLEX = 'Misc-SubdComplex',
	MISCTARGET = 'Misc-Target',
	ITEMSITEM_1 = 'Items-Item_1',
	ITEMSITEM_3 = 'Items-Item_3',
	ITEMSITEM_2 = 'Items-Item_2',
	STATSPOINT = 'Stats-Point',
	STATSLINE = 'Stats-Line',
	STATSPOLYGON = 'Stats-Polygon',
	STATSMESH = 'Stats-Mesh',
	STATSSAMPLES = 'Stats-Samples',
	STATSTIME = 'Stats-Time',
	MONETIZATIONCOMMERCIALNOT = 'Monetization-CommercialNot',
	MONETIZATIONCOMMERCIAL = 'Monetization-Commercial',
	SOCIALINVITE = 'Social-Invite',
	TEMPDUPLICATE = 'TEMP-Duplicate',
	TEMPUNLINK = 'TEMP-Unlink',
	TEMPTARGET = 'TEMP-Target',
	TEMPHIDE = 'TEMP-Hide',
	TEMPPIPETTE_COLOR = 'TEMP-Pipette_Color',
	TEMPPIPETTE_MATERIAL = 'TEMP-Pipette_Material',
	TEMPSEARCH = 'TEMP-Search',
	TEMPDOWNLOAD = 'TEMP-Download',
	TEMPRESET = 'TEMP-Reset',
	TEMPPLUS = 'TEMP-Plus',
	TEMPMINUS = 'TEMP-Minus',
	TEMPCROSS = 'TEMP-Cross',
	TEMPARROW_DOWN = 'TEMP-Arrow_Down',
	TEMPARROW_UP = 'TEMP-Arrow_Up',
	TEMPARROW_LEFT = 'TEMP-Arrow_Left',
	TEMPARROW_RIGHT = 'TEMP-Arrow_Right',
	TEMPLINK = 'TEMP-Link',
	TEMPBREAK_LINK = 'TEMP-Break_Link',
	TEMPEXPAND_OPENED = 'TEMP-Expand_Opened',
	TEMPEXPAND_CLOSED = 'TEMP-Expand_Closed',
	TEMPDELETE = 'TEMP-Delete',
	TEMPVERTICAL_ELLIPSIS = 'TEMP-Vertical_Ellipsis',
	TEMPUPLOAD = 'TEMP-Upload',
	TEMPMODIFIER = 'TEMP-Modifier',
	TEMPFIT_VIEW = 'TEMP-Fit_View',
	TEMPSNAP = 'TEMP-Snap',
	TEMPRENDER = 'TEMP-Render',
	TEMPSELECT = 'TEMP-Select',
	TEMPPREVIEW = 'TEMP-Preview',
	TEMPBOX = 'TEMP-Box',
	TEMPCONVERT_TO_GEOMETRY = 'TEMP-Convert_To_Geometry',
	TEMPTO = 'TEMP-To',
	TEMPCURVE = 'TEMP-Curve',
	TEMPAR = 'TEMP-AR',
	TEMPGRID = 'TEMP-Grid',
	TEMPTERRAIN = 'TEMP-Terrain',
	TEMPPHYSICS = 'TEMP-Physics',
	TEMPPEN = 'TEMP-Pen',
	TEMPRECTANGLE = 'TEMP-Rectangle',
	TEMPCIRCLE = 'TEMP-Circle',
	TEMPPOLYGON = 'TEMP-Polygon',
	TEMPPIPE = 'TEMP-Pipe',
	TEMPARROW_UP_DOWN = 'TEMP-Arrow_Up_Down',
	TEMPARROW_DOWN_SMALL = 'TEMP-Arrow_Down_Small',
	TEMPHELP = 'TEMP-Help',
	TEMPINFO = 'TEMP-Info',
	TEMPPOINT = 'TEMP-Point',
	TEMPPLUGIN = 'TEMP-Plugin',
	TEMPSEND = 'TEMP-Send',
	TEMPEQUALS = 'TEMP-Equals',
	TEMPMULTIPLY = 'TEMP-Multiply',
	TEMPHOME = 'TEMP-Home',
	TEMPLOCK = 'TEMP-Lock',
	TEMPUNLOCK = 'TEMP-Unlock',
	TEMPZOOM = 'TEMP-Zoom',
	TEMPSHOW = 'TEMP-Show',
	TEMPEDIT = 'TEMP-Edit',
	TEMPPIVOT = 'TEMP-Pivot',
	TEMPWORLD = 'TEMP-World',
	TEMPARROW_UP_SMALL = 'TEMP-Arrow_Up_Small',
	TEMPRADIO_ON = 'TEMP-Radio_ON',
	TEMPRADIO_OFF = 'TEMP-Radio_OFF',
	TEMPOBJECT_SWITCHER = 'TEMP-Object_Switcher',
	TEMPSTARS = 'TEMP-Stars',
	TEMPSCALE = 'TEMP-Scale',
	TEMPMOVE = 'TEMP-Move',
	TEMPSIZE = 'TEMP-Size',
	TEMPROTATE = 'TEMP-Rotate',
	GIZMOGLOBAL = 'Gizmo-Global',
	GIZMOLOCAL = 'Gizmo-Local',
	CHARACTERSX_SEMIBOLD = 'Characters-X_Semibold',
	CHARACTERSX_REGULAR = 'Characters-X_Regular',
	CHARACTERSY_SEMIBOLD = 'Characters-Y_Semibold',
	CHARACTERSY_REGULAR = 'Characters-Y_Regular',
	CHARACTERSZ_SEMIBOLD = 'Characters-Z_Semibold',
	CHARACTERSZ_REGULAR = 'Characters-Z_Regular',
	CHARACTERSQUESTIONMARK = 'Characters-Questionmark',
	CHARACTERSU_REGULAR = 'Characters-U_Regular',
	CHARACTERSH_REGULAR = 'Characters-H_Regular',
	CHARACTERSV_REGULAR = 'Characters-V_Regular',
	CHARACTERSW_REGULAR = 'Characters-W_Regular',
	CHARACTERSU_SEMIBOLD = 'Characters-U_Semibold',
	CHARACTERSH_SEMIBOLD = 'Characters-H_Semibold',
	CHARACTERSV_SEMIBOLD = 'Characters-V_Semibold',
	CHARACTERSW_SEMIBOLD = 'Characters-W_Semibold',
}

export interface IconProps {
	className?: string;
	name: IconEnumName;
	onClick?: () => void;
}

export const Icon: FC<IconProps> = ({ name, className, onClick }) => (
	<IconWrapper onClick={onClick} className={`${className}`}>
		<use href={'./images/icons.svg' + `#${name}`} />;
	</IconWrapper>
);
