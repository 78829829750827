export function removeFromArray<T extends any>(target: T, array: T[]) {
	const index = array.indexOf(target);
	const arr = [...array];
	if (index) {
		arr.splice(index, 1);
	}
	return arr;
}

export function isEqualArray(arr1: any[], arr2: any[]) {
	if (!arr1 || !arr2 || arr1.length !== arr2.length) return false;
	let result = true;
	arr1.map((value, index) => {
		if (value !== arr2[index]) result = false;
	});
	return result;
}

/**
 * Splits given array into a chunks of smaller arrays.
 *
 * @param arr
 * @param size
 * @returns
 */
export function chunkArrayInGroups<T extends any>(
	arr: T[],
	size: number,
): T[][] {
	let myArray = [];

	for (let i = 0, arrLength = arr.length; i < arrLength; i += size) {
		myArray.push(arr.slice(i, i + size));
	}
	return myArray;
}
