export const toCamelCase = (str: string): string => {
	return str
		.replace(/\s(.)/g, function ($1) {
			return $1.toUpperCase();
		})
		.replace(/\s/g, '')
		.replace(/\?/g, '')
		.replace(/\!/g, '')
		.replace(/\#/g, '')
		.replace(/^(.)/, function ($1) {
			return $1.toLowerCase();
		});
};

export function getFileNameFromPath(path: string): string {
	return path.replace(/^.*(\\|\/|\:)/, '');
}

export function getExtensionFromFileName(filename: string): string {
	if (!filename) return '';
	const regexExtensionMatchArray = filename.match(/\.[^/.]+$/);
	return regexExtensionMatchArray
		? regexExtensionMatchArray[0].split('.')[1].toLowerCase()
		: '';
}

export function removeExtension(filename: string): string {
	return filename.replace(/\.[^/.]+$/, '');
}

export function removeProblematicCharsForUrlsAndUris(filename: string) {
	// delims      = "<" | ">" | "#" | "%" | <">
	// unwise      = "{" | "}" | "|" | "\" | "^" | "[" | "]" | "`"
	// reserved    = ";" | "/" | "?" | ":" | "@" | "&" | "=" | "+" | "$" | ","

	return filename.replace(/[<>#%{}\|\\\^\[\]`;\/?:@&=+$,.*\'\"~()]/g, '');
}

export function getNextAvailableNumberedName(
	name: string,
	usedNames: Set<string>,
	startNumber: number = 1,
	separatorBeforeNumber: string = ' ',
): string {
	if (!usedNames.has(name)) {
		return name;
	}

	const values = name.split(separatorBeforeNumber);
	const lastValue = values.pop();

	let baseName: string;
	let nextAvailableNumber: bigint; // use big int to be able to work with numbers greater than 1e20
	try {
		nextAvailableNumber = BigInt(lastValue);
		baseName = values.join(separatorBeforeNumber);
	} catch {
		nextAvailableNumber = BigInt(startNumber);
		baseName = name;
	}

	const getTryName = () =>
		baseName + separatorBeforeNumber + nextAvailableNumber.toString();

	let tryName = getTryName();
	while (true) {
		if (usedNames.has(tryName)) {
			nextAvailableNumber = nextAvailableNumber + BigInt(1);
			if (tryName === getTryName()) {
				// infinite loop
				return tryName + separatorBeforeNumber + '1';
			}
			tryName = getTryName();
			continue;
		}
		return tryName;
	}
}

export function addSuffix(name: string, suffix: string): string {
	return name.endsWith(suffix) ? name : name + suffix;
}

export function isVectaryEmail(email: string): boolean {
	return email?.indexOf('@vectary.com') > -1;
}

export function isVctr3ImportWorkspaceAllowed(workspaceId: string): boolean {
	const allowedWorkspaces = [
		// Tulfa
		'37dcc93e-9b88-4207-be29-8abaef6f1127',
		// Costex
		'f1eb2405-aeb4-489a-a5ed-370112685bef',
	];
	return allowedWorkspaces.includes(workspaceId);
}

export function isLogitechWorkspace(workspaceId: string): boolean {
	return workspaceId === 'b26450f7-d2d7-43ea-9d21-0a825090e636';
}
