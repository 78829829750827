import { isProdPaddle } from '../../plan/client';
import { BillingPeriod, ProductIds } from '../../plan/interface';
import { PlanIds } from '../../plan/plans';
import { WorkspacePlan } from '../interface/interface';
import { Cloud } from '@vctr-libs/vctr-utils';

// Why do we hardcode it and copy three times if can be fetched from Paddle API?
// https://developer.paddle.com/classic/api-reference/a835554495295-list-plans

/**
 * TODO rework this nightmare,
 * reuse ids from `vctr-api-client/plan` (that can be improved as well);
 * look into Resources API as well
 */
export const newToOldId: Record<number, PlanIds> = {
	1: PlanIds.LOCKED,
	2: PlanIds.STARTER_10,
	3: PlanIds.STARTER_25,
	4: PlanIds.SETUP,
	5: PlanIds.EDUCATION,
	11: PlanIds.CUSTOM,
	// Production IDs
	552550: PlanIds.PREMIUM_1,
	555663: PlanIds.VECTARY_1,
	565048: PlanIds.PREMIUM_12,
	571529: PlanIds.PREMIUM_15,
	571531: PlanIds.PREMIUM_15_YEARLY,
	580487: PlanIds.PREMIUM_25,
	580488: PlanIds.PREMIUM_25_YEARLY,
	584280: PlanIds.LITE_9,
	584281: PlanIds.LITE_9_YEARLY,
	594022: PlanIds.COMPANY,
	633635: PlanIds.PREMIUM,
	633636: PlanIds.PREMIUM_YEARLY,
	750924: PlanIds.ENTERPRISE,
	750925: PlanIds.PRO,
	750926: PlanIds.PRO_YEARLY,
	757163: PlanIds.BUSINESS,
	757164: PlanIds.BUSINESS,
	797943: PlanIds.PRO_19,
	797946: PlanIds.PRO_180_YEARLY,
	833761: PlanIds.PRO_2023_06_MONTHLY_19_TRIAL,
	833762: PlanIds.PRO_2023_06_YEARLY_180_TRIAL,
	836566: PlanIds.PRO_2023_06_MONTHLY_19,
	836567: PlanIds.PRO_2023_06_YEARLY_180,
	882769: PlanIds.PRO_2024_06_MONTHLY_29_TRIAL,
	882770: PlanIds.PRO_2024_06_YEARLY_288_TRIAL,
	882771: PlanIds.PRO_2024_06_MONTHLY_29,
	882772: PlanIds.PRO_2024_06_YEARLY_288,

	// Dev IDs
	30513: PlanIds.PRO,
	30545: PlanIds.PRO_YEARLY,
	38601: PlanIds.PRO_2023_06_MONTHLY_19,
	38602: PlanIds.PRO_2023_06_YEARLY_180,
	52953: PlanIds.PRO_2023_06_MONTHLY_19_TRIAL,
	52954: PlanIds.PRO_2023_06_YEARLY_180_TRIAL,
	63654: PlanIds.ENTERPRISE,
	558894: PlanIds.PREMIUM_1,
	558899: PlanIds.VECTARY_1,
	565047: PlanIds.PREMIUM_12,
	571440: PlanIds.PREMIUM_15,
	571441: PlanIds.PREMIUM_15_YEARLY,
	580469: PlanIds.PREMIUM_25,
	580470: PlanIds.PREMIUM_25_YEARLY,
	584085: PlanIds.LITE_9,
	584275: PlanIds.LITE_9_YEARLY,
	633645: PlanIds.PREMIUM,
	633646: PlanIds.PREMIUM_YEARLY,
	635426: PlanIds.COMPANY,
	741085: PlanIds.ENTERPRISE,
	758059: PlanIds.BUSINESS,
	758060: PlanIds.BUSINESS,
};

export const commonIds: Record<string, number> = {
	LOCKED: 1,
	STARTER_10: 2,
	STARTER_25: 3,
	FREE: 3,
	SETUP: 4,
	EDUCATION: 5,
	CUSTOM: 11,
};

const devIds: Record<string, number> = {
	PRO: 30513,
	PRO_YEARLY: 30545,
	PRO_2023_06_MONTHLY_19: 38601,
	PRO_2023_06_YEARLY_180: 38602,
	PRO_2023_06_MONTHLY_19_TRIAL: 52953,
	PRO_2023_06_YEARLY_180_TRIAL: 52954,
	ENTERPRISE: 63654,
	PREMIUM_1: 558894,
	VECTARY_1: 558899,
	PREMIUM_12: 565047,
	PREMIUM_15: 571440,
	PREMIUM_15_YEARLY: 571441,
	PREMIUM_25: 580469,
	PREMIUM_25_YEARLY: 580470,
	LITE_9: 584085,
	LITE_9_YEARLY: 584275,
	PREMIUM: 633645,
	PREMIUM_YEARLY: 633646,
	COMPANY: 635426,
	INVOICE: 635426,
	BUSINESS: 758059,
	PRO_2024_06_MONTHLY_29_TRIAL: 69286,
	PRO_2024_06_YEARLY_288_TRIAL: 69287,
	PRO_2024_06_MONTHLY_29: 69288,
	PRO_2024_06_YEARLY_288: 69289,
};

const prodIds: Record<string, number> = {
	PREMIUM_1: 552550,
	VECTARY_1: 555663,
	PREMIUM_12: 565048,
	PREMIUM_15: 571529,
	PREMIUM_15_YEARLY: 571531,
	PREMIUM_25: 580487,
	PREMIUM_25_YEARLY: 580488,
	LITE_9: 584280,
	LITE_9_YEARLY: 584281,
	COMPANY: 594022,
	INVOICE: 594022,
	PREMIUM: 633635,
	PREMIUM_YEARLY: 633636,
	ENTERPRISE: 750924,
	PRO: 750925,
	PRO_YEARLY: 750926,
	BUSINESS: 757163,
	PRO_19: 797943,
	PRO_180_YEARLY: 797946,
	PRO_2023_06_MONTHLY_19_TRIAL: 833761,
	PRO_2023_06_YEARLY_180_TRIAL: 833762,
	PRO_2023_06_MONTHLY_19: 836566,
	PRO_2023_06_YEARLY_180: 836567,
	PRO_2024_06_MONTHLY_29_TRIAL: 882769,
	PRO_2024_06_YEARLY_288_TRIAL: 882770,
	PRO_2024_06_MONTHLY_29: 882771,
	PRO_2024_06_YEARLY_288: 882772,
};

export const oldToNewId = (id: string, cloud: Cloud) => {
	const idsToUse = isProdPaddle(cloud)
		? { ...commonIds, ...prodIds }
		: { ...commonIds, ...devIds };
	return idsToUse[id];
};

const getSeatLimitFromPermissionSet = (
	permissionSet: any,
	productId: string,
	limitType: 'softLimit' | 'hardLimit' | 'pricePerUnit',
): number | undefined =>
	permissionSet.properties?.members?.[productId]?.[limitType];

const makeOldProductObject = (
	permissionSet: string,
	productId: ProductIds,
	oldPropertyName: string,
): {
	id: ProductIds;
	name: string;
	soft_limit: number | undefined;
	hard_limit: number | undefined;
	price_per_unit: number | undefined;
} => {
	return {
		id: productId,
		name: productId,
		soft_limit:
			getSeatLimitFromPermissionSet(permissionSet, productId, 'softLimit') ??
			// We still have old permissions in the system so keep the backward compatibility.
			getSeatLimitFromPermissionSet(
				permissionSet,
				oldPropertyName,
				'softLimit',
			),
		hard_limit:
			getSeatLimitFromPermissionSet(permissionSet, productId, 'hardLimit') ??
			// We still have old permissions in the system so keep the backward compatibility.
			getSeatLimitFromPermissionSet(
				permissionSet,
				oldPropertyName,
				'hardLimit',
			),
		price_per_unit: getSeatLimitFromPermissionSet(
			permissionSet,
			productId,
			'pricePerUnit',
		),
	};
};

export const permissionSetToOldPlan = (
	permissionSet: any,
	paddleDetails: any,
): WorkspacePlan => ({
	id:
		newToOldId[permissionSet.id] ??
		((permissionSet.name as string).toUpperCase() as PlanIds),
	name: permissionSet.name,
	product_list: [
		{
			id: ProductIds.PROJECT,
			name: ProductIds.PROJECT,
			soft_limit: permissionSet.properties?.projects?.softLimit,
			hard_limit: permissionSet.properties?.projects?.hardLimit,
			price_per_unit: permissionSet.properties?.projects?.pricePerUnit,
		},
		{
			id: ProductIds.VIEW,
			name: ProductIds.VIEW,
			soft_limit: permissionSet.properties?.views?.softLimit,
			hard_limit: permissionSet.properties?.views?.hardLimit,
			price_per_unit: permissionSet.properties?.views?.pricePerUnit,
		},
		{
			id: ProductIds.EXPORT,
			name: ProductIds.EXPORT,
			formats: permissionSet.properties?.exports?.formats,
		},
		...[makeOldProductObject(permissionSet, ProductIds.SEAT_VIEW, 'viewers')],
		...[makeOldProductObject(permissionSet, ProductIds.SEAT_EDIT, 'editors')],
		// Include the new permissions as of Q2 2024.
		...[
			ProductIds.SEAT_MANAGE_2024,
			ProductIds.SEAT_COLLABORATE_2024,
			ProductIds.SEAT_VIEW_2024,
			ProductIds.SEAT_EDIT_2024,
		].map(productId => {
			return {
				id: productId,
				name: productId,
				soft_limit: permissionSet.properties?.members?.[productId]?.softLimit,
				hard_limit: permissionSet.properties?.members?.[productId]?.hardLimit,
				price_per_unit:
					permissionSet.properties?.members?.[productId]?.pricePerUnit,
			};
		}),
	],
	internal_note: permissionSet.internalNote,
	price: paddleDetails?.price ?? 0,
	billing:
		paddleDetails?.recurringInterval === 'P1Y0M0DT0H0M0S'
			? BillingPeriod.YEAR
			: BillingPeriod.MONTH,
	baseSubId: paddleDetails?.subscriptionId,
	nextBillDate: paddleDetails?.nextPaymentDate?.toISOString().split('T')[0],
	cancellationEffectiveDate: paddleDetails?.cancellationEffectiveDate
		?.toISOString()
		.split('T')[0],
});
