// Sources and their types

export enum DashboardLoggerSource {
	Dashboard = 'dashboard',
	CheckoutPaddle = 'checkout_paddle',
}

export const UpgradeWorkspaceSource = [
	'locked_workspace',
	'projects_limit',
] as const;

export type UpgradeWorkspaceSource = (typeof UpgradeWorkspaceSource)[number];

export const UpgradeToProClickSource = [
	'workspace_settings_menu',
	'workspace_settings',
] as const;

export type UpgradeToProClickSource = (typeof UpgradeToProClickSource)[number];
