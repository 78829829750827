import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components';

const SelectSC = styled.select`
	display: flex;
	min-height: 26px;
`;

export type SelectOption = {
	title: string;
	value: string;
};

export type SelectProps = {
	options: SelectOption[];
	value: string | null;
	onChange: (selectedValue: string) => void;
};

export const Select: FC<SelectProps> = props => {
	const { options, value, onChange } = props;
	const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const selectedValue = event.target.value;

		onChange(selectedValue);
	};

	return (
		<SelectSC value={value} onChange={handleOnChange}>
			{options.map(option => {
				return (
					<option key={`value_${option.value}`} value={option.value}>
						{option.title}
					</option>
				);
			})}
		</SelectSC>
	);
};
