import {
	Visibility,
	WorkspaceBase
} from '../interface/interface';
import { PermissionEnumValues } from '../interface/permissionRoles';
import { GraphQLTypes, PermissionEnum } from '@vctr-api/vctr-resources/sdk';
import { permissionSetToOldPlan } from '../utils/resourcesApiUtils';


export const convertResourcesApiWorkspacesToOld = (
	items: Array<GraphQLTypes['UserWorkspaceNode']>
) => {
	// see enum PermissionEnum from Resources (generated "const enum")

	const workspaces: Record<PermissionEnum, WorkspaceBase[]> = (() => {
		const entries = PermissionEnumValues.map(p => [p, []]);
		const _workspaces = Object.fromEntries(entries);
		return _workspaces;
	})();

	items.forEach(workspace => {
		const processedWorkspace = {
			uuid: workspace.id,
			name: workspace.name,
			owner_id: workspace.owner.id,
			customDomain: workspace.customDomain,
			picture: workspace.picture,
			root_name: 'default',
			visibility: Visibility.PUBLIC,
			plan: permissionSetToOldPlan(
				workspace.permissions,
				workspace.paddleDetails
			),
			projectsCount: 0,
		};

		if (workspace.folders) {
			processedWorkspace.projectsCount = workspace.folders.items.reduce(
				(accumulator, currentValue) => accumulator + currentValue.projects.items.length,
				0
			);
		}

		if (workspace.permission) {
			workspaces[workspace.permission].push(processedWorkspace);
		} else {
			console.log("missing workspace.permission");
		}
	});

	return workspaces;
};
