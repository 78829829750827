/**
 * DiscoverPro container component for studio
 */

import React, { FC } from 'react';
// TODO: Remove this ignore when all necessary packages like styled-component will support React 18 types.
// @ts-ignore
import { createRoot } from 'react-dom/client';
import { PlanIds } from '@vctr-libs/vctr-api-client';

import { StudioReset } from '../../styles/studioReset';
import { DiscoverPro as DiscoverProFC } from '.';

export type DiscoverProStudioProps = {
	showModalInitial?: boolean;
	logEventOnShow?: () => void;
	logEventOpenModal?: () => void;
	modalZIndex?: number;
	assetsPath?: string;
	imagesPath?: string;
	workspaceUuid?: string;
	autoplay?: boolean;
	initialSlide?: number;
};

const DEFAULT_PRO_PLAN = PlanIds.PRO_2024_06_YEARLY_288;

export const DiscoverProComponent: FC<DiscoverProStudioProps> = (
	props: DiscoverProStudioProps,
) => {
	const {
		workspaceUuid,
		showModalInitial,
		logEventOnShow,
		logEventOpenModal,
		assetsPath = './',
		imagesPath = './images',
		autoplay = true,
		initialSlide = 0,
	} = props;
	return (
		<StudioReset>
			<DiscoverProFC
				showModalInitial={showModalInitial}
				handleGetProClick={() =>
					window.open(
						`/dashboard/?buy=${DEFAULT_PRO_PLAN}${
							workspaceUuid ? `&workspaceId=${workspaceUuid}` : ``
						}`,
						'_blank',
					)
				}
				handleComparePlansClick={() =>
					window.open(
						window.location.origin.replace('app', 'www') + '/pricing/',
						'_blank',
					)
				}
				logEventOnShow={() => logEventOnShow()}
				logEventOpenModal={() => logEventOpenModal()}
				modalZIndex={53}
				assetsPath={assetsPath}
				imagesPath={imagesPath}
				autoplay={autoplay}
				initialSlide={initialSlide}
			/>
		</StudioReset>
	);
};

export const DiscoverProStudio = {
	render: (rootEl: HTMLElement, props: DiscoverProStudioProps) => {
		const root = createRoot(rootEl);
		return root.render(<DiscoverProComponent {...props} />);
	},
};
