import {
	Box,
	Breakpoint,
	Container,
	Grid,
	SxProps,
	ThemeProvider,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { getSignupinTheme } from '../../../ui';

interface VeContainerProps {
	children: ReactNode;
	htmlFontSize?: number;
	maxWidth?: Breakpoint;
	sx?: SxProps;
}

export function VeSignupinContainer(props: VeContainerProps) {
	const {
		children,
		htmlFontSize = 16,
		maxWidth = 'xs',
		sx = { height: '100%' },
	} = props;
	const theme = getSignupinTheme(htmlFontSize);

	return (
		<ThemeProvider theme={theme}>
			<Container {...{ maxWidth, sx }}>
				<Grid
					container
					direction="column"
					alignItems="center"
					justifyContent="center"
					minHeight="100%"
				>
					<Box mt={2} mb={2}>
						{children}
					</Box>
				</Grid>
			</Container>
		</ThemeProvider>
	);
}
