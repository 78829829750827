type CopyTextToClipboardArgs = {
	text: string;
	callback?: () => void;
};
export async function copyTextToClipboard(args: CopyTextToClipboardArgs) {
	const { text, callback } = args;

	if ('clipboard' in navigator) {
		await navigator.clipboard.writeText(text);
	} else {
		document.execCommand('copy', true, text);
	}

	if (callback) callback();
}
