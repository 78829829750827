import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import {
	WorkspacePlan,
	getReadablePlanName,
	getPlanTypeDashboard,
	PlanTypeDashboard,
	isWorkspacePlanCancelled,
} from '@vctr-libs/vctr-api-client';
import { H_StudioHeading } from '../../../ui';
import { BoxContent } from '../../_uko/BoxContent';

export interface BillingSectionProps
	extends getBillingSubscriptionComponentsArgs {}

export const BillingSubscription: React.FC<BillingSectionProps> = props => {
	const { plan } = props;

	if (!plan) return null;
	const billingComponents = getBillingSubscriptionComponents(props);

	return (
		<BoxContent>
			<H_StudioHeading mb={2}>Billing & subscriptions</H_StudioHeading>

			<Typography variant="body1" paragraph>
				{billingComponents?.text}
			</Typography>

			<Stack direction="row" spacing={1} flexWrap="wrap">
				{billingComponents?.actions}
			</Stack>
		</BoxContent>
	);
};

type getBillingSubscriptionComponentsArgs = {
	plan: WorkspacePlan;
	handleCancelSubscriptionClick: () => void;
	handleContactSales: () => void;
	handleUpgradePlanClick: () => void;
	handleComparePlansClick: () => void;
};

export const getBillingSubscriptionComponents = ({
	plan,
	handleCancelSubscriptionClick,
	handleContactSales,
	handleUpgradePlanClick,
	handleComparePlansClick,
}: getBillingSubscriptionComponentsArgs): {
	text: React.ReactNode;
	actions: React.ReactNode;
} => {
	// getPlanTypeDashboard is undefined sometimes for mysterious reasons
	if (!plan || !getPlanTypeDashboard) return null;

	const planType = getPlanTypeDashboard(plan.id);

	const planName = getReadablePlanName(plan);

	const ContactSalesButton = (
		<Button
			variant="contained-pale"
			color="primary"
			onClick={handleContactSales}
			aria-label="Contact sales team"
		>
			Contact sales
		</Button>
	);

	const CancelSubscriptionButton = (
		<Button
			variant="outlined"
			color="error"
			onClick={handleCancelSubscriptionClick}
			aria-label="Cancel current subscription"
		>
			Cancel subscription
		</Button>
	);

	const UpgradePlanButton = (
		<Button
			variant="contained"
			color="primary"
			onClick={handleUpgradePlanClick}
			aria-label="Upgrade plan"
		>
			Upgrade plan
		</Button>
	);

	const ComparePlansButton = (
		<Button
			variant="outlined"
			// color="default"
			onClick={handleComparePlansClick}
			aria-label="Compare plans"
		>
			Compare plans
		</Button>
	);

	if (planType === PlanTypeDashboard.PRO && !isWorkspacePlanCancelled(plan))
		return {
			text: (
				<>
					Your <strong>{planName}</strong> subscription in this workspace will{' '}
					<strong>renew on {plan.nextBillDate}.</strong> Need more features or a
					custom plan? Contact sales to explore Business options.
				</>
			),
			actions: (
				<>
					{ContactSalesButton}
					{CancelSubscriptionButton}
				</>
			),
		};

	if (planType === PlanTypeDashboard.PRO && isWorkspacePlanCancelled(plan))
		return {
			text: (
				<>
					Your Pro plan has been cancelled and will expire soon. To continue
					enjoying all features without interruption, renew your plan or
					consider upgrading to a different plan.
				</>
			),
			actions: (
				<>
					{UpgradePlanButton}
					{ComparePlansButton}
				</>
			),
		};

	if (planType === PlanTypeDashboard.ENTERPRISE)
		return {
			text: (
				<>
					Your workspace is on an <strong>{planName}</strong> plan. For any
					additional needs or custom solutions, please contact our sales team.
				</>
			),
			actions: <>{ContactSalesButton}</>,
		};

	if (planType === PlanTypeDashboard.STARTER)
		return {
			text: (
				<>
					Your workspace is on a free <strong>{planName}</strong> plan. Upgrade
					to unlock more features and tools. Need even more? Contact sales for
					Business options.
				</>
			),
			actions: (
				<>
					{UpgradePlanButton}
					{ContactSalesButton}
				</>
			),
		};

	if (planType === PlanTypeDashboard.LOCKED)
		return {
			text: (
				<>
					Your workspace is currently locked due to an inactive plan. To gain
					full access, please upgrade your subscription. For any additional
					needs or custom solutions, please contact our sales team.
				</>
			),
			actions: (
				<>
					{UpgradePlanButton}
					{ContactSalesButton}
				</>
			),
		};

	return {
		text: (
			<>
				Your workspace is on a <strong>{planName}</strong> plan. For any
				additional needs or custom solutions, please contact our sales team.
			</>
		),
		actions: <>{ContactSalesButton}</>,
	};
};
