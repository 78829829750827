// if debouncedFunction is called more than every "waitTime" miliseconds, the func will be called only once (at the end)
export function debounce<T>(func: (param?: T) => void, waitTime: number = 25): (param?: T) => void {
    let indebounce: any;
    let lastParam: T;

    const endWait = () => func(lastParam);

    const debouncedFunction = (param: T) => {
        lastParam = param;
        clearTimeout(indebounce);
        indebounce = setTimeout(endWait, waitTime);
    }

    return debouncedFunction;
}

// if throttledFunction is called more than every "waitTime" miliseconds, the func will be called once per "waitTime"
export function throttle<T>(func: (param?: T) => void, waitTime: number = 25): (param?: T) => void {
    let triggeredDuringWaitPeriod: boolean = false;
    let triggeredDuringWaitPeriodParam: T = null;
    let isWaitPeriod = false;

    const endWaitPeriod = () => {
        isWaitPeriod = false;

        if (triggeredDuringWaitPeriod) {
            throttledFunction(triggeredDuringWaitPeriodParam);
        }

        triggeredDuringWaitPeriod = null;
    };

    const throttledFunction = (param: T) => {
        if (isWaitPeriod) {
            triggeredDuringWaitPeriod = true;
            triggeredDuringWaitPeriodParam = param;
        } else {
            isWaitPeriod = true;
            setTimeout(endWaitPeriod, waitTime);
            func(param);
        }
    };

    return throttledFunction;
}

export function timeoutedResolve(resolve: <T>(T: any) => void, value: any, t = 50) {
    setTimeout(() => {
        resolve(value);
    }, t);
}

export async function asyncTimeout(t: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        setTimeout(resolve, t);
    });
}
