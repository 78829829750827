import { Product, ProductIds, ExportFormats } from './interface';
const proExportFormats = [
	ExportFormats.GLB,
	ExportFormats.GLTF,
	ExportFormats.USDZ,
];

export const STARTER_product_list: Product[] = [
	{
		id: ProductIds.VIEW,
		name: 'Free views with watermark',
		soft_limit: null,
		hard_limit: 2000,
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: 25,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats).filter(
			format => !proExportFormats.includes(format),
		), // Free exports only
	},
];
export const STARTER_10_product_list: Product[] = [
	{
		id: ProductIds.VIEW,
		name: 'Free views with watermark',
		soft_limit: null,
		hard_limit: 2000,
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: 10,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats).filter(
			format => !proExportFormats.includes(format),
		), // Free exports only
	},
];

export const EDUCATION_product_list = STARTER_10_product_list;

export const LOCKED_product_list: Product[] = []; // Locked workspaces cannot be interacted with

export const PRO_2022_product_list: Product[] = [
	{
		id: ProductIds.VIEW,
		name: 'Free views with watermark',
		soft_limit: 100000,
		hard_limit: 200000,
	},
	{
		id: ProductIds.HIDDEN_WATERMAK,
		name: "Views don't show watermark",
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats),
	},
];

export const PRO_2023_06_product_list: Product[] = [
	{
		id: ProductIds.VIEW,
		name: 'Free views with watermark',
		soft_limit: 100000,
		hard_limit: 200000,
	},
	{
		id: ProductIds.HIDDEN_WATERMAK,
		name: "Views don't show watermark",
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: 100,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats),
	},
];

export const PRO_2024_06_product_list: Product[] = PRO_2023_06_product_list;

export const SETUP_product_list: Product[] = {
	...PRO_2023_06_product_list,
	...{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: 1,
		hard_limit: 1,
	},
};

export const PRO_product_list: Product[] = [
	{
		id: ProductIds.VIEW,
		name: 'Free views with watermark',
		soft_limit: 10000,
		hard_limit: 20000,
	},
	{
		id: ProductIds.HIDDEN_WATERMAK,
		name: "Views don't show watermark",
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats),
	},
];

export const BUSINESS_product_list: Product[] = [
	{
		id: ProductIds.VIEW,
		name: 'Free views',
		soft_limit: 250000,
		hard_limit: -1,
	},
	{
		id: ProductIds.HIDDEN_WATERMAK,
		name: "Views don't show watermark",
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats),
	},
	{
		id: ProductIds.SEAT_VIEW,
		name: 'View seats',
		price_per_unit: 0,
		soft_limit: null,
		hard_limit: 10,
	},
	{
		id: ProductIds.SEAT_EDIT,
		name: 'Edit seats',
		price_per_unit: 0,
		soft_limit: null,
		hard_limit: 10,
	},
];
export const CUSTOM_product_list: Product[] = [
	{
		id: ProductIds.VIEW,
		name: 'Views',
		soft_limit: 30000,
		hard_limit: -1,
	},
	{
		id: ProductIds.HIDDEN_WATERMAK,
		name: "Views don't show watermark",
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats), // All exports
	},
	{
		id: ProductIds.SEAT_VIEW,
		name: 'View seats',
		price_per_unit: 0,
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.SEAT_EDIT,
		name: 'Edit seats',
		price_per_unit: 0,
		soft_limit: null,
		hard_limit: -1,
	},
];
export const ENTERPRISE_product_list: Product[] = [
	{
		id: ProductIds.VIEW,
		name: 'Views',
		soft_limit: 20000,
		hard_limit: -1,
	},
	{
		id: ProductIds.HIDDEN_WATERMAK,
		name: "Views don't show watermark",
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats), // All exports
	},
	{
		id: ProductIds.SEAT_VIEW,
		name: 'View seats',
		price_per_unit: 0,
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.SEAT_EDIT,
		name: 'Edit seats',
		price_per_unit: 0,
		soft_limit: null,
		hard_limit: -1,
	},
];
export const COMPANY_product_list: Product[] = [
	{
		id: ProductIds.SEAT_LEGACY_VIEW,
		name: 'Legacy view seats',
		soft_limit: 30,
		hard_limit: 0,
	},
	{
		id: ProductIds.SEAT_VIEW,
		name: 'View seats',
		price_per_unit: 0,
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.SEAT_EDIT,
		name: 'Edit seats',
		price_per_unit: 0,
		soft_limit: 3,
		hard_limit: -1,
	},
	{
		id: ProductIds.VIEW,
		name: 'Views',
		soft_limit: 1000,
		hard_limit: 4000,
	},
	{
		id: ProductIds.HIDDEN_WATERMAK,
		name: "Views don't show watermark",
	},
	{
		id: ProductIds.TEAM,
		name: 'Teams',
		soft_limit: null,
		hard_limit: 1,
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats), // All exports
	},
];
export const FREE_product_list: Product[] = [
	{
		id: ProductIds.VIEW,
		name: 'Free views with watermark',
		soft_limit: 10000,
		hard_limit: 50000, // 50K
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: 25,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: [ExportFormats.OBJ, ExportFormats.STL],
	},
];
export const PREMIUM_product_list: Product[] = [
	{
		id: ProductIds.SEAT_LEGACY_VIEW,
		name: 'Legacy view seats',
		soft_limit: 30,
		hard_limit: 0,
	},
	{
		id: ProductIds.SEAT_VIEW,
		name: 'View seats',
		price_per_unit: 12,
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.SEAT_EDIT,
		name: 'Edit seats',
		price_per_unit: 12,
		soft_limit: 20,
		hard_limit: -1,
	},
	{
		id: ProductIds.VIEW,
		name: 'Free views with watermark',
		soft_limit: 10000,
		hard_limit: 50000, // 50K
	},
	{
		id: ProductIds.TEAM,
		name: 'Teams',
		soft_limit: null,
		hard_limit: 3,
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats), // All exports
	},
];
export const OLD_PREMIUM_product_list: Product[] = [
	{
		id: ProductIds.SEAT_LEGACY_VIEW,
		name: 'Legacy view seats',
		soft_limit: 30,
		hard_limit: 0,
	},
	{
		id: ProductIds.SEAT_VIEW,
		name: 'View seats',
		price_per_unit: 12,
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.SEAT_EDIT,
		name: 'Edit seats',
		price_per_unit: 12,
		soft_limit: 20,
		hard_limit: -1,
	},
	{
		id: ProductIds.VIEW,
		name: 'Views',
		price_per_unit: 0,
		soft_limit: 1000,
		hard_limit: 2000,
	},
	{
		id: ProductIds.TEAM,
		name: 'Teams',
		soft_limit: null,
		hard_limit: 3,
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats), // All exports
	},
];
export const OLD_UNLIMITTED_product_list: Product[] = [
	{
		id: ProductIds.SEAT_LEGACY_VIEW,
		name: 'Legacy view seats',
		soft_limit: 30,
		hard_limit: 0,
	},
	{
		id: ProductIds.SEAT_VIEW,
		name: 'View seats',
		price_per_unit: 0,
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.SEAT_EDIT,
		name: 'Edit seats',
		price_per_unit: 0,
		soft_limit: 20,
		hard_limit: -1,
	},
	{
		id: ProductIds.VIEW,
		name: 'Views',
		soft_limit: -1,
		hard_limit: null,
	},
	{
		id: ProductIds.TEAM,
		name: 'Teams',
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.PROJECT,
		name: 'Projects',
		soft_limit: null,
		hard_limit: -1,
	},
	{
		id: ProductIds.EXPORT,
		name: '3D Exports',
		formats: Object.values(ExportFormats), // All exports
	},
];
