import React from 'react';
import toast from 'react-hot-toast';
import { copyTextToClipboard } from '@vctr-libs/vctr-utils';
import { Link as LinkIcon } from '@mui/icons-material';
import {
	WorkspaceInvitation,
	getInvitationLink,
} from '@vctr-libs/vctr-api-client';
import {
	MemberTableItemName,
	MemberTableLinkInvitation__ContentLink,
	MemberTableLinkInvitation__Icon,
	MemberTableItemLinkIcon,
} from './membersTable/styledComponents';
import { Icon, IconEnumName } from '..';

export function MemberTable__Item__LinkInvitation(
	props: Pick<WorkspaceInvitation, 'uuid'>,
) {
	const { uuid } = props;
	return (
		<MemberTableLinkInvitation__ContentLink
			underline="none"
			color="text"
			onClick={() =>
				copyTextToClipboard({
					text: getInvitationLink({
						invitationUuid: uuid,
					}),
					callback: () => toast('Invite link has been copied to clipboard.'),
				})
			}
		>
			<MemberTableItemLinkIcon>
				<LinkIcon />
			</MemberTableItemLinkIcon>
			<MemberTableItemName>
				{getInvitationLink({ invitationUuid: uuid })}
			</MemberTableItemName>
			<MemberTableLinkInvitation__Icon>
				<Icon
					className="MembersList__CopyInvitationLinkButton"
					name={IconEnumName.OBJECTCOPY}
				/>
			</MemberTableLinkInvitation__Icon>{' '}
		</MemberTableLinkInvitation__ContentLink>
	);
}
