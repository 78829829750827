import fetch from 'cross-fetch';
import { Env } from './env';

export type FetchMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type Cloud = Env | string;

export enum UrlProtocol {
	HTTP = 'http',
	HTTPS = 'https',
}

export const createCloudUrl = (cloud: string): string => {
	if (cloud.indexOf('http://localhost:') >= 0) return '';
	return Object.values(Env).includes(cloud as Env)
		? `https://${cloud}.vectary.com`
		: `${cloud}`;
};

function getWwwPrefix(cloud: string) {
	return cloud === Env.Prod ? '' : `www.`;
}

export const createCloudUrlWithWww = (cloud: string): string => {
	const www = getWwwPrefix(cloud);
	return Object.values(Env).includes(cloud as Env)
		? `https://${www}${cloud}.vectary.com`
		: `${cloud}`;
};

export const createCloudStudioUrl = (cloud: string): string => {
	const cloudFormatted = cloud === Env.Prod ? '' : `.${cloud}`;
	return Object.values(Env).includes(cloud as Env)
		? `https://app${cloudFormatted}.vectary.com`
		: `${cloud}`;
};

export const createCloudHostnameWithWww = (cloud: string): string => {
	return Object.values(Env).includes(cloud as Env)
		? `${cloud}.vectary.com`
		: `${cloud}`;
};

export const vctrFetch = (
	input: RequestInfo,
	init: RequestInit,
	cloud?: Env | string,
): Promise<Response> => {
	let url = '';

	if (cloud) {
		url += createCloudUrlWithWww(cloud);
	} else if (
		typeof window !== 'undefined' &&
		window?.location?.hostname?.indexOf('vectary.com') === -1 &&
		window?.location?.hostname !== 'localhost' &&
		!input.toString().startsWith('http')
	) {
		// We're running in browser and no cloud was provided + we're not running on vectary domain or localhost
		// Set URL to prod
		url += createCloudUrl(Env.Prod);
	}

	url += input; /* <- Fails here when running repo + app locally:
    if the cloud parameter is set -> "http:localhost:8000http:localhost:7001/..." -> wrong URL */
	return fetch(url, init);
};

export const fetchRequest = (
	method: FetchMethod = 'GET',
	body?: string | FormData,
	auth?: object,
	signal?: AbortSignal,
): RequestInit => {
	const headers = {};
	if (body && (typeof body === 'string' || body instanceof String)) {
		Object.assign(headers, {
			'Content-Type': 'application/json',
		});
	}
	if (auth) {
		Object.assign(headers, auth);
	}

	const param: RequestInit = {
		method, // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, cors, *same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers,
		redirect: 'follow', // manual, *follow, error
		referrer: 'no-referrer', // no-referrer, *client,
	};

	if (body) {
		param.body = body;
	}
	if (signal) {
		param.signal = signal;
	}

	return param;
};

function logFetchResponseError(response: Response) {
	console.log(JSON.stringify(response));
	console.error(`Fetch error: ${response.status}`);
}

export function fetchResult<T>(r: Response): Promise<T> {
	if (r.status >= 400) logFetchResponseError(r);
	return r.status >= 400 ? Promise.reject(r.status) : Promise.resolve(r.json());
}

export function fetchResultText(r: Response): Promise<string> {
	if (r.status >= 400) logFetchResponseError(r);
	return r.status >= 400 ? Promise.reject(r.status) : Promise.resolve(r.text());
}

export function fetchResultArrayBuffer(r: Response): Promise<ArrayBuffer> {
	if (r.status >= 400) logFetchResponseError(r);
	return r.status >= 400
		? Promise.reject(r.status)
		: Promise.resolve(r.arrayBuffer());
}

export function fetchResultBlob(r: Response): Promise<Blob> {
	if (r.status >= 400) logFetchResponseError(r);
	return r.status >= 400 ? Promise.reject(r.status) : Promise.resolve(r.blob());
}

export function fetchResultAndStatus<T>(r: Response): Promise<T> {
	return new Promise(async (resolve, reject) => {
		if (!r.ok) {
			reject(r.status);
		} else {
			const response = await r.json();
			resolve({
				...response,
				responseStatus: r.status,
			});
		}
	});
}

export function fetchResultAndError<T>(r: Response): Promise<T> {
	return r.status >= 400 ? Promise.reject(r.json()) : Promise.resolve(r.json());
}

export function getSecureState(): string {
	const arr = new Uint16Array(8);
	window.crypto.getRandomValues(arr);
	return arr.toString().replace(/,/g, '-');
}

// HOW DO COOKIES EXPIRE?
// i was expecting this code not to work, because if c=val;expires... and you split all by ";", it removes the expire part
// however, cookies' expiry is the sole responsibility of the browser, you don't have to handle it
// please play in console first
export function getCookie(cookieName: string): string {
	const decodedCookie = decodeURIComponent(document.cookie);
	const cookies = decodedCookie.split(';');
	for (let i = 0; i < cookies.length; i++) {
		let cookie = cookies[i];
		while (cookie.charAt(0) === ' ') {
			cookie = cookie.substring(1);
		}
		if (cookie.indexOf(cookieName) === 0) {
			return cookie.substring(cookieName.length + 1, cookie.length);
		}
	}
	return '';
}

export function setCookie(
	cookieName: string,
	token: string,
	expires: number,
): void {
	const date = new Date();
	date.setTime(date.getTime() + expires * 1000);
	document.cookie = `${cookieName}=${token};expires=${date.toUTCString()}`;
}

interface AuthHeaders {
	'x-vctr-user-uuid': string;
	'x-vctr-user-name': string;
	'x-vctr-user-email': string;
	'x-vctr-user-roles': string[];
}

const base64 = (text: string) => Buffer.from(text).toString('base64');

export const getServiceHeaders = (
	uuid: string,
	name: string,
	email: string,
	roles: string,
): AuthHeaders => {
	return {
		'x-vctr-user-uuid': uuid,
		'x-vctr-user-name': base64(name),
		'x-vctr-user-email': email,
		'x-vctr-user-roles': [roles],
	};
};

export enum Role {
	ADMIN = 'administrator',
	MEMBER = 'member',
	NON_AUTH = 'non-authorized',
	WEBHOOK = 'webhook',
	SERVICE = 'service',
}

export function isValidHttpUrl(urlString: string): boolean {
	let url;

	try {
		url = new URL(urlString);
	} catch (_) {
		return false;
	}

	return (
		url.protocol === `${UrlProtocol.HTTP}:` ||
		url.protocol === `${UrlProtocol.HTTPS}:`
	);
}
export function makeUrlValid(urlString: string): string | null {
	let url = urlString;
	if (!new RegExp(/^(http:\/\/|https:\/\/)/).test(urlString)) {
		url = `${UrlProtocol.HTTPS}://${urlString}`;
	}
	return isValidHttpUrl(url) ? url : null;
}
