import { Signal } from './signal';

interface NotificationConfig {
	title: string;
	text?: string;
	error?: Error;
	autoCloseInMs?: number;
	callback?: () => void;
	callbackButtonText?: string;
	sigRequestClose?: Signal<any>;
}

export class CenterNotifications {
	readonly sigNotification = new Signal<NotificationConfig>();

	add(conf: NotificationConfig): void {
		this.sigNotification.emit(conf);
	}
}
