import { arrayBufferToObject, loadFile } from './blobUtils';
import { getExtensionFromFileName, removeExtension } from './string';
import {
	getViewPortSizeInUserUnits,
	readSvgFileAsSVGElement,
} from './svgUtils';
export class FileData {
	file: Blob;
	name: string;
	extension: string;
	// @ts-ignore
	private $nominal!: never;

	constructor(file: Blob, name: string, extension: string) {
		this.file = file;
		this.name = name;
		this.extension = extension;
	}
}
export function downloadLink(
	url: string,
	downloadAttribute: string = '',
): void {
	const a = document.createElement('a');
	a.href = url;
	a.setAttribute('download', downloadAttribute);
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}
export function downloadBlob(blob: Blob, downloadAttribute: string = ''): void {
	if (blob.type && downloadAttribute) {
		const filenameExtension = '.' + getExtensionFromFileName(downloadAttribute);
		const mimetypeExtension = getExtensionFromMimeType(blob.type);
		if (filenameExtension !== mimetypeExtension) {
			downloadAttribute += mimetypeExtension;
		}
	}

	let url = window.URL.createObjectURL(blob);
	downloadLink(url, downloadAttribute);
	setTimeout(() => {
		window.URL.revokeObjectURL(url);
	}, 1000);
}

export const exportCSVFile = (
	items: { [key: string]: string }[],
	fileTitle: string,
) => {
	const keys = [].concat(Object.keys(items[0]), '\n');
	const toBlob = [keys.join(',')];
	items.forEach(item => {
		toBlob.push(
			[]
				.concat(
					Object.keys(item).map(key => item[key]),
					'\n',
				)
				.join(','),
		);
	});
	const fileName = fileTitle + '.csv';
	const blob = new Blob(toBlob, { type: 'text/csv;charset=utf-8;' });

	const link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = fileName;
	link.style.visibility = 'hidden';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const isZip = (file: File) => {
	const zipMimes = [
		'application/zip',
		'application/x-zip',
		'application/x-zip-compressed',
		'application/octet-stream',
		'application/x-compress',
		'application/x-compressed',
		'multipart/x-zip',
	];
	return zipMimes.some(zm => zm === file.type);
};

export function getImageArraybufferMimeType(arraybuffer: ArrayBuffer): string {
	const uint = new Uint8Array(arraybuffer);
	if (uint.length < 4) {
		return '';
	}
	let hex: string =
		uint[0].toString(16) +
		uint[1].toString(16) +
		uint[2].toString(16) +
		uint[3].toString(16);
	hex = hex.toUpperCase();

	switch (hex) {
		case '89504E47':
			return 'image/png';
		case '47494638':
			return 'image/gif';
		case '25504446':
			return 'application/pdf';
		case '3C3F786D':
		case '3C737667':
			return 'image/svg+xml';
		case 'FFD8FFDB':
		case 'FFD8FFE0':
		case 'FFD8FFE1':
			return 'image/jpeg';
		case '504B0304':
			return 'application/zip';
		default:
			// There is no reliable way to tell if something is a json
			// therefore, we can assume everything that is not a defined type is josn.
			// if this fails for some specific type please register that mimetype above.
			return 'application/json';
	}
}

export function getImageBlobMimeType(imageBlob: Blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		const firstFourBytesBlob = imageBlob.slice(0, 4);

		reader.onloadend = () => {
			resolve(getImageArraybufferMimeType(reader.result as ArrayBuffer));
		};

		reader.readAsArrayBuffer(firstFourBytesBlob);
	});
}

export function getExtensionFromMimeType(mimeType: string): string {
	switch (mimeType) {
		case 'image/png':
			return '.png';
		case 'image/gif':
			return '.gif';
		case 'image/jpeg':
			return '.jpg';
		case 'image/svg+xml':
			return '.svg';
		case 'application/zip':
			return '.zip';
		case 'application/pdf':
			return '.pdf';
		case 'application/json':
			return '.json';
		case 'font/otf':
			return '.otf';
		case 'font/ttf':
			return '.ttf';
		case 'font/woff':
			return '.woff';
		case 'image/svg+xml':
			return '.svg';
		case 'image/vnd.radiance':
			return '.hdr';
		default:
			return '';
	}
}

export function getMimeTypeFromExt(ext: string): string {
	switch (ext.toLowerCase()) {
		// Image types (we can get any kind from fbx embeded textures)
		case '.apng':
			return 'image/apng';
		case '.bmp':
			return 'image/bmp';
		case '.gif':
			return 'image/gif';
		case '.jpg':
		case '.jpeg':
		case '.jfif':
		case '.pjpeg':
		case '.pjp':
			return 'image/jpg';
		case '.png':
			return 'image/png';
		case '.svg':
			return 'image/svg+xml';
		case '.tif':
		case '.tiff':
			return 'image/tiff';
		case '.webp':
			return 'image/webp';
		// Non web standard
		// case ".tga":
		//     return "image/x-tga";
		// Other
		case '.zip':
			return 'image/zip';
		case '.pdf':
			return 'image/pdf';
		case '.otf':
			return 'font/otf';
		case '.ttf':
			return 'font/ttf';
		case '.woff':
			return 'font/woff';
		case '.json':
			return 'application/json';
		default:
			return null;
	}
}

// clone arraybuffer from file data to new blob instance to get rid of reference
export async function processImportedData(
	file: File | Blob,
	name: string,
): Promise<FileData> {
	const arrayBuffer = await readArrayBuffer(file);
	const extension = getExtensionFromFileName(name);
	const mimeType = file.type || getMimeTypeFromExt('.' + extension);
	return new FileData(
		new Blob([arrayBuffer], { type: mimeType }),
		removeExtension(name),
		extension,
	);
}

export async function readArrayBuffer(file: File | Blob): Promise<ArrayBuffer> {
	// if arrayBuffer is not a function use FileReader to read data instead
	return file.arrayBuffer
		? file.arrayBuffer()
		: new Promise<ArrayBuffer>((resolve, reject) => {
				const fileReader = new FileReader();

				fileReader.onload = () => resolve(fileReader.result as ArrayBuffer);
				fileReader.onerror = () =>
					reject(new Error('Could not read imported file'));
				fileReader.readAsArrayBuffer(file);
		  });
}

export async function getImageDimensions(
	blob: Blob,
): Promise<{ width: number; height: number }> {
	return new Promise<{ width: number; height: number }>(
		async (resolve, reject) => {
			switch (blob.type) {
				case 'application/json':
					const bytes = await loadFile(blob);
					const jsonObject = arrayBufferToObject<any>(bytes);
					if (jsonObject.w === undefined || jsonObject.h === undefined)
						reject(
							"Invalid Lottie file. File does not contain 'w' or 'h' attribute.",
						);
					resolve({ width: jsonObject.w, height: jsonObject.h });
					break;
				case 'image/svg+xml':
					const svgElement = await readSvgFileAsSVGElement(blob);
					const res = getViewPortSizeInUserUnits(svgElement);
					resolve({ width: res.width, height: res.height });
					break;
				default:
					const imgEl = document.createElement('img');
					imgEl.onload = () => {
						URL.revokeObjectURL(imgEl.src);
						resolve({ width: imgEl.width, height: imgEl.height });
					};
					imgEl.src = URL.createObjectURL(blob);
			}
		},
	);
}

export const RASTER_IMG_FORMATS = {
	array: ['jpeg', 'jpg', 'jpe', 'png', 'tga'],
	label: 'Upload JPG / PNG',
};
export const STATIC_IMG_FORMATS = {
	array: [...RASTER_IMG_FORMATS.array, 'svg'],
	label: 'Upload JPG / PNG / SVG',
};
export const ENV_IMG_FORMATS = {
	array: [...STATIC_IMG_FORMATS.array, 'hdr', 'exr'],
	label: 'Upload JPG / PNG / HDR / EXR',
};
export const ANIMATED_IMG_FORMATS = {
	array: ['json', 'gif', 'mp4'],
	label: 'Upload Lottie / GIF / MP4',
};
export const LOADING_IMAGE_FORMATS = {
	array: [...STATIC_IMG_FORMATS.array, ...ANIMATED_IMG_FORMATS.array],
	label: 'Upload JPG / PNG / SVG / GIF / JSON',
};
export const FONT_FORMATS = {
	array: ['otf', 'ttf', 'woff'],
	label: 'Upload Font',
};
