import React, { useEffect, useState } from 'react';
import {
	Plan,
	BillingPeriod,
	CURRENT_PRO_TRIAL_PLANS,
} from '@vctr-libs/vctr-api-client';
import { PaddlePrice, Spinner, StudentDiscount } from '..';
import {
	Mark,
	PriceInfo,
	PriceInfoTotal,
	TotalPricingWrapper,
	TotalPricingWrapperContainer,
} from './styledComponents';
import { Highlight, Bold } from '..';
import {
	getIsPricingLoading,
	getDateDaysFromDate,
	getDateYearsFromDate,
	getDateMonthsFromDate,
} from './dateUtils';

export type TotalPricingProps = {
	couponPrice: PaddlePrice;
	couponCode: string;
	selectedPlan: Plan;
	isStudentDiscountAllowed: boolean;
	studentDiscount: StudentDiscount;
	isCheckoutLoaded: boolean;
	isCouponLoaded: boolean;
};

export const TRIAL_PERIOD_IN_DAYS: number = 14;

export function daysToMilliseconds(days: number): number {
	return 1000 * 60 * 60 * 24 * days;
}

export const TotalPricingInitial = (props: TotalPricingProps) => {
	const {
		selectedPlan,
		couponPrice,
		couponCode,
		isStudentDiscountAllowed,
		studentDiscount,
		isCheckoutLoaded,
		isCouponLoaded,
	} = props;

	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const _isLoading = getIsPricingLoading({
			isCheckoutLoaded,
			couponCode,
			isCouponLoaded,
		});
		setIsLoading(_isLoading);
	}, [isCheckoutLoaded, couponCode, isCouponLoaded]);

	if (
		!selectedPlan ||
		!selectedPlan?.billing ||
		[BillingPeriod.MONTH, BillingPeriod.YEAR].indexOf(selectedPlan.billing) ===
			-1
	) {
		console.log('Selected plan billing period is incorrect');
		return null;
	}

	const isTrialPlan = Object.values(CURRENT_PRO_TRIAL_PLANS).includes(
		selectedPlan.id,
	);

	let regularPrice: number;

	if (
		isStudentDiscountAllowed &&
		studentDiscount?.REAL_VALUE_FLAT &&
		selectedPlan.billing === BillingPeriod.YEAR
	) {
		regularPrice = selectedPlan.price - studentDiscount.REAL_VALUE_FLAT;
	} else {
		regularPrice = selectedPlan.price;
	}

	const recurringPrice: number =
		typeof couponPrice?.recurringPrice === 'number'
			? couponPrice.recurringPrice
			: regularPrice;

	let initialPrice: number;
	// TODO this can be removed, 'cause we update price from paddle
	if (isTrialPlan) {
		initialPrice = 0;
	} else {
		initialPrice =
			typeof couponPrice?.initialPrice === 'number'
				? couponPrice.initialPrice
				: regularPrice;
	}

	if (!window.location.hostname.includes('www.vectary.com')) {
		console.log({
			initialPrice,
			recurringPrice,
			...props,
		});
	}

	const currentDate = new Date();

	// trial period is always a month regardless of the billing period

	let nextPaymentDate;
	if (isTrialPlan && selectedPlan.billing == BillingPeriod.MONTH) {
		nextPaymentDate = getDateDaysFromDate({
			startDate: currentDate,
			days: TRIAL_PERIOD_IN_DAYS,
		});
	} else if (isTrialPlan && selectedPlan.billing == BillingPeriod.YEAR) {
		nextPaymentDate = getDateYearsFromDate({
			startDate: getDateDaysFromDate({
				startDate: currentDate,
				days: TRIAL_PERIOD_IN_DAYS,
			}),
			years: 1,
		});
	} else if (!isTrialPlan && selectedPlan.billing == BillingPeriod.MONTH) {
		nextPaymentDate = getDateMonthsFromDate({
			startDate: currentDate,
			months: 1,
		});
	} else if (!isTrialPlan && selectedPlan.billing == BillingPeriod.YEAR) {
		nextPaymentDate = getDateYearsFromDate({
			startDate: currentDate,
			years: 1,
		});
	}

	const nextPaymentDateFormatted = nextPaymentDate.toLocaleDateString('en-US', {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	});

	const isCouponBeingApplied =
		couponCode &&
		[couponPrice?.initialPrice, couponPrice?.recurringPrice].some(
			p => typeof p !== 'number',
		);

	const isCouponApplied =
		couponCode &&
		[couponPrice?.initialPrice, couponPrice?.recurringPrice].some(
			p => typeof p === 'number',
		);

	return (
		<TotalPricingWrapperContainer>
			<TotalPricingWrapper>
				<PriceInfo>
					<Mark>
						Due {nextPaymentDateFormatted}{' '}
						{isTrialPlan && <>(subscription&nbsp;starts)</>}
					</Mark>
				</PriceInfo>
				<PriceInfoTotal>
					<Mark>
						${recurringPrice} / {selectedPlan.billing}
					</Mark>
				</PriceInfoTotal>
			</TotalPricingWrapper>
			<TotalPricingWrapper>
				<PriceInfo>
					<Mark>
						<Bold>Due today </Bold>
						{isTrialPlan && (
							<Highlight> {TRIAL_PERIOD_IN_DAYS} days free</Highlight>
						)}
						{isCouponApplied && <> (discount&nbsp;applied)</>}
					</Mark>
				</PriceInfo>
				<PriceInfoTotal>
					<Mark>
						<Bold>${initialPrice}</Bold>
					</Mark>
				</PriceInfoTotal>
			</TotalPricingWrapper>
			{isCouponBeingApplied && (
				<TotalPricingWrapper>
					<Spinner />
					<div>Applying coupon…</div>
				</TotalPricingWrapper>
			)}
			{isLoading && <Spinner />}
		</TotalPricingWrapperContainer>
	);
};
