import React, { FC } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';

export type RemoveWorkspaceMemberDialogProps = {
	open: boolean;
	handleCloseDialog: () => void;
	handleRemoveMember: () => void;
};

export const RemoveWorkspaceMemberDialog: FC<
	RemoveWorkspaceMemberDialogProps
> = props => {
	const { open, handleCloseDialog, handleRemoveMember } = props;

	return (
		<Dialog open={open} onClose={handleCloseDialog}>
			<DialogTitle>Remove workspace member</DialogTitle>
			<DialogContent>
				<DialogContentText mb={1.5}>
					By removing this seat, the member will lose access to all projects and
					links in this workspace. They will no longer see the workspace or its
					content.
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button color="secondary" onClick={handleCloseDialog}>
					Cancel
				</Button>
				<Button color="error" onClick={handleRemoveMember}>
					Remove
				</Button>
			</DialogActions>
		</Dialog>
	);
};
