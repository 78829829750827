import React from 'react';

export function GoogleIcon() {
	return (
		<svg
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.3514 8.15736C15.3514 7.69653 15.3106 7.25902 15.2406 6.83319H8.64893V9.46403H12.4231C12.2539 10.3274 11.7581 11.0565 11.0231 11.5524L13.2748 13.3024C14.5931 12.0832 15.3514 10.2865 15.3514 8.15736Z"
				fill="#4285F4"
			/>
			<path
				d="M8.64889 15.0002C10.5389 15.0002 12.1197 14.3702 13.2747 13.3027L11.0231 11.5527C10.3931 11.9727 9.59389 12.2293 8.64889 12.2293C6.82305 12.2293 5.27721 10.9985 4.72305 9.336L2.40137 11.1385C3.55054 13.4252 5.91305 15.0002 8.64889 15.0002Z"
				fill="#34A853"
			/>
			<path
				d="M4.7231 9.33605C4.57726 8.91605 4.50143 8.46688 4.50143 8.00021C4.50143 7.53355 4.5831 7.08438 4.7231 6.66438L2.40143 4.86188C1.92309 5.80688 1.64893 6.86855 1.64893 8.00021C1.64893 9.13188 1.92309 10.1936 2.40143 11.1386L4.7231 9.33605Z"
				fill="#FBBC05"
			/>
			<path
				d="M8.64888 3.77084C9.68138 3.77084 10.603 4.12667 11.3322 4.82084L13.3272 2.82584C12.1197 1.69417 10.5389 1 8.64888 1C5.91304 1 3.55054 2.575 2.40137 4.86167L4.72304 6.66418C5.27721 5.00168 6.82304 3.77084 8.64888 3.77084Z"
				fill="#EA4335"
			/>
		</svg>
	);
}
