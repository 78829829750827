import {
	GraphQLTypes,
	MemberPermissionEnum,
	PermissionType,
	EmailInvitationStatusColumn,
} from '@vctr-api/vctr-resources';
import { Cloud } from '@vctr-libs/vctr-utils';
import { ProductIds } from '../plan/interface';
import {
	CreateWorkspaceRequest,
	GetWorkspaceParams,
	GetWorkspaceResponse,
	PlanBase,
	UpdateWorkspaceRequest,
	Visibility,
	WorkspaceBase,
	WorkspaceFolder,
	WorkspaceFolderRequest,
	WorkspaceInvitation,
	WorkspacePayment,
	WorkspacePlan,
	WorkspaceSeat,
} from './interface/interface';
import { convertResourcesApiWorkspacesToOld } from './transformers/convertResourcesApiWorkspacesToOld';
import { handleError } from '../utils/handleError';
import { GET_WORKSPACES_ITEMS } from './fetchers/queries';
import { workspaceSdk } from './config';
import { updateWorkspaceSeat } from './workspaceUsers';
import { permissionSetToOldPlan, oldToNewId } from './utils/resourcesApiUtils';
import { Permission } from './interface/permissionRoles';
import { EMAIL_INVITATION_FIELDS } from './constants';

export * from './utils/getWorkspacePicture';

/**
 * PUBLIC
 * GET
 * ​/workspace​/{workspaceId_base62}
 * Get workspace information
 */
export const getWorkspaceInfo = async (
	workspaceUuid: string,
	cloud?: Cloud,
	asAService = true,
): Promise<WorkspaceBase> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			workspace: [
				{ id: workspaceUuid },
				{
					id: true,
					name: true,
					picture: true,
					createdAt: true,
					customDomain: true,
					owner: {
						id: true,
					},
					permissions: {
						id: true,
						name: true,
						properties: true,
						internalNote: true,
					},
					workspaceRolePermissions: true,
					paddleDetails: {
						customerId: true,
						subscriptionId: true,
						linkedSubscriptionId: true,
						status: true,
						price: true,
						currency: true,
						recurringInterval: true,
						nextPaymentAmount: true,
						nextPaymentDate: true,
						cancellationEffectiveDate: true,
						cancelUrl: true,
						updateUrl: true,
						receipts: true,
						latestEventTime: true,
					},
				},
			],
		});
		return {
			uuid: response.workspace.id,
			name: response.workspace.name,
			owner_id: response.workspace.owner.id,
			customDomain: response.workspace.customDomain,
			picture: response.workspace.picture,
			root_name: 'default',
			visibility: Visibility.PUBLIC,
			plan: permissionSetToOldPlan(
				response.workspace.permissions,
				response.workspace.paddleDetails,
			),
			rolePermissions: response.workspace
				.workspaceRolePermissions as PermissionType[],
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

/**
 * PRIVATE
 * GET
 * ​/user​/{userId_base62}​/workspaces
 * Get list of workspaces a user is owner or seat of
 */
export const getWorkspaces = async (
	userId: string,
	params?: GetWorkspaceParams,
	cloud?: Cloud,
): Promise<GetWorkspaceResponse> => {
	const query = [];
	params?.includeDeleted &&
		query.push(`includeDeleted=${params.includeDeleted}`);
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			user: [
				{ id: userId },
				{
					workspaces: [
						{},
						{
							items: GET_WORKSPACES_ITEMS,
						},
					],
				},
			],
		});

		const workspacesResponse: GetWorkspaceResponse =
			convertResourcesApiWorkspacesToOld(
				response.user.workspaces.items as unknown as Array<
					GraphQLTypes['UserWorkspaceNode']
				>,
			);
		return workspacesResponse;
	} catch (error) {
		throw `${error} Error getting user workspaces`;
	}
};
export const getWorkspacesWithProjectsCount = async (
	userId: string,
	params?: GetWorkspaceParams,
	cloud?: Cloud,
): Promise<GetWorkspaceResponse> => {
	const query = [];
	params?.includeDeleted &&
		query.push(`includeDeleted=${params.includeDeleted}`);
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			user: [
				{ id: userId },
				{
					workspaces: [
						{},
						{
							items: {
								...GET_WORKSPACES_ITEMS,
								folders: [
									{},
									{
										items: {
											id: true,
											projects: [
												{},
												{
													items: {
														id: true,
													},
												},
											],
										},
									},
								],
							},
						},
					],
				},
			],
		});
		const workspacesResponse: GetWorkspaceResponse =
			convertResourcesApiWorkspacesToOld(
				response.user.workspaces.items as unknown as Array<
					GraphQLTypes['UserWorkspaceNode']
				>,
			);
		return workspacesResponse;
	} catch (error) {
		throw `${error} Error getting user workspaces`;
	}
};

/**
 * POST
 * ​/workspace
 * Create a workspace
 */
export const createWorkspace = async (
	workspace: CreateWorkspaceRequest,
	cloud?: Cloud,
): Promise<WorkspaceBase & { folders?: any[] }> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('mutation')({
			createWorkspace: [
				{
					name: workspace.name,
					picture: workspace.picture,
					ownerId: workspace.owner,
				},
				{
					id: true,
					name: true,
					picture: true,
					createdAt: true,
					folders: [
						{},
						{
							items: {
								id: true,
								isHomeFolder: true,
								name: true,
								ordering: true,
							},
						},
					],
					owner: {
						id: true,
					},
					permissions: {
						id: true,
						name: true,
						properties: true,
						internalNote: true,
					},
					paddleDetails: {
						customerId: true,
						subscriptionId: true,
						linkedSubscriptionId: true,
						status: true,
						price: true,
						currency: true,
						recurringInterval: true,
						nextPaymentAmount: true,
						nextPaymentDate: true,
						cancellationEffectiveDate: true,
						cancelUrl: true,
						updateUrl: true,
						receipts: true,
						latestEventTime: true,
					},
					workspaceRolePermissions: true,
				},
			],
		});
		return {
			uuid: response.createWorkspace.id,
			name: response.createWorkspace.name,
			owner_id: response.createWorkspace.owner.id,
			picture: response.createWorkspace.picture,
			root_name: 'Home',
			visibility: Visibility.PUBLIC,
			folders: response.createWorkspace.folders.items as any[],
			plan: permissionSetToOldPlan(
				response.createWorkspace.permissions,
				response.createWorkspace.paddleDetails,
			),
			rolePermissions: response.createWorkspace
				.workspaceRolePermissions as PermissionType[],
		};
	} catch (error) {
		throw `${error} Error creating workspace`;
	}
};

/**
 * PATCH
 * ​/workspace​/{workspaceId_base62}
 * Update workspace information
 */
export const updateWorkspace = async (
	workspaceUuid: string,
	data: UpdateWorkspaceRequest,
	cloud?: Cloud,
): Promise<WorkspaceBase> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('mutation')({
			updateWorkspace: [
				{
					id: workspaceUuid,
					name: data.name,
					picture: data.picture,
					ownerId: data.owner,
					permissionsWorkspaceId: data.permissionsWorkspaceId,
					customDomainId: data.customDomainId,
				},
				{
					id: true,
					name: true,
					picture: true,
					createdAt: true,
					customDomain: true,
					owner: {
						id: true,
					},
					permissions: {
						id: true,
						name: true,
						properties: true,
						internalNote: true,
					},
					workspaceRolePermissions: true,
					paddleDetails: {
						customerId: true,
						subscriptionId: true,
						linkedSubscriptionId: true,
						status: true,
						price: true,
						currency: true,
						recurringInterval: true,
						nextPaymentAmount: true,
						nextPaymentDate: true,
						cancellationEffectiveDate: true,
						cancelUrl: true,
						updateUrl: true,
						receipts: true,
						latestEventTime: true,
					},
				},
			],
		});
		return {
			uuid: response.updateWorkspace.id,
			name: response.updateWorkspace.name,
			owner_id: response.updateWorkspace.owner.id,
			picture: response.updateWorkspace.picture,
			root_name: 'default',
			visibility: Visibility.PUBLIC,
			plan: permissionSetToOldPlan(
				response.updateWorkspace.permissions,
				response.updateWorkspace.paddleDetails,
			),
			rolePermissions: response.updateWorkspace
				.workspaceRolePermissions as PermissionType[],
		};
	} catch (error) {
		throw `${error} Error updating workspace!`;
	}
};

type AcceptInvitationResult = {
	id: string;
	permission: Permission;
	workspaceId: string;
	status?: EmailInvitationStatusColumn;
};

export const acceptInvitation = async (
	invitationUuid: string,
	cloud?: Cloud,
): Promise<AcceptInvitationResult> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('mutation')({
			acceptInvitation: [
				{
					invitationId: invitationUuid,
				},
				{
					id: true,
					permission: true,
					workspace: {
						id: true,
					},
				},
			],
		});
		return {
			id: response.acceptInvitation.id,
			permission: response.acceptInvitation.permission as string as Permission,
			workspaceId: response.acceptInvitation.workspace.id,
		};
	} catch (error) {
		console.error(error);
		throw `${error} Error accepting the invitation!`;
	}
};

// TODO: Join with `acceptInvitation` and add type as an argument?
export const acceptEmailInvitation = async (
	invitationId: string,
	cloud?: Cloud,
): Promise<AcceptInvitationResult> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('mutation')({
			acceptEmailInvitation: [
				{
					invitationId,
				},
				EMAIL_INVITATION_FIELDS,
			],
		});

		return {
			id: response.acceptEmailInvitation.id,
			permission: response.acceptEmailInvitation
				.permission as string as Permission,
			status: response.acceptEmailInvitation
				.status as string as EmailInvitationStatusColumn,
			workspaceId: response.acceptEmailInvitation.workspace.id,
		};
	} catch (error) {
		handleError({ error, name: 'Error accepting the email invitation' });
	}
};

export const reactivateEmailInvitation = async (
	id: string,
	cloud?: Cloud,
): Promise<AcceptInvitationResult> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('mutation')({
			reactivateEmailInvitation: [
				{
					id,
				},
				EMAIL_INVITATION_FIELDS,
			],
		});
		return {
			id: response.reactivateEmailInvitation.id,
			permission: response.reactivateEmailInvitation
				.permission as string as Permission,
			status: response.reactivateEmailInvitation
				.status as string as EmailInvitationStatusColumn,
			workspaceId: response.reactivateEmailInvitation.workspace.id,
		};
	} catch (error) {
		handleError({ error, name: 'Error reactivating the email invitation' });
	}
};

/**
 * GET
 * ​/workspace​/{workspaceId_base62}​/folders
 * Get list of folders inside the workspace
 */
export const getWorkspaceFolders = async (
	workspaceUuid: string,
	cloud?: Cloud,
): Promise<WorkspaceFolder[]> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			workspace: [
				{ id: workspaceUuid },
				{
					folders: [
						{},
						{
							items: {
								id: true,
								name: true,
								isHomeFolder: true,
								projects: [
									{},
									{
										items: {
											id: true,
										},
									},
								],
							},
						},
					],
				},
			],
		});
		return response.workspace.folders.items.map(folder => ({
			id: folder.id,
			name: folder.name,
			models: folder.projects.items.map(project => project.id),
			isHomeFolder: folder.isHomeFolder,
		}));
	} catch (error) {
		throw `${error} Error getting workspace folders`;
	}
};

/**
 * POST
 * ​/workspace​/{workspaceId_base62}​/folders
 * Create a folder inside the workspace
 */
export const createWorkspaceFolder = async (
	workspaceUuid: string,
	folder: WorkspaceFolderRequest,
	cloud?: Cloud,
): Promise<WorkspaceFolder> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('mutation')({
			createFolder: [
				{
					workspaceId: workspaceUuid,
					name: folder.name,
				},
				{
					id: true,
					name: true,
					projects: [
						{},
						{
							items: {
								id: true,
							},
						},
					],
				},
			],
		});
		return {
			id: response.createFolder.id,
			name: response.createFolder.name,
			models: response.createFolder.projects.items.map(project => project.id),
		};
	} catch (error) {
		throw `${error} Error creating a folder!`;
	}
};

/**
 * PUT
 * ​/workspace​/{workspaceId_base62}​/folders​/reorder
 * Reorder the folders inside the workspace
 */
export const reorderWorkspaceFolders = async (
	workspaceUuid: string,
	orderedWorkspaceFolderUuids: string[],
	cloud?: Cloud,
): Promise<void> => {
	try {
		for (let i = 0, len = orderedWorkspaceFolderUuids.length; i < len; i++) {
			await (
				await workspaceSdk(cloud)
			)('mutation')({
				updateFolder: [
					{
						id: orderedWorkspaceFolderUuids[i],
						ordering: i,
					},
					{
						id: true,
					},
				],
			});
		}

		orderedWorkspaceFolderUuids.forEach((folder, i) => {});
	} catch (error) {
		throw `${error} Error reordering workspaces!`;
	}
};

/**
 * GET
 * ​/workspace​/{workspaceId_base62}​/folders​/{folderId_base62}
 * Get a folder of the workspace
 */
export const getWorkspaceFolderInfo = async (
	workspaceUuid: string,
	folderUuid: string,
	cloud?: Cloud,
): Promise<WorkspaceFolder> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			folder: [
				{ id: folderUuid },
				{
					id: true,
					name: true,
					projects: [
						{},
						{
							items: {
								id: true,
							},
						},
					],
				},
			],
		});
		return {
			id: response.folder.id,
			name: response.folder.name,
			models: response.folder.projects.items.map(project => project.id),
		};
	} catch (error) {
		throw `${error} Error getting workspace folder info!`;
	}
};

/**
 * PATCH
 * ​/workspace​/{workspaceId_base62}​/folders​/{folderId_base62}
 * Update a folder of the workspace
 */
export const updateWorkspaceFolder = async (
	workspaceUuid: string,
	folder: WorkspaceFolder,
	cloud?: Cloud,
): Promise<WorkspaceFolder> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('mutation')({
			updateFolder: [
				{
					id: folder.id,
					name: folder.name,
				},
				{
					id: true,
					name: true,
					projects: [
						{},
						{
							items: {
								id: true,
							},
						},
					],
				},
			],
		});
		return {
			id: response.updateFolder.id,
			name: response.updateFolder.name,
			models: response.updateFolder.projects.items.map(project => project.id),
		};
	} catch (error) {
		throw `${error} Error updating workspace folder!`;
	}
};

/**
 * DELETE
 * ​/workspace​/{workspaceId_base62}​/folders​/{folderId_base62}
 * Delete a folder of the workspace
 */
export const deleteWorkspaceFolder = async (
	workspaceUuid: string,
	folderUuid: string,
	cloud?: Cloud,
): Promise<void> => {
	try {
		await (
			await workspaceSdk(cloud)
		)('mutation')({
			deleteFolder: [{ id: folderUuid }, true],
		});
	} catch (error) {
		throw `${error} Error deleting workspace folder!`;
	}
};

/**
 * DELETE
 * ​/workspace​/{workspaceId_base62}​/plan
 * Cancel the subscription associated to the workspace
 */
export const deleteWorkspacePlan = async (
	workspaceUuid: string,
	cloud?: Cloud,
): Promise<void> => {
	try {
		await (
			await workspaceSdk(cloud)
		)('mutation')({
			// TODO fix typings here -> Peto
			// @ts-ignore
			cancelPaddleSubscription: [{ workspaceId: workspaceUuid }, true],
		});
	} catch (error) {
		throw `${error} Error deleting workspace plan`;
	}
};

/**
* DELETE
* ​/workspace​/{workspaceId_base62}​/custom_plan
* Use this method to remove the custom plan of the workspace identified by the parameter workspaceId_base62.
  The same could be achieved by putting and empty custom plan. Pay attention to what you do with it and request
  modifiers to be recalculated if the removal of the custom plan needs to modify what the user is charged for features already active.
*/
export const deleteWorkspaceCustomPlan = async (
	workspaceUuid: string,
	cloud?: Cloud,
): Promise<null> => {
	try {
		const query = await (
			await workspaceSdk(cloud)
		)('query')({
			workspace: [
				{ id: workspaceUuid },
				{
					overwrite: true,
				},
			],
		});
		const newOverwrite: any = query.workspace.overwrite ?? {};
		delete newOverwrite.customPermissions;

		await (
			await workspaceSdk(cloud)
		)('mutation')({
			updateWorkspace: [
				{ id: workspaceUuid, overwrite: newOverwrite },
				{
					createdAt: true,
				},
			],
		});

		return null;
	} catch (error) {
		throw `${error} Error deleting workspace plan`;
	}
};

/**
 * OWNER
 * DELETE
 * ​/workspace​/{workspaceId_base62}
 * Delete a workspace
 */
export const deleteWorkspace = async (
	workspaceUuid: string,
	cloud?: Cloud,
): Promise<void> => {
	try {
		await (
			await workspaceSdk(cloud)
		)('mutation')({
			deleteWorkspace: [
				{
					id: workspaceUuid,
				},
				true,
			],
		});
	} catch (error) {
		throw `${error} Error deleting a workspace!`;
	}
};

/**
 * POST
 * ​/workspace​/{workspaceId_base62}​/invitations
 * Generate an invitation code to add a user with a specific permission
 */
export const createWorkspaceInvitation = async (
	workspaceUuid: string,
	permission: Permission,
	cloud?: Cloud,
): Promise<WorkspaceInvitation> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('mutation')({
			createInvitation: [
				{
					workspaceId: workspaceUuid,
					permission: permission as string as MemberPermissionEnum,
				},
				{
					id: true,
					permission: true,
				},
			],
		});
		return {
			uuid: response.createInvitation.id,
			permission: response.createInvitation.permission as string as Permission,
		};
	} catch (error) {
		throw `${error} Error creating an invitation!`;
	}
};

/**
 * Adds given user to a given workspace.
 *
 * @param workspaceId
 * @param userId
 * @param permission
 * @param cloud
 * @returns
 */
export const addUserToWorkspaceSeats = async (
	workspaceId: string,
	userId: string,
	permission: Permission,
	cloud?: Cloud,
): Promise<WorkspaceSeat> => {
	try {
		await (
			await workspaceSdk(cloud)
		)('mutation')({
			addMember: [
				{
					workspaceId,
					userId,
				},
				true,
			],
		});
		return await updateWorkspaceSeat(workspaceId, userId, permission);
	} catch (error) {
		throw `${error} Error adding user to workspace!`;
	}
};

/**
 * PUT
 * ​/workspace​/{workspaceId_base62}​/custom_plan
 * Get the custom plan of a workspace
 */
export const setWorkspaceCustomPlan = async (
	workspaceUuid: string,
	plan: WorkspacePlan,
	cloud?: Cloud,
): Promise<PlanBase> => {
	try {
		const query = await (
			await workspaceSdk(cloud)
		)('query')({
			workspace: [
				{ id: workspaceUuid },
				{
					overwrite: true,
				},
			],
		});
		const newOverwrite: any = query.workspace.overwrite ?? {};
		const projectProduct = plan.product_list?.find(
			p => p.id === ProductIds.PROJECT,
		);
		const viewProduct = plan.product_list?.find(p => p.id === ProductIds.VIEW);

		let newSeatsLimits: {
			[key in ProductIds]?: {
				softLimit: number | null;
				hardLimit: number | null;
				pricePerUnit: number | null;
			};
		} = {};
		[
			ProductIds.SEAT_EDIT,
			ProductIds.SEAT_VIEW,
			ProductIds.SEAT_EDIT_2024,
			ProductIds.SEAT_VIEW_2024,
			ProductIds.SEAT_COLLABORATE_2024,
			ProductIds.SEAT_MANAGE_2024,
		].forEach(productId => {
			const product = plan.product_list?.find(p => p.id === productId);
			newSeatsLimits[productId] = {
				softLimit: product?.soft_limit ?? null,
				hardLimit: product?.hard_limit ?? null,
				pricePerUnit: product?.price_per_unit ?? null,
			};
		});

		newOverwrite.customPermissions = {
			id: oldToNewId(plan.id, cloud),
			name: plan.name,
			properties: {
				projects: {
					softLimit: projectProduct?.soft_limit,
					hardLimit: projectProduct?.hard_limit,
					pricePerUnit: projectProduct?.price_per_unit,
				},
				views: {
					softLimit: viewProduct?.soft_limit,
					hardLimit: viewProduct?.hard_limit,
					pricePerUnit: viewProduct?.price_per_unit,
					watermark: !plan.product_list?.find(
						p => p.id === ProductIds.HIDDEN_WATERMAK,
					),
				},
				exports: {
					formats: plan.product_list?.find(p => p.id === ProductIds.EXPORT)
						?.formats,
				},
				members: {
					...newSeatsLimits,
				},
			},
			internalNote: plan.internal_note,
		};

		await (
			await workspaceSdk(cloud)
		)('mutation')({
			updateWorkspace: [
				{ id: workspaceUuid, overwrite: newOverwrite },
				{
					createdAt: true,
				},
			],
		});

		const newCustomPermissionsId: PlanBase = {
			id: newOverwrite.customPermissions.id,
		};

		return newCustomPermissionsId;
	} catch (error) {
		throw `${error} Error setting workspace custom plan!`;
	}
};

/**
 * GET
 * ​/workspace​/{workspaceId_base62}​/payment_history
 * Get the payment history of the workspace
 */
export const getWorkspacePayments = async (
	workspaceUuid: string,
	cloud?: Cloud,
): Promise<WorkspacePayment[]> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			workspace: [
				{ id: workspaceUuid },
				{
					paddleDetails: {
						receipts: true,
					},
				},
			],
		});
		return (
			response.workspace.paddleDetails?.receipts?.map((receipt: any) => ({
				id: receipt.orderId,
				receipt_url: receipt.receiptUrl,
			})) ?? []
		);
	} catch (error) {
		throw `${error} Error getting workspace payment history`;
	}
};

export const getWorkspacePermissions = async (
	workspaceId: string,
	cloud?: Cloud,
): Promise<any> => {
	// TODO: Type this.
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			workspacePermissions: [{ id: workspaceId }, true],
		});
		return response.workspacePermissions;
	} catch (error) {
		handleError({ error, name: 'Error getting workspace permissions' });
	}
};

export const getAllMemberRoles = async (
	cloud?: Cloud,
): Promise<{ id: number; name: string; displayName: string }[]> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			allMemberRoles: {
				id: true,
				name: true,
				displayName: true,
			},
		});
		return response.allMemberRoles;
	} catch (error) {
		handleError({ error, name: 'Error getting all member roles' });
	}
};

export const getAllRolePermissions = async (cloud?: Cloud): Promise<any> => {
	// TODO: Type this.
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			allRolePermissions: {
				id: true,
				name: true,
				displayName: true,
				description: true,
			},
		});
		return response.allRolePermissions;
	} catch (error) {
		handleError({ error, name: 'Error getting all role permissions' });
	}
};

type UpdateWorkspaceRolePermissionArgs = {
	isAllowed: boolean;
	memberRole: string; // TODO: Type
	rolePermission: string; // TODO: Type
	workspaceId: string;
};

export const updateWorkspaceRolePermission = async (
	args: UpdateWorkspaceRolePermissionArgs,
	cloud?: Cloud,
): Promise<any> => {
	// TODO: Type this.
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('mutation')({
			updateWorkspaceRolePermission: [
				{
					isAllowed: args.isAllowed,
					memberRole: args.memberRole,
					rolePermission: args.rolePermission,
					workspaceId: args.workspaceId,
				},
				{
					isAllowed: true,
					memberRole: true,
					rolePermission: true,
					workspaceId: true,
				},
			],
		});
		return response.updateWorkspaceRolePermission;
	} catch (error) {
		handleError({ error, name: 'Error while setting permissions' });
	}
};
