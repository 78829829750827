export class ExtensionRendererError extends Error {
	constructor(msg: string) {
		super(msg);
		Object.setPrototypeOf(this, new.target.prototype);
	}
}

export class MessageException extends Error {
	readonly msg: string;

	constructor(msg: string) {
		// 'Error' breaks prototype chain here, therefore...
		super(msg);

		// ...restore prototype chain. Only needed for target ES5
		// see https://stackoverflow.com/questions/41102060/typescript-extending-error-class
		const actualProto = new.target.prototype;
		Object.setPrototypeOf(this, actualProto);

		this.msg = msg;
	}

	getMessage(): string {
		return this.msg;
	}
}

export class SerializationError extends Error {
	readonly error: string;

	constructor(error: string) {
		// 'Error' breaks prototype chain here, therefore...
		super(error);

		// ...restore prototype chain. Only needed for target ES5
		// see https://stackoverflow.com/questions/41102060/typescript-extending-error-class
		const actualProto = new.target.prototype;
		Object.setPrototypeOf(this, actualProto);

		this.error = error;
	}

	getMessage(): string {
		return this.error;
	}
}

export class BugException extends Error {
	readonly error: ErrorText;

	constructor(error: ErrorText) {
		// 'Error' breaks prototype chain here, therefore...
		super(error.error);

		// ...restore prototype chain. Only needed for target ES5
		// see https://stackoverflow.com/questions/41102060/typescript-extending-error-class
		const actualProto = new.target.prototype;
		Object.setPrototypeOf(this, actualProto);

		this.error = error;
	}

	getError() {
		return this.error;
	}
}

export function runAndShowErrors(
	fnc: () => Promise<any>,
	errorCallback: (error: Error) => void,
) {
	try {
		fnc().catch(err => {
			if (err instanceof MessageException) {
				errorCallback(err);
			} else {
				throw err;
			}
		});
	} catch (err) {
		if (err instanceof MessageException) {
			errorCallback(err);
		} else {
			throw err;
		}
	}
}

interface ErrorText {
	error: string;
	stack?: string;
	httpStatus?: number;
}

export function extractError(anything: any): ErrorText {
	if (anything instanceof MessageException) {
		return {
			error: anything.msg,
			stack: undefined, // messages don't have stack, by design
		};
	}

	if (anything instanceof BugException) {
		return anything.getError();
	}

	if (anything.error && typeof anything.error === 'string') {
		return { error: anything.error };
	}

	// if unknown error show everything in the log, it could be useful
	console.error(anything);

	return {
		error: `${anything.message ? anything.message : anything}`,
		stack: undefined,
	};
}
