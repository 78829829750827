interface LimitedCallback {
	callback: () => void;
	limitTriggers: number;
}

interface ElapsedCallback {
	callback: () => void;
	time: number;
	pending: boolean;
}

interface WsOptions {
	host: string;
	appId: string; // dummyId
}

interface InitOptions {
	currentPageId?: string;
	persistOnLeaveOrIdle?: boolean;
	startTime?: Date;
	idleTimeout?: number;
	idleStateRateCheck?: number;
	trackWhenUserLeavesPage?: boolean;
	trackWhenPageIsVisible?: boolean;
	trackWhenUserGoesIdle?: boolean;
	websocketOptions?: WsOptions;
}

interface TimePeriod {
	startTime: Date;
	stopTime: Date;
}

interface TimeRecords {
	periods: TimePeriod[];
	persistent: boolean;
}

export interface TimeRecord {
	name: string;
	time: number;
}

export class StopWatch {
	private currentPageId: string = 'default-page-id';
	private currentIdleTime: number = 0;
	private idleTimeout: number = 30 * 1000;
	private idleStateRateCheck: number = 250;
	private isUserCurrentlyOnPage: boolean = true;
	private isUserCurrentlyIdle: boolean = false;

	private recordedTimes: { [recordIdName: string]: TimeRecords } = {};

	private userReturnCallbacks: LimitedCallback[] = [];
	private userLeftCallbacks: LimitedCallback[] = [];
	private elapsedCallbacks: ElapsedCallback[] = [];

	private websocket: WebSocket;

	constructor(options?: InitOptions) {
		this.currentPageId = options?.currentPageId || this.currentPageId;
		this.idleTimeout = options?.idleTimeout || this.idleTimeout;
		this.idleStateRateCheck =
			options?.idleStateRateCheck || this.idleStateRateCheck;
		this.listenForVisibilityEvents(
			options?.trackWhenUserLeavesPage,
			options?.trackWhenUserGoesIdle,
			options?.trackWhenPageIsVisible,
		);
		this.setUpWebsocket(options?.websocketOptions);

		this.startTimer(
			this.currentPageId,
			options?.persistOnLeaveOrIdle,
			options?.startTime,
		); // TODO - only do this if page currently visible.
	}

	startTimer(
		recordIdName?: string,
		persistOnLeaveOrIdle = false,
		startTime?: Date,
	): void {
		if (!recordIdName) {
			recordIdName = this.currentPageId;
		}

		if (this.recordedTimes[recordIdName] === undefined) {
			this.recordedTimes[recordIdName] = {
				periods: [],
				persistent: persistOnLeaveOrIdle,
			};
		} else {
			const arrayOfTimes = this.recordedTimes[recordIdName].periods;
			const latestEntry = arrayOfTimes[arrayOfTimes.length - 1];
			if (!latestEntry?.stopTime) {
				// Can't start new timer until previous finishes.
				return;
			}
		}
		this.recordedTimes[recordIdName].periods.push({
			startTime: startTime || new Date(),
			stopTime: undefined,
		});
	}

	stopTimer(recordIdName?: string, stopTime?: Date): void {
		if (!recordIdName) {
			recordIdName = this.currentPageId;
		}

		const arrayOfTimes = this.recordedTimes[recordIdName].periods;
		if (arrayOfTimes === undefined || arrayOfTimes.length === 0) {
			// Can't stop timer before you've started it.
			return;
		}
		if (arrayOfTimes[arrayOfTimes.length - 1].stopTime === undefined) {
			arrayOfTimes[arrayOfTimes.length - 1].stopTime = stopTime || new Date();
		}
	}

	stopAllTimers(keepPersistent = true): void {
		Object.keys(this.recordedTimes).forEach(recordName => {
			if (!keepPersistent || !this.recordedTimes[recordName].persistent) {
				this.stopTimer(recordName);
			}
		});
	}

	trackTimeOnElement(elementId: string): void {
		const element = document.getElementById(elementId);
		if (element) {
			element.addEventListener('mouseover', () => {
				this.startTimer(elementId);
			});
			element.addEventListener('mousemove', () => {
				this.startTimer(elementId);
			});
			element.addEventListener('mouseleave', () => {
				this.stopTimer(elementId);
			});
			element.addEventListener('keypress', () => {
				this.startTimer(elementId);
			});
			element.addEventListener('focus', () => {
				this.startTimer(elementId);
			});
		}
	}

	getMostRecentTime(recordIdName?: string): number {
		const arrayOfTimeRecords =
			this.recordedTimes[recordIdName ? recordIdName : this.currentPageId]
				.periods;
		if (!arrayOfTimeRecords) {
			// Can't get time on page before you've started the timer.
			return null;
		}
		const lastTimeRecord = arrayOfTimeRecords[arrayOfTimeRecords.length - 1];
		const startTime = lastTimeRecord.startTime;
		const stopTime = lastTimeRecord.stopTime || new Date();
		return stopTime.getTime() - startTime.getTime();
	}

	getTotalTime(recordIdName?: string): number {
		const arrayOfTimeRecords =
			this.recordedTimes[recordIdName ? recordIdName : this.currentPageId]
				.periods;
		if (!arrayOfTimeRecords) {
			// Can't get time on page before you've started the timer.
			return null;
		}

		let timeRecorded = 0;
		arrayOfTimeRecords.forEach(record => {
			const startTime = record.startTime;
			const stopTime = record.stopTime || new Date();
			const difference = stopTime.getTime() - startTime.getTime();
			timeRecorded += difference;
		});

		return timeRecorded;
	}

	getAllTimeRecords(): TimeRecord[] {
		const allRecords: TimeRecord[] = [];
		Object.keys(this.recordedTimes).forEach(recordIdName =>
			allRecords.push({
				name: recordIdName,
				time: this.getTotalTime(recordIdName),
			}),
		);
		return allRecords;
	}

	setCurrentPageId(pageId: string): void {
		this.currentPageId = pageId;
	}

	resetRecordedTime(recordIdName: string): void {
		delete this.recordedTimes[recordIdName];
	}

	resetAllRecordedTimes(): void {
		Object.keys(this.recordedTimes).forEach(recordIdName =>
			this.resetRecordedTime(recordIdName),
		);
	}

	userActivityDetected(): void {
		if (this.isUserCurrentlyIdle) {
			this.triggerUserHasReturned();
		}
		this.resetIdleCountdown();
	}

	private resetIdleCountdown(): void {
		this.isUserCurrentlyIdle = false;
		this.currentIdleTime = 0;
	}

	callWhenUserLeaves(callback: () => void, maxLimitTriggers?: number): void {
		this.userLeftCallbacks.push({
			callback,
			limitTriggers: maxLimitTriggers ? maxLimitTriggers : -1, // -1 => unlimitted
		});
	}

	callWhenUserReturns(callback: () => void, maxLimitTriggers?: number): void {
		this.userReturnCallbacks.push({
			callback,
			limitTriggers: maxLimitTriggers ? maxLimitTriggers : -1,
		});
	}

	callAfterTimeElapsed(msec: number, callback: () => void) {
		this.elapsedCallbacks.push({
			callback,
			time: msec,
			pending: true,
		});
	}

	private triggerUserHasReturned(): void {
		if (!this.isUserCurrentlyOnPage) {
			this.resetIdleCountdown();
			this.isUserCurrentlyOnPage = true;
			this.userReturnCallbacks.forEach(lcb => {
				if (lcb.limitTriggers !== 0) {
					lcb.limitTriggers -= 1;
					lcb.callback();
				}
			});
		}
		this.startTimer();
	}

	private triggerUserHasLeftOrGoneIdle() {
		if (this.isUserCurrentlyOnPage) {
			this.isUserCurrentlyOnPage = false;
			this.userLeftCallbacks.forEach(ulcb => {
				if (ulcb.limitTriggers !== 0) {
					ulcb.limitTriggers -= 1;
					ulcb.callback();
				}
			});
		}
		this.stopAllTimers();
	}

	private checkElapsedCallbacksAndIdleState() {
		this.elapsedCallbacks.forEach(ecb => {
			if (ecb.pending && this.getTotalTime() > ecb.time) {
				ecb.callback();
				ecb.pending = false;
			}
		});

		if (
			this.isUserCurrentlyIdle === false &&
			this.currentIdleTime > this.idleTimeout
		) {
			this.isUserCurrentlyIdle = true;
			this.triggerUserHasLeftOrGoneIdle();
		} else {
			this.currentIdleTime += this.idleStateRateCheck;
		}
	}

	private listenForVisibilityEvents(
		trackWhenUserLeavesPage = true,
		trackWhenUserGoesIdle = true,
		trackWhenPageIsVisible = false,
	) {
		if (trackWhenUserLeavesPage || trackWhenPageIsVisible) {
			this.listenForUserLeavesOrReturnsEvents(trackWhenPageIsVisible);
		}

		if (trackWhenUserGoesIdle) {
			this.listenForIdleEvents();
		}
	}

	private listenForUserLeavesOrReturnsEvents(trackWhenPageIsVisible: boolean) {
		const checkVisibilityState = () => {
			if (document.visibilityState === 'visible') {
				this.triggerUserHasReturned();
			} else {
				this.triggerUserHasLeftOrGoneIdle();
			}
		};
		document.addEventListener('visibilitychange', () => checkVisibilityState());

		window.addEventListener('pageshow', () => this.triggerUserHasReturned());
		window.addEventListener('pagehide', () =>
			this.triggerUserHasLeftOrGoneIdle(),
		);

		if (!trackWhenPageIsVisible) {
			window.addEventListener('focus', () => this.triggerUserHasReturned());
			window.addEventListener('blur', () =>
				this.triggerUserHasLeftOrGoneIdle(),
			);
		} else {
			// Intersection Observer
			let observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					entry.intersectionRatio > 0
						? this.triggerUserHasReturned()
						: this.triggerUserHasLeftOrGoneIdle();
				});
			});
			observer.observe(document.body);
		}
	}

	private listenForIdleEvents() {
		document.addEventListener('mousemove', () => {
			this.userActivityDetected();
		});
		document.addEventListener('keyup', () => {
			this.userActivityDetected();
		});
		document.addEventListener('touchstart', () => {
			this.userActivityDetected();
		});
		window.addEventListener('scroll', () => {
			this.userActivityDetected();
		});

		setInterval(() => {
			if (this.isUserCurrentlyIdle === false) {
				this.checkElapsedCallbacksAndIdleState();
			}
		}, this.idleStateRateCheck);
	}

	private setUpWebsocket(websocketOptions: WsOptions) {
		if (window.WebSocket && websocketOptions) {
			try {
				this.websocket = new WebSocket(websocketOptions.host); // "ws://hostname:port"
				this.websocket.onopen = () =>
					this.sendInitWsRequest(websocketOptions.appId);
				this.websocket.onerror = error =>
					console.log('Error occurred in websocket connection:', error);
				this.websocket.onmessage = event =>
					console.log('Message received:', event.data);

				window.addEventListener('beforeunload', () => {
					this.sendCurrentTime(websocketOptions.appId);
				});
			} catch (error) {
				console.error('Failed to connect to websocket host.  Error:' + error);
			}
		}
	}

	private websocketSend(data: any) {
		this.websocket?.send(JSON.stringify(data));
	}

	private sendInitWsRequest(appId: string) {
		// Dummy init data
		const data = {
			type: 'INIT',
			appId: appId,
		};
		this.websocketSend(data);
	}

	sendCurrentTime(appId: string) {
		// Dummy insert_time data
		const data = {
			type: 'INSERT_TIME',
			appId: appId,
			pageId: this.currentPageId,
			timeOnPageMs: this.getTotalTime(),
			elements: false,
		};
		const elements: any = []; // Need to make proper interface for data

		this.getAllTimeRecords().forEach(record => {
			if (record.name !== this.currentPageId) {
				elements.push({
					elemId: record.name,
					timeOnElemMs: record.time,
				});
			}
		});
		data.elements = elements; // I know I know...

		this.websocketSend(data);
	}
}
