const BLOG_ITEMS_PER_PAGE = 12;
const BLOG_PATH = "/3d-modeling-blog";

export const paginateBlog = () => {
    if (window.location.href.indexOf(BLOG_PATH) === -1) return;

    const container = document.querySelector(`[js="blog__posts__container"]`) as HTMLElement;
    const pagination = document.querySelector(`[js="blog__pagination__container"]`) as HTMLElement;
    // container.style.setProperty("display", "none");

    if (!container || !pagination) {
        return;
    }

    [container, pagination].forEach(el => {
        el.style.setProperty("transition-property", "opacity");
        el.style.setProperty("transition-duration", "0.3s");
        el.style.setProperty("transition-timing-function", "ease");
        el.style.setProperty("opacity", "0");
    })

    const postItems = Array.from(document.querySelectorAll(`[js="blog__posts__item"]`)) as HTMLElement[];

    if (postItems.length == 0) return;

    const postItemsCount = postItems.length;
    const pagesCount = Math.ceil(postItemsCount / BLOG_ITEMS_PER_PAGE);
    const nextBtn = document.querySelector(`[js="blog__pagination__next"]`) as HTMLLinkElement;
    const prevBtn = document.querySelector(`[js="blog__pagination__previous"]`) as HTMLLinkElement;
    nextBtn.style.display = "none";
    prevBtn.style.display = "none";
    const urlParams = new URLSearchParams(window.location.search);
    const pageParam = urlParams.get("page");
    const currentPage = pageParam ? parseInt(pageParam) : 1;
    if (!nextBtn || !prevBtn) {
        console.error("No pagination buttons");
        return;
    }
    if (pagesCount > 0 && currentPage < pagesCount) {
        const nextPageParam = new URLSearchParams;
        nextPageParam.set("page", `${currentPage + 1}`);
        nextBtn.href = `${window.location.pathname}?${nextPageParam.toString()}`;
        nextBtn.style.setProperty("display", "flex");
    } else {
        nextBtn.style.setProperty("display", "none");
    }
    if (pagesCount > 0 && currentPage >= 2) {
        if (currentPage === 2) {
            prevBtn.href = `${window.location.pathname}`;
        } else {
            const prevPageParam = new URLSearchParams;
            prevPageParam.set("page", `${currentPage - 1}`);
            prevBtn.href = `${window.location.pathname}?${prevPageParam.toString()}`;
        }
        prevBtn.style.setProperty("display", "flex");
    } else {
        prevBtn.style.setProperty("display", "none");
    }
    Array.from(postItems).forEach((item, index) => {
        const isVisible = (index + 1) > (currentPage - 1) * BLOG_ITEMS_PER_PAGE && (index + 1) <= (currentPage) * BLOG_ITEMS_PER_PAGE;
        if (isVisible) {
            item.style.setProperty("display", "flex");
        } else {
            item.style.setProperty("display", "none");
        }
    });

    setTimeout(() => {
        [container, pagination].forEach(el => {
            el.style.setProperty("display", "flex");
            el.style.setProperty("opacity", "1");
        })
    }, 300);
};
