import { UserBase, isProWorkspace } from '@vctr-libs/vctr-api-client';
import { asyncTimeout } from '@vctr-libs/vctr-utils';

declare global {
	interface Window {
		_cio: {
			identify: (data: Record<string, any>) => void;
			reset: () => void;
			track: (eventName: string, eventData?: Record<string, any>) => void;
			on: (eventName: string, cb: (ev: CustomerIoEvent) => void) => void;
		};
	}
}

export interface CustomerIoEvent {
	detail: {
		actionName: string;
		actionValue: string;
		message: { dismiss: () => void };
	};
}

export interface CustomerIoWorkspace {
	user_role: string;
	plan_id: string;
	plan_name: string;
}

export interface CustomerIoUserData {
	id: string;
	email: string | null;
	name?: string;
	first_name?: string;
	last_name?: string;
	newsletter?: boolean;
	deleted?: boolean;
	questionnaire?: string;
	created_at?: number;
	workspaces?: CustomerIoWorkspace[];
	trial_used?: boolean;
	pro_used?: boolean;
	last_activity?: number;
	has_pro_workspace?: boolean;
}

export function resetCustomerIOUser() {
	if (!window?._cio?.reset) return;

	window._cio.reset();
}

export function isSegmentConditionsMet(user: UserBase) {
	const userCreated = new Date(user.created).getTime();
	const agreedToNewsletter = user.notifications === true;

	// 70 days are used for safe stopping of sending user actions to customer.io
	// to prevent new creation of already removed user from customer.io
	const after72Days = userCreated + (72 - 2) * 24 * 60 * 60 * 1000;
	const userIsOlderThan72Days = new Date(after72Days) < new Date();

	const isSegmentConditionsMet =
		agreedToNewsletter &&
		(!userIsOlderThan72Days || user.trial_used || user.pro_used);
	// TODO: new conditions after we adjust numbers
	// (agreedToNewsletter && !userIsOlderThan72Days) ||
	// user.trial_used ||
	// user.pro_used;

	return isSegmentConditionsMet;
}
export interface IdentifyCustomerIOUserProps {
	user: UserBase;
	workspaces?: CustomerIoWorkspace[];
	forceIdentify?: boolean;
}

export function identifyCustomerIOUser({
	user,
	workspaces,
	forceIdentify,
}: IdentifyCustomerIOUserProps) {
	const isMissingData =
		!user || !user.questionnaire || !user.email || !user.created;
	if (!window?._cio?.identify || isMissingData) {
		console.error('Customer.io - missing global _cio or user data');
		return;
	}

	if (!isSegmentConditionsMet(user) && !forceIdentify) {
		// this removes user from tracking page views & events
		resetCustomerIOUser();
		return;
	}

	const newsletter = isSegmentConditionsMet(user) ? user.notifications : false;
	const userData: CustomerIoUserData = {
		id: user.uuid,
		email: user.email,
		name: user.name,
		first_name: user.firstName,
		last_name: user.lastName,
		newsletter: newsletter,
		questionnaire: user.questionnaire.occupation.value,
		created_at: Math.round(new Date(user.created).getTime() / 1000),
		trial_used: user.trial_used,
		pro_used: !!user?.pro_used,
		last_activity: Math.round(new Date().getTime() / 1000),
	};

	if (workspaces) {
		userData.has_pro_workspace = workspaces
			.map(workspace => isProWorkspace(workspace.plan_id))
			.includes(true);

		// we split it here because some users have many workspaces,
		//   and we are limited by the maximum length of URL
		window._cio.identify({
			id: user.uuid,
			email: user.email,
			workspaces,
		});
	}

	window._cio.identify(userData);
}

export function initializeCustomerIo() {
	const isProduction = [
		'www.vectary.com',
		'app.vectary.com',
		'www.alpha.vectary.com',
		'app.alpha.vectary.com',
		'www.manuel.vectary.com',
		'app.manuel.vectary.com',
	].includes(window.location.hostname);
	const isInserted = !!document.querySelector('#cio-tracker');
	if (!isProduction || isInserted) return;

	const t = document.createElement('script'),
		s = document.getElementsByTagName('script')[0];
	t.async = true;
	t.id = 'cio-tracker';

	t.setAttribute('data-site-id', 'c5f48d52f53eec512b20');
	t.setAttribute('data-base-url', 'https://eu.customerioforms.com');
	t.setAttribute('data-use-array-params', 'true');

	t.src = 'https://assets.customer.io/assets/track-eu.js';

	if (s?.parentNode) s.parentNode.insertBefore(t, s);
}

export async function deleteCustomerIoUser(
	userUUID: string,
	userEmail: string,
) {
	if (!userUUID || !userEmail) return;

	const userData: CustomerIoUserData = {
		id: userUUID,
		email: userEmail,
		deleted: true,
	};

	window._cio.identify(userData);
	await asyncTimeout(400);
	resetCustomerIOUser();
	await asyncTimeout(400);
}

export function trackCustomerIoEvent(
	eventName: string,
	eventData?: Record<string, any>,
) {
	if (!window?._cio?.track) return;
	window._cio.track(eventName, eventData);
}
