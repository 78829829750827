import React, { FC } from 'react';
import { Alert, Button, Stack } from '@mui/material';
import { Warning } from '@mui/icons-material';

export const MembersSettingsLimitsAlert: FC = () => (
	<Alert
		color="warning-grey"
		variant="filled"
		icon={<Warning color="error" style={{ fontSize: '16px' }} />}
		action={
			<Stack className="btn-group" direction="row">
				<Button
					href="/business-contact-dashboard"
					target="_blank"
					size="medium"
					variant="contained-dark"
					color="primary"
				>
					Contact us
				</Button>
			</Stack>
		}
	>
		Your workspace has reached the limit for one or more seat types. To add more
		seats or if you need help, please contact us.
	</Alert>
);
