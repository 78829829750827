import { Permission, WorkspaceInvitation } from '@vctr-libs/vctr-api-client';

export function isMemberRoleSelectDisabled({
	permissionOptions,
	currentUserCanManageMembers,
	selectedPermission,
}: {
	permissionOptions: Permission[] | WorkspaceInvitation[];
	currentUserCanManageMembers: boolean;
	selectedPermission: Permission | WorkspaceInvitation;
}): boolean {
	return (
		!(permissionOptions.length > 0) ||
		!currentUserCanManageMembers ||
		selectedPermission === Permission.OWNER
	);
}
