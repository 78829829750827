import React, { FC } from 'react';
import {
	Plan,
	getReadablePlanPeriodName,
	BillingPeriod,
	isUserStudent,
	isUserEducator,
	UserBase,
	PlanIds,
} from '@vctr-libs/vctr-api-client';

import {
	GetProContent,
	TitleBlockComp,
	TextList,
	ListItem,
	GetProHighlight,
	GetProSubtitle,
	RadioGroup,
	RadioItemButton,
	RadioDot,
	RadioItemDot,
	RadioItemTitleBlock,
	RadioItemTitle,
	GetProComponentStates,
} from '../getPro';
import { Muted } from '../_base/typography';
import { DiscountBadge, UpgradeBlock } from './components';
import { PlanBillingText } from './PlanBillingText';
import { TotalPricingInitial } from './TotalPricingInitial';
import { BackButton } from './styledComponents';
import { PaddlePrice, StudentDiscount } from './utils';

// export type WidgetComponentProps = {
// 	show: boolean;
// 	initWidget: () => void;
// };

export type PaymentsContentProps = {
	// isTrialAllowed: boolean;
	// isUserStudent: boolean;
	studentDiscount: StudentDiscount;
	componentState: GetProComponentStates;
	selectedPlan: Plan;
	setSelectedPlan: (plan: Plan) => void;
	handleClickUpgrade: (plan: Plan) => void;
	handleClosePayments: () => void;
	handleBackPayments: () => void;
	couponPrice?: PaddlePrice;
	couponCode: string;
	// WidgetComponent: FC<WidgetComponentProps>;
	WidgetComponent: React.ReactNode;
	sortedPlanList: Plan[];
	user: UserBase;
	showMaybeLater?: boolean;
	isCheckoutLoaded: boolean;
	isCouponLoaded: boolean;
	precalculatedPlanPrices: Array<PaddlePrice & { planId: PlanIds}>;
};

export const PaymentsContent: FC<PaymentsContentProps> = props => {
	const {
		user,
		componentState,
		// isTrialAllowed,
		// isUserStudent,
		studentDiscount,
		selectedPlan,
		setSelectedPlan,
		handleClickUpgrade,
		handleClosePayments,
		handleBackPayments,
		couponPrice,
		couponCode,
		WidgetComponent,
		sortedPlanList,
		showMaybeLater = true,
		isCheckoutLoaded,
		isCouponLoaded,
		precalculatedPlanPrices
	} = props;

	const isStudentDiscountAllowed =
		isUserStudent(user) ||
		(isUserEducator(user) && studentDiscount?.REAL_VALUE_FLAT > 0);

	switch (componentState) {
		case GetProComponentStates.PAYMENTS_INITIAL_TRIAL:
			return (
				<>
					<GetProContent>
						<TitleBlockComp />
						<TextList>
							<ListItem>
								<GetProHighlight>Free 14 day trial, </GetProHighlight>
								<Muted>cancel any time</Muted>
							</ListItem>
							<ListItem>
								<Muted>Get all Pro features today</Muted>
							</ListItem>
						</TextList>
						<RadioGroup>
							{sortedPlanList?.map(plan => (
								<RadioItemButton
									onClick={() => setSelectedPlan(plan)}
									key={plan.id}
								>
									<RadioDot>
										<RadioItemDot active={selectedPlan.id === plan.id} />
									</RadioDot>
									<RadioItemTitleBlock>
										<RadioItemTitle>
											{getReadablePlanPeriodName(plan)}
											{` `}
											{plan.billing === BillingPeriod.YEAR && (
												// TODO: change this text for PRO flow for student
												<DiscountBadge
													isStudentDiscountAllowed={false}
													{...{ couponCode, sortedPlanList, studentDiscount }}
												/>
											)}
										</RadioItemTitle>
										<PlanBillingText
											{ ...{
												plan,
												couponPrice,
												precalculatedPlanPrices
											}}
											isStudentDiscountAllowed={false}
											studentDiscountFlat={null}
										/>
									</RadioItemTitleBlock>
								</RadioItemButton>
							))}
						</RadioGroup>
						<TotalPricingInitial
							{...{
								selectedPlan,
								couponPrice,
								couponCode,
								isStudentDiscountAllowed: false,
								studentDiscount: null,
								isCheckoutLoaded,
								isCouponLoaded,
							}}
						/>
						<UpgradeBlock
							componentState={componentState}
							selectedPlan={selectedPlan}
							handleClickUpgrade={handleClickUpgrade}
							handleClosePayments={handleClosePayments}
							showMaybeLater={showMaybeLater}
						/>
						{WidgetComponent}
					</GetProContent>
				</>
			);
		case GetProComponentStates.PAYMENTS_INITIAL_TRIAL_STUDENTS:
			return (
				<>
					<GetProContent>
						<TitleBlockComp />
						<TextList>
							<ListItem>
								<GetProHighlight>Free 14 day trial, </GetProHighlight>
								<Muted>cancel any time</Muted>
							</ListItem>
							<ListItem>
								<Muted>Get all Pro features today</Muted>
							</ListItem>
						</TextList>
						<RadioGroup>
							{sortedPlanList?.map(plan => (
								<RadioItemButton
									onClick={() => setSelectedPlan(plan)}
									key={plan.id}
								>
									<RadioDot>
										<RadioItemDot active={selectedPlan.id === plan.id} />
									</RadioDot>
									<RadioItemTitleBlock>
										<RadioItemTitle>
											{getReadablePlanPeriodName(plan)}
											{` `}
											{plan.billing === BillingPeriod.YEAR && (
												// TODO: change this text for PRO flow for student
												<DiscountBadge
													isStudentDiscountAllowed={true}
													{...{
														couponCode,
														sortedPlanList,
														studentDiscount,
													}}
												/>
											)}
										</RadioItemTitle>
										<PlanBillingText
											{...{
												plan,
												couponPrice,
												precalculatedPlanPrices
											}}
											isStudentDiscountAllowed={true}
											studentDiscountFlat={studentDiscount.REAL_VALUE_FLAT}
										/>
									</RadioItemTitleBlock>
								</RadioItemButton>
							))}
						</RadioGroup>
						<TotalPricingInitial
							{...{
								selectedPlan,
								couponPrice,
								couponCode,
								isStudentDiscountAllowed: true,
								studentDiscount,
								isCheckoutLoaded,
								isCouponLoaded,
							}}
						/>
						<UpgradeBlock
							componentState={componentState}
							selectedPlan={selectedPlan}
							handleClickUpgrade={handleClickUpgrade}
							handleClosePayments={handleClosePayments}
							showMaybeLater={showMaybeLater}
						/>
						{WidgetComponent}
					</GetProContent>
				</>
			);
		case GetProComponentStates.PAYMENTS_INITIAL_PRO_STUDENTS:
		case GetProComponentStates.PAYMENTS_INITIAL_PRO:
			return (
				<>
					<GetProContent>
						<TitleBlockComp />
						<GetProSubtitle>
							Boost your productivity and get more projects done faster with a
							Pro subscription plan. Select your prefered billing interval.
						</GetProSubtitle>
						<>
							{/* <WorkspaceToUpgradeSelect workspacesOptions={workspacesOptions} handleOnWorkspaceChange={handleOnWorkspaceChange} value={selectedWorkspace?.uuid} /> */}
							<RadioGroup>
								{sortedPlanList.map(plan => (
									<RadioItemButton
										onClick={() => setSelectedPlan(plan)}
										key={plan.id}
									>
										<RadioDot>
											<RadioItemDot active={selectedPlan.id === plan.id} />
										</RadioDot>
										<RadioItemTitleBlock>
											<RadioItemTitle>
												{getReadablePlanPeriodName(plan)}
												{` `}
												{plan.billing === BillingPeriod.YEAR && (
													<DiscountBadge
														isStudentDiscountAllowed={isStudentDiscountAllowed}
														{...{
															sortedPlanList,
															couponCode,
															studentDiscount,
														}}
													/>
												)}
											</RadioItemTitle>
											<PlanBillingText
											{ ...{
												plan,
												couponPrice,
												precalculatedPlanPrices
											}}
											isStudentDiscountAllowed={isStudentDiscountAllowed}
											studentDiscountFlat={studentDiscount.REAL_VALUE_FLAT}
										/>
										</RadioItemTitleBlock>
									</RadioItemButton>
								))}
							</RadioGroup>
							<TotalPricingInitial
								{...{
									selectedPlan,
									couponPrice,
									couponCode,
									isStudentDiscountAllowed,
									studentDiscount,
									isCheckoutLoaded,
									isCouponLoaded,
								}}
							/>
							<UpgradeBlock
								componentState={componentState}
								selectedPlan={selectedPlan}
								handleClickUpgrade={handleClickUpgrade}
								handleClosePayments={handleClosePayments}
								showMaybeLater={showMaybeLater}
							/>
						</>
						{WidgetComponent}
					</GetProContent>
				</>
			);
		case GetProComponentStates.PAYMENTS_WIDGET:
			return (
				<>
					<BackButton onClick={() => handleBackPayments()}>
						<img src="./images/icon-arrow-left-full.svg" />
					</BackButton>
					<GetProContent>
						<TitleBlockComp />
						<TotalPricingInitial
							{...{
								selectedPlan,
								couponPrice,
								couponCode,
								isStudentDiscountAllowed,
								studentDiscount,
								isCheckoutLoaded,
								isCouponLoaded,
							}}
						/>
					</GetProContent>
					{WidgetComponent}
				</>
			);
		default:
			return null;
	}
};
