import React from 'react';
import { WorkspaceUserSeat } from '@vctr-libs/vctr-api-client';
import {
	MemberTableItemName,
	MemberTableItemImage,
	MembersTable__Item__NameBlock,
	MemberTableItemName__Email,
} from './membersTable/styledComponents';
import { defaultUserPicture } from './defaultUserPicture';

export function MemberTable__Item__Seat(
	props: Pick<WorkspaceUserSeat, 'name' | 'email' | 'profile_picture'>,
) {
	const { name, email, profile_picture } = props;
	return (
		<MembersTable__Item__NameBlock>
			<MemberTableItemImage src={profile_picture || defaultUserPicture} />

			<MemberTableItemName>
				<strong>{name}</strong>
				<br />
				<MemberTableItemName__Email>{email}</MemberTableItemName__Email>
			</MemberTableItemName>
		</MembersTable__Item__NameBlock>
	);
}
