import React, { FC, useEffect, useState } from 'react';

import {
	Wrapper,
	Slideshow,
	Item,
	ItemImage,
	ItemVideo,
	Content,
	Title,
	Description,
	ArrowLeftWrapper,
	ArrowImage,
	ArrowRightWrapper,
	NavigationDots,
	DotWrapper,
	Dot,
} from './styledComponents';
import { CarouselItemType } from '..';

const getCurrentItemIndex = (currentLeftOffset: number, itemWidth: number) =>
	Math.abs(currentLeftOffset / itemWidth);

const DEFAULT_ITEM_WIDTH = 384;
const DEFAULT_ITEM_HEIGHT = 288;
const AUTOPLAY_INTERVAL_SECONDS = 14; // seconds

let interval: ReturnType<typeof setInterval>;

export type CarouselItem = {
	content: string | React.ReactElement;
	title: string;
	contentType?: CarouselItemType;
	imgSrc?: string;
	videoSrc?: [
		{
			videoType: string;
			src: string;
		},
	];
};

export type CarouselProps = {
	items: CarouselItem[];
	itemHeight?: number;
	itemWidth?: number;
	imgAspectRatio?: string | number;
	assetsPath: string;
	imagesPath: string;
	autoplay?: boolean;
	initialSlide?: number;
};

export const Carousel: FC<CarouselProps> = props => {
	const {
		items,
		itemWidth = DEFAULT_ITEM_WIDTH,
		itemHeight = DEFAULT_ITEM_HEIGHT,
		imgAspectRatio = 'auto',
		assetsPath,
		imagesPath,
		autoplay = true,
		initialSlide = 0,
	} = props;
	const initialLeftOffset = initialSlide * itemWidth * -1;
	const [leftOffset, setLeftOffset] = useState<number>(initialLeftOffset);
	const [playing, setPlaying] = useState<boolean>(autoplay);
	const [leftArrowIsHovered, setLeftArrowIsHovered] = useState<boolean>(false);
	const [rightArrowIsHovered, setRightArrowIsHovered] =
		useState<boolean>(false);

	useEffect(() => {
		interval = setInterval(() => {
			if (!playing) {
				return;
			}
			showNextSlide();
		}, AUTOPLAY_INTERVAL_SECONDS * 1000);

		return function cleanup() {
			clearInterval(interval);
		};
	}, [playing, leftOffset]);

	function handleReset(): void {
		setLeftOffset(0);
	}

	function showPreviousSlide() {
		const currentItemIndex = getCurrentItemIndex(leftOffset, itemWidth);
		if (currentItemIndex === 0) {
			setLeftOffset((items.length - 1) * itemWidth * -1);
			return;
		}
		setLeftOffset(leftOffset + itemWidth);
	}

	function showNextSlide() {
		const currentItemIndex = getCurrentItemIndex(leftOffset, itemWidth);
		if (currentItemIndex === items.length - 1) {
			handleReset();
			return;
		}
		setLeftOffset(leftOffset - itemWidth);
	}

	const handleRightArrowClick = (): void => {
		setPlaying(false);
		showNextSlide();
	};

	const handleLeftArrowClick = (): void => {
		setPlaying(false);
		showPreviousSlide();
	};

	const handleSetSelectedItem = (itemIndex: number): void => {
		setLeftOffset(itemIndex * itemWidth * -1);
		setPlaying(false);
	};

	return (
		<Wrapper width={itemWidth}>
			<Slideshow leftOffset={leftOffset}>
				{items.map((item, index) => {
					const { content, imgSrc, title } = item;
					const isSelected =
						getCurrentItemIndex(leftOffset, itemWidth) === index;
					const key = `item_${title}`;

					return (
						<Item isSelected={isSelected} key={key}>
							{item.contentType === CarouselItemType.VIDEO ? (
								<ItemVideo
									width={itemWidth}
									imgAspectRatio={imgAspectRatio}
									autoPlay={true}
									muted={true}
									playsInline={true}
									loop={true}
									crossOrigin=""
								>
									{item.videoSrc.map((video, index) => {
										return (
											<source
												src={video.src}
												type={video.videoType}
												key={`video_${index}`}
											/>
										);
									})}
								</ItemVideo>
							) : (
								<ItemImage
									src={`${imagesPath}/${imgSrc}`}
									alt={title}
									width={itemWidth}
									imgAspectRatio={imgAspectRatio}
								/>
							)}
							<Content>
								<Title>{title}</Title>
								<Description>{content}</Description>
							</Content>
						</Item>
					);
				})}
			</Slideshow>
			<ArrowLeftWrapper
				itemHeight={itemHeight}
				onMouseEnter={() => {
					setLeftArrowIsHovered(true);
				}}
				onMouseLeave={() => {
					setLeftArrowIsHovered(false);
				}}
				onClick={handleLeftArrowClick}
			>
				<ArrowImage
					src={
						leftArrowIsHovered
							? `${assetsPath}/images/icon-arrow-left-primary.svg`
							: `${assetsPath}/images/icon-arrow-left-black.svg`
					}
				/>
			</ArrowLeftWrapper>
			<ArrowRightWrapper
				itemHeight={itemHeight}
				onMouseEnter={() => {
					setRightArrowIsHovered(true);
				}}
				onMouseLeave={() => {
					setRightArrowIsHovered(false);
				}}
				onClick={handleRightArrowClick}
			>
				<ArrowImage
					src={
						rightArrowIsHovered
							? `${assetsPath}/images/icon-arrow-right-primary.svg`
							: `${assetsPath}/images/icon-arrow-right-black.svg`
					}
				/>
			</ArrowRightWrapper>
			<NavigationDots itemsCount={items.length + 1} itemHeight={itemHeight}>
				{items.map((item, index) => {
					const isSelected =
						getCurrentItemIndex(leftOffset, itemWidth) === index;
					const key = `dot_${item.title}`;

					return (
						<DotWrapper
							key={key}
							onClick={() => {
								handleSetSelectedItem(index);
							}}
						>
							<Dot isSelected={isSelected} />
						</DotWrapper>
					);
				})}
			</NavigationDots>
		</Wrapper>
	);
};
