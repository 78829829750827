import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from '@mui/material';
import { DashboardMuiOverrideStyler, getDashboardAppMuiTheme } from '../themes';
import { ToasterProvider } from '../../components';

/**
 * Provides Dashboard App MUI theme (4.0 / 2022 styles implemented with MUI)
 * */
export const DashboardMuiThemeProvider: FC = ({
	children,
}: {
	children: ReactNode;
}) => (
	<ThemeProvider theme={getDashboardAppMuiTheme()}>
		<DashboardMuiOverrideStyler className="DashboardMuiOverrideStyler">
			{children}
		</DashboardMuiOverrideStyler>
		<ToasterProvider />
	</ThemeProvider>
);
