// Pricing/Plans helper enum and switch for studio and studio lite

import { PlanIds } from "../../plan/plans";

// These could be removed once all "products" are added to all the right plans lists
export enum PlanType {
	FREE = 'free',
	PRO = 'pro',
	BUSINESS = 'business',
}

export enum PlanProductLimit {
	HARD_LIMIT = 'hard_limit',
	SOFT_LIMIT = 'soft_limit'
}

/** extended plan type for dashboard
 * 
 * TODO merge with PlanType later
*/
export const PlanTypeDashboard = {
	LOCKED: 'locked',
	STARTER: 'starter',
	PRO: 'pro',
	ENTERPRISE: 'enterprise',
	LEGACY: 'legacy',
} as const;

export type PlanTypeDashboard = typeof PlanTypeDashboard[keyof typeof PlanTypeDashboard];

export const planTypeByKeywords = {
	[PlanTypeDashboard.STARTER]: ["starter", "education", "setup"],
	[PlanTypeDashboard.PRO]: ["pro", "business"],
	[PlanTypeDashboard.ENTERPRISE]: ["enterprise", "custom", "vectary"],
	[PlanTypeDashboard.LOCKED]: ["locked"],
}

export function getPlanTypeDashboard(planId: PlanIds): PlanTypeDashboard {

	const matchingEntries = (Object.entries(planTypeByKeywords) as [PlanTypeDashboard, string[]][]).find(([kind, plans]) => {
		const _p = plans.some(p => (planId as string).toLowerCase().includes(p));
		return _p;
	});

	const planType = matchingEntries ? matchingEntries[0] : PlanTypeDashboard.LEGACY;
	return planType;
}

