import React, { FC, useEffect, useState } from 'react';

import { Button, ButtonEnumVariant } from '../_common/button';
import {
	ModalButtons,
	CarouselModal,
	CarouselWrapper,
} from './styledComponents';

import { ThemeStyler } from '../../styles';

import { Carousel } from '../carousel';
import { enterpriseItems, DiscoverProProps } from '.';

export type DiscoverProEnterpriseProps = DiscoverProProps & {
	handleGetInTouch?: () => void;
};

export const DiscoverProEnterprise: FC<DiscoverProEnterpriseProps> = (
	props: DiscoverProEnterpriseProps,
) => {
	const {
		showModalInitial = false,
		handleGetInTouch,
		logEventOnShow,
		// modalZIndex, // Not sure why it is not working
		assetsPath = './',
		imagesPath = './images',
		autoplay = true,
		initialSlide,
	} = props;

	const [showModal, setShowModal] = useState<boolean>(showModalInitial);

	useEffect(() => {
		setShowModal(showModalInitial);
	}, [showModalInitial]);

	const handleModalToggle = (isVisible: boolean): void => {
		setShowModal(isVisible);
	};

	useEffect(() => {
		if (showModal === true) {
			logEventOnShow();
		}
	}, [showModal]);

	return (
		<ThemeStyler>
			<CarouselModal
				show={showModal}
				handleToggle={handleModalToggle}
				zIndex={53 /* modalZIndex */}
				className="discover_pro_modal discover_pro_modal--enterprise"
			>
				<CarouselWrapper>
					<Carousel
						items={enterpriseItems}
						imgAspectRatio={384 / 288}
						assetsPath={assetsPath}
						imagesPath={imagesPath}
						autoplay={autoplay}
						initialSlide={initialSlide}
					/>
					<ModalButtons>
						<Button
							onClick={handleGetInTouch}
							variant={ButtonEnumVariant.PRIMARY}
						>
							Get started
						</Button>
					</ModalButtons>
				</CarouselWrapper>
			</CarouselModal>
		</ThemeStyler>
	);
};
