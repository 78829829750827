import React from 'react';
import { Shadows, ThemeOptions, createTheme } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import { Prettify } from '@vctr-libs/vctr-utils';
import { colors } from '../../styles';
import styled from 'styled-components';

type VeThemeOptions = Prettify<ThemeOptions>;

const PRIMARY = colors.c_light_mode_primary;
const ALMOST_BLACK_8 = colors.c_almost_black;
const MID_GREY_5 = colors.c_mid_grey;

const _fontSizeBase = 11;
// const _fontSizeSmall = 9;
const _fontSizeHeading = 18;

const _lineHeightBase = '1.455em';

const _iconFontSizeBase = 14;

// there's no light in Figma
const _fontWeightLight = 200;
const _fontWeightRegular = 400;
const _fontWeightSemibold = 600;
const _fontWeightBold = 700;

const _paddingBaseY = 4;
const _paddingBaseX = 8;
const _paddingBase = `${_paddingBaseY}px ${_paddingBaseX}px`;
const _paddingSmall = `${_paddingBaseY / 2}px ${_paddingBaseX / 2}px`;

const _borderWidthBase = 1;

/** Overrides to fix resets and other global styles */
export const DashboardMuiOverrideStyler = styled.div`
	strong {
		font-weight: ${_fontWeightBold};
	}
	& ::-webkit-scrollbar {
		height: 4px;
	}
	&::-webkit-scrollbar-thumb,
	& *::-webkit-scrollbar-thumb {
		background-color: ${colors.c_soft_grey};
		border-radius: 4px;
		padding: 0;
		margin: 0;
	}
`;

/** sizes in pixels */
export const DashboardAppMuiThemeVariables = {
	gridGap: 16,
	memberImageSize: 24,
	iconFontSize: _iconFontSizeBase,
} as const;

const components: VeThemeOptions['components'] = {
	// MuiCssBaseline: {
	// 	styleOverrides: {
	// 	}
	// },
	MuiSvgIcon: {
		styleOverrides: {
			root: {
				fontSize: _iconFontSizeBase,
			},
		},
	},
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				fontSize: _fontSizeHeading,
			},
		},
	},
	MuiButtonBase: {
		defaultProps: {
			disableRipple: true,
			disableTouchRipple: true,
		},
	},
	MuiButton: {
		defaultProps: {
			disableRipple: true,
		},
		styleOverrides: {
			root: {
				padding: _paddingBase,
				lineHeight: _lineHeightBase,
				fontWeight: _fontWeightSemibold,
				textTransform: 'none',
			},
		},
	},
	MuiMenu: {
		styleOverrides: {
			paper: {
				boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				padding: _paddingBase,
				lineHeight: _lineHeightBase,
				minHeight: 0,
			},
		},
	},
	MuiSelect: {
		defaultProps: {
			IconComponent: ExpandMore,
		},
		styleOverrides: {
			root: {
				lineHeight: _lineHeightBase,
			},
			select: {
				'&.Mui-disabled': {
					'cursor': 'not-allowed',
					'&:hover': {
						borderColor: colors.c_soft_grey,
					},
				},
			},
			icon: {
				fontSize: _iconFontSizeBase,
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				// TODO fix this, somehow not working
				// '&:hover': {
				// 	'borderColor': PRIMARY,
				// 	'&.Mui-focused': {
				// 		borderColor: PRIMARY,
				// 	},
				// },
				// '&.Mui-focused': {
				// 	borderColor: PRIMARY,
				// },
			},
			input: {
				padding: _paddingBase,
			},
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				'&:hover .MuiOutlinedInput-notchedOutline': {
					borderColor: PRIMARY,
				},
				'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					borderWidth: _borderWidthBase,
				},
			},
			inputTypeSearch: {
				padding: _paddingBase,
			},
			input: {
				padding: _paddingBase,
			},
		},
	},
	MuiAutocomplete: {
		styleOverrides: {
			root: {
				'.MuiOutlinedInput-root .MuiAutocomplete-input': {
					padding: _paddingBase,
				},
			},
			input: {
				padding: _paddingSmall,
			},
			inputRoot: {
				padding: _paddingSmall,
			},
			inputFocused: {},
		},
	},
	MuiChip: {
		defaultProps: {
			deleteIcon: <ClearIcon />,
		},
		styleOverrides: {
			root: {
				fontSize: _fontSizeBase,
				lineHeight: _lineHeightBase,
				height: 'auto',
				// padding: `${_paddingBaseY / 2}px ${_paddingBaseX}px`,
			},

			label: {
				padding: `${_paddingBaseY / 2}px ${_paddingBaseX}px`,
			},

			deletable: {
				paddingRight: `${_iconFontSizeBase / 2}px`,

				// root: {
				// 	padding: `${_paddingBaseY / 2}px ${_paddingBaseX}px`,
				// 	// paddingRight: `${_paddingBaseX + 10 + 14}px`,
				// },
				// label: {
				// paddingLeft: 0,
				// paddingRight: 8,
				// },
			},
			deleteIcon: {
				fontSize: _iconFontSizeBase,
				marginRight: 0,
				marginLeft: -(_iconFontSizeBase / 2),
			},
		},
	},
	MuiTabs: {
		styleOverrides: {
			root: {
				minHeight: 0,
				boxShadow: `inset 0 -1px 0 ${colors.c_light_grey}`,
			},
			indicator: {
				height: 1,
			},
		},
	},
	MuiTab: {
		defaultProps: {
			disableRipple: true,
			disableFocusRipple: true,
			disableTouchRipple: true,
		},
		styleOverrides: {
			root: {
				'textTransform': 'none',
				'padding': `${_paddingBaseX}px ${_paddingBaseY * 2}px`,
				'minHeight': 0,
				'borderBottom': `1px solid ${colors.c_light_grey}`,
				'&.Mui-selected': {
					borderBottomWidth: 1,
				},
			},
		},
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				borderColor: colors.c_light_grey,
			},
		},
	},
};

export function getDashboardAppMuiTheme() {
	const themeOptions: VeThemeOptions = {
		typography: {
			fontFamily: 'Inter, Helvetica, Arial, sans-serif',
			htmlFontSize: _fontSizeBase,
			fontSize: _fontSizeBase,
			fontWeightLight: _fontWeightLight,
			fontWeightRegular: _fontWeightRegular,
			fontWeightMedium: _fontWeightSemibold,
			fontWeightBold: _fontWeightBold,
			body1: {
				fontSize: _fontSizeBase,
			},
			body2: {
				fontSize: _fontSizeBase,
			},
			button: {
				fontSize: _fontSizeBase,
			},
			caption: {
				fontSize: _fontSizeBase,
			},
			// subtitle1,
			// subtitle2,
		},

		palette: {
			primary: {
				main: PRIMARY,
			},
			secondary: {
				main: ALMOST_BLACK_8,
			},
			text: {
				primary: ALMOST_BLACK_8,
				secondary: MID_GREY_5,
			},
		},

		// see Shadows typing — there're 25 shadow values
		shadows: Array<string>(25).fill('none') as Shadows,

		shape: {
			borderRadius: 4,
		},

		// spacing: 8,

		components,

		// 	MuiInputBase: {
		// 		styleOverrides: {
		// 			root: {
		// 				'border': '1px solid #EFEFEF',
		// 				'borderRadius': '8px',
		// 				'&:hover': {
		// 					'&.Mui-focused': {
		// 						borderColor: PRIMARY,
		// 					},
		// 				},
		// 				'&.Mui-focused': {
		// 					borderColor: PRIMARY,
		// 				},
		// 			},
		// 		},
		// 	},
		// 	MuiSelect: {
		// 		styleOverrides: {
		// 			select: {
		// 				'&:focus': {
		// 					backgroundColor: 'transparent',
		// 				},
		// 			},
		// 		},
		// 	},
	};

	return createTheme(themeOptions);
}
