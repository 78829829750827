import { isCookiesConsentEnv } from '@vctr-libs/vctr-utils';

declare global {
	interface Window {
		trackingFunctions: {
			onLoad: (data: Record<string, any>) => void;
		};
	}
}

export function initApollo() {
	const isInserted = !!document.querySelector('#apollo-tracker');
	if (!isCookiesConsentEnv || isInserted) return;

	var n = Math.random().toString(36).substring(7),
		o = document.createElement('script');
	(o.src =
		'https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=' +
		n),
		(o.async = !0),
		(o.defer = !0),
		(o.id = 'apollo-tracker'),
		(o.onload = function () {
			window.trackingFunctions.onLoad({ appId: '660d037a769f3c0301c883da' });
		}),
		document.head.appendChild(o);
}
