import React, { FC } from 'react';
import styled from 'styled-components';

const ButtonContact = styled.a`
	display: flex;
	justify-content: center;
	border-radius: ${({ theme }) => theme.borderRadius[0]};
	background-color: ${({ theme }) => theme.colors.c_light_mode_primary};
	border: 1px solid ${({ theme }) => theme.colors.c_light_mode_primary};
	color: ${({ theme }) => theme.colors.c_off_white};
	user-select: none;
	padding: 4px 8px;
	flex: 1;
	font-size: ${({ theme }) => theme.fontSize.font_size_small};
	line-height: normal;
	font-weight: ${({ theme }) => theme.fontWeights.font_weight_small};
	text-align: center;
	text-transform: capitalize;

	&:hover {
		background-color: ${({ theme }) => theme.colors.c_white};
		color: ${({ theme }) => theme.colors.c_light_mode_primary};
	}
`;

interface ContactUsButtonProps {
	type?: 'general' | 'enterprise';
}

export const ContactUsButton: FC<ContactUsButtonProps> = ({
	type = 'general',
}) => {
	const isGeneral = type === 'general';

	return (
		<ButtonContact
			href={isGeneral ? '/contact' : '/enterprise-contact'}
			target="blank"
		>
			{isGeneral ? 'Contact Us' : 'Contact Sales'}
		</ButtonContact>
	);
};
