interface NavigatorInfo {
    userAgent: string;
    appVersion: string;
    platform: string;
    connection?: string;
}

export class BrowserInfo {

    static getNavigatorInfo(): NavigatorInfo {
        let data: NavigatorInfo = {
            userAgent: navigator.userAgent,
            appVersion: navigator.appVersion,
            platform: navigator.platform,
        };
        if ((navigator as any).connection) {
            const c = (navigator as any).connection;
            data.connection = [
                `downlink: ${c.downlink}`,
                `rtt: ${c.rtt}`,
                `effectiveType: ${c.effectiveType}`,
                `type: ${c.type}`
            ].join(", ");
        }
        return data;
    }

    static isEdge(): boolean {
        return /Edge/.test(navigator.userAgent);
    }

    static isSafari(): boolean {
        return /^((?!chrome|android|crios|fxios).)*safari/i.test(navigator.userAgent);
    }

    static isMobile(): boolean {
        const mobileDevicesMatcher: RegExp = /(iPhone|iPod|iPad|BlackBerry|Android|Windows Phone|iemobile)/;
        return navigator.userAgent.match(mobileDevicesMatcher) !== null;
    }

    static isTouchAvailable(): boolean {
        return ('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }

    static getPixelRatio(): number {
        return self.devicePixelRatio || 1;
    }

    static isLocalhost(): boolean {
        return window.location.origin.includes("localhost");
    }

    static isIOS(): boolean {
        const isiPadOS = () => {
            if (/Safari[\/\s](\d+\.\d+)/.test(window.navigator.userAgent)) {
                return "ontouchstart" in window;
            }
            return false;
        };

        function iOSversion(): number[] {
            const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || "0", 10)];
        }

        let iosVersion;
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            iosVersion = iOSversion();
        }
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const isFirefox = navigator.userAgent.toLowerCase().indexOf("fxios") > -1;

        if (iosVersion && iOS && iosVersion[0] >= 12 && !isFirefox || isiPadOS()) {
            return true;
        }
        return false;
    }

    static isMacOS(): boolean {
        return /(Mac)/i.test(navigator.userAgent);
    }
}
