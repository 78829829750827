import { logout } from "@vctr-libs/vctr-api-client";
import { showElementsForUnauhtorisedUsers, showLoginForm } from "./showHide";

export const attachLogout = () => {
    try {
        const signOutBtns = document.querySelectorAll(`[js="navbarSignOut"]`);
        signOutBtns.forEach((elem) => {
            elem.addEventListener("click", (e: Event) => {
                e.preventDefault();
                userLogout();
                console.log("click");
            });
        });
    } catch (e) {
        console.warn("attach logout", e);
    }
};
const userLogout = async () => {
    try {
        await logout();
    } catch (e) {
        console.warn("logout", e);
    }
    showElementsForUnauhtorisedUsers();
    showLoginForm();
};
