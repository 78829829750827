import React, { ComponentProps, FC } from 'react';
import {
	Box,
	Alert,
	TableRow,
	TableCell,
	Link,
	TableHead,
	TableBody,
} from '@mui/material';
// import styled from 'styled-components';
import { BoxContentPaper } from '../../_uko/BoxContent';
import { Spinner } from '../../spinner';
import { H_StudioHeading, textLight } from '../../../ui';
import {
	WorkspaceBaseDashboard,
	WorkspacePayment,
} from '@vctr-libs/vctr-api-client';
import { Info, Link as LinkIcon } from '@mui/icons-material';
import { BillingSubscription } from './BillingSubscriptions';
import { BillingInvoicesTable } from './styledComponents';

type BillingSubscriptionSharedProps = Pick<
	ComponentProps<typeof BillingSubscription>,
	| 'handleCancelSubscriptionClick'
	| 'handleContactSales'
	| 'handleUpgradePlanClick'
	| 'handleComparePlansClick'
>;

export type BillingSettingsProps = BillingSubscriptionSharedProps & {
	currentWorkspace: WorkspaceBaseDashboard;
	BillingSeatsText: React.ReactNode;
	payments: WorkspacePayment[];
	isLoading: boolean;
};

export const BillingSettingsUi: FC<BillingSettingsProps> = props => {
	const {
		currentWorkspace,
		payments,
		isLoading,
		handleCancelSubscriptionClick,
		handleContactSales,
		handleUpgradePlanClick,
		handleComparePlansClick,
	} = props;
	return (
		<>
			<Box mb={2}>
				<BillingSubscription
					{...{
						plan: currentWorkspace.plan,
						handleCancelSubscriptionClick,
						handleContactSales,
						handleUpgradePlanClick,
						handleComparePlansClick,
					}}
				/>
			</Box>
			<Box mb={2}>
				<Alert
					color="warning-grey"
					variant="filled"
					icon={<Info style={{ fontSize: '16px' }} />}
				>
					Invoices are sent via <strong>Paddle</strong> our payment provider.
					For help or assistance with your receipt or tax information, please
					reply to your last invoice or contact{' '}
					<a href="mailto:help@paddle.com" target="_blank">
						help@paddle.com
					</a>
				</Alert>
			</Box>
			{payments?.length > 0 && (
				<Box mb={2}>
					<BoxContentPaper>
						<Box py={2}>
							<H_StudioHeading p={3}>
								<strong>{'Payment History'}</strong>{' '}
								<small>({payments.length} most recent)</small>
							</H_StudioHeading>

							<BillingInvoicesTable>
								<TableHead>
									<TableRow>
										<TableCell width={'50%'}>Order number</TableCell>
										<TableCell width={'50%'}>Link</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{payments?.map((payment: WorkspacePayment) => {
										return (
											<TableRow key={`workspace_payment_${payment.id}`}>
												<TableCell>{payment.id}</TableCell>
												<TableCell>
													<Link
														color={textLight.secondary}
														href={payment.receipt_url}
														target="_blank"
													>
														<LinkIcon style={{ rotate: '-45deg' }} />
													</Link>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</BillingInvoicesTable>
							{/* <>
							{!isCustomPlanWorkspace(currentWorkspace?.plan?.id) && (
								<BillingCancellationInfo currentWorkspace={currentWorkspace} />
							)}
						</> */}
						</Box>
					</BoxContentPaper>
				</Box>
			)}

			{/* <Box mb={1}>
					<Button
						variant="contained"
						color="primary"
						href={'/contact'}
						target="blank"
					>
						Contact Us
					</Button>
				</Box>
				<Box>
					{canCurrentUser({
						action: UserAction.WORKSPACE_BILLING_EDIT,
						currentWorkspace,
					}) &&
						isWorkspaceDowngradable(currentWorkspace.plan) && (
							<Link onClick={onCancelSubscriptionClick}>
								Cancel subscription
							</Link>
						)}
				</Box> */}
			{/* TODO: Read /contact-us from some config/env file in the future. */}
			{isLoading && <Spinner />}
		</>
	);
};
