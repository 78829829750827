import React, { FC, useEffect, useState } from 'react';

import { Button, ButtonEnumVariant } from '../_common/button';
import {
	ModalButtons,
	CarouselModal,
	CarouselWrapper,
} from './styledComponents';

import { ThemeStyler } from '../../styles';
import { Carousel } from '../carousel';
import { DiscoverProButton, carouselItems } from '.';

export type DiscoverProProps = {
	showModalInitial?: boolean;
	handleGetProClick?: () => void;
	handleComparePlansClick?: () => void;
	logEventOpenModal?: () => void;
	logEventOnShow?: () => void;
	modalZIndex?: number;
	assetsPath?: string;
	imagesPath?: string;
	workspaceUuid?: string;
	autoplay?: boolean;
	initialSlide?: number;
};

export const DiscoverPro: FC<DiscoverProProps> = (props: DiscoverProProps) => {
	const {
		showModalInitial = false,
		handleComparePlansClick,
		handleGetProClick,
		logEventOpenModal,
		logEventOnShow,
		modalZIndex,
		assetsPath = './',
		imagesPath = './images',
		autoplay = true,
		initialSlide,
	} = props;

	const [showModal, setShowModal] = useState<boolean>(showModalInitial);

	useEffect(() => {
		setShowModal(showModalInitial);
	}, [showModalInitial]);

	const handleModalToggle = (isVisible: boolean): void => {
		setShowModal(isVisible);
	};
	const handleButtonClick = (): void => {
		setShowModal(true);
		if (logEventOpenModal) {
			try {
				logEventOpenModal();
			} catch {
				throw new Error('Failed to log event');
			}
		}
	};

	useEffect(() => {
		if (showModal === true) {
			logEventOnShow();
		}
	}, [showModal]);

	return (
		<ThemeStyler>
			<DiscoverProButton handleButtonClick={handleButtonClick} />
			<CarouselModal
				show={showModal}
				handleToggle={handleModalToggle}
				zIndex={modalZIndex}
				className="discover_pro_modal"
			>
				<CarouselWrapper>
					<Carousel
						items={carouselItems}
						imgAspectRatio={384 / 288}
						assetsPath={assetsPath}
						imagesPath={imagesPath}
						autoplay={autoplay}
						initialSlide={initialSlide}
					/>
					<ModalButtons>
						<Button
							onClick={handleComparePlansClick}
							variant={ButtonEnumVariant.DEFAULT}
						>
							Compare plans
						</Button>
						<Button
							onClick={handleGetProClick}
							variant={ButtonEnumVariant.PRIMARY}
						>
							Get Pro
						</Button>
					</ModalButtons>
				</CarouselWrapper>
			</CarouselModal>
		</ThemeStyler>
	);
};
