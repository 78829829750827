import { EventLogger } from '@vctr-libs/vctr-analytics';
import { logWebsiteEvent } from './helpers';
import { pushGTMEvent, Ga4Events } from './analyticsEvents';

export async function handleFlowhuntEvents(eventLogger: EventLogger) {
	const chatRoot = document.querySelector('.fh-chatbot');
	const shadowRoot = chatRoot?.shadowRoot;

	if (!shadowRoot) {
		console.warn('Flowhunt chatbot element not found. Retrying in 500ms');
		// async timeout
		await new Promise((resolve) => setTimeout(resolve, 500));
		handleFlowhuntEvents(eventLogger);
	}

	const flowhuntButton = shadowRoot.querySelector('button[type="button"]');

	if (!flowhuntButton) {
		console.warn('Flowhunt chatbot button not found. Retrying in 500ms');
		// async timeout
		await new Promise((resolve) => setTimeout(resolve, 500));
		handleFlowhuntEvents(eventLogger);
	}

	let isCloseBtnListenerSet: boolean = false;

	flowhuntButton.addEventListener('click', () => {
		logWebsiteEvent(['flowhunt_bot_button_click', undefined], eventLogger);
		pushGTMEvent({
			event: Ga4Events.FlowhuntBotButtonClick,
		});
		isCloseBtnListenerSet = false;
	});

	const mutationsCallback = (mutationsList: MutationRecord[]) => {

		const closeBtn = shadowRoot.querySelector('.flex.size-6.items-center.justify-center.rounded.bg-transparent.transition-colors');
		if (closeBtn && !isCloseBtnListenerSet) {
			closeBtn.addEventListener('click', () => {
				logWebsiteEvent(['flowhunt_bot_closed_by_user', undefined], eventLogger);
			});
			isCloseBtnListenerSet = true;
		}

		for (const mutation of mutationsList) {
			if (mutation.type === 'childList') {
				console.log("addedNodes", mutation.addedNodes);
				mutation.addedNodes.forEach((node: HTMLElement) => {
					if (!node) return;
					const classNames = node.classList ? Array.from(node.classList) : undefined;
					if (node.nodeType === Node.ELEMENT_NODE
						&& node.tagName === 'DIV'
						&& node.classList
						&& classNames?.includes('group/message') && classNames?.includes('bg-chatbot-600')
					) {
						logWebsiteEvent(['flowhunt_bot_user_sent_message', undefined], eventLogger);
						pushGTMEvent({
							event: Ga4Events.FlowhuntBotUserSentMessage,
						});
					}
				});
			}
		}

	};

	const config = {
		childList: true,
		subtree: true,
	};

	const observer = new MutationObserver(mutationsCallback);

	observer.observe(shadowRoot.children[0], config);
}
