import { WorkspaceBaseDashboard, WorkspaceBase } from '../interface/interface';
import { Permission } from '../interface/permissionRoles';
import { UserBase } from '../../user/interface';
import { FREE_PLANS } from '../../plan/plans';
import { isWorkspaceCancelled, isCustomPlanWorkspace } from './isWorkspace';

interface WorkspaceMemberCheckProps {
	user: UserBase;
	workspace: WorkspaceBaseDashboard;
	isCurrentUser?: boolean; // Tells if given user is currently logged-in user.
}
interface WorkspaceOwnerCheckProps {
	user: UserBase;
	workspace: WorkspaceBaseDashboard;
}

export const isUserEditor = (props: WorkspaceMemberCheckProps): boolean => {
	const { user, workspace, isCurrentUser = true } = props;

	return (
		(isCurrentUser === true &&
			workspace.currentUserRole === Permission.EDIT_2024) ||
		workspace?.users?.filter(
			u =>
				u.uuid === user.uuid &&
				u.permission === (Permission.EDIT_2024 as String),
		).length > 0
	);
};

export const isUserViewer = (props: WorkspaceMemberCheckProps): boolean => {
	const { user, workspace, isCurrentUser = true } = props;

	return (
		(isCurrentUser === true &&
			workspace.currentUserRole === Permission.VIEW_2024) ||
		workspace?.users?.filter(
			u =>
				u.uuid === user.uuid &&
				u.permission === (Permission.VIEW_2024 as String),
		).length > 0
	);
};

export const isUserOwner = (props: WorkspaceOwnerCheckProps): boolean => {
	const { user, workspace } = props;
	return workspace?.owner_id === user.uuid;
};

export const isWorkspaceUpgradable = (
	workspace: WorkspaceBaseDashboard | WorkspaceBase,
): boolean => {
	if (!workspace?.plan?.id) return false;
	const isCancelledSelfService =
		isWorkspaceCancelled(workspace) &&
		!isCustomPlanWorkspace(workspace.plan.id);
	return (
		FREE_PLANS.map(p => p.toString()).includes(workspace.plan.id) ||
		isCancelledSelfService
	);
};
