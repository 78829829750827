import { CloseOrLogout, CloseOrLogoutProps } from './CloseOrLogout';
import { VeBox, VeLink, VeTypography } from '../_base';
import React from 'react';
import { SignupinLogo } from './SignupinLogo';

export interface VerifyEmailProps extends CloseOrLogoutProps {
	urlEmail: string;
	handleResendClick: () => void;
}

export function VerifyEmail(props: VerifyEmailProps) {
	const { urlEmail, handleResendClick, handleLogout } = props;
	return (
		<>
			<SignupinLogo />
			<VeBox mb={2}>
				<VeTypography align="center" variant="h4" component="h1" mb={2}>
					Verify your email
				</VeTypography>
				<VeTypography component="p" fontSize={15 / 16 + 'rem'}>
					Activate your Vectary account by clicking the link in the email we
					just sent to{' '}
					<VeTypography component="span" color="secondary">
						{urlEmail}
					</VeTypography>
					. This step helps in securing your account. Can't find the email in
					your inbox or spam folder?{' '}
					<VeLink onClick={handleResendClick}>Let's resend it.</VeLink>
				</VeTypography>
			</VeBox>
			<VeBox mb={2}>
				<VeTypography>
					You're all set! <CloseOrLogout {...{ handleLogout }} />
				</VeTypography>
			</VeBox>
		</>
	);
}
