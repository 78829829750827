import { Link, TypographyProps } from '@mui/material';
import React, { HTMLAttributeAnchorTarget, ReactNode } from 'react';

interface VeLinkProps {
	children: ReactNode;
	onClick?: () => void;
	href?: string;
	target?: HTMLAttributeAnchorTarget;
	style?: React.CSSProperties;
	color?: TypographyProps['color'];
	rel?: string;
	underline?: 'none' | 'hover' | 'always';
	disabled?: boolean;
}

export function VeLink({
	children,
	href,
	target,
	rel,
	style,
	color,
	underline = 'hover',
	disabled = false,
	onClick,
}: VeLinkProps) {
	return (
		<Link
			style={{ cursor: 'pointer', ...style }}
			onClick={onClick}
			underline={underline}
			href={href}
			target={target}
			rel={rel}
			color={color ?? 'primary'}
			aria-disabled={disabled}
		>
			{children}
		</Link>
	);
}
