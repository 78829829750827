import styled from 'styled-components';

export const Highlight = styled.span`
	color: ${({ theme }) => theme.colors.c_light_mode_primary};
`;

// export const Bold = styled.span`
// 	font-weight: ${({ theme }) => theme.fontWeights.font_weight_small};
// `;

export const Bold = styled.span`
	font-weight: 600;
`;
