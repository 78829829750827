import {
	MemberPermission as Current_Permission,
	PermissionEnum,
} from '@vctr-api/vctr-resources/sdk';

export { Current_Permission };

export enum Current_Permission_Owner {
	OWNER = 'owner',
}

export const InvitationPermission = Current_Permission;

export type InvitationPermission = Current_Permission;

export const Permission = {
	...Current_Permission,
	...Current_Permission_Owner,
};
export type Permission = Current_Permission | Current_Permission_Owner;

/** fixing "const enum" from generated Resources API typing */
// This is here as a special request by Aleks
export const PermissionEnumValues: Array<
	(typeof PermissionEnum)[keyof typeof PermissionEnum]
> = [
		PermissionEnum.edit,
		PermissionEnum.view,
		PermissionEnum.edit_2024,
		PermissionEnum.view_2024,
		PermissionEnum.collaborate_2024,
		PermissionEnum.manage_2024,
		PermissionEnum.owner,
	];
