import React, { useState } from 'react';
import { IconEnumName, DropdownEnumPositionY, DropdownEnumPositionX } from '..';
import { HelpDropdown, IconHelp } from './styledComponents';
import { DropdownItemProps, DropdownStyleVariant } from '../dropdown/Dropdown';

export type HelpDropdownContainerProps = {
	items: DropdownItemProps[];
};

export const HelpDropdownContainer = (props: HelpDropdownContainerProps) => {
	const { items } = props;
	const [showDropdown, setShowDropdown] = useState(false);

	return (
		<HelpDropdown
			menu={items}
			handleToggle={setShowDropdown}
			show={showDropdown}
			variant={DropdownStyleVariant.MENU}
			positionX={DropdownEnumPositionX.LEFT}
			positionY={DropdownEnumPositionY.BOTTOM}
			containerStyle={{ position: 'absolute', left: '8px', top: '-9px' }}
		>
			<IconHelp
				name={IconEnumName.TEMPHELP}
				onClick={() => setShowDropdown(true)}
			/>
		</HelpDropdown>
	);
};
