declare global {
    interface Window {
        OnetrustActiveGroups: string;
        OneTrust: {
            IsAlertBoxClosedAndValid: () => boolean;
        }
    }
}

export enum OneTrustCookiesGroup {
    StrictlyNecessary = "C0001",
    Performance = "C0002",
    Functional = "C0003",
    Targeting = "C0004",
    SocialMedia = "C0005",
    Marketing = "C0008"
};

export function onCookieConsentChanged(fn: (groups: OneTrustCookiesGroup[]) => void) {
    let oneTrustGroups: string = '';
    setInterval(() => {
        if (typeof window?.OnetrustActiveGroups !== 'string' || window?.OnetrustActiveGroups === oneTrustGroups || !fn) return;
        oneTrustGroups = window.OnetrustActiveGroups;

        const groups = oneTrustGroups.split(',').filter(x => !!x) as OneTrustCookiesGroup[];
        fn(groups);
    }, 200);
}

export function onCookieConsentClosedAndValid(fn: () => void) {
    let wasFired = false;
    const interval = setInterval(() => {
        if (window?.OneTrust?.IsAlertBoxClosedAndValid() && !wasFired) {
            wasFired = true;
            fn();
            clearInterval(interval);
        }
    }, 200);
}