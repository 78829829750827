import React, { FC } from 'react';
import { Alert, Button, Stack } from '@mui/material';
import { Info } from '@mui/icons-material';

export const MembersSettingsContactUsAlert: FC = () => (
	<Alert
		color="warning-grey"
		variant="filled"
		icon={<Info style={{ fontSize: '16px' }} />}
		action={
			<Stack className="btn-group" direction="row">
				<Button
					href="https://help.vectary.com/roles"
					target="_blank"
					size="medium"
					variant="contained-dark"
					color="primary"
				>
					Learn more
				</Button>
			</Stack>
		}
	>
		Learn more about adding members and role permissions in our documentation.
	</Alert>
);
