import React from 'react';
import {
	Logo,
	GetProTitleBlock as TitleBlockC,
	GetProTitle,
	GetProHighlight,
	TextList,
	ListItem,
	GetProBadge,
	GetProButtonBlack,
	ListItemMuted,
} from './styledComponents';
import { Muted } from '..';

export function SubtitleTrial() {
	return (
		<>
			Try our most powerful features,{' '}
			<GetProHighlight>free for 14 days</GetProHighlight>.
		</>
	);
}
export function ProFeatures() {
	return (
		<TextList>
			<ListItem>
				Design, Animate, Interact
				<Muted> - Full Studio</Muted>
			</ListItem>

			<ListItem>
				Import, Export <Muted>and</Muted> Share <Muted>projects</Muted>
			</ListItem>
			<ListItem>
				Augmented Reality<Muted> - USDZ, GLTF, GLB, QR Code</Muted>
			</ListItem>
			<ListItem>
				More space<Muted> - up to 100 projects</Muted>
			</ListItem>
			<ListItem>
				Unbranded viewer<Muted> - with 100k monthly views</Muted>
			</ListItem>
			<ListItem>
				Version history<Muted> - up to 90 days</Muted>
			</ListItem>
			<ListItem>
				Materials, 3D Assets and HDRis<Muted> - Free CC0</Muted>
			</ListItem>
		</TextList>
	);
}

export function EducationFeatures() {
	return (
		<>
			<TextList>
				<ListItem>
					Design, Animate, Interact
					<Muted> - Full Studio</Muted>
				</ListItem>

				<ListItem>Limited Studio - Design mode only</ListItem>
				<ListItem>Import, Export and Share projects</ListItem>
			</TextList>
			<TextList>
				<u>Not included in this plan:</u>
				<ListItemMuted>
					- Augmented Reality - USDZ, GLTF, GLB, QR Code
				</ListItemMuted>
				<ListItemMuted>- Animations, Interactions</ListItemMuted>
				<ListItemMuted>- Unbranded viewer</ListItemMuted>
				<ListItemMuted>- Version history</ListItemMuted>
			</TextList>
		</>
	);
}

export function TitleBlockComp({ title }: { title?: string }) {
	const titleText = title || 'Get Vectary Pro';
	return (
		<TitleBlockC>
			<Logo />
			<GetProTitle>{titleText}</GetProTitle>
		</TitleBlockC>
	);
}

export const StudentOfferButtonBlock = ({
	handleClick,
}: {
	handleClick: () => void;
}) => (
	<>
		<GetProBadge>LIMITED TIME OFFER — SAVE UP TO $138</GetProBadge>
		<GetProButtonBlack onClick={handleClick}>Upgrade now</GetProButtonBlack>
	</>
);
