import { initDocumentationTracking, logPageView } from './documentation';
import { start } from '@vctr-libs-public/webflow-scripts';

const isDocumentation = window.location.hostname === 'help.vectary.com';

if (!isDocumentation) start();

if (isDocumentation) {
	initDocumentationTracking();
	logPageView();
}
