import React from 'react';
import {
	WorkspaceBaseDashboard,
	isLockedWorkspace,
} from '@vctr-libs/vctr-api-client';

export const BillingCancellationInfo: React.FC<{
	currentWorkspace: WorkspaceBaseDashboard;
}> = ({ currentWorkspace }) =>
	currentWorkspace?.plan?.cancellationEffectiveDate && (
		<>
			{`This workspace subscription has been cancelled`}
			{!isLockedWorkspace(currentWorkspace?.plan?.id) && (
				<>
					{` and will expire on ${currentWorkspace?.plan.cancellationEffectiveDate}.`}
				</>
			)}
		</>
	);
