import React, { useState } from 'react';
import {
	DropdownItemIcon,
	DropdownItemWithIcons,
	DropdownItemWithIconsTitle,
	ItemChildrenMenu,
} from './styledComponents';
import { DropdownItemProps, Dropdown, DropdownStyleVariant } from '../Dropdown';
import {
	DropdownEnumPositionX,
	DropdownEnumPositionY,
} from '../utils/position';
import { useOnClickOutside } from '../../../hooks';

export type ItemWithChildrenProps = {
	color?: string;
	onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
	onMouseDown?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
	// onHover?: () => void;
	title: string;
	childrenMenu: DropdownItemProps[];
	childrenMenuPositionX: DropdownEnumPositionX;
	childrenMenuPositionY: DropdownEnumPositionY;
	iconSrc?: string;
	variant?: DropdownStyleVariant;
	disabled?: boolean;
};

export const ItemWithChildren = (props: ItemWithChildrenProps) => {
	const {
		color,
		onClick,
		onMouseDown,
		// onHover,
		title,
		childrenMenu,
		childrenMenuPositionX,
		childrenMenuPositionY,
		iconSrc,
		variant,
		disabled,
	} = props;

	const [showChildrenMenu, setShowChildrenMenu] = useState(false);
	const itemRef = React.useRef<HTMLLIElement>(null);

	useOnClickOutside(itemRef, () => setShowChildrenMenu(false));

	return (
		<DropdownItemWithIcons
			ref={itemRef}
			disabled={disabled}
			color={color}
			className="dropdown__Item"
			onClick={event => {
				if (onClick) {
					onClick(event);
				}
				setShowChildrenMenu(!showChildrenMenu);
			}}
			onMouseOver={() => {
				setShowChildrenMenu(true);
				// onHover();
			}}
			// onMouseLeave={() => setTimeout(() => {
			// 	setShowChildrenMenu(false);
			// }, 0)}
			onMouseDown={onMouseDown}
		>
			{iconSrc && <DropdownItemIcon src={iconSrc} />}
			<DropdownItemWithIconsTitle>{title}</DropdownItemWithIconsTitle>
			<DropdownItemIcon src="./images/icon-triangle-right.svg" />
			<ItemChildrenMenu
				positionX={childrenMenuPositionX}
				positionY={childrenMenuPositionY}
			>
				<Dropdown
					menu={childrenMenu}
					show={showChildrenMenu}
					positionX={childrenMenuPositionX}
					positionY={childrenMenuPositionY}
					variant={variant}
				>
					&nbsp;
				</Dropdown>
			</ItemChildrenMenu>
		</DropdownItemWithIcons>
	);
};
