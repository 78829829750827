import fetch from 'cross-fetch';
// @ts-ignore: @types/extract-files are incorrect
import extractFiles from 'extract-files/extractFiles.mjs';
import stringifyObject from 'stringify-object';
import { Thunder, ZeusScalars, } from '../generated/zeus/index';
export * from '../generated/zeus/index';
export { hasDefaultPermission, PermissionType, rolePermissions, } from '../src/server/utils/permissions';
export { Permission, MemberPermission, EmailInvitationStatusColumn, } from '../src/server/utils/constants/enums';
const scalars = ZeusScalars({
    NonNegativeInt: {
        decode: (e) => e,
    },
    DateTime: {
        decode: (e) => new Date(e),
    },
    UUID: {
        decode: (e) => e,
    },
    JSONObject: {
        encode: (e) => stringifyObject(e, { indent: '', singleQuotes: false }),
        decode: (e) => e,
    },
    NonNegativeFloat: {
        decode: (e) => e,
    },
    Currency: {
        decode: (e) => e,
    },
    Duration: {
        decode: (e) => e,
    },
    URL: {
        decode: (e) => e,
    },
    SemVer: {
        decode: (e) => e,
    },
    SHA1HashOrUpload: {
        decode: (e) => e,
    },
});
const valueIsBuffer = (value) => {
    return value instanceof Buffer;
};
export class ErrorWithExtras extends Error {
    constructor(message, extras) {
        super(message);
        this.extras = extras || {};
    }
}
export class ServerErrorWithExtras extends ErrorWithExtras {
    constructor(message, statusCode, extras) {
        super(message);
        this.statusCode = statusCode;
        this.extras = extras || {};
    }
}
const handleFetchResponse = async (response) => {
    const text = await response.text();
    if (!response.ok) {
        try {
            const json = JSON.parse(text);
            throw new ServerErrorWithExtras(json.message, response.status, Object.assign({}, json.details));
        }
        catch (err) {
            throw new ServerErrorWithExtras(text, response.status);
        }
    }
    try {
        const json = JSON.parse(text);
        // TODO: actually check the shape of the response
        return json;
    }
    catch (err) {
        throw new ErrorWithExtras('Could not parse JSON response', {
            response: text,
        });
    }
};
const apiFetch = (options, operation) => (query, variables = {}) => {
    const fetchOptions = options[1] || {};
    const { clone, files, } = extractFiles(variables, valueIsBuffer, 'variables');
    if (files.size) {
        const formData = new FormData();
        const map = {};
        let i = 0;
        let preQuery = '(';
        files.forEach(paths => {
            paths.forEach(path => {
                map[i++] = [path];
                preQuery = `${preQuery}$${path.replace('variables.', '')}: SHA1HashOrUpload!, `;
            });
        });
        preQuery = preQuery.replace(/, $/, ')');
        const operations = JSON.stringify({ query, variables: clone })
            .replace('mutation  {', `mutation ${preQuery} {`)
            .replace(/\\\"\$varObject[0-9]+\\\"/g, substring => substring.replace(/\\"/g, ''));
        formData.append('operations', operations);
        formData.append('map', JSON.stringify(map));
        i = 0;
        files.forEach((paths, file) => {
            paths.forEach(path => {
                formData.append(`${i++}`, new File([file], path), path);
            });
        });
        return postToResources(options[0].toString(), formData, fetchOptions, operation);
    }
    else {
        fetchOptions.headers = Object.assign({ 'Content-Type': 'application/json' }, fetchOptions.headers);
        return postToResources(options[0].toString(), JSON.stringify({ query, variables }), fetchOptions, operation);
    }
};
async function postToResources(url, body, fetchOptions, operation) {
    return fetch(`${url}?o=${operation}`, Object.assign({ body, method: 'POST' }, fetchOptions))
        .then(handleFetchResponse)
        .then((response) => {
        return response.data;
    });
}
export const SDK = (...options) => {
    return (operation) => {
        return Thunder(apiFetch(options, operation))(operation, {
            scalars,
        });
    };
};
let isRelativeUrlWorking = null;
/**
 * Returns the URL for the Resources API.
 *
 * @param cloud
 * @returns
 */
const getResourcesApiUrl = async (cloud) => {
    var _a, _b, _c;
    let url;
    if (cloud !== undefined) {
        url = createCloudUrl(cloud);
        if (url.indexOf('amazonaws.com') >= 0) {
            // It is an internal URL
            url += '/graphql'; // /api/vctr-resources/v1 is only required for the Gateway
        }
        else {
            url += '/api/vctr-resources/v1/graphql';
        }
    }
    else {
        url = '/api/vctr-resources/v1/graphql';
        if (typeof window !== 'undefined' &&
            ((window && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) === 'localhost') ||
                ((_c = (_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.hostname) === null || _c === void 0 ? void 0 : _c.indexOf('vectary.com')) !== -1)) {
            // We don't need to do anything here; just skip the further else block.
        }
        else {
            if (isRelativeUrlWorking === null) {
                // First we need to check if relative URL is working.
                try {
                    const result = await fetch(url, {
                        headers: {
                            'content-type': 'application/json',
                        },
                        body: '{"query":"{\\n  user {\\n    id\\n  }\\n}"}',
                        method: 'POST',
                        mode: 'cors',
                        credentials: 'include',
                    });
                    if (result.status === 404) {
                        throw new Error();
                    }
                    isRelativeUrlWorking = true;
                }
                catch (error) {
                    isRelativeUrlWorking = false;
                }
            }
            // Don't append /api/vctr-resources/v1 to foreign URLs, use vectary prod URL instead.
            url = `${isRelativeUrlWorking ? '' : createCloudUrl(Env.Prod)}/api/vctr-resources/v1/graphql`;
        }
    }
    return url.replace(/:7\d\d\d/g, ':7200');
};
var Env;
(function (Env) {
    Env["Test"] = "test";
    Env["Staging"] = "staging";
    Env["Alpha"] = "alpha";
    Env["Beta"] = "beta";
    Env["Delta"] = "delta";
    Env["Gamma"] = "gamma";
    Env["Render"] = "render";
    Env["Animations"] = "animations";
    Env["WebXR"] = "webxr";
    Env["Preprod"] = "preprod";
    Env["ComW"] = "comw";
    Env["Prod"] = "www";
    Env["PrfmTest"] = "prfm-test";
    Env["Manuel"] = "manuel";
})(Env || (Env = {}));
const createCloudUrl = (cloud) => {
    if (cloud.indexOf('http://localhost:') >= 0)
        return '';
    return Object.values(Env).includes(cloud)
        ? `https://${cloud}.vectary.com`
        : `${cloud}`;
};
export class ResourcesSDK {
    constructor(cloud, extraHeaders, forceSync) {
        this.cloud = cloud;
        this.extraHeaders = extraHeaders;
        this.forceSync = forceSync;
    }
    async initialize() {
        if (this._sdk) {
            return;
        }
        const headers = {};
        const url = await getResourcesApiUrl(this.cloud);
        if (this.forceSync === true) {
            headers['x-request-force-sync'] = true;
        }
        this._sdk = SDK(url, { headers: Object.assign(Object.assign({}, headers), this.extraHeaders) });
    }
    async query() {
        await this.initialize();
        return this._sdk('query');
    }
    async mutation() {
        await this.initialize();
        return () => this._sdk('mutation');
    }
    async subscription() {
        await this.initialize();
        return () => this._sdk('subscription');
    }
}
