import { PlanIds } from './plans';

export type Plan = {
	id: PlanIds;
	name: string;
	price: number;
	billing: BillingPeriod;
	product_list: Product[];
	default?: boolean;
	total?: number;
	paddleId?: string;
	internal_note?: string;
	// These are filled from webhooks for each user
	baseSubId?: string;
	linkedSubId?: string;
	startBillDate?: string;
	nextBillDate?: string;
	cancellationEffectiveDate?: string;
};

export type CustomPlan = {
	id?: PlanIds;
	name?: string;
	price?: number;
	product_list?: Product[];
	total?: number;
	discount?: string;
	internal_note?: string;
	baseSubId?: string; // Present for linked workspaces
};

export type Product = {
	id: ProductIds;
	name?: string;
	soft_limit?: number;
	hard_limit?: number;
	price_per_unit?: number;
	formats?: ExportFormats[];
};

export enum BillingPeriod {
	MONTH = 'month',
	YEAR = 'year',
}

export enum ProductIds {
	VIEW = 'view',
	SEAT_LEGACY_VIEW = 'seat_legacy_view',
	SEAT_VIEW = 'seat_view',
	SEAT_EDIT = 'seat_edit',
	TEAM = 'team',
	PROJECT = 'project',
	EXPORT = 'export',
	HIDDEN_WATERMAK = 'hidden_watermark',
	// per new permissions as of Q2 2024
	SEAT_MANAGE_2024 = 'seat_manage_2024',
	SEAT_COLLABORATE_2024 = 'seat_collaborate_2024',
	SEAT_VIEW_2024 = 'seat_view_2024',
	SEAT_EDIT_2024 = 'seat_edit_2024',
}

export enum ExportFormats {
	OBJ = 'OBJ',
	GLTF = 'glTF',
	GLB = 'glB',
	DAE = 'DAE',
	USDZ = 'USDZ',
	FBX = 'FBX',
	STL = 'STL',
}

export enum PaymentProviders {
	PADDLE = 'Paddle',
	INVOICE = 'Invoice',
}

export enum PaddleVendorIds {
	PROD = 31267,
	STAGE = 49625,
	SANDBOX = 6728,
}
