// @ts-nocheck
/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';


export const HOST=`${process.env.VCTR_RESOURCES}/graphql`


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json() as Promise<GraphQLResponse>;
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const);
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v;
      return a;
    }, {});
    return objectFromEntries;
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : never, SCLR>
        : never;
    }[keyof SRC] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	NonNegativeInt?: ScalarResolver;
	DateTime?: ScalarResolver;
	UUID?: ScalarResolver;
	JSONObject?: ScalarResolver;
	NonNegativeFloat?: ScalarResolver;
	Currency?: ScalarResolver;
	Duration?: ScalarResolver;
	URL?: ScalarResolver;
	PositiveInt?: ScalarResolver;
	SemVer?: ScalarResolver;
	BigInt?: ScalarResolver;
	SHA1HashOrUpload?: ScalarResolver;
}
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["Query"]: AliasType<{
permissionsUser?: [{	id: ValueTypes["NonNegativeInt"] | Variable<any, string>},ValueTypes["PermissionsUserNode"]],
allPermissionsUsers?: [{	id?: Array<ValueTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	role?: Array<ValueTypes["UserRoleFilter"] | undefined | null> | undefined | null | Variable<any, string>,	workspaceLimit?: Array<ValueTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null | Variable<any, string>,	internalNote?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["PermissionsUserNodePaginated"]],
permissionsWorkspace?: [{	id: ValueTypes["NonNegativeInt"] | Variable<any, string>},ValueTypes["PermissionsWorkspaceNode"]],
allPermissionsWorkspaces?: [{	id?: Array<ValueTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	internalNote?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["PermissionsWorkspaceNodePaginated"]],
user?: [{	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},ValueTypes["UserNode"]],
allUsers?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	username?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	biography?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permissionsUserId?: Array<ValueTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null | Variable<any, string>,	trialUsed?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	proUsed?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["UserNodePaginated"]],
userByEmailOrHash?: [{	emailOrHash?: string | undefined | null | Variable<any, string>},ValueTypes["UserEmailDetailsNode"]],
workspace?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["WorkspaceNode"]],
allWorkspaces?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	ownerId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permissionsWorkspaceId?: Array<ValueTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["WorkspaceNodePaginated"]],
invitation?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["InvitationNode"]],
allInvitations?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permission?: Array<ValueTypes["MemberPermissionFilter"] | undefined | null> | undefined | null | Variable<any, string>,	workspaceId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["InvitationNodePaginated"]],
emailInvitation?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["EmailInvitationNode"]],
allEmailInvitations?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	email?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permission?: Array<ValueTypes["MemberPermissionFilter"] | undefined | null> | undefined | null | Variable<any, string>,	status?: Array<ValueTypes["EmailInvitationStatusFilter"] | undefined | null> | undefined | null | Variable<any, string>,	workspaceId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["EmailInvitationNodePaginated"]],
folder?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["FolderNode"]],
allFoldersPaginated?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	isHomeFolder?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	workspaceId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["FolderNodePaginated"]],
allFolders?: [{	filters?: ValueTypes["FolderFiltersArgsWithLists"] | undefined | null | Variable<any, string>,	scope?: ValueTypes["QueryScopeEnum"] | undefined | null | Variable<any, string>},ValueTypes["FolderNodeBase"]],
	allCustomDomains?:ValueTypes["CustomDomainNode"],
project?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	// @ts-nocheck
/** Specific point in time. If provided, the returned resources will be the version of them at that moment. */
	version?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>},ValueTypes["ProjectNode"]],
allProjectsPaginated?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	description?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	view?: boolean | undefined | null | Variable<any, string>,	studioVersion?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	folderId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	readonly?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	deleted?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	updatedAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	sort?: ValueTypes["ProjectSort"] | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["ProjectNodePaginated"]],
allProjects?: [{	filters?: ValueTypes["ProjectFiltersArgsWithLists"] | undefined | null | Variable<any, string>,	scope?: ValueTypes["QueryScopeEnum"] | undefined | null | Variable<any, string>},ValueTypes["ProjectNode"]],
allProjectsCount?: [{	filters?: ValueTypes["ProjectFiltersArgsWithLists"] | undefined | null | Variable<any, string>,	scope?: ValueTypes["QueryScopeEnum"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
projectState?: [{	id: string | Variable<any, string>,	projectId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["ProjectStateNode"]],
nsProject?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["NSProjectNode"]],
allNSProjects?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	description?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	updatedAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	sort?: ValueTypes["NSProjectSort"] | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["NSProjectNodePaginated"]],
sharedProject?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["SharedProjectNode"]],
node?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	// @ts-nocheck
/** Specific point in time. If provided, the returned resources will be the version of at that moment. */
	version?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>},ValueTypes["NodeNode"]],
allNodesPaginated?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	description?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	category?: Array<ValueTypes["NodeCategoryFilter"] | undefined | null> | undefined | null | Variable<any, string>,	projectId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	libraryItem?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	folder?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	workspace?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	deleted?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["NodeNodePaginated"]],
allNodes?: [{	filters?: ValueTypes["NodeFiltersArgsWithLists"] | undefined | null | Variable<any, string>,	scope?: ValueTypes["QueryScopeEnum"] | undefined | null | Variable<any, string>},ValueTypes["NodeNodeBase"]],
allNodesCount?: [{	filters?: ValueTypes["NodeFiltersArgsWithLists"] | undefined | null | Variable<any, string>,	scope?: ValueTypes["QueryScopeEnum"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
token?: [{	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},ValueTypes["TokenNode"]],
	allTokens?:ValueTypes["TokenNode"],
emails?: [{	subscribed?: boolean | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["UserEmailDetailsNodePaginated"]],
rolePermission?: [{	id?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["RolePermissionNode"]],
	allRolePermissions?:ValueTypes["RolePermissionNode"],
memberRole?: [{	id?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["MemberRoleType"]],
	allMemberRoles?:ValueTypes["MemberRoleType"],
workspaceRolePermission?: [{	workspaceId: ValueTypes["UUID"] | Variable<any, string>,	memberRoleId: number | Variable<any, string>,	rolePermissionId: number | Variable<any, string>},ValueTypes["WorkspaceRolePermissionType"]],
workspacePermissions?: [{	id: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a user resource. */
["PermissionsUserNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	workspaceLimit?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
users?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	username?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	biography?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permissionsUserId?: Array<ValueTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null | Variable<any, string>,	trialUsed?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	proUsed?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["UserNodePaginated"]],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Integers that will have a value of 0 or more. */
["NonNegativeInt"]:unknown;
	["UserRoleEnum"]:UserRoleEnum;
	// @ts-nocheck
/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]:unknown;
	["UserNodePaginated"]: AliasType<{
	items?:ValueTypes["UserNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A user resource of the Vectary platform. Contains the basic information of an account and it is directly related with certain collections of details of the account and the workspaces related to it */
["UserNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of a user. It is a UUIDv4 but at some point it will start accepting any string. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Nickname chosen by the user. */
	username?:boolean | `@${string}`,
	// @ts-nocheck
/** Firstname of the user. */
	firstName?:boolean | `@${string}`,
	// @ts-nocheck
/** Lastname the user. */
	lastName?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the user written by them. */
	biography?:boolean | `@${string}`,
	// @ts-nocheck
/** The location of the picture used for the profile of the user. */
	picture?:boolean | `@${string}`,
	// @ts-nocheck
/** Metadata we have collected from the user via questionnaires. */
	questionnaire?:boolean | `@${string}`,
	// @ts-nocheck
/** Version of the General Data Protection Regulation accepted by the user. */
	gdpr?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the General Data Protection Regulation accepted by the user is the current one or not. */
	isGdprCurrent?:boolean | `@${string}`,
	// @ts-nocheck
/** Version of the Terms Of Use accepted by the user. */
	tou?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the Terms Of Use accepted by the user is the current one or not. */
	isTouCurrent?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the user has used the one time trial. */
	trialUsed?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the user has ever paid for a PRO plan. */
	proUsed?:boolean | `@${string}`,
	// @ts-nocheck
/** Internal notes for a given user. Available only for Administrators. */
	internalNotes?:boolean | `@${string}`,
	// @ts-nocheck
/** Time of creation of the user. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Email configuration for the user. */
	emailDetails?:ValueTypes["UserEmailDetailsNode"],
	// @ts-nocheck
/** Location information about the user. */
	locationDetails?:ValueTypes["UserLocationDetailsNode"],
	// @ts-nocheck
/** Access information about the user. Available only for Administrators. */
	accessDetails?:ValueTypes["UserAccessDetailsNode"],
	// @ts-nocheck
/** The set of permissions assigned to the user. */
	permissions?:ValueTypes["PermissionsUserNode"],
workspaces?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	ownerId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permissionsWorkspaceId?: Array<ValueTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["UserWorkspaceNodePaginated"]],
	// @ts-nocheck
/** Tokens of external accounts of the user. And app tokens for authorizing request to Vectary. */
	tokens?:ValueTypes["TokenNode"],
	// @ts-nocheck
/** Tells if the given user is a CIAM user. */
	isCiamUser?:boolean | `@${string}`,
	// @ts-nocheck
/** Email invitations assigned to the user. */
	emailInvitations?:ValueTypes["EmailInvitationNode"],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
["UUID"]:unknown;
	// @ts-nocheck
/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSONObject"]:unknown;
	// @ts-nocheck
/** Floats that will have a value of 0 or more. */
["NonNegativeFloat"]:unknown;
	// @ts-nocheck
/** Resource with information about a user's email. */
["UserEmailDetailsNode"]: AliasType<{
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	newsletter?:boolean | `@${string}`,
	notifyComments?:boolean | `@${string}`,
	undeliverable?:boolean | `@${string}`,
	verificationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Resource with information about a user's location. */
["UserLocationDetailsNode"]: AliasType<{
	location?:boolean | `@${string}`,
	geo?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Resource with information about a user's access detail. */
["UserAccessDetailsNode"]: AliasType<{
	accessToken?:boolean | `@${string}`,
	accessTokenExpiration?:boolean | `@${string}`,
	refreshToken?:boolean | `@${string}`,
	refreshTokenExpiration?:boolean | `@${string}`,
	passwordIdentifier?:boolean | `@${string}`,
	passwordResetToken?:boolean | `@${string}`,
	facebookIdentifier?:boolean | `@${string}`,
	googleIdentifier?:boolean | `@${string}`,
	ciamIdentifier?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserWorkspaceNodePaginated"]: AliasType<{
	items?:ValueTypes["UserWorkspaceNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A workspace resource of the Vectary platform.
Includes extra properties about the relation with the user. */
["UserWorkspaceNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	// @ts-nocheck
/** Collection of admin-defined properties to override some kind of behaviour. */
	overwrite?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	owner?:ValueTypes["UserNode"],
	customDomain?:boolean | `@${string}`,
	workspaceRolePermissions?:boolean | `@${string}`,
	permissions?:ValueTypes["PermissionsWorkspaceNode"],
	paddleDetails?:ValueTypes["PaddleDetailsNode"],
folders?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	isHomeFolder?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	workspaceId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["WorkspaceFolderNodePaginated"]],
	members?:ValueTypes["WorkspaceMemberNode"],
invitations?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	workspaceId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permission?: Array<ValueTypes["MemberPermissionFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["InvitationNodePaginated"]],
emailInvitations?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	workspaceId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permission?: Array<ValueTypes["MemberPermissionFilter"] | undefined | null> | undefined | null | Variable<any, string>,	status?: Array<ValueTypes["EmailInvitationStatusFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["EmailInvitationNodePaginated"]],
	permission?:boolean | `@${string}`,
	ordering?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a workspace resource. */
["PermissionsWorkspaceNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	properties?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
workspaces?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	ownerId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permissionsWorkspaceId?: Array<ValueTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["WorkspaceNodePaginated"]],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceNodePaginated"]: AliasType<{
	items?:ValueTypes["WorkspaceNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A workspace resource of the Vectary platform. */
["WorkspaceNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	// @ts-nocheck
/** Collection of admin-defined properties to override some kind of behaviour. */
	overwrite?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	owner?:ValueTypes["UserNode"],
	customDomain?:boolean | `@${string}`,
	workspaceRolePermissions?:boolean | `@${string}`,
	permissions?:ValueTypes["PermissionsWorkspaceNode"],
	paddleDetails?:ValueTypes["PaddleDetailsNode"],
folders?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	isHomeFolder?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	workspaceId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["WorkspaceFolderNodePaginated"]],
	members?:ValueTypes["WorkspaceMemberNode"],
invitations?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	workspaceId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permission?: Array<ValueTypes["MemberPermissionFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["InvitationNodePaginated"]],
emailInvitations?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	workspaceId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permission?: Array<ValueTypes["MemberPermissionFilter"] | undefined | null> | undefined | null | Variable<any, string>,	status?: Array<ValueTypes["EmailInvitationStatusFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["EmailInvitationNodePaginated"]],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a workspace resource. */
["PaddleDetailsNode"]: AliasType<{
	customerId?:boolean | `@${string}`,
	subscriptionId?:boolean | `@${string}`,
	linkedSubscriptionId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
	currency?:boolean | `@${string}`,
	recurringInterval?:boolean | `@${string}`,
	nextPaymentAmount?:boolean | `@${string}`,
	nextPaymentDate?:boolean | `@${string}`,
	cancellationEffectiveDate?:boolean | `@${string}`,
	cancelUrl?:boolean | `@${string}`,
	updateUrl?:boolean | `@${string}`,
	receipts?:boolean | `@${string}`,
	latestEventTime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
["Currency"]:unknown;
	// @ts-nocheck
/** 
    A string representing a duration conforming to the ISO8601 standard,
    such as: P1W1DT13H23M34S
    P is the duration designator (for period) placed at the start of the duration representation.
    Y is the year designator that follows the value for the number of years.
    M is the month designator that follows the value for the number of months.
    W is the week designator that follows the value for the number of weeks.
    D is the day designator that follows the value for the number of days.
    T is the time designator that precedes the time components of the representation.
    H is the hour designator that follows the value for the number of hours.
    M is the minute designator that follows the value for the number of minutes.
    S is the second designator that follows the value for the number of seconds.

    Note the time designator, T, that precedes the time value.

    Matches moment.js, Luxon and DateFns implementations
    ,/. is valid for decimal places and +/- is a valid prefix
   */
["Duration"]:unknown;
	// @ts-nocheck
/** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
["URL"]:unknown;
	["WorkspaceFolderNodePaginated"]: AliasType<{
	items?:ValueTypes["WorkspaceFolderNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A folder resource of the Vectary platform.
Includes extra properties about the relation with the workspace. */
["WorkspaceFolderNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	isHomeFolder?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	workspace?:ValueTypes["WorkspaceNode"],
projects?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	description?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	view?: boolean | undefined | null | Variable<any, string>,	studioVersion?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	folderId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	readonly?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	deleted?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	updatedAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	sort?: ValueTypes["ProjectSort"] | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["FolderProjectNodePaginated"]],
nsProjects?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	description?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	updatedAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	sort?: ValueTypes["NSProjectSort"] | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["FolderNSProjectNodePaginated"]],
	ordering?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FolderProjectNodePaginated"]: AliasType<{
	items?:ValueTypes["FolderProjectNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A project resource of the Vectary platform.
Includes extra properties about the relation with the folder. */
["FolderProjectNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the project written by a user. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	files?:ValueTypes["File"],
	keywords?:boolean | `@${string}`,
	scene?:boolean | `@${string}`,
	view?:boolean | `@${string}`,
	credits?:boolean | `@${string}`,
	published?:boolean | `@${string}`,
	imports?:boolean | `@${string}`,
	settings?:boolean | `@${string}`,
	studioVersion?:boolean | `@${string}`,
	forkedFromId?:boolean | `@${string}`,
	readonly?:boolean | `@${string}`,
	deleted?:boolean | `@${string}`,
	folderId?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?:boolean | `@${string}`,
	folder?:ValueTypes["FolderNode"],
nodes?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	description?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	category?: Array<ValueTypes["NodeCategoryFilter"] | undefined | null> | undefined | null | Variable<any, string>,	projectId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	libraryItem?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	folder?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	workspace?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	deleted?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["NodeNodePaginated"]],
	history?:boolean | `@${string}`,
	ordering?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Uploaded file meta data. */
["File"]: AliasType<{
	purpose?:boolean | `@${string}`,
	urls?:boolean | `@${string}`,
	sizes?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Integers that will have a value greater than 0. */
["PositiveInt"]:unknown;
	// @ts-nocheck
/** A field whose value is a Semantic Version: https://semver.org */
["SemVer"]:unknown;
	// @ts-nocheck
/** A folder resource of the Vectary platform. */
["FolderNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	isHomeFolder?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	workspace?:ValueTypes["WorkspaceNode"],
projects?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	description?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	view?: boolean | undefined | null | Variable<any, string>,	studioVersion?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	folderId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	readonly?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	deleted?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	updatedAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	sort?: ValueTypes["ProjectSort"] | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["FolderProjectNodePaginated"]],
nsProjects?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	description?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	updatedAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	sort?: ValueTypes["NSProjectSort"] | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["FolderNSProjectNodePaginated"]],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["StringFilter"]: {
	filter: ValueTypes["TextFilterEnum"] | Variable<any, string>,
	not?: boolean | undefined | null | Variable<any, string>,
	value: string | Variable<any, string>
};
	["TextFilterEnum"]:TextFilterEnum;
	// @ts-nocheck
/** [server_value] is [filter?] [value] */
["BooleanFilter"]: {
	filter: ValueTypes["BooleanFilterEnum"] | Variable<any, string>,
	value: boolean | Variable<any, string>
};
	["BooleanFilterEnum"]:BooleanFilterEnum;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["DateTimeFilter"]: {
	filter: ValueTypes["SortableFilterEnum"] | Variable<any, string>,
	not?: boolean | undefined | null | Variable<any, string>,
	value: ValueTypes["DateTime"] | Variable<any, string>
};
	["SortableFilterEnum"]:SortableFilterEnum;
	["ProjectSort"]:ProjectSort;
	["FolderNSProjectNodePaginated"]: AliasType<{
	items?:ValueTypes["FolderNSProjectNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A nsProject resource of the Vectary platform.
Includes extra properties about the relation with the folder. */
["FolderNSProjectNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the nsProject written by a user. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	keywords?:boolean | `@${string}`,
	binaries?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?:boolean | `@${string}`,
	folder?:ValueTypes["FolderNode"],
	ordering?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
["BigInt"]:unknown;
	["NSProjectSort"]:NSProjectSort;
	["NodeNodePaginated"]: AliasType<{
	items?:ValueTypes["NodeNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A node resource of the Vectary platform. */
["NodeNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?:boolean | `@${string}`,
	category?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
	binaries?:boolean | `@${string}`,
	files?:boolean | `@${string}`,
	libraryItem?:boolean | `@${string}`,
	forkedFromId?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
	project?:ValueTypes["ProjectNode"],
	history?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NodeCategoryEnum"]:NodeCategoryEnum;
	// @ts-nocheck
/** A project resource of the Vectary platform. */
["ProjectNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the project written by a user. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	files?:ValueTypes["File"],
	keywords?:boolean | `@${string}`,
	scene?:boolean | `@${string}`,
	view?:boolean | `@${string}`,
	credits?:boolean | `@${string}`,
	published?:boolean | `@${string}`,
	imports?:boolean | `@${string}`,
	settings?:boolean | `@${string}`,
	studioVersion?:boolean | `@${string}`,
	forkedFromId?:boolean | `@${string}`,
	readonly?:boolean | `@${string}`,
	deleted?:boolean | `@${string}`,
	folderId?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?:boolean | `@${string}`,
	folder?:ValueTypes["FolderNode"],
nodes?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	description?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	category?: Array<ValueTypes["NodeCategoryFilter"] | undefined | null> | undefined | null | Variable<any, string>,	projectId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	libraryItem?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	folder?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	workspace?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	deleted?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["NodeNodePaginated"]],
	history?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["NodeCategoryFilter"]: {
	filter: ValueTypes["TextFilterEnum"] | Variable<any, string>,
	not?: boolean | undefined | null | Variable<any, string>,
	value: ValueTypes["NodeCategoryEnum"] | Variable<any, string>
};
	// @ts-nocheck
/** A user resource of the Vectary platform. Contains the basic information of an account and it is directly related with certain collections of details of the account and the workspaces related to it
Includes extra properties about the relation with the workspace. */
["WorkspaceMemberNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of a user. It is a UUIDv4 but at some point it will start accepting any string. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Nickname chosen by the user. */
	username?:boolean | `@${string}`,
	// @ts-nocheck
/** Firstname of the user. */
	firstName?:boolean | `@${string}`,
	// @ts-nocheck
/** Lastname the user. */
	lastName?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the user written by them. */
	biography?:boolean | `@${string}`,
	// @ts-nocheck
/** The location of the picture used for the profile of the user. */
	picture?:boolean | `@${string}`,
	// @ts-nocheck
/** Metadata we have collected from the user via questionnaires. */
	questionnaire?:boolean | `@${string}`,
	// @ts-nocheck
/** Version of the General Data Protection Regulation accepted by the user. */
	gdpr?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the General Data Protection Regulation accepted by the user is the current one or not. */
	isGdprCurrent?:boolean | `@${string}`,
	// @ts-nocheck
/** Version of the Terms Of Use accepted by the user. */
	tou?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the Terms Of Use accepted by the user is the current one or not. */
	isTouCurrent?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the user has used the one time trial. */
	trialUsed?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the user has ever paid for a PRO plan. */
	proUsed?:boolean | `@${string}`,
	// @ts-nocheck
/** Internal notes for a given user. Available only for Administrators. */
	internalNotes?:boolean | `@${string}`,
	// @ts-nocheck
/** Time of creation of the user. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Email configuration for the user. */
	emailDetails?:ValueTypes["UserEmailDetailsNode"],
	// @ts-nocheck
/** Location information about the user. */
	locationDetails?:ValueTypes["UserLocationDetailsNode"],
	// @ts-nocheck
/** Access information about the user. Available only for Administrators. */
	accessDetails?:ValueTypes["UserAccessDetailsNode"],
	// @ts-nocheck
/** The set of permissions assigned to the user. */
	permissions?:ValueTypes["PermissionsUserNode"],
workspaces?: [{	id?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	name?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	picture?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	ownerId?: Array<ValueTypes["StringFilter"] | undefined | null> | undefined | null | Variable<any, string>,	permissionsWorkspaceId?: Array<ValueTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null | Variable<any, string>,	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,	cursor?: string | undefined | null | Variable<any, string>,	limit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["UserWorkspaceNodePaginated"]],
	// @ts-nocheck
/** Tokens of external accounts of the user. And app tokens for authorizing request to Vectary. */
	tokens?:ValueTypes["TokenNode"],
	// @ts-nocheck
/** Tells if the given user is a CIAM user. */
	isCiamUser?:boolean | `@${string}`,
	// @ts-nocheck
/** Email invitations assigned to the user. */
	emailInvitations?:ValueTypes["EmailInvitationNode"],
	permission?:boolean | `@${string}`,
	rolePermissions?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["NonNegativeIntFilter"]: {
	filter: ValueTypes["SortableFilterEnum"] | Variable<any, string>,
	not?: boolean | undefined | null | Variable<any, string>,
	value: ValueTypes["NonNegativeInt"] | Variable<any, string>
};
	// @ts-nocheck
/** Resource with information about a user's tokens. */
["TokenNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TokenTypeColumnEnum"]:TokenTypeColumnEnum;
	// @ts-nocheck
/** An email invitation resource of the Vectary platform. */
["EmailInvitationNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Email of the invitation. */
	email?:boolean | `@${string}`,
	permission?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	workspace?:ValueTypes["WorkspaceNode"],
		__typename?: boolean | `@${string}`
}>;
	["MemberPermissionEnum"]:MemberPermissionEnum;
	["EmailInvitationStatusEnum"]:EmailInvitationStatusEnum;
	["InvitationNodePaginated"]: AliasType<{
	items?:ValueTypes["InvitationNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** An invitation resource of the Vectary platform. */
["InvitationNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	permission?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	workspace?:ValueTypes["WorkspaceNode"],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["MemberPermissionFilter"]: {
	filter: ValueTypes["TextFilterEnum"] | Variable<any, string>,
	not?: boolean | undefined | null | Variable<any, string>,
	value: ValueTypes["MemberPermissionEnum"] | Variable<any, string>
};
	["EmailInvitationNodePaginated"]: AliasType<{
	items?:ValueTypes["EmailInvitationNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["EmailInvitationStatusFilter"]: {
	filter: ValueTypes["TextFilterEnum"] | Variable<any, string>,
	not?: boolean | undefined | null | Variable<any, string>,
	value: ValueTypes["EmailInvitationStatusEnum"] | Variable<any, string>
};
	["PermissionEnum"]:PermissionEnum;
	["PermissionsUserNodePaginated"]: AliasType<{
	items?:ValueTypes["PermissionsUserNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["UserRoleFilter"]: {
	filter: ValueTypes["TextFilterEnum"] | Variable<any, string>,
	not?: boolean | undefined | null | Variable<any, string>,
	value: ValueTypes["UserRoleEnum"] | Variable<any, string>
};
	["PermissionsWorkspaceNodePaginated"]: AliasType<{
	items?:ValueTypes["PermissionsWorkspaceNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FolderNodePaginated"]: AliasType<{
	items?:ValueTypes["FolderNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A folder resource of the Vectary platform. */
["FolderNodeBase"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	isHomeFolder?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FolderFiltersArgsWithLists"]: {
	id?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	isHomeFolder?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>
};
	// @ts-nocheck
/** [server_value] is [not?] in [values] */
["StringListFilter"]: {
	not?: boolean | undefined | null | Variable<any, string>,
	values: Array<string | undefined | null> | Variable<any, string>
};
	["QueryScopeEnum"]:QueryScopeEnum;
	// @ts-nocheck
/** A custom domain resource of the Vectary platform. */
["CustomDomainNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Hostname of the domain. */
	hostname?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectNodePaginated"]: AliasType<{
	items?:ValueTypes["ProjectNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectFiltersArgsWithLists"]: {
	id?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	folderId?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	readonly?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	archived?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,
	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>
};
	// @ts-nocheck
/** A project resource of the Vectary platform. */
["ProjectStateNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. It is a hash of the state object itself. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** ID of the project this state belong to. */
	projectId?:boolean | `@${string}`,
	// @ts-nocheck
/** Object containing a state configuration. */
	state?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A nsProject resource of the Vectary platform. */
["NSProjectNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the nsProject written by a user. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	keywords?:boolean | `@${string}`,
	binaries?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?:boolean | `@${string}`,
	folder?:ValueTypes["FolderNode"],
		__typename?: boolean | `@${string}`
}>;
	["NSProjectNodePaginated"]: AliasType<{
	items?:ValueTypes["NSProjectNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A shared project resource of the Vectary platform. */
["SharedProjectNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the shared project written by a user. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	files?:boolean | `@${string}`,
	keywords?:boolean | `@${string}`,
	scene?:boolean | `@${string}`,
	credits?:boolean | `@${string}`,
	imports?:boolean | `@${string}`,
	settings?:boolean | `@${string}`,
	studioVersion?:boolean | `@${string}`,
	nodes?:ValueTypes["NodeNode"],
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A node resource of the Vectary platform. */
["NodeNodeBase"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?:boolean | `@${string}`,
	category?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
	binaries?:boolean | `@${string}`,
	files?:boolean | `@${string}`,
	libraryItem?:boolean | `@${string}`,
	forkedFromId?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NodeFiltersArgsWithLists"]: {
	id?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["NodeCategoryListFilter"] | undefined | null | Variable<any, string>,
	projectId?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	libraryItem?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	createdAt?: Array<ValueTypes["DateTimeFilter"] | undefined | null> | undefined | null | Variable<any, string>,
	folderId?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringListFilter"] | undefined | null | Variable<any, string>,
	inArchivedProject?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>
};
	// @ts-nocheck
/** [server_value] is [not?] in [values] */
["NodeCategoryListFilter"]: {
	not?: boolean | undefined | null | Variable<any, string>,
	values: Array<ValueTypes["NodeCategoryEnum"] | undefined | null> | Variable<any, string>
};
	["UserEmailDetailsNodePaginated"]: AliasType<{
	items?:ValueTypes["UserEmailDetailsNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A role permission resource type. */
["RolePermissionNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name of the role permission. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Display name of the role permission. */
	displayName?:boolean | `@${string}`,
	// @ts-nocheck
/** Description of the role permission. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A member role resource of the Vectary platform. */
["MemberRoleType"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name of the member role. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Display name of the member role. */
	displayName?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A workspace role permission resource of the Vectary platform. */
["WorkspaceRolePermissionType"]: AliasType<{
	// @ts-nocheck
/** ID of the workspace. */
	workspaceId?:boolean | `@${string}`,
	// @ts-nocheck
/** Member role. */
	memberRole?:boolean | `@${string}`,
	// @ts-nocheck
/** Role permission. */
	rolePermission?:boolean | `@${string}`,
	// @ts-nocheck
/** Tells if the given permission is allowed or not for the role. */
	isAllowed?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createPermissionsUser?: [{	id: ValueTypes["NonNegativeInt"] | Variable<any, string>,	name: string | Variable<any, string>,	role?: ValueTypes["UserRoleEnum"] | undefined | null | Variable<any, string>,	workspaceLimit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>,	internalNote?: string | undefined | null | Variable<any, string>},ValueTypes["PermissionsUserNode"]],
deletePermissionsUser?: [{	id: ValueTypes["NonNegativeInt"] | Variable<any, string>},boolean | `@${string}`],
updatePermissionsUser?: [{	id: ValueTypes["NonNegativeInt"] | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	role?: ValueTypes["UserRoleEnum"] | undefined | null | Variable<any, string>,	workspaceLimit?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>,	internalNote?: string | undefined | null | Variable<any, string>},ValueTypes["PermissionsUserNode"]],
createPermissionsWorkspace?: [{	id: ValueTypes["NonNegativeInt"] | Variable<any, string>,	name: string | Variable<any, string>,	properties?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	internalNote?: string | undefined | null | Variable<any, string>},ValueTypes["PermissionsWorkspaceNode"]],
deletePermissionsWorkspace?: [{	id: ValueTypes["NonNegativeInt"] | Variable<any, string>},boolean | `@${string}`],
updatePermissionsWorkspace?: [{	id: ValueTypes["NonNegativeInt"] | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	properties?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	internalNote?: string | undefined | null | Variable<any, string>},ValueTypes["PermissionsWorkspaceNode"]],
deleteUser?: [{	id: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
updateUser?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	username?: string | undefined | null | Variable<any, string>,	firstName?: string | undefined | null | Variable<any, string>,	lastName?: string | undefined | null | Variable<any, string>,	biography?: string | undefined | null | Variable<any, string>,	picture?: string | undefined | null | Variable<any, string>,	questionnaire?: ValueTypes["QuestionnaireInputObjectType"] | undefined | null | Variable<any, string>,	permissionsUserId?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>,	trialUsed?: boolean | undefined | null | Variable<any, string>,	internalNotes?: string | undefined | null | Variable<any, string>,	proUsed?: boolean | undefined | null | Variable<any, string>},ValueTypes["UserNode"]],
updateUserEmailDetails?: [{	email?: string | undefined | null | Variable<any, string>,	newsletter?: boolean | undefined | null | Variable<any, string>,	notifyComments?: string | undefined | null | Variable<any, string>,	undeliverable?: boolean | undefined | null | Variable<any, string>,	userId: ValueTypes["UUID"] | Variable<any, string>,	verificationToken?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},ValueTypes["UserEmailDetailsNode"]],
updateUserLocationDetails?: [{	userId: ValueTypes["UUID"] | Variable<any, string>,	location?: string | undefined | null | Variable<any, string>,	geo?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>},ValueTypes["UserLocationDetailsNode"]],
acceptGdprAndTou?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["UserNode"]],
addEmailAuthenticationMethod?: [{	userId: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
createWorkspace?: [{	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	picture?: string | undefined | null | Variable<any, string>,	ownerId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	permissionsWorkspaceId?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["WorkspaceNode"]],
deleteWorkspace?: [{	id: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
updateWorkspace?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	picture?: string | undefined | null | Variable<any, string>,	ownerId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	permissionsWorkspaceId?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>,	overwrite?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	customDomainId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	ordering?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["WorkspaceNode"]],
addMember?: [{	workspaceId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	userId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
updateMember?: [{	workspaceId: ValueTypes["UUID"] | Variable<any, string>,	userId: ValueTypes["UUID"] | Variable<any, string>,	permission: ValueTypes["MemberPermissionEnum"] | Variable<any, string>},boolean | `@${string}`],
removeMember?: [{	workspaceId: ValueTypes["UUID"] | Variable<any, string>,	userId: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
cancelPaddleSubscription?: [{	workspaceId: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
acceptInvitation?: [{	invitationId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},ValueTypes["InvitationNode"]],
createInvitation?: [{	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	permission?: ValueTypes["MemberPermissionEnum"] | undefined | null | Variable<any, string>,	workspaceId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["InvitationNode"]],
deleteInvitation?: [{	id: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
updateInvitation?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	permission?: ValueTypes["MemberPermissionEnum"] | undefined | null | Variable<any, string>},ValueTypes["InvitationNode"]],
createEmailInvitation?: [{	items?: Array<ValueTypes["EmailInvitationInput"] | undefined | null> | undefined | null | Variable<any, string>},ValueTypes["EmailInvitationPossibleErrorNode"]],
deleteEmailInvitation?: [{	id: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
updateEmailInvitation?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	permission?: ValueTypes["MemberPermissionEnum"] | undefined | null | Variable<any, string>,	status?: ValueTypes["EmailInvitationStatusEnum"] | undefined | null | Variable<any, string>},ValueTypes["EmailInvitationNode"]],
acceptEmailInvitation?: [{	invitationId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},ValueTypes["EmailInvitationNode"]],
reactivateEmailInvitation?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["EmailInvitationNode"]],
createFolder?: [{	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	workspaceId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["FolderNode"]],
deleteFolder?: [{	id: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
updateFolder?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	workspaceId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	ordering?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>},ValueTypes["FolderNode"]],
createCustomDomain?: [{	hostname?: string | undefined | null | Variable<any, string>},ValueTypes["CustomDomainNode"]],
deleteCustomDomain?: [{	id: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
createProject?: [{	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>,	picture?: ValueTypes["SHA1HashOrUpload"] | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	scene: ValueTypes["JSONObject"] | Variable<any, string>,	view?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	credits?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,	published?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	imports?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	settings?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	studioVersion?: ValueTypes["SemVer"] | undefined | null | Variable<any, string>,	forkedFromId?: string | undefined | null | Variable<any, string>,	folderId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	readonly?: boolean | undefined | null | Variable<any, string>,	deleted?: boolean | undefined | null | Variable<any, string>},ValueTypes["ProjectNode"]],
updateProject?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>,	picture?: ValueTypes["SHA1HashOrUpload"] | undefined | null | Variable<any, string>,	files?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	scene?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	view?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	credits?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,	published?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	imports?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	settings?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	studioVersion?: ValueTypes["SemVer"] | undefined | null | Variable<any, string>,	forkedFromId?: string | undefined | null | Variable<any, string>,	folderId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	ordering?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>,	readonly?: boolean | undefined | null | Variable<any, string>,	deleted?: boolean | undefined | null | Variable<any, string>},ValueTypes["ProjectNode"]],
createOrUpdateProject?: [{	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>,	picture?: ValueTypes["SHA1HashOrUpload"] | undefined | null | Variable<any, string>,	files?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	scene: ValueTypes["JSONObject"] | Variable<any, string>,	view?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	credits?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,	published?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	imports?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	settings?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	studioVersion?: ValueTypes["SemVer"] | undefined | null | Variable<any, string>,	forkedFromId?: string | undefined | null | Variable<any, string>,	folderId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	ordering?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>,	readonly?: boolean | undefined | null | Variable<any, string>,	deleted?: boolean | undefined | null | Variable<any, string>,	activeNodes?: Array<ValueTypes["UUID"] | undefined | null> | undefined | null | Variable<any, string>,	nodes?: Array<ValueTypes["NodeTypeBaseInput"] | undefined | null> | undefined | null | Variable<any, string>},ValueTypes["ProjectNode"]],
deleteProject?: [{	id: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
cloneProject?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	newId: ValueTypes["UUID"] | Variable<any, string>,	workspaceId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["OperationResult"]],
copyProject?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	newId: ValueTypes["UUID"] | Variable<any, string>,	folderId: ValueTypes["UUID"] | Variable<any, string>,	workspaceId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["OperationResult"]],
createProjectState?: [{	id?: string | undefined | null | Variable<any, string>,	projectId: ValueTypes["UUID"] | Variable<any, string>,	state?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>},ValueTypes["ProjectStateNode"]],
createNSProject?: [{	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>,	picture?: ValueTypes["SHA1HashOrUpload"] | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	binaries?: Array<ValueTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null | Variable<any, string>,	folderId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["NSProjectNode"]],
updateNSProject?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>,	picture?: ValueTypes["SHA1HashOrUpload"] | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	binaries?: Array<ValueTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null | Variable<any, string>,	folderId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},ValueTypes["NSProjectNode"]],
deleteNSProject?: [{	id: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
createSharedProject?: [{	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	projectId?: string | undefined | null | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>,	picture?: ValueTypes["SHA1HashOrUpload"] | undefined | null | Variable<any, string>,	keywords?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	scene: ValueTypes["JSONObject"] | Variable<any, string>,	credits?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,	imports?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	settings?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	studioVersion?: ValueTypes["SemVer"] | undefined | null | Variable<any, string>,	nodes?: Array<ValueTypes["SharedProjectNodeNode"] | undefined | null> | undefined | null | Variable<any, string>},ValueTypes["ProjectNode"]],
createNode?: [{	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>,	category: ValueTypes["NodeCategoryEnum"] | Variable<any, string>,	data: ValueTypes["JSONObject"] | Variable<any, string>,	binaries?: Array<ValueTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null | Variable<any, string>,	files?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,	libraryItem?: boolean | undefined | null | Variable<any, string>,	forkedFromId?: string | undefined | null | Variable<any, string>,	projectId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["NodeNode"]],
createOrUpdateNode?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>,	category?: ValueTypes["NodeCategoryEnum"] | undefined | null | Variable<any, string>,	data?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	binaries?: Array<ValueTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null | Variable<any, string>,	files?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,	libraryItem?: boolean | undefined | null | Variable<any, string>,	forkedFromId?: string | undefined | null | Variable<any, string>,	projectId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},ValueTypes["NodeNode"]],
deleteNode?: [{	id: ValueTypes["UUID"] | Variable<any, string>},boolean | `@${string}`],
updateNode?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>,	category?: ValueTypes["NodeCategoryEnum"] | undefined | null | Variable<any, string>,	data?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	binaries?: Array<ValueTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null | Variable<any, string>,	files?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,	libraryItem?: boolean | undefined | null | Variable<any, string>,	forkedFromId?: string | undefined | null | Variable<any, string>,	projectId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},ValueTypes["NodeNode"]],
createCommentMessage?: [{	projectId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	nodeId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	commentId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	msg?: string | undefined | null | Variable<any, string>,	user?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	clickedPosition?: Array<number | undefined | null> | undefined | null | Variable<any, string>,	targetOrientation?: Array<number | undefined | null> | undefined | null | Variable<any, string>},ValueTypes["NodeNode"]],
updateCommentMessage?: [{	projectId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	nodeId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	commentId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	messageId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	msg?: string | undefined | null | Variable<any, string>,	user?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,	clickedPosition?: Array<number | undefined | null> | undefined | null | Variable<any, string>,	targetOrientation?: Array<number | undefined | null> | undefined | null | Variable<any, string>},ValueTypes["NodeNode"]],
deleteCommentMessage?: [{	projectId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	nodeId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	commentId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	messageId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},ValueTypes["NodeNode"]],
uploadBinary?: [{	binary?: ValueTypes["SHA1HashOrUpload"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
createToken?: [{	name: string | Variable<any, string>,	type: ValueTypes["TokenTypeColumnEnum"] | Variable<any, string>,	token: string | Variable<any, string>},ValueTypes["TokenNode"]],
createApiToken?: [{	name: string | Variable<any, string>},ValueTypes["TokenNode"]],
updateToken?: [{	id: ValueTypes["UUID"] | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	type?: ValueTypes["TokenTypeColumnEnum"] | undefined | null | Variable<any, string>,	token?: string | undefined | null | Variable<any, string>},ValueTypes["TokenNode"]],
deleteToken?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["TokenNode"]],
createRolePermission?: [{	id?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>,	name?: string | undefined | null | Variable<any, string>,	displayName?: string | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>},ValueTypes["RolePermissionNode"]],
deleteRolePermission?: [{	id: ValueTypes["NonNegativeInt"] | Variable<any, string>},boolean | `@${string}`],
createMemberRole?: [{	id?: ValueTypes["NonNegativeInt"] | undefined | null | Variable<any, string>,	name: string | Variable<any, string>,	displayName: string | Variable<any, string>},ValueTypes["MemberRoleType"]],
deleteMemberRole?: [{	id: ValueTypes["NonNegativeInt"] | Variable<any, string>},boolean | `@${string}`],
updateWorkspaceRolePermission?: [{	workspaceId: ValueTypes["UUID"] | Variable<any, string>,	memberRole: string | Variable<any, string>,	rolePermission: string | Variable<any, string>,	isAllowed: boolean | Variable<any, string>},ValueTypes["WorkspaceRolePermissionType"]],
deleteWorkspaceRolePermission?: [{	workspaceId: ValueTypes["UUID"] | Variable<any, string>,	memberRoleId: number | Variable<any, string>,	rolePermissionId: number | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Questionnaire mutation object */
["QuestionnaireInputObjectType"]: {
	occupation?: ValueTypes["QuestionnaireDataInputObjectType"] | undefined | null | Variable<any, string>,
	reasonToLeave?: ValueTypes["QuestionnaireDataInputObjectType"] | undefined | null | Variable<any, string>
};
	// @ts-nocheck
/** Questionnaire mutation object */
["QuestionnaireDataInputObjectType"]: {
	value: string | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	planName?: string | undefined | null | Variable<any, string>,
	category?: string | undefined | null | Variable<any, string>,
	subscribedForDays?: ValueTypes["NonNegativeFloat"] | undefined | null | Variable<any, string>
};
	// @ts-nocheck
/** An email invitation resource of the Vectary platform. It includes error if any. */
["EmailInvitationPossibleErrorNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Email of the invitation. */
	email?:boolean | `@${string}`,
	permission?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	workspace?:ValueTypes["WorkspaceNode"],
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EmailInvitationInput"]: {
	email?: string | undefined | null | Variable<any, string>,
	permission?: ValueTypes["MemberPermissionEnum"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["EmailInvitationStatusEnum"] | undefined | null | Variable<any, string>,
	workspaceId: ValueTypes["UUID"] | Variable<any, string>
};
	// @ts-nocheck
/** This scalar accepts either a SHA-1 hash, which is a 40 character long string or a file upload. See the [GraphQL multipart request specification](https://github.com/jaydenseric/graphql-multipart-request-spec) for more information. */
["SHA1HashOrUpload"]:unknown;
	["NodeTypeBaseInput"]: {
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined | null | Variable<any, string>,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?: string | undefined | null | Variable<any, string>,
	category?: ValueTypes["NodeCategoryEnum"] | undefined | null | Variable<any, string>,
	data?: ValueTypes["JSONObject"] | undefined | null | Variable<any, string>,
	binaries?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	files?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,
	libraryItem?: boolean | undefined | null | Variable<any, string>,
	forkedFromId?: string | undefined | null | Variable<any, string>,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	projectId?: string | undefined | null | Variable<any, string>
};
	// @ts-nocheck
/** The result of the operation. */
["OperationResult"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OperationResultType"]:OperationResultType;
	["SharedProjectNodeNode"]: {
	id: ValueTypes["UUID"] | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	category: ValueTypes["NodeCategoryEnum"] | Variable<any, string>,
	data: ValueTypes["JSONObject"] | Variable<any, string>,
	binaries?: Array<ValueTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null | Variable<any, string>,
	files?: Array<ValueTypes["JSONObject"] | undefined | null> | undefined | null | Variable<any, string>,
	libraryItem?: boolean | undefined | null | Variable<any, string>,
	forkedFromId?: string | undefined | null | Variable<any, string>
};
	["Subscription"]: AliasType<{
	countDown?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ResolverInputTypes = {
    ["Query"]: AliasType<{
permissionsUser?: [{	id: ResolverInputTypes["NonNegativeInt"]},ResolverInputTypes["PermissionsUserNode"]],
allPermissionsUsers?: [{	id?: Array<ResolverInputTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	role?: Array<ResolverInputTypes["UserRoleFilter"] | undefined | null> | undefined | null,	workspaceLimit?: Array<ResolverInputTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null,	internalNote?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["PermissionsUserNodePaginated"]],
permissionsWorkspace?: [{	id: ResolverInputTypes["NonNegativeInt"]},ResolverInputTypes["PermissionsWorkspaceNode"]],
allPermissionsWorkspaces?: [{	id?: Array<ResolverInputTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	internalNote?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["PermissionsWorkspaceNodePaginated"]],
user?: [{	id?: ResolverInputTypes["UUID"] | undefined | null},ResolverInputTypes["UserNode"]],
allUsers?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	username?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	biography?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permissionsUserId?: Array<ResolverInputTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null,	trialUsed?: ResolverInputTypes["BooleanFilter"] | undefined | null,	proUsed?: ResolverInputTypes["BooleanFilter"] | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["UserNodePaginated"]],
userByEmailOrHash?: [{	emailOrHash?: string | undefined | null},ResolverInputTypes["UserEmailDetailsNode"]],
workspace?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["WorkspaceNode"]],
allWorkspaces?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	ownerId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permissionsWorkspaceId?: Array<ResolverInputTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["WorkspaceNodePaginated"]],
invitation?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["InvitationNode"]],
allInvitations?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permission?: Array<ResolverInputTypes["MemberPermissionFilter"] | undefined | null> | undefined | null,	workspaceId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["InvitationNodePaginated"]],
emailInvitation?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["EmailInvitationNode"]],
allEmailInvitations?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	email?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permission?: Array<ResolverInputTypes["MemberPermissionFilter"] | undefined | null> | undefined | null,	status?: Array<ResolverInputTypes["EmailInvitationStatusFilter"] | undefined | null> | undefined | null,	workspaceId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["EmailInvitationNodePaginated"]],
folder?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["FolderNode"]],
allFoldersPaginated?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	isHomeFolder?: ResolverInputTypes["BooleanFilter"] | undefined | null,	workspaceId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["FolderNodePaginated"]],
allFolders?: [{	filters?: ResolverInputTypes["FolderFiltersArgsWithLists"] | undefined | null,	scope?: ResolverInputTypes["QueryScopeEnum"] | undefined | null},ResolverInputTypes["FolderNodeBase"]],
	allCustomDomains?:ResolverInputTypes["CustomDomainNode"],
project?: [{	id: ResolverInputTypes["UUID"],	// @ts-nocheck
/** Specific point in time. If provided, the returned resources will be the version of them at that moment. */
	version?: ResolverInputTypes["DateTime"] | undefined | null},ResolverInputTypes["ProjectNode"]],
allProjectsPaginated?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	description?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	view?: boolean | undefined | null,	studioVersion?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	folderId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	readonly?: ResolverInputTypes["BooleanFilter"] | undefined | null,	deleted?: ResolverInputTypes["BooleanFilter"] | undefined | null,	updatedAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	sort?: ResolverInputTypes["ProjectSort"] | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["ProjectNodePaginated"]],
allProjects?: [{	filters?: ResolverInputTypes["ProjectFiltersArgsWithLists"] | undefined | null,	scope?: ResolverInputTypes["QueryScopeEnum"] | undefined | null},ResolverInputTypes["ProjectNode"]],
allProjectsCount?: [{	filters?: ResolverInputTypes["ProjectFiltersArgsWithLists"] | undefined | null,	scope?: ResolverInputTypes["QueryScopeEnum"] | undefined | null},boolean | `@${string}`],
projectState?: [{	id: string,	projectId: ResolverInputTypes["UUID"]},ResolverInputTypes["ProjectStateNode"]],
nsProject?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["NSProjectNode"]],
allNSProjects?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	description?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	updatedAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	sort?: ResolverInputTypes["NSProjectSort"] | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["NSProjectNodePaginated"]],
sharedProject?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["SharedProjectNode"]],
node?: [{	id: ResolverInputTypes["UUID"],	// @ts-nocheck
/** Specific point in time. If provided, the returned resources will be the version of at that moment. */
	version?: ResolverInputTypes["DateTime"] | undefined | null},ResolverInputTypes["NodeNode"]],
allNodesPaginated?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	description?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	category?: Array<ResolverInputTypes["NodeCategoryFilter"] | undefined | null> | undefined | null,	projectId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	libraryItem?: ResolverInputTypes["BooleanFilter"] | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	folder?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	workspace?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	deleted?: ResolverInputTypes["BooleanFilter"] | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["NodeNodePaginated"]],
allNodes?: [{	filters?: ResolverInputTypes["NodeFiltersArgsWithLists"] | undefined | null,	scope?: ResolverInputTypes["QueryScopeEnum"] | undefined | null},ResolverInputTypes["NodeNodeBase"]],
allNodesCount?: [{	filters?: ResolverInputTypes["NodeFiltersArgsWithLists"] | undefined | null,	scope?: ResolverInputTypes["QueryScopeEnum"] | undefined | null},boolean | `@${string}`],
token?: [{	id?: ResolverInputTypes["UUID"] | undefined | null},ResolverInputTypes["TokenNode"]],
	allTokens?:ResolverInputTypes["TokenNode"],
emails?: [{	subscribed?: boolean | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["UserEmailDetailsNodePaginated"]],
rolePermission?: [{	id?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["RolePermissionNode"]],
	allRolePermissions?:ResolverInputTypes["RolePermissionNode"],
memberRole?: [{	id?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["MemberRoleType"]],
	allMemberRoles?:ResolverInputTypes["MemberRoleType"],
workspaceRolePermission?: [{	workspaceId: ResolverInputTypes["UUID"],	memberRoleId: number,	rolePermissionId: number},ResolverInputTypes["WorkspaceRolePermissionType"]],
workspacePermissions?: [{	id: ResolverInputTypes["UUID"]},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a user resource. */
["PermissionsUserNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	workspaceLimit?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
users?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	username?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	biography?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permissionsUserId?: Array<ResolverInputTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null,	trialUsed?: ResolverInputTypes["BooleanFilter"] | undefined | null,	proUsed?: ResolverInputTypes["BooleanFilter"] | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["UserNodePaginated"]],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Integers that will have a value of 0 or more. */
["NonNegativeInt"]:unknown;
	["UserRoleEnum"]:UserRoleEnum;
	// @ts-nocheck
/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]:unknown;
	["UserNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["UserNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A user resource of the Vectary platform. Contains the basic information of an account and it is directly related with certain collections of details of the account and the workspaces related to it */
["UserNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of a user. It is a UUIDv4 but at some point it will start accepting any string. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Nickname chosen by the user. */
	username?:boolean | `@${string}`,
	// @ts-nocheck
/** Firstname of the user. */
	firstName?:boolean | `@${string}`,
	// @ts-nocheck
/** Lastname the user. */
	lastName?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the user written by them. */
	biography?:boolean | `@${string}`,
	// @ts-nocheck
/** The location of the picture used for the profile of the user. */
	picture?:boolean | `@${string}`,
	// @ts-nocheck
/** Metadata we have collected from the user via questionnaires. */
	questionnaire?:boolean | `@${string}`,
	// @ts-nocheck
/** Version of the General Data Protection Regulation accepted by the user. */
	gdpr?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the General Data Protection Regulation accepted by the user is the current one or not. */
	isGdprCurrent?:boolean | `@${string}`,
	// @ts-nocheck
/** Version of the Terms Of Use accepted by the user. */
	tou?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the Terms Of Use accepted by the user is the current one or not. */
	isTouCurrent?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the user has used the one time trial. */
	trialUsed?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the user has ever paid for a PRO plan. */
	proUsed?:boolean | `@${string}`,
	// @ts-nocheck
/** Internal notes for a given user. Available only for Administrators. */
	internalNotes?:boolean | `@${string}`,
	// @ts-nocheck
/** Time of creation of the user. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Email configuration for the user. */
	emailDetails?:ResolverInputTypes["UserEmailDetailsNode"],
	// @ts-nocheck
/** Location information about the user. */
	locationDetails?:ResolverInputTypes["UserLocationDetailsNode"],
	// @ts-nocheck
/** Access information about the user. Available only for Administrators. */
	accessDetails?:ResolverInputTypes["UserAccessDetailsNode"],
	// @ts-nocheck
/** The set of permissions assigned to the user. */
	permissions?:ResolverInputTypes["PermissionsUserNode"],
workspaces?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	ownerId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permissionsWorkspaceId?: Array<ResolverInputTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["UserWorkspaceNodePaginated"]],
	// @ts-nocheck
/** Tokens of external accounts of the user. And app tokens for authorizing request to Vectary. */
	tokens?:ResolverInputTypes["TokenNode"],
	// @ts-nocheck
/** Tells if the given user is a CIAM user. */
	isCiamUser?:boolean | `@${string}`,
	// @ts-nocheck
/** Email invitations assigned to the user. */
	emailInvitations?:ResolverInputTypes["EmailInvitationNode"],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
["UUID"]:unknown;
	// @ts-nocheck
/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSONObject"]:unknown;
	// @ts-nocheck
/** Floats that will have a value of 0 or more. */
["NonNegativeFloat"]:unknown;
	// @ts-nocheck
/** Resource with information about a user's email. */
["UserEmailDetailsNode"]: AliasType<{
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	newsletter?:boolean | `@${string}`,
	notifyComments?:boolean | `@${string}`,
	undeliverable?:boolean | `@${string}`,
	verificationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Resource with information about a user's location. */
["UserLocationDetailsNode"]: AliasType<{
	location?:boolean | `@${string}`,
	geo?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Resource with information about a user's access detail. */
["UserAccessDetailsNode"]: AliasType<{
	accessToken?:boolean | `@${string}`,
	accessTokenExpiration?:boolean | `@${string}`,
	refreshToken?:boolean | `@${string}`,
	refreshTokenExpiration?:boolean | `@${string}`,
	passwordIdentifier?:boolean | `@${string}`,
	passwordResetToken?:boolean | `@${string}`,
	facebookIdentifier?:boolean | `@${string}`,
	googleIdentifier?:boolean | `@${string}`,
	ciamIdentifier?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserWorkspaceNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["UserWorkspaceNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A workspace resource of the Vectary platform.
Includes extra properties about the relation with the user. */
["UserWorkspaceNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	// @ts-nocheck
/** Collection of admin-defined properties to override some kind of behaviour. */
	overwrite?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	owner?:ResolverInputTypes["UserNode"],
	customDomain?:boolean | `@${string}`,
	workspaceRolePermissions?:boolean | `@${string}`,
	permissions?:ResolverInputTypes["PermissionsWorkspaceNode"],
	paddleDetails?:ResolverInputTypes["PaddleDetailsNode"],
folders?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	isHomeFolder?: ResolverInputTypes["BooleanFilter"] | undefined | null,	workspaceId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["WorkspaceFolderNodePaginated"]],
	members?:ResolverInputTypes["WorkspaceMemberNode"],
invitations?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	workspaceId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permission?: Array<ResolverInputTypes["MemberPermissionFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["InvitationNodePaginated"]],
emailInvitations?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	workspaceId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permission?: Array<ResolverInputTypes["MemberPermissionFilter"] | undefined | null> | undefined | null,	status?: Array<ResolverInputTypes["EmailInvitationStatusFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["EmailInvitationNodePaginated"]],
	permission?:boolean | `@${string}`,
	ordering?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a workspace resource. */
["PermissionsWorkspaceNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	properties?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
workspaces?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	ownerId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permissionsWorkspaceId?: Array<ResolverInputTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["WorkspaceNodePaginated"]],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["WorkspaceNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A workspace resource of the Vectary platform. */
["WorkspaceNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	// @ts-nocheck
/** Collection of admin-defined properties to override some kind of behaviour. */
	overwrite?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	owner?:ResolverInputTypes["UserNode"],
	customDomain?:boolean | `@${string}`,
	workspaceRolePermissions?:boolean | `@${string}`,
	permissions?:ResolverInputTypes["PermissionsWorkspaceNode"],
	paddleDetails?:ResolverInputTypes["PaddleDetailsNode"],
folders?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	isHomeFolder?: ResolverInputTypes["BooleanFilter"] | undefined | null,	workspaceId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["WorkspaceFolderNodePaginated"]],
	members?:ResolverInputTypes["WorkspaceMemberNode"],
invitations?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	workspaceId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permission?: Array<ResolverInputTypes["MemberPermissionFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["InvitationNodePaginated"]],
emailInvitations?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	workspaceId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permission?: Array<ResolverInputTypes["MemberPermissionFilter"] | undefined | null> | undefined | null,	status?: Array<ResolverInputTypes["EmailInvitationStatusFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["EmailInvitationNodePaginated"]],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a workspace resource. */
["PaddleDetailsNode"]: AliasType<{
	customerId?:boolean | `@${string}`,
	subscriptionId?:boolean | `@${string}`,
	linkedSubscriptionId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
	currency?:boolean | `@${string}`,
	recurringInterval?:boolean | `@${string}`,
	nextPaymentAmount?:boolean | `@${string}`,
	nextPaymentDate?:boolean | `@${string}`,
	cancellationEffectiveDate?:boolean | `@${string}`,
	cancelUrl?:boolean | `@${string}`,
	updateUrl?:boolean | `@${string}`,
	receipts?:boolean | `@${string}`,
	latestEventTime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
["Currency"]:unknown;
	// @ts-nocheck
/** 
    A string representing a duration conforming to the ISO8601 standard,
    such as: P1W1DT13H23M34S
    P is the duration designator (for period) placed at the start of the duration representation.
    Y is the year designator that follows the value for the number of years.
    M is the month designator that follows the value for the number of months.
    W is the week designator that follows the value for the number of weeks.
    D is the day designator that follows the value for the number of days.
    T is the time designator that precedes the time components of the representation.
    H is the hour designator that follows the value for the number of hours.
    M is the minute designator that follows the value for the number of minutes.
    S is the second designator that follows the value for the number of seconds.

    Note the time designator, T, that precedes the time value.

    Matches moment.js, Luxon and DateFns implementations
    ,/. is valid for decimal places and +/- is a valid prefix
   */
["Duration"]:unknown;
	// @ts-nocheck
/** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
["URL"]:unknown;
	["WorkspaceFolderNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["WorkspaceFolderNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A folder resource of the Vectary platform.
Includes extra properties about the relation with the workspace. */
["WorkspaceFolderNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	isHomeFolder?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	workspace?:ResolverInputTypes["WorkspaceNode"],
projects?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	description?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	view?: boolean | undefined | null,	studioVersion?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	folderId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	readonly?: ResolverInputTypes["BooleanFilter"] | undefined | null,	deleted?: ResolverInputTypes["BooleanFilter"] | undefined | null,	updatedAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	sort?: ResolverInputTypes["ProjectSort"] | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["FolderProjectNodePaginated"]],
nsProjects?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	description?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	updatedAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	sort?: ResolverInputTypes["NSProjectSort"] | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["FolderNSProjectNodePaginated"]],
	ordering?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FolderProjectNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["FolderProjectNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A project resource of the Vectary platform.
Includes extra properties about the relation with the folder. */
["FolderProjectNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the project written by a user. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	files?:ResolverInputTypes["File"],
	keywords?:boolean | `@${string}`,
	scene?:boolean | `@${string}`,
	view?:boolean | `@${string}`,
	credits?:boolean | `@${string}`,
	published?:boolean | `@${string}`,
	imports?:boolean | `@${string}`,
	settings?:boolean | `@${string}`,
	studioVersion?:boolean | `@${string}`,
	forkedFromId?:boolean | `@${string}`,
	readonly?:boolean | `@${string}`,
	deleted?:boolean | `@${string}`,
	folderId?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?:boolean | `@${string}`,
	folder?:ResolverInputTypes["FolderNode"],
nodes?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	description?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	category?: Array<ResolverInputTypes["NodeCategoryFilter"] | undefined | null> | undefined | null,	projectId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	libraryItem?: ResolverInputTypes["BooleanFilter"] | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	folder?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	workspace?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	deleted?: ResolverInputTypes["BooleanFilter"] | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["NodeNodePaginated"]],
	history?:boolean | `@${string}`,
	ordering?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Uploaded file meta data. */
["File"]: AliasType<{
	purpose?:boolean | `@${string}`,
	urls?:boolean | `@${string}`,
	sizes?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Integers that will have a value greater than 0. */
["PositiveInt"]:unknown;
	// @ts-nocheck
/** A field whose value is a Semantic Version: https://semver.org */
["SemVer"]:unknown;
	// @ts-nocheck
/** A folder resource of the Vectary platform. */
["FolderNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	isHomeFolder?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	workspace?:ResolverInputTypes["WorkspaceNode"],
projects?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	description?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	view?: boolean | undefined | null,	studioVersion?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	folderId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	readonly?: ResolverInputTypes["BooleanFilter"] | undefined | null,	deleted?: ResolverInputTypes["BooleanFilter"] | undefined | null,	updatedAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	sort?: ResolverInputTypes["ProjectSort"] | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["FolderProjectNodePaginated"]],
nsProjects?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	description?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	updatedAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	sort?: ResolverInputTypes["NSProjectSort"] | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["FolderNSProjectNodePaginated"]],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["StringFilter"]: {
	filter: ResolverInputTypes["TextFilterEnum"],
	not?: boolean | undefined | null,
	value: string
};
	["TextFilterEnum"]:TextFilterEnum;
	// @ts-nocheck
/** [server_value] is [filter?] [value] */
["BooleanFilter"]: {
	filter: ResolverInputTypes["BooleanFilterEnum"],
	value: boolean
};
	["BooleanFilterEnum"]:BooleanFilterEnum;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["DateTimeFilter"]: {
	filter: ResolverInputTypes["SortableFilterEnum"],
	not?: boolean | undefined | null,
	value: ResolverInputTypes["DateTime"]
};
	["SortableFilterEnum"]:SortableFilterEnum;
	["ProjectSort"]:ProjectSort;
	["FolderNSProjectNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["FolderNSProjectNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A nsProject resource of the Vectary platform.
Includes extra properties about the relation with the folder. */
["FolderNSProjectNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the nsProject written by a user. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	keywords?:boolean | `@${string}`,
	binaries?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?:boolean | `@${string}`,
	folder?:ResolverInputTypes["FolderNode"],
	ordering?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
["BigInt"]:unknown;
	["NSProjectSort"]:NSProjectSort;
	["NodeNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["NodeNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A node resource of the Vectary platform. */
["NodeNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?:boolean | `@${string}`,
	category?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
	binaries?:boolean | `@${string}`,
	files?:boolean | `@${string}`,
	libraryItem?:boolean | `@${string}`,
	forkedFromId?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
	project?:ResolverInputTypes["ProjectNode"],
	history?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NodeCategoryEnum"]:NodeCategoryEnum;
	// @ts-nocheck
/** A project resource of the Vectary platform. */
["ProjectNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the project written by a user. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	files?:ResolverInputTypes["File"],
	keywords?:boolean | `@${string}`,
	scene?:boolean | `@${string}`,
	view?:boolean | `@${string}`,
	credits?:boolean | `@${string}`,
	published?:boolean | `@${string}`,
	imports?:boolean | `@${string}`,
	settings?:boolean | `@${string}`,
	studioVersion?:boolean | `@${string}`,
	forkedFromId?:boolean | `@${string}`,
	readonly?:boolean | `@${string}`,
	deleted?:boolean | `@${string}`,
	folderId?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?:boolean | `@${string}`,
	folder?:ResolverInputTypes["FolderNode"],
nodes?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	description?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	category?: Array<ResolverInputTypes["NodeCategoryFilter"] | undefined | null> | undefined | null,	projectId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	libraryItem?: ResolverInputTypes["BooleanFilter"] | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	folder?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	workspace?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	deleted?: ResolverInputTypes["BooleanFilter"] | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["NodeNodePaginated"]],
	history?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["NodeCategoryFilter"]: {
	filter: ResolverInputTypes["TextFilterEnum"],
	not?: boolean | undefined | null,
	value: ResolverInputTypes["NodeCategoryEnum"]
};
	// @ts-nocheck
/** A user resource of the Vectary platform. Contains the basic information of an account and it is directly related with certain collections of details of the account and the workspaces related to it
Includes extra properties about the relation with the workspace. */
["WorkspaceMemberNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of a user. It is a UUIDv4 but at some point it will start accepting any string. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Nickname chosen by the user. */
	username?:boolean | `@${string}`,
	// @ts-nocheck
/** Firstname of the user. */
	firstName?:boolean | `@${string}`,
	// @ts-nocheck
/** Lastname the user. */
	lastName?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the user written by them. */
	biography?:boolean | `@${string}`,
	// @ts-nocheck
/** The location of the picture used for the profile of the user. */
	picture?:boolean | `@${string}`,
	// @ts-nocheck
/** Metadata we have collected from the user via questionnaires. */
	questionnaire?:boolean | `@${string}`,
	// @ts-nocheck
/** Version of the General Data Protection Regulation accepted by the user. */
	gdpr?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the General Data Protection Regulation accepted by the user is the current one or not. */
	isGdprCurrent?:boolean | `@${string}`,
	// @ts-nocheck
/** Version of the Terms Of Use accepted by the user. */
	tou?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the Terms Of Use accepted by the user is the current one or not. */
	isTouCurrent?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the user has used the one time trial. */
	trialUsed?:boolean | `@${string}`,
	// @ts-nocheck
/** Whether the user has ever paid for a PRO plan. */
	proUsed?:boolean | `@${string}`,
	// @ts-nocheck
/** Internal notes for a given user. Available only for Administrators. */
	internalNotes?:boolean | `@${string}`,
	// @ts-nocheck
/** Time of creation of the user. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Email configuration for the user. */
	emailDetails?:ResolverInputTypes["UserEmailDetailsNode"],
	// @ts-nocheck
/** Location information about the user. */
	locationDetails?:ResolverInputTypes["UserLocationDetailsNode"],
	// @ts-nocheck
/** Access information about the user. Available only for Administrators. */
	accessDetails?:ResolverInputTypes["UserAccessDetailsNode"],
	// @ts-nocheck
/** The set of permissions assigned to the user. */
	permissions?:ResolverInputTypes["PermissionsUserNode"],
workspaces?: [{	id?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	name?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	picture?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	ownerId?: Array<ResolverInputTypes["StringFilter"] | undefined | null> | undefined | null,	permissionsWorkspaceId?: Array<ResolverInputTypes["NonNegativeIntFilter"] | undefined | null> | undefined | null,	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,	cursor?: string | undefined | null,	limit?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["UserWorkspaceNodePaginated"]],
	// @ts-nocheck
/** Tokens of external accounts of the user. And app tokens for authorizing request to Vectary. */
	tokens?:ResolverInputTypes["TokenNode"],
	// @ts-nocheck
/** Tells if the given user is a CIAM user. */
	isCiamUser?:boolean | `@${string}`,
	// @ts-nocheck
/** Email invitations assigned to the user. */
	emailInvitations?:ResolverInputTypes["EmailInvitationNode"],
	permission?:boolean | `@${string}`,
	rolePermissions?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["NonNegativeIntFilter"]: {
	filter: ResolverInputTypes["SortableFilterEnum"],
	not?: boolean | undefined | null,
	value: ResolverInputTypes["NonNegativeInt"]
};
	// @ts-nocheck
/** Resource with information about a user's tokens. */
["TokenNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TokenTypeColumnEnum"]:TokenTypeColumnEnum;
	// @ts-nocheck
/** An email invitation resource of the Vectary platform. */
["EmailInvitationNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Email of the invitation. */
	email?:boolean | `@${string}`,
	permission?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	workspace?:ResolverInputTypes["WorkspaceNode"],
		__typename?: boolean | `@${string}`
}>;
	["MemberPermissionEnum"]:MemberPermissionEnum;
	["EmailInvitationStatusEnum"]:EmailInvitationStatusEnum;
	["InvitationNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["InvitationNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** An invitation resource of the Vectary platform. */
["InvitationNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	permission?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	workspace?:ResolverInputTypes["WorkspaceNode"],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["MemberPermissionFilter"]: {
	filter: ResolverInputTypes["TextFilterEnum"],
	not?: boolean | undefined | null,
	value: ResolverInputTypes["MemberPermissionEnum"]
};
	["EmailInvitationNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["EmailInvitationNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["EmailInvitationStatusFilter"]: {
	filter: ResolverInputTypes["TextFilterEnum"],
	not?: boolean | undefined | null,
	value: ResolverInputTypes["EmailInvitationStatusEnum"]
};
	["PermissionEnum"]:PermissionEnum;
	["PermissionsUserNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["PermissionsUserNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["UserRoleFilter"]: {
	filter: ResolverInputTypes["TextFilterEnum"],
	not?: boolean | undefined | null,
	value: ResolverInputTypes["UserRoleEnum"]
};
	["PermissionsWorkspaceNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["PermissionsWorkspaceNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FolderNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["FolderNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A folder resource of the Vectary platform. */
["FolderNodeBase"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	isHomeFolder?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FolderFiltersArgsWithLists"]: {
	id?: ResolverInputTypes["StringListFilter"] | undefined | null,
	name?: ResolverInputTypes["StringListFilter"] | undefined | null,
	isHomeFolder?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringListFilter"] | undefined | null,
	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null
};
	// @ts-nocheck
/** [server_value] is [not?] in [values] */
["StringListFilter"]: {
	not?: boolean | undefined | null,
	values: Array<string | undefined | null>
};
	["QueryScopeEnum"]:QueryScopeEnum;
	// @ts-nocheck
/** A custom domain resource of the Vectary platform. */
["CustomDomainNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Hostname of the domain. */
	hostname?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["ProjectNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectFiltersArgsWithLists"]: {
	id?: ResolverInputTypes["StringListFilter"] | undefined | null,
	name?: ResolverInputTypes["StringListFilter"] | undefined | null,
	description?: ResolverInputTypes["StringListFilter"] | undefined | null,
	keywords?: Array<string | undefined | null> | undefined | null,
	folderId?: ResolverInputTypes["StringListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringListFilter"] | undefined | null,
	readonly?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	archived?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	updatedAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,
	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null
};
	// @ts-nocheck
/** A project resource of the Vectary platform. */
["ProjectStateNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. It is a hash of the state object itself. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** ID of the project this state belong to. */
	projectId?:boolean | `@${string}`,
	// @ts-nocheck
/** Object containing a state configuration. */
	state?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A nsProject resource of the Vectary platform. */
["NSProjectNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the nsProject written by a user. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	keywords?:boolean | `@${string}`,
	binaries?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?:boolean | `@${string}`,
	folder?:ResolverInputTypes["FolderNode"],
		__typename?: boolean | `@${string}`
}>;
	["NSProjectNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["NSProjectNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A shared project resource of the Vectary platform. */
["SharedProjectNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the shared project written by a user. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?:boolean | `@${string}`,
	files?:boolean | `@${string}`,
	keywords?:boolean | `@${string}`,
	scene?:boolean | `@${string}`,
	credits?:boolean | `@${string}`,
	imports?:boolean | `@${string}`,
	settings?:boolean | `@${string}`,
	studioVersion?:boolean | `@${string}`,
	nodes?:ResolverInputTypes["NodeNode"],
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A node resource of the Vectary platform. */
["NodeNodeBase"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name chosen by a user. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?:boolean | `@${string}`,
	category?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
	binaries?:boolean | `@${string}`,
	files?:boolean | `@${string}`,
	libraryItem?:boolean | `@${string}`,
	forkedFromId?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NodeFiltersArgsWithLists"]: {
	id?: ResolverInputTypes["StringListFilter"] | undefined | null,
	name?: ResolverInputTypes["StringListFilter"] | undefined | null,
	description?: ResolverInputTypes["StringListFilter"] | undefined | null,
	category?: ResolverInputTypes["NodeCategoryListFilter"] | undefined | null,
	projectId?: ResolverInputTypes["StringListFilter"] | undefined | null,
	libraryItem?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	createdAt?: Array<ResolverInputTypes["DateTimeFilter"] | undefined | null> | undefined | null,
	folderId?: ResolverInputTypes["StringListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringListFilter"] | undefined | null,
	inArchivedProject?: ResolverInputTypes["BooleanFilter"] | undefined | null
};
	// @ts-nocheck
/** [server_value] is [not?] in [values] */
["NodeCategoryListFilter"]: {
	not?: boolean | undefined | null,
	values: Array<ResolverInputTypes["NodeCategoryEnum"] | undefined | null>
};
	["UserEmailDetailsNodePaginated"]: AliasType<{
	items?:ResolverInputTypes["UserEmailDetailsNode"],
	cursor?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A role permission resource type. */
["RolePermissionNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name of the role permission. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Display name of the role permission. */
	displayName?:boolean | `@${string}`,
	// @ts-nocheck
/** Description of the role permission. */
	description?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A member role resource of the Vectary platform. */
["MemberRoleType"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Name of the member role. */
	name?:boolean | `@${string}`,
	// @ts-nocheck
/** Display name of the member role. */
	displayName?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** A workspace role permission resource of the Vectary platform. */
["WorkspaceRolePermissionType"]: AliasType<{
	// @ts-nocheck
/** ID of the workspace. */
	workspaceId?:boolean | `@${string}`,
	// @ts-nocheck
/** Member role. */
	memberRole?:boolean | `@${string}`,
	// @ts-nocheck
/** Role permission. */
	rolePermission?:boolean | `@${string}`,
	// @ts-nocheck
/** Tells if the given permission is allowed or not for the role. */
	isAllowed?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createPermissionsUser?: [{	id: ResolverInputTypes["NonNegativeInt"],	name: string,	role?: ResolverInputTypes["UserRoleEnum"] | undefined | null,	workspaceLimit?: ResolverInputTypes["NonNegativeInt"] | undefined | null,	internalNote?: string | undefined | null},ResolverInputTypes["PermissionsUserNode"]],
deletePermissionsUser?: [{	id: ResolverInputTypes["NonNegativeInt"]},boolean | `@${string}`],
updatePermissionsUser?: [{	id: ResolverInputTypes["NonNegativeInt"],	name?: string | undefined | null,	role?: ResolverInputTypes["UserRoleEnum"] | undefined | null,	workspaceLimit?: ResolverInputTypes["NonNegativeInt"] | undefined | null,	internalNote?: string | undefined | null},ResolverInputTypes["PermissionsUserNode"]],
createPermissionsWorkspace?: [{	id: ResolverInputTypes["NonNegativeInt"],	name: string,	properties?: ResolverInputTypes["JSONObject"] | undefined | null,	internalNote?: string | undefined | null},ResolverInputTypes["PermissionsWorkspaceNode"]],
deletePermissionsWorkspace?: [{	id: ResolverInputTypes["NonNegativeInt"]},boolean | `@${string}`],
updatePermissionsWorkspace?: [{	id: ResolverInputTypes["NonNegativeInt"],	name?: string | undefined | null,	properties?: ResolverInputTypes["JSONObject"] | undefined | null,	internalNote?: string | undefined | null},ResolverInputTypes["PermissionsWorkspaceNode"]],
deleteUser?: [{	id: ResolverInputTypes["UUID"]},boolean | `@${string}`],
updateUser?: [{	id: ResolverInputTypes["UUID"],	username?: string | undefined | null,	firstName?: string | undefined | null,	lastName?: string | undefined | null,	biography?: string | undefined | null,	picture?: string | undefined | null,	questionnaire?: ResolverInputTypes["QuestionnaireInputObjectType"] | undefined | null,	permissionsUserId?: ResolverInputTypes["NonNegativeInt"] | undefined | null,	trialUsed?: boolean | undefined | null,	internalNotes?: string | undefined | null,	proUsed?: boolean | undefined | null},ResolverInputTypes["UserNode"]],
updateUserEmailDetails?: [{	email?: string | undefined | null,	newsletter?: boolean | undefined | null,	notifyComments?: string | undefined | null,	undeliverable?: boolean | undefined | null,	userId: ResolverInputTypes["UUID"],	verificationToken?: ResolverInputTypes["UUID"] | undefined | null},ResolverInputTypes["UserEmailDetailsNode"]],
updateUserLocationDetails?: [{	userId: ResolverInputTypes["UUID"],	location?: string | undefined | null,	geo?: ResolverInputTypes["JSONObject"] | undefined | null},ResolverInputTypes["UserLocationDetailsNode"]],
acceptGdprAndTou?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["UserNode"]],
addEmailAuthenticationMethod?: [{	userId: ResolverInputTypes["UUID"]},boolean | `@${string}`],
createWorkspace?: [{	id?: ResolverInputTypes["UUID"] | undefined | null,	name?: string | undefined | null,	picture?: string | undefined | null,	ownerId?: ResolverInputTypes["UUID"] | undefined | null,	permissionsWorkspaceId?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["WorkspaceNode"]],
deleteWorkspace?: [{	id: ResolverInputTypes["UUID"]},boolean | `@${string}`],
updateWorkspace?: [{	id: ResolverInputTypes["UUID"],	name?: string | undefined | null,	picture?: string | undefined | null,	ownerId?: ResolverInputTypes["UUID"] | undefined | null,	permissionsWorkspaceId?: ResolverInputTypes["NonNegativeInt"] | undefined | null,	overwrite?: ResolverInputTypes["JSONObject"] | undefined | null,	customDomainId?: ResolverInputTypes["UUID"] | undefined | null,	ordering?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["WorkspaceNode"]],
addMember?: [{	workspaceId?: ResolverInputTypes["UUID"] | undefined | null,	userId?: ResolverInputTypes["UUID"] | undefined | null},boolean | `@${string}`],
updateMember?: [{	workspaceId: ResolverInputTypes["UUID"],	userId: ResolverInputTypes["UUID"],	permission: ResolverInputTypes["MemberPermissionEnum"]},boolean | `@${string}`],
removeMember?: [{	workspaceId: ResolverInputTypes["UUID"],	userId: ResolverInputTypes["UUID"]},boolean | `@${string}`],
cancelPaddleSubscription?: [{	workspaceId: ResolverInputTypes["UUID"]},boolean | `@${string}`],
acceptInvitation?: [{	invitationId?: ResolverInputTypes["UUID"] | undefined | null},ResolverInputTypes["InvitationNode"]],
createInvitation?: [{	id?: ResolverInputTypes["UUID"] | undefined | null,	permission?: ResolverInputTypes["MemberPermissionEnum"] | undefined | null,	workspaceId: ResolverInputTypes["UUID"]},ResolverInputTypes["InvitationNode"]],
deleteInvitation?: [{	id: ResolverInputTypes["UUID"]},boolean | `@${string}`],
updateInvitation?: [{	id: ResolverInputTypes["UUID"],	permission?: ResolverInputTypes["MemberPermissionEnum"] | undefined | null},ResolverInputTypes["InvitationNode"]],
createEmailInvitation?: [{	items?: Array<ResolverInputTypes["EmailInvitationInput"] | undefined | null> | undefined | null},ResolverInputTypes["EmailInvitationPossibleErrorNode"]],
deleteEmailInvitation?: [{	id: ResolverInputTypes["UUID"]},boolean | `@${string}`],
updateEmailInvitation?: [{	id: ResolverInputTypes["UUID"],	permission?: ResolverInputTypes["MemberPermissionEnum"] | undefined | null,	status?: ResolverInputTypes["EmailInvitationStatusEnum"] | undefined | null},ResolverInputTypes["EmailInvitationNode"]],
acceptEmailInvitation?: [{	invitationId?: ResolverInputTypes["UUID"] | undefined | null},ResolverInputTypes["EmailInvitationNode"]],
reactivateEmailInvitation?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["EmailInvitationNode"]],
createFolder?: [{	id?: ResolverInputTypes["UUID"] | undefined | null,	name?: string | undefined | null,	workspaceId: ResolverInputTypes["UUID"]},ResolverInputTypes["FolderNode"]],
deleteFolder?: [{	id: ResolverInputTypes["UUID"]},boolean | `@${string}`],
updateFolder?: [{	id: ResolverInputTypes["UUID"],	name?: string | undefined | null,	workspaceId?: ResolverInputTypes["UUID"] | undefined | null,	ordering?: ResolverInputTypes["NonNegativeInt"] | undefined | null},ResolverInputTypes["FolderNode"]],
createCustomDomain?: [{	hostname?: string | undefined | null},ResolverInputTypes["CustomDomainNode"]],
deleteCustomDomain?: [{	id: ResolverInputTypes["UUID"]},boolean | `@${string}`],
createProject?: [{	id?: ResolverInputTypes["UUID"] | undefined | null,	name?: string | undefined | null,	description?: string | undefined | null,	picture?: ResolverInputTypes["SHA1HashOrUpload"] | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	scene: ResolverInputTypes["JSONObject"],	view?: ResolverInputTypes["JSONObject"] | undefined | null,	credits?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,	published?: Array<string | undefined | null> | undefined | null,	imports?: Array<string | undefined | null> | undefined | null,	settings?: ResolverInputTypes["JSONObject"] | undefined | null,	studioVersion?: ResolverInputTypes["SemVer"] | undefined | null,	forkedFromId?: string | undefined | null,	folderId?: ResolverInputTypes["UUID"] | undefined | null,	readonly?: boolean | undefined | null,	deleted?: boolean | undefined | null},ResolverInputTypes["ProjectNode"]],
updateProject?: [{	id: ResolverInputTypes["UUID"],	name?: string | undefined | null,	description?: string | undefined | null,	picture?: ResolverInputTypes["SHA1HashOrUpload"] | undefined | null,	files?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	scene?: ResolverInputTypes["JSONObject"] | undefined | null,	view?: ResolverInputTypes["JSONObject"] | undefined | null,	credits?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,	published?: Array<string | undefined | null> | undefined | null,	imports?: Array<string | undefined | null> | undefined | null,	settings?: ResolverInputTypes["JSONObject"] | undefined | null,	studioVersion?: ResolverInputTypes["SemVer"] | undefined | null,	forkedFromId?: string | undefined | null,	folderId?: ResolverInputTypes["UUID"] | undefined | null,	ordering?: ResolverInputTypes["NonNegativeInt"] | undefined | null,	readonly?: boolean | undefined | null,	deleted?: boolean | undefined | null},ResolverInputTypes["ProjectNode"]],
createOrUpdateProject?: [{	id?: ResolverInputTypes["UUID"] | undefined | null,	name?: string | undefined | null,	description?: string | undefined | null,	picture?: ResolverInputTypes["SHA1HashOrUpload"] | undefined | null,	files?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	scene: ResolverInputTypes["JSONObject"],	view?: ResolverInputTypes["JSONObject"] | undefined | null,	credits?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,	published?: Array<string | undefined | null> | undefined | null,	imports?: Array<string | undefined | null> | undefined | null,	settings?: ResolverInputTypes["JSONObject"] | undefined | null,	studioVersion?: ResolverInputTypes["SemVer"] | undefined | null,	forkedFromId?: string | undefined | null,	folderId?: ResolverInputTypes["UUID"] | undefined | null,	ordering?: ResolverInputTypes["NonNegativeInt"] | undefined | null,	readonly?: boolean | undefined | null,	deleted?: boolean | undefined | null,	activeNodes?: Array<ResolverInputTypes["UUID"] | undefined | null> | undefined | null,	nodes?: Array<ResolverInputTypes["NodeTypeBaseInput"] | undefined | null> | undefined | null},ResolverInputTypes["ProjectNode"]],
deleteProject?: [{	id: ResolverInputTypes["UUID"]},boolean | `@${string}`],
cloneProject?: [{	id: ResolverInputTypes["UUID"],	newId: ResolverInputTypes["UUID"],	workspaceId: ResolverInputTypes["UUID"]},ResolverInputTypes["OperationResult"]],
copyProject?: [{	id: ResolverInputTypes["UUID"],	newId: ResolverInputTypes["UUID"],	folderId: ResolverInputTypes["UUID"],	workspaceId: ResolverInputTypes["UUID"]},ResolverInputTypes["OperationResult"]],
createProjectState?: [{	id?: string | undefined | null,	projectId: ResolverInputTypes["UUID"],	state?: ResolverInputTypes["JSONObject"] | undefined | null},ResolverInputTypes["ProjectStateNode"]],
createNSProject?: [{	id?: ResolverInputTypes["UUID"] | undefined | null,	name?: string | undefined | null,	description?: string | undefined | null,	picture?: ResolverInputTypes["SHA1HashOrUpload"] | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	binaries?: Array<ResolverInputTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null,	folderId: ResolverInputTypes["UUID"]},ResolverInputTypes["NSProjectNode"]],
updateNSProject?: [{	id: ResolverInputTypes["UUID"],	name?: string | undefined | null,	description?: string | undefined | null,	picture?: ResolverInputTypes["SHA1HashOrUpload"] | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	binaries?: Array<ResolverInputTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null,	folderId?: ResolverInputTypes["UUID"] | undefined | null},ResolverInputTypes["NSProjectNode"]],
deleteNSProject?: [{	id: ResolverInputTypes["UUID"]},boolean | `@${string}`],
createSharedProject?: [{	id?: ResolverInputTypes["UUID"] | undefined | null,	projectId?: string | undefined | null,	name?: string | undefined | null,	description?: string | undefined | null,	picture?: ResolverInputTypes["SHA1HashOrUpload"] | undefined | null,	keywords?: Array<string | undefined | null> | undefined | null,	scene: ResolverInputTypes["JSONObject"],	credits?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,	imports?: Array<string | undefined | null> | undefined | null,	settings?: ResolverInputTypes["JSONObject"] | undefined | null,	studioVersion?: ResolverInputTypes["SemVer"] | undefined | null,	nodes?: Array<ResolverInputTypes["SharedProjectNodeNode"] | undefined | null> | undefined | null},ResolverInputTypes["ProjectNode"]],
createNode?: [{	id?: ResolverInputTypes["UUID"] | undefined | null,	name?: string | undefined | null,	description?: string | undefined | null,	category: ResolverInputTypes["NodeCategoryEnum"],	data: ResolverInputTypes["JSONObject"],	binaries?: Array<ResolverInputTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null,	files?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,	libraryItem?: boolean | undefined | null,	forkedFromId?: string | undefined | null,	projectId: ResolverInputTypes["UUID"]},ResolverInputTypes["NodeNode"]],
createOrUpdateNode?: [{	id: ResolverInputTypes["UUID"],	name?: string | undefined | null,	description?: string | undefined | null,	category?: ResolverInputTypes["NodeCategoryEnum"] | undefined | null,	data?: ResolverInputTypes["JSONObject"] | undefined | null,	binaries?: Array<ResolverInputTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null,	files?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,	libraryItem?: boolean | undefined | null,	forkedFromId?: string | undefined | null,	projectId?: ResolverInputTypes["UUID"] | undefined | null},ResolverInputTypes["NodeNode"]],
deleteNode?: [{	id: ResolverInputTypes["UUID"]},boolean | `@${string}`],
updateNode?: [{	id: ResolverInputTypes["UUID"],	name?: string | undefined | null,	description?: string | undefined | null,	category?: ResolverInputTypes["NodeCategoryEnum"] | undefined | null,	data?: ResolverInputTypes["JSONObject"] | undefined | null,	binaries?: Array<ResolverInputTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null,	files?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,	libraryItem?: boolean | undefined | null,	forkedFromId?: string | undefined | null,	projectId?: ResolverInputTypes["UUID"] | undefined | null},ResolverInputTypes["NodeNode"]],
createCommentMessage?: [{	projectId?: ResolverInputTypes["UUID"] | undefined | null,	nodeId?: ResolverInputTypes["UUID"] | undefined | null,	commentId?: ResolverInputTypes["UUID"] | undefined | null,	msg?: string | undefined | null,	user?: ResolverInputTypes["JSONObject"] | undefined | null,	clickedPosition?: Array<number | undefined | null> | undefined | null,	targetOrientation?: Array<number | undefined | null> | undefined | null},ResolverInputTypes["NodeNode"]],
updateCommentMessage?: [{	projectId?: ResolverInputTypes["UUID"] | undefined | null,	nodeId?: ResolverInputTypes["UUID"] | undefined | null,	commentId?: ResolverInputTypes["UUID"] | undefined | null,	messageId?: ResolverInputTypes["UUID"] | undefined | null,	msg?: string | undefined | null,	user?: ResolverInputTypes["JSONObject"] | undefined | null,	clickedPosition?: Array<number | undefined | null> | undefined | null,	targetOrientation?: Array<number | undefined | null> | undefined | null},ResolverInputTypes["NodeNode"]],
deleteCommentMessage?: [{	projectId?: ResolverInputTypes["UUID"] | undefined | null,	nodeId?: ResolverInputTypes["UUID"] | undefined | null,	commentId?: ResolverInputTypes["UUID"] | undefined | null,	messageId?: ResolverInputTypes["UUID"] | undefined | null},ResolverInputTypes["NodeNode"]],
uploadBinary?: [{	binary?: ResolverInputTypes["SHA1HashOrUpload"] | undefined | null},boolean | `@${string}`],
createToken?: [{	name: string,	type: ResolverInputTypes["TokenTypeColumnEnum"],	token: string},ResolverInputTypes["TokenNode"]],
createApiToken?: [{	name: string},ResolverInputTypes["TokenNode"]],
updateToken?: [{	id: ResolverInputTypes["UUID"],	name?: string | undefined | null,	type?: ResolverInputTypes["TokenTypeColumnEnum"] | undefined | null,	token?: string | undefined | null},ResolverInputTypes["TokenNode"]],
deleteToken?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["TokenNode"]],
createRolePermission?: [{	id?: ResolverInputTypes["NonNegativeInt"] | undefined | null,	name?: string | undefined | null,	displayName?: string | undefined | null,	description?: string | undefined | null},ResolverInputTypes["RolePermissionNode"]],
deleteRolePermission?: [{	id: ResolverInputTypes["NonNegativeInt"]},boolean | `@${string}`],
createMemberRole?: [{	id?: ResolverInputTypes["NonNegativeInt"] | undefined | null,	name: string,	displayName: string},ResolverInputTypes["MemberRoleType"]],
deleteMemberRole?: [{	id: ResolverInputTypes["NonNegativeInt"]},boolean | `@${string}`],
updateWorkspaceRolePermission?: [{	workspaceId: ResolverInputTypes["UUID"],	memberRole: string,	rolePermission: string,	isAllowed: boolean},ResolverInputTypes["WorkspaceRolePermissionType"]],
deleteWorkspaceRolePermission?: [{	workspaceId: ResolverInputTypes["UUID"],	memberRoleId: number,	rolePermissionId: number},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	// @ts-nocheck
/** Questionnaire mutation object */
["QuestionnaireInputObjectType"]: {
	occupation?: ResolverInputTypes["QuestionnaireDataInputObjectType"] | undefined | null,
	reasonToLeave?: ResolverInputTypes["QuestionnaireDataInputObjectType"] | undefined | null
};
	// @ts-nocheck
/** Questionnaire mutation object */
["QuestionnaireDataInputObjectType"]: {
	value: string,
	workspaceId?: string | undefined | null,
	planName?: string | undefined | null,
	category?: string | undefined | null,
	subscribedForDays?: ResolverInputTypes["NonNegativeFloat"] | undefined | null
};
	// @ts-nocheck
/** An email invitation resource of the Vectary platform. It includes error if any. */
["EmailInvitationPossibleErrorNode"]: AliasType<{
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?:boolean | `@${string}`,
	// @ts-nocheck
/** Email of the invitation. */
	email?:boolean | `@${string}`,
	permission?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?:boolean | `@${string}`,
	workspace?:ResolverInputTypes["WorkspaceNode"],
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EmailInvitationInput"]: {
	email?: string | undefined | null,
	permission?: ResolverInputTypes["MemberPermissionEnum"] | undefined | null,
	status?: ResolverInputTypes["EmailInvitationStatusEnum"] | undefined | null,
	workspaceId: ResolverInputTypes["UUID"]
};
	// @ts-nocheck
/** This scalar accepts either a SHA-1 hash, which is a 40 character long string or a file upload. See the [GraphQL multipart request specification](https://github.com/jaydenseric/graphql-multipart-request-spec) for more information. */
["SHA1HashOrUpload"]:unknown;
	["NodeTypeBaseInput"]: {
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ResolverInputTypes["UUID"] | undefined | null,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined | null,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?: string | undefined | null,
	category?: ResolverInputTypes["NodeCategoryEnum"] | undefined | null,
	data?: ResolverInputTypes["JSONObject"] | undefined | null,
	binaries?: Array<string | undefined | null> | undefined | null,
	files?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,
	libraryItem?: boolean | undefined | null,
	forkedFromId?: string | undefined | null,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	projectId?: string | undefined | null
};
	// @ts-nocheck
/** The result of the operation. */
["OperationResult"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OperationResultType"]:OperationResultType;
	["SharedProjectNodeNode"]: {
	id: ResolverInputTypes["UUID"],
	name?: string | undefined | null,
	description?: string | undefined | null,
	category: ResolverInputTypes["NodeCategoryEnum"],
	data: ResolverInputTypes["JSONObject"],
	binaries?: Array<ResolverInputTypes["SHA1HashOrUpload"] | undefined | null> | undefined | null,
	files?: Array<ResolverInputTypes["JSONObject"] | undefined | null> | undefined | null,
	libraryItem?: boolean | undefined | null,
	forkedFromId?: string | undefined | null
};
	["Subscription"]: AliasType<{
	countDown?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Query"]: {
		permissionsUser?: ModelTypes["PermissionsUserNode"] | undefined,
	allPermissionsUsers?: ModelTypes["PermissionsUserNodePaginated"] | undefined,
	permissionsWorkspace?: ModelTypes["PermissionsWorkspaceNode"] | undefined,
	allPermissionsWorkspaces?: ModelTypes["PermissionsWorkspaceNodePaginated"] | undefined,
	user?: ModelTypes["UserNode"] | undefined,
	allUsers?: ModelTypes["UserNodePaginated"] | undefined,
	userByEmailOrHash?: ModelTypes["UserEmailDetailsNode"] | undefined,
	workspace?: ModelTypes["WorkspaceNode"] | undefined,
	allWorkspaces?: ModelTypes["WorkspaceNodePaginated"] | undefined,
	invitation?: ModelTypes["InvitationNode"] | undefined,
	allInvitations?: ModelTypes["InvitationNodePaginated"] | undefined,
	emailInvitation?: ModelTypes["EmailInvitationNode"] | undefined,
	allEmailInvitations?: ModelTypes["EmailInvitationNodePaginated"] | undefined,
	folder?: ModelTypes["FolderNode"] | undefined,
	allFoldersPaginated?: ModelTypes["FolderNodePaginated"] | undefined,
	allFolders?: Array<ModelTypes["FolderNodeBase"] | undefined> | undefined,
	allCustomDomains?: Array<ModelTypes["CustomDomainNode"] | undefined> | undefined,
	project?: ModelTypes["ProjectNode"] | undefined,
	allProjectsPaginated?: ModelTypes["ProjectNodePaginated"] | undefined,
	allProjects?: Array<ModelTypes["ProjectNode"] | undefined> | undefined,
	allProjectsCount?: number | undefined,
	projectState?: ModelTypes["ProjectStateNode"] | undefined,
	nsProject?: ModelTypes["NSProjectNode"] | undefined,
	allNSProjects?: ModelTypes["NSProjectNodePaginated"] | undefined,
	sharedProject?: ModelTypes["SharedProjectNode"] | undefined,
	node?: ModelTypes["NodeNode"] | undefined,
	allNodesPaginated?: ModelTypes["NodeNodePaginated"] | undefined,
	allNodes?: Array<ModelTypes["NodeNodeBase"] | undefined> | undefined,
	allNodesCount?: number | undefined,
	token?: ModelTypes["TokenNode"] | undefined,
	allTokens?: Array<ModelTypes["TokenNode"] | undefined> | undefined,
	emails?: ModelTypes["UserEmailDetailsNodePaginated"] | undefined,
	rolePermission?: ModelTypes["RolePermissionNode"] | undefined,
	allRolePermissions?: Array<ModelTypes["RolePermissionNode"] | undefined> | undefined,
	memberRole?: ModelTypes["MemberRoleType"] | undefined,
	allMemberRoles?: Array<ModelTypes["MemberRoleType"] | undefined> | undefined,
	workspaceRolePermission?: ModelTypes["WorkspaceRolePermissionType"] | undefined,
	workspacePermissions?: ModelTypes["JSONObject"] | undefined
};
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a user resource. */
["PermissionsUserNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["NonNegativeInt"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	role?: ModelTypes["UserRoleEnum"] | undefined,
	workspaceLimit?: ModelTypes["NonNegativeInt"] | undefined,
	internalNote?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	users?: ModelTypes["UserNodePaginated"] | undefined
};
	// @ts-nocheck
/** Integers that will have a value of 0 or more. */
["NonNegativeInt"]:any;
	["UserRoleEnum"]:UserRoleEnum;
	// @ts-nocheck
/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]:any;
	["UserNodePaginated"]: {
		items?: Array<ModelTypes["UserNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A user resource of the Vectary platform. Contains the basic information of an account and it is directly related with certain collections of details of the account and the workspaces related to it */
["UserNode"]: {
		// @ts-nocheck
/** Unique identifier of a user. It is a UUIDv4 but at some point it will start accepting any string. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Nickname chosen by the user. */
	username?: string | undefined,
	// @ts-nocheck
/** Firstname of the user. */
	firstName?: string | undefined,
	// @ts-nocheck
/** Lastname the user. */
	lastName?: string | undefined,
	// @ts-nocheck
/** Flavour text about the user written by them. */
	biography?: string | undefined,
	// @ts-nocheck
/** The location of the picture used for the profile of the user. */
	picture?: string | undefined,
	// @ts-nocheck
/** Metadata we have collected from the user via questionnaires. */
	questionnaire?: ModelTypes["JSONObject"] | undefined,
	// @ts-nocheck
/** Version of the General Data Protection Regulation accepted by the user. */
	gdpr?: ModelTypes["NonNegativeFloat"] | undefined,
	// @ts-nocheck
/** Whether the General Data Protection Regulation accepted by the user is the current one or not. */
	isGdprCurrent?: boolean | undefined,
	// @ts-nocheck
/** Version of the Terms Of Use accepted by the user. */
	tou?: ModelTypes["NonNegativeFloat"] | undefined,
	// @ts-nocheck
/** Whether the Terms Of Use accepted by the user is the current one or not. */
	isTouCurrent?: boolean | undefined,
	// @ts-nocheck
/** Whether the user has used the one time trial. */
	trialUsed?: boolean | undefined,
	// @ts-nocheck
/** Whether the user has ever paid for a PRO plan. */
	proUsed?: boolean | undefined,
	// @ts-nocheck
/** Internal notes for a given user. Available only for Administrators. */
	internalNotes?: string | undefined,
	// @ts-nocheck
/** Time of creation of the user. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Email configuration for the user. */
	emailDetails?: ModelTypes["UserEmailDetailsNode"] | undefined,
	// @ts-nocheck
/** Location information about the user. */
	locationDetails?: ModelTypes["UserLocationDetailsNode"] | undefined,
	// @ts-nocheck
/** Access information about the user. Available only for Administrators. */
	accessDetails?: ModelTypes["UserAccessDetailsNode"] | undefined,
	// @ts-nocheck
/** The set of permissions assigned to the user. */
	permissions?: ModelTypes["PermissionsUserNode"] | undefined,
	// @ts-nocheck
/** Workspaces the user has access to. They can be filtered and paginated. */
	workspaces?: ModelTypes["UserWorkspaceNodePaginated"] | undefined,
	// @ts-nocheck
/** Tokens of external accounts of the user. And app tokens for authorizing request to Vectary. */
	tokens?: Array<ModelTypes["TokenNode"] | undefined> | undefined,
	// @ts-nocheck
/** Tells if the given user is a CIAM user. */
	isCiamUser?: boolean | undefined,
	// @ts-nocheck
/** Email invitations assigned to the user. */
	emailInvitations?: Array<ModelTypes["EmailInvitationNode"] | undefined> | undefined
};
	// @ts-nocheck
/** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
["UUID"]:any;
	// @ts-nocheck
/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSONObject"]:any;
	// @ts-nocheck
/** Floats that will have a value of 0 or more. */
["NonNegativeFloat"]:any;
	// @ts-nocheck
/** Resource with information about a user's email. */
["UserEmailDetailsNode"]: {
		id?: ModelTypes["UUID"] | undefined,
	email?: string | undefined,
	newsletter?: boolean | undefined,
	notifyComments?: string | undefined,
	undeliverable?: boolean | undefined,
	verificationToken?: ModelTypes["UUID"] | undefined
};
	// @ts-nocheck
/** Resource with information about a user's location. */
["UserLocationDetailsNode"]: {
		location?: string | undefined,
	geo?: ModelTypes["JSONObject"] | undefined
};
	// @ts-nocheck
/** Resource with information about a user's access detail. */
["UserAccessDetailsNode"]: {
		accessToken?: ModelTypes["UUID"] | undefined,
	accessTokenExpiration?: string | undefined,
	refreshToken?: ModelTypes["UUID"] | undefined,
	refreshTokenExpiration?: string | undefined,
	passwordIdentifier?: string | undefined,
	passwordResetToken?: ModelTypes["UUID"] | undefined,
	facebookIdentifier?: string | undefined,
	googleIdentifier?: string | undefined,
	ciamIdentifier?: string | undefined
};
	["UserWorkspaceNodePaginated"]: {
		items?: Array<ModelTypes["UserWorkspaceNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A workspace resource of the Vectary platform.
Includes extra properties about the relation with the user. */
["UserWorkspaceNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	// @ts-nocheck
/** Collection of admin-defined properties to override some kind of behaviour. */
	overwrite?: ModelTypes["JSONObject"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	owner?: ModelTypes["UserNode"] | undefined,
	customDomain?: string | undefined,
	workspaceRolePermissions: Array<string | undefined>,
	permissions?: ModelTypes["PermissionsWorkspaceNode"] | undefined,
	paddleDetails?: ModelTypes["PaddleDetailsNode"] | undefined,
	folders?: ModelTypes["WorkspaceFolderNodePaginated"] | undefined,
	members?: Array<ModelTypes["WorkspaceMemberNode"] | undefined> | undefined,
	invitations?: ModelTypes["InvitationNodePaginated"] | undefined,
	emailInvitations?: ModelTypes["EmailInvitationNodePaginated"] | undefined,
	permission?: ModelTypes["PermissionEnum"] | undefined,
	ordering?: ModelTypes["NonNegativeInt"] | undefined
};
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a workspace resource. */
["PermissionsWorkspaceNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["NonNegativeInt"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	properties?: ModelTypes["JSONObject"] | undefined,
	internalNote?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	workspaces?: ModelTypes["WorkspaceNodePaginated"] | undefined
};
	["WorkspaceNodePaginated"]: {
		items?: Array<ModelTypes["WorkspaceNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A workspace resource of the Vectary platform. */
["WorkspaceNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	// @ts-nocheck
/** Collection of admin-defined properties to override some kind of behaviour. */
	overwrite?: ModelTypes["JSONObject"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	owner?: ModelTypes["UserNode"] | undefined,
	customDomain?: string | undefined,
	workspaceRolePermissions: Array<string | undefined>,
	permissions?: ModelTypes["PermissionsWorkspaceNode"] | undefined,
	paddleDetails?: ModelTypes["PaddleDetailsNode"] | undefined,
	folders?: ModelTypes["WorkspaceFolderNodePaginated"] | undefined,
	members?: Array<ModelTypes["WorkspaceMemberNode"] | undefined> | undefined,
	invitations?: ModelTypes["InvitationNodePaginated"] | undefined,
	emailInvitations?: ModelTypes["EmailInvitationNodePaginated"] | undefined
};
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a workspace resource. */
["PaddleDetailsNode"]: {
		customerId?: ModelTypes["NonNegativeInt"] | undefined,
	subscriptionId?: ModelTypes["NonNegativeInt"] | undefined,
	linkedSubscriptionId?: ModelTypes["NonNegativeInt"] | undefined,
	status?: string | undefined,
	price?: ModelTypes["NonNegativeFloat"] | undefined,
	currency?: ModelTypes["Currency"] | undefined,
	recurringInterval?: ModelTypes["Duration"] | undefined,
	nextPaymentAmount?: ModelTypes["NonNegativeFloat"] | undefined,
	nextPaymentDate?: ModelTypes["DateTime"] | undefined,
	cancellationEffectiveDate?: ModelTypes["DateTime"] | undefined,
	cancelUrl?: ModelTypes["URL"] | undefined,
	updateUrl?: ModelTypes["URL"] | undefined,
	receipts?: Array<ModelTypes["JSONObject"] | undefined> | undefined,
	latestEventTime?: ModelTypes["DateTime"] | undefined
};
	// @ts-nocheck
/** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
["Currency"]:any;
	// @ts-nocheck
/** 
    A string representing a duration conforming to the ISO8601 standard,
    such as: P1W1DT13H23M34S
    P is the duration designator (for period) placed at the start of the duration representation.
    Y is the year designator that follows the value for the number of years.
    M is the month designator that follows the value for the number of months.
    W is the week designator that follows the value for the number of weeks.
    D is the day designator that follows the value for the number of days.
    T is the time designator that precedes the time components of the representation.
    H is the hour designator that follows the value for the number of hours.
    M is the minute designator that follows the value for the number of minutes.
    S is the second designator that follows the value for the number of seconds.

    Note the time designator, T, that precedes the time value.

    Matches moment.js, Luxon and DateFns implementations
    ,/. is valid for decimal places and +/- is a valid prefix
   */
["Duration"]:any;
	// @ts-nocheck
/** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
["URL"]:any;
	["WorkspaceFolderNodePaginated"]: {
		items?: Array<ModelTypes["WorkspaceFolderNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A folder resource of the Vectary platform.
Includes extra properties about the relation with the workspace. */
["WorkspaceFolderNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	isHomeFolder?: boolean | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceNode"] | undefined,
	projects?: ModelTypes["FolderProjectNodePaginated"] | undefined,
	nsProjects?: ModelTypes["FolderNSProjectNodePaginated"] | undefined,
	ordering?: ModelTypes["NonNegativeInt"] | undefined
};
	["FolderProjectNodePaginated"]: {
		items?: Array<ModelTypes["FolderProjectNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A project resource of the Vectary platform.
Includes extra properties about the relation with the folder. */
["FolderProjectNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the project written by a user. */
	description?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	files?: Array<ModelTypes["File"] | undefined> | undefined,
	keywords?: Array<string | undefined> | undefined,
	scene?: ModelTypes["JSONObject"] | undefined,
	view?: ModelTypes["JSONObject"] | undefined,
	credits?: Array<ModelTypes["JSONObject"] | undefined> | undefined,
	published?: Array<string | undefined> | undefined,
	imports?: Array<string | undefined> | undefined,
	settings?: ModelTypes["JSONObject"] | undefined,
	studioVersion?: ModelTypes["SemVer"] | undefined,
	forkedFromId?: string | undefined,
	readonly?: boolean | undefined,
	deleted?: boolean | undefined,
	folderId?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?: ModelTypes["DateTime"] | undefined,
	folder?: ModelTypes["FolderNode"] | undefined,
	nodes?: ModelTypes["NodeNodePaginated"] | undefined,
	history?: Array<ModelTypes["DateTime"] | undefined> | undefined,
	ordering?: ModelTypes["NonNegativeInt"] | undefined
};
	// @ts-nocheck
/** Uploaded file meta data. */
["File"]: {
		purpose?: string | undefined,
	urls?: Array<string | undefined> | undefined,
	sizes?: Array<ModelTypes["PositiveInt"] | undefined> | undefined,
	status?: Array<string | undefined> | undefined
};
	// @ts-nocheck
/** Integers that will have a value greater than 0. */
["PositiveInt"]:any;
	// @ts-nocheck
/** A field whose value is a Semantic Version: https://semver.org */
["SemVer"]:any;
	// @ts-nocheck
/** A folder resource of the Vectary platform. */
["FolderNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	isHomeFolder?: boolean | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceNode"] | undefined,
	projects?: ModelTypes["FolderProjectNodePaginated"] | undefined,
	nsProjects?: ModelTypes["FolderNSProjectNodePaginated"] | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["StringFilter"]: {
	filter: ModelTypes["TextFilterEnum"],
	not?: boolean | undefined,
	value: string
};
	["TextFilterEnum"]:TextFilterEnum;
	// @ts-nocheck
/** [server_value] is [filter?] [value] */
["BooleanFilter"]: {
	filter: ModelTypes["BooleanFilterEnum"],
	value: boolean
};
	["BooleanFilterEnum"]:BooleanFilterEnum;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["DateTimeFilter"]: {
	filter: ModelTypes["SortableFilterEnum"],
	not?: boolean | undefined,
	value: ModelTypes["DateTime"]
};
	["SortableFilterEnum"]:SortableFilterEnum;
	["ProjectSort"]:ProjectSort;
	["FolderNSProjectNodePaginated"]: {
		items?: Array<ModelTypes["FolderNSProjectNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A nsProject resource of the Vectary platform.
Includes extra properties about the relation with the folder. */
["FolderNSProjectNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the nsProject written by a user. */
	description?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	keywords?: Array<string | undefined> | undefined,
	binaries?: Array<string | undefined> | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?: ModelTypes["DateTime"] | undefined,
	folder?: ModelTypes["FolderNode"] | undefined,
	ordering?: ModelTypes["NonNegativeInt"] | undefined
};
	// @ts-nocheck
/** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
["BigInt"]:any;
	["NSProjectSort"]:NSProjectSort;
	["NodeNodePaginated"]: {
		items?: Array<ModelTypes["NodeNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A node resource of the Vectary platform. */
["NodeNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?: string | undefined,
	category?: ModelTypes["NodeCategoryEnum"] | undefined,
	data?: ModelTypes["JSONObject"] | undefined,
	binaries?: Array<string | undefined> | undefined,
	files?: Array<ModelTypes["JSONObject"] | undefined> | undefined,
	libraryItem?: boolean | undefined,
	forkedFromId?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	projectId?: string | undefined,
	project?: ModelTypes["ProjectNode"] | undefined,
	history?: Array<ModelTypes["DateTime"] | undefined> | undefined
};
	["NodeCategoryEnum"]:NodeCategoryEnum;
	// @ts-nocheck
/** A project resource of the Vectary platform. */
["ProjectNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the project written by a user. */
	description?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	files?: Array<ModelTypes["File"] | undefined> | undefined,
	keywords?: Array<string | undefined> | undefined,
	scene?: ModelTypes["JSONObject"] | undefined,
	view?: ModelTypes["JSONObject"] | undefined,
	credits?: Array<ModelTypes["JSONObject"] | undefined> | undefined,
	published?: Array<string | undefined> | undefined,
	imports?: Array<string | undefined> | undefined,
	settings?: ModelTypes["JSONObject"] | undefined,
	studioVersion?: ModelTypes["SemVer"] | undefined,
	forkedFromId?: string | undefined,
	readonly?: boolean | undefined,
	deleted?: boolean | undefined,
	folderId?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?: ModelTypes["DateTime"] | undefined,
	folder?: ModelTypes["FolderNode"] | undefined,
	nodes?: ModelTypes["NodeNodePaginated"] | undefined,
	history?: Array<ModelTypes["DateTime"] | undefined> | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["NodeCategoryFilter"]: {
	filter: ModelTypes["TextFilterEnum"],
	not?: boolean | undefined,
	value: ModelTypes["NodeCategoryEnum"]
};
	// @ts-nocheck
/** A user resource of the Vectary platform. Contains the basic information of an account and it is directly related with certain collections of details of the account and the workspaces related to it
Includes extra properties about the relation with the workspace. */
["WorkspaceMemberNode"]: {
		// @ts-nocheck
/** Unique identifier of a user. It is a UUIDv4 but at some point it will start accepting any string. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Nickname chosen by the user. */
	username?: string | undefined,
	// @ts-nocheck
/** Firstname of the user. */
	firstName?: string | undefined,
	// @ts-nocheck
/** Lastname the user. */
	lastName?: string | undefined,
	// @ts-nocheck
/** Flavour text about the user written by them. */
	biography?: string | undefined,
	// @ts-nocheck
/** The location of the picture used for the profile of the user. */
	picture?: string | undefined,
	// @ts-nocheck
/** Metadata we have collected from the user via questionnaires. */
	questionnaire?: ModelTypes["JSONObject"] | undefined,
	// @ts-nocheck
/** Version of the General Data Protection Regulation accepted by the user. */
	gdpr?: ModelTypes["NonNegativeFloat"] | undefined,
	// @ts-nocheck
/** Whether the General Data Protection Regulation accepted by the user is the current one or not. */
	isGdprCurrent?: boolean | undefined,
	// @ts-nocheck
/** Version of the Terms Of Use accepted by the user. */
	tou?: ModelTypes["NonNegativeFloat"] | undefined,
	// @ts-nocheck
/** Whether the Terms Of Use accepted by the user is the current one or not. */
	isTouCurrent?: boolean | undefined,
	// @ts-nocheck
/** Whether the user has used the one time trial. */
	trialUsed?: boolean | undefined,
	// @ts-nocheck
/** Whether the user has ever paid for a PRO plan. */
	proUsed?: boolean | undefined,
	// @ts-nocheck
/** Internal notes for a given user. Available only for Administrators. */
	internalNotes?: string | undefined,
	// @ts-nocheck
/** Time of creation of the user. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Email configuration for the user. */
	emailDetails?: ModelTypes["UserEmailDetailsNode"] | undefined,
	// @ts-nocheck
/** Location information about the user. */
	locationDetails?: ModelTypes["UserLocationDetailsNode"] | undefined,
	// @ts-nocheck
/** Access information about the user. Available only for Administrators. */
	accessDetails?: ModelTypes["UserAccessDetailsNode"] | undefined,
	// @ts-nocheck
/** The set of permissions assigned to the user. */
	permissions?: ModelTypes["PermissionsUserNode"] | undefined,
	// @ts-nocheck
/** Workspaces the user has access to. They can be filtered and paginated. */
	workspaces?: ModelTypes["UserWorkspaceNodePaginated"] | undefined,
	// @ts-nocheck
/** Tokens of external accounts of the user. And app tokens for authorizing request to Vectary. */
	tokens?: Array<ModelTypes["TokenNode"] | undefined> | undefined,
	// @ts-nocheck
/** Tells if the given user is a CIAM user. */
	isCiamUser?: boolean | undefined,
	// @ts-nocheck
/** Email invitations assigned to the user. */
	emailInvitations?: Array<ModelTypes["EmailInvitationNode"] | undefined> | undefined,
	permission?: ModelTypes["MemberPermissionEnum"] | undefined,
	rolePermissions?: Array<string | undefined> | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["NonNegativeIntFilter"]: {
	filter: ModelTypes["SortableFilterEnum"],
	not?: boolean | undefined,
	value: ModelTypes["NonNegativeInt"]
};
	// @ts-nocheck
/** Resource with information about a user's tokens. */
["TokenNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	name?: string | undefined,
	type?: ModelTypes["TokenTypeColumnEnum"] | undefined,
	token?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["TokenTypeColumnEnum"]:TokenTypeColumnEnum;
	// @ts-nocheck
/** An email invitation resource of the Vectary platform. */
["EmailInvitationNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Email of the invitation. */
	email?: string | undefined,
	permission?: ModelTypes["MemberPermissionEnum"] | undefined,
	status?: ModelTypes["EmailInvitationStatusEnum"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceNode"] | undefined
};
	["MemberPermissionEnum"]:MemberPermissionEnum;
	["EmailInvitationStatusEnum"]:EmailInvitationStatusEnum;
	["InvitationNodePaginated"]: {
		items?: Array<ModelTypes["InvitationNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** An invitation resource of the Vectary platform. */
["InvitationNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	permission?: ModelTypes["MemberPermissionEnum"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceNode"] | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["MemberPermissionFilter"]: {
	filter: ModelTypes["TextFilterEnum"],
	not?: boolean | undefined,
	value: ModelTypes["MemberPermissionEnum"]
};
	["EmailInvitationNodePaginated"]: {
		items?: Array<ModelTypes["EmailInvitationNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["EmailInvitationStatusFilter"]: {
	filter: ModelTypes["TextFilterEnum"],
	not?: boolean | undefined,
	value: ModelTypes["EmailInvitationStatusEnum"]
};
	["PermissionEnum"]:PermissionEnum;
	["PermissionsUserNodePaginated"]: {
		items?: Array<ModelTypes["PermissionsUserNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["UserRoleFilter"]: {
	filter: ModelTypes["TextFilterEnum"],
	not?: boolean | undefined,
	value: ModelTypes["UserRoleEnum"]
};
	["PermissionsWorkspaceNodePaginated"]: {
		items?: Array<ModelTypes["PermissionsWorkspaceNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	["FolderNodePaginated"]: {
		items?: Array<ModelTypes["FolderNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A folder resource of the Vectary platform. */
["FolderNodeBase"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	isHomeFolder?: boolean | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["FolderFiltersArgsWithLists"]: {
	id?: ModelTypes["StringListFilter"] | undefined,
	name?: ModelTypes["StringListFilter"] | undefined,
	isHomeFolder?: ModelTypes["BooleanFilter"] | undefined,
	workspaceId?: ModelTypes["StringListFilter"] | undefined,
	createdAt?: Array<ModelTypes["DateTimeFilter"] | undefined> | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] in [values] */
["StringListFilter"]: {
	not?: boolean | undefined,
	values: Array<string | undefined>
};
	["QueryScopeEnum"]:QueryScopeEnum;
	// @ts-nocheck
/** A custom domain resource of the Vectary platform. */
["CustomDomainNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Hostname of the domain. */
	hostname?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["ProjectNodePaginated"]: {
		items?: Array<ModelTypes["ProjectNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	["ProjectFiltersArgsWithLists"]: {
	id?: ModelTypes["StringListFilter"] | undefined,
	name?: ModelTypes["StringListFilter"] | undefined,
	description?: ModelTypes["StringListFilter"] | undefined,
	keywords?: Array<string | undefined> | undefined,
	folderId?: ModelTypes["StringListFilter"] | undefined,
	workspaceId?: ModelTypes["StringListFilter"] | undefined,
	readonly?: ModelTypes["BooleanFilter"] | undefined,
	archived?: ModelTypes["BooleanFilter"] | undefined,
	updatedAt?: Array<ModelTypes["DateTimeFilter"] | undefined> | undefined,
	createdAt?: Array<ModelTypes["DateTimeFilter"] | undefined> | undefined
};
	// @ts-nocheck
/** A project resource of the Vectary platform. */
["ProjectStateNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. It is a hash of the state object itself. */
	id?: string | undefined,
	// @ts-nocheck
/** ID of the project this state belong to. */
	projectId?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Object containing a state configuration. */
	state?: ModelTypes["JSONObject"] | undefined
};
	// @ts-nocheck
/** A nsProject resource of the Vectary platform. */
["NSProjectNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the nsProject written by a user. */
	description?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	keywords?: Array<string | undefined> | undefined,
	binaries?: Array<string | undefined> | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?: ModelTypes["DateTime"] | undefined,
	folder?: ModelTypes["FolderNode"] | undefined
};
	["NSProjectNodePaginated"]: {
		items?: Array<ModelTypes["NSProjectNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A shared project resource of the Vectary platform. */
["SharedProjectNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the shared project written by a user. */
	description?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	files?: Array<ModelTypes["JSONObject"] | undefined> | undefined,
	keywords?: Array<string | undefined> | undefined,
	scene?: ModelTypes["JSONObject"] | undefined,
	credits?: Array<ModelTypes["JSONObject"] | undefined> | undefined,
	imports?: Array<string | undefined> | undefined,
	settings?: ModelTypes["JSONObject"] | undefined,
	studioVersion?: ModelTypes["SemVer"] | undefined,
	nodes?: Array<ModelTypes["NodeNode"] | undefined> | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined
};
	// @ts-nocheck
/** A node resource of the Vectary platform. */
["NodeNodeBase"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?: string | undefined,
	category?: ModelTypes["NodeCategoryEnum"] | undefined,
	data?: ModelTypes["JSONObject"] | undefined,
	binaries?: Array<string | undefined> | undefined,
	files?: Array<ModelTypes["JSONObject"] | undefined> | undefined,
	libraryItem?: boolean | undefined,
	forkedFromId?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	projectId?: string | undefined
};
	["NodeFiltersArgsWithLists"]: {
	id?: ModelTypes["StringListFilter"] | undefined,
	name?: ModelTypes["StringListFilter"] | undefined,
	description?: ModelTypes["StringListFilter"] | undefined,
	category?: ModelTypes["NodeCategoryListFilter"] | undefined,
	projectId?: ModelTypes["StringListFilter"] | undefined,
	libraryItem?: ModelTypes["BooleanFilter"] | undefined,
	createdAt?: Array<ModelTypes["DateTimeFilter"] | undefined> | undefined,
	folderId?: ModelTypes["StringListFilter"] | undefined,
	workspaceId?: ModelTypes["StringListFilter"] | undefined,
	inArchivedProject?: ModelTypes["BooleanFilter"] | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] in [values] */
["NodeCategoryListFilter"]: {
	not?: boolean | undefined,
	values: Array<ModelTypes["NodeCategoryEnum"] | undefined>
};
	["UserEmailDetailsNodePaginated"]: {
		items?: Array<ModelTypes["UserEmailDetailsNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: ModelTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A role permission resource type. */
["RolePermissionNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["NonNegativeInt"] | undefined,
	// @ts-nocheck
/** Name of the role permission. */
	name?: string | undefined,
	// @ts-nocheck
/** Display name of the role permission. */
	displayName?: string | undefined,
	// @ts-nocheck
/** Description of the role permission. */
	description?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined
};
	// @ts-nocheck
/** A member role resource of the Vectary platform. */
["MemberRoleType"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id: ModelTypes["NonNegativeInt"],
	// @ts-nocheck
/** Name of the member role. */
	name: string,
	// @ts-nocheck
/** Display name of the member role. */
	displayName: string,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt: ModelTypes["DateTime"]
};
	// @ts-nocheck
/** A workspace role permission resource of the Vectary platform. */
["WorkspaceRolePermissionType"]: {
		// @ts-nocheck
/** ID of the workspace. */
	workspaceId: ModelTypes["UUID"],
	// @ts-nocheck
/** Member role. */
	memberRole: string,
	// @ts-nocheck
/** Role permission. */
	rolePermission: string,
	// @ts-nocheck
/** Tells if the given permission is allowed or not for the role. */
	isAllowed: boolean,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt: ModelTypes["DateTime"]
};
	["Mutation"]: {
		createPermissionsUser?: ModelTypes["PermissionsUserNode"] | undefined,
	deletePermissionsUser?: ModelTypes["NonNegativeInt"] | undefined,
	updatePermissionsUser?: ModelTypes["PermissionsUserNode"] | undefined,
	createPermissionsWorkspace?: ModelTypes["PermissionsWorkspaceNode"] | undefined,
	deletePermissionsWorkspace?: ModelTypes["NonNegativeInt"] | undefined,
	updatePermissionsWorkspace?: ModelTypes["PermissionsWorkspaceNode"] | undefined,
	deleteUser?: ModelTypes["NonNegativeInt"] | undefined,
	updateUser?: ModelTypes["UserNode"] | undefined,
	updateUserEmailDetails?: ModelTypes["UserEmailDetailsNode"] | undefined,
	updateUserLocationDetails?: ModelTypes["UserLocationDetailsNode"] | undefined,
	acceptGdprAndTou?: ModelTypes["UserNode"] | undefined,
	addEmailAuthenticationMethod?: boolean | undefined,
	createWorkspace?: ModelTypes["WorkspaceNode"] | undefined,
	deleteWorkspace?: ModelTypes["NonNegativeInt"] | undefined,
	updateWorkspace?: ModelTypes["WorkspaceNode"] | undefined,
	addMember?: boolean | undefined,
	updateMember?: boolean | undefined,
	removeMember?: boolean | undefined,
	cancelPaddleSubscription?: boolean | undefined,
	acceptInvitation?: ModelTypes["InvitationNode"] | undefined,
	createInvitation?: ModelTypes["InvitationNode"] | undefined,
	deleteInvitation?: ModelTypes["NonNegativeInt"] | undefined,
	updateInvitation?: ModelTypes["InvitationNode"] | undefined,
	createEmailInvitation?: Array<ModelTypes["EmailInvitationPossibleErrorNode"] | undefined> | undefined,
	deleteEmailInvitation?: ModelTypes["NonNegativeInt"] | undefined,
	updateEmailInvitation?: ModelTypes["EmailInvitationNode"] | undefined,
	acceptEmailInvitation?: ModelTypes["EmailInvitationNode"] | undefined,
	reactivateEmailInvitation?: ModelTypes["EmailInvitationNode"] | undefined,
	createFolder?: ModelTypes["FolderNode"] | undefined,
	deleteFolder?: ModelTypes["NonNegativeInt"] | undefined,
	updateFolder?: ModelTypes["FolderNode"] | undefined,
	createCustomDomain?: ModelTypes["CustomDomainNode"] | undefined,
	deleteCustomDomain?: ModelTypes["NonNegativeInt"] | undefined,
	createProject?: ModelTypes["ProjectNode"] | undefined,
	updateProject?: ModelTypes["ProjectNode"] | undefined,
	createOrUpdateProject?: ModelTypes["ProjectNode"] | undefined,
	deleteProject?: ModelTypes["NonNegativeInt"] | undefined,
	cloneProject?: ModelTypes["OperationResult"] | undefined,
	copyProject?: ModelTypes["OperationResult"] | undefined,
	createProjectState?: ModelTypes["ProjectStateNode"] | undefined,
	createNSProject?: ModelTypes["NSProjectNode"] | undefined,
	updateNSProject?: ModelTypes["NSProjectNode"] | undefined,
	deleteNSProject?: ModelTypes["NonNegativeInt"] | undefined,
	createSharedProject?: ModelTypes["ProjectNode"] | undefined,
	createNode?: ModelTypes["NodeNode"] | undefined,
	createOrUpdateNode?: ModelTypes["NodeNode"] | undefined,
	deleteNode?: ModelTypes["NonNegativeInt"] | undefined,
	updateNode?: ModelTypes["NodeNode"] | undefined,
	createCommentMessage?: ModelTypes["NodeNode"] | undefined,
	updateCommentMessage?: ModelTypes["NodeNode"] | undefined,
	deleteCommentMessage?: ModelTypes["NodeNode"] | undefined,
	uploadBinary?: string | undefined,
	createToken?: ModelTypes["TokenNode"] | undefined,
	createApiToken?: ModelTypes["TokenNode"] | undefined,
	updateToken?: ModelTypes["TokenNode"] | undefined,
	deleteToken?: ModelTypes["TokenNode"] | undefined,
	createRolePermission?: ModelTypes["RolePermissionNode"] | undefined,
	deleteRolePermission?: ModelTypes["NonNegativeInt"] | undefined,
	createMemberRole?: ModelTypes["MemberRoleType"] | undefined,
	deleteMemberRole?: ModelTypes["NonNegativeInt"] | undefined,
	updateWorkspaceRolePermission?: ModelTypes["WorkspaceRolePermissionType"] | undefined,
	deleteWorkspaceRolePermission?: number | undefined
};
	// @ts-nocheck
/** Questionnaire mutation object */
["QuestionnaireInputObjectType"]: {
	occupation?: ModelTypes["QuestionnaireDataInputObjectType"] | undefined,
	reasonToLeave?: ModelTypes["QuestionnaireDataInputObjectType"] | undefined
};
	// @ts-nocheck
/** Questionnaire mutation object */
["QuestionnaireDataInputObjectType"]: {
	value: string,
	workspaceId?: string | undefined,
	planName?: string | undefined,
	category?: string | undefined,
	subscribedForDays?: ModelTypes["NonNegativeFloat"] | undefined
};
	// @ts-nocheck
/** An email invitation resource of the Vectary platform. It includes error if any. */
["EmailInvitationPossibleErrorNode"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Email of the invitation. */
	email?: string | undefined,
	permission?: ModelTypes["MemberPermissionEnum"] | undefined,
	status?: ModelTypes["EmailInvitationStatusEnum"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceNode"] | undefined,
	error?: ModelTypes["JSONObject"] | undefined
};
	["EmailInvitationInput"]: {
	email?: string | undefined,
	permission?: ModelTypes["MemberPermissionEnum"] | undefined,
	status?: ModelTypes["EmailInvitationStatusEnum"] | undefined,
	workspaceId: ModelTypes["UUID"]
};
	// @ts-nocheck
/** This scalar accepts either a SHA-1 hash, which is a 40 character long string or a file upload. See the [GraphQL multipart request specification](https://github.com/jaydenseric/graphql-multipart-request-spec) for more information. */
["SHA1HashOrUpload"]:any;
	["NodeTypeBaseInput"]: {
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: ModelTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?: string | undefined,
	category?: ModelTypes["NodeCategoryEnum"] | undefined,
	data?: ModelTypes["JSONObject"] | undefined,
	binaries?: Array<string | undefined> | undefined,
	files?: Array<ModelTypes["JSONObject"] | undefined> | undefined,
	libraryItem?: boolean | undefined,
	forkedFromId?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: ModelTypes["DateTime"] | undefined,
	projectId?: string | undefined
};
	// @ts-nocheck
/** The result of the operation. */
["OperationResult"]: {
		result?: ModelTypes["OperationResultType"] | undefined
};
	["OperationResultType"]:OperationResultType;
	["SharedProjectNodeNode"]: {
	id: ModelTypes["UUID"],
	name?: string | undefined,
	description?: string | undefined,
	category: ModelTypes["NodeCategoryEnum"],
	data: ModelTypes["JSONObject"],
	binaries?: Array<ModelTypes["SHA1HashOrUpload"] | undefined> | undefined,
	files?: Array<ModelTypes["JSONObject"] | undefined> | undefined,
	libraryItem?: boolean | undefined,
	forkedFromId?: string | undefined
};
	["Subscription"]: {
		countDown?: number | undefined
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	permissionsUser?: GraphQLTypes["PermissionsUserNode"] | undefined,
	allPermissionsUsers?: GraphQLTypes["PermissionsUserNodePaginated"] | undefined,
	permissionsWorkspace?: GraphQLTypes["PermissionsWorkspaceNode"] | undefined,
	allPermissionsWorkspaces?: GraphQLTypes["PermissionsWorkspaceNodePaginated"] | undefined,
	user?: GraphQLTypes["UserNode"] | undefined,
	allUsers?: GraphQLTypes["UserNodePaginated"] | undefined,
	userByEmailOrHash?: GraphQLTypes["UserEmailDetailsNode"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNode"] | undefined,
	allWorkspaces?: GraphQLTypes["WorkspaceNodePaginated"] | undefined,
	invitation?: GraphQLTypes["InvitationNode"] | undefined,
	allInvitations?: GraphQLTypes["InvitationNodePaginated"] | undefined,
	emailInvitation?: GraphQLTypes["EmailInvitationNode"] | undefined,
	allEmailInvitations?: GraphQLTypes["EmailInvitationNodePaginated"] | undefined,
	folder?: GraphQLTypes["FolderNode"] | undefined,
	allFoldersPaginated?: GraphQLTypes["FolderNodePaginated"] | undefined,
	allFolders?: Array<GraphQLTypes["FolderNodeBase"] | undefined> | undefined,
	allCustomDomains?: Array<GraphQLTypes["CustomDomainNode"] | undefined> | undefined,
	project?: GraphQLTypes["ProjectNode"] | undefined,
	allProjectsPaginated?: GraphQLTypes["ProjectNodePaginated"] | undefined,
	allProjects?: Array<GraphQLTypes["ProjectNode"] | undefined> | undefined,
	allProjectsCount?: number | undefined,
	projectState?: GraphQLTypes["ProjectStateNode"] | undefined,
	nsProject?: GraphQLTypes["NSProjectNode"] | undefined,
	allNSProjects?: GraphQLTypes["NSProjectNodePaginated"] | undefined,
	sharedProject?: GraphQLTypes["SharedProjectNode"] | undefined,
	node?: GraphQLTypes["NodeNode"] | undefined,
	allNodesPaginated?: GraphQLTypes["NodeNodePaginated"] | undefined,
	allNodes?: Array<GraphQLTypes["NodeNodeBase"] | undefined> | undefined,
	allNodesCount?: number | undefined,
	token?: GraphQLTypes["TokenNode"] | undefined,
	allTokens?: Array<GraphQLTypes["TokenNode"] | undefined> | undefined,
	emails?: GraphQLTypes["UserEmailDetailsNodePaginated"] | undefined,
	rolePermission?: GraphQLTypes["RolePermissionNode"] | undefined,
	allRolePermissions?: Array<GraphQLTypes["RolePermissionNode"] | undefined> | undefined,
	memberRole?: GraphQLTypes["MemberRoleType"] | undefined,
	allMemberRoles?: Array<GraphQLTypes["MemberRoleType"] | undefined> | undefined,
	workspaceRolePermission?: GraphQLTypes["WorkspaceRolePermissionType"] | undefined,
	workspacePermissions?: GraphQLTypes["JSONObject"] | undefined
};
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a user resource. */
["PermissionsUserNode"]: {
	__typename: "PermissionsUserNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["NonNegativeInt"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	role?: GraphQLTypes["UserRoleEnum"] | undefined,
	workspaceLimit?: GraphQLTypes["NonNegativeInt"] | undefined,
	internalNote?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	users?: GraphQLTypes["UserNodePaginated"] | undefined
};
	// @ts-nocheck
/** Integers that will have a value of 0 or more. */
["NonNegativeInt"]: "scalar" & { name: "NonNegativeInt" };
	["UserRoleEnum"]: UserRoleEnum;
	// @ts-nocheck
/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]: "scalar" & { name: "DateTime" };
	["UserNodePaginated"]: {
	__typename: "UserNodePaginated",
	items?: Array<GraphQLTypes["UserNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A user resource of the Vectary platform. Contains the basic information of an account and it is directly related with certain collections of details of the account and the workspaces related to it */
["UserNode"]: {
	__typename: "UserNode",
	// @ts-nocheck
/** Unique identifier of a user. It is a UUIDv4 but at some point it will start accepting any string. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Nickname chosen by the user. */
	username?: string | undefined,
	// @ts-nocheck
/** Firstname of the user. */
	firstName?: string | undefined,
	// @ts-nocheck
/** Lastname the user. */
	lastName?: string | undefined,
	// @ts-nocheck
/** Flavour text about the user written by them. */
	biography?: string | undefined,
	// @ts-nocheck
/** The location of the picture used for the profile of the user. */
	picture?: string | undefined,
	// @ts-nocheck
/** Metadata we have collected from the user via questionnaires. */
	questionnaire?: GraphQLTypes["JSONObject"] | undefined,
	// @ts-nocheck
/** Version of the General Data Protection Regulation accepted by the user. */
	gdpr?: GraphQLTypes["NonNegativeFloat"] | undefined,
	// @ts-nocheck
/** Whether the General Data Protection Regulation accepted by the user is the current one or not. */
	isGdprCurrent?: boolean | undefined,
	// @ts-nocheck
/** Version of the Terms Of Use accepted by the user. */
	tou?: GraphQLTypes["NonNegativeFloat"] | undefined,
	// @ts-nocheck
/** Whether the Terms Of Use accepted by the user is the current one or not. */
	isTouCurrent?: boolean | undefined,
	// @ts-nocheck
/** Whether the user has used the one time trial. */
	trialUsed?: boolean | undefined,
	// @ts-nocheck
/** Whether the user has ever paid for a PRO plan. */
	proUsed?: boolean | undefined,
	// @ts-nocheck
/** Internal notes for a given user. Available only for Administrators. */
	internalNotes?: string | undefined,
	// @ts-nocheck
/** Time of creation of the user. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Email configuration for the user. */
	emailDetails?: GraphQLTypes["UserEmailDetailsNode"] | undefined,
	// @ts-nocheck
/** Location information about the user. */
	locationDetails?: GraphQLTypes["UserLocationDetailsNode"] | undefined,
	// @ts-nocheck
/** Access information about the user. Available only for Administrators. */
	accessDetails?: GraphQLTypes["UserAccessDetailsNode"] | undefined,
	// @ts-nocheck
/** The set of permissions assigned to the user. */
	permissions?: GraphQLTypes["PermissionsUserNode"] | undefined,
	// @ts-nocheck
/** Workspaces the user has access to. They can be filtered and paginated. */
	workspaces?: GraphQLTypes["UserWorkspaceNodePaginated"] | undefined,
	// @ts-nocheck
/** Tokens of external accounts of the user. And app tokens for authorizing request to Vectary. */
	tokens?: Array<GraphQLTypes["TokenNode"] | undefined> | undefined,
	// @ts-nocheck
/** Tells if the given user is a CIAM user. */
	isCiamUser?: boolean | undefined,
	// @ts-nocheck
/** Email invitations assigned to the user. */
	emailInvitations?: Array<GraphQLTypes["EmailInvitationNode"] | undefined> | undefined
};
	// @ts-nocheck
/** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
["UUID"]: "scalar" & { name: "UUID" };
	// @ts-nocheck
/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSONObject"]: "scalar" & { name: "JSONObject" };
	// @ts-nocheck
/** Floats that will have a value of 0 or more. */
["NonNegativeFloat"]: "scalar" & { name: "NonNegativeFloat" };
	// @ts-nocheck
/** Resource with information about a user's email. */
["UserEmailDetailsNode"]: {
	__typename: "UserEmailDetailsNode",
	id?: GraphQLTypes["UUID"] | undefined,
	email?: string | undefined,
	newsletter?: boolean | undefined,
	notifyComments?: string | undefined,
	undeliverable?: boolean | undefined,
	verificationToken?: GraphQLTypes["UUID"] | undefined
};
	// @ts-nocheck
/** Resource with information about a user's location. */
["UserLocationDetailsNode"]: {
	__typename: "UserLocationDetailsNode",
	location?: string | undefined,
	geo?: GraphQLTypes["JSONObject"] | undefined
};
	// @ts-nocheck
/** Resource with information about a user's access detail. */
["UserAccessDetailsNode"]: {
	__typename: "UserAccessDetailsNode",
	accessToken?: GraphQLTypes["UUID"] | undefined,
	accessTokenExpiration?: string | undefined,
	refreshToken?: GraphQLTypes["UUID"] | undefined,
	refreshTokenExpiration?: string | undefined,
	passwordIdentifier?: string | undefined,
	passwordResetToken?: GraphQLTypes["UUID"] | undefined,
	facebookIdentifier?: string | undefined,
	googleIdentifier?: string | undefined,
	ciamIdentifier?: string | undefined
};
	["UserWorkspaceNodePaginated"]: {
	__typename: "UserWorkspaceNodePaginated",
	items?: Array<GraphQLTypes["UserWorkspaceNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A workspace resource of the Vectary platform.
Includes extra properties about the relation with the user. */
["UserWorkspaceNode"]: {
	__typename: "UserWorkspaceNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	// @ts-nocheck
/** Collection of admin-defined properties to override some kind of behaviour. */
	overwrite?: GraphQLTypes["JSONObject"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	owner?: GraphQLTypes["UserNode"] | undefined,
	customDomain?: string | undefined,
	workspaceRolePermissions: Array<string | undefined>,
	permissions?: GraphQLTypes["PermissionsWorkspaceNode"] | undefined,
	paddleDetails?: GraphQLTypes["PaddleDetailsNode"] | undefined,
	folders?: GraphQLTypes["WorkspaceFolderNodePaginated"] | undefined,
	members?: Array<GraphQLTypes["WorkspaceMemberNode"] | undefined> | undefined,
	invitations?: GraphQLTypes["InvitationNodePaginated"] | undefined,
	emailInvitations?: GraphQLTypes["EmailInvitationNodePaginated"] | undefined,
	permission?: GraphQLTypes["PermissionEnum"] | undefined,
	ordering?: GraphQLTypes["NonNegativeInt"] | undefined
};
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a workspace resource. */
["PermissionsWorkspaceNode"]: {
	__typename: "PermissionsWorkspaceNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["NonNegativeInt"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	properties?: GraphQLTypes["JSONObject"] | undefined,
	internalNote?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	workspaces?: GraphQLTypes["WorkspaceNodePaginated"] | undefined
};
	["WorkspaceNodePaginated"]: {
	__typename: "WorkspaceNodePaginated",
	items?: Array<GraphQLTypes["WorkspaceNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A workspace resource of the Vectary platform. */
["WorkspaceNode"]: {
	__typename: "WorkspaceNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	// @ts-nocheck
/** Collection of admin-defined properties to override some kind of behaviour. */
	overwrite?: GraphQLTypes["JSONObject"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	owner?: GraphQLTypes["UserNode"] | undefined,
	customDomain?: string | undefined,
	workspaceRolePermissions: Array<string | undefined>,
	permissions?: GraphQLTypes["PermissionsWorkspaceNode"] | undefined,
	paddleDetails?: GraphQLTypes["PaddleDetailsNode"] | undefined,
	folders?: GraphQLTypes["WorkspaceFolderNodePaginated"] | undefined,
	members?: Array<GraphQLTypes["WorkspaceMemberNode"] | undefined> | undefined,
	invitations?: GraphQLTypes["InvitationNodePaginated"] | undefined,
	emailInvitations?: GraphQLTypes["EmailInvitationNodePaginated"] | undefined
};
	// @ts-nocheck
/** Resource with information about a set of permissions assignable to a workspace resource. */
["PaddleDetailsNode"]: {
	__typename: "PaddleDetailsNode",
	customerId?: GraphQLTypes["NonNegativeInt"] | undefined,
	subscriptionId?: GraphQLTypes["NonNegativeInt"] | undefined,
	linkedSubscriptionId?: GraphQLTypes["NonNegativeInt"] | undefined,
	status?: string | undefined,
	price?: GraphQLTypes["NonNegativeFloat"] | undefined,
	currency?: GraphQLTypes["Currency"] | undefined,
	recurringInterval?: GraphQLTypes["Duration"] | undefined,
	nextPaymentAmount?: GraphQLTypes["NonNegativeFloat"] | undefined,
	nextPaymentDate?: GraphQLTypes["DateTime"] | undefined,
	cancellationEffectiveDate?: GraphQLTypes["DateTime"] | undefined,
	cancelUrl?: GraphQLTypes["URL"] | undefined,
	updateUrl?: GraphQLTypes["URL"] | undefined,
	receipts?: Array<GraphQLTypes["JSONObject"] | undefined> | undefined,
	latestEventTime?: GraphQLTypes["DateTime"] | undefined
};
	// @ts-nocheck
/** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
["Currency"]: "scalar" & { name: "Currency" };
	// @ts-nocheck
/** 
    A string representing a duration conforming to the ISO8601 standard,
    such as: P1W1DT13H23M34S
    P is the duration designator (for period) placed at the start of the duration representation.
    Y is the year designator that follows the value for the number of years.
    M is the month designator that follows the value for the number of months.
    W is the week designator that follows the value for the number of weeks.
    D is the day designator that follows the value for the number of days.
    T is the time designator that precedes the time components of the representation.
    H is the hour designator that follows the value for the number of hours.
    M is the minute designator that follows the value for the number of minutes.
    S is the second designator that follows the value for the number of seconds.

    Note the time designator, T, that precedes the time value.

    Matches moment.js, Luxon and DateFns implementations
    ,/. is valid for decimal places and +/- is a valid prefix
   */
["Duration"]: "scalar" & { name: "Duration" };
	// @ts-nocheck
/** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
["URL"]: "scalar" & { name: "URL" };
	["WorkspaceFolderNodePaginated"]: {
	__typename: "WorkspaceFolderNodePaginated",
	items?: Array<GraphQLTypes["WorkspaceFolderNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A folder resource of the Vectary platform.
Includes extra properties about the relation with the workspace. */
["WorkspaceFolderNode"]: {
	__typename: "WorkspaceFolderNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	isHomeFolder?: boolean | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNode"] | undefined,
	projects?: GraphQLTypes["FolderProjectNodePaginated"] | undefined,
	nsProjects?: GraphQLTypes["FolderNSProjectNodePaginated"] | undefined,
	ordering?: GraphQLTypes["NonNegativeInt"] | undefined
};
	["FolderProjectNodePaginated"]: {
	__typename: "FolderProjectNodePaginated",
	items?: Array<GraphQLTypes["FolderProjectNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A project resource of the Vectary platform.
Includes extra properties about the relation with the folder. */
["FolderProjectNode"]: {
	__typename: "FolderProjectNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the project written by a user. */
	description?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	files?: Array<GraphQLTypes["File"] | undefined> | undefined,
	keywords?: Array<string | undefined> | undefined,
	scene?: GraphQLTypes["JSONObject"] | undefined,
	view?: GraphQLTypes["JSONObject"] | undefined,
	credits?: Array<GraphQLTypes["JSONObject"] | undefined> | undefined,
	published?: Array<string | undefined> | undefined,
	imports?: Array<string | undefined> | undefined,
	settings?: GraphQLTypes["JSONObject"] | undefined,
	studioVersion?: GraphQLTypes["SemVer"] | undefined,
	forkedFromId?: string | undefined,
	readonly?: boolean | undefined,
	deleted?: boolean | undefined,
	folderId?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	folder?: GraphQLTypes["FolderNode"] | undefined,
	nodes?: GraphQLTypes["NodeNodePaginated"] | undefined,
	history?: Array<GraphQLTypes["DateTime"] | undefined> | undefined,
	ordering?: GraphQLTypes["NonNegativeInt"] | undefined
};
	// @ts-nocheck
/** Uploaded file meta data. */
["File"]: {
	__typename: "File",
	purpose?: string | undefined,
	urls?: Array<string | undefined> | undefined,
	sizes?: Array<GraphQLTypes["PositiveInt"] | undefined> | undefined,
	status?: Array<string | undefined> | undefined
};
	// @ts-nocheck
/** Integers that will have a value greater than 0. */
["PositiveInt"]: "scalar" & { name: "PositiveInt" };
	// @ts-nocheck
/** A field whose value is a Semantic Version: https://semver.org */
["SemVer"]: "scalar" & { name: "SemVer" };
	// @ts-nocheck
/** A folder resource of the Vectary platform. */
["FolderNode"]: {
	__typename: "FolderNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	isHomeFolder?: boolean | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNode"] | undefined,
	projects?: GraphQLTypes["FolderProjectNodePaginated"] | undefined,
	nsProjects?: GraphQLTypes["FolderNSProjectNodePaginated"] | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["StringFilter"]: {
		filter: GraphQLTypes["TextFilterEnum"],
	not?: boolean | undefined,
	value: string
};
	["TextFilterEnum"]: TextFilterEnum;
	// @ts-nocheck
/** [server_value] is [filter?] [value] */
["BooleanFilter"]: {
		filter: GraphQLTypes["BooleanFilterEnum"],
	value: boolean
};
	["BooleanFilterEnum"]: BooleanFilterEnum;
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["DateTimeFilter"]: {
		filter: GraphQLTypes["SortableFilterEnum"],
	not?: boolean | undefined,
	value: GraphQLTypes["DateTime"]
};
	["SortableFilterEnum"]: SortableFilterEnum;
	["ProjectSort"]: ProjectSort;
	["FolderNSProjectNodePaginated"]: {
	__typename: "FolderNSProjectNodePaginated",
	items?: Array<GraphQLTypes["FolderNSProjectNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A nsProject resource of the Vectary platform.
Includes extra properties about the relation with the folder. */
["FolderNSProjectNode"]: {
	__typename: "FolderNSProjectNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the nsProject written by a user. */
	description?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	keywords?: Array<string | undefined> | undefined,
	binaries?: Array<string | undefined> | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	folder?: GraphQLTypes["FolderNode"] | undefined,
	ordering?: GraphQLTypes["NonNegativeInt"] | undefined
};
	// @ts-nocheck
/** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
["BigInt"]: "scalar" & { name: "BigInt" };
	["NSProjectSort"]: NSProjectSort;
	["NodeNodePaginated"]: {
	__typename: "NodeNodePaginated",
	items?: Array<GraphQLTypes["NodeNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A node resource of the Vectary platform. */
["NodeNode"]: {
	__typename: "NodeNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?: string | undefined,
	category?: GraphQLTypes["NodeCategoryEnum"] | undefined,
	data?: GraphQLTypes["JSONObject"] | undefined,
	binaries?: Array<string | undefined> | undefined,
	files?: Array<GraphQLTypes["JSONObject"] | undefined> | undefined,
	libraryItem?: boolean | undefined,
	forkedFromId?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	projectId?: string | undefined,
	project?: GraphQLTypes["ProjectNode"] | undefined,
	history?: Array<GraphQLTypes["DateTime"] | undefined> | undefined
};
	["NodeCategoryEnum"]: NodeCategoryEnum;
	// @ts-nocheck
/** A project resource of the Vectary platform. */
["ProjectNode"]: {
	__typename: "ProjectNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the project written by a user. */
	description?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	files?: Array<GraphQLTypes["File"] | undefined> | undefined,
	keywords?: Array<string | undefined> | undefined,
	scene?: GraphQLTypes["JSONObject"] | undefined,
	view?: GraphQLTypes["JSONObject"] | undefined,
	credits?: Array<GraphQLTypes["JSONObject"] | undefined> | undefined,
	published?: Array<string | undefined> | undefined,
	imports?: Array<string | undefined> | undefined,
	settings?: GraphQLTypes["JSONObject"] | undefined,
	studioVersion?: GraphQLTypes["SemVer"] | undefined,
	forkedFromId?: string | undefined,
	readonly?: boolean | undefined,
	deleted?: boolean | undefined,
	folderId?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	folder?: GraphQLTypes["FolderNode"] | undefined,
	nodes?: GraphQLTypes["NodeNodePaginated"] | undefined,
	history?: Array<GraphQLTypes["DateTime"] | undefined> | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["NodeCategoryFilter"]: {
		filter: GraphQLTypes["TextFilterEnum"],
	not?: boolean | undefined,
	value: GraphQLTypes["NodeCategoryEnum"]
};
	// @ts-nocheck
/** A user resource of the Vectary platform. Contains the basic information of an account and it is directly related with certain collections of details of the account and the workspaces related to it
Includes extra properties about the relation with the workspace. */
["WorkspaceMemberNode"]: {
	__typename: "WorkspaceMemberNode",
	// @ts-nocheck
/** Unique identifier of a user. It is a UUIDv4 but at some point it will start accepting any string. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Nickname chosen by the user. */
	username?: string | undefined,
	// @ts-nocheck
/** Firstname of the user. */
	firstName?: string | undefined,
	// @ts-nocheck
/** Lastname the user. */
	lastName?: string | undefined,
	// @ts-nocheck
/** Flavour text about the user written by them. */
	biography?: string | undefined,
	// @ts-nocheck
/** The location of the picture used for the profile of the user. */
	picture?: string | undefined,
	// @ts-nocheck
/** Metadata we have collected from the user via questionnaires. */
	questionnaire?: GraphQLTypes["JSONObject"] | undefined,
	// @ts-nocheck
/** Version of the General Data Protection Regulation accepted by the user. */
	gdpr?: GraphQLTypes["NonNegativeFloat"] | undefined,
	// @ts-nocheck
/** Whether the General Data Protection Regulation accepted by the user is the current one or not. */
	isGdprCurrent?: boolean | undefined,
	// @ts-nocheck
/** Version of the Terms Of Use accepted by the user. */
	tou?: GraphQLTypes["NonNegativeFloat"] | undefined,
	// @ts-nocheck
/** Whether the Terms Of Use accepted by the user is the current one or not. */
	isTouCurrent?: boolean | undefined,
	// @ts-nocheck
/** Whether the user has used the one time trial. */
	trialUsed?: boolean | undefined,
	// @ts-nocheck
/** Whether the user has ever paid for a PRO plan. */
	proUsed?: boolean | undefined,
	// @ts-nocheck
/** Internal notes for a given user. Available only for Administrators. */
	internalNotes?: string | undefined,
	// @ts-nocheck
/** Time of creation of the user. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Email configuration for the user. */
	emailDetails?: GraphQLTypes["UserEmailDetailsNode"] | undefined,
	// @ts-nocheck
/** Location information about the user. */
	locationDetails?: GraphQLTypes["UserLocationDetailsNode"] | undefined,
	// @ts-nocheck
/** Access information about the user. Available only for Administrators. */
	accessDetails?: GraphQLTypes["UserAccessDetailsNode"] | undefined,
	// @ts-nocheck
/** The set of permissions assigned to the user. */
	permissions?: GraphQLTypes["PermissionsUserNode"] | undefined,
	// @ts-nocheck
/** Workspaces the user has access to. They can be filtered and paginated. */
	workspaces?: GraphQLTypes["UserWorkspaceNodePaginated"] | undefined,
	// @ts-nocheck
/** Tokens of external accounts of the user. And app tokens for authorizing request to Vectary. */
	tokens?: Array<GraphQLTypes["TokenNode"] | undefined> | undefined,
	// @ts-nocheck
/** Tells if the given user is a CIAM user. */
	isCiamUser?: boolean | undefined,
	// @ts-nocheck
/** Email invitations assigned to the user. */
	emailInvitations?: Array<GraphQLTypes["EmailInvitationNode"] | undefined> | undefined,
	permission?: GraphQLTypes["MemberPermissionEnum"] | undefined,
	rolePermissions?: Array<string | undefined> | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["NonNegativeIntFilter"]: {
		filter: GraphQLTypes["SortableFilterEnum"],
	not?: boolean | undefined,
	value: GraphQLTypes["NonNegativeInt"]
};
	// @ts-nocheck
/** Resource with information about a user's tokens. */
["TokenNode"]: {
	__typename: "TokenNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	type?: GraphQLTypes["TokenTypeColumnEnum"] | undefined,
	token?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["TokenTypeColumnEnum"]: TokenTypeColumnEnum;
	// @ts-nocheck
/** An email invitation resource of the Vectary platform. */
["EmailInvitationNode"]: {
	__typename: "EmailInvitationNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Email of the invitation. */
	email?: string | undefined,
	permission?: GraphQLTypes["MemberPermissionEnum"] | undefined,
	status?: GraphQLTypes["EmailInvitationStatusEnum"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNode"] | undefined
};
	["MemberPermissionEnum"]: MemberPermissionEnum;
	["EmailInvitationStatusEnum"]: EmailInvitationStatusEnum;
	["InvitationNodePaginated"]: {
	__typename: "InvitationNodePaginated",
	items?: Array<GraphQLTypes["InvitationNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** An invitation resource of the Vectary platform. */
["InvitationNode"]: {
	__typename: "InvitationNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	permission?: GraphQLTypes["MemberPermissionEnum"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNode"] | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["MemberPermissionFilter"]: {
		filter: GraphQLTypes["TextFilterEnum"],
	not?: boolean | undefined,
	value: GraphQLTypes["MemberPermissionEnum"]
};
	["EmailInvitationNodePaginated"]: {
	__typename: "EmailInvitationNodePaginated",
	items?: Array<GraphQLTypes["EmailInvitationNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["EmailInvitationStatusFilter"]: {
		filter: GraphQLTypes["TextFilterEnum"],
	not?: boolean | undefined,
	value: GraphQLTypes["EmailInvitationStatusEnum"]
};
	["PermissionEnum"]: PermissionEnum;
	["PermissionsUserNodePaginated"]: {
	__typename: "PermissionsUserNodePaginated",
	items?: Array<GraphQLTypes["PermissionsUserNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] [filter] [value] */
["UserRoleFilter"]: {
		filter: GraphQLTypes["TextFilterEnum"],
	not?: boolean | undefined,
	value: GraphQLTypes["UserRoleEnum"]
};
	["PermissionsWorkspaceNodePaginated"]: {
	__typename: "PermissionsWorkspaceNodePaginated",
	items?: Array<GraphQLTypes["PermissionsWorkspaceNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	["FolderNodePaginated"]: {
	__typename: "FolderNodePaginated",
	items?: Array<GraphQLTypes["FolderNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A folder resource of the Vectary platform. */
["FolderNodeBase"]: {
	__typename: "FolderNodeBase",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	isHomeFolder?: boolean | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["FolderFiltersArgsWithLists"]: {
		id?: GraphQLTypes["StringListFilter"] | undefined,
	name?: GraphQLTypes["StringListFilter"] | undefined,
	isHomeFolder?: GraphQLTypes["BooleanFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringListFilter"] | undefined,
	createdAt?: Array<GraphQLTypes["DateTimeFilter"] | undefined> | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] in [values] */
["StringListFilter"]: {
		not?: boolean | undefined,
	values: Array<string | undefined>
};
	["QueryScopeEnum"]: QueryScopeEnum;
	// @ts-nocheck
/** A custom domain resource of the Vectary platform. */
["CustomDomainNode"]: {
	__typename: "CustomDomainNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Hostname of the domain. */
	hostname?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["ProjectNodePaginated"]: {
	__typename: "ProjectNodePaginated",
	items?: Array<GraphQLTypes["ProjectNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	["ProjectFiltersArgsWithLists"]: {
		id?: GraphQLTypes["StringListFilter"] | undefined,
	name?: GraphQLTypes["StringListFilter"] | undefined,
	description?: GraphQLTypes["StringListFilter"] | undefined,
	keywords?: Array<string | undefined> | undefined,
	folderId?: GraphQLTypes["StringListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringListFilter"] | undefined,
	readonly?: GraphQLTypes["BooleanFilter"] | undefined,
	archived?: GraphQLTypes["BooleanFilter"] | undefined,
	updatedAt?: Array<GraphQLTypes["DateTimeFilter"] | undefined> | undefined,
	createdAt?: Array<GraphQLTypes["DateTimeFilter"] | undefined> | undefined
};
	// @ts-nocheck
/** A project resource of the Vectary platform. */
["ProjectStateNode"]: {
	__typename: "ProjectStateNode",
	// @ts-nocheck
/** Unique identifier of the resource. It is a hash of the state object itself. */
	id?: string | undefined,
	// @ts-nocheck
/** ID of the project this state belong to. */
	projectId?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Object containing a state configuration. */
	state?: GraphQLTypes["JSONObject"] | undefined
};
	// @ts-nocheck
/** A nsProject resource of the Vectary platform. */
["NSProjectNode"]: {
	__typename: "NSProjectNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the nsProject written by a user. */
	description?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	keywords?: Array<string | undefined> | undefined,
	binaries?: Array<string | undefined> | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	// @ts-nocheck
/** Datetime of last update of the resource. With a timezone. */
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	folder?: GraphQLTypes["FolderNode"] | undefined
};
	["NSProjectNodePaginated"]: {
	__typename: "NSProjectNodePaginated",
	items?: Array<GraphQLTypes["NSProjectNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A shared project resource of the Vectary platform. */
["SharedProjectNode"]: {
	__typename: "SharedProjectNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the shared project written by a user. */
	description?: string | undefined,
	// @ts-nocheck
/** Location of an image to use as an icon for the resource. */
	picture?: string | undefined,
	files?: Array<GraphQLTypes["JSONObject"] | undefined> | undefined,
	keywords?: Array<string | undefined> | undefined,
	scene?: GraphQLTypes["JSONObject"] | undefined,
	credits?: Array<GraphQLTypes["JSONObject"] | undefined> | undefined,
	imports?: Array<string | undefined> | undefined,
	settings?: GraphQLTypes["JSONObject"] | undefined,
	studioVersion?: GraphQLTypes["SemVer"] | undefined,
	nodes?: Array<GraphQLTypes["NodeNode"] | undefined> | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	// @ts-nocheck
/** A node resource of the Vectary platform. */
["NodeNodeBase"]: {
	__typename: "NodeNodeBase",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?: string | undefined,
	category?: GraphQLTypes["NodeCategoryEnum"] | undefined,
	data?: GraphQLTypes["JSONObject"] | undefined,
	binaries?: Array<string | undefined> | undefined,
	files?: Array<GraphQLTypes["JSONObject"] | undefined> | undefined,
	libraryItem?: boolean | undefined,
	forkedFromId?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	projectId?: string | undefined
};
	["NodeFiltersArgsWithLists"]: {
		id?: GraphQLTypes["StringListFilter"] | undefined,
	name?: GraphQLTypes["StringListFilter"] | undefined,
	description?: GraphQLTypes["StringListFilter"] | undefined,
	category?: GraphQLTypes["NodeCategoryListFilter"] | undefined,
	projectId?: GraphQLTypes["StringListFilter"] | undefined,
	libraryItem?: GraphQLTypes["BooleanFilter"] | undefined,
	createdAt?: Array<GraphQLTypes["DateTimeFilter"] | undefined> | undefined,
	folderId?: GraphQLTypes["StringListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringListFilter"] | undefined,
	inArchivedProject?: GraphQLTypes["BooleanFilter"] | undefined
};
	// @ts-nocheck
/** [server_value] is [not?] in [values] */
["NodeCategoryListFilter"]: {
		not?: boolean | undefined,
	values: Array<GraphQLTypes["NodeCategoryEnum"] | undefined>
};
	["UserEmailDetailsNodePaginated"]: {
	__typename: "UserEmailDetailsNodePaginated",
	items?: Array<GraphQLTypes["UserEmailDetailsNode"] | undefined> | undefined,
	cursor?: string | undefined,
	remaining?: GraphQLTypes["BigInt"] | undefined
};
	// @ts-nocheck
/** A role permission resource type. */
["RolePermissionNode"]: {
	__typename: "RolePermissionNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["NonNegativeInt"] | undefined,
	// @ts-nocheck
/** Name of the role permission. */
	name?: string | undefined,
	// @ts-nocheck
/** Display name of the role permission. */
	displayName?: string | undefined,
	// @ts-nocheck
/** Description of the role permission. */
	description?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	// @ts-nocheck
/** A member role resource of the Vectary platform. */
["MemberRoleType"]: {
	__typename: "MemberRoleType",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id: GraphQLTypes["NonNegativeInt"],
	// @ts-nocheck
/** Name of the member role. */
	name: string,
	// @ts-nocheck
/** Display name of the member role. */
	displayName: string,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt: GraphQLTypes["DateTime"]
};
	// @ts-nocheck
/** A workspace role permission resource of the Vectary platform. */
["WorkspaceRolePermissionType"]: {
	__typename: "WorkspaceRolePermissionType",
	// @ts-nocheck
/** ID of the workspace. */
	workspaceId: GraphQLTypes["UUID"],
	// @ts-nocheck
/** Member role. */
	memberRole: string,
	// @ts-nocheck
/** Role permission. */
	rolePermission: string,
	// @ts-nocheck
/** Tells if the given permission is allowed or not for the role. */
	isAllowed: boolean,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt: GraphQLTypes["DateTime"]
};
	["Mutation"]: {
	__typename: "Mutation",
	createPermissionsUser?: GraphQLTypes["PermissionsUserNode"] | undefined,
	deletePermissionsUser?: GraphQLTypes["NonNegativeInt"] | undefined,
	updatePermissionsUser?: GraphQLTypes["PermissionsUserNode"] | undefined,
	createPermissionsWorkspace?: GraphQLTypes["PermissionsWorkspaceNode"] | undefined,
	deletePermissionsWorkspace?: GraphQLTypes["NonNegativeInt"] | undefined,
	updatePermissionsWorkspace?: GraphQLTypes["PermissionsWorkspaceNode"] | undefined,
	deleteUser?: GraphQLTypes["NonNegativeInt"] | undefined,
	updateUser?: GraphQLTypes["UserNode"] | undefined,
	updateUserEmailDetails?: GraphQLTypes["UserEmailDetailsNode"] | undefined,
	updateUserLocationDetails?: GraphQLTypes["UserLocationDetailsNode"] | undefined,
	acceptGdprAndTou?: GraphQLTypes["UserNode"] | undefined,
	addEmailAuthenticationMethod?: boolean | undefined,
	createWorkspace?: GraphQLTypes["WorkspaceNode"] | undefined,
	deleteWorkspace?: GraphQLTypes["NonNegativeInt"] | undefined,
	updateWorkspace?: GraphQLTypes["WorkspaceNode"] | undefined,
	addMember?: boolean | undefined,
	updateMember?: boolean | undefined,
	removeMember?: boolean | undefined,
	cancelPaddleSubscription?: boolean | undefined,
	acceptInvitation?: GraphQLTypes["InvitationNode"] | undefined,
	createInvitation?: GraphQLTypes["InvitationNode"] | undefined,
	deleteInvitation?: GraphQLTypes["NonNegativeInt"] | undefined,
	updateInvitation?: GraphQLTypes["InvitationNode"] | undefined,
	createEmailInvitation?: Array<GraphQLTypes["EmailInvitationPossibleErrorNode"] | undefined> | undefined,
	deleteEmailInvitation?: GraphQLTypes["NonNegativeInt"] | undefined,
	updateEmailInvitation?: GraphQLTypes["EmailInvitationNode"] | undefined,
	acceptEmailInvitation?: GraphQLTypes["EmailInvitationNode"] | undefined,
	reactivateEmailInvitation?: GraphQLTypes["EmailInvitationNode"] | undefined,
	createFolder?: GraphQLTypes["FolderNode"] | undefined,
	deleteFolder?: GraphQLTypes["NonNegativeInt"] | undefined,
	updateFolder?: GraphQLTypes["FolderNode"] | undefined,
	createCustomDomain?: GraphQLTypes["CustomDomainNode"] | undefined,
	deleteCustomDomain?: GraphQLTypes["NonNegativeInt"] | undefined,
	createProject?: GraphQLTypes["ProjectNode"] | undefined,
	updateProject?: GraphQLTypes["ProjectNode"] | undefined,
	createOrUpdateProject?: GraphQLTypes["ProjectNode"] | undefined,
	deleteProject?: GraphQLTypes["NonNegativeInt"] | undefined,
	cloneProject?: GraphQLTypes["OperationResult"] | undefined,
	copyProject?: GraphQLTypes["OperationResult"] | undefined,
	createProjectState?: GraphQLTypes["ProjectStateNode"] | undefined,
	createNSProject?: GraphQLTypes["NSProjectNode"] | undefined,
	updateNSProject?: GraphQLTypes["NSProjectNode"] | undefined,
	deleteNSProject?: GraphQLTypes["NonNegativeInt"] | undefined,
	createSharedProject?: GraphQLTypes["ProjectNode"] | undefined,
	createNode?: GraphQLTypes["NodeNode"] | undefined,
	createOrUpdateNode?: GraphQLTypes["NodeNode"] | undefined,
	deleteNode?: GraphQLTypes["NonNegativeInt"] | undefined,
	updateNode?: GraphQLTypes["NodeNode"] | undefined,
	createCommentMessage?: GraphQLTypes["NodeNode"] | undefined,
	updateCommentMessage?: GraphQLTypes["NodeNode"] | undefined,
	deleteCommentMessage?: GraphQLTypes["NodeNode"] | undefined,
	uploadBinary?: string | undefined,
	createToken?: GraphQLTypes["TokenNode"] | undefined,
	createApiToken?: GraphQLTypes["TokenNode"] | undefined,
	updateToken?: GraphQLTypes["TokenNode"] | undefined,
	deleteToken?: GraphQLTypes["TokenNode"] | undefined,
	createRolePermission?: GraphQLTypes["RolePermissionNode"] | undefined,
	deleteRolePermission?: GraphQLTypes["NonNegativeInt"] | undefined,
	createMemberRole?: GraphQLTypes["MemberRoleType"] | undefined,
	deleteMemberRole?: GraphQLTypes["NonNegativeInt"] | undefined,
	updateWorkspaceRolePermission?: GraphQLTypes["WorkspaceRolePermissionType"] | undefined,
	deleteWorkspaceRolePermission?: number | undefined
};
	// @ts-nocheck
/** Questionnaire mutation object */
["QuestionnaireInputObjectType"]: {
		occupation?: GraphQLTypes["QuestionnaireDataInputObjectType"] | undefined,
	reasonToLeave?: GraphQLTypes["QuestionnaireDataInputObjectType"] | undefined
};
	// @ts-nocheck
/** Questionnaire mutation object */
["QuestionnaireDataInputObjectType"]: {
		value: string,
	workspaceId?: string | undefined,
	planName?: string | undefined,
	category?: string | undefined,
	subscribedForDays?: GraphQLTypes["NonNegativeFloat"] | undefined
};
	// @ts-nocheck
/** An email invitation resource of the Vectary platform. It includes error if any. */
["EmailInvitationPossibleErrorNode"]: {
	__typename: "EmailInvitationPossibleErrorNode",
	// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Email of the invitation. */
	email?: string | undefined,
	permission?: GraphQLTypes["MemberPermissionEnum"] | undefined,
	status?: GraphQLTypes["EmailInvitationStatusEnum"] | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNode"] | undefined,
	error?: GraphQLTypes["JSONObject"] | undefined
};
	["EmailInvitationInput"]: {
		email?: string | undefined,
	permission?: GraphQLTypes["MemberPermissionEnum"] | undefined,
	status?: GraphQLTypes["EmailInvitationStatusEnum"] | undefined,
	workspaceId: GraphQLTypes["UUID"]
};
	// @ts-nocheck
/** This scalar accepts either a SHA-1 hash, which is a 40 character long string or a file upload. See the [GraphQL multipart request specification](https://github.com/jaydenseric/graphql-multipart-request-spec) for more information. */
["SHA1HashOrUpload"]: "scalar" & { name: "SHA1HashOrUpload" };
	["NodeTypeBaseInput"]: {
		// @ts-nocheck
/** Unique identifier of the resource. */
	id?: GraphQLTypes["UUID"] | undefined,
	// @ts-nocheck
/** Name chosen by a user. */
	name?: string | undefined,
	// @ts-nocheck
/** Flavour text about the node written by a user. */
	description?: string | undefined,
	category?: GraphQLTypes["NodeCategoryEnum"] | undefined,
	data?: GraphQLTypes["JSONObject"] | undefined,
	binaries?: Array<string | undefined> | undefined,
	files?: Array<GraphQLTypes["JSONObject"] | undefined> | undefined,
	libraryItem?: boolean | undefined,
	forkedFromId?: string | undefined,
	// @ts-nocheck
/** Datetime of creation of the resource. With a timezone. */
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	projectId?: string | undefined
};
	// @ts-nocheck
/** The result of the operation. */
["OperationResult"]: {
	__typename: "OperationResult",
	result?: GraphQLTypes["OperationResultType"] | undefined
};
	["OperationResultType"]: OperationResultType;
	["SharedProjectNodeNode"]: {
		id: GraphQLTypes["UUID"],
	name?: string | undefined,
	description?: string | undefined,
	category: GraphQLTypes["NodeCategoryEnum"],
	data: GraphQLTypes["JSONObject"],
	binaries?: Array<GraphQLTypes["SHA1HashOrUpload"] | undefined> | undefined,
	files?: Array<GraphQLTypes["JSONObject"] | undefined> | undefined,
	libraryItem?: boolean | undefined,
	forkedFromId?: string | undefined
};
	["Subscription"]: {
	__typename: "Subscription",
	countDown?: number | undefined
}
    }
export const enum UserRoleEnum {
	administrator = "administrator",
	standard = "standard"
}
export const enum TextFilterEnum {
	equal_to = "equal_to"
}
export const enum BooleanFilterEnum {
	equal_to = "equal_to"
}
export const enum SortableFilterEnum {
	equal_to = "equal_to",
	greater_than = "greater_than",
	less_than = "less_than"
}
export const enum ProjectSort {
	default = "default",
	name = "name",
	timeCreated = "timeCreated",
	timeUpdated = "timeUpdated"
}
export const enum NSProjectSort {
	default = "default",
	name = "name",
	timeCreated = "timeCreated",
	timeUpdated = "timeUpdated"
}
export const enum NodeCategoryEnum {
	environment = "environment",
	camera = "camera",
	model = "model",
	light = "light",
	material = "material",
	texture = "texture",
	user_interface = "user_interface",
	animation = "animation",
	interaction = "interaction",
	interaction_group = "interaction_group",
	interactions = "interactions",
	selection = "selection",
	transformation_state = "transformation_state",
	geometry_uv1_data = "geometry_uv1_data",
	sound = "sound",
	event_api = "event_api",
	variable = "variable",
	dataset = "dataset"
}
export const enum TokenTypeColumnEnum {
	figma = "figma",
	api = "api"
}
export const enum MemberPermissionEnum {
	collaborate_2024 = "collaborate_2024",
	edit = "edit",
	edit_2024 = "edit_2024",
	manage_2024 = "manage_2024",
	view = "view",
	view_2024 = "view_2024"
}
export const enum EmailInvitationStatusEnum {
	accepted = "accepted",
	active = "active",
	deleted = "deleted",
	expired = "expired"
}
export const enum PermissionEnum {
	collaborate_2024 = "collaborate_2024",
	edit = "edit",
	edit_2024 = "edit_2024",
	manage_2024 = "manage_2024",
	view = "view",
	view_2024 = "view_2024",
	owner = "owner"
}
export const enum QueryScopeEnum {
	PUBLIC = "PUBLIC",
	PRIVATE = "PRIVATE",
	ADMIN = "ADMIN",
	SHARED_PUBLIC = "SHARED_PUBLIC"
}
export const enum OperationResultType {
	ok = "ok",
	error = "error"
}

type ZEUS_VARIABLES = {
	["NonNegativeInt"]: ValueTypes["NonNegativeInt"];
	["UserRoleEnum"]: ValueTypes["UserRoleEnum"];
	["DateTime"]: ValueTypes["DateTime"];
	["UUID"]: ValueTypes["UUID"];
	["JSONObject"]: ValueTypes["JSONObject"];
	["NonNegativeFloat"]: ValueTypes["NonNegativeFloat"];
	["Currency"]: ValueTypes["Currency"];
	["Duration"]: ValueTypes["Duration"];
	["URL"]: ValueTypes["URL"];
	["PositiveInt"]: ValueTypes["PositiveInt"];
	["SemVer"]: ValueTypes["SemVer"];
	["StringFilter"]: ValueTypes["StringFilter"];
	["TextFilterEnum"]: ValueTypes["TextFilterEnum"];
	["BooleanFilter"]: ValueTypes["BooleanFilter"];
	["BooleanFilterEnum"]: ValueTypes["BooleanFilterEnum"];
	["DateTimeFilter"]: ValueTypes["DateTimeFilter"];
	["SortableFilterEnum"]: ValueTypes["SortableFilterEnum"];
	["ProjectSort"]: ValueTypes["ProjectSort"];
	["BigInt"]: ValueTypes["BigInt"];
	["NSProjectSort"]: ValueTypes["NSProjectSort"];
	["NodeCategoryEnum"]: ValueTypes["NodeCategoryEnum"];
	["NodeCategoryFilter"]: ValueTypes["NodeCategoryFilter"];
	["NonNegativeIntFilter"]: ValueTypes["NonNegativeIntFilter"];
	["TokenTypeColumnEnum"]: ValueTypes["TokenTypeColumnEnum"];
	["MemberPermissionEnum"]: ValueTypes["MemberPermissionEnum"];
	["EmailInvitationStatusEnum"]: ValueTypes["EmailInvitationStatusEnum"];
	["MemberPermissionFilter"]: ValueTypes["MemberPermissionFilter"];
	["EmailInvitationStatusFilter"]: ValueTypes["EmailInvitationStatusFilter"];
	["PermissionEnum"]: ValueTypes["PermissionEnum"];
	["UserRoleFilter"]: ValueTypes["UserRoleFilter"];
	["FolderFiltersArgsWithLists"]: ValueTypes["FolderFiltersArgsWithLists"];
	["StringListFilter"]: ValueTypes["StringListFilter"];
	["QueryScopeEnum"]: ValueTypes["QueryScopeEnum"];
	["ProjectFiltersArgsWithLists"]: ValueTypes["ProjectFiltersArgsWithLists"];
	["NodeFiltersArgsWithLists"]: ValueTypes["NodeFiltersArgsWithLists"];
	["NodeCategoryListFilter"]: ValueTypes["NodeCategoryListFilter"];
	["QuestionnaireInputObjectType"]: ValueTypes["QuestionnaireInputObjectType"];
	["QuestionnaireDataInputObjectType"]: ValueTypes["QuestionnaireDataInputObjectType"];
	["EmailInvitationInput"]: ValueTypes["EmailInvitationInput"];
	["SHA1HashOrUpload"]: ValueTypes["SHA1HashOrUpload"];
	["NodeTypeBaseInput"]: ValueTypes["NodeTypeBaseInput"];
	["OperationResultType"]: ValueTypes["OperationResultType"];
	["SharedProjectNodeNode"]: ValueTypes["SharedProjectNodeNode"];
}