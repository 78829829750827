import { getCurrentUser, UserBase } from '@vctr-libs/vctr-api-client';
import { EventLogger } from '@vctr-libs/vctr-analytics';
import { handleProductHuntLink } from './productHunt';
import { paginateBlog } from './pagination';
import {
	hideLoginForm,
	setProfile,
	showElementsForAuhtorisedUsers,
	showElementsForUnauhtorisedUsers,
} from './showHide';
import { attachLogout } from './loginLogout';
import { handleContactFormSubmit } from './contactForm';
import { handleCopyLink } from './handleCopyLink';
import { handlePricing } from './handlePricing';
import {
	handleGADataLayerEvents,
	handleHomepageReadyToDiveIn,
} from './events/analyticsEvents';
import { initOneTrustCookies } from './cookieConsent';
import { initBusinessContactForm } from './businessContactForm';
import { handleYear } from './handleYear';
import { initSentry } from './initSentry';
// import { addSourceForUpgradeToProLinks } from './events/helpers';
import {
	clearbitRevealCompany,
	identifyCustomerIOUser,
	initApollo,
	initHubspot,
	initializeClearbit,
	initializeCustomerIo,
	initializeFacebookPixel,
	initLinkedInInsightTag,
	onCookieConsentChanged,
	onCookieConsentClosedAndValid,
	OneTrustCookiesGroup,
} from '@vctr-libs/vctr-integrations';
import { ABMTrackingLogPageView, getABMTrackingData } from './ABMTracking';
import {
	initializeHubspotForms,
	initializeHubspotFormsEvents,
} from './hubspotForms';
import { handleFlowhuntEvents } from './events/handleFlowhuntEvents';
// import { initElkApm } from "./initElkApm";
declare global {
	interface Window {
		vctrStartWebflow: () => void;
		vctrUser: UserBase;
		vctrWebsite: {
			state?: 'loading' | 'started';
		};
		OnetrustActiveGroups: string;
	}
}

const vctrWebflowLoaded = new CustomEvent('vctrWebflowLoaded');

export const start = async (pageNamePrefix = 'wfPage-v4') => {
	if (window.vctrWebsite?.state === 'started') {
		console.log('Website scripts are already initialized');
	}

	window.vctrWebsite ||= {};
	window.vctrWebsite.state = 'loading';

	document.dispatchEvent(vctrWebflowLoaded);

	initSentry();
	// TODO turn on when ELK is available without VPN
	// initElkApm();

	paginateBlog();

	const path = document.location.pathname
		.split('/')
		.filter(item => item !== '');
	attachLogout();
	let user: UserBase = null;
	try {
		user = await getCurrentUser();
		window.vctrUser = user;
	} catch (e) {
		console.warn(e);
		showElementsForUnauhtorisedUsers();
	}

	const eventType = (pageNamePrefix + '-' + path.join('-')).substring(
		0,
		50,
	);
	const eventLogger = new EventLogger(eventType, user);
	await clearbitRevealCompany(eventLogger, user);

	try {
		if (user) {
			setProfile(user);
			showElementsForAuhtorisedUsers();
			if (path.length === 0 || (path.length === 1 && path[0] === 'webflow')) {
				// HomePage
				hideLoginForm();
			}
			identifyCustomerIOUser({ user });
		} else {
			showElementsForUnauhtorisedUsers();
		}
	} catch (e) {
		console.warn(e);
		showElementsForUnauhtorisedUsers();
	}

	initOneTrustCookies();
	initializeHubspotForms(user);
	initBusinessContactForm();
	handleCopyLink();
	handlePricing();
	handleProductHuntLink();
	ABMTrackingLogPageView();

	onCookieConsentClosedAndValid(() => {
		// we wait some time for GA to generate _gid cookie
		setTimeout(async () => {
			handleGADataLayerEvents(eventLogger, user);
			await handleFlowhuntEvents(eventLogger);
			handleHomepageReadyToDiveIn(eventLogger);
			handleContactFormSubmit(eventLogger);
			initializeHubspotFormsEvents(eventLogger);
		}, 400);
	});

	// TODO: waiting for backend fix
	// addSourceForUpgradeToProLinks();

	window.vctrWebsite.state = 'started';

	handleYear();

	const AbmData = getABMTrackingData();

	onCookieConsentChanged(groups => {
		if (groups.includes(OneTrustCookiesGroup.Targeting)) {
			initializeCustomerIo();
			initializeFacebookPixel();
			initLinkedInInsightTag();
			initHubspot();
			initApollo();
		}

		if (groups.includes(OneTrustCookiesGroup.Marketing)) {
			initializeClearbit({ user, AbmData });
		}
	});
};

window.vctrStartWebflow = start;
