export function writeString(
	text: string,
	buffer: ArrayBuffer,
	offset: number,
): number {
	const encoded = new TextEncoder().encode(text);
	const uint8 = new Uint8Array(buffer, offset, encoded.length + 1);
	for (let i = 0, I = encoded.length; i < I; ++i) {
		uint8[i] = encoded[i];
	}
	uint8[encoded.length] = 0;
	return offset + encoded.length + 1;
}

export function writeUint32(
	uint: number,
	buffer: ArrayBuffer,
	offset: number,
): number {
	const dataView = new DataView(buffer, offset, 4);
	dataView.setUint32(0, uint, true);
	return offset + 4;
}

export function writeUint8(
	uint: number,
	buffer: ArrayBuffer,
	offset: number,
): number {
	const dataView = new DataView(buffer, offset, 1);
	dataView.setUint8(0, uint);
	return offset + 1;
}

export function writeFloat32(
	float: number,
	buffer: ArrayBuffer,
	offset: number,
): number {
	const dataView = new DataView(buffer, offset, 4);
	dataView.setFloat32(0, float, true);
	return offset + 4;
}

export function readUint32(
	buffer: ArrayBuffer,
	offset: { offset: number },
): number {
	const dataView = new DataView(buffer, offset.offset, 4);
	offset.offset += 4;
	return dataView.getUint32(0, true);
}

export function readUint8(
	buffer: ArrayBuffer,
	offset: { offset: number },
): number {
	const dataView = new DataView(buffer, offset.offset, 1);
	offset.offset += 1;
	return dataView.getUint8(0);
}

export function readFloat32(
	buffer: ArrayBuffer,
	offset: { offset: number },
): number {
	const dataView = new DataView(buffer, offset.offset, 4);
	offset.offset += 4;
	return dataView.getFloat32(0, true);
}
