import React from 'react';
import { Toaster, ToastBar } from 'react-hot-toast';
import { useVctrTheme } from '../../hooks/useVctrTheme';

export const ToasterProvider = () => {
	const theme = useVctrTheme();

	return (
		<Toaster
			position="bottom-center"
			reverseOrder={false}
			gutter={8}
			containerClassName="Toaster__container"
			containerStyle={{
				zIndex: 9999,
			}}
			toastOptions={{
				// Define default options
				className: 'Toast__container',
				duration: 5000,
				// Default options for specific types
				success: {
					duration: 3000,
					//@ts-ignore
					theme: {
						primary: 'green',
						secondary: 'black',
					},
				},
			}}
		>
			{t => (
				<ToastBar
					toast={t}
					style={{
						...t.style,
						backgroundColor: theme.colors.c_dark_grey,
						borderRadius: theme.borderRadius[0],
						color: theme.colors.c_white,
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.font_size_small,
						fontWeight: theme.fontWeights.font_weight_small,
						minHeight: '32px',
					}}
				/>
			)}
		</Toaster>
	);
};
