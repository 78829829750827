import { isCookiesConsentEnv } from '@vctr-libs/vctr-utils';

export function initHubspot() {
	const isCookieConsentEnv = isCookiesConsentEnv();
	const isScriptInserted = !!document.querySelector('#hs-script-loader');
	if (!isCookieConsentEnv || isScriptInserted) return;

	const script = document.createElement('script');
	script.async = true;
	script.id = 'hs-script-loader';
	script.defer = true;
	script.src = '//js.hs-scripts.com/24079192.js';

	document.body.appendChild(script);
}
