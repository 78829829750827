// can be improved, e.g. array -> object with types
export const DashboardPaddleEvents = {
	add_coupon_click: 'add_coupon_click',
	applied_coupon: 'applied_coupon',
	add_vat_click: 'add_vat_click',
	applied_vat: 'applied_vat',
	checkout_loaded: 'checkout_loaded',
	checkout_completed: 'checkout_completed',
	checkout_payment_completed: 'checkout_payment_completed',
	checkout_error: 'checkout_error',
	checkout_showed: 'checkout_showed',
} as const;

export type DashboardPaddleEventName = keyof typeof DashboardPaddleEvents;

export type DashboardPaddleEventPayload = Record<
	DashboardPaddleEventName,
	DashboardPaddleEventInfo
>;

// loose fallback for event info/data
export type DashboardPaddleEventInfo = {
	[name: string]: string;
};
