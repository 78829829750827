export function equals(value1: any, value2: any): boolean {
	if (typeof value1 === 'object' && typeof value2 === 'object') {
		return Object.keys(value1 ?? {}).every(key =>
			equals(value1[key], value2[key]),
		);
	} else {
		return value1 === value2;
	}
}

export const arrayEquals = (a: any[], b: any[]): boolean =>
	Array.isArray(a) &&
	Array.isArray(b) &&
	a.length === b.length &&
	a.every((val, index) => val === b[index]);

export function shallowCopy<T>(object: T): T {
	return Object.assign({}, object) as T;
}

export function isNullValue(value: any) {
	return (
		value === null ||
		value === undefined ||
		value === '' ||
		value === 'null' ||
		value === 'undefined'
	);
}

export function isDefaultDOMRect(rect: DOMRect) {
	return (
		rect.x === 0 &&
		rect.y === 0 &&
		rect.width === 0 &&
		rect.height === 0 &&
		rect.top === 0 &&
		rect.right === 0 &&
		rect.bottom === 0 &&
		rect.left === 0
	);
}

export function offsetRect(rect: DOMRect, offset: { x: number; y: number }) {
	return {
		x: rect.x - offset.x,
		y: rect.y - offset.y,
		width: rect.width,
		height: rect.height,
		top: rect.top - offset.y,
		bottom: rect.bottom - offset.y,
		left: rect.left - offset.x,
		right: rect.right - offset.x,
	};
}
