enum Access {
	PRIVATE = 'private',
	CLONEABLE = 'cloneable',
	VIEWONLY = 'view_only',
	TEMPLATE = 'template',
}

enum Alignment {
	TOP_LEFT = 'top_left',
	TOP_RIGHT = 'top_right',
	BOTTOM_RIGHT = 'bottom_right',
}

enum DatabaseComparison {
	EQUAL = '=',
	GREATER = '>',
	LESS = '<',
	LIKE = 'LIKE',
}

enum ExportFormat {
	DAE = 'DAE',
	F3D = 'F3D',
	FBX = 'FBX',
	OBJ = 'OBJ',
	STL = 'STL',
	USDZ = 'USDZ',
	glB = 'glB',
	glTF = 'glTF',
}

enum ISO_639_1 {
	AUTO = 'auto',
	AA = 'aa',
	AB = 'ab',
	AF = 'af',
	AK = 'ak',
	AM = 'am',
	AR = 'ar',
	AN = 'an',
	AS = 'as',
	AV = 'av',
	AE = 'ae',
	AY = 'ay',
	AZ = 'az',
	BA = 'ba',
	BM = 'bm',
	BE = 'be',
	BN = 'bn',
	BI = 'bi',
	BO = 'bo',
	BS = 'bs',
	BR = 'br',
	BG = 'bg',
	CA = 'ca',
	CS = 'cs',
	CH = 'ch',
	CE = 'ce',
	CU = 'cu',
	CV = 'cv',
	KW = 'kw',
	CO = 'co',
	CR = 'cr',
	CY = 'cy',
	DA = 'da',
	DE = 'de',
	DV = 'dv',
	DZ = 'dz',
	EL = 'el',
	EN = 'en',
	EO = 'eo',
	ET = 'et',
	EU = 'eu',
	EE = 'ee',
	FO = 'fo',
	FA = 'fa',
	FJ = 'fj',
	FI = 'fi',
	FR = 'fr',
	FY = 'fy',
	FF = 'ff',
	GD = 'gd',
	GA = 'ga',
	GL = 'gl',
	GV = 'gv',
	GN = 'gn',
	GU = 'gu',
	HT = 'ht',
	HA = 'ha',
	SH = 'sh',
	HE = 'he',
	HZ = 'hz',
	HI = 'hi',
	HO = 'ho',
	HR = 'hr',
	HU = 'hu',
	HY = 'hy',
	IG = 'ig',
	IO = 'io',
	II = 'ii',
	IU = 'iu',
	IE = 'ie',
	IA = 'ia',
	ID = 'id',
	IK = 'ik',
	IS = 'is',
	IT = 'it',
	JV = 'jv',
	JA = 'ja',
	KL = 'kl',
	KN = 'kn',
	KS = 'ks',
	KA = 'ka',
	KR = 'kr',
	KK = 'kk',
	KM = 'km',
	KI = 'ki',
	RW = 'rw',
	KY = 'ky',
	KV = 'kv',
	KG = 'kg',
	KO = 'ko',
	KJ = 'kj',
	KU = 'ku',
	LO = 'lo',
	LA = 'la',
	LV = 'lv',
	LI = 'li',
	LN = 'ln',
	LT = 'lt',
	LB = 'lb',
	LU = 'lu',
	LG = 'lg',
	MH = 'mh',
	ML = 'ml',
	MR = 'mr',
	MK = 'mk',
	MG = 'mg',
	MT = 'mt',
	MN = 'mn',
	MI = 'mi',
	MS = 'ms',
	MY = 'my',
	NA = 'na',
	NV = 'nv',
	NR = 'nr',
	ND = 'nd',
	NG = 'ng',
	NE = 'ne',
	NL = 'nl',
	NN = 'nn',
	NB = 'nb',
	NO = 'no',
	NY = 'ny',
	OC = 'oc',
	OJ = 'oj',
	OR = 'or',
	OM = 'om',
	OS = 'os',
	PA = 'pa',
	PI = 'pi',
	PL = 'pl',
	PT = 'pt',
	PS = 'ps',
	QU = 'qu',
	RM = 'rm',
	RO = 'ro',
	RN = 'rn',
	RU = 'ru',
	SG = 'sg',
	SA = 'sa',
	SI = 'si',
	SK = 'sk',
	SL = 'sl',
	SE = 'se',
	SM = 'sm',
	SN = 'sn',
	SD = 'sd',
	SO = 'so',
	ST = 'st',
	ES = 'es',
	SQ = 'sq',
	SC = 'sc',
	SR = 'sr',
	SS = 'ss',
	SU = 'su',
	SW = 'sw',
	SV = 'sv',
	TY = 'ty',
	TA = 'ta',
	TT = 'tt',
	TE = 'te',
	TG = 'tg',
	TL = 'tl',
	TH = 'th',
	TI = 'ti',
	TO = 'to',
	TN = 'tn',
	TS = 'ts',
	TK = 'tk',
	TR = 'tr',
	TW = 'tw',
	UG = 'ug',
	UK = 'uk',
	UR = 'ur',
	UZ = 'uz',
	VE = 've',
	VI = 'vi',
	VO = 'vo',
	WA = 'wa',
	WO = 'wo',
	XH = 'xh',
	YI = 'yi',
	YO = 'yo',
	ZA = 'za',
	ZH = 'zh',
	ZU = 'zu',
}

export enum TokenTypeColumn {
	FIGMA = 'figma',
	API = 'api',
}

export enum EmailInvitationStatusColumn {
	ACCEPTED = 'accepted',
	ACTIVE = 'active',
	DELETED = 'deleted',
	EXPIRED = 'expired',
}

enum MemberPermission {
	COLLABORATE_2024 = 'collaborate_2024',
	EDIT = 'edit',
	EDIT_2024 = 'edit_2024',
	MANAGE_2024 = 'manage_2024',
	VIEW = 'view',
	VIEW_2024 = 'view_2024',
}

enum OwnerPermission {
	OWNER = 'owner',
}

const Permission = { ...MemberPermission, ...OwnerPermission };
type Permission = MemberPermission | OwnerPermission;

enum NodeCategory {
	ENVIRONMENT = 'environment',
	CAMERA = 'camera',
	MODEL = 'model',
	LIGHT = 'light',
	MATERIAL = 'material',
	TEXTURE = 'texture',
	USER_INTERFACE = 'user_interface',
	ANIMATION = 'animation',
	INTERACTION = 'interaction',
	INTERACTION_GROUP = 'interaction_group',
	INTERACTION_DEPRECATED = 'interactions', // This value is deprecated but still supported.
	SELECTION = 'selection',
	TRANSFORMATION_STATE = 'transformation_state',
	GEOMETRY_UV1_DATA = 'geometry_uv1_data',
	SOUND = 'sound',
	EVENT_API = 'event_api',
	VARIABLE = 'variable',
	DATASET = 'dataset',
}

enum Placement {
	GROUND = 'ground',
	WALL = 'wall',
	CEILING = 'ceiling',
}

enum UserRole {
	ADMINISTRATOR = 'administrator',
	STANDARD = 'standard',
}

enum CommentNotification {
	ALL = 'all',
	NONE = 'none',
	MENTIONS_ONLY = 'mentions_only',
}

enum SentryTagAction {
	CLONE = 'clone',
	COPY = 'copy',
	CREATE = 'create',
	DELETE = 'delete',
	GET = 'get',
	MIGRATE = 'migrate',
	UPDATE = 'update',
}

enum QueryScope {
	PUBLIC = 'PUBLIC',
	PRIVATE = 'PRIVATE', // OWNER + MEMBER
	ADMIN = 'ADMIN',

	// Not implemented yet:
	// OWNER = 'OWNER',
	// MEMBER = 'MEMBER',

	SHARED_PUBLIC = 'SHARED_PUBLIC',
	// Not sure if makes sense:
	// SHARED_PRIVATE = 'SHARED_PRIVATE'
}

enum PaddleSubscriptionStatus {
	ACTIVE = 'active',
	DELETED = 'deleted',
	PAST_DUE = 'past_due',
	PAUSED = 'paused',
	TRIALING = 'trialing',
}

enum SecurityLogType {
	LOGIN_ATTEMPT = 'login_attempt',
	PASSWORD_RESET = 'password_reset',
}

export {
	Access,
	Alignment,
	CommentNotification,
	DatabaseComparison,
	ExportFormat,
	ISO_639_1,
	MemberPermission,
	NodeCategory,
	PaddleSubscriptionStatus,
	Permission,
	Placement,
	QueryScope,
	SecurityLogType,
	SentryTagAction,
	UserRole,
};
