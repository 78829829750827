import { RefObject } from 'react';
import { DROPDOWN_WINDOW_GAP } from '../Dropdown';


export enum DropdownEnumPositionX {
	LEFT = 'left',
	RIGHT = 'right'
}

export enum DropdownEnumPositionY {
	TOP = 'top',
	BOTTOM = 'bottom'
}

export const stylePosition = (
	positionX: DropdownEnumPositionX,
	positionY: DropdownEnumPositionY,
	autoPlacement: boolean,
	ref: RefObject<HTMLElement>
) => {
	type CSSPosition = {
		top?: string | number;
		bottom?: string | number;
		left?: string | number;
		right?: string | number;
	};
	let position: CSSPosition = {};

	if (autoPlacement && ref?.current) {
		if (ref.current.getClientRects()[0].right + DROPDOWN_WINDOW_GAP >
			window.innerWidth) {
			position = { ...position, right: 0, left: 'auto' };
		}

		if (ref.current.getClientRects()[0].bottom + DROPDOWN_WINDOW_GAP >
			window.innerHeight) {
			position = {
				...position,
				bottom: 0,
				top: 'auto',
			};
		}
		return position;
	}

	if (positionX === DropdownEnumPositionX.LEFT) {
		position = {
			...position,
			left: 0,
		};
	} else {
		position = { right: 0 };
	}

	if (positionY === DropdownEnumPositionY.TOP) {
		position = {
			...position,
			top: 0,
		};
	} else {
		position = {
			...position,
			bottom: 0,
		};
	}

	return position;
};
