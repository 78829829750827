import { EventLogger } from '@vctr-libs/vctr-analytics';
import {
	clearbitIdentify,
	clearbitRevealCompany,
} from '@vctr-libs/vctr-integrations';
import { vctrFetch, fetchRequest } from '@vctr-libs/vctr-utils';

const TOPICS: { [key: string]: string } = {
	'account': 'Account',
	'billing-and-invoicing': 'Billing & Invoicing',
	'troubleshooting': 'Troubleshooting',
	'other': 'Other',
};

function getTopic(value: string) {
	return TOPICS[value] || value;
}

export const handleContactFormSubmit = async (eventLogger: EventLogger) => {
	const CONTACT_FORM_RECEPIENT = 'support@vectary.com';
	const forms = document.querySelectorAll<HTMLFormElement>(
		"[js='contact-form'] form",
	);
	forms.forEach(form => {
		form.addEventListener('submit', (e: Event) => {
			e.preventDefault();
			showUi(form.parentElement, 'hidden');

			const GetInputValue = (name: string) =>
				form.querySelector<HTMLInputElement>(`[name=${name}]`)?.value;
			const email = GetInputValue('email');
			const name = GetInputValue('firstname') + ' ' + GetInputValue('lastname');
			const topic = getTopic(String(GetInputValue('topic')));
			const message = `${name} | ${topic} | ${GetInputValue(
				'how-can-we-help',
			)}`;

			const data = {
				email: email,
				emailAddress: CONTACT_FORM_RECEPIENT,
				message: message,
				subject: topic,
			};
			vctrFetch(
				'/api/vctr-email/v1/contact',
				fetchRequest('POST', JSON.stringify(data)),
			)
				.then(resp => {
					resp.json().then(res => console.log(res));
					clearbitIdentify(email, {
						email,
						topic,
					});
					clearbitRevealCompany(eventLogger, undefined, true);
					showUi(form, 'success');
				})
				.catch(e => {
					console.log('Error submitting form ', e);
					showUi(form, 'error');
				});
		});
	});
};

function showUi(form: HTMLElement, type: 'success' | 'error' | 'hidden') {
	const successBlock = form.querySelector("[js='form-success']");
	const errorBlock = form.querySelector("[js='form-error']");

	if (!successBlock || !errorBlock) {
		console.error('Missing form-success & form-error blocks');
		return;
	}

	try {
		if (type === 'hidden') {
			(<HTMLInputElement>errorBlock).style.display = 'none';
			(<HTMLInputElement>successBlock).style.display = 'none';
		}

		if (type === 'error') {
			(<HTMLInputElement>errorBlock).style.display = 'block';
			(<HTMLInputElement>successBlock).style.display = 'none';
		}

		if (type === 'success') {
			(<HTMLInputElement>errorBlock).style.display = 'none';
			(<HTMLInputElement>successBlock).style.display = 'block';
		}
	} catch (e) {
		console.log(e);
	}
}
