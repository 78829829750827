export const colors = {
	c_warning: '#fa6c80',
	c_success: '#31d0aa',

	c_black: '#000000',
	c_white: '#ffffff',
	c_almost_white: '#fcfcfc',
	c_grey: '#595959',
	c_blue: '#545fd9',
	c_peach: '#ffae93',
	c_mid_grey: '#949494',
	c_dark_grey: '#313131',
	c_soft_grey: '#d0d0d0',
	c_light_grey: '#efefef',
	c_off_white: '#f7f7f7',
	c_almost_black: '#252525',

	c_dark_mode_primary: '#9d50ff',
	c_light_mode_primary: '#6100ff',

	c_primary_red: '#fb3363',
	c_primary_blue: '#18a0fb',
	c_primary_green: '#1ed760',

	c_secondary_green: '#3ff07e',
	c_secondary_red: '#ff7798',
	c_secondary_blue: '#58bcff',
};

export const fontSizes = {
	font_size_xsmall: '9px',
	font_size_small: '11px',
	font_size_medium: '13px',
	font_size_large: '14px',
	font_size_xlarge: '18px',
	font_size_xxlarge: '26px',
};

export const fontFamily = {
	font_sans: " 'Open Sans', sans-serif",
	font_inter: 'Inter, Helvetica, Arial, sans-serif',
};

export const fontWeight = {
	font_weight_medium: 700,
	font_weight_small: 600,
	font_weight_xsmall: 400,
};
