let scriptLoaded: Promise<void>;

export async function initImportSystemJsMap({
	imports,
}: {
	imports: { [key: string]: string };
}) {
	// @ts-ignore
	const _System = window.System;

	if (!_System?.import || !_System?.constructor?.prototype?.addImportMap) {
		scriptLoaded = new Promise<void>((res, _rej) => {
			const systemJsScript = document.createElement('script');
			systemJsScript.src =
				'https://cdn.jsdelivr.net/npm/systemjs/dist/system.js';
			systemJsScript.onload = () => res();
			document.head.appendChild(systemJsScript);
		});
	}

	if (!document.querySelector('script[type=systemjs-importmap]')) {
		try {
			await (async () => {
				const importMapsScript = document.createElement('script');
				importMapsScript.type = 'systemjs-importmap';
				importMapsScript.text = JSON.stringify({ imports });
				document.head.appendChild(importMapsScript);

				// TODO add observer to make sure the script is loaded
			})();
		} catch (e) {
			console.error('Error adding import map script', e);
		}
	}
}

/**
 * @module @vctr-libs/vctr-utils/importSystemJsModules
 * @description
 * This module provides a function to import modules using SystemJS.
 * It is used by the VCTR Engine to defer the load of JS code, which is only needed if a specific functionality is required.
 *
 * You must've initialized the import map with "initImportSystemJsMap" first.
 * @example
 * ```
 * import { initImportSystemJsMap, importSystemJsModules } from "@vctr-libs/vctr-utils";
 *
 * const imports = {
 *     'opentype.js': `${AppContext.conf.assetsPath}/vendor/opentype.js`,
 *     'paper-full.js': `${AppContext.conf.assetsPath}/vendor/paper-full.js`,
 *     'paperjs-offset.js': `${AppContext.conf.assetsPath}/vendor/paperjs-offset.js`,
 * };
 * initImportSystemJsMap(imports);
 *
 * const opentype = await importSystemJsModules('opentype.js');
 * ```
 * Then use lib as you would normally do.
 */

export async function importSystemJsModules(moduleName: string) {
	// @ts-ignore
	let _System = window.System;
	if (!_System?.import || !_System?.constructor?.prototype?.addImportMap) {
		await scriptLoaded;
		// @ts-ignore
		_System = window.System;
		if (!_System?.import || !_System?.constructor?.prototype?.addImportMap) {
			throw new Error('SystemJS failed to import/initialize');
		}
	}

	console.debug(`Loading module ${moduleName} via SystemJS`);
	return (
		_System
			.import(moduleName)
			// @ts-ignore
			.then(({ default: importedModule }) => {
				console.log(`Loaded module ${moduleName} via SystemJS`, importedModule);
				return importedModule;
			})
			// @ts-ignore
			.catch(e => {
				console.error(`Error while importing module ${moduleName}:`, e);
				throw new Error(`Error while importing module ${moduleName}`);
			})
	);
}
