import { EmailInvitation } from '../workspaces/interface/interface';
import { ActivityEntry } from '../activity/interface';
import { TokenTypeColumnEnum } from '@vctr-api/vctr-resources';

export enum TermsState {
	none = 'none',
	current = 'current',
	obsolete = 'obsolete',
}

// TODO apply this type to all places where it is used (e.g. vctr-user)
// export type UserQuestionnaire = {
//     occupation: {
//         value: QuestionnaireOccupation;
//     },
//     /** TODO find out what is it and describe here */
//     welcome: {
//         [id: string]: any
//     },
//     reasonToLeave: {
//         value: ReasonsToLeave | string;
//         planName: PlanIds;
//         subscribedForDays: number;
//         workspaceId: string,
//     }
// };

export type UserQuestionnaire = {
	[id: string]: any;
};

export type UserTokens = {
	id: string;
	name: string;
	type: TokenTypeColumnEnum;
	token: string;
}[];

export type UserBase = {
	uuid: string;
	email: string | null;
	firstName: string;
	lastName: string;
	name: string;
	bio: string;
	location: string;
	role: string;
	profileUrl: string;
	created: string;
	deleted: Date;
	verified: boolean;
	notifications: boolean;
	profile_picture: string;
	gdpr: TermsState;
	terms: TermsState;
	geo: GeoInfo;
	emailIdentity: boolean;
	welcomeEmailDelivered?: boolean;
	mailsComments: boolean;
	questionnaire: UserQuestionnaire;
	trial_used: boolean;
	pro_used: boolean;
	tokens: UserTokens;
	emailInvitations: EmailInvitation[];
	workspacePermissions?: string[];
	isCiamUser?: boolean;
};

export type UserApiSearchPaymentsOptions = {
	deleted?: boolean;
	deletedFrom?: Date;
	deletedTo?: Date;
	email?: string;
	registeredFrom?: Date;
	registeredTo?: Date;
	coupon?: boolean;
	paymentStatus?: Array<'free' | 'pending' | 'confirmed' | 'cancelled'>;
	// TODO remove this since there's questionnaire, but check the usage first
	professions?: Array<
		| 'graphicDesigner'
		| 'gameDesigner'
		| 'webDesigner'
		| 'artistdeveloper'
		| 'ecommerce'
		| 'socialMedia'
		| 'student'
		| 'teacher'
		| 'businessOwner'
		| 'mediaNews'
		| 'architect'
		| 'freelancerOrAgency'
		| 'makerHobbyist'
		| 'other'
	>;
	startBillDateFrom?: Date;
	startBillDateTo?: Date;
	cancelledDateFrom?: Date;
	cancelledDateTo?: Date;
	[key: string]: any;
};

export type GeoInfo = {
	country?: string;
	country_code?: string;
	ip?: string;
	timezone?: string;
};

export enum IdentityType {
	email = 'email',
	google = 'google',
	ciam = 'ciam',
}

export type SignupinData = {
	auth_token: string;
	identity_type: IdentityType;
	identifier: string;
	email: string;
	name: string;
	firstname?: string;
	lastname?: string;
	profile_picture: string;
	gdpr?: boolean;
	terms?: boolean;
	mails?: boolean;
	occupation?: string;
};

// SHOULD THESE BE HERE??
export type UserActivity = {
	user: UserBase;
	activity: ActivityEntry<UserBase>[];
};

export type UserEmailDetails = {
	email?: string;
	newsletter?: boolean;
	notifyComments?: CommentNotification;
	undeliverable?: boolean;
	verificationToken?: string | null;
};

export type UserLocationDetails = {
	location?: string;
	geo?: GeoIpLoggedData;
};

export type GeoIpLoggedData = {
	accuracy: number;
	city: string;
	country_code: string;
	region: string;
	timezone: string;
};

export type InputModifier = {
	amount: number;
	description: ModifierReasons | string;
};

export enum ModifierReasons {
	EDIT_SEAT = 'Extra edit seat',
	VIEW_SEAT = 'Extra view seat',
	VIEWS = 'Extra views',
}

export enum CheckoutReasons {
	PRICING = 'pricing',
	COME_BACK = 'comeBack',
	CREATE_TEAM = 'createTeam',
	REACTIVATE_TEAM = 'reactivateTeam',
	LOCKED_MODEL = 'lockedModel',
	TOO_MANY_MODELS = 'tooManyModels',
	MORE_VIEWS = 'moreViews',
	UPGRADE = 'upgrade',
	UPGRADE_YEARLY_LITE = 'upgradeYearlyLite',
	UPGRADE_YEARLY_PREMIUM = 'upgradeYearlyPremium',
	UPGRADE_MONTHLY_PREMIUM = 'upgradeMonthlyPremium',
	PROMO = 'promo',
	THREE_D_EXPORT = '3DExport',
	IMAGE_EXPORT = 'imageExport',
	SHARE = 'share',
	COMMENTS = 'comments',
	LOCKED_MODE = 'lockedMode',
	SALE_BANNER = 'saleBanner',
	SALE_POPUP = 'salePopup',
}

export type Checkout = {
	checkout: {
		completed: boolean;
		passthrough: string;
	};
	product: {
		id: number;
	};
	checkout_source: string;
	checkout_reason?: string;
	event_time?: string;
};

export type CheckoutSuccess = {
	data: any;
	reason: CheckoutReasons;
};

export type CheckoutUser = {
	last_checkout: Checkout;
} & UserBase;

export enum CommentNotification {
	ALL = 'all',
	NONE = 'none',
	MENTIONS_ONLY = 'mentions_only',
}

export enum QuestionnaireTopic {
	Occupation = 'occupation',
	ReasonToLeave = 'reasonToLeave',
}

export enum ReasonsToLeave {
	IAmInAnotherWorkspace = 'I’m in another Pro workspace',
	NeedADifferentPlan = 'Need a different plan',
	ItWasTooExpensive = 'It was too expensive',
	ServiceNoLongerNeeded = 'Service no longer needed',
	ItWasTooDifficultToSetup = 'It was too difficult to setup',
	SwitchingToAnotherSolution = 'Switching to another solution',
	IWasTesting = 'I was testing',
	Other = 'Other',
}

export enum QuestionnaireOccupation {
	ProductDesign = 'Product Design',
	WebDesign = 'Web Design',
	SoftwareDevelopment = 'Software Development',
	EngineeringCad = 'Engineering (CAD)',
	Marketing = 'Marketing',
	ProjectManagement = 'Project Management',
	Leadership = 'Leadership',
	Research = 'Research',
	Educator = 'Educator',
	Student = 'Student',
	Other = 'Other',
}
