export function readSvgFileAsSVGElement(file: Blob): Promise<SVGSVGElement> {
	return new Promise(resolve => {
		const fileReader = new FileReader();
		fileReader.onload = (event: ProgressEvent) => {
			const helperDivElement = document.createElement('div');
			helperDivElement.innerHTML = fileReader.result as string;
			const svgElement = helperDivElement.querySelector('svg');

			resolve(svgElement);
		};
		fileReader.readAsText(file);
	});
}

export function changeResolution(
	svgElement: SVGSVGElement,
	textureResolution: -1 | 128 | 256 | 512 | 1024 | 2048 | 4096,
	doNotPreserveAspectRatio: boolean,
): SVGSVGElement {
	const originalSize = getViewPortSizeInUserUnits(svgElement);

	let newWidth: number, newHeight: number;
	if (originalSize.width >= originalSize.height) {
		newWidth =
			textureResolution === -1 ? originalSize.width : textureResolution;
		newHeight = (newWidth * originalSize.height) / originalSize.width;
	} else {
		newHeight =
			textureResolution === -1 ? originalSize.height : textureResolution;
		newWidth = (newHeight * originalSize.width) / originalSize.height;
	}

	const newElement = svgElement.cloneNode(true) as SVGSVGElement;

	newElement.setAttribute('width', Math.floor(newWidth).toString());
	newElement.setAttribute('height', Math.floor(newHeight).toString());

	if (doNotPreserveAspectRatio) {
		newElement.setAttribute('preserveAspectRatio', 'none');
	}

	return newElement;
}

export function getViewPortSizeInUserUnits(svgElement: SVGSVGElement): {
	width: number;
	height: number;
} {
	let width: number, height: number;

	switch (svgElement.width.baseVal.unitType) {
		case SVGLength.SVG_LENGTHTYPE_PERCENTAGE:
			width =
				(svgElement.width.baseVal.valueInSpecifiedUnits / 100) *
				svgElement.viewBox.baseVal.width;
			break;
		default:
			width = svgElement.width.baseVal.value;
			break;
	}

	switch (svgElement.height.baseVal.unitType) {
		case SVGLength.SVG_LENGTHTYPE_PERCENTAGE:
			height =
				(svgElement.height.baseVal.valueInSpecifiedUnits / 100) *
				svgElement.viewBox.baseVal.height;
			break;
		default:
			height = svgElement.height.baseVal.value;
			break;
	}

	return { width, height };
}

export function createSVGBlobFromSVGElement(svgElement: SVGSVGElement) {
	const svgString = new XMLSerializer().serializeToString(svgElement);
	const svgBlob = new Blob([svgString], {
		type: 'image/svg+xml;charset=utf-8',
	});
	return svgBlob;
}

export function getGeometrySizeAndOrigin(
	viewPort: { width: number; height: number },
	viewBox: { x: number; y: number; width: number; height: number },
	preserveAspectRatio: SVGPreserveAspectRatio,
): {
	size: { width: number; height: number };
	origin: { x: number; y: number };
	scale?: { x: number; y: number };
} {
	let origin = {
		x: viewBox.x + viewBox.width / 2,
		y: viewBox.y + viewBox.height / 2,
	};

	let size = {
		width: viewBox.width,
		height: viewBox.height,
	};
	if (size.width === 0) {
		size.width = viewPort.width;
	}
	if (size.height === 0) {
		size.height = viewPort.height;
	}

	const widthRatio = viewBox.width / viewPort.width;
	const heightRatio = viewBox.height / viewPort.height;

	// TODO: this condition equals to equalsFloat from vctr-math package
	// that is already dependent on vctr-utils -> improve the flow
	if (Math.abs(widthRatio - heightRatio) < 1e-5) {
		return { size, origin };
	}

	let fixedWidth: boolean;
	let translationX: number = 0,
		translationY: number = 0;

	switch (preserveAspectRatio.meetOrSlice) {
		case preserveAspectRatio.SVG_MEETORSLICE_MEET:
		case preserveAspectRatio.SVG_MEETORSLICE_UNKNOWN:
			fixedWidth = widthRatio > heightRatio;
			break;
		case preserveAspectRatio.SVG_MEETORSLICE_SLICE:
			fixedWidth = widthRatio < heightRatio;
			break;
	}

	if (fixedWidth) {
		size.height = widthRatio * viewPort.height;
	} else {
		size.width = heightRatio * viewPort.width;
	}

	if (
		preserveAspectRatio.align ===
		preserveAspectRatio.SVG_PRESERVEASPECTRATIO_NONE
	) {
		let scale = { x: 1, y: 1 };
		if (fixedWidth) {
			scale.y = 1 / widthRatio;
		} else {
			scale.x = 1 / heightRatio;
		}

		return { size, origin, scale };
	}

	if (fixedWidth) {
		switch (preserveAspectRatio.align) {
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMINYMIN:
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMIDYMIN:
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMAXYMIN:
				translationY = (size.height - viewBox.height) / 2;
				break;
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMINYMAX:
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMIDYMAX:
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMAXYMAX:
				translationY = (-size.height + viewBox.height) / 2;
				break;
		}
	} else {
		switch (preserveAspectRatio.align) {
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMINYMIN:
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMINYMID:
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMINYMAX:
				translationX = (size.width - viewBox.width) / 2;
				break;
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMAXYMIN:
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMAXYMID:
			case preserveAspectRatio.SVG_PRESERVEASPECTRATIO_XMAXYMAX:
				translationX = (-size.width + viewBox.width) / 2;
				break;
		}
	}

	origin.x += translationX;
	origin.y += translationY;

	return { size, origin };
}
