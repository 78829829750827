import {
	Plan,
	PlanIds,
	UserBase,
	WorkspaceBaseDashboard,
	isUserOwner,
	isWorkspaceUpgradable,
	BillingPeriod,
} from '@vctr-libs/vctr-api-client';
import { WorkspaceOptions } from './components';
// export function getSaveOnYearly({ plans, monthlyPlanId, yearlyPlanId }: { plans: Plan[], monthlyPlanId: PlanIds, yearlyPlanId: PlanIds }): number | undefined {
//     const monthlyPlanPrice = plans.find(plan => plan.id === monthlyPlanId)?.price;
//     const yearlyPlanPrice = plans.find(plan => plan.id === yearlyPlanId)?.price;
//     console.log("getSaveOnYearly", { plans, monthlyPlanId, monthlyPlanPrice, yearlyPlanId, yearlyPlanPrice });
//     if (!monthlyPlanPrice || !yearlyPlanPrice) return undefined;
//     return monthlyPlanPrice * 12 - yearlyPlanPrice;
// }

export const CURRENT_COUPON_CODES = {
	EDU_Q2_2024: 'EDU_2024',
	YEARLY_Q4_2024: 'ANNUAL99',
} as const;

/** Get amound saved on yearly plan:
 There's a product requirement to "criss-cross" compare pricings between maximum of `12 x monthly payments` vs `12 x yearly with student_discount of 50%` — this "calculation" is counter–logical to implement in code, that's why I'm hardcoding it */
export const STUDENT_DISCOUNT = {
	/** used in UI only */
	// DISPLAY_VALUE_IN_PERCENT: 60,
	/** should match the Paddle coupon */
	// REAL_VALUE_IN_PERCENT: 50,
	REAL_VALUE_FLAT: 189,
	// COUPON_CODE_PERCENTS: 'VCTR_STUDENTS_2023',
	// COUPON_CODE_FLAT: 'STUDENTS_2024',
	COUPON_CODE_FLAT: CURRENT_COUPON_CODES.EDU_Q2_2024,
} as const;

export type StudentDiscount = typeof STUDENT_DISCOUNT;

export type PaddlePrice = {
	initialPrice: number;
	recurringPrice: number;
};

export function getSaveOnYearly({
	plans,
	couponDiscountInPercent, // componentState
}: {
	plans: Plan[];
	couponDiscountInPercent?: number;
	// componentState: GetProComponentStates
}): number | null {
	const monthlyPlan = plans.find(plan => plan.billing === BillingPeriod.MONTH);
	const yearlyPlan = plans.find(plan => plan.billing === BillingPeriod.YEAR);

	const monthlyPlanPrice = monthlyPlan?.price;
	const yearlyPlanPrice = yearlyPlan?.price;

	if (!monthlyPlanPrice || !yearlyPlanPrice) return null;

	if (couponDiscountInPercent) {
		return (
			monthlyPlanPrice * 12 -
			yearlyPlanPrice * (1 - couponDiscountInPercent / 100)
		);
	} else {
		return monthlyPlanPrice * 12 - yearlyPlanPrice;
	}
}

export const getWorkspacesSelectOptions = ({
	user,
	workspaces,
}: {
	user: UserBase;
	workspaces: WorkspaceBaseDashboard[];
}): WorkspaceOptions[] => {
	if (!user || !workspaces) return [];
	return workspaces
		.filter(
			w => isUserOwner({ user, workspace: w }) && isWorkspaceUpgradable(w),
		)
		.map(workspace => ({
			title: workspace.name,
			value: workspace.uuid,
		}));
};

/** sort from high to low price, so Yearly is above */
export const getSortedPlans = ({
	availablePlanIds,
	plans,
}: {
	availablePlanIds: PlanIds[];
	plans: Plan[];
}): Plan[] => {
	if (!(plans?.length > 0) || !(availablePlanIds?.length > 0)) {
		return [];
	}
	return plans
		.filter(plan => availablePlanIds.includes(plan.id))
		.sort((a, b) => a.price - b.price);
};
