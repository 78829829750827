// can be improved, e.g. array -> object with types
export const DashboardEvents = {
	add_user_seat: 'add_user_seat',
	remove_user_seat: 'remove_user_seat',
	create_workspace: 'create_workspace',
	remove_workspace: 'remove_workspace',
	leave_workspace: 'leave_workspace',
	upgrade_workspace: 'upgrade_workspace',
	add_workspace_member: 'add_workspace_member',
	upgrade_to_pro_click: 'upgrade_to_pro_click',
	discover_pro_click: 'discover_pro_click',
	discover_pro_shown: 'discover_pro_shown',
	discover_pro_modal_auto_open: 'discover_pro_modal_auto_open',
	discover_pro_badge_shown: 'discover_pro_badge_shown',
	get_pro_modal_auto_open: 'get_pro_modal_auto_open',
	get_pro_for_educators_modal_auto_open:
		'get_pro_for_educators_modal_auto_open',
	get_pro_for_educators_badge_shown: 'get_pro_for_educators_badge_shown',
	get_pro_for_educators_message_sent: 'get_pro_for_educators_message_sent',
	get_pro_trial_modal_auto_open: 'get_pro_trial_modal_auto_open',
	get_pro_cta_click: 'get_pro_cta_click',
	get_pro_close_click: 'get_pro_close_click',
	get_pro_trial_close_click: 'get_pro_trial_close_click',
	get_pro_trial_cta_click: 'get_pro_trial_cta_click',
	dashboard_start_new_local_session: 'dashboard_start_new_local_session',
	contact_us_question_mark: 'contact_us_question_mark',
	community: 'community',
	take_platform_tour: 'take_platform_tour',
	docs: 'docs',
	project_limit_shown: 'project_limit_shown',
	add_coupon_click: 'add_coupon_click',
	applied_coupon: 'applied_coupon',
	add_vat_click: 'add_vat_click',
	applied_vat: 'applied_vat',
	checkout_loaded: 'checkout_loaded',
	checkout_completed: 'checkout_completed',
	checkout_payment_completed: 'checkout_payment_completed',
	checkout_error: 'checkout_error',
	checkout_close: 'checkout_close',
	checkout_back: 'checkout_back',
	checkout_click_upgrade: 'checkout_click_upgrade',
	checkout_showed: 'checkout_showed',
	cloning_start: 'cloning_start',
	cloning_finished: 'cloning_finished',
	cloning_failed: 'cloning_failed',
	usetiful_modal_open: 'usetiful_modal_open',
} as const;

export type DashboardEventName = keyof typeof DashboardEvents;

/**
 * Event payload object for `logEvent`
 */
export type DashboardEventPayload = Record<
	DashboardEventName,
	DashboardEventInfo
>;

// loose fallback for event info/data
export type DashboardEventInfo = {
	[name: string]: string;
};
