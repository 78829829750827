import {
	ErrorWithExtras,
	TextFilterEnum,
	TokenTypeColumnEnum,
	UserRoleEnum,
} from '@vctr-api/vctr-resources';
import {
	Cloud,
	createCloudUrl,
	fetchRequest,
	fetchResult,
	uuidToUuidBase62,
	vctrFetch,
} from '@vctr-libs/vctr-utils';
import { ActivityEntry } from '../activity/interface';
import { ClientApi } from '../clientApi';
import { handleError } from '../utils/handleError';
import {
	CommentNotification,
	GeoInfo,
	GeoIpLoggedData,
	IdentityType,
	QuestionnaireTopic,
	SignupinData,
	TermsState,
	UserActivity,
	UserBase,
	UserEmailDetails,
	UserLocationDetails,
} from './interface';
import { getSDK } from '../utils';

const PLATFORM_API_AUTH_URL = '/api/auth';

type UserAndActivity = {
	user: UserBase;
	activity: ActivityEntry<UserBase>;
};

const createResApiUrl = (cloud?: Cloud) => {
	let url: string;
	if (cloud !== undefined) {
		url = createCloudUrl(cloud);
		if (url.indexOf('amazonaws.com') >= 0) {
			// It is an internal URL
			// /api/vctr-resources/v1 is only required for the Gateway, so skip this part completely.
		} else {
			url += '/api/vctr-resources/v1';
		}
	} else {
		url = '/api/vctr-resources/v1';
	}

	return url.replace(/7020/g, '7200');
};

const sdk = (cloud?: Cloud, reqheaders?: HeadersInit) =>
	getSDK({ cloud, extraHeaders: reqheaders });

export const userBaseQueryFields = {
	id: true,
	username: true,
	firstName: true,
	lastName: true,
	biography: true,
	picture: true,
	questionnaire: true,
	gdpr: true,
	isCiamUser: true,
	isGdprCurrent: true,
	tou: true,
	isTouCurrent: true,
	trialUsed: true,
	proUsed: true,
	internalNotes: true,
	createdAt: true,
	emailDetails: {
		email: true,
		newsletter: true,
		notifyComments: true,
		undeliverable: true,
		verificationToken: true,
	},
	locationDetails: {
		location: true,
		geo: true,
	},
	accessDetails: {
		accessToken: true,
		accessTokenExpiration: true,
		refreshToken: true,
		refreshTokenExpiration: true,
		passwordIdentifier: true,
		passwordResetToken: true,
		facebookIdentifier: true,
		googleIdentifier: true,
		ciamIdentifier: true,
	},
	permissions: {
		role: true,
	},
	tokens: {
		id: true,
		name: true,
		type: true,
		token: true,
	},
	emailInvitations: {
		id: true,
		email: true,
		permission: true,
		status: true,
		createdAt: true,
		workspace: {
			id: true,
		},
	},
};

// FIXME The types coming from the API are currently all optional. We should get proper types from the API, and then replace "any" here.
export function userBaseFromResponse(user: any): UserBase {
	return {
		uuid: user.id,
		email: user.emailDetails?.email,
		name: user.username,
		firstName: user.firstName,
		lastName: user.lastName,
		bio: user.biography,
		location: user.locationDetails?.location,
		role: user.permissions?.role,
		profileUrl: '', //TODO
		created: user.createdAt,
		deleted: undefined,
		verified: !user.emailDetails?.verificationToken,
		notifications: user.emailDetails?.newsletter,
		profile_picture: user.picture,
		gdpr:
			user.isGdprCurrent === true ? TermsState.current : TermsState.obsolete,
		terms:
			user.isTouCurrent === true ? TermsState.current : TermsState.obsolete,
		geo: user.locationDetails?.geo ?? {},
		emailIdentity: !!user.emailDetails?.email,
		mailsComments: mapNotifyCommentsToBoolean(
			user.emailDetails?.notifyComments as CommentNotification,
		),
		questionnaire: user.questionnaire,
		trial_used: user.trialUsed,
		pro_used: user.proUsed,
		tokens: user.tokens,
		emailInvitations: user.emailInvitations,
		workspacePermissions: user.rolePermissions,
		isCiamUser: user.isCiamUser,
	};
}

export const getCurrentUser = async (
	cloud?: Cloud,
	auth?: HeadersInit,
): Promise<UserBase | null> => {
	try {
		const response = await (
			await sdk(cloud, auth)
		)('query')({
			user: [{}, userBaseQueryFields],
		});
		return userBaseFromResponse(response.user);
	} catch (error) {
		// TODO fix missing error typing from Res API
		// don't throw an error if it's Unauthorized
		if (error.type !== 'Unauthorized') {
			handleError({
				error,
				name: 'Error getting current user',
			});
		}
		return null;
	}
};

/**
 * Returns the raw user object from the Resources API so it's not converted to old UserBase.
 *
 * @param uuid
 * @param cloud
 * @returns
 */
// TODO: Type output of this function.
export const getUserRaw = async (uuid: string, cloud?: Cloud): Promise<any> => {
	try {
		const response = await (
			await sdk(cloud)
		)('query')({
			user: [{ id: uuid }, userBaseQueryFields],
		});
		return response.user;
	} catch (error) {
		// TODO change to handleError without returning undefined,
		//  but make sure it doesn't break anything
		// There is also getUserWithError
		console.error('Error getting user:', JSON.stringify(error));
		return undefined;
	}
};

export const getCurrentUserVerification = async (
	cloud?: Cloud,
	auth?: HeadersInit,
): Promise<boolean | null> => {
	try {
		const response = await (
			await sdk(cloud, auth)
		)('query')({
			user: [
				{},
				{
					emailDetails: {
						verificationToken: true,
					},
				},
			],
		});
		return !response.user?.emailDetails?.verificationToken;
	} catch (error) {
		// TODO fix missing error typing from Res API
		// don't throw an error if it's Unauthorized
		if (error.type !== 'Unauthorized') {
			handleError({
				error,
				name: 'Error getting current user',
			});
		}
		return null;
	}
};

export const getUser = async (
	uuid: string,
	cloud?: Cloud,
): Promise<UserBase> => {
	try {
		const user = await getUserRaw(uuid, cloud);
		return userBaseFromResponse(user);
	} catch (error) {
		// TODO change to handleError without returning undefined,
		//  but make sure it doesn't break anything
		// There is also getUserWithError
		console.error('Error getting user:', JSON.stringify(error));
		return undefined;
	}
};

export const getUserWithError = async (
	uuid: string,
	cloud?: Cloud,
): Promise<UserBase> => {
	try {
		const response = await (
			await sdk(cloud)
		)('query')({
			user: [{ id: uuid }, userBaseQueryFields],
		});
		const result = userBaseFromResponse(response.user);

		return result;
	} catch (err) {
		console.error('Error getting user with error:', JSON.stringify(err));
		throw err;
	}
};

export const getUsers = async (
	uuids: string[],
	cloud?: Cloud,
	_asAService = false,
): Promise<UserBase[]> => {
	const operations: Record<string, any> = {};
	uuids.forEach((uuid, i) => {
		operations[`user${i}`] = {
			user: [{ id: uuid }, userBaseQueryFields],
		};
	});
	try {
		const response: Record<string, unknown> = await (
			await sdk(cloud)
		)('query')({
			__alias: {
				...operations,
			},
		});

		const userList = [];
		for (const propertyName in response) {
			const user = response[propertyName] as {
				id?: unknown;
				createdAt?: unknown;
				username?: string;
				biography?: string;
				picture?: string;
				questionnaire?: unknown;
				gdpr?: string;
				isGdprCurrent?: boolean;
				tou?: string;
				isTouCurrent?: boolean;
				trialUsed?: boolean;
				proUsed?: boolean;
				emailDetails?: {
					email?: string;
					newsletter?: boolean;
					notifyComments?: CommentNotification;
					undeliverable?: boolean;
					verificationToken?: unknown;
				};
				locationDetails?: {
					location?: string;
					geo?: unknown;
				};
				permissions?: {
					role?: UserRoleEnum;
				};
			};
			userList.push(userBaseFromResponse(user));
		}
		return userList;
	} catch (error) {
		console.error('Error getting users:', JSON.stringify(error));
		//throw `${error} Error getting users`;
		return [];
	}
};

export const getUserIdByEmail = async (
	email: string,
	cloud?: Cloud,
): Promise<string> => {
	try {
		const response = await (
			await sdk(cloud)
		)('query')({
			userByEmailOrHash: [{ emailOrHash: email }, { id: true }],
		});

		return response.userByEmailOrHash.id;
	} catch (error) {
		console.error('Error getting the user ID by email:', JSON.stringify(error));
		handleError({ error, name: `Error getting the user ID by email ${email}` });
	}
};

export const getUserByEmail = async (
	email: string,
	cloud?: Cloud,
): Promise<UserBase[]> => {
	try {
		const userId = await getUserIdByEmail(email, cloud);
		if (!userId) return undefined;

		return [await getUser(userId as string)];
	} catch (error) {
		console.error('Error getting the user by email:', JSON.stringify(error));
		handleError({ error, name: `Error getting the user by email ${email}` });
	}
};

export const getUserByName = async (
	name: string,
	cloud?: Cloud,
): Promise<UserBase[]> => {
	try {
		const response = await (
			await sdk(cloud)
		)('query')({
			allUsers: [
				{ username: [{ filter: TextFilterEnum.equal_to, value: name }] },
				{
					items: userBaseQueryFields,
				},
			],
		});
		return [userBaseFromResponse(response.allUsers.items[0])];
	} catch (error) {
		handleError({
			error,
			name: 'Error getting user by name',
			info: { name },
		});
	}
};

export const getSubscriptionEmails = async (
	results: string,
	page: string = '0',
	subscribed: 'true' | 'false' | undefined,
	cloud?: Cloud,
): Promise<string[]> => {
	try {
		const queryParams: { subscribed?: boolean; limit: number; cursor: string } =
			{
				limit: Number(results),
				cursor: page === '0' ? page : String(Number(page) * Number(results)),
			};

		if (subscribed !== undefined) {
			queryParams.subscribed = subscribed === 'true';
		}
		const response = await (
			await sdk(cloud)
		)('query')({
			emails: [
				{ ...queryParams },
				{
					items: {
						email: true,
					},
					cursor: true,
				},
			],
		});

		return response.emails.items.map(i => i.email);
	} catch (error) {
		handleError({
			error,
			name: 'User API: Error getting subscription emails',
			info: { results, page, subscribed },
		});
	}
};

export const getUserByProjectId = async (
	projectId: string,
	cloud?: Cloud,
): Promise<UserBase> => {
	try {
		const response = await (
			await sdk(cloud)
		)('query')({
			project: [
				{ id: projectId },
				{
					folder: {
						workspace: {
							owner: userBaseQueryFields,
						},
					},
				},
			],
		});
		return userBaseFromResponse(response.project.folder.workspace.owner);
	} catch (error) {
		handleError({
			error,
			name: 'Error getting user by projectId',
			info: { projectId },
		});
	}
};

export const forgotPass = async (
	email: string,
	cloud?: Cloud,
): Promise<void> => {
	const resApiUrl = createResApiUrl(cloud);
	let errorMessage = 'Error during password reset';

	try {
		const response = await vctrFetch(
			`${resApiUrl}/auth/forgot_password`,
			fetchRequest('POST', JSON.stringify({ identifier: email })),
		);

		if (!response.ok) {
			try {
				const body = await response.json();
				errorMessage = body.message ?? errorMessage;
			} catch (error) {
				// ignore for case when response is not JSON
			}

			throw new ErrorWithExtras(errorMessage, {
				statusCode: response.status,
				statusText: response.statusText,
			});
		}
	} catch (error) {
		handleError({
			error,
			name: errorMessage,
			info: { email },
		});
	}
};

export const resendVerification = async (
	userId: string,
	cloud?: Cloud,
): Promise<void> => {
	console.debug('resendVerification', userId, cloud);
	try {
		const resApiUrl = createResApiUrl(cloud);
		const url = `${resApiUrl}/auth/resend_verification`;
		const response = await vctrFetch(
			url,
			fetchRequest('POST', JSON.stringify({ userId })),
		);
		if (!response.ok) {
			throw new Error(`Error resending verification: ${response.statusText}`);
		}
	} catch (error) {
		handleError({
			error,
			name: 'Error requesting a verification resend',
			info: { userId },
		});
	}
};

export const confirmVerification = async (
	token: string,
	cloud?: Cloud,
): Promise<void> => {
	console.debug('confirmVerification', token, cloud);
	try {
		const resApiUrl = createResApiUrl(cloud);
		const url = `${resApiUrl}/auth/verify_email`;
		const response = await vctrFetch(
			url,
			fetchRequest('POST', JSON.stringify({ token })),
		);
		if (!response.ok) {
			throw new Error(`Error confirming verification: ${response.statusText}`);
		}
	} catch (error) {
		handleError({
			error,
			name: 'Error confirming verification',
			info: { token },
		});
	}
};

export const acceptGdprAndTou = async (
	user: UserBase,
	cloud?: Cloud,
	auth?: HeadersInit,
): Promise<UserActivity> => {
	try {
		const response = await (
			await sdk(cloud, auth)
		)('mutation')({
			acceptGdprAndTou: [
				{
					id: user.uuid,
				},
				userBaseQueryFields,
			],
		});
		return {
			user: userBaseFromResponse(response.acceptGdprAndTou),
			activity: [],
		};
	} catch (error) {
		handleError({
			error,
			name: 'Error accepting GDPR',
			info: { user },
		});
	}
};

export const addEmailAuthenticationMethod = async (
	userId: string,
	cloud?: Cloud,
	auth?: HeadersInit,
): Promise<boolean> => {
	try {
		await (
			await sdk(cloud, auth)
		)('mutation')({
			addEmailAuthenticationMethod: [
				{
					userId,
				},
				true,
			],
		});
		return true;
	} catch (error) {
		handleError({
			error,
			name: 'Error adding email authentication method',
			info: { userId },
		});
	}
};

// this needs to be handled in platform to falsify other user sessions
// auth endpoint returns only user
export const changeEmail = async (
	user: string | UserBase,
	data: { email: string; password: string },
	cloud?: Cloud,
): Promise<UserBase> => {
	const uuid = typeof user === 'string' ? user : user.uuid;
	try {
		await (
			await sdk(cloud)
		)('mutation')({
			updateUserEmailDetails: [
				{
					userId: uuid,
					email: data.email,
					password: data.password,
				},
				{
					email: true,
				},
			],
		});
		return await getCurrentUser(cloud);
	} catch (error) {
		handleError({
			error,
			name: 'Error changing email',
			info: { user, data },
		});
	}
};

// TODO: This constant is placed also in @vctr-api/vctr-resources package. Move it to some shared place.
export const PASSWORD_REQUIREMENTS_MESSAGE =
	'Password must be 8+ chars, with upper/lowercase letters and a number.';

export const changePassword = async (
	user: string | UserBase,
	data: { password: string; new_password: string; new_password_r: string },
	cloud?: Cloud,
): Promise<UserAndActivity> => {
	const resApiUrl = createResApiUrl(cloud);
	const userObject = typeof user === 'string' ? await getUser(user) : user;
	try {
		// Update Resources API
		const response = await vctrFetch(
			`${resApiUrl}/auth/change_password`,
			fetchRequest('POST', JSON.stringify({ newPassword: data.new_password }), {
				Authorization: `Basic ${Buffer.from(
					`${userObject.email}:${data.password}`,
				).toString('base64')}`,
			}),
		);
		const { status: statusResApi } = response;

		if (statusResApi !== 200) {
			const responseBody = await response.json();

			throw new ErrorWithExtras(
				responseBody?.message ?? 'Unable to change password',
			);
		}

		return { user: userObject } as UserAndActivity; // Activity is not really used
	} catch (error) {
		handleError({
			error,
			name: 'Error changing password',
			info: { user, data },
		});
	}
};

export const resetPassword = async (
	token: string,
	data: { password: string; password_r: string },
	cloud?: Cloud,
): Promise<UserBase> => {
	const resApiUrl = createResApiUrl(cloud);
	try {
		const resetPasswordResponse = await vctrFetch(
			`${resApiUrl}/auth/reset_password`,
			fetchRequest('POST', undefined, {
				Authorization: `Basic ${Buffer.from(
					`${token}:${data.password}`,
				).toString('base64')}`,
			}),
		);

		if (!resetPasswordResponse.ok) {
			throw resetPasswordResponse.statusText;
		}

		const credentials: {
			accessToken: string;
		} = await resetPasswordResponse.json();

		if (
			!credentials.accessToken ||
			typeof credentials.accessToken !== 'string'
		) {
			throw 'AccessToken not found';
		}

		return await getCurrentUser(cloud, {
			Authorization: `Bearer ${uuidToUuidBase62(credentials.accessToken)}`,
		});
	} catch (error) {
		handleError({
			error,
			name: 'User API: Error resetting password',
			info: { token, data },
		});
	}
};

export const signup = async (
	data: SignupinData,
	cloud?: Cloud,
): Promise<UserBase> => {
	const resApiUrl = createResApiUrl(cloud);
	try {
		let loginResponse: Response;
		switch (data.identity_type) {
			case IdentityType.email:
				loginResponse = await vctrFetch(
					`${resApiUrl}/auth/signup`,
					fetchRequest(
						'POST',
						JSON.stringify({
							email: data.email,
							username: data.name,
							picture: data.profile_picture,
							firstname: data.firstname,
							lastname: data.lastname,
							newsletter: data.mails,
						}),
						{
							Authorization: `Basic ${Buffer.from(
								`${data.email}:${data.auth_token}`,
							).toString('base64')}`,
						},
					),
				);
				break;
			case IdentityType.google:
				loginResponse = await vctrFetch(
					`${resApiUrl}/auth/google`,
					fetchRequest(
						'POST',
						JSON.stringify({
							email: data.email,
							username: data.name,
							picture: data.profile_picture,
							firstname: data.firstname,
							lastname: data.lastname,
							newsletter: data.mails,
						}),
						{
							Authorization: `Bearer ${data.identifier}`,
						},
					),
				);
				break;
			case IdentityType.ciam:
				loginResponse = await vctrFetch(
					`${resApiUrl}/auth/ciam`,
					fetchRequest(
						'POST',
						JSON.stringify({
							email: data.email,
							username: data.name,
							picture: data.profile_picture,
							firstname: data.firstname,
							lastname: data.lastname,
							newsletter: data.mails,
						}),
						{
							Authorization: `Bearer ${data.identifier}`,
						},
					),
				);
				break;
			default:
				handleError({
					error: new Error(`Invalid identity type`),
					name: 'Error while signup',
					info: {
						indentity_type: data.identity_type,
					},
				});
		}

		if (!loginResponse.ok) {
			const error = await loginResponse.json();
			throw new Error(error?.message ?? error?.type ?? loginResponse.status);
		}

		const credentials: {
			accessToken: string;
			// accessTokenExpiration: Date,
			// refreshToken: string,
			// refreshTokenExpiration: Date,
		} = await loginResponse.json();

		if (
			!credentials.accessToken ||
			typeof credentials.accessToken !== 'string'
		) {
			throw new Error('accessToken not found');
		}
		const bearer = `Bearer ${uuidToUuidBase62(credentials.accessToken)}`;

		const user = await getCurrentUser(cloud, { Authorization: bearer });

		if (!user || !user.uuid) {
			throw new Error(`Empty user response.`);
		}

		if (data.occupation) {
			await props(
				user.uuid,
				{
					questionnaire: {
						[QuestionnaireTopic.Occupation]: { value: data.occupation },
					},
				},
				cloud,
				{ Authorization: bearer },
			);
		}
		if (data.terms || data.gdpr) {
			await acceptGdprAndTou(user, cloud, { Authorization: bearer });
		}

		return await getCurrentUser(cloud, { Authorization: bearer });
	} catch (error) {
		handleError({
			error,
			name: 'Error while signup',
			info: {
				data,
			},
		});
	}
};

export const login = async (
	data: SignupinData,
	cloud?: Cloud,
): Promise<UserBase> => {
	const resApiUrl = createResApiUrl(cloud);
	try {
		let loginResponse: Response;
		switch (data.identity_type) {
			case IdentityType.email:
				loginResponse = await vctrFetch(
					`${resApiUrl}/auth/login`,
					fetchRequest('POST', undefined, {
						Authorization: `Basic ${Buffer.from(
							`${data.email}:${data.auth_token}`,
						).toString('base64')}`,
					}),
				);
				break;
			case IdentityType.google:
				loginResponse = await vctrFetch(
					`${resApiUrl}/auth/google`,
					fetchRequest(
						'POST',
						JSON.stringify({
							email: data.email,
							username: data.name,
							picture: data.profile_picture,
							firstname: data.firstname,
							lastname: data.lastname,
						}),
						{ Authorization: `Bearer ${data.identifier}` },
					),
				);
				break;
			case IdentityType.ciam:
				loginResponse = await vctrFetch(
					`${resApiUrl}/auth/ciam`,
					fetchRequest(
						'POST',
						JSON.stringify({
							email: data.email,
							username: data.name,
							picture: data.profile_picture,
							firstname: data.firstname,
							lastname: data.lastname,
						}),
						{
							Authorization: `Bearer ${data.identifier}`,
						},
					),
				);
				break;
			default:
				handleError({
					error: new Error(`Invalid identity type`),
					name: 'Invalid identity type',
					info: {
						indentity_type: data.identity_type,
					},
				});
		}

		if (!loginResponse.ok) {
			const error = await loginResponse.json();
			throw new ErrorWithExtras('Error while login', {
				statusCode: loginResponse.status,
				statusText: loginResponse.statusText,
				message: error,
			});
		}

		const credentials: {
			accessToken: string;
			// accessTokenExpiration: Date,
			// refreshToken: string,
			// refreshTokenExpiration: Date,
		} = await loginResponse.json();

		if (
			!credentials.accessToken ||
			typeof credentials.accessToken !== 'string'
		) {
			throw new Error('accessToken not found.');
		}
		const bearer = `Bearer ${uuidToUuidBase62(credentials.accessToken)}`;

		const user = await getCurrentUser(cloud, { Authorization: bearer });

		if (!user || !user.uuid) {
			throw new Error(`Empty user response.`);
		}

		return user;
	} catch (error) {
		handleError({
			error,
			name: 'Error while login',
			info: {
				data,
				...error.extras,
			},
		});
	}
};

export const logout = async (cloud?: Cloud): Promise<void> => {
	try {
		const logoutResponse = await vctrFetch(
			`${PLATFORM_API_AUTH_URL}/logout`,
			fetchRequest(),
			cloud,
		);
		return fetchResult<void>(logoutResponse);
	} catch (error) {
		handleError({
			error,
			name: 'Error while logout',
		});
	}
};

export const logoutFromOtherDevices = async (cloud?: Cloud): Promise<void> => {
	try {
		const logoutResponse = await vctrFetch(
			`/falsify-other-sessions`,
			fetchRequest(),
			cloud,
		);
		return fetchResult<void>(logoutResponse);
	} catch (error) {
		handleError({
			error,
			name: 'Error while logging out from other devices',
		});
	}
};

// this needs to be handled in platform to falsify other user sessions
export const deactivate = async (
	user: string | UserBase,
	cloud?: Cloud,
): Promise<any> => {
	const uuid = typeof user === 'string' ? user : user.uuid;
	try {
		await (
			await sdk(cloud)
		)('mutation')({
			deleteUser: [{ id: uuid }, true],
		});
	} catch (error) {
		handleError({
			error,
			name: 'Error while deactivation',
		});
	}
};

export const props = async (
	userUuid: string,
	data: {
		mails?: boolean;
		mails_comments?: boolean;
		username?: string;
		firstName?: string;
		lastName?: string;
		bio?: string;
		location?: string;
		profile_picture?: string;
		geo?: GeoInfo;
		welcome_email_delivered?: boolean;
		trial_used?: boolean;
		pro_used?: boolean;
		questionnaire?: Record<string, any>;
		internalNotes?: string;
	},
	cloud?: Cloud,
	auth?: HeadersInit,
): Promise<UserActivity> => {
	try {
		const response = await (
			await sdk(cloud, auth)
		)('mutation')({
			updateUser: [
				{
					id: userUuid,
					username: data.username,
					firstName: data.firstName,
					lastName: data.lastName,
					biography: data.bio,
					picture: data.profile_picture,
					trialUsed: data.trial_used,
					proUsed: data.pro_used,
					questionnaire: data.questionnaire,
					internalNotes: data.internalNotes,
				},
				// TODO: Extract this object to some common place and reuse on all places.
				userBaseQueryFields,
			],
		});
		return {
			user: userBaseFromResponse(response.updateUser),
			activity: [],
		};
	} catch (error) {
		handleError({
			error,
			name: 'Error while changing props',
			info: { userUuid, data },
		});
	}
};

export const updateUserEmailDetails = async (
	userId: string,
	data: {
		email?: string;
		newsletter?: boolean;
		notifyComments?: CommentNotification;
		undeliverable?: boolean;
		verificationToken?: string | null;
	},
	cloud?: Cloud,
): Promise<UserEmailDetails> => {
	try {
		const response = await (
			await sdk(cloud)
		)('mutation')({
			updateUserEmailDetails: [
				{
					email: data.email,
					newsletter: data.newsletter,
					notifyComments: data.notifyComments,
					undeliverable: data.undeliverable,
					userId,
					verificationToken: data.verificationToken,
				},
				{
					email: true,
					newsletter: true,
					notifyComments: true,
					undeliverable: true,
					verificationToken: true,
				},
			],
		});
		return {
			email: response.updateUserEmailDetails.email,
			newsletter: response.updateUserEmailDetails.newsletter,
			notifyComments: response.updateUserEmailDetails
				.notifyComments as CommentNotification,
			undeliverable: response.updateUserEmailDetails.undeliverable,
			verificationToken: response.updateUserEmailDetails.verificationToken,
		};
	} catch (error) {
		handleError({
			error,
			name: 'Error updating user email details',
			info: { userId, data },
		});
	}
};

export const updateUserLocationDetails = async (
	userId: string,
	data: {
		location?: string;
		geo?: GeoIpLoggedData;
	},
	cloud?: Cloud,
): Promise<UserLocationDetails> => {
	try {
		const response = await (
			await sdk(cloud)
		)('mutation')({
			updateUserLocationDetails: [
				{
					userId,
					location: data.location,
					geo: data.geo,
				},
				{
					location: true,
					geo: true,
				},
			],
		});
		return {
			location: response.updateUserLocationDetails.location,
			geo: response.updateUserLocationDetails.geo as GeoIpLoggedData,
		};
	} catch (error) {
		handleError({
			error,
			name: 'Error updating user location details',
			info: { userId, data },
		});
	}
};

export const createApiToken = async (
	name: string,
	cloud?: Cloud,
): Promise<{ token: string | null }> => {
	try {
		const response = await (
			await sdk(cloud)
		)('mutation')({
			createApiToken: [
				{
					name,
				},
				{
					id: true,
					name: true,
					type: true,
					token: true,
				},
			],
		});
		return { token: response.createApiToken.token };
	} catch (error) {
		handleError({
			error,
			name: 'Error creating token',
			info: { name },
		});
	}
};

export const deleteToken = async (id: string, cloud?: Cloud): Promise<any> => {
	try {
		await (
			await sdk(cloud)
		)('mutation')({
			deleteToken: [
				{
					id,
				},
				{
					// We must specify at least one subfield.
					id: true,
				},
			],
		});
	} catch (error) {
		handleError({
			error,
			name: 'Error deleting token',
			info: { id },
		});
	}
};

export const postFigmaToken = async (
	tokenId: string | undefined,
	token: string,
	cloud?: Cloud,
): Promise<{ token: string | null }> => {
	try {
		if (!tokenId) {
			const response = await (
				await sdk(cloud)
			)('mutation')({
				createToken: [
					{
						type: TokenTypeColumnEnum.figma,
						name: 'figma',
						token,
					},
					{
						id: true,
						name: true,
						type: true,
						token: true,
					},
				],
			});
			return { token: response.createToken.token };
		}

		const response = await (
			await sdk(cloud)
		)('mutation')({
			updateToken: [
				{
					id: tokenId,
					token: token,
				},
				{
					id: true,
					name: true,
					type: true,
					token: true,
				},
			],
		});
		return { token: response.updateToken.token };
	} catch (error) {
		handleError({
			error,
			name: 'Error posting figma token',
			info: { tokenId, token },
		});
	}
};

export const unsubscribeByEmailHash = async (
	emailHash: string,
	cloud?: Cloud,
): Promise<{ newsletter: boolean }> => {
	try {
		const response = await (
			await sdk(cloud)
		)('query')({
			userByEmailOrHash: [{ emailOrHash: emailHash }, { id: true }],
		});
		if (!response.userByEmailOrHash.id) {
			handleError({
				error: new Error('Not Found Error'),
				name: `Error unsubscribing by email hash ${emailHash}`,
			});
		}
		const response2 = await (
			await sdk(cloud)
		)('mutation')({
			updateUserEmailDetails: [
				{
					userId: response.userByEmailOrHash.id,
					newsletter: false,
				},
				{ newsletter: true },
			],
		});

		return { newsletter: response2.updateUserEmailDetails.newsletter };
	} catch (error) {
		handleError({
			error,
			name: 'User API: Error unsubscribing by email hash',
			info: { emailHash },
		});
	}
};

/**
 * Remove this function, when dashboard starts using enums instead of boolean.
 */
function mapNotifyCommentsToBoolean(notifyComments: CommentNotification) {
	return notifyComments === CommentNotification.ALL ? true : false;
}

export class UserApi extends ClientApi {
	async getCurrentUser(auth?: HeadersInit) {
		return getCurrentUser(this.cloud, auth);
	}
	async getUser(uuid: string) {
		return getUser(uuid, this.cloud);
	}
	async getUserRaw(uuid: string) {
		return getUserRaw(uuid, this.cloud);
	}
	async getUserWithError(uuid: string) {
		return getUserWithError(uuid, this.cloud);
	}
	async getUsers(uuids: string[]) {
		return getUsers(uuids, this.cloud);
	}
	async getUserByEmail(email: string) {
		return getUserByEmail(email, this.cloud);
	}
	async getUserIdByEmail(email: string) {
		return getUserIdByEmail(email, this.cloud);
	}
	async getUserByName(name: string) {
		return getUserByName(name, this.cloud);
	}
	async getUserByProjectId(projId: string) {
		return getUserByProjectId(projId, this.cloud);
	}
	async forgotPass(email: string) {
		return forgotPass(email, this.cloud);
	}
	async resendVerification(userId: string) {
		return resendVerification(userId, this.cloud);
	}
	async confirmVerification(token: string) {
		return confirmVerification(token, this.cloud);
	}
	async acceptGdprAndTou(user: UserBase) {
		return acceptGdprAndTou(user, this.cloud);
	}
	async changeEmail(
		user: string | UserBase,
		data: { email: string; password: string },
	) {
		return changeEmail(user, data, this.cloud);
	}
	async changePassword(
		user: string | UserBase,
		data: { password: string; new_password: string; new_password_r: string },
	) {
		return changePassword(user, data, this.cloud);
	}
	async resetPassword(
		token: string,
		data: { password: string; password_r: string },
	) {
		return resetPassword(token, data, this.cloud);
	}
	async signup(data: SignupinData) {
		return signup(data, this.cloud);
	}
	async login(data: SignupinData) {
		return login(data, this.cloud);
	}
	async logout() {
		return logout(this.cloud);
	}
	async deactivate(user: string | UserBase) {
		return deactivate(user, this.cloud);
	}
	async props(userUuid: string, data: any) {
		return props(userUuid, data, this.cloud);
	}
	async updateUserEmailDetails(userId: string, data: any) {
		return updateUserEmailDetails(userId, data, this.cloud);
	}
	async updateUserLocationDetails(userId: string, data: any) {
		return updateUserLocationDetails(userId, data, this.cloud);
	}

	async createApiToken(name: string) {
		return createApiToken(name, this.cloud);
	}
	async deleteToken(id: string) {
		return deleteToken(id, this.cloud);
	}
	async postFigmaToken(userUuid: string, token: string) {
		return postFigmaToken(userUuid, token, this.cloud);
	}
	async getSubscriptionEmails(
		results: string,
		page: string,
		subscribed: 'true' | 'false' | undefined,
	) {
		return getSubscriptionEmails(results, page, subscribed, this.cloud);
	}
}
