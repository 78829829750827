import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Dropdown as DropdownC } from '../../dropdown/Dropdown';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { hexToRGB } from '../../../utils/hexToRGB';

export const $modalPadding = `24px`;

const ModalWrapper = styled.div<{ zIndex?: number }>`
	background-color: ${({ theme }) =>
		hexToRGB(theme.colors.c_almost_black, 0.5)};
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	overflow: hidden;
	height: 100%;
	width: 100%;
	z-index: ${({ theme, zIndex }) => zIndex || theme.zIndex[3]};
`;

const ModalContent = styled.div<{ contentPadding?: string; width?: string }>`
	position: absolute;
	background: ${({ theme }) => theme.colors.c_white};
	min-width: ${36 * 0.625}rem;
	width: ${({ width }) => (width ? width : 'initial')};
	min-height: 100px;
	border-radius: ${({ theme }) => theme.borderRadius[0]};
	padding: ${({ contentPadding }) =>
		contentPadding ? contentPadding : $modalPadding};
	box-shadow: ${({ contentPadding }) =>
		contentPadding === '0' ? '0px 0px 20px hsl(11, 0%, 20%);' : 'none'};
`;

Modal.Header = styled.div`
	display: flex;
	margin-bottom: 16px;
`;

Modal.Description = styled.div`
	flex: 1;
	position: relative;
	padding-left: 4px;
`;

Modal.Dropdown = styled(DropdownC)`
	position: absolute;
	top: 0;
	right: 0;
`;

Modal.Label = styled.p`
	font-size: ${({ theme }) => theme.fontSize.font_size_xsmall};
	color: ${({ theme }) => theme.colors.c_almost_black};
	margin: 2px 0 0 4px;
`;

export type ModalProps = {
	children: React.ReactNode | React.ReactNode[];
	className?: string;
	handleToggle?: (isVisible: boolean) => void;
	show?: boolean;
	zIndex?: number;
	isInputFocused?: boolean;
	padding?: string;
	width?: string;
};

export function Modal({
	children,
	show,
	handleToggle,
	className,
	zIndex,
	isInputFocused = false,
	padding,
	width,
}: ModalProps) {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		function onKeyup(event: KeyboardEvent) {
			if (event.key === 'Escape') {
				if (!isInputFocused && handleToggle) {
					handleToggle(false);
				}
			}
		}
		window.addEventListener('keyup', onKeyup);

		return () => window.removeEventListener('keyup', onKeyup);
	}, [isInputFocused]);

	useOnClickOutside(ref, () => {
		if (handleToggle) {
			handleToggle(false);
		}
	});

	return show ? (
		<ModalWrapper className="vctr-react__modal" zIndex={zIndex}>
			<ModalContent
				ref={ref}
				className={className}
				contentPadding={padding}
				width={width}
			>
				{children}
			</ModalContent>
		</ModalWrapper>
	) : null;
}
