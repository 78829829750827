import React from 'react';

export function VectaryIcon() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_7_4669)">
				<g clipPath="url(#clip1_7_4669)">
					<path
						d="M2.54281 3.57282C4.46601 3.4519 6.63037 3.9672 8.4306 5.07451C10.2308 6.18182 11.6669 7.88112 12.4264 9.65214C13.224 11.4063 13.5128 13.6124 13.1189 15.6888C12.7249 17.7653 11.6481 19.7122 10.2634 21.0524C9.68273 21.6559 8.69446 21.9574 7.87579 21.7809C7.04937 21.6453 6.24024 21.0027 5.92098 20.2285L0.227167 7.33752C-0.130119 6.58008 -0.06016 5.5492 0.396183 4.84696C0.817114 4.12295 1.70564 3.59556 2.54281 3.57282Z"
						fill="#252525"
					/>
					<path
						d="M31.9484 4.97125C32.016 4.82417 32.0173 4.6352 31.9517 4.48721C31.8906 4.33733 31.7536 4.20718 31.6008 4.15386C29.5029 3.33125 26.6686 3.32262 24.2715 4.34204C21.8745 5.36146 19.9146 7.40894 19.0519 9.49065L10.8966 27.9599C10.829 28.107 10.8277 28.296 10.8932 28.444C10.9543 28.5939 11.0914 28.724 11.2442 28.7773C13.3421 29.5999 16.1764 29.6086 18.5734 28.5891C20.9705 27.5697 22.9303 25.5222 23.7931 23.4405L31.9484 4.97125Z"
						fill="#252525"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_7_4669">
					<rect width="32" height="32" fill="white" />
				</clipPath>
				<clipPath id="clip1_7_4669">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
