function unifyValues(value1: any, value2: any) {
	if (typeof value1 !== typeof value2) return value2;
	if (Array.isArray(value1) && Array.isArray(value2)) {
		return [...value1, ...value2];
	}
	switch (typeof value1) {
		case 'boolean':
		case 'function':
		case 'number':
		case 'string':
			return value2;
		case 'object':
			return { ...value1, ...value2 };
	}
}

export function UnionMap(map1: Map<any, any>, map2: Map<any, any>) {
	const newMap: Map<any, any> = new Map();
	if (!map1) map1 = new Map();
	if (!map2) map2 = new Map();

	for (const [key, value] of map1.entries()) {
		if (map2.has(key)) newMap.set(key, unifyValues(value, map2.get(key)));
		else newMap.set(key, value);
	}
	for (const [key, value] of map2.entries()) {
		if (map1.has(key) && !newMap.has(key))
			newMap.set(key, unifyValues(value, map1.get(key)));
		else newMap.set(key, value);
	}

	return newMap;
}

export function addToMapAsArray(map: Map<any, any>, key: any, value: any) {
	if (!map.has(key)) map.set(key, [value]);
	else map.set(key, [...map.get(key), value]);
}

export function addToMapAsSet(map: Map<any, any>, key: any, value: any) {
	if (!map.has(key)) map.set(key, new Set([value]));
	else map.set(key, new Set([...map.get(key), value]));
}
