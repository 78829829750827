import { UAParser } from 'ua-parser-js';

export enum MobilePlatform {
	ANDROID = 'android',
	IOS_AR_SUPPORTED = 'ios',
	IOS_AR_NOT_SUPPORTED = 'ios_ar_not_supported',
	NONE = 'none',
}

/**
 * Returns mobile platform type, NONE is returned if called on desktop.
 * If iOS device is detected and is not compatible with AR, MobilePlatform.IOS_AR_NOT_SUPPORTED will
 * be returned. We can't do the same thing for Android because android AR compatibility
 * is determined when launching AR.
 */
export const getMobilePlatformAndCheckIfiOSCompatible = (): MobilePlatform => {
	return isAndroidDevice()
		? MobilePlatform.ANDROID
		: isiOSDevice() || (isMacOsX() && (isMobileDevice() || isTouchDevice()))
		? isiOSARSupported()
			? MobilePlatform.IOS_AR_SUPPORTED
			: MobilePlatform.IOS_AR_NOT_SUPPORTED
		: MobilePlatform.NONE;
};

export const isMacOsX = (): boolean => {
	return new UAParser().getOS()?.name?.toLowerCase() === 'mac os';
};

export const isMobileDevice = (): boolean => {
	const type = new UAParser().getDevice()?.type?.toLowerCase();
	return type === 'tablet' || type === 'mobile' || type === 'wearable';
};

const isTouchDevice = (): boolean => {
	return navigator.maxTouchPoints >= 5; // VisionOS is 5
	// maxTouchPoints one here is a bit sketchy, but there really isn't a better way
	// Windows laptops with touch screen tend to display 10 as their value
};

const isAndroidDevice = (): boolean => {
	return new UAParser().getOS()?.name?.toLowerCase() === 'android';
};

const isiOSDevice = (): boolean => {
	const uaParser = new UAParser();
	const iOS = uaParser.getOS()?.name.match(/iOS/i);
	const version = parseInt(uaParser.getOS()?.version);

	if (iOS && version >= 12) {
		return true;
	}
	return false;
};

/**
 * This is method of detecting AR compatibility for iOS devices recommended by Apple.
 * It does not work 100%, for example it gives false positive for iPad mini 2 running iOS 13.
 */
const isiOSARSupported = (): boolean => {
	// This method started to fail, specially for Chrome and it is just better to get false positives, than false negatives
	return true;
	try {
		const a = document.createElement('a');
		if (a.relList.supports('ar')) {
			return true;
		} else {
			console.debug(`Anchor relList "ar", not supported`);
			return false;
		}
	} catch (error) {
		console.debug(`Failed to check relList`, error);
		return false;
	}
};

export function getChromeVersion(): number {
	var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

	return raw ? parseInt(raw[2], 10) : null;
}
