import { VeBox, VeTypography } from '../_base';
import React from 'react';
import { CloseOrLogout, CloseOrLogoutProps } from './CloseOrLogout';
import { SignupinLogo } from './SignupinLogo';

export interface VerifyEmailResentProps extends CloseOrLogoutProps {
	urlEmail: string;
}

export function VerifyEmailResent(props: VerifyEmailResentProps) {
	const { urlEmail, handleLogout } = props;
	return (
		<>
			<SignupinLogo />
			<VeBox mb={2}>
				<VeTypography align="center" variant="h4" component="h1" mb={2}>
					Verification email resent
				</VeTypography>
				<VeTypography component="p" fontSize={15 / 16 + 'rem'}>
					We've just sent another confirmation email to{' '}
					<VeTypography component="span" color="secondary">
						{urlEmail}
					</VeTypography>
					. Please check your inbox and spam folder if you don't see it in your
					main inbox within a few minutes. If you encounter any problems or have
					questions, please don't hesitate to contact our support team at{' '}
					<VeTypography component="span" color="primary">
						support@vectary.com
					</VeTypography>{' '}
				</VeTypography>
			</VeBox>
			<VeBox mb={2}>
				<VeTypography>
					<CloseOrLogout {...{ handleLogout }} />
				</VeTypography>
			</VeBox>
		</>
	);
}
