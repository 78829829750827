import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Chip } from '@mui/material';
import {
	WorkspaceUserSeat,
	Permission,
	InvitationPermission,
	EmailInvitation,
	EmailInvitationWithPossibleError,
	WorkspaceInvitation,
} from '@vctr-libs/vctr-api-client';
import {
	MembersTable__TableCell,
	MembersTable__TableContainer,
	MembersTable__TableCell_Role,
} from './membersTable/styledComponents';
import { MemberRoleSelect } from './MemberRoleSelect';
import { DashboardAppMuiThemeVariables } from '../../ui';
import { EmailInvitationStatusBadge } from './EmailInvitationStatusBadge';
import { isMemberRoleSelectDisabled } from './isMemberRoleSelectDisabled';
import { default as DeleteIcon } from '../../ui/themes/uko/icons/Delete';
import { MemberTable__Item__Seat } from './MemberTable__Item__Seat';
import { MemberTable__Item__LinkInvitation } from './MemberTable__Item__LinkInvitation';
import { MemberTable__Item__EmailInvitation } from './MemberTable__Item__EmailInvitation';

type UpdateSeatOrInvitation = ({
	id,
	permission,
}: {
	id: string;
	permission: Permission;
}) => void;

type RemoveSeatOrInvitation = (id: string) => void;

export type MembersTableProps = {
	users: WorkspaceUserSeat[];
	removeUserSeat: RemoveSeatOrInvitation;
	updateUserSeat: UpdateSeatOrInvitation;
	emailInvitations: EmailInvitation[] | EmailInvitationWithPossibleError[];
	removeEmailInvitation: RemoveSeatOrInvitation;
	updateEmailInvitation: UpdateSeatOrInvitation;
	legacyLinkInvitations: WorkspaceInvitation[];
	removeLegacyLinkInvitation: RemoveSeatOrInvitation;
	updateLegacyLinkInvitation: UpdateSeatOrInvitation;
	availablePermissions: Permission[];
	availableInvitationPermissions: InvitationPermission[];
	isDisabledPermission: (p: Permission) => boolean;
	isHiddenPermission: (p: Permission) => boolean;
	currentUserCanManageMembers: boolean;
};

export const MembersTable: FC<MembersTableProps> = props => {
	const {
		users: userSeats,
		legacyLinkInvitations,
		emailInvitations,
		removeUserSeat,
		updateUserSeat,
		removeEmailInvitation,
		updateEmailInvitation,
		removeLegacyLinkInvitation,
		updateLegacyLinkInvitation,
		availablePermissions,
		availableInvitationPermissions,
		isDisabledPermission,
		isHiddenPermission,
		currentUserCanManageMembers,
	} = props;

	const tableHeaderItems = ['Name and email', 'Seat type', 'Status', ''];

	return (
		<MembersTable__TableContainer>
			<Table sx={{ minWidth: 500 }}>
				<TableHead>
					<TableRow>
						{tableHeaderItems.map((prop, i) => (
							<MembersTable__TableCell
								key={`workspace_members_table_header_${i}`}
							>
								{prop}
							</MembersTable__TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{userSeats.map((userSeat, i) => (
						<TableRow
							key={`workspace_member_fake_pending_${i}`}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<MembersTable__TableCell component="th" scope="row">
								<MemberTable__Item__Seat {...userSeat} />
							</MembersTable__TableCell>
							<MembersTable__TableCell_Role>
								<MemberRoleSelect
									width="fixed"
									disabled={isMemberRoleSelectDisabled({
										permissionOptions: availablePermissions,
										currentUserCanManageMembers,
										selectedPermission: userSeat.permission,
									})}
									isDisabledPermission={isDisabledPermission}
									isHiddenPermission={isHiddenPermission}
									permissions={availablePermissions || []}
									selectedPermission={userSeat.permission}
									handleChange={newPermission =>
										updateUserSeat({
											id: userSeat.uuid,
											permission: newPermission,
										})
									}
								/>
							</MembersTable__TableCell_Role>
							<MembersTable__TableCell>
								<Chip variant="outlined" label="Joined" />
								{/* Pending approval — when joined by link */}
								{/* Pending — for invitation */}
							</MembersTable__TableCell>
							{/* <MembersTable__TableCell align="right"> */}
							<MembersTable__TableCell>
								{currentUserCanManageMembers &&
									userSeat.permission !== Permission.OWNER && (
										<Button
											onClick={() => removeUserSeat(userSeat.uuid)}
											// color="error"
											color="secondary"
											variant="text"
										>
											{/* <RemoveCircleOutline fontSize="small" /> */}
											{/* &nbsp; */}
											<DeleteIcon />
										</Button>
									)}
								{/* <VeLink>Approve — when joined by link</VeLink> */}
								{/* <VeLink>Resend</VeLink>
									<VeLink>Revoke invitation</VeLink> */}
							</MembersTable__TableCell>
						</TableRow>
					))}

					{emailInvitations &&
						emailInvitations.map(invitation => (
							<TableRow
								key={`workspace_member_${invitation.id}`}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<MembersTable__TableCell component="th" scope="row">
									<MemberTable__Item__EmailInvitation {...invitation} />
								</MembersTable__TableCell>
								<MembersTable__TableCell_Role>
									{/* {invitation.permission} */}
									<MemberRoleSelect
										width="fixed"
										disabled={isMemberRoleSelectDisabled({
											permissionOptions: availableInvitationPermissions,
											currentUserCanManageMembers,
											selectedPermission:
												invitation.permission as unknown as Permission,
										})}
										isDisabledPermission={isDisabledPermission}
										isHiddenPermission={isHiddenPermission}
										permissions={availableInvitationPermissions || []}
										selectedPermission={
											invitation.permission as unknown as Permission
										}
										handleChange={newPermission =>
											updateEmailInvitation({
												id: invitation.id,
												permission: newPermission,
											})
										}
									/>
								</MembersTable__TableCell_Role>
								<MembersTable__TableCell>
									<EmailInvitationStatusBadge {...{ invitation }} />
								</MembersTable__TableCell>
								<MembersTable__TableCell>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											gap: `${DashboardAppMuiThemeVariables.gridGap}px`,
										}}
									>
										{/* Hiding Resend button since there's no API support for that currently */}
										{/* <Button
											onClick={() => resendEmailInvitation(invitation.id)}
											color="secondary"
											variant="outlined"
										>
											Resend
										</Button> */}
										{currentUserCanManageMembers && (
											<Button
												onClick={() => removeEmailInvitation(invitation.id)}
												color="secondary"
												variant="text"
											>
												{/* <RemoveCircleOutline fontSize="small" /> */}
												{/* &nbsp; */}
												<DeleteIcon />
											</Button>
										)}
									</Box>
								</MembersTable__TableCell>
							</TableRow>
						))}

					{legacyLinkInvitations &&
						legacyLinkInvitations.map((invitation: WorkspaceInvitation) => (
							<TableRow
								key={`workspace_member_${invitation.uuid}`}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<MembersTable__TableCell component="th" scope="row">
									<MemberTable__Item__LinkInvitation {...invitation} />
								</MembersTable__TableCell>
								<MembersTable__TableCell_Role>
									{/* {invitation.permission} */}
									<MemberRoleSelect
										width="fixed"
										disabled={isMemberRoleSelectDisabled({
											permissionOptions: legacyLinkInvitations,
											currentUserCanManageMembers,
											selectedPermission:
												invitation.permission as unknown as Permission,
										})}
										isDisabledPermission={isDisabledPermission}
										isHiddenPermission={isHiddenPermission}
										permissions={availableInvitationPermissions || []}
										selectedPermission={invitation.permission}
										handleChange={newPermission =>
											updateLegacyLinkInvitation({
												id: invitation.uuid,
												permission: newPermission,
											})
										}
									/>
								</MembersTable__TableCell_Role>
								<MembersTable__TableCell>
									<Chip variant="filled" label="Pending" color="secondary" />
								</MembersTable__TableCell>
								<MembersTable__TableCell>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											gap: `${DashboardAppMuiThemeVariables.gridGap}px`,
										}}
									>
										{currentUserCanManageMembers && (
											<Button
												onClick={() =>
													removeLegacyLinkInvitation(invitation.uuid)
												}
												color="secondary"
												variant="text"
											>
												{/* <RemoveCircleOutline fontSize="small" /> */}
												{/* &nbsp; */}
												<DeleteIcon />
											</Button>
										)}
									</Box>
								</MembersTable__TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</MembersTable__TableContainer>
	);
};
