const getCookies = (names: string[]): { [name: string]: string } => {
    const cookiesArr = document.cookie.split(";");
    const cookies: { [name: string]: string } = {};
    const ret: { [name: string]: string } = {};
    cookiesArr.forEach((cookie) => {
        const a = cookie.split("=");
        if (a.length > 1) {
            cookies[a[0].trim()] = a[1].trim();
        }
    });
    names.forEach((name) => {
        ret[name] = cookies[name] ? cookies[name] : "";
    });
    return ret;
};

const getCookieVal = (cookieName: string): string => {
    const c = getCookies([cookieName]);
    if (c[cookieName]) {
        return c[cookieName];
    }
    return "";
};

export const getSessionId = (): string => {
    return getCookieVal(getCookieName(document.URL));
};
export const getGID = (): string => {
    return getCookieVal("_gid");
};

// Cookies are created in GW and name is choosen there
// @see @link packages/@vctr-api/vctr-platform/src/server/config/middleware.ts
const getCookieName = (url: string): string => {
    const urlParts = url.split('.');
    if (urlParts.length > 1 && urlParts[1] !== 'vectary') {
        return `${urlParts[1]}-sid`;
    }
    return "vctr-sid";
}
