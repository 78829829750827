import { ThemeOptions, createTheme } from '@mui/material';
import { Prettify } from '@vctr-libs/vctr-utils';
// import { colors } from '../../../styles';

type VeThemeOptions = Prettify<ThemeOptions>;

export function getDashboardMarketingTheme() {
	const themeOptions: VeThemeOptions = {};

	return createTheme(themeOptions);
}
