import React, { FC } from 'react';
import { Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const ViewerLimitationsAlert: FC = () => (
	<Alert
		color="warning-grey"
		variant="filled"
		icon={<InfoIcon style={{ fontSize: '16px' }} />}
	>
		Your current role is <strong>limited to viewer permissions</strong> only.
		You will not be able to see workspace members or access projects and folders
		unless you have a direct link provided by another member. If you need
		broader access, please contact your workspace owner or manager to change
		your role.
	</Alert>
);
