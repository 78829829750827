import { Plan } from "../../plan/interface";
import { PRO_PLAN_IDS, PlanIds, LEGACY_BUSINESS_PLAN_IDS, FREE_PLANS, CURRENT_PRO_TRIAL_PLANS } from "../../plan/plans";
import { UserBase } from "../../user/interface";
import { WorkspaceBaseDashboard, WorkspaceBase } from "../interface/interface";

export interface WorkspacePermissionsProps {
	user: UserBase;
	workspace: WorkspaceBaseDashboard;
}

export const isWorkspaceDowngradable = (workspacePlan: Plan): boolean => {
	return (
		!!workspacePlan.baseSubId &&
		!!workspacePlan.nextBillDate &&
		!!workspacePlan.billing
	);
};

export const isProWorkspace = (workspacePlanId: string): boolean => {
	return PRO_PLAN_IDS.map(p => p.toString()).includes(workspacePlanId);
};

export const isBusinessWorkspace = (workspacePlanId: string): boolean => {
	return workspacePlanId === PlanIds.BUSINESS;
};

export const isLegacyBusinessWorkspace = (workspacePlanId: string): boolean => {
	return LEGACY_BUSINESS_PLAN_IDS.map(p => p.toString()).includes(
		workspacePlanId,
	);
};

export const isCustomPlanWorkspace = (workspacePlanId: string): boolean => {
	return !PRO_PLAN_IDS.concat(FREE_PLANS)
		.map(id => id.toString())
		.includes(workspacePlanId);
};

export const isLockedWorkspace = (workspacePlanId: string): boolean => {
	return workspacePlanId === PlanIds.LOCKED;
};

export const isWorkspaceCancelled = (
	workspace: WorkspaceBaseDashboard | WorkspaceBase,
): boolean => {
	return workspace.plan?.cancellationEffectiveDate?.length > 0;
};

export const isWorkspacePlanCancelled = (
	plan: Plan
): boolean => {
	if (!plan?.cancellationEffectiveDate) return false;
	return plan?.cancellationEffectiveDate?.length > 0;
};

export const isTrialWorkspace = (workspacePlanId: PlanIds): boolean => {
	return Object.values(CURRENT_PRO_TRIAL_PLANS).includes(workspacePlanId);
};

export const hasTrialWorkspace = (
	workspaces: WorkspaceBaseDashboard[],
): boolean => {
	return workspaces.some(w => isTrialWorkspace(w.plan?.id));
};

export const isStarterWorkspace = (workspacePlanId: PlanIds): boolean => {
	return [PlanIds.STARTER_25, PlanIds.STARTER_10].includes(workspacePlanId);
};

export const isEducationWorkspace = (workspacePlanId: PlanIds): boolean => {
	return workspacePlanId === PlanIds.EDUCATION;
};

export const isSetupWorkspace = (workspacePlanId: PlanIds): boolean => {
	return workspacePlanId === PlanIds.SETUP;
};

/** Plans that behave similar to Stater (which is archived) */
export function isStarterKindPlan(planId: PlanIds) {
	return (
		isSetupWorkspace(planId) ||
		isEducationWorkspace(planId) ||
		// still need to support Starter
		isStarterWorkspace(planId)
	);
}

export const isFreeLegacyWorkspace = (workspacePlanId: PlanIds): boolean => {
	return [PlanIds.FREE].includes(workspacePlanId);
};

type IsTrialAllowedArgs = {
	workspaces: WorkspaceBaseDashboard[];
	selectedWorkspace: WorkspaceBaseDashboard;
	user: UserBase;
	// selectedPlan: PlanIds;
};

// export const isTrialAllowed = (args: IsTrialAllowedArgs) => {
//     const { workspaces, selectedWorkspace, user } = args;
//     return isStarterWorkspace(selectedWorkspace?.plan?.id) && !hasTrialWorkspace(workspaces) && !user.trial_used;
// }

export const isTrialAllowed = (args: IsTrialAllowedArgs) => {
	return false;
	// // const { workspaces, selectedWorkspace, user, selectedPlan } = args;
	// const { workspaces, selectedWorkspace, user } = args;

	// // const isStudent = isUserStudent(user);
	// // const isStudentTrialAllowed = isStudent && selectedPlan?.includes('TRIAL');
	// // const isStudentTrialAllowed = isStudent;

	// if (!selectedWorkspace?.plan?.id) return;

	// const planId = selectedWorkspace.plan.id;

	// const isTrialAllowedForSelectedWorkspace =
	// 	isStarterWorkspace(planId) ||
	// 	isSetupWorkspace(planId) ||
	// 	isEducationWorkspace(planId);

	// // !isLockedWorkspace(planId) is redundant

	// return (
	// 	isTrialAllowedForSelectedWorkspace &&
	// 	!hasTrialWorkspace(workspaces) &&
	// 	!user.trial_used &&
	// 	!user.pro_used
	// );
	// // student deliberately chooses TRIAL over PRO discount yearly offer
	// // (!isStudent || !selectedPlan || isStudentTrialAllowed)
	// // (!isStudent || isStudentTrialAllowed)
};

export const isProWorkspaceWithActiveSubscription = (
	workspace: WorkspaceBaseDashboard,
) => {
	return (
		workspace?.plan &&
		isProWorkspace(workspace.plan.id) &&
		!isWorkspaceCancelled(workspace)
	);
};
