import { Alert, AlertColor } from '@mui/material';
import React, { ReactNode } from 'react';

interface VeAlertProps {
	severity: AlertColor;
	children: ReactNode;
}

export function VeAlert({ severity, children }: VeAlertProps) {
	return <Alert severity={severity}>{children}</Alert>;
}
