import { MemberPermissionEnum } from '@vctr-api/vctr-resources';
import { Cloud } from '@vctr-libs/vctr-utils';
import { userBaseFromResponse } from '../user/client';
import { UserBase } from '../user/interface';
import { WorkspaceSeat, WorkspaceUserSeat } from './interface/interface';
import { Permission } from './interface/permissionRoles';
import { workspaceSdk } from './config';
import { handleError } from '../utils/handleError';

/**
 * GET
 * ​/workspace​/{workspaceId_base62}​/seats
 * Get list of users that are seats (not owners) of a workspace
 */

export const getWorkspaceSeats = async (
	workspaceUuid: string,
	cloud?: Cloud,
	asAService = false,
): Promise<WorkspaceSeat[] | undefined> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			workspace: [
				{ id: workspaceUuid },
				{
					id: true,
					members: {
						id: true,
						permission: true,
					},
				},
			],
		});

		if (!response?.workspace?.id || !response?.workspace?.members) {
			throw new Error('Error getting workspace seats');
		}

		const seats: WorkspaceSeat[] = response.workspace.members.map(member => ({
			permission: member.permission as string as Permission,
			user_id: member.id as string,
			workspace_id: response?.workspace?.id as string,
		}));

		return seats;
	} catch (error) {
		handleError({
			error,
			name: 'Error getting workspace seats',
		});
		return undefined;
	}
};
/**
 * TODO accept single objects and arrays  u: UserBase | UserBase[], s: WorkspaceSeat | WorkspaceSeat[]): WorkspaceUserSeat[]
 */

export const mergeUsersAndSeats = (
	users: UserBase[],
	seats: WorkspaceSeat[],
): WorkspaceUserSeat[] =>
	users?.map(u => {
		const seat = seats?.find(s => s.user_id === u.uuid);
		return Object.assign(u, seat);
	});
/**
 * Fetch users by workspace seats and merge user and seat data
 */

export const getWorkspaceSeatUsers = async (
	workspaceUuid: string,
): Promise<WorkspaceUserSeat[]> => {
	try {
		const response = await (
			await workspaceSdk()
		)('query')({
			workspace: [
				{ id: workspaceUuid },
				{
					id: true,
					members: {
						id: true,
						username: true,
						biography: true,
						picture: true,
						questionnaire: true,
						gdpr: true,
						tou: true,
						trialUsed: true,
						proUsed: true,
						createdAt: true,
						permission: true,
						emailDetails: {
							email: true,
							newsletter: true,
							notifyComments: true,
							undeliverable: true,
							verificationToken: true,
						},
						locationDetails: {
							location: true,
							geo: true,
						},
						permissions: {
							role: true,
						},
					},
				},
			],
		});

		if (!response?.workspace?.id || !response?.workspace?.members) {
			throw new Error('Error getting workspace seats');
		}

		return response.workspace.members.map(member => ({
			...userBaseFromResponse(member),
			permission: member.permission as string as Permission,
			user_id: member.id as string,
			workspace_id: response?.workspace?.id as string,
		}));
	} catch (error) {
		throw `${error} Error getting workspace Users`;
	}
};
type WorkspaceUsersSettings = {
	workspaceCloud: Cloud;
	userCloud: Cloud;
	asAService: boolean;
};
/**
 * Fetch all users by workspace and merge user and seat data
 */

export const getWorkspaceUsers = async (
	workspaceUuid: string,
	{ workspaceCloud, userCloud, asAService = false }: WorkspaceUsersSettings,
): Promise<UserBase[] | undefined> => {
	try {
		const response = await (
			await workspaceSdk(workspaceCloud)
		)('query')({
			workspace: [
				{ id: workspaceUuid },
				{
					id: true,
					owner: {
						id: true,
						username: true,
						biography: true,
						picture: true,
						questionnaire: true,
						gdpr: true,
						tou: true,
						trialUsed: true,
						proUsed: true,
						createdAt: true,
						emailDetails: {
							email: true,
							newsletter: true,
							notifyComments: true,
							undeliverable: true,
							verificationToken: true,
						},
						locationDetails: {
							location: true,
							geo: true,
						},
						permissions: {
							role: true,
						},
					},
					members: {
						id: true,
						username: true,
						biography: true,
						picture: true,
						questionnaire: true,
						gdpr: true,
						tou: true,
						trialUsed: true,
						proUsed: true,
						createdAt: true,
						permission: true,
						emailDetails: {
							email: true,
							newsletter: true,
							notifyComments: true,
							undeliverable: true,
							verificationToken: true,
						},
						locationDetails: {
							location: true,
							geo: true,
						},
						permissions: {
							role: true,
						},
						rolePermissions: true,
					},
				},
			],
		});

		if (!response?.workspace?.id || !response?.workspace?.members) {
			throw new Error('Error getting workspace seats');
		}

		return response.workspace.members
			.map(member => userBaseFromResponse(member))
			.concat(userBaseFromResponse(response.workspace.owner));
	} catch (error) {
		handleError({
			error,
			name: 'Error getting workspace Users',
		});
		return undefined;
	}
};
/**
 * GET
 * ​/workspace​/{workspaceId_base62}​/seats​/{userId_base62}
 * Get a seat of a workspace
 */

export const getWorkspaceSeatInfo = async (
	workspaceUuid: string,
	userUuid: string,
	cloud?: Cloud,
): Promise<WorkspaceSeat | undefined> => {
	try {
		const response = await (
			await workspaceSdk(cloud)
		)('query')({
			workspace: [
				{ id: workspaceUuid },
				{
					id: true,
					members: {
						id: true,
						permission: true,
					},
				},
			],
		});

		if (!response?.workspace?.id || !response?.workspace?.members) {
			throw new Error('Error getting workspace seats');
		}

		const member = response.workspace.members.find(
			member => member.id === userUuid,
		);

		if (!member) {
			return undefined;
		}

		return {
			permission: member.permission as string as Permission,
			user_id: member.id as string,
			workspace_id: response.workspace.id as string,
		};
	} catch (error) {
		handleError({
			error,
			name: 'Error getting workspace seat info',
		});
		return undefined;
	}
};
/**
 * PATCH
 * ​/workspace​/{workspaceId_base62}​/seats​/{userId_base62}
 * Change the permission a user has over a workspace
 */

export const updateWorkspaceSeat = async (
	workspaceUuid: string,
	userUuid: string,
	permission: Permission,
	cloud?: Cloud,
): Promise<WorkspaceSeat | undefined> => {
	try {
		await (
			await workspaceSdk(cloud)
		)('mutation')({
			updateMember: [
				{
					permission: permission as string as MemberPermissionEnum,
					userId: userUuid,
					workspaceId: workspaceUuid,
				},
				true,
			],
		});

		return getWorkspaceSeatInfo(workspaceUuid, userUuid);
	} catch (error) {
		handleError({
			error,
			name: 'Error updating a workspace seat',
		});
		return undefined;
	}
};
/**
 * DELETE
 * ​/workspace​/{workspaceId_base62}​/seats​/{userId_base62}
 * Remove a user from the seats of a workspace
 */

export const deleteWorkspaceSeat = async (
	workspaceUuid: string,
	userUuid: string,
	cloud?: Cloud,
): Promise<void> => {
	try {
		await (
			await workspaceSdk(cloud)
		)('mutation')({
			removeMember: [
				{
					userId: userUuid,
					workspaceId: workspaceUuid,
				},
				true,
			],
		});
	} catch (error) {
		throw `${error} Error deleting a workspace seat!`;
	}
};
