import UuidEncoder from 'uuid-encoder';
import { isValidUuidBase62 } from './uuidGenerator';

const base62Encoder = new UuidEncoder('base62');

/**
 * Transforms an standard UUID into a base62 string. This function throws an error if it cannot be converted.
 * @param uuid UUID encoded in base16 with dashes. @example "8d623630-f307-93A3-6a0c-936c537c3013"
 * @returns The decoded UUID in base62. @example "4Imnevergonnagiveyouup"
 */
export function uuidToUuidBase62(uuid: string): string {
	const uuidBase62 = `0000000000000000000000${base62Encoder.encode(uuid)}`; //Ensure 22 characteres
	return uuidBase62.substr(uuidBase62.length - 22);
}

/**
 * Transforms a base62 string into an standard UUID. This function throws an error if it cannot be converted.
 * @param uuidBase62 UUID encoded in base62. @example "4Imnevergonnagiveyouup"
 * @returns The decoded UUID in base16 with dashes. @example "8d623630-f307-93A3-6a0c-936c537c3013"
 */
export function uuidBase62ToUuid(uuidBase62: string): string {
	try {
		// decode method will convert every string to its uuid form.
		// we need to check if the given string is a valid base 62 id or not.
		if (!isValidUuidBase62(uuidBase62)) throw new Error('Invalid base 62 Uuid');

		const uuid = base62Encoder.decode(uuidBase62);
		if (uuid.length === 36) {
			return uuid;
		}
		throw new Error('Invalid base 62 Uuid');
	} catch (error) {
		throw new Error('Invalid base 62 Uuid');
	}
}
