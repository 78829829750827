import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from '@mui/material';
import { ToasterProvider } from '../../components';
import {
	createCustomTheme,
	initialSettings,
} from '../themes/uko/createCustomTheme';
import { DashboardMuiOverrideStyler } from '../themes';
import styled from 'styled-components';

/**
 * Override styler to fix legacy styles we can't change in the dashboard
 * due to legacy code and multiple UI systems
 */
const DashboardUkoOverrideStyler = styled(DashboardMuiOverrideStyler)`
	font-size: 100%;
`;

/**
 * MUI UKO theme provider for dashboard components
 * Based on https://uko-doc.vercel.app/ theme
 */
export const DashboardUkoThemeProvider: FC = ({
	children,
}: {
	children: ReactNode;
}) => (
	<DashboardUkoOverrideStyler className="DashboardUkoOverrideStyler">
		<ThemeProvider theme={createCustomTheme(initialSettings)}>
			{children}
			<ToasterProvider />
		</ThemeProvider>
	</DashboardUkoOverrideStyler>
);
