import React, { FC, forwardRef, useEffect, useRef, useState } from 'react';
import { Icon, IconEnumName } from '../_common/icon';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Wrapper, ButtonDropdown } from './components/styledComponents';
import { DropdownList } from './components/DropdownList';
import { DropdownEnumPositionX, DropdownEnumPositionY } from './utils/position';

export const DROPDOWN_WINDOW_GAP = 100;

type CustomRendererArgs = {
	onCloseDropdown: () => void;
};

interface SingleDropdownItemProps {
	color?: string;
	customRenderer?: (args?: CustomRendererArgs) => React.ReactNode;
	link?: string;
	onClick?: (event: React.MouseEvent) => void;
	onMouseDown?: (event: React.MouseEvent<HTMLElement>) => void;
	title?: string;
	iconSrc?: string;
	disabled?: boolean;
}

export interface DropdownItemProps extends SingleDropdownItemProps {
	childrenMenu?: SingleDropdownItemProps[];
	childrenMenuPositionX?: DropdownEnumPositionX;
	childrenMenuPositionY?: DropdownEnumPositionY;
}

export enum DropdownStyleVariant {
	DEFAULT = 'default',
	MENU = 'menu',
}

export type DropdownProps = {
	autoPlacement?: boolean;
	className?: string;
	disabled?: boolean;
	handleToggle?: (arg0: boolean) => void;
	isVisible?: boolean;
	menu: DropdownItemProps[];
	positionX?: DropdownEnumPositionX;
	positionY?: DropdownEnumPositionY;
	ref?: any;
	show?: boolean;
	childrenMenu?: DropdownItemProps[];
	variant?: DropdownStyleVariant;
	containerStyle?: React.CSSProperties;
};

export const Dropdown: FC<DropdownProps> = forwardRef((props, ref) => {
	const {
		autoPlacement = false,
		children,
		className,
		disabled,
		handleToggle,
		menu,
		positionX = DropdownEnumPositionX.LEFT,
		positionY = DropdownEnumPositionY.TOP,
		show,
		isVisible,
		variant = DropdownStyleVariant.DEFAULT,
		containerStyle,
	} = props;
	const wrapperRef = useRef<HTMLUListElement>(null);
	const listRef = useRef<HTMLUListElement>(null);
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	useOnClickOutside(listRef, () => {
		handleOnShow(false);
	});

	useEffect(() => {
		handleOnShow(isVisible);
	}, [isVisible]);

	const handleOnShow = (isVisible: boolean): void => {
		if (handleToggle) {
			handleToggle(isVisible);
		}

		setShowDropdown(isVisible);
	};

	if (menu.length <= 0) return null;

	return (
		<Wrapper className={className} ref={wrapperRef as any}>
			{children || (
				<ButtonDropdown
					className="dropdown__ButtonDropdown"
					onClick={event => {
						event.stopPropagation();
						handleOnShow(true);
					}}
					ref={ref as any}
				>
					<Icon name={IconEnumName.TEMPVERTICAL_ELLIPSIS} />
				</ButtonDropdown>
			)}
			{(!disabled && show) || showDropdown ? (
				<DropdownList
					listRef={listRef}
					positionX={positionX}
					positionY={positionY}
					autoPlacement={autoPlacement}
					wrapperRef={wrapperRef}
					menu={menu}
					handleOnShow={handleOnShow}
					variant={variant}
					containerStyle={containerStyle}
				/>
			) : null}
		</Wrapper>
	);
});
