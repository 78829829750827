import { colors as colorsC, fontWeight, fontSizes } from '../variables';

const breakpoints = {
	tablet: '@media screen and (min-width: 600px)',
	desktop: '@media screen and (min-width: 1200px)',
	tv5k: '@media screen and (min-width: 1920px)',
};

const zIndex = [0, 1, 2, 3, 4, 5];
const borderRadius = ['4px', '100px'];
const borderRadiusCard = '8px';
const colors = { ...colorsC };
const fontSize = { ...fontSizes };
const fontWeights = { ...fontWeight };

// export type ThemeSet = {
//     fontFamily: string,
//     colors: typeof colors,
//     breakpoints: typeof breakpoints,
//     zIndex: typeof zIndex,
//     borderRadius: typeof borderRadius,
//     borderRadiusCard: typeof borderRadiusCard,
//     fontSize: typeof fontSize,
//     fontWeights: typeof fontWeights,
// }

export type Theme = {
	[key: string]: any;
	// black: ThemeSet,
	// white: ThemeSet,
};

export const theme: Theme = {
	black: {
		fontFamily: 'Inter, Helvetica, Arial, sans-serif',
		colors,
		breakpoints,
		zIndex,
		borderRadius,
		borderRadiusCard,
		fontSize,
		fontWeights,
	},
	white: {
		fontFamily: 'Inter, Helvetica, Arial, sans-serif',
		colors,
		breakpoints,
		zIndex,
		borderRadius,
		borderRadiusCard,
		fontSize,
		fontWeights,
	},
};
