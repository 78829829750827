import React from 'react';
import { CarouselItem } from '../carousel';

export enum CarouselItemType {
	IMAGE = 'image',
	VIDEO = 'video',
}

export enum CarouselSlideNames {
	// Discover_PRO
	ANIMATIONS_INTERACTIONS = 'animations_interactions',
	CONFIGURATORS = 'configurators',
	AUGMENTED_REALITY = 'augmented_reality',
	UNBRANDED = 'unbranded',
	DESIGN_SYSTEMS = 'design_systems',
	UNLIMITED_PROJECTS = 'unlimited_projects',
	VERSION_HISTORY = 'version_history',

	// Discover_Enterprise
	COLLABORATE = 'collaborate',
	EVENT_INTERACTONS = 'event_interactions',
}

export const slideNameToIndex = (slideName: CarouselSlideNames): number => {
	switch (slideName) {
		case CarouselSlideNames.ANIMATIONS_INTERACTIONS:
		case CarouselSlideNames.COLLABORATE:
			return 0;
		case CarouselSlideNames.EVENT_INTERACTONS:
		case CarouselSlideNames.CONFIGURATORS:
			return 1;
		case CarouselSlideNames.AUGMENTED_REALITY:
			return 2;
		case CarouselSlideNames.UNBRANDED:
			return 3;
		case CarouselSlideNames.DESIGN_SYSTEMS:
			return 4;
		case CarouselSlideNames.UNLIMITED_PROJECTS:
			return 5;
		case CarouselSlideNames.VERSION_HISTORY:
			return 6;
	}
};

export const carouselItems: CarouselItem[] = [
	{
		contentType: CarouselItemType.VIDEO,
		videoSrc: [
			{
				videoType: 'video/mp4',
				src: 'https://www.vectary.com/comments/discover-pro-animations-interactions.mp4?raw=1',
			},
		],
		title: 'Animations & Interactions',
		content: (
			<span>
				Make 3D animations using keyframes or make any object actionable. Build
				elevated interactivity to provide detailed information, dynamic content
				and keep the interest and engagement of your audience.
			</span>
		),
	},
	{
		imgSrc: 'discover-pro-2.png',
		title: 'Build configurators',
		content: (
			<span>
				No-code! Drag and drop UI elements, build configurators and update menus
				with a few clicks. Let your audience view and interact with content from
				links or embeds.
			</span>
		),
	},
	{
		imgSrc: 'discover-pro-augmented-reality.png',
		title: 'Augmented Reality',
		content: (
			<span>
				Access AR to make a completely immersive experience. App-free
				functionality on both iOS and Android devices. Transition from digital
				to virtual seamlessly with one click.
			</span>
		),
	},
	{
		imgSrc: 'discover-pro-unbranded.png',
		title: 'Unbranded links and embeds',
		content: (
			<span>
				Remove the Vectary logo from shared 3D embeds and links for a seamless
				integration with your website or presentation.
			</span>
		),
	},
	{
		imgSrc: 'discover-pro-4.png',
		title: 'Build 3D design systems',
		content: (
			<span>
				Re-use your 3D assets - save or share them as individual projects. Just
				drag and drop from your library to the Studio scene. Bring new levels of
				consistency and scalability for product and web design.
			</span>
		),
	},
	{
		imgSrc: 'discover-pro-1.png',
		title: 'Store up to 100 projects and increase view limits to 100k',
		content: (
			<span>
				Keep more projects and stay organized by saving custom material
				libraries, backups and design system assets. Enjoy up to 100K views for
				all the shared projects in your workspace.
			</span>
		),
	},
	{
		imgSrc: 'discover-pro-6.png',
		title: 'Access project version history',
		content: (
			<span>
				Revert or recover any project to a previously saved version. See
				timestamps of saved changes. Access version history of already existing
				projects created prior to your workspace upgrade.
			</span>
		),
	},
];

export const enterpriseItems: CarouselItem[] = [
	{
		imgSrc: 'discover-pro-teams.jpg',
		title: 'Book a demo session for your team',
		content: (
			<span>
				We will send you a calendar link where you can pick a suitable time for
				you and your team to go through all the features available for
				Business clients. Transform your 3D pipeline with unlimited projects,
				in-design commenting, unbranded viewer, file access control, SSO, and
				success manager support.
			</span>
		),
	},
	{
		imgSrc: 'discover-pro-API.png',
		title: 'Deliver more options with API',
		content: (
			<span>
				API allows you to remove boundaries, and create set ups specific to your
				needs. Add event listeners and send events with easy-to-use Vectary
				interactions. Connect with your favorite apps and extend the digital
				twins workflow.
			</span>
		),
	},
];
