


export function equalSumSets<T>(set1: Set<T>, set2: Set<T>) {
    if (set1.size !== set2.size) return false;
    for (const a of set1) if (!set2.has(a)) return false;
    return true;
}

export function equalSets<T>(set1: Set<T>, set2: Set<T>) {
    if (set1.size !== set2.size) return false;
    const secondSetIterator = set2.values();
    for (const s1 of set1) {
        const s2 = secondSetIterator.next().value;
        if (s2 !== s1) return false; // This compares values, and in case of object, if those are the same instance
    }
    return true;
}

export function addToSet<T>(value: T | T[], targetSet: Set<T>, onAddCallback?: (toBeAdded: T) => void, allowNullValues: boolean = true) {
    if (!allowNullValues && (value === undefined || value === null)) return;
    if (Array.isArray(value)) {
        value.forEach(v => {
            targetSet.add(v)
            if (onAddCallback !== undefined) onAddCallback(v)
        })
    } else {
        targetSet.add(value)
        if (onAddCallback !== undefined) onAddCallback(value)
    }
}

export function removeFromSet<T>(value: T | T[], targetSet: Set<T>, onRemoveCallback?: (toBeRemoved: T) => void) {
    if (Array.isArray(value)) {
        value.forEach(v => {
            targetSet.delete(v)
            if (onRemoveCallback !== undefined) onRemoveCallback(v)

        })
    } else {
        targetSet.delete(value)
        if (onRemoveCallback !== undefined) onRemoveCallback(value);
    }
}


export function toggleInSet<T>(value: T, targetSet: Set<T>, onAddCallback?: (toBeAdded: T) => void, onRemoveCallback?: (toBeRemoved: T) => void, forceToggle?: boolean) {
    if (forceToggle) {
        addToSet(value, targetSet, onAddCallback);
        return;
    }
    if (forceToggle === false) {
        removeFromSet(value, targetSet, onRemoveCallback);
        return;
    }
    else {
        if (targetSet.has(value)) {
            removeFromSet(value, targetSet, onRemoveCallback);
        } else {
            addToSet(value, targetSet, onAddCallback);
        }
    }
}