import React, { ReactNode } from 'react';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';

interface VeCheckboxProps {
	name: string;
	label?: string;
	required?: boolean;
	children?: ReactNode;
	value?: string;
	disabled?: boolean;
	checked?: boolean;
	tooltip?: string;
	onChange?: (
		event: React.SyntheticEvent<Element, Event>,
		checked: boolean,
	) => void;
}

export function VeCheckbox({
	name,
	label,
	required = true,
	children,
	disabled,
	checked,
	tooltip,
	onChange,
	value,
}: VeCheckboxProps) {
	return (
		<Tooltip title={tooltip}>
			<FormControlLabel
				sx={{ color: 'text.secondary' }}
				required={required}
				control={<Checkbox name={name} required={required} />}
				label={children ? children : label}
				value={value}
				disabled={disabled}
				checked={checked}
				onChange={onChange}
			/>
		</Tooltip>
	);
}
