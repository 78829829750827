
export const AR_ALLOW_SCALING_PARAM = "allowScaling";
export const AR_TITLE_PARAM = "title";
export const AR_TEXT_PARAM = "text";
export const AR_REDIRECT_URL_PARAM = "url";
export const AR_CALL_TO_ACTION_PARAM = "callToAction";
export const AR_APPLE_FILE_LINK = "arApple";
export const AR_ANDROID_FILE_LINK = "arAndroid";

/**
 * iOS banner works by dispalying custom html page in the banner.
 * Custom URL passing the data only accepts one get parameter
 * therefore all data are encoded into json and passed in one parameter. 
 */
export function getUsdzParams(
        allowScaling: number,
        bannerTitle: string,
        bannerText: string,        
        callToAction: string) {
            
    let params = `#allowsContentScaling=${allowScaling}`;

    if (isStringParamPresent(bannerTitle) || isStringParamPresent(bannerText)) {
        let data = {
            title : (bannerTitle == "null" || bannerTitle == null) ? "" : bannerTitle,
            text : (bannerText == "null" || bannerText == null) ? "" : bannerText,
            callToAction : (callToAction == "null" || callToAction == null || callToAction.length == 0) ? "Visit" : callToAction
        }
        let url = encodeURIComponent("https://www.vectary.com/viewer-ar/v1/arBanner.html?data=" + encodeURIComponent(JSON.stringify(data)));
        params += `&custom=${url}`;
    }
    return params;
}

export function getAndroidARParams(
        allowScaling: number,
        bannerTitle: string,
        bannerText: string,
        bannerURL: string) {
                        
    let params = `&resizable=${allowScaling ? "true" : "false"}`;
    let bannerContent: string = "";
    if (isStringParamPresent(bannerTitle)) {
        bannerContent = `<h3>${bannerTitle}</h3>`;
    }
    if (isStringParamPresent(bannerText)) {
        bannerContent += `<span style="color:#666666">${bannerText}</span>`
    }
    if (isStringParamPresent(bannerContent)) {
        params += `&title=${encodeURIComponent(bannerContent)}`;
    }
    if (isStringParamPresent(bannerURL)) {
        params += `&link=${encodeURIComponent(bannerURL)}`;
    }
    return params;
}

export function getAndroidARFallbackURL(protocolAndHost: string) {
    return `${protocolAndHost}/viewer-ar/v1/?unsupported=true`;
}

export function getQRParams( 
        allowScaling: number,  
        bannerTitle: string,
        bannerText: string,
        bannerURL: string,
        callToAction: string,
        usdzPath: string,
        glbPath: string) {
    
    let params = `&${AR_ALLOW_SCALING_PARAM}=${allowScaling}`;
    if (isStringParamPresent(bannerTitle)) {
        params += `&${AR_TITLE_PARAM}=${encodeURIComponent(bannerTitle)}`;
    }
    if (isStringParamPresent(bannerText)) {
        params += `&${AR_TEXT_PARAM}=${encodeURIComponent(bannerText)}`;
    }
    if (isStringParamPresent(bannerURL)) {
        params += `&${AR_REDIRECT_URL_PARAM}=${encodeURIComponent(bannerURL)}`;
    }
    if (isStringParamPresent(callToAction)) {
        params += `&${AR_CALL_TO_ACTION_PARAM}=${encodeURIComponent(callToAction)}`;
    }
    if (isStringParamPresent(usdzPath)) {
        params += `&${AR_APPLE_FILE_LINK}=${encodeURIComponent(usdzPath)}`;
    }
    if (isStringParamPresent(glbPath)) {
        params += `&${AR_ANDROID_FILE_LINK}=${encodeURIComponent(glbPath)}`;
    }
    return params;
}

function isStringParamPresent(stringValue: string) {
    return stringValue && stringValue.length > 0 && stringValue != "null";
}