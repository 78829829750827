import React, { FC, useEffect, useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Permission, getReadablePermission } from '@vctr-libs/vctr-api-client';
import styled from 'styled-components';
import { Paragraph } from '../../ui/themes/uko/themeComponents/typography';
import { usePermissionsSelectHelper } from './usePermissionsSelectHelper';

const _Select = styled(Select)`
	margin-bottom: 0 !important;
`;

const memberRoleSelectFixedWidth: string = '160px';

type MemberRoleSelectProps = {
	/** all available permissions, including the ones to be hidden or disabled: 
	 * it's still necessary to show those as selected values */
	permissions: Permission[];
	selectedPermission: Permission;
	handleChange: (role: Permission) => void;
	disabled?: boolean;
	/** show but disable permissions in select dropdown */
	isDisabledPermission: (p: Permission) => boolean;
	/** hide permissions from select dropdown */
	isHiddenPermission: (p: Permission) => boolean;
	width?: 'auto' | 'full' | 'fixed';
};

export const MemberRoleSelect: FC<MemberRoleSelectProps> = props => {
	const {
		permissions,
		selectedPermission,
		handleChange,
		disabled = false,
		isDisabledPermission,
		isHiddenPermission,
		width = 'full',
	} = props;

	const [isDisabled, setIsDisabled] = useState<boolean>(disabled);

	const {
		enabledPermissions,
		disabledPermissions,
		visiblePermissions,
		hiddenPermissions,
	} = usePermissionsSelectHelper({
		permissions,
		isDisabledPermission,
		isHiddenPermission,
	});

	const onChange = (event: SelectChangeEvent<unknown>) => {
		const role = event.target.value as string as Permission;
		handleChange(role);
	};

	const widthProp =
		width === 'full'
			? { fullWidth: true }
			: width === 'fixed'
			? { style: { width: memberRoleSelectFixedWidth } }
			: {};

	useEffect(() => {
		setIsDisabled(
			disabled ||
				enabledPermissions.length === 0 ||
				visiblePermissions.length === 0,
		);
	}, [disabled, enabledPermissions, visiblePermissions]);

	return (
		<>
			<_Select
				className="MemberRoleSelect"
				disabled={isDisabled}
				value={selectedPermission}
				onChange={onChange}
				displayEmpty={false}
				variant="outlined"
				sx={{ mb: 2 }}
				{...widthProp}
			>
				{isDisabled ? (
					<MenuItem
						value={selectedPermission}
						key={`member_${0}_role_${selectedPermission}`}
						disabled={true}
					>
						<Paragraph>{getReadablePermission(selectedPermission)}</Paragraph>
					</MenuItem>
				) : (
					permissions
						// filtering out hidden permissions unless it's a selected one (by value)
						.filter(p => p === selectedPermission ? true : !hiddenPermissions.includes(p))
						.map((permission, i) => (
							(<MenuItem
								value={permission}
								key={`member_${i}_role_${permission}`}
								disabled={disabledPermissions.includes(permission)}
							>
								<Paragraph>{getReadablePermission(permission)}</Paragraph>
							</MenuItem>) 
						))
				)}
			</_Select>
		</>
	);
};
