// Don't use generate:lib 'cause it fails on exporting duplicated names
export * from './utils';
export { hexToRGB } from './utils/hexToRGB';
export {
	colors,
	fontSizes,
	fontFamily,
	fontWeight,
} from './styles/variables/index';
export { Theme, theme } from './styles/theme/index';
export * from './styles/index';
export * from './icons';
export { useVctrTheme } from './hooks/useVctrTheme';
export { useOnClickOutside } from './hooks/useOnClickOutside';
export * from './hooks/index';
export * from './components/index';
export * from './components/_common/index';
export * from './ui';
