import { Divider, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface DividerProps {
	children?: ReactNode;
}

export function VeDivider({ children }: DividerProps) {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Divider style={{ flexGrow: 1 }} />
			{children && (
				<Typography variant="body2" color="textSecondary" sx={{ px: 2 }}>
					{children}
				</Typography>
			)}
			<Divider style={{ flexGrow: 1 }} />
		</div>
	);
}
