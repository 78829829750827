import { useEffect, useState } from 'react';
import { Permission } from '@vctr-libs/vctr-api-client';

export const usePermissionsSelectHelper = ({
	permissions,
	isDisabledPermission,
	isHiddenPermission,
}: {
	permissions: Permission[];
	isDisabledPermission: (p: Permission) => boolean;
	isHiddenPermission: (p: Permission) => boolean;
}): {
	enabledPermissions: Permission[];
	disabledPermissions: Permission[];
	visiblePermissions: Permission[];
	hiddenPermissions: Permission[];
} => {
	const [enabledPermissions, setEnabledPermissions] = useState<Permission[]>(
		permissions.filter(p => !isDisabledPermission(p)),
	);

	const [disabledPermissions, setDisabledPermissions] = useState<Permission[]>(
		permissions.filter(p => isDisabledPermission(p)),
	);

	const [hiddenPermissions, setHiddenPermissions] = useState<Permission[]>(
		permissions.filter(p => isHiddenPermission(p)),
	);

	const [visiblePermissions, setVisiblePermissions] = useState<Permission[]>(
		permissions.filter(p => !isHiddenPermission(p)),
	);

	useEffect(() => {
		setDisabledPermissions(permissions.filter(p => isDisabledPermission(p)));
		setEnabledPermissions(permissions.filter(p => !isDisabledPermission(p)));
		setVisiblePermissions(permissions.filter(p => !isHiddenPermission(p)));
		setHiddenPermissions(permissions.filter(p => isHiddenPermission(p)));
	}, [permissions]);

	return {
		enabledPermissions,
		disabledPermissions,
		visiblePermissions,
		hiddenPermissions,
	};
};
