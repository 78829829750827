import { Table, styled } from '@mui/material';
import { grey } from '../../../ui';
import { DashboardUkoThemeVariables } from '../../../ui/themes/dashboardUkoTheme';

export const BillingInvoicesTable = styled(Table)`
	& thead.MuiTableHead-root {
		background-color: ${grey[100]};
	}
	& th.MuiTableCell-root {
		background-color: ${grey[100]};
		padding: ${DashboardUkoThemeVariables.spacing}px
			${DashboardUkoThemeVariables.spacing * 3}px;
	}
	& td.MuiTableCell-root {
		border-bottom: 1px solid #eaecef;
		padding: ${DashboardUkoThemeVariables.spacing * 3}px
			${DashboardUkoThemeVariables.spacing * 3}px;
	}
`;
