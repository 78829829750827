
declare global {
    interface Window {
        geoIpCallback?: { callback : (data: any) => void };
        geoip: (json: string) => void;
    }
}
export interface GeoIpData { // based on https://www.geojs.io/docs/v1/endpoints/geo/
    accuracy: number,
    area_code: string,
    asn: number,
    city: string,
    continent_code: string,
    country: string,
    country_code: string,
    country_code3: string,
    ip: string,
    latitude: string,
    longitude: string,
    organization: string,
    organization_name: string,
    region: string,
    timezone: string,
}

export interface GeopIpLoggedData {
    accuracy: number
    city: string,
    country_code: string,
    region: string,
    timezone: string,
}

export class GeoIp {

    public initialize(callback: (data: GeoIpData) => void): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const callbackScript = document.createElement("script");

            if (!window.geoIpCallback && !window.geoip) {
                const inlineScript = document.createTextNode(`
                    var geoIpCallback = { callback: null };
                    var geoip = (json) => {
                        geoIpCallback.callback(json);
                    }
                `);
                callbackScript.appendChild(inlineScript);
                document.body.appendChild(callbackScript);
            }

            window.geoIpCallback.callback = callback;

            fetch("https://get.geojs.io/v1/ip/geo.js")
                .then((response) => {
                    if (response.status >= 400) return null;
                    return response.blob();
                })
                .catch(error => {
                    reject(error)
                })
                .then((scriptBlob: Blob | null) => {
                    if (!scriptBlob) {
                        reject("Failed to fetch GeoIp information, status >= 400");
                    }
                    else {
                        const objectURL = URL.createObjectURL(scriptBlob);
                        const scriptElement = document.createElement("script");
                        scriptElement.setAttribute("src", objectURL);
                        scriptElement.setAttribute("type", "text/javascript");
                        scriptElement.setAttribute("crossorigin", "")
                        document.body.appendChild(scriptElement);
                        resolve();
                    }
                });
        });
    }
}
