export const UIs = {
	/** dashboard based on MUI, 2023 — WIP */
	dashboard_mui: 'dashboard_mui',
	/** dashboard "marketing" UI: Get Pro and others, derived from website 2023/2024 design */
	dashboard_marketing: 'dashboard_marketing',
	/** dashboard app–style UI derived from studio in 2022*/
	dashboard_4_0: 'dashboard_4_0',
	/** signupin based on MUI, 2023 */
	signupin_mui: 'signupin_mui',
	/** dashboard based on MUI UKO, 2024 */
	dashboard_uko_mui: 'dashboard_uko_mui',
} as const;
