/**
 * @description Handle errors by logging them and throwing a new error
 * @param error The error object to log (console.log only, not thrown)
 * @param name The error name (message) to throw, will be error name in Sentry. No ids, errors or other variables/objects here!
 * @param info The details to log (console.log only, not thrown) — any info, e.g. ids, etc.
 */

import {
	ServerErrorWithExtras,
	ErrorWithExtras,
} from '@vctr-api/vctr-resources';

export const handleError = (args: {
	error: unknown;
	name: string;
	info?: any;
}) => {
	const { error, name, info = null } = args;
	// log every error to console
	console.log(JSON.stringify({ name, error, info }));
	// This can be extended with setting tags when found in `info`, e.g.:
	// Sentry.setTags({ user: info.user.uuid, project: info.project.uuid });

	// throw error (string and error only, no objects or variables here!)
	// by avoiding entity ids, we have better issue grouping in Sentry

	if (
		error instanceof ErrorWithExtras ||
		error instanceof ServerErrorWithExtras
	) {
		throw error;
	}

	throw new Error(`${name}: ${error}`);

	// TODO use ErrorWithExtras instead of Error

	// throw new ErrorWithExtras(
	// 	`${name}: ${error}`,
	// 	info ? { info } : undefined
	// );
};
