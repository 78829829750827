import { PuppeteerNode, Browser } from 'puppeteer-core';

const HTML = `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
    <style>
      html,
      body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      canvas {
        width: 100%;
        height: 100vh;
        position: fixed;
      }
    </style>
  </head>
  <body>
    <canvas></canvas>
    <img>
  </body>
</html>`;

type puppeteerInstance = {
	puppeteer: PuppeteerNode;
	browser: Browser;
	canvas: HTMLCanvasElement;
	image: HTMLImageElement;
};

const pupInst: puppeteerInstance = {
	puppeteer: undefined,
	browser: undefined,
	canvas: undefined,
	image: undefined,
};

export const initPuppeteer = async (
	puppeteer: PuppeteerNode,
	browser: Browser,
): Promise<puppeteerInstance> => {
	pupInst.puppeteer = puppeteer;
	pupInst.browser = browser;
	return pupInst;
};

export const newPuppeteerElements = async (): Promise<void> => {
	// Create new page
	const page = await pupInst.browser.newPage();
	await page.setViewport({ width: 1920, height: 1080 });
	await page.setContent(HTML);
	// @ts-ignore -> Type instantiation is excessively deep and possibly infinite.ts(2589)
	await page.waitForSelector('canvas');
	// @ts-ignore
	await page.waitForSelector('img');

	pupInst.canvas = await page.$eval('canvas', (el: HTMLCanvasElement) => el);
	pupInst.image = await page.$eval('img', (el: HTMLImageElement) => el);
};

// We don't want to return a promise since this could be used in constructors
export const createHTMLCanvasElement = (): HTMLCanvasElement => {
	if (typeof window === 'undefined') {
		// We are in nodeJs, so we need to use puppeteer
		// We assume puppeteer has been initialized and a canvas created
		return pupInst.canvas;
	} else {
		return document.createElement('canvas');
	}
};

export const createHTMLImageElement = (): HTMLImageElement => {
	if (typeof window === 'undefined') {
		return pupInst.image;
	} else {
		return document.createElement('img');
	}
};
