// .xml.ts
export { CommentTagClass, ALL_MEMBER_MENTION, CommentChunk, parseXML, getCommentChunks, createCommentFromChunks, serializeComment } from "./xml";
// .uuidGenerator.ts
export { getUuidV4String, isUuid, isValidUuidBase62, isValidUuid4 } from "./uuidGenerator";
// .uuidBase62.ts
export { uuidToUuidBase62, uuidBase62ToUuid } from "./uuidBase62";
// .typescript.ts
export { Prettify, XOR, UnwrapPromise } from "./typescript";
// .typesafeBooleanParser.ts
export { typesafeBooleanParser } from "./typesafeBooleanParser";
// .translator/arTranslations.ts
export { ARTranslations } from "./translator/arTranslations";
// .translator.ts
export { ArLanguages, LanguageResource, Translator, LanguageToISO639, ISO639ToLanguage, Language, ISO_639_1 } from "./translator";
// .translationObjects.ts
export { ArTranslationObject } from "./translationObjects";
// .throttleUtil.ts
export { debounce, throttle, timeoutedResolve, asyncTimeout } from "./throttleUtil";
// .svgUtils.ts
export { readSvgFileAsSVGElement, changeResolution, getViewPortSizeInUserUnits, createSVGBlobFromSVGElement, getGeometrySizeAndOrigin } from "./svgUtils";
// .string.ts
export { toCamelCase, getFileNameFromPath, getExtensionFromFileName, removeExtension, removeProblematicCharsForUrlsAndUris, getNextAvailableNumberedName, addSuffix, isVectaryEmail, isVctr3ImportWorkspaceAllowed, isLogitechWorkspace } from "./string";
// .signal.ts
export { Signal } from "./signal";
// .shortcuts.ts
export { KeyEventType, ShortcutDefinition, IGNORED_ELEMS, Shortcut, ShortcutManager, eventToKey, keyCodeToKeyMap, KEY } from "./shortcuts";
// .set.ts
export { equalSumSets, equalSets, addToSet, removeFromSet, toggleInSet } from "./set";
// .session.ts
export { getSessionId, getGID } from "./session";
// .route-patterns.ts
export { uuidV4Regex, nickRegex, numberRegex, base62Regex, preBase62Regex, slugBase62Regex } from "./route-patterns";
// .puppeteer.ts
export { initPuppeteer, newPuppeteerElements, createHTMLCanvasElement, createHTMLImageElement } from "./puppeteer";
// .profilePicture.ts
export { getProfilePicture } from "./profilePicture";
// .object.ts
export { equals, arrayEquals, shallowCopy, isNullValue, isDefaultDOMRect, offsetRect } from "./object";
// .number.ts
export { decimalNumToFixed } from "./number";
// .map.ts
export { UnionMap, addToMapAsArray, addToMapAsSet } from "./map";
// .localStorage.ts
export { userSettingsKeys, LocalStorage } from "./localStorage";
// .importSystemJsModules.ts
export { initImportSystemJsMap, importSystemJsModules } from "./importSystemJsModules";
// .http-client.ts
export { FetchMethod, Cloud, UrlProtocol, createCloudUrl, createCloudUrlWithWww, createCloudStudioUrl, createCloudHostnameWithWww, vctrFetch, fetchRequest, fetchResult, fetchResultText, fetchResultArrayBuffer, fetchResultBlob, fetchResultAndStatus, fetchResultAndError, getSecureState, getCookie, setCookie, getServiceHeaders, Role, isValidHttpUrl, makeUrlValid } from "./http-client";
// .googleChat.ts
export { GoogleChatSpaceUrl, postToGoogleChat, postToChat } from "./googleChat";
// .file.ts
export { FileData, downloadLink, downloadBlob, exportCSVFile, isZip, getImageArraybufferMimeType, getImageBlobMimeType, getExtensionFromMimeType, getMimeTypeFromExt, processImportedData, readArrayBuffer, getImageDimensions, RASTER_IMG_FORMATS, STATIC_IMG_FORMATS, ENV_IMG_FORMATS, ANIMATED_IMG_FORMATS, LOADING_IMAGE_FORMATS, FONT_FORMATS } from "./file";
// .exceptions.ts
export { ExtensionRendererError, MessageException, SerializationError, BugException, runAndShowErrors, extractError } from "./exceptions";
// .env.ts
export { Env, LOCALHOST_ENV, PROD_LIKE_ENVS, COOKIE_CONSENT_ENVS, isCookiesConsentEnv, isSandboxEnvByHostname, isProductionSite, isEnvByHostname, getCurrentEnvironment } from "./env";
// .emailValidation.ts
export { emailValidation, isEmailValid } from "./emailValidation";
// .date.ts
export { getDiffFullMonths, getDiffMonths, getDateUTCFormat, cleanMinutes, cleanHours, isBefore, isAfter } from "./date";
// .copyTextToClipboard.ts
export { copyTextToClipboard } from "./copyTextToClipboard";
// .containers.ts
export { getFirstValueInSet, getLastValueInSet, mergeSets, subtractSets, compareSets, compareArrays, reverseMap } from "./containers";
// .compressionUtil.ts
export { isAZip, unifiedZipFilesPromise, unifiedZipFiles, unifiedUnzipFile, unifiedZipFileBuffers, unifiedUnzipBuffer, unifiedZlibSync, zipNamedBuffersSync, zipFiles, unzipFile, zipFileBuffers, unzipBuffer, createHDRZip } from "./compressionUtil";
// .color.ts
export { HSBColor, convertRGBToHSB, convertHSBToRGB, convertRGBToHEX, parseHEXValue, convertHEXToRGB, convertRGBToLuminance, compareHSBColors, FilterType, createFilterMatrix, filterBrightness, filterChannel, mixWithColor, flipG } from "./color";
// .checkExists.ts
export { checkAssetExists } from "./checkExists";
// .checkDevice.ts
export { MobilePlatform, getMobilePlatformAndCheckIfiOSCompatible, isMacOsX, isMobileDevice, getChromeVersion } from "./checkDevice";
// .centerNotifications.ts
export { CenterNotifications } from "./centerNotifications";
// .browserInfo.ts
export { BrowserInfo } from "./browserInfo";
// .blobUtils.ts
export { blobToDataUrl, blobToString, blobToArraybuffer, base64toBlob, uint8ArrayToHexString, computeHash, base64ToBinaryConvert, loadFile, arrayBufferToObject, arrayBufferToFileData, blobToImageData, compareBlobs, NamedBlob, NamedArrayBuffer, fileDataToNamedBlob } from "./blobUtils";
// .arraybufferUtils.ts
export { writeString, writeUint32, writeUint8, writeFloat32, readUint32, readUint8, readFloat32 } from "./arraybufferUtils";
// .array.ts
export { removeFromArray, isEqualArray, chunkArrayInGroups } from "./array";
// .arParametersBuilder.ts
export { AR_ALLOW_SCALING_PARAM, AR_TITLE_PARAM, AR_TEXT_PARAM, AR_REDIRECT_URL_PARAM, AR_CALL_TO_ACTION_PARAM, AR_APPLE_FILE_LINK, AR_ANDROID_FILE_LINK, getUsdzParams, getAndroidARParams, getAndroidARFallbackURL, getQRParams } from "./arParametersBuilder";