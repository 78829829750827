import { showNoticeTemporary } from "./showNotice";

export const handleCopyLink = () => {
    const btns = document.querySelectorAll(`[js="copy-link"]`);
    btns.forEach((btn) => {
        btn.addEventListener("click", (event) => {
            event.preventDefault();
            const link = btn.getAttribute("href");
            if (!link) {
                console.log("Error copying link: no link to copy");
                return;
            }
            const successText = btn.getAttribute("js-notice-success");
            const errorText = btn.getAttribute("js-notice-error");

            window.navigator.clipboard.writeText(link).then(() => {
                if (successText)
                    showNoticeTemporary(successText);
            }).catch((error) => {
                console.log(error);
                if (errorText)
                    showNoticeTemporary(errorText);
            });
        });
    });
};
