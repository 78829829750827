import { FileData } from './file';
import { getExtensionFromFileName } from './string';

export function blobToDataUrl(blob: Blob): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		let reader = new FileReader();
		reader.onload = function () {
			const base64data = reader.result as string;
			resolve(base64data);
		};
		reader.readAsDataURL(blob);
	});
}

export function blobToString(blob: Blob): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		let reader = new FileReader();
		reader.onload = function () {
			resolve(reader.result as string);
		};
		reader.readAsText(blob);
	});
}

export function blobToArraybuffer(blob: Blob): Promise<ArrayBuffer> {
	return new Promise<ArrayBuffer>((resolve, reject) => {
		let reader = new FileReader();
		reader.onload = function () {
			resolve(reader.result as ArrayBuffer);
		};

		reader.readAsArrayBuffer(blob);
	});
}

export function base64toBlob(
	b64Data: string,
	contentType: string = '',
	sliceSize = 512,
): Blob {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

export function uint8ArrayToHexString(ui8array: Uint8Array) {
	var hexstring = '',
		h;
	for (var i = 0; i < ui8array.length; i++) {
		h = ui8array[i].toString(16);
		if (h.length == 1) {
			h = '0' + h;
		}
		hexstring += h;
	}
	var p = Math.pow(2, Math.ceil(Math.log2(hexstring.length)));
	hexstring = hexstring.padStart(p, '0');

	return hexstring.substring(hexstring.length - 40);
}

export async function computeHash(
	binaryData: Blob | ArrayBuffer,
): Promise<string> {
	const buffer = new Uint8Array(
		binaryData instanceof Blob
			? await blobToArraybuffer(binaryData)
			: binaryData,
	);
	const digest = await crypto.subtle.digest('SHA-1', buffer);
	const result = uint8ArrayToHexString(new Uint8Array(digest));

	return result;
}

export function base64ToBinaryConvert(base64Str: string): Uint8Array {
	let binStr = atob(base64Str.split(',')[1]);
	let len = binStr.length;
	let arr = new Uint8Array(len);

	for (let i = 0; i < len; i++) {
		arr[i] = binStr.charCodeAt(i);
	}

	return arr;
}

export async function loadFile(file: Blob): Promise<ArrayBuffer> {
	return new Promise<ArrayBuffer>((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result as ArrayBuffer);
		};
		reader.readAsArrayBuffer(file);
	});
}

export function arrayBufferToObject<T>(bytes: ArrayBuffer): T {
	// try {
	// do we need this? For some reason it is valid for some nodes but it seems to get exactly the same results
	// for Uint16 and Uint8; maybe TextDecoder does the magic?
	//     return JSON.parse(new TextDecoder("utf-8").decode(new Uint16Array(bytes))) as T;
	// } catch (e) {
	return JSON.parse(
		new TextDecoder('utf-8').decode(new Uint8Array(bytes)),
	) as T;
	// }
}

export function arrayBufferToFileData(
	bytes: ArrayBuffer,
	imageName: string,
): FileData {
	const extension = getExtensionFromFileName(imageName) || 'json';
	const file: Blob =
		extension === 'json'
			? new Blob([bytes], { type: 'application/json' })
			: new Blob([bytes], { type: `image/${extension}` });

	return new FileData(file, imageName, extension);
}

export function blobToImageData(blob: Blob): Promise<ImageData> {
	let blobUrl = URL.createObjectURL(blob);

	return new Promise<HTMLImageElement>((resolve, reject) => {
		let img = new Image();
		img.onload = () => resolve(img);
		img.onerror = err => reject(err);
		img.src = blobUrl;
	}).then((img: HTMLImageElement) => {
		URL.revokeObjectURL(blobUrl);

		let w = img.width;
		let h = img.height;

		let canvas = document.createElement('canvas');
		canvas.width = w;
		canvas.height = h;
		let ctx = canvas.getContext('2d');
		ctx.drawImage(img, 0, 0);
		return ctx.getImageData(0, 0, w, h);
	});
}

export async function compareBlobs(a: Blob, b: Blob): Promise<boolean> {
	if (!a || !b) {
		return false;
	}
	if (a === b) {
		return true;
	}
	if (a.size != b.size) {
		return false;
	}
	let aBuffer = new Uint8Array(await a.arrayBuffer());
	let bBuffer = new Uint8Array(await b.arrayBuffer());
	for (let i = 0, I = aBuffer.length; i < I; ++i) {
		if (aBuffer[i] != bBuffer[i]) {
			return false;
		}
	}
	return true;
}

export class NamedBlob {
	blob: Blob;
	name: string = '';

	constructor(blob: Blob, name: string = '') {
		this.blob = blob;

		if (!name && blob instanceof File) {
			this.name = blob.name;
		} else {
			this.name = name;
		}
	}
}

export class NamedArrayBuffer {
	arrayBuffer: ArrayBuffer;
	name: string = '';

	constructor(arrayBuffer: ArrayBuffer, name: string = '') {
		this.arrayBuffer = arrayBuffer;
		this.name = name;
	}
}

export function fileDataToNamedBlob(fileData: FileData) {
	if (!fileData) return null;
	return new NamedBlob(fileData.file, fileData.name);
}
