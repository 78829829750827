import React, { FC } from 'react';
import { ButtonWrapper, Text } from './styledComponents';

export type DiscoverProButtonProps = {
	handleButtonClick: () => void;
};

export const DiscoverProButton: FC<DiscoverProButtonProps> = props => {
	const { handleButtonClick } = props;
	return (
		<ButtonWrapper onClick={handleButtonClick}>
			<Text>Discover</Text>
			<Text highlighted={true}>PRO</Text>
		</ButtonWrapper>
	);
};
