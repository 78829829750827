import React, { FC, ReactNode } from 'react';
import { GlobalStyler } from '../../styles';
import { ToasterProvider } from '../../components';

/**
 * "Marketing" template for dashboard components.
 * Used in "Get Pro" modals, similar to signupin theme.
 */
export const DashboardAppTemplate: FC = ({
	children,
}: {
	children: ReactNode;
}) => {
	return (
		// `GlobalStyler` is a "legacy 4.0" dashboard styler to have the same root styles as in dashboard
		<GlobalStyler>
			<>{children}</>
			<ToasterProvider />
		</GlobalStyler>
	);
};
