import {
	OneTrustCookiesGroup,
	onCookieConsentChanged,
} from '@vctr-libs/vctr-integrations';
import { isCookiesConsentEnv } from '@vctr-libs/vctr-utils';

export function initOneTrustCookies() {
	if (!isCookiesConsentEnv()) return;

	insertOneTrustSettingsButton();
	onCookieConsentChanged(groups => processYoutubeIframes(groups));
}

function processYoutubeIframes(groups: OneTrustCookiesGroup[]) {
	const targetingCookiesConsent = groups.includes(
		OneTrustCookiesGroup.Targeting,
	);

	const iframeElements = [
		...document.querySelectorAll<HTMLIFrameElement>('iframe[src*=youtube]'),
		...document.querySelectorAll<HTMLIFrameElement>(
			'iframe[data-src*=youtube]',
		),
	];

	iframeElements.forEach(iframe => {
		if (targetingCookiesConsent) {
			const src = iframe.getAttribute('data-src');
			if (!src) return;
			iframe.src = src;
			iframe.setAttribute('style', iframe.getAttribute('data-style'));
			document
				.querySelectorAll('.blocked-youtube-iframe')
				.forEach(el => el.remove());
		} else {
			if (iframe.getAttribute('data-src')) return;
			const src = iframe.src;
			iframe.src = '';
			iframe.setAttribute('data-src', src);
			iframe.setAttribute('data-style', iframe.getAttribute('style'));
			iframe.setAttribute('style', 'display: none;');
			replaceIframeWithBlockedContent(iframe);
		}
	});
}

function insertOneTrustSettingsButton() {
	if (document.querySelector('#ot-sdk-btn')) return;

	const OneTrustSettingsButton = document.createElement('button');
	OneTrustSettingsButton.setAttribute('id', 'ot-sdk-btn');
	OneTrustSettingsButton.setAttribute('style', 'display: none;');
	OneTrustSettingsButton.classList.add('ot-sdk-show-settings');
	document.body.insertBefore(OneTrustSettingsButton, document.body.firstChild);
}

function replaceIframeWithBlockedContent(iframe: HTMLIFrameElement) {
	const blockedYoutubeInfo = document.createElement('div');

	if (iframe.width && iframe.height) {
		blockedYoutubeInfo.setAttribute(
			'style',
			`width: ${iframe.width}px; height: ${iframe.height}px;`,
		);
	} else {
		blockedYoutubeInfo.classList.add('blocked-youtube-iframe-ratio-sizing');
	}

	blockedYoutubeInfo.classList.add('blocked-youtube-iframe');
	if (!iframe.parentElement.classList.contains('w-embed-youtubevideo')) {
		blockedYoutubeInfo.classList.add('w-embed-youtubevideo');
	}

	blockedYoutubeInfo.innerHTML = `
    <div class="vctr-container">
      <div class="content__section__content">
        <div class="content__card__title-block">
          <h3 class="vctr-h3 content__section__title">
            Review your cookie consent to watch this video
          </h3>
        </div>
        <div class="content__card__buttons">
          <button type="button" onclick="document.querySelector('#ot-sdk-btn').click()" class="btn--outline">
            Review Cookie Consent
          </a>
        </div>
      </div>
    </div>
    `;
	iframe.parentElement.insertBefore(blockedYoutubeInfo, iframe);
}
