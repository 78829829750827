import React, { FC } from 'react';
import { Plan, BillingPeriod, PlanIds } from '@vctr-libs/vctr-api-client';
import {
	Muted,
	Small, PaddlePrice
} from '..';


export const PlanBillingText: FC<{
	plan: Plan;
	// plans: Plan[];
	isStudentDiscountAllowed: boolean;
	// studentDiscountInPercent: number;
	studentDiscountFlat: number;
	precalculatedPlanPrices: Array<PaddlePrice & { planId: PlanIds}>;
}> = ({ plan, isStudentDiscountAllowed, studentDiscountFlat, precalculatedPlanPrices }) => {
	if (!plan?.billing || !plan?.price) return null;

	// TODO as of Nov 2024, this containes a dirty hack of including non-student discount
	// rework this later
	const nonStudentDiscountedPrice = precalculatedPlanPrices?.find(p => p.planId === plan.id)?.recurringPrice ?? plan.price;

	// const monthlyPlan = plans.find(plan => plan.billing === BillingPeriod.MONTH);
	// const monthlyPlanPrice = monthlyPlan?.price;
	const yearlyPricingText = isStudentDiscountAllowed
		? `$${(plan.price - studentDiscountFlat) / 12}/month `
		: `$${ nonStudentDiscountedPrice / 12}/month `;

	// using monthly plan price * 12 here per product requirements
	const yearlyPricingBilledText = isStudentDiscountAllowed ? (
		// <>
		// 	(<s>${monthlyPlanPrice * 12}</s> after discount $
		// 	{(plan.price * (100 - studentDiscountInPercent)) / 100} billed annually)
		// </>
		<>(billed ${nonStudentDiscountedPrice - studentDiscountFlat} annually)</>
	) : (
		`($${nonStudentDiscountedPrice} billed annually)`
	);

	const monthlyPricingText = `$${nonStudentDiscountedPrice}`;

	const monthlyPricingBilledText = `(billed monthly)`;

	return (
		<div>
			{plan.billing === BillingPeriod.YEAR ? (
				<Small>
					{yearlyPricingText}
					<Muted>{yearlyPricingBilledText}</Muted>
				</Small>
			) : (
				<Small>
					{monthlyPricingText} <Muted>{monthlyPricingBilledText}</Muted>
				</Small>
			)}
		</div>
	);
};
