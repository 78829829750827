type GetInvitationLinkArgs = {
	invitationUuid: string;
};

/**
 * Calculates and returns an invitation link URL.
 *
 * @param {GetInvitationLinkArgs} args
 * @param {string} args.invitationUuid - UUID of the invitation.
 * @returns {string} - Calculated URL of invitation.
 */
export const getInvitationLink = (args: GetInvitationLinkArgs): string => {
	const { invitationUuid } = args;

	let pathname = window.location.pathname;

	if (pathname[pathname.length - 1] !== '/') {
		pathname += '/';
	}

	return `${window.location.origin}${pathname}?invite=${invitationUuid}`;
};
