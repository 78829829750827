import { Box, Button } from '@mui/material';
import React, { BaseSyntheticEvent, ReactNode } from 'react';

interface VeButtonProps {
	variant?: 'primary' | 'secondary' | 'outlined';
	icon?: ReactNode;
	children: ReactNode;
	onClick?: (ev: BaseSyntheticEvent) => void;
	disabled?: boolean;
	type?: 'button' | 'submit';
}

export function VeButton({
	variant = 'primary',
	icon,
	children,
	type = 'button',
	disabled,
	onClick,
}: VeButtonProps) {
	const isOutlined = variant === 'outlined';
	const isPrimary = variant === 'primary';

	return (
		<Box mb={2} mt={2}>
			<Button
				variant={isOutlined ? 'outlined' : 'contained'}
				size="large"
				color={isPrimary ? 'primary' : 'secondary'}
				startIcon={icon}
				sx={{ textTransform: 'none' }}
				type={type}
				fullWidth
				onClick={onClick}
				disabled={disabled}
			>
				{children}
			</Button>
		</Box>
	);
}
