import {
	isProductionSite,
	LocalStorage,
	userSettingsKeys,
} from '@vctr-libs/vctr-utils';

interface ABMPage {
	url: string;
	timestamp: number;
}

export interface ABMData {
	viewed_web_page_any_6_distinct: boolean;
	viewed_web_page_distinct_pages: number;
	viewed_web_page_product: boolean;
	viewed_web_page_pricing: boolean;
	multiple_web_page_visits_per_week: boolean;
}

export const INDUSTRY_PRODUCT_PAGES = [
	'3d-design-workflow-in-cycling',
	'vectary-for-cycling-industry-designers',
	'automotive',
	'vectary-for-automotive-industry',
	'manufacturing',
	'furniture',
	'fashion',
	'electronics',
	'consumer-products',
	'agencies',
	'augmented-reality-events-trade-shows',
];

const PRICING_PAGE = 'pricing';

export function ABMTrackingLogPageView() {
	if (!isProductionSite()) return;

	const abmPages: ABMPage[] =
		LocalStorage.getValueOfItem(userSettingsKeys.ABM_PAGES) || [];

	abmPages.push({
		url: window.location.href,
		timestamp: new Date().getTime(),
	});

	LocalStorage.setValueOfItem(userSettingsKeys.ABM_PAGES, abmPages);

	// Website - Viewed Non-Product Page
	// TODO: do in webflow
}

export function getABMTrackingData(): ABMData {
	const abmPages: ABMPage[] =
		LocalStorage.getValueOfItem(userSettingsKeys.ABM_PAGES) || [];

	const distinctPages: Record<string, number> = {};
	abmPages.forEach(page => {
		distinctPages[page.url] = distinctPages[page.url]
			? distinctPages[page.url] + 1
			: 1;
	});

	return {
		// Viewed Web Page - Any (6 distinct)
		viewed_web_page_any_6_distinct: Object.keys(distinctPages).length >= 6,

		// Viewed Web Page - Any Count
		viewed_web_page_distinct_pages: Object.keys(distinctPages).length,

		// Viewed Web Page - Product
		viewed_web_page_product: getViewedWebPageProduct(distinctPages),

		// Viewed Web Page - Pricing (2+ times)
		viewed_web_page_pricing:
			distinctPages[`${window.location.origin}/${PRICING_PAGE}/`] > 1,

		// Multiple Web Page Visits Per Week
		multiple_web_page_visits_per_week:
			getMultipleWebPageVisitsPerWeek(abmPages),
	};
}

function getViewedWebPageProduct(
	distinctPages: Record<string, number>,
): boolean {
	const viewedProductPages = Object.keys(distinctPages).filter(url =>
		INDUSTRY_PRODUCT_PAGES.includes(
			url.slice(window.location.origin.length + 1, -1),
		),
	).length;
	return viewedProductPages >= 2;
}

function getMultipleWebPageVisitsPerWeek(abmPages: ABMPage[]): boolean {
	const weekLong = 7 * 24 * 60 * 60 * 1000;
	const visitedPagesLastWeek = abmPages.filter(
		page => page.timestamp > new Date().getTime() - weekLong,
	);
	const first = visitedPagesLastWeek[0];
	const last = visitedPagesLastWeek[visitedPagesLastWeek.length - 1];
	// check intervals between them as a session (2+ hours)
	if (first && last && first !== last) {
		const timeBetween = last.timestamp - first.timestamp;
		return timeBetween > 2 * 60 * 60 * 1000;
	}

	return false;
}
