export const getDiffFullMonths = (dateFrom: Date, dateTo: Date): number => {
    let diffMonths = dateTo.getUTCMonth() - dateFrom.getUTCMonth();
    if (dateTo.getUTCDate() < dateFrom.getUTCDate()) diffMonths -= 1; // Need to truncate to get only the number of full months passed
    return diffMonths + (12 * (dateTo.getUTCFullYear() - dateFrom.getUTCFullYear()))
};

export const getDiffMonths = (dateFrom: Date, dateTo: Date): number => {
    let diffMonths = dateTo.getUTCMonth() - dateFrom.getUTCMonth();
    diffMonths += ((dateTo.getUTCDate() - dateFrom.getUTCDate()) / 30); // each started month
    return diffMonths + (12 * (dateTo.getUTCFullYear() - dateFrom.getUTCFullYear()))
};

export const getDateUTCFormat = (date: Date, format: string): string => {
    if (format === "YYYY-MM-DD" || format === "YYYY-MM-DDTHH" || format === "YYYY-MM-DDTHH:mm:ss.SSS") {
        return date.toISOString().slice(0, format.length);
    } else if (format === "YYMMDD") {
        return date.toISOString().replace(/-/g, "").slice(2, 8);
    } else if (format === "YYYY-MM-DD HH:mm:ss") {
        return date.toISOString().slice(0, format.length).replace("T", " ");
    }
    return date.toISOString();
};

export const cleanMinutes = (date: Date): Date => {
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0); 
    return date;
}
export const cleanHours = (date: Date): Date => {
    date.setUTCHours(0);
    cleanMinutes(date);
    return date;
}

export const isBefore = (d1: Date, d2: Date): boolean => {
    return d1?.getTime() < d2?.getTime()
};

export const isAfter = (d1: Date, d2: Date): boolean => {
    return d1?.getTime() > d2?.getTime()
};
