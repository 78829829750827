import styled from 'styled-components';
import { Link, TableCell, TableContainer } from '@mui/material';
import { textLight } from '../../../ui/themes/uko';
import { DashboardUkoThemeVariables } from '../../../ui/themes/dashboardUkoTheme';
import { defaultUserPicture } from '../defaultUserPicture';

const { gridGap, memberImageSize } = DashboardUkoThemeVariables;

export const MembersTable__Item__NameBlock = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	padding-right: 10px;
`;

export const MemberTableItemImage = styled.img`
	flex: 0 0 ${memberImageSize}px;
	width: ${memberImageSize}px;
	height: ${memberImageSize}px;
	border-radius: 100%;
	background-color: ${({ theme }) => theme.colors.c_light_grey};
	overflow: hidden;
	margin-right: ${gridGap / 2}px;
	background-size: cover;
	background-position: center;
	background-image: url(${defaultUserPicture});
`;

export const MemberTableItemLinkIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 ${memberImageSize}px;
	width: ${memberImageSize}px;
	height: ${memberImageSize}px;
	border-radius: 100%;
	background-color: ${({ theme }) => theme.colors.c_light_grey};
	overflow: hidden;
	margin-right: ${gridGap / 2}px;
	svg {
		opacity: 0.3;
		font-size: 18px;
	}
`;

export const MemberTableItemName = styled.div`
	flex: 0 1 auto;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: ${gridGap / 2}px;
	color: ${textLight.primary};
	word-break: break-word;
`;

export const MemberTableItemName__Email = styled.span`
	color: ${textLight.secondary};
`;
const memberTableItemContent = `
	flex: 1;
	display: flex;
	align-items: center;
`;

export const MemberTableItemContent = styled.div`
	${memberTableItemContent};
`;

export const MemberTableLinkInvitation__ContentLink = styled(Link)`
	display: block;
	${memberTableItemContent};
`;

export const MemberTableLinkInvitation__Icon = styled.div`
	flex: 1 0 ${memberImageSize}px;
`;
// UKO MUI styles

export const MembersTable__TableCell = styled(TableCell)``;
export const MembersTable__TableCell_Role = styled(MembersTable__TableCell)``;

export const MembersTable__TableContainer = styled(TableContainer)`
	${MembersTable__TableCell} {
		padding-top: ${gridGap}px;
		padding-bottom: ${gridGap}px;
		margin-top: ${gridGap}px;
		margin-bottom: ${gridGap}px;
	}
	thead {
		${MembersTable__TableCell} {
			color: ${textLight.secondary};
			font-weight: 600;
		}
	}
	tbody {
		${MembersTable__TableCell} {
			border-top: 1px solid ${({ theme }) => theme.colors.c_light_grey};
		}
	}
	${MembersTable__TableCell_Role} {
		padding-right: ${gridGap}px;
	}
`;
