import styled, { css } from 'styled-components';
import { hexToRGB } from '../../../utils';
import { InputProps } from '.';

export const Wrapper = styled.input<
	Pick<InputProps, 'isValid'> & { readOnly: boolean }
>`
	border-radius: ${({ theme }) => theme.borderRadius[0]};
	border: 1px solid ${({ theme }) => theme.colors.c_off_white};
	background-color: ${({ theme }) => theme.colors.c_off_white};
	padding: 5px 4px 5px 8px;
	font-size: ${({ theme }) => theme.fontSize.font_size_small};
	color: ${({ theme }) => theme.colors.c_grey};
	width: 100%;
	box-sizing: border-box;

	&::placeholder {
		font-size: ${({ theme }) => theme.fontSize.font_size_small};
		color: ${({ theme }) => theme.colors.c_mid_grey};
	}

	${readOnly =>
		!readOnly
			? css`
					&:not([disabled]):hover,
					&:not([disabled]):focus {
						outline: 0;
						box-shadow: 0px 0px 5px
							${({ theme }) => hexToRGB(theme.colors.c_almost_black, 0.3)};
						border: 1px solid ${({ theme }) => theme.colors.c_soft_grey};
						color: ${({ theme }) => theme.colors.c_almost_black};
					}
			  `
			: css`
					cursor: unset;
			  `}

	&:disabled {
		color: ${({ theme }) => theme.colors.c_soft_grey};
		cursor: not-allowed;

		&::placeholder {
			color: ${({ theme }) => theme.colors.c_soft_grey};
		}
	}

	[type='password'] {
		letter-spacing: 1px;

		&::placeholder {
			letter-spacing: normal;
		}
	}

	${({ isValid }) =>
		!isValid &&
		css`
			box-shadow: 0px 0px 5px
				${({ theme }) => hexToRGB(theme.colors.c_almost_black, 0.3)};
			border-color: ${({ theme }) => theme.colors.c_warning};
			color: ${({ theme }) => theme.colors.c_warning};
		`}
`;
export const Hint = styled.p`
	color: ${({ theme }) => theme.colors.c_warning};
	padding: 3px 8px 0;
	font-size: ${({ theme }) => theme.fontSize.font_size_xsmall};
`;
