import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export * from './contactUsButton';

export enum ButtonEnumType {
	SUBMIT = 'submit',
	BUTTON = 'button',
}

export enum ButtonEnumVariant {
	DEFAULT = 'default',
	PRIMARY = 'primary',
	DANGER = 'danger',
}
export interface ButtonProps {
	onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
	className?: string;
	type?: ButtonEnumType;
	variant?: ButtonEnumVariant;
	children: ReactNode;
	disabled?: boolean;
	capitalize?: boolean;
}

const Wrapper = styled.button<{
	disabled: boolean;
	variant: string;
	capitalize: boolean;
}>`
	display: inline-block;
	background-color: ${({ theme }) => theme.colors.c_light_mode_primary};
	color: ${({ theme }) => theme.colors.c_off_white};
	text-transform: capitalize;
	text-align: center;
	border-radius: ${({ theme }) => theme.borderRadius[0]};
	font-size: ${({ theme }) => theme.fontSize.font_size_small};
	line-height: normal;
	font-weight: ${({ theme }) => theme.fontWeights.font_weight_small};
	width: 100%;
	padding: 4px 8px;
	position: relative;
	border: 1px solid ${({ theme }) => theme.colors.c_light_mode_primary};
	margin: 0;
	cursor: pointer;
	cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
	vertical-align: middle;
	user-select: none;

	${({ capitalize }) =>
		!capitalize &&
		css`
			text-transform: none;
		`}

	${({ disabled }) =>
		!disabled &&
		css`
			&:hover {
				background-color: ${({ theme }) => theme.colors.c_white};
				color: ${({ theme }) => theme.colors.c_light_mode_primary};
			}
		`}

	// TODO sort out this mess with variants
    ${({ variant }) =>
		variant === ButtonEnumVariant.DEFAULT &&
		css`
			background-color: ${({ theme }) => theme.colors.c_light_grey};
			color: ${({ theme }) => theme.colors.c_dark_grey};
			border-color: ${({ theme }) => theme.colors.c_light_grey};
		`}

    ${({ variant }) =>
		variant === ButtonEnumVariant.DANGER &&
		css`
			background-color: ${({ theme }) => theme.colors.c_almost_white};
			border-color: ${({ theme }) => theme.colors.c_warning};
			color: ${({ theme }) => theme.colors.c_warning};

			&:hover {
				background-color: ${({ theme }) => theme.colors.c_warning};
				color: ${({ theme }) => theme.colors.c_almost_white};
			}
		`}

    // Button is disabled so override its styles.
    ${({ disabled }) =>
		disabled &&
		css`
			background-color: ${({ theme }) => theme.colors.c_soft_grey};
			color: ${({ theme }) => theme.colors.c_almost_white};
			border-color: ${({ theme }) => theme.colors.c_soft_grey};
		`}
`;

export const Button: FC<ButtonProps> = ({
	className,
	type,
	variant,
	onClick,
	children,
	disabled = false,
	capitalize = true,
	...analyticsProps
}) => (
	<Wrapper
		className={className}
		variant={variant || ButtonEnumVariant.PRIMARY}
		type={type || ButtonEnumType.BUTTON}
		onClick={onClick}
		disabled={disabled}
		capitalize={capitalize}
		{...analyticsProps}
	>
		{children}
	</Wrapper>
);
