import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const LoadKeyframes = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

export const LoaderSC = styled.div`
	display: flex;
	border-radius: 50%;
	color: ${({ theme }) => theme.colors.c_almost_black};
	font-size: 11px;
	text-indent: -99999em;
	margin: 55px auto;
	width: 10em;
	height: 10em;
	box-shadow: inset 0 0 0 1em;
	transform: translateZ(0);

	&:after,
	&:before {
		border-radius: 50%;
		position: absolute;
		content: '';
	}

	&:before {
		width: 5.2em;
		height: 10.2em;
		background: ${({ theme }) => theme.colors.c_white};
		border-radius: 10.2em 0 0 10.2em;
		top: -0.1em;
		left: -0.1em;
		transform-origin: 5.1em 5.1em;
		animation: ${LoadKeyframes} 2s infinite ease 1.5s;
	}

	&:after {
		width: 5.2em;
		height: 10.2em;
		background: ${({ theme }) => theme.colors.c_white};
		border-radius: 0 10.2em 10.2em 0;
		top: -0.1em;
		left: 4.9em;
		transform-origin: 0.1em 5.1em;
		animation: ${LoadKeyframes} 2s infinite ease;
	}
`;

const WrapperSC = styled.div<{ verticalAlign: string; inline: boolean }>`
	position: ${({ inline }) => (inline ? 'relative' : 'absolute')};
	background-color: white;
	opacity: 0.5;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: ${({ verticalAlign }) => verticalAlign};
	justify-content: center;
	z-index: 1;
	top: 0px;
	left: 0px;
`;

export const Resizer = styled.div`
	transform: scale(0.2);
`;

type Props = {
	verticalAlign?: string;
	isBlank?: boolean;
	inline?: boolean;
};

export const Spinner: FC<Props> = ({
	verticalAlign = 'center',
	isBlank = false,
	inline = false,
}) => {
	return (
		<WrapperSC verticalAlign={verticalAlign} inline={inline}>
			<Resizer>{!isBlank && <LoaderSC>Loading</LoaderSC>}</Resizer>
		</WrapperSC>
	);
};
