import { EventLogger } from '@vctr-libs/vctr-analytics';
import { UserBase } from '@vctr-libs/vctr-api-client';
import {
	Env,
	isEnvByHostname,
	isProductionSite,
	LocalStorage,
	userSettingsKeys,
} from '@vctr-libs/vctr-utils';

declare global {
	interface Window {
		clearbit: {
			identify: (uuid: string, data: Record<string, any>) => void;
		};
	}
}

export function initializeClearbit({
	user,
	AbmData,
}: {
	user: UserBase;
	AbmData?: Record<string, any>;
}) {
	const cbScript = document.createElement('script');
	cbScript.src =
		'https://tag.clearbitscripts.com/v1/pk_70c9b8051d8f560a11623c38177251b3/tags.js';
	cbScript.id = 'clearbit-tracker';

	const isInserted = !!document.querySelector('#clearbit-tracker');
	if (isInserted) return;

	document.body.appendChild(cbScript);

	if (!user) return;

	cbScript.addEventListener('load', function () {
		// TODO it can accept a user object instead of a request
		// current user is requested on every app

		try {
			updateClearbitUser(user, AbmData);
		} catch (error) {
			return console.log('Clearbit error', error);
		}
	});
}

export function updateClearbitUser(
	user: UserBase,
	AbmData?: Record<string, any>,
) {
	if (!user) return;

	const { uuid, email, name, created: createdAt, trial_used, pro_used } = user;

	const country = user.geo ? user.geo.country : null;
	const data = {
		email,
		name,
		country,
		signed_up: createdAt,
		questionnaire: user.questionnaire?.occupation?.value,
		trial_used,
		pro_used,
		...(AbmData || {}),
	};

	clearbitIdentify(uuid, data);
}

/** incomplete property set — only what we may need */
export type ClearbitRevealData = {
	domain: string;
	// e.g. "company"
	type: string;
	company: {
		id: string;
		name: string;
		domain: string;
		category: {
			sector: string;
			industryGroup: string;
			industry: string;
			subIndustry: string;
		};
		tags: string[];
		description: string;
		foundedYear: number;
		// e.g. "America/New_York"
		timeZone: string;
		geo: {
			country: string;
			countyCode: string;
		};
		metrics: {
			employees: number;
			// e.g. "11-50"
			employeesRange: string;
			raised: number;
			annualRevenue: number;
			// e.g. "$1M-$10M"
			estimatedAnnualRevenue: string;
		};
	};
};

export function clearbitIdentify(ID: string, data: Record<string, any>) {
	if (!isProductionSite()) return;
	if (!window?.clearbit?.identify) return;

	window.clearbit.identify(ID, data);
}

export async function clearbitRevealCompany(
	eventLogger: EventLogger,
	user?: UserBase,
	forceReveal = false,
): Promise<ClearbitRevealData> {
	// if (!isProductionSite()) return;
	if (!([Env.Prod, Env.Beta] as Env[]).some(env => isEnvByHostname(env))) return;
	if (user && user.email.includes('@vectary.com')) return;

	try {
		const companyData = await getClearbitRevealData(forceReveal);

		eventLogger.logEvent([
			'company_reveal',
			{
				data: companyData,
			},
		]);

		return companyData;
	} catch (error) {
		throw Error(error);
	}
}

export async function getClearbitRevealData(forceReveal = false): Promise<any> {
	const storedRevealData = LocalStorage.getValueOfItem(
		userSettingsKeys.CLEARBIT_REVEAL_DATA,
	);
	// we are limiting the number of requests
	// to avoid hitting our monthly limit of 150k
	if (storedRevealData && !forceReveal)
		return Promise.resolve(storedRevealData);

	const revealData = await (
		await fetch(
			'https://reveal.clearbit.com/v1/companies/reveal?authorization=pk_70c9b8051d8f560a11623c38177251b3',
		)
	).json();
	LocalStorage.setValueOfItem(
		userSettingsKeys.CLEARBIT_REVEAL_DATA,
		revealData,
	);

	return Promise.resolve(revealData);
}
