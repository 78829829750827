import styled from 'styled-components';

export const Wrapper = styled.div<{ width: number }>`
	max-width: ${({ width }) => `${width}px`};
	display: flex;
	position: relative;
	flex-direction: column;
	border-radius: 8px;
	overflow-x: hidden;
`;
export const Slideshow = styled.div<{ leftOffset?: number }>`
	display: flex;
	flex-direction: row;
	left: ${({ leftOffset }) => (leftOffset != undefined ? leftOffset : 120)}px;
	position: relative;

	transition: left 0.5s ease-out;
	border-radius: 8px;
`;
export const NavigationDots = styled.div<{
	itemsCount: number;
	itemHeight: number;
}>`
	display: flex;
	position: absolute;
	left: calc(50% - ${({ itemsCount }) => (itemsCount * 16) / 2}px);
	top: ${({ itemHeight }) => itemHeight - 24}px;
`;
export const DotWrapper = styled.div`
	display: flex;
	width: 16px;
	height: 16px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;
export const Dot = styled.div<{ isSelected: boolean }>`
	display: flex;
	background-color: ${({ theme, isSelected }) =>
		isSelected ? theme.colors.c_light_mode_primary : theme.colors.c_mid_grey};
	width: 6px;
	height: 6px;
	border-radius: 50%;
	opacity: 0.7;

	transition: background-color 0.5s ease-out;
`;
export const Item = styled.div<{ isSelected: boolean }>`
	display: flex;
	flex-direction: column;
	font-family: ${({ theme }) => theme.fontFamily};
	color: ${({ theme }) => theme.colors.c_almost_black};

	opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
	transition: opacity 0.5s ease-out;
`;
export const Content = styled.div`
	margin-top: 20px;
	/* margin-bottom: 24px; */
	margin-left: 24px;
	margin-right: 24px;
	line-height: 1.3em;
`;
export const Title = styled.div`
	display: flex;
	font-size: ${({ theme }) => theme.fontSize.font_size_xlarge};
	line-height: 1.3em;
	font-weight: ${({ theme }) => theme.fontWeights.font_weight_small};
	padding-bottom: 16px;
`;
export const Description = styled.div`
	display: flex;
	font-size: ${({ theme }) => theme.fontSize.font_size_small};
	font-weight: ${({ theme }) => theme.fontWeights.font_weight_xsmall};
	line-height: 130%;
	padding-bottom: 16px;
`;

export const ItemImage = styled.img<{
	width: number;
	imgAspectRatio?: string | number;
}>`
	display: flex;
	width: ${({ width }) => `${width}px`};
	object-fit: cover;
	aspect-ratio: ${({ imgAspectRatio }) =>
		`${imgAspectRatio ? imgAspectRatio : 'auto'}`};

	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
`;

export const ItemVideo = styled.video<{
	width: number;
	imgAspectRatio?: string | number;
}>`
	display: flex;
	width: ${({ width }) => `${width}px`};
	object-fit: cover;
	aspect-ratio: ${({ imgAspectRatio }) =>
		`${imgAspectRatio ? imgAspectRatio : 'auto'}`};

	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
`;

export const ArrowImage = styled.img`
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
`;
const IconWrapper = styled.div<{ itemHeight: number }>`
	display: flex;
	position: absolute;
	cursor: pointer;
	top: ${({ itemHeight }) => itemHeight / 2}px;
	width: 32px;
	height: 32px;
`;
export const ArrowRightWrapper = styled(IconWrapper)<{ itemHeight: number }>`
	right: 8px;
`;
export const ArrowLeftWrapper = styled(IconWrapper)<{ itemHeight: number }>`
	left: 8px;
`;
