import React from 'react';
import { Components, Theme, PaletteColor } from '@mui/material/styles';
// MUI ICON COMPONENTS
import Info from '@mui/icons-material/Info';
import Error from '@mui/icons-material/Error';
import Warning from '@mui/icons-material/Warning';
import CheckCircle from '@mui/icons-material/CheckCircle';
// CUSTOM UTILS METHOD
import { isDark } from '../constants';
import { vectaryColors } from '../colors';

declare module '@mui/material/Alert' {
	interface AlertPropsColorOverrides {
		'warning-grey': true;
	}
}

const standardStyle = (color: PaletteColor) => ({
	color: color.main,
	backgroundColor: color[50],
});

const outlinedStyle = (color: PaletteColor) => ({
	color: color.main,
	borderColor: color.main,
	backgroundColor: color[50],
});

const actionBtnStyle = (primary: string, secondary: string) => ({
	'& .btn-group button': {
		':first-of-type': {
			border: `1px solid ${secondary}`,
			marginRight: '1rem',
			marginLeft: 0,
		},
		':last-of-type': {
			backgroundColor: secondary,
			color: primary,
			marginRight: 0,
			marginLeft: '1rem',
		},
	},
});

const Alert = (theme: Theme): Components['MuiAlert'] => {
	const { primary, success, error, warning, common, grey } = theme.palette;

	return {
		defaultProps: {
			iconMapping: {
				info: <Info />,
				error: <Error />,
				success: <CheckCircle />,
				warning: <Warning />,
			},
		},
		styleOverrides: {
			root: {
				borderRadius: 16,
				fontSize: 12,
				fontWeight: 600,
				alignItems: 'center',
				padding: `${10.5 / 16}rem ${14 / 16}rem`,
			},
			standardError: standardStyle(error),
			standardSuccess: standardStyle(success),
			standardWarning: standardStyle(warning),
			standardInfo: {
				...standardStyle(primary),
				'& .MuiAlert-icon': { color: primary.main },
			},

			outlinedError: outlinedStyle(error),
			outlinedSuccess: outlinedStyle(success),
			outlinedWarning: outlinedStyle(warning),
			outlinedInfo: {
				...outlinedStyle(primary),
				'& .MuiAlert-icon': { color: primary.main },
				...(isDark(theme) && { backgroundColor: grey[700] }),
			},

			filledWarning: { color: common.white },
			filledSuccess: {
				color: common.white,
				backgroundColor: success[600],
			},
			filledInfo: { color: common.white, backgroundColor: primary.main },

			action: ({ ownerState: { color, severity, variant } }) => ({
				':has( > .btn-group)': {
					'padding': 0,
					'marginRight': 0,
					'& button': {
						borderRadius: 10,
						padding: `${7.5 / 16}rem ${10 / 16}rem`,
						fontWeight: 600,
					},
				},

				...(severity === 'info' && {
					...(variant === 'filled' &&
						actionBtnStyle(primary.main, common.white)),
					...(variant === 'outlined' &&
						actionBtnStyle(common.white, primary.main)),
					...(variant === 'standard' &&
						actionBtnStyle(common.white, primary.main)),
				}),
				...(severity === 'error' && {
					...(variant === 'filled' && actionBtnStyle(error.main, common.white)),
					...(variant === 'outlined' &&
						actionBtnStyle(common.white, error.main)),
					...(variant === 'standard' &&
						actionBtnStyle(common.white, error.main)),
				}),
				...(severity === 'success' && {
					...(variant === 'filled' &&
						actionBtnStyle(success.main, common.white)),
					...(variant === 'outlined' &&
						actionBtnStyle(common.white, success.main)),
					...(variant === 'standard' &&
						actionBtnStyle(common.white, success.main)),
				}),
				...(severity === 'warning' && {
					...(variant === 'filled' &&
						actionBtnStyle(warning.main, common.white)),
					...(variant === 'outlined' &&
						actionBtnStyle(common.white, warning.main)),
					...(variant === 'standard' &&
						actionBtnStyle(common.white, warning.main)),
				}),
				...(color === 'warning-grey' && {
					...(variant === 'filled' && actionBtnStyle(common.white, grey[800])),
					...(variant === 'outlined' &&
						actionBtnStyle(common.white, grey[800])),
					...(variant === 'standard' &&
						actionBtnStyle(common.white, grey[800])),
				}),
			}),
			// icon: ({ ownerState: { color } }) => ({
			// 	...(color === 'warning-grey' && {
			// 		fontSize: 16,
			// 	}),
			// }),
		},
		variants: [
			{
				props: { color: 'warning-grey' },
				style: {
					backgroundColor: vectaryColors.background,
					color: grey[600],
					fontWeight: 400,
				},
			},
		],
	};
};

export default Alert;
