import { VeBox } from '../_base';
import React from 'react';
import { VectaryIcon } from '../../../icons';

export function SignupinLogo() {
	return (
		<VeBox
			mb={2}
			display={'flex'}
			flexDirection={'row'}
			justifyContent={'center'}
		>
			<VectaryIcon />
		</VeBox>
	);
}
