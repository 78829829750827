import { ArTranslationObject } from '../translationObjects';
import { Language, LanguageResource } from '../translator';

export const ARTranslations: LanguageResource<ArTranslationObject> = {
	[Language.ENGLISH]: {
		qrTitle: 'Augmented Reality',
		qrSubtitle:
			'Point your camera at the QR code. Tap the banner that appears on your screen.',
		qrCloseButtonLabel: 'Close',
	},
	[Language.SPANISH]: {
		qrTitle: 'Augmented Reality',
		qrSubtitle:
			'Apunte su cámara al código QR, luego toque el mensaje que aparece.',
		qrCloseButtonLabel: 'Cerrar',
	},
	[Language.FRENCH]: {
		qrTitle: 'Augmented Reality',
		qrSubtitle:
			'Dirigez votre appareil photo vers le code QR. Appuyez sur la bannière qui apparaît sur votre écran.',
		qrCloseButtonLabel: 'Fermer',
	},
	[Language.GERMAN]: {
		qrTitle: 'Augmented Reality',
		qrSubtitle:
			'Richte deine Kamera auf den QR-Code und tippe dann auf die eingeblendete Mitteilung.',
		qrCloseButtonLabel: 'Schließen',
	},
	[Language.HEBREW]: {
		qrTitle: 'מציאות רבודה',
		qrSubtitle:
			'כוון את המצלמה אל קוד ה-QR.<br> הקש על הבאנר שמופיע על המסך שלך.',
		qrCloseButtonLabel: 'סגור',
	},
	[Language.ITALIAN]: {
		qrTitle: 'Augmented Reality',
		qrSubtitle:
			'Punta la fotocamera verso il codice QR, quindi tocca il messaggio che appare.',
		qrCloseButtonLabel: 'Chiudere',
	},
	[Language.LITHUANIAN]: {
		qrTitle: 'Papildyta Realybė',
		qrSubtitle:
			'Nukreipkite kamerą į QR kodą. Paspauskite ekrane rodomą reklamjuostę.',
		qrCloseButtonLabel: 'Uždaryti',
	},
	[Language.RUSSIAN]: {
		qrTitle: 'Augmented Reality',
		qrSubtitle:
			'Наведите камеру на QR-код, затем нажмите на появившееся сообщение.',
		qrCloseButtonLabel: 'Закрыть',
	},
	[Language.PORTUGUESE]: {
		qrTitle: 'Realidade Aumentada',
		qrSubtitle:
			'Aponte sua camera para o QR code e clique na mensagem na tela.',
		qrCloseButtonLabel: 'Fechar',
	},
	[Language.JAPANESE]: {
		qrTitle: 'AR (拡張現実)',
		qrSubtitle:
			'上記QRコードを読み取り、その後画面に表示されるバナーをタップしてください。',
		qrCloseButtonLabel: '閉じる',
	},
};
