const ignoredEmails = [
    "zrubec.michal@gmail.com",
    "t.singerova@gmail.com",
    "andrej.klement@gmail.com",
    "boris.gombarcik@gmail.com",
    "beranova.natalia@gmail.com",
    "hello@milangladis.com",
    "hello@meo.sk",
    "kasanicka.m@gmail.com",
    "miroslavabiel@gmail.com",
    "p.sovis@gmail.com",
    // Estimated test addresses following:
    "vecttester1991@gmail.com",
    "lubopanak@gmail.com",
    "email@milangladis.sk",
    "straka.kubo@gmail.com",
    "krivdasimon@gmail.com",
    "amankovecky@gmail.com",
    "janalaksa19@gmail.com",
    "matt350z@gmail.com",
    "vectary-tes468784@quick-mail.club",
    "trepo@rfopgerp.com",
    "vectary-test73198@direct-mail.info",
    "vectary-test587319@mail-register.com",
    "dzonny19@azet.sk",
    "katarina.krizova@yahoo.com",
    "vectary-test573469712@clearmail.online",
    "orsag91@gmail.com",
    "efsdfsdfsdfvectary@sadasd.as",
    "joekoker91@gmail.com",
    "vectary-test45882219@click-email.com",
    "vectary-test5579934@virtualemail.info",
    "danosedlak@gmail.com",
    "wsfcsdc55@fhffh.com",
    "kdkljd@edfjeipof.com",
    "bronamol@gmail.com",
    "vpdhmusic@gmail.com",
    "yanjuli89@gmail.com",
    "ilcik@cg.tuwien.ac.at",
    "klco.roman@gmail.com",
    "jamesbuchananmarketing@gmail.com",
    "ferko.marian@gmail.com",
    "vectary-test0004568@coinlink.club",
    "vectary-test-erewtqw@efew.com",
    "asdasdasdvect@sasas.as",
    "joekokersasasasas91@gmail.com",
    "sexygeek21@gmail.com",
    "ewfwefwef@asdasd.as",
    "test!vectary@gdsg.sk",
    "testingvectary@gmail.com",
    "vecasdasd@fafas.sk",
    "vectarytesting@gmail.com",
    "vectary-test5734622@key-mail.net",
    "yawizu@quick-mail.online",
    "vectary-test-pay1@test.com",
    "vectary-test1239477@directmail.top",
    "rigreoi@veroigero.com",
    "vectary-test758111@key-mail.net",
    "vectary-test4466772@clickmail.info",
    "vectary-test77334@mrmail.info",
    "testman01@nextemail.net",
    "rkgurio@ieouwreoi.com",
    "rlrptruoi@oefuewoiew.com",
    "cksuwoi@feoiuewoi.com",
    "rgre@reger.com",
    "josko@pavolsovis.com",
    "vectary.testa-sdfasdfasfd@asfdasdfasdf.com",
    "1@pavolsovis.com",
    "vectary-test854433@business-agent.info",
    "vectary-test580033@email-list.online",
    "vectary-test59722@host-info.com",
    "vectary-test12334@cyber-host.net",
    "vectary-test4546548@webgmail.info",
    "test-vectary91735546@mailing.one",
    "butusa@freeweb.email",
    "nihumupe@alpha-web.net",
    "midadawihe@easymail.top",
    "kgheroi@greipo.com",
    "vectary-test12378944@first-email.net",
    "vectary-test1788896@5-mail.info",
    "kgreoig@oifirpeo.com",
    "kgroi@oeagiopre.com",
    "fgirpe@operre.com",
    "vectary-test45872@master-mail.net",
    "vectary-test15712@red-mail.top",
    "vectary-test35478@myfavorite.info",
    "rlkthroi@freigjeroi.com",
    "vectary-test12234@net-solution.info",
    "vectary-test125@myfavorite.info",
    "rgjroiej@irgjroie.com",
    "rigureoi@rogirp.com",
    "ofeoifj@ewfpkepo.com",
    "lp23@lp.com",
    "lp4@lp.com",
    "lp5@lp.com",
    "lp3@lp.com",
    "lp@lp.com",
    "firgjroi@efijeowi.com",
    "abcdef@abcdef.com",
    "vectary-testjhcdchjvsc333@network-source.com",
    "vectary-test23762372nndgdqtor@virtual-email.com",
    "vectary-test@dfekfel.com",
    "straka.kubo+test2@gmail.com",
    "rofjroiew@iewfjiewo.com",
    "ewfhewoh@eiowfjeoiw.com",
    "aoifgwoi@rifjwoi.com",
    "frwigjwoi@igjiwef.cocm",
    "rgjrgoi@rgrp.com",
    "rfhruoifhwi@ofjeoiw.com",
    "vectary-test468@mail4.online",
    "vectary-test123456@virtual-email.com",
    "vectary-test24867@network-source.com",
    "vectary-test@oeafeop.com",
    "vectary-test5689@nextemail.net",
    "marotur@virtual-email.com",
    "q747805@nwytg.net",
    "retgjreoi@oerptipo.com",
    "vcvbnmklkjhgfdfg@gmail.com",
    "ahoj@alicasevcikova.com",
    "lylynka@gmail.com",
    "dsfkjkfj@dfkjreiop.com",
    "rgjroige@rigreoigure.com",
    "edcewc@gdjbv.com",
    "dghrogh@fieogfreoi.com",
    "fkgjroi@froigrei.com",
    "koko@voltaer.com",
    "fowidey@heros3.com",
    "sevcikova.alica@gmail.com",
    "wurocajake@spindl-e.com",
    "juniel.menno@lndex.org",
    "bofoso@nando1.com",
    "rytmus@autowb.com",
    "tejewiw@zdfpost.net",
    "ceskjhbcvhjb66@rsvhr.com",
    "bewefele@zsero.com",
    "lumomuy@idx4.com",
    "vuzet@zdfpost.net",
    "jilewat@geroev.net",
    "bahigo@voltaer.com",
    "vsemivu@dabox.com",
    "vasemivu@daabox.com",
    "tester2@dev.org",
    "tester@dev.org",
    "hugudije@kulmeo.com",
    "pogekekoye@autowb.com",
    "ciloruri@mail4gmail.com",
    "contactus@jaumecalm.com",
    "joekoker932323231@gmail.com",
    "kholmes@kowessex.co.uk",
];
export const regExpIgnoredEmails = (): string | RegExp => {

    let regExp = `((.+)@(vectary.com))|((vectary\\.test\+.+)@(.+))|((.*)vectary(.*)test(.*))`;
    ignoredEmails.forEach((email: string) => {
        regExp = `${regExp}|(${email})`;
    });
    return new RegExp(regExp, "g");
};
