import fetch from "cross-fetch";

export enum GoogleChatSpaceUrl {
	Deploy = 'https://chat.googleapis.com/v1/spaces/AAAAiyT4WnY/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=Cv6XH4vmVYhwIaO7vwS5gzi_7sBQ-P-obLvcvAsc0P8%3D',
	CICD = 'https://chat.googleapis.com/v1/spaces/AAAAfTMfsC8/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=PW9LtdUZ-iwh5MU0P7GafWpFoNOL24_hD7eITp5gy1U%3D',
	CancelledSubscriptions = 'https://chat.googleapis.com/v1/spaces/AAAAUXuqNXQ/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=gMAObHDop08RUDoZuvIQ611gwJ61R0OPXv-Q1jhCshI',
	EducationPlanRequests = 'https://chat.googleapis.com/v1/spaces/AAAAnONB5UI/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=OI3oRmbn9Cv7fC9q_zT1Fhvuvl2NOe7ySWal5eN6D4M',
	SalesNotification = 'https://chat.googleapis.com/v1/spaces/AAAAthqfUlw/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=uZoJz0Vwu_od8deCnlEB20Kw5ZF9pvWXuDuACyvJAFE',
	HubspotSalesNotifications__EnterpriseChat = 'https://chat.googleapis.com/v1/spaces/AAAAXhSkVXQ/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=7eKX7_Ay0gTM1Bh-ymTWp6gWWvHPiDD-UmvtV1fFABE'
}

export const postToGoogleChat = async ({ googleUrl, googleData }: { googleUrl: GoogleChatSpaceUrl, googleData: { text: string } }) => {
	return fetch(googleUrl, { method: "POST", body: JSON.stringify(googleData) })
		.then(r => r.status >= 400 ? console.log("Upload failed!", r.status) : r.json())
		.then(r => {
			if (r) {
				console.info(googleData, r)
				console.log("Uploaded successfully!")
			}
		})
}

export const postToChat = async ({ data, chatWebhookUrl }: { data: string, chatWebhookUrl: string }) => {
	return fetch(chatWebhookUrl, { method: "POST", body: JSON.stringify({ text: data }) })
		.then(r => {
			if (r) {
				console.log("Successfully posted to chat");
			}
		}).catch((error) => {
			console.log("Failed to report test results to chat", JSON.stringify(error));
		});
}
