export const WorkspaceBlacklist = [
	// real blacklisted workspaces
	// '5cd6740d-1337-4fda-899d-d1589a8e325e', --> Gustav By Cocktail, I'm keeping you here...
	// Vectary testing workspaces
	'ab488464-6b9e-4c13-820e-d5b7049ef9d0',
	'14d1ff42-d85c-4e17-9330-d6c64b1bdd54',
	// dev testing workspaces
	'0afb744e-a565-4269-be79-19c72638ca2e',
	'bfec16f4-5a4f-4304-b82d-18bae4767a17',
];

export enum PlanIds {
	// Active plans
	// as of 2024-05-26 product requirements

	// SETUP is introduced
	SETUP = 'SETUP',
	// EDUCATION plan is introduced instead of Starter for users with Student and Educator occupations
	EDUCATION = 'EDUCATION',
	LOCKED = 'LOCKED',

	// Active plans with Paddle subscription as of 2024-05-26 product requirements
	PRO_2024_06_MONTHLY_29_TRIAL = 'PRO_2024_06_MONTHLY_29_TRIAL',
	PRO_2024_06_YEARLY_288_TRIAL = 'PRO_2024_06_YEARLY_288_TRIAL',
	PRO_2024_06_MONTHLY_29 = 'PRO_2024_06_MONTHLY_29',
	PRO_2024_06_YEARLY_288 = 'PRO_2024_06_YEARLY_288',

	// LEGACY plans with Paddle subscription
	PRO_2023_06_MONTHLY_19 = 'PRO_2023_06_MONTHLY_19',
	PRO_2023_06_YEARLY_180 = 'PRO_2023_06_YEARLY_180',
	PRO_2023_06_MONTHLY_19_TRIAL = 'PRO_2023_06_MONTHLY_19_TRIAL',
	PRO_2023_06_YEARLY_180_TRIAL = 'PRO_2023_06_YEARLY_180_TRIAL',

	// Custom plans, subscription is handled by Sales
	ENTERPRISE = 'ENTERPRISE',
	CUSTOM = 'CUSTOM',

	// Archived plans
	// don't use for new subscriptions
	// STARTER_10 is archived as of 2024-05-26
	STARTER_10 = 'STARTER_10',
	STARTER_25 = 'STARTER_25',
	PRO = 'PRO',
	PRO_YEARLY = 'PRO_YEARLY',
	PRO_19 = 'PRO_19',
	PRO_180_YEARLY = 'PRO_180_YEARLY',
	PRO_19_TRIAL = 'PROD_19_TRIAL',
	PRO_180_YEARLY_TRIAL = 'PROD_180_TRIAL',
	BUSINESS = 'BUSINESS',

	// Old plans
	FREE = 'FREE',
	PREMIUM = 'PREMIUM',
	PREMIUM_YEARLY = 'PREMIUM_YEARLY',
	COMPANY = 'COMPANY',
	INVOICE = 'INVOICE',
	LINKED = 'LINKED',

	// Even older plans
	LITE_9 = 'LITE_9',
	LITE_9_YEARLY = 'LITE_9_YEARLY',
	PREMIUM_25 = 'PREMIUM_25',
	PREMIUM_25_YEARLY = 'PREMIUM_25_YEARLY',
	PREMIUM_15 = 'PREMIUM_15',
	PREMIUM_15_YEARLY = 'PREMIUM_15_YEARLY',
	PREMIUM_12 = 'PREMIUM_12',
	PREMIUM_1 = 'PREMIUM_1',
	VECTARY_1 = 'VECTARY_1',
}

// TODO get this from plans list
// find out where it's used
// it seems to be [ "prod trial monthly", "sandbox trial monthly", "prod trial yearly", "sandbox trial yearly"]
// previous version is:
// export const CURRENT_PRO_TRIAL_PLAN_PADDLE_IDS = [833761, 52953, 833762, 52954];
export const CURRENT_PRO_TRIAL_PLAN_PADDLE_IDS = [882769, 69286, 882770, 69287];

export const PLAN_IDS_AVAILABLE_TRIAL: PlanIds[] = [
	// PlanIds.PRO_2024_06_MONTHLY_29_TRIAL,
	// PlanIds.PRO_2024_06_YEARLY_288_TRIAL,
];
export const PLAN_IDS_AVAILABLE_PRO: PlanIds[] = [
	PlanIds.PRO_2024_06_MONTHLY_29,
	PlanIds.PRO_2024_06_YEARLY_288,
];

type PlansList = { [key: string]: PlanIds };

export const CURRENT_PRO_TRIAL_PLANS: PlansList = {
	// PRO_MONTHLY_TRIAL: PlanIds.PRO_2024_06_MONTHLY_29_TRIAL,
	// PRO_YEARLY_TRIAL: PlanIds.PRO_2024_06_YEARLY_288_TRIAL,
};

export const CURRENT_PRO_PLANS: PlansList = {
	PRO_MONTHLY: PlanIds.PRO_2024_06_MONTHLY_29,
	PRO_YEARLY: PlanIds.PRO_2024_06_YEARLY_288,
	...CURRENT_PRO_TRIAL_PLANS,
};

const CurrentAllowedBuyParams = Object.values(CURRENT_PRO_PLANS).map(val => {
	return {
		[val]: val,
	};
});

export const AllowedBuyParams = {
	EDUCATION: 'EDUCATION',
	PRO: 'PRO',
	PRO_TRIAL: 'PRO_TRIAL',
	PRO_19: 'PRO_19',
	PRO_180_YEARLY: 'PRO_180_YEARLY',
	PRO_YEARLY: 'PRO_YEARLY',
	PRO_YEARLY_TRIAL: 'PRO_YEARLY_TRIAL',
	// PREMIUM is here to support "?buy=PREMIUM" urls from the website or any other page
	PREMIUM: 'PREMIUM',
	...CurrentAllowedBuyParams,
} as const;

/** Currently available plans (without locked) */

export const CURRENT_PLANS: PlansList = {
	SETUP: PlanIds.SETUP,
	EDUCATION: PlanIds.EDUCATION,
	...CURRENT_PRO_PLANS,
};

export const FREE_PLANS: PlanIds[] = [
	PlanIds.EDUCATION,
	PlanIds.SETUP,
	PlanIds.STARTER_10,
	PlanIds.STARTER_25,
	PlanIds.LOCKED,
];

/** Legacy PRO plans that may be in use
 *
 * TODO add proper "exclusion" to have "everything rather than active" here
 */
export const LEGACY_PRO_PLANS: PlanIds[] = [
	PlanIds.PREMIUM,
	PlanIds.PREMIUM_1,
	PlanIds.PREMIUM_12,
	PlanIds.PREMIUM_15,
	PlanIds.PREMIUM_15_YEARLY,
	PlanIds.PREMIUM_25,
	PlanIds.PREMIUM_25_YEARLY,
	PlanIds.PREMIUM_YEARLY,
	PlanIds.PRO,
	PlanIds.PRO_YEARLY,
	PlanIds.PRO_19,
	PlanIds.PRO_19_TRIAL,
	PlanIds.PRO_2023_06_MONTHLY_19,
	PlanIds.PRO_2023_06_YEARLY_180,
	PlanIds.PRO_2023_06_MONTHLY_19_TRIAL,
	PlanIds.PRO_2023_06_YEARLY_180_TRIAL,
	PlanIds.PRO_2024_06_MONTHLY_29_TRIAL,
	PlanIds.PRO_2024_06_YEARLY_288_TRIAL,
	PlanIds.PRO_180_YEARLY,
	PlanIds.PRO_180_YEARLY_TRIAL,
	PlanIds.LITE_9,
	PlanIds.LITE_9_YEARLY,
];

export const LEGACY_BUSINESS_PLANS: PlanIds[] = [
	PlanIds.LINKED,
	PlanIds.ENTERPRISE,
	PlanIds.CUSTOM,
	PlanIds.INVOICE,
	PlanIds.COMPANY,
	PlanIds.VECTARY_1,
];

export const LEGACY_BUSINESS_PLAN_IDS: PlanIds[] = LEGACY_BUSINESS_PLANS
	? Object.values(LEGACY_BUSINESS_PLANS)
	: [];

// somehow it's undefined or null in tests
const ACTIVE_PRO_PLAN_IDS = CURRENT_PRO_PLANS
	? Object.values(CURRENT_PRO_PLANS)
	: [];
export const PRO_PLAN_IDS: PlanIds[] = [
	...ACTIVE_PRO_PLAN_IDS,
	...LEGACY_PRO_PLANS,
];