const STORAGE_PREFIX = 'vctr.user.';

export const userSettingsKeys = {
	ONBOARDING_TEMPLATES: 'onboarding_templates',
	ONBOARDING_TEMPLATES_MOBILE: 'onboarding_templates_mobile',
	ONBOARDING_HELP: 'onboarding_help',
	ONBOARDING_HELP_MOBILE: 'onboarding_help_mobile',
	TEST_EVENT_TRACKING: 'test_event_tracking',
	CLEARBIT_REVEAL_DATA: 'clearbit_reveal_data',
	ABM_PAGES: 'abm_pages',
};

export class LocalStorage {
	static getValueOfItem(item: string): any {
		let value = null;
		if (window.localStorage) {
			value = JSON.parse(window.localStorage.getItem(STORAGE_PREFIX + item));
		}
		return value;
	}

	static setValueOfItem(item: string, value: any) {
		if (window.localStorage) {
			window.localStorage.setItem(STORAGE_PREFIX + item, JSON.stringify(value));
		}
	}

	static removeValueOfItem(item: string) {
		if (!window.localStorage) return;

		window.localStorage.removeItem(STORAGE_PREFIX + item);
	}
}
