import styled from 'styled-components';

// /** use it when any basic text style is needed */
// const $bodyTextNestedTags = `p, span, a, em {
//     ${$bodyTextSize}
//     ${$bodyLineHeight}
// }`;

export const $bodyTextSize = `font-size: 14px;`;
export const $bodyLineHeight = `line-height: 150%;`;

export const $bodyText = `
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.03em;
    -webit-font-smoothing: antialiased;
    strong {
        font-weight: 600;
    }
    ${$bodyTextSize}
    ${$bodyLineHeight}
`;

export const $smallText = `
    font-size: 11px;
    line-height: 130%;
    letter-spacing: 0;
`;

export const BodyText = styled.p`
	${$bodyText}
`;

export const Muted = styled.span`
	color: ${({ theme }) => theme.colors.c_mid_grey};
`;

export const Small = styled.span`
	${$smallText}
`;

export const TermsText = styled(Muted)`
	${$smallText}
	text-align: center;
`;

export const MarketingMixins = {
	$bodyText,
	$smallText,
	$bodyTextSize,
	$bodyLineHeight,
};
export const MarketingComponents = { BodyText, Muted, Small, TermsText };
