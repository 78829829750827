import {
	sendPageEvent,
	sendPageView,
	UserBase,
} from '@vctr-libs/vctr-api-client';
import {
	getSessionId,
	getGID,
	getUuidV4String,
	Signal,
} from '@vctr-libs/vctr-utils';

import { GeoIp, GeoIpData, GeopIpLoggedData } from '../geoIp';
import { StopWatch } from '../stop-watch';
import { StudioEvents } from './events-studio';
import { StudioLiteEvents } from './events-studio-lite';
import { DashboardEventPayload } from './dashboard';
import { DashboardPaddleEventPayload } from './paddle';
import { ViewerEvents } from './events-viewer';
import { ViewerArEvents } from './events-viewer-ar';
import { ViewerArButtonEvents } from './events-viewer-ar-button';
import { WebflowEvents } from './events-webflow';
import { SignupinEvents } from './events-signupin';

const getScreenParams = () => {
	return {
		height:
			window.screen && window.screen.availHeight
				? window.screen.availHeight
				: null,
		width:
			window.screen && window.screen.availWidth
				? window.screen.availWidth
				: null,
		orientation:
			window.screen &&
			window.screen.orientation &&
			window.screen.orientation.type
				? window.screen.orientation.type
				: null,
	};
};

export type LogEvent<T> = [keyof T, T[keyof T]];
export type EventTypes =
	| LogEvent<StudioEvents>
	| LogEvent<StudioLiteEvents>
	| LogEvent<DashboardEventPayload>
	| LogEvent<DashboardPaddleEventPayload>
	| LogEvent<ViewerEvents>
	| LogEvent<ViewerArEvents>
	| LogEvent<ViewerArButtonEvents>
	| LogEvent<WebflowEvents>
	| LogEvent<SignupinEvents>;

export class EventLogger {
	private readonly browser: string;
	private readonly sessionId: string;
	private readonly gID: string;
	private readonly pageSessionId: string;
	private geolocation: GeopIpLoggedData;

	private sigGeoLoaded = new Signal<boolean>();

	private readonly source: string;
	private readonly user: UserBase;
	private workspaceId: string;
	private projectId: string;
	private externalUuid: string;
	private readonly graphic: string;
	private readonly webgl2: boolean;

	private fps: number = null;

	private stopWatch: StopWatch;
	private inPageAndActive = true;

	private readonly cloud: string;

	constructor(
		source: string,
		user: UserBase | null,
		workspaceId?: string | null,
		projectId?: string,
		gpu?: string,
		cloud?: string,
	) {
		this.browser = window.navigator.userAgent;
		this.sessionId = getSessionId();
		this.gID = getGID();
		this.pageSessionId = getUuidV4String();
		this.source = source;
		this.user = user;
		this.workspaceId = workspaceId;
		this.projectId = projectId;
		this.graphic = gpu;

		this.cloud = cloud;

		this.webgl2 = !!document.createElement('canvas').getContext('webgl2');

		this.startFps();

		this.logPageView();

		switch (this.source) {
			case 'studio_4':
			case 'studio_lite_4':
				// init the stopwatch
				const controlTimeTag = 'time_beign_active';
				this.stopWatch = new StopWatch({
					currentPageId: this.source,
					// 2 hours for studio, 30 sec for studio-lite
					idleTimeout:
						this.source === 'studio_4' ? 2 * 60 * 60 * 1000 : 30 * 1000,
				});
				this.stopWatch.startTimer(controlTimeTag);

				// When user returns to the page or stops beign idle
				this.stopWatch.callWhenUserReturns(() => {
					this.inPageAndActive = true;
					this.stopWatch.startTimer(controlTimeTag);
				});
				// When the user leaves the page or starts beign idle
				this.stopWatch.callWhenUserLeaves(() => {
					this.inPageAndActive = false;
					this.stopWatch.stopTimer(controlTimeTag);
					this.logEvent([
						this.source === 'studio_4'
							? 'studio_time_interaction'
							: 'studio_lite_time_interaction',
						{ time: this.stopWatch.getMostRecentTime(controlTimeTag) },
					]);
				});

				// send each minute if user is active in the page
				setInterval(() => {
					if (this.inPageAndActive) {
						this.logEvent([
							this.source === 'studio_4'
								? 'studio_time_tracking'
								: 'studio_lite_time_tracking',
							{ time: this.stopWatch.getTotalTime(controlTimeTag) },
						]);
					}
				}, 60000);

				break;

			default:
				// We need to keep recording events like we used to for the old apps
				['click', 'change', 'submit', 'blur', 'keyup'].forEach(eventType => {
					document.addEventListener(
						eventType,
						ev => this.oldEventHandler(ev),
						true,
					);
				});
				break;
		}
	}

	setExternalUuid(externalUuid: string) {
		this.externalUuid = externalUuid;
	}

	private getSessionInfo(): any {
		return {
			browser: this.browser,
			sid: this.sessionId,
			gid: this.gID,
			page_session_id: this.pageSessionId,
			projectid: this.projectId,
			workspaceid: this.workspaceId,
			externaluuid: this.externalUuid,
			fps: this.fps,
			graphic: this.graphic,
			webgl2: this.webgl2,
		};
	}

	async logEvent(event: EventTypes, source?: string) {
		if (!this.geolocation) {
			// Try to wait for the geolocation to be loaded
			await new Promise<void>(resolve => {
				this.sigGeoLoaded.connect(() => resolve());
			});
		}

		await sendPageEvent(
			source || this.source,
			this.user?.uuid ?? 'unloged',
			event[0],
			{
				info: Object.assign(event[1] ?? {}, {
					geolocation: this.geolocation,
				}),
				...this.getSessionInfo(),
			},
			this.cloud,
		);
	}

	private logPageView() {
		const postGeoIpLoading = (success: boolean) => {
			this.sigGeoLoaded.emit(success);

			const searchParams = new URLSearchParams(window.location.search);
			const source = searchParams.get('source');
			const referrer = source ? decodeURIComponent(source) : document.referrer;

			sendPageView(
				this.source,
				this.user ? this.user.uuid : 'unloged',
				{
					info: {
						screen: getScreenParams(),
						referrer: referrer,
						location: window.location.href,
						geolocation: this.geolocation,
					},
					...this.getSessionInfo(),
				},
				this.cloud,
			);
		};

		new GeoIp()
			.initialize((data: GeoIpData) => {
				this.geolocation = {
					country_code: data?.country_code,
					region: data?.region,
					city: data?.city,
					timezone: data?.timezone,
					accuracy: data?.accuracy,
				};
				postGeoIpLoading(true);
			})
			.catch(_e => {
				console.warn(_e);
				this.geolocation = {
					country_code: null,
					region: null,
					city: null,
					timezone: null,
					accuracy: null,
				};
				postGeoIpLoading(false);
			});
	}

	private startFps(): void {
		const times: number[] = [];

		const countFps = () => {
			const now = performance.now();
			while (times.length > 0 && times[0] <= now - 1000) {
				times.shift();
			}
			times.push(now);
			this.fps = times.length;
			refreshLoop();
		};
		function refreshLoop() {
			window.requestAnimationFrame(countFps);
		}

		refreshLoop();
	}

	// FROM HERE --> To be deprecated once we get rid of all old apps
	private oldLogEvent(event: string, info: any) {
		sendPageEvent(this.source, this.user ? this.user.uuid : 'unloged', event, {
			info,
			...this.getSessionInfo(),
		});
	}
	private oldEventHandler(ev: Event | MouseEvent) {
		let node = ev.target as Element;
		while (node && node.attributes) {
			const eventType = node.attributes.getNamedItem('vctr-log-event');
			if (eventType && eventType.value === ev.type) {
				const eventName = node.attributes.getNamedItem('vctr-event-name');
				const eventAttributes =
					node.attributes.getNamedItem('vctr-event-attrs');
				const attributes: { [key: string]: string } = {};
				if (eventAttributes && eventAttributes.value) {
					const attrs = eventAttributes.value.split(',');
					attrs.forEach(attr => {
						const attrName = attr.trim();
						// @ts-ignore
						attributes[attr] = node[attrName]
							? (node as any)[attrName]
							: node.attributes.getNamedItem(attrName)
							? String(node.attributes.getNamedItem(attrName).value)
							: '';
					});
				}
				console.info(ev);
				this.oldLogEvent(eventName.value, { attributes });
				break;
			}
			node = node.parentElement;
		}
	}

	public set({
		workspaceId,
		projectId,
	}: {
		workspaceId?: string;
		projectId?: string;
	}) {
		this.workspaceId = workspaceId;
		this.projectId = projectId;
	}
}
