import { SDK } from '@vctr-api/vctr-resources';
import { Cloud, Env, createCloudUrl } from '@vctr-libs/vctr-utils';

let isRelativeUrlWorking: boolean = null;

/**
 * Returns the URL for the Resources API.
 *
 * @param cloud
 * @returns
 */
const getResourcesApiUrl = async (cloud?: Cloud) => {
	let url: string;

	if (cloud !== undefined) {
		url = createCloudUrl(cloud);
		if (url.indexOf('amazonaws.com') >= 0) {
			// It is an internal URL
			url += '/graphql'; // /api/vctr-resources/v1 is only required for the Gateway
		} else {
			url += '/api/vctr-resources/v1/graphql';
		}
	} else {
		url = '/api/vctr-resources/v1/graphql';
		if (
			typeof window !== 'undefined' &&
			((window && window?.location?.hostname === 'localhost') ||
				window?.location?.hostname?.indexOf('vectary.com') !== -1)
		) {
			// We don't need to do anything here; just skip the further else block.
		} else {
			if (isRelativeUrlWorking === null) {
				// First we need to check if relative URL is working.
				try {
					const result = await fetch(url, {
						headers: {
							'content-type': 'application/json',
						},
						body: '{"query":"{\\n  user {\\n    id\\n  }\\n}"}',
						method: 'POST',
						mode: 'cors',
						credentials: 'include',
					});

					if (result.status === 404) {
						throw new Error();
					}

					isRelativeUrlWorking = true;
				} catch (error) {
					isRelativeUrlWorking = false;
				}
			}
			// Don't append /api/vctr-resources/v1 to foreign URLs, use vectary prod URL instead.
			url = `${
				isRelativeUrlWorking ? '' : createCloudUrl(Env.Prod)
			}/api/vctr-resources/v1/graphql`;
		}
	}

	return url.replace(/:7\d\d\d/g, ':7200');
};

type GetSDKArgs = {
	cloud?: Cloud;
	extraHeaders?: HeadersInit;
	forceSync?: boolean;
};

/**
 * Returns the SDK for the Resources API.
 *
 * @param args
 * @returns
 */
export const getSDK = async (args: GetSDKArgs) => {
	const { cloud, extraHeaders, forceSync } = args;
	const headers = {} as Record<string, any>;
	const url = await getResourcesApiUrl(cloud);

	if (forceSync === true) {
		headers['x-request-force-sync'] = true;
	}

	return SDK(url, {
		headers: { ...headers, ...extraHeaders },
	});
};
