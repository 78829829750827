import React, { FC, ReactNode } from 'react';
import { GlobalStyler, GlobalStylerFix } from '../../styles';
import { DashboardUkoThemeProvider } from '../themeProviders/DashboardUkoThemeProvider';

/**
 * - Reproduces dashboard styles in Storybook
 * - Applies theme
 * - Takes care of template code for stories
 * */
export const DashboardUkoTemplate: FC = ({
	children,
}: {
	children: ReactNode;
}) => {
	return (
		<>
			{/* `GlobalStyler` is a "legacy 4.0" dashboard styler to have the same root styles as in dashboard */}
			<GlobalStyler>
				{/* `GlobalStylerFix` resets tricky legacy styles, so we can use MUI and others easier */}
				<GlobalStylerFix>
					{/* ThemeProvider to apply styled-components theme */}
					<DashboardUkoThemeProvider>{children}</DashboardUkoThemeProvider>
				</GlobalStylerFix>
			</GlobalStyler>
		</>
	);
};
