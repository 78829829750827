import React, { FC, ComponentProps } from 'react';
import { Alert, Chip, Popover } from '@mui/material';
import {
	EmailInvitation,
	EmailInvitationWithPossibleError,
} from '@vctr-libs/vctr-api-client';

export const EmailInvitationStatusBadge: FC<{
	invitation: EmailInvitation | EmailInvitationWithPossibleError;
}> = props => {
	const { invitation } = props;
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	const handleStatusPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleStatusPopoverClose = () => {
		setAnchorEl(null);
	};

	const isStatusPopoverOpen = Boolean(anchorEl);

	const alertColorByInvitationStatus: {
		[status: string]: ComponentProps<typeof Chip>['color'];
	} = {
		active: 'secondary',
		expired: 'warning',
	};

	const badgeTextByInvitationStatus: {
		[status: string]: string;
	} = {
		active: 'Pending',
		expired: 'Expired',
	};

	const badgeVariantByInvitationStatus: {
		[status: string]: ComponentProps<typeof Chip>['variant'];
	} = {
		active: 'filled',
		expired: 'filled',
	};

	if ((invitation as EmailInvitationWithPossibleError).error) {
		return (
			<>
				<Chip
					variant="outlined"
					color="error"
					label="Not sent"
					onMouseEnter={handleStatusPopoverOpen}
					onMouseLeave={handleStatusPopoverClose}
					style={{ cursor: 'pointer' }}
				/>
				<Popover
					id="mouse-over-popover"
					sx={{
						pointerEvents: 'none',
					}}
					open={isStatusPopoverOpen}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					onClose={handleStatusPopoverClose}
					disableRestoreFocus
				>
					<Alert severity="error" color="error" icon={false}>
						{(invitation as EmailInvitationWithPossibleError).error.message}
					</Alert>
				</Popover>
			</>
		);
	} else {
		return (
			<Chip
				variant={badgeVariantByInvitationStatus[invitation.status]}
				color={alertColorByInvitationStatus[invitation.status]}
				label={badgeTextByInvitationStatus[invitation.status]}
			/>
		);
	}
};
