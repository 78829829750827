import { Signal } from "./signal";

/**
 * Handles shortcuts as follws:
 *
 * 1)       If an event would be dispatched, it is automatically preventeded
 * 2)       If the event target is a form element (e.g. input),
 *            - it is not dispatched nor prevented
 *            - if setDispatchForIgnoredElements(true), it is dispatched but not prevented
 * 3)       Does not dispatch already prevented events.
 * 4)       Does not stop propagation. (Consumer can still stop it)
 */

export enum KeyEventType {
	DOWN,
	UP,
	PRESS
}

type KeyDefinition = string | string[];

/**
 * ctrl/shift/alt can be defined as null: ignore, true/false: check it is (not) pressed
 */
export interface ShortcutDefinition {
	name: string;
	type?: KeyEventType;
	key?: KeyDefinition;
	ctrl?: boolean | null;
	alt?: boolean | null;
	shift?: boolean | null;
	repeat?: boolean | null;
	other?: string | null;
	toolIcon?: string | null;
	toolLabel?: string | null;
	keyLabel?: string | null;
}

const DEFAULT_DEFINITION_OPTIONS: { [key: string]: any } = {
	type: KeyEventType.DOWN,
	key: null,
	ctrl: false,
	alt: false,
	shift: false,
	repeat: false
};

// UNUSED
// function getStringFromShortcutDefinition(
// 	shortcutDefinition: ShortcutDefinition
// ): string {
// 	let result: string = shortcutDefinition.ctrl ? "ctrl + " : "";
// 	result += shortcutDefinition.alt ? "alt + " : "";
// 	result += shortcutDefinition.shift ? "shift + " : "";
// 	if (typeof shortcutDefinition.key === "string") {
// 		result += shortcutDefinition.key;
// 	} else if (shortcutDefinition.key) {
// 		shortcutDefinition.key.forEach((keyElement: KeyDefinition, i: number) => {
// 			if (i > 0) {
// 				result += "/";
// 			}
// 			result += keyElement;
// 		});
// 	}
// 	return result;
// }

// Make sure these are lowercase.
// element.tagName can return both lowercase and uppercase strings (depending
// on XHTML vs. HTML), so we lowercase the output.
export const IGNORED_ELEMS = ["input", "textarea"];

// for delete key
const IGNORED_IDS = ["palette-pointer"];

const WHITE_LIST_FOR_IGNORED_ELEMS = ["c", "v", "x", "a", "r"];

export class Shortcut {
	readonly definition: ShortcutDefinition;
	readonly sigEvent = new Signal<KeyboardEvent>();

	constructor(definition: ShortcutDefinition) {
		if (!definition) {
			throw new Error("Shortcut definition does not exist.");
		}
		this.definition = Object.assign({}, DEFAULT_DEFINITION_OPTIONS, definition);
	}

	dispatch(
		e: KeyboardEvent,
		dispatchEventsForIgnoredElements: boolean
	): boolean {
		let dispatched = false;
		if (this._shouldHandleEvent(e)) {
			// Rules 1) & 2)
			let willDispatch = true;
			if ((e.target as Element).tagName) {
				const tagName = (e.target as Element).tagName.toLowerCase();
				const id = (e.target as Element).id;
				const ignoredElement =
					IGNORED_ELEMS.indexOf(tagName) !== -1 ||
					(IGNORED_IDS.indexOf(id) !== -1 && e.key === "Delete");
				willDispatch = !ignoredElement;
				// in ignored elements, prevent default browser actions
				// for existing shortcut (e.g. Ctrl + O) except for ones on whitelist
				if (
					ignoredElement &&
					e.ctrlKey === true &&
					WHITE_LIST_FOR_IGNORED_ELEMS.indexOf(e.key) === -1
				) {
					e.preventDefault();
				}
			}

			if (willDispatch) {
				e.preventDefault();
				this.sigEvent.emit(e);
				dispatched = true;
			} else if (dispatchEventsForIgnoredElements) {
				this.sigEvent.emit(e);
				dispatched = true;
			}
		}
		return dispatched;
	}

	onSigEvent(callback: (e: KeyboardEvent) => void): this {
		this.sigEvent.connect(callback);
		return this;
	}

	private _shouldHandleEvent(e: KeyboardEvent): boolean {
		const def = this.definition;
		if (e.type !== keyEventTypeToString(def.type)) {
			return false;
		}

		// Fallback to nonstandard event for chrome < 51 and safari.

		if (def.key !== null) {
			const key = eventToKey(e);
			// Don't handle keys you cannot map
			if (key === null) {
				return false;
			}

			if (typeof def.key === "string") {
				if (key.toLowerCase() !== def.key.toLowerCase()) {
					return false;
				}
			} else {
				// TODO: precompute...
				const keys = def.key.map(k => k.toLowerCase());
				if (keys.indexOf(key.toLowerCase())) {
					return false;
				}
			}
		}

		// CTRL == META
		const ctrl = e.ctrlKey || e.metaKey;
		if (def.ctrl !== null && ctrl !== def.ctrl) {
			return false;
		}

		if (def.alt !== null && e.altKey !== def.alt) {
			return false;
		}
		if (def.shift !== null && e.shiftKey !== def.shift) {
			return false;
		}

		// by default, do not trigger repeat events
		if (e.repeat === true && (def.repeat === null || def.repeat === false)) {
			return false;
		}

		return true;
	}
}

export class ShortcutManager {
	private _document: Document;

	private _shiftPressed: boolean = false;
	private _altPressed: boolean = false;
	private _ctrlPressed: boolean = false;

	private _activeGroup: Shortcut[];
	private _defaultGroup: Shortcut[];
	private _dispatchForIgnoredElements = false;

	readonly sigShortcut = new Signal<Shortcut>();
	readonly sigPressedKeyChanged = new Signal<{
		key: "ctrl" | "alt" | "shift";
		pressed: boolean;
	}>();

	private _onKey = (e: KeyboardEvent) => {
		// Rule 3)
		if (!e.defaultPrevented) {
			this._activeGroup.forEach(shortcut => {
				if (shortcut.dispatch(e, this._dispatchForIgnoredElements)) {
					this.sigShortcut.emit(shortcut);
				}
			});
		}

		if (this._ctrlPressed !== e.ctrlKey) {
			this._ctrlPressed = e.ctrlKey;
			this.sigPressedKeyChanged.emit({ key: "ctrl", pressed: e.ctrlKey });
		}

		if (this._shiftPressed !== e.shiftKey) {
			this._shiftPressed = e.shiftKey;
			this.sigPressedKeyChanged.emit({ key: "shift", pressed: e.shiftKey });
		}

		if (this._altPressed !== e.altKey) {
			e.preventDefault(); // after "alt up" -> a browser functionality blocks each key event (chrome)
			this._altPressed = e.altKey;
			this.sigPressedKeyChanged.emit({ key: "alt", pressed: e.altKey });
		}

		return false;
	};

	private _onWindowBlur = () => {
		if (this._ctrlPressed) {
			this._ctrlPressed = false;
			this.sigPressedKeyChanged.emit({
				key: "ctrl",
				pressed: this._ctrlPressed
			});
		}

		if (this._shiftPressed) {
			this._shiftPressed = false;
			this.sigPressedKeyChanged.emit({
				key: "shift",
				pressed: this._shiftPressed
			});
		}

		if (this._altPressed) {
			this._altPressed = false;
			this.sigPressedKeyChanged.emit({ key: "alt", pressed: this._altPressed });
		}
	};

	constructor(doc: Document) {
		this._document = doc;

		this._defaultGroup = [];
		this._activeGroup = this._defaultGroup;

		// this.setActive();
	}

	setActive(value: boolean = true): this {
		if (value) {
			this._document.addEventListener("keydown", this._onKey);
			this._document.addEventListener("keyup", this._onKey);
			this._document.addEventListener("keypress", this._onKey);
			window.addEventListener("blur", this._onWindowBlur);
		} else {
			this._document.removeEventListener("keydown", this._onKey);
			this._document.removeEventListener("keyup", this._onKey);
			this._document.removeEventListener("keypress", this._onKey);
			window.removeEventListener("blur", this._onWindowBlur);
		}
		return this;
	}

	mergeGroup(localGroup: Shortcut[]): this {
		const localKeys = localGroup.map(shortcut => shortcut.definition.key);
		const allowedActive = this._activeGroup.filter(
			shortcut => localKeys.indexOf(shortcut.definition.key) === -1
		);
		const allowedShortcuts = localGroup.concat(allowedActive);
		this.setGroup(allowedShortcuts);
		return this;
	}

	setGroup(group: Shortcut[]): this {
		this._activeGroup = group;
		return this;
	}

	getGroup(): Shortcut[] {
		return this._activeGroup;
	}

	setDefaultGroup(group: Shortcut[]): this {
		this._defaultGroup = group;
		return this;
	}

	getDefaultGroup(): Shortcut[] {
		return this._defaultGroup;
	}

	resetToDefaultGroup(): this {
		this._activeGroup = this._defaultGroup;
		return this;
	}

	getDispatchForIgnoredElements(): boolean {
		return this._dispatchForIgnoredElements;
	}

	setDispatchForIgnoredElements(value: boolean): this {
		this._dispatchForIgnoredElements = value;
		return this;
	}

	onSigShortcut(callback: (s: Shortcut) => void): this {
		this.sigShortcut.connect(callback);
		return this;
	}

	isShiftPressed(): boolean {
		return this._shiftPressed;
	}

	isAltPressed(): boolean {
		return this._altPressed;
	}

	isCtrlPressed(): boolean {
		return this._ctrlPressed;
	}
}

// The string event type in KeyboardEvent from dom.d.ts
// Unfortunately this not in dom.d.ts
type KeyEventTypeString = "keyup" | "keydown" | "keypress";

function keyEventTypeToString(type: KeyEventType): KeyEventTypeString {
	switch (type) {
		case KeyEventType.DOWN:
			return "keydown";
		case KeyEventType.UP:
			return "keyup";
		case KeyEventType.PRESS:
			return "keypress";
	}
}

export function eventToKey(e: KeyboardEvent): string | null {
	if (e.key) {
		return normalizeKey(e.key);
	}
	const key = keyCodeToKey(e.keyCode as keyof typeof keyCodeToKeyMap);
	return key === null ? null : normalizeKey(key);
}

function keyCodeToKey(keyCode: keyof typeof keyCodeToKeyMap): string | null {
	if (keyCode in keyCodeToKeyMap) {
		return keyCodeToKeyMap[keyCode];
	}
	return null;
}

/**
 * Taken from keypress library, than modified to be compatible with e.key.
 * https://github.com/dmauro/Keypress/blob/development/keypress.coffee#L802
 */
// TODO: exported only because of some hack elsewhere... should not export
export const keyCodeToKeyMap = {
	0: "\\", // Firefox reports this keyCode when shift is held
	8: "Backspace",
	9: "Tab",
	12: "NumLock", // ???
	13: "Enter",
	16: "Shift",
	17: "Control",
	18: "Alt",
	19: "Pause",
	20: "CapsLock",
	27: "Escape",
	32: " ",
	33: "PageUp",
	34: "PageDown",
	35: "End",
	36: "Home",
	37: "ArrowLeft",
	38: "ArrowUp",
	39: "ArrowRight",
	40: "ArrowDown",
	44: "Print",
	45: "Insert",
	46: "Delete",
	48: "0",
	49: "1",
	50: "2",
	51: "3",
	52: "4",
	53: "5",
	54: "6",
	55: "7",
	56: "8",
	57: "9",
	65: "a",
	66: "b",
	67: "c",
	68: "d",
	69: "e",
	70: "f",
	71: "g",
	72: "h",
	73: "i",
	74: "j",
	75: "k",
	76: "l",
	77: "m",
	78: "n",
	79: "o",
	80: "p",
	81: "q",
	82: "r",
	83: "s",
	84: "t",
	85: "u",
	86: "v",
	87: "w",
	88: "x",
	89: "y",
	90: "z",
	91: "Meta",
	92: "Meta",
	93: "Meta",
	96: "0",
	97: "1",
	98: "2",
	99: "3",
	100: "4",
	101: "5",
	102: "6",
	103: "7",
	104: "8",
	105: "9",
	106: "*",
	107: "+",
	108: "Enter",
	109: "-",
	110: ",",
	111: "/",
	112: "f1",
	113: "f2",
	114: "f3",
	115: "f4",
	116: "f5",
	117: "f6",
	118: "f7",
	119: "f8",
	120: "f9",
	121: "f10",
	122: "f11",
	123: "f12",
	// 124: "print",
	// 144: "num",
	// 145: "scroll",
	186: ";",
	187: "=",
	188: ",",
	189: "-",
	190: ".",
	191: "/",
	192: "@",
	219: "[",
	220: "\\",
	221: "]",
	222: "'",
	223: "`",
	224: "Meta",
	225: "Alt",
	// Opera weirdness
	57392: "Control",
	63289: "Num",
	// Firefox weirdness
	59: ";",
	61: "=",
	173: "-"
};

export enum KEY {
	DoubleBackslash = "\\",
	Backspace = "Backspace",
	Tab = "Tab",
	NumLock = "NumLock",
	Enter = "Enter",
	Shift = "Shift",
	Control = "Control",
	Alt = "Alt",
	Pause = "Pause",
	CapsLock = "CapsLock",
	Escape = "Escape",
	EmptySpace = "",
	PageUp = "PageUp",
	PageDown = "PageDown",
	End = "End",
	Home = "Home",
	ArrowLeft = "ArrowLeft",
	ArrowUp = "ArrowUp",
	ArrowRight = "ArrowRight",
	ArrowDown = "ArrowDown",
	Print = "Print",
	Insert = "Insert",
	Delete = "Delete",
	Zero = "0",
	One = "1",
	Two = "2",
	Three = "3",
	Four = "4",
	Five = "5",
	Six = "6",
	Seven = "7",
	Eight = "8",
	Nine = "9",
	Semicolon = ";",
	Equals = "=",
	A = "a",
	B = "b",
	C = "c",
	D = "d",
	E = "e",
	F = "f",
	G = "g",
	H = "h",
	I = "i",
	J = "j",
	K = "k",
	L = "l",
	M = "m",
	N = "n",
	O = "o",
	P = "p",
	Q = "q",
	R = "r",
	S = "s",
	T = "t",
	U = "u",
	V = "v",
	W = "w",
	X = "x",
	Y = "y",
	Z = "z",
	Meta = "Meta",
	Multiply = "*",
	Plus = "+",
	Minus = "-",
	Divide = "/",
	Comma = ",",
	Dot = ".",
	F1 = "f1",
	F2 = "f2",
	F3 = "f3",
	F4 = "f4",
	F5 = "f5",
	F6 = "f6",
	F7 = "f7",
	F8 = "f8",
	F9 = "f9",
	F10 = "f10",
	F11 = "f11",
	F12 = "f12",
	Backslash = "/",
	Backtick = "`",
	OpenBracket = "[",
	ClosingBracket = "]",
	SingleQuote = "'",
	OpenCurlyBracket = "{",
	ClosingCurlyBracket = "}",
	Num = "Num"
}

function normalizeKey(key: string): string {
	if (key in normalizeKeyMap) {
		return normalizeKeyMap[key];
	}
	return key;
}

const normalizeKeyMap: { [key: string]: string } = {
	Esc: "Escape",
	Del: "Delete",
	Left: "ArrowLeft",
	Right: "ArrowRight",
	Up: "ArrowUp",
	Down: "ArrowDown",
	Add: "+",
	Subtract: "-",
	Multiply: "*",
	Divide: "/"
};
