import React, { FC } from 'react';

import { InputUpload, DropdownItem, Label } from './styledComponents';

type Props = {
	onUpload?: (files: FileList) => void;
	onCloseDropdown: () => void;
	title?: string;
};

export const UploadImageMenuItem: FC<Props> = ({
	onUpload,
	onCloseDropdown,
	title = 'Upload Cover',
}) => {
	return (
		<DropdownItem className="dropdown__Item">
			{title}
			<Label htmlFor="file2">
				<InputUpload
					title=""
					type="file"
					id="file2"
					accept="image.*"
					multiple={false}
					autocomplete="off"
					onChange={event => {
						if (onUpload) {
							onUpload(event.target.files as FileList);
						}

						onCloseDropdown();
					}}
				/>
			</Label>
		</DropdownItem>
	);
};
