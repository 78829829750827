import { isEmailValid } from '@vctr-libs/vctr-utils';
import {
	EmailInvitation,
	EmailInvitationWithPossibleError,
	WorkspaceUserSeat,
} from '@vctr-libs/vctr-api-client';

import {
	NO_EMAILS_MESSAGE,
	INCORRECT_EMAILS_MESSAGE,
	EMAIL_IN_USE_MESSAGE,
} from './AddUserForm';

export type EmailInputError = Record<'message' | 'value', string>;

export function validateEmails({
	emails,
	emailInvitations,
	users,
	onError,
}: {
	emails: string[];
	emailInvitations: EmailInvitation[] | EmailInvitationWithPossibleError[];
	users: WorkspaceUserSeat[];
	onError: (errors: EmailInputError[]) => void;
}): boolean {
	if (!emailInvitations) {
		console.log('No existing email invitations provided for validation');
	}

	if (!users) {
		console.log('No existing users provided for validation');
	}

	if (!emails || emails.length === 0) {
		onError([
			{
				message: NO_EMAILS_MESSAGE,
				value: null,
			},
		]);
		return false;
	}

	const invalidEmails = emails.filter(val => !isEmailValid(val));
	const emailsInUse = emails.filter(val =>
		isEmailInUse({
			email: val,
			users,
			emailInvitations,
		}),
	);

	const _errors: Array<EmailInputError> = new Array();

	if (invalidEmails.length > 0 || emailsInUse.length > 0) {
		if (invalidEmails.length > 0) {
			_errors.push({
				message: INCORRECT_EMAILS_MESSAGE,
				value: invalidEmails.join(', '),
			});
		}

		if (emailsInUse.length > 0) {
			_errors.push({
				message: EMAIL_IN_USE_MESSAGE,
				value: emailsInUse.join(', '),
			});
		}
		onError(_errors);
		return false;
	} else {
		onError([]);
		return true;
	}
}

export function isEmailInUse({
	email,
	emailInvitations,
	users,
}: {
	email: string;
	emailInvitations: EmailInvitation[] | EmailInvitationWithPossibleError[];
	users: WorkspaceUserSeat[];
}): boolean {
	return (
		emailInvitations?.some(inv => inv.email === email) ||
		users?.some(inv => inv.email === email)
	);
}
