import { Plan } from "../../plan/interface";
import { PlanIds } from "../../plan/plans";

export const getReadablePlanName = (plan: Plan): string => {
	switch (plan.id) {
		case PlanIds.PRO:
		case PlanIds.PRO_2023_06_MONTHLY_19:
		case PlanIds.PRO_2023_06_MONTHLY_19_TRIAL:
		case PlanIds.PRO_2024_06_MONTHLY_29_TRIAL:
			return 'Pro Monthly';
		case PlanIds.PRO_180_YEARLY:
		case PlanIds.PRO_2023_06_YEARLY_180:
		case PlanIds.PRO_2023_06_YEARLY_180_TRIAL:
		case PlanIds.PRO_2024_06_YEARLY_288_TRIAL:
			return 'Pro Annually';
		case PlanIds.BUSINESS:
		case PlanIds.ENTERPRISE:
		case PlanIds.CUSTOM:
			return 'Business';
		default:
			const name = plan.name;
			return name.toLowerCase().includes('plan') ? `${name} plan` : name;
	}
};

/** for Workspace */
export const getReadablePlanNameForWorkspace = (plan: Plan): string => {
	switch (plan.id) {
		case PlanIds.STARTER_10:
		case PlanIds.STARTER_25:
			return 'Starter';
		case PlanIds.PRO:
		case PlanIds.PRO_2023_06_MONTHLY_19:
		case PlanIds.PRO_2023_06_MONTHLY_19_TRIAL:
		case PlanIds.PRO_180_YEARLY:
		case PlanIds.PRO_2023_06_YEARLY_180:
		case PlanIds.PRO_2023_06_YEARLY_180_TRIAL:
		case PlanIds.PRO_2024_06_MONTHLY_29_TRIAL:
		case PlanIds.PRO_2024_06_YEARLY_288_TRIAL:
			return 'Pro';
		case PlanIds.BUSINESS:
			return 'Business';
		default:
			return plan.name;
	}
};

/** short name for Billing switch */
export const getReadablePlanPeriodName = (plan: Plan): string => {
	switch (plan.id) {
		case PlanIds.PRO:
		case PlanIds.PRO_2023_06_MONTHLY_19:
		case PlanIds.PRO_2023_06_MONTHLY_19_TRIAL:
		case PlanIds.PRO_2024_06_MONTHLY_29:
		case PlanIds.PRO_2024_06_MONTHLY_29_TRIAL:
			return 'Monthly';
		case PlanIds.PRO_180_YEARLY:
		case PlanIds.PRO_2023_06_YEARLY_180:
		case PlanIds.PRO_2023_06_YEARLY_180_TRIAL:
		case PlanIds.PRO_2024_06_YEARLY_288:
		case PlanIds.PRO_2024_06_YEARLY_288_TRIAL:
			return 'Annually';
		default:
			return plan.name;
	}
};