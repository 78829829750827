import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

export function initSentry() {
    if (!["localhost", "webflow.io"].some(host => host === window.location.hostname)) {
        Sentry.init({
            dsn: "https://4016835e41124f85adcc39a8840e4275@sentry.vectary.com/8",
            integrations: [new BrowserTracing()],
            // TODO check ENV var on deploy
            environment: process.env.ENV,
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            sampleRate: 1.0,
            tracesSampleRate: 1.0,
        });
    }
}
