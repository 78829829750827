import styled from 'styled-components';
import { hexToRGB } from '../../../utils/hexToRGB';
import {
	DropdownEnumPositionX,
	DropdownEnumPositionY,
} from '../utils/position';
import { DropdownStyleVariant } from '../Dropdown';

const $disabledStyles = `
	&, &:hover {
		pointer-events: none;
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

export const Wrapper = styled.div`
	position: relative;
`;

export const Label = styled.label`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
`;

export const ButtonDropdown = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border-radius: ${({ theme }) => theme.borderRadius[0]};
	border: 0;
	padding: 4px;

	&:hover {
		background-color: ${({ theme }) => theme.colors.c_light_grey};
	}
`;

export const InputUpload = styled.input<{ autocomplete: string }>`
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const ItemChildrenMenu = styled.div<{
	positionX: DropdownEnumPositionX;
	positionY: DropdownEnumPositionY;
}>`
	position: absolute;
	padding: 0 8px;

	${({ positionX }) => {
		if (positionX === DropdownEnumPositionX.LEFT) {
			return 'left: 100%;';
		} else if (positionX === DropdownEnumPositionX.RIGHT) {
			return 'left: 0; margin-left: -20px;';
		}
	}}
	${({ positionY }) => {
		if (positionY === DropdownEnumPositionY.TOP) {
			return 'top: 0;';
		} else if (positionY === DropdownEnumPositionY.BOTTOM) {
			return 'bottom: 0; margin-top: -20px;';
		}
	}};
`;

const $dropdownItemWithIcons = `
	display: flex;
	gap: 4px;
	width: 100%;
`;

export const LinkWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Link = styled.a`
	${$dropdownItemWithIcons}
	font-weight: ${({ theme }) => theme.fontWeights.font_weight_xsmall};
	color: ${({ theme }) => theme.colors.c_almost_black};
	cursor: pointer;
	flex: 1 0;
`;

export const DropdownItem = styled.li<{ color?: string; disabled?: boolean }>`
	position: relative;
	padding: 6px 8px;
	line-height: 12px;
	white-space: nowrap;
	transition: all 0.5s;
	cursor: pointer;
	color: ${({ color, theme }) => (color ? color : theme.colors.c_almost_black)};
	&:hover {
		background-color: ${({ theme }) => theme.colors.c_light_grey};

		${Link} {
			color: ${({ theme }) => theme.colors.c_almost_black};
		}
	}
	${({ disabled }) => disabled && $disabledStyles}}
`;

export const DropdownItemDisabledWrapper = styled.div<{ disabled?: boolean }>`
	${({ disabled }) => disabled && $disabledStyles}}
`;

const $largeDropdownItem = `
	display: flex;
	align-items: center;
	min-height: 32px;
`;

export const DropdownItemsList = styled.ul<{ variant?: DropdownStyleVariant }>`
	background: ${({ theme }) => theme.colors.c_white};
	box-shadow: 0px 0px 20px
		${({ theme }) => hexToRGB(theme.colors.c_almost_black, 0.25)};
	position: absolute;
	border-radius: ${({ theme }) => theme.borderRadius[0]};
	padding: 4px 0;
	font-size: ${({ theme }) => theme.fontSize.font_size_small};
	margin: 0;
	list-style: none;
	z-index: ${({ theme }) => theme.zIndex[3]};
	${DropdownItem} {
		${({ variant }) => (variant === 'menu' ? $largeDropdownItem : '')}
		min-width: ${({ variant }) => (variant === 'menu' ? '160px' : '81px')};
	}
`;

export const DropdownItemWithIcons = styled(DropdownItem)`
	${$dropdownItemWithIcons}
`;

export const DropdownItemWithIconsTitle = styled.div`
	flex: 1 0;
`;

export const DropdownItemIcon = styled.div<{ src: string }>`
	flex: 0 0 24px;
	justify-self: flex-end;
	width: 24px;
	height: 24px;
	margin: -6px 0;
	background-image: url(${({ src }) => src});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 16px 16px;
`;
