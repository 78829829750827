import styled from 'styled-components';

export const MemberImage = styled.img`
	width: 24px;
	height: 24px;
	border-radius: 100%;
	background-color: ${({ theme }) => theme.colors.c_light_grey};
	overflow: hidden;
	flex: 1 0 24px;
`;

export const MemberName = styled.div`
	color: ${({ theme }) => theme.colors.c_almost_black};
	font-weight: ${({ theme }) => theme.fontWeights.font_weight_small};
	line-height: normal;
	padding-left: 8px;
	max-width: 122px;
	padding-right: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const MemberLeftContent = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.c_mid_grey};
	font-size: ${({ theme }) => theme.fontSize.font_size_small};
	padding-right: 10px;
`;

export const MemberInvitationLeftContent = styled(MemberLeftContent)`
	padding-right: 0;
`;
