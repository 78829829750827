import React, {
	ChangeEvent,
	FocusEvent,
	KeyboardEvent,
	FC,
	Ref,
	forwardRef,
	Fragment,
} from 'react';

import { Wrapper, Hint } from './styledComponents';

export enum InputEnumType {
	TEXT = 'text',
	NUMBER = 'number',
	EMAIL = 'email',
	PASSWORD = 'password',
}

export interface InputProps {
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	isValid?: boolean;
	name?: string;
	errorHint?: string;
	maxLength?: number;
	type: InputEnumType;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
	className?: string;
	ref?: Ref<HTMLInputElement>;
	readOnly?: boolean;
	autoFocus?: boolean;
}

export const _Input: FC<InputProps> = forwardRef((props, ref) => {
	const {
		value,
		placeholder,
		disabled,
		isValid = true,
		name,
		type,
		className,
		maxLength,
		onChange,
		onBlur,
		onKeyUp,
		onFocus,
		errorHint,
		onKeyDown,
		readOnly = false,
		autoFocus = false,
	} = props;

	const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
		if (onBlur) {
			onBlur(event);
		}
	};

	const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
		if (onFocus) {
			onFocus(event);
		}
	};

	return (
		<Fragment>
			<Wrapper
				onFocus={handleOnFocus}
				onChange={onChange}
				onBlur={handleOnBlur}
				onKeyUp={onKeyUp}
				onKeyDown={onKeyDown}
				ref={ref}
				type={type}
				maxLength={maxLength}
				isValid={isValid}
				value={value}
				name={name}
				placeholder={placeholder}
				className={className}
				disabled={disabled}
				readOnly={readOnly}
				autoFocus={autoFocus}
			/>
			{!isValid && errorHint && <Hint>{errorHint}</Hint>}
		</Fragment>
	);
});

_Input.displayName = 'Input';
