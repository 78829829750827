
export const handleProductHuntLink = () => {
    const current = new Date();
    const productHuntStart = new Date("December 13, 2022 09:01:00");
    const productHuntEnd = new Date("December 14, 2022 09:01:00");

    if (current > productHuntStart && current < productHuntEnd) {
        const container = document.querySelector(`[js="ui__badge__container"]`);
        const button = `<a href="https://www.producthunt.com/posts/vectary-interactions-animations?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-vectary&#0045;interactions&#0045;animations" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=370818&theme=dark" alt="Vectary&#0032;Interactions&#0032;&#0043;&#0032;Animations - No&#0045;code&#0044;&#0032;high&#0045;quality&#0044;&#0032;real&#0045;time&#0032;3D&#0032;experiences&#0032;for&#0032;the&#0032;web | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>`;

        (container as HTMLElement).style.setProperty("display", "block");
        container.innerHTML = button;
    }



}

