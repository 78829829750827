import styled from 'styled-components';

// TODO
// 1 — review studio styles to eliminate hacks like this
// 2 — think of better reset for components (styled reset breaks our styles)

export const StudioReset = styled.div`
	p,
	span {
		white-space: normal;
	}
`;
