import { ThemeOptions, createTheme } from '@mui/material';
import { Prettify } from '@vctr-libs/vctr-utils';
import { colors } from '../../styles';

const PRIMARY = colors.c_light_mode_primary;
const ALMOST_BLACK_8 = colors.c_almost_black;
const MID_GREY_5 = colors.c_mid_grey;

type VeThemeOptions = Prettify<ThemeOptions>;

export function getSignupinTheme(htmlFontSize = 16) {
	// TODO fix this calculation — it makes font smaller when increasing htmlFontSize
	const blackMagicFontSize = `${(16 / htmlFontSize) * 2}rem`;

	const themeOptions: VeThemeOptions = {
		components: {
			MuiInputBase: {
				styleOverrides: {
					root: {
						'border': '1px solid #EFEFEF',
						'borderRadius': '8px',
						'&:hover': {
							'&.Mui-focused': {
								borderColor: PRIMARY,
							},
						},
						'&.Mui-focused': {
							borderColor: PRIMARY,
						},
					},
				},
			},
			MuiSelect: {
				styleOverrides: {
					select: {
						'&:focus': {
							backgroundColor: 'transparent',
						},
					},
				},
			},
		},

		typography: {
			fontFamily: 'Inter, Helvetica, Arial, sans-serif',
			htmlFontSize: htmlFontSize,

			h1: { fontFamily: 'Fira Sans', color: ALMOST_BLACK_8 },
			h2: { fontFamily: 'Fira Sans', color: ALMOST_BLACK_8 },
			h3: { fontFamily: 'Fira Sans', color: ALMOST_BLACK_8 },
			h4: {
				fontFamily: 'Fira Sans',
				color: ALMOST_BLACK_8,
				fontWeight: 600,
				fontSize: blackMagicFontSize,
			},
			h5: { fontFamily: 'Fira Sans', color: ALMOST_BLACK_8 },
			h6: { fontFamily: 'Fira Sans', color: ALMOST_BLACK_8 },
		},

		palette: {
			primary: {
				main: PRIMARY,
			},
			secondary: {
				main: ALMOST_BLACK_8,
			},
			text: {
				primary: ALMOST_BLACK_8,
				secondary: MID_GREY_5,
			},
		},

		shape: {
			borderRadius: 8,
		},
	};

	return createTheme(themeOptions);
}
