import fetch from 'cross-fetch';
// @ts-ignore: @types/extract-files are incorrect
import extractFiles from 'extract-files/extractFiles.mjs';
import stringifyObject from 'stringify-object';
import { Ops } from '../generated/zeus/const';
import {
	chainOptions,
	fetchOptions,
	GraphQLResponse,
	Thunder,
	ZeusScalars,
} from '../generated/zeus/index';
export * from '../generated/zeus/index';

export {
	hasDefaultPermission,
	PermissionType,
	rolePermissions,
} from '../src/server/utils/permissions';
export {
	Permission,
	MemberPermission,
	EmailInvitationStatusColumn,
} from '../src/server/utils/constants/enums';

const scalars = ZeusScalars({
	NonNegativeInt: {
		decode: (e: number) => e,
	},
	DateTime: {
		decode: (e: string) => new Date(e),
	},
	UUID: {
		decode: (e: string) => e,
	},
	JSONObject: {
		encode: (e: Record<string, unknown>) =>
			stringifyObject(e, { indent: '', singleQuotes: false }),
		decode: (e: Record<string, unknown>) => e,
	},
	NonNegativeFloat: {
		decode: (e: number) => e,
	},
	Currency: {
		decode: (e: string) => e,
	},
	Duration: {
		decode: (e: string) => e,
	},
	URL: {
		decode: (e: string) => e,
	},
	SemVer: {
		decode: (e: string) => e,
	},
	SHA1HashOrUpload: {
		decode: (e: string) => e,
	},
});

const valueIsBuffer = (value: any): value is Buffer => {
	return value instanceof Buffer;
};

export class ErrorWithExtras extends Error {
	extras: { [key: string]: any };

	constructor(message: string, extras?: { [key: string]: any }) {
		super(message);
		this.extras = extras || {};
	}
}

export class ServerErrorWithExtras extends ErrorWithExtras {
	statusCode: number;
	extras: { [key: string]: any };

	constructor(
		message: string,
		statusCode: number,
		extras?: { [key: string]: any },
	) {
		super(message);
		this.statusCode = statusCode;
		this.extras = extras || {};
	}
}

const handleFetchResponse = async (
	response: Response,
): Promise<GraphQLResponse> => {
	const text = await response.text();

	if (!response.ok) {
		try {
			const json: any = JSON.parse(text);
			throw new ServerErrorWithExtras(json.message, response.status, {
				...json.details,
			});
		} catch (err) {
			throw new ServerErrorWithExtras(text, response.status);
		}
	}

	try {
		const json: any = JSON.parse(text);
		// TODO: actually check the shape of the response
		return json as GraphQLResponse;
	} catch (err) {
		throw new ErrorWithExtras('Could not parse JSON response', {
			response: text,
		});
	}
};

const apiFetch =
	(options: fetchOptions, operation: string) =>
	(query: string, variables: Record<string, unknown> = {}) => {
		const fetchOptions = options[1] || {};

		const {
			clone,
			files,
		}: { clone: Record<string, null>; files: Map<Buffer, Array<string>> } =
			extractFiles<Buffer>(variables, valueIsBuffer, 'variables');

		if (files.size) {
			const formData = new FormData();

			const map: Record<string, string[]> = {};
			let i = 0;
			let preQuery = '(';
			files.forEach(paths => {
				paths.forEach(path => {
					map[i++] = [path];
					preQuery = `${preQuery}$${path.replace(
						'variables.',
						'',
					)}: SHA1HashOrUpload!, `;
				});
			});
			preQuery = preQuery.replace(/, $/, ')');

			const operations = JSON.stringify({ query, variables: clone })
				.replace('mutation  {', `mutation ${preQuery} {`)
				.replace(/\\\"\$varObject[0-9]+\\\"/g, substring =>
					substring.replace(/\\"/g, ''),
				);

			formData.append('operations', operations);
			formData.append('map', JSON.stringify(map));
			i = 0;
			files.forEach((paths, file) => {
				paths.forEach(path => {
					formData.append(`${i++}`, new File([file], path), path);
				});
			});

			return postToResources(
				options[0].toString(),
				formData,
				fetchOptions,
				operation,
			);
		} else {
			fetchOptions.headers = {
				'Content-Type': 'application/json',
				...fetchOptions.headers,
			};

			return postToResources(
				options[0].toString(),
				JSON.stringify({ query, variables }),
				fetchOptions,
				operation,
			);
		}
	};

async function postToResources(
	url: string,
	body: any,
	fetchOptions: RequestInit,
	operation: string,
) {
	return fetch(`${url}?o=${operation}`, {
		body,
		method: 'POST',
		...fetchOptions,
	} as RequestInit)
		.then(handleFetchResponse)
		.then((response: GraphQLResponse) => {
			return response.data;
		});
}

export const SDK = (...options: chainOptions) => {
	return <O extends keyof typeof Ops>(operation: O) => {
		return Thunder(apiFetch(options, operation))(operation, {
			scalars,
		});
	};
};

let isRelativeUrlWorking: boolean = null;

/**
 * Returns the URL for the Resources API.
 *
 * @param cloud
 * @returns
 */
const getResourcesApiUrl = async (cloud?: Cloud) => {
	let url: string;

	if (cloud !== undefined) {
		url = createCloudUrl(cloud);
		if (url.indexOf('amazonaws.com') >= 0) {
			// It is an internal URL
			url += '/graphql'; // /api/vctr-resources/v1 is only required for the Gateway
		} else {
			url += '/api/vctr-resources/v1/graphql';
		}
	} else {
		url = '/api/vctr-resources/v1/graphql';
		if (
			typeof window !== 'undefined' &&
			((window && window?.location?.hostname === 'localhost') ||
				window?.location?.hostname?.indexOf('vectary.com') !== -1)
		) {
			// We don't need to do anything here; just skip the further else block.
		} else {
			if (isRelativeUrlWorking === null) {
				// First we need to check if relative URL is working.
				try {
					const result = await fetch(url, {
						headers: {
							'content-type': 'application/json',
						},
						body: '{"query":"{\\n  user {\\n    id\\n  }\\n}"}',
						method: 'POST',
						mode: 'cors',
						credentials: 'include',
					});

					if (result.status === 404) {
						throw new Error();
					}

					isRelativeUrlWorking = true;
				} catch (error) {
					isRelativeUrlWorking = false;
				}
			}
			// Don't append /api/vctr-resources/v1 to foreign URLs, use vectary prod URL instead.
			url = `${
				isRelativeUrlWorking ? '' : createCloudUrl(Env.Prod)
			}/api/vctr-resources/v1/graphql`;
		}
	}

	return url.replace(/:7\d\d\d/g, ':7200');
};

enum Env {
	Test = 'test',
	Staging = 'staging',
	Alpha = 'alpha',
	Beta = 'beta',
	Delta = 'delta',
	Gamma = 'gamma',
	Render = 'render',
	Animations = 'animations',
	WebXR = 'webxr',
	Preprod = 'preprod',
	ComW = 'comw',
	Prod = 'www',
	PrfmTest = 'prfm-test',
	Manuel = 'manuel',
}

type Cloud = Env | string;

const createCloudUrl = (cloud: string): string => {
	if (cloud.indexOf('http://localhost:') >= 0) return '';
	return Object.values(Env).includes(cloud as Env)
		? `https://${cloud}.vectary.com`
		: `${cloud}`;
};

export class ResourcesSDK {
	private _sdk: ReturnType<typeof SDK>;
	private cloud: Cloud;
	private extraHeaders: HeadersInit;
	private forceSync: boolean;

	constructor(cloud?: Cloud, extraHeaders?: HeadersInit, forceSync?: boolean) {
		this.cloud = cloud;
		this.extraHeaders = extraHeaders;
		this.forceSync = forceSync;
	}

	private async initialize() {
		if (this._sdk) {
			return;
		}

		const headers = {} as Record<string, any>;
		const url = await getResourcesApiUrl(this.cloud);

		if (this.forceSync === true) {
			headers['x-request-force-sync'] = true;
		}

		this._sdk = SDK(url, { headers: { ...headers, ...this.extraHeaders } });
	}

	public async query() {
		await this.initialize();

		return this._sdk('query');
	}

	public async mutation() {
		await this.initialize();

		return () => this._sdk('mutation');
	}

	public async subscription() {
		await this.initialize();

		return () => this._sdk('subscription');
	}
}
