import React, { ChangeEvent, FC, useState } from 'react';
import { Box, Grid, TextField, Alert, Stack, Button } from '@mui/material';
import { Info } from '@mui/icons-material';
import { H_StudioHeading, Paragraph } from '../../ui';
import { Spinner } from '../spinner';
import { BoxContentPaper, BoxContent } from '../_uko/BoxContent';

export enum WorkspaceSettingsActionsState {
	ready = 'ready',
	loading = 'loading',
}

export type WorkspaceSettingsActionsProps = {
	workspaceName: string;
	setWorkspaceName: React.Dispatch<React.SetStateAction<string>>;
	handleSelectCoverFile: () => void;
	handleSubmitWorkspaceName: () => void;
	isLeaveWorkspaceDisabled?: boolean;
	handleLeaveWorkspace: () => void;
	handleDeleteWorkspace: () => void;
	componentState: WorkspaceSettingsActionsState;
	WorkspaceImagePickerInput: React.ReactNode;
	currentUserCanManageWorkspace: boolean;
	currentUserCanDeleteWorkspace: boolean;
};

export const WorkspaceSettingsActionsUi: FC<
	WorkspaceSettingsActionsProps
> = props => {
	const {
		workspaceName,
		setWorkspaceName,
		handleSelectCoverFile,
		handleSubmitWorkspaceName,
		isLeaveWorkspaceDisabled,
		handleLeaveWorkspace,
		handleDeleteWorkspace,
		componentState,
		// setComponentState,
		WorkspaceImagePickerInput,
		currentUserCanManageWorkspace,
		currentUserCanDeleteWorkspace,
	} = props;

	const [workspaceNameForConfirmation, setWorkspaceNameForConfirmation] =
		useState<string>('');

	function onChangeWorkspaceFormSubmit(
		event: React.FormEvent<HTMLFormElement>,
	) {
		event.preventDefault();
		console.log({ event });

		const form = event.target as HTMLFormElement;
		const formData = new FormData(form);
		const value = formData.get('workspaceName') as string;

		setWorkspaceName(value);
		handleSubmitWorkspaceName();
	}

	function handleChangeWorkspaceName(
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void {
		event.preventDefault();
		event.stopPropagation();
		const value = event?.target?.value;
		setWorkspaceName(value);
	}

	function handleChangeWorkspaceNameForConfirmation(
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void {
		const value = event?.target?.value;
		setWorkspaceNameForConfirmation(value);
	}

	function handleDeleteWorkspaceClick() {
		if (workspaceNameForConfirmation !== workspaceName) return;

		handleDeleteWorkspace();
	}

	function onDeleteWorkspaceFormSubmit(
		event: React.FormEvent<HTMLFormElement>,
	) {
		event.preventDefault();
		handleDeleteWorkspaceClick();
	}

	// const handleChangeWorkspaceName: ComponentProps<
	// 	typeof TextField
	// >['onChange'] = event => {
	// 	const target = event.target;
	// 	const value: string = target?.value;

	// 	setWorkspaceName(value);
	// };

	return (
		<Box position={'relative'}>
			{currentUserCanManageWorkspace && (
				<>
					<Grid container spacing={3}>
						<Grid item xs={6} display={'flex'} flexDirection={'column'} mb={3}>
							<BoxContentPaper style={{ display: 'flex', flex: '1 0 100%' }}>
								<Box
									p={3}
									style={{ flex: '1 0 100%' }}
									display={'flex'}
									flexDirection={'column'}
									justifyContent={'space-between'}
								>
									<H_StudioHeading mb={2}>Replace cover image</H_StudioHeading>
									<Box position={'relative'}>
										<Button
											variant="contained-pale"
											onClick={() => handleSelectCoverFile()}
										>
											Upload image&hellip;
										</Button>
										{WorkspaceImagePickerInput}
									</Box>
								</Box>
							</BoxContentPaper>
						</Grid>
						<Grid item xs={6} display={'flex'} flexDirection={'column'} mb={3}>
							<BoxContent style={{ height: '100%' }}>
								<H_StudioHeading mb={2}>Rename workspace</H_StudioHeading>
								<form onSubmit={onChangeWorkspaceFormSubmit}>
									<Box mb={2}>
										<TextField
											size="medium"
											value={workspaceName}
											onChange={handleChangeWorkspaceName}
											placeholder="Enter workspace name"
											// error={errors?.length > 0}
											fullWidth
											style={{
												fontSize: 14,
											}}
										>
											{workspaceName}
										</TextField>
									</Box>
									<Button
										// color="primary-pale"
										variant="contained-pale"
										type="submit"
										onClick={() => handleSubmitWorkspaceName()}
									>
										Save changes
									</Button>
								</form>
							</BoxContent>
						</Grid>
					</Grid>
					<Box mb={3}>
						<Alert
							color="warning-grey"
							variant="filled"
							icon={<Info style={{ fontSize: '16px' }} />}
							action={
								<Stack className="btn-group" direction="row">
									<Button
										href="/business-contact-dashboard"
										target="_blank"
										size="medium"
										variant="contained-dark"
										color="primary"
									>
										Contact Us
									</Button>
								</Stack>
							}
						>
							For assistance with setting up or managing your workspace, we're
							here to support you.
						</Alert>
					</Box>
				</>
			)}

			<BoxContent mb={3}>
				<H_StudioHeading mb={2}>Leave workspace</H_StudioHeading>
				<Paragraph mb={2}>
					By leaving, you will lose access to all projects in this shared
					workspace, including any privately shared links.
				</Paragraph>
				<Button
					variant="outlined"
					color="error"
					onClick={
						isLeaveWorkspaceDisabled ? null : () => handleLeaveWorkspace()
					}
					disabled={isLeaveWorkspaceDisabled}
				>
					Leave workspace
				</Button>
			</BoxContent>

			{currentUserCanDeleteWorkspace && (
				<BoxContent mb={3}>
					<H_StudioHeading mb={2}>Delete workspace</H_StudioHeading>
					<Paragraph mb={2}>
						This action will remove all projects and shared links within the
						workspace, and it cannot be undone. Make sure to back up any
						important data before proceeding. To delete the workspace please
						type in the name of the workspace below.
					</Paragraph>
					<form onSubmit={onDeleteWorkspaceFormSubmit}>
						<TextField
							size="medium"
							value={workspaceNameForConfirmation}
							onChange={handleChangeWorkspaceNameForConfirmation}
							placeholder="Enter your workspace name"
							// error={errors?.length > 0}
							fullWidth
							style={{
								fontSize: 14,
								marginBottom: 10,
								maxWidth: 320,
								display: 'block',
							}}
						></TextField>
						<Button
							variant="outlined"
							color="error"
							type="submit"
							onClick={() => handleDeleteWorkspaceClick()}
						>
							Delete workspace
						</Button>
					</form>
				</BoxContent>
			)}

			{componentState === WorkspaceSettingsActionsState.loading && <Spinner />}
		</Box>
	);
};
