import * as fflate from 'fflate';
import JSZip from 'jszip';
import pako from 'pako';

import { NamedBlob } from './blobUtils';
import { FileData } from './file';
import { getExtensionFromFileName } from './string';

/* ------------------------------------------------------
----------------------- UNIFIED -------------------------
-------------------------------------------------------*/

export function isAZip(files: NamedBlob[]): boolean {
	if (files.length !== 1) {
		return false;
	}
	return 'zip' === getExtensionFromFileName(files[0].name);
}

const use_fflate = true;

export async function unifiedZipFilesPromise(
	files: NamedBlob[],
): Promise<Blob> {
	return use_fflate || typeof window === 'undefined'
		? zipFilesPromise(files)
		: new Blob([await getZippedFilesPromise(files)]);
}

export function unifiedZipFiles(
	files: NamedBlob[],
	zippedCallback: (content: Blob) => void,
): void {
	return use_fflate
		? zipFiles(files, zippedCallback)
		: getZippedFiles(files, zippedCallback);
}

// NOT STABLE BECAUSE OF THE COMPRESSION IN VCTR-REPO
export async function unifiedUnzipFile(zipFile: Blob): Promise<NamedBlob[]> {
	return /* use_fflate ? await unzipFile(zipFile) : */ await loadZip(zipFile);
}

export function unifiedZipFileBuffers(
	files: (Uint8Array | ArrayBuffer)[],
	level: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 = 6,
): Uint8Array {
	return use_fflate
		? zipFileBuffers(files, level)
		: getZippedFilesPako(files, level);
}

export function unifiedUnzipBuffer(
	compressed: ArrayBuffer | Uint8Array,
): ArrayBufferLike {
	return use_fflate ? unzipBuffer(compressed).buffer : loadZipPako(compressed);
}

export function unifiedZlibSync(
	data: Uint8Array | ArrayBuffer,
	level: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 = 6,
): Uint8Array {
	if (use_fflate) {
		const deflatedData = fflate.zlibSync(new Uint8Array(data), {
			level: level,
		});
		return deflatedData;
	} else {
		let d = new pako.Deflate({ level: 6 });
		d.push(data, true);
		return d.result as Uint8Array;
	}
}

/* ------------------------------------------------------
------------------------ FFLATE -------------------------
-------------------------------------------------------*/

const ALREADY_COMPRESSED = new Set([
	'zip',
	'gz',
	'png',
	'jpg',
	'jpeg',
	'pdf',
	'doc',
	'docx',
	'ppt',
	'pptx',
	'xls',
	'xlsx',
	'heic',
	'heif',
	'7z',
	'bz2',
	'rar',
	'gif',
	'webp',
	'webm',
	'mp4',
	'mov',
	'mp3',
	'aifc',
	// Add whatever extensions you don't want to compress
]);
const LARGER_FILE_SIZE = 500000;

// Change/duplicate this function to work with fs/e
const readFile = (file: Blob) => {
	return new Promise<Uint8Array>(async (resolve, reject) => {
		try {
			const reader = new FileReader();

			reader.onloadend = () => {
				resolve(new Uint8Array(reader.result as ArrayBuffer));
			};
			reader.onerror = () => {
				console.error('ERROR READING FILE');
				reject();
			};
			reader.readAsArrayBuffer(file);
		} catch (e) {
			const arrayBuffer = await file.arrayBuffer();
			resolve(new Uint8Array(arrayBuffer));
		}
	});
};

async function zipFilesPromise(files: NamedBlob[]): Promise<Blob> {
	const archiveStruct: fflate.AsyncZippable = {};
	for (const file of files) {
		const fileBuffer = await readFile(file.blob);
		archiveStruct[file.name] = fileBuffer;
	}

	return new Promise<Blob>(async (resolve, reject) => {
		fflate.zip(
			archiveStruct,
			{ consume: false, level: 6, mtime: new Date('2024-01-01') },
			(err, out) => {
				if (err) {
					console.error('ERROR WHILE ZIPPING:', err);
					reject();
				}
				const blob = new Blob([out]);
				resolve(blob);
			},
		);
	});

	return new Promise<Blob>(async (resolve, reject) => {
		try {
			const zipped: Uint8Array[] = [];
			const zip = new fflate.Zip();
			zip.ondata = (err, data, final) => {
				if (err) {
					throw err;
				}
				zipped.push(data);
				if (final) {
					const blob = new Blob(zipped);
					resolve(blob);
				}
			};

			for (const file of files) {
				const ext = file.name.slice(file.name.lastIndexOf('.') + 1);
				const zippedFileStream = ALREADY_COMPRESSED.has(ext)
					? new fflate.ZipPassThrough(file.name)
					: file.blob.size > LARGER_FILE_SIZE
					? new fflate.AsyncZipDeflate(file.name, { level: 6 })
					: new fflate.ZipDeflate(file.name, { level: 6 });

				zip.add(zippedFileStream);

				const arrayBuffer = await file.blob.arrayBuffer();
				// We are pushing complete files, so final is always true
				zippedFileStream.push(new Uint8Array(arrayBuffer), true);
			}

			zip.end();
		} catch (e) {
			console.error(`Error while unzipping: ${e}`);
			reject();
		}
	});
}

export function zipNamedBuffersSync(
	namedBuffers: [Uint8Array, string][],
): Blob {
	const archiveStruct: fflate.Zippable = {};
	for (const [buffer, name] of namedBuffers) {
		archiveStruct[name] = buffer;
	}

	return new Blob([fflate.zipSync(archiveStruct, { level: 6 })], {
		type: 'application/zip',
	});
}

export function zipFiles(
	files: NamedBlob[],
	zippedCallback: (content: Blob) => void,
): void {
	zipFilesPromise(files).then((content: Blob) => {
		zippedCallback(content);
	});
}

export async function unzipFile(zippedFile: Blob): Promise<NamedBlob[]> {
	return new Promise<NamedBlob[]>(async (resolve, reject) => {
		try {
			let counter = 0;
			let interval: any;

			const unzipped: NamedBlob[] = [];
			const unzip = new fflate.Unzip();
			unzip.register(fflate.UnzipInflate);
			unzip.onfile = file => {
				counter++;
				clearInterval(interval);

				file.ondata = (_err, data, final) => {
					if (_err) {
						console.error('ERROR ON DATA:', file.name, _err);
					}
					const blob: Blob = new Blob([data]);

					// if (final) {
					const namedBlob = new NamedBlob(blob, file.name);
					unzipped.push(namedBlob);

					// We have no direct way of asking if all files have been processed
					counter--;
					clearInterval(interval);
					interval = setInterval(() => {
						if (counter === 0) {
							clearInterval(interval);
							resolve(unzipped);
						}
					}, 100);
					// }
				};
				if (!file.name.startsWith('__MACOSX')) {
					file.start();
				}
			};

			const arrayBuffer = await zippedFile.arrayBuffer();
			unzip.push(new Uint8Array(arrayBuffer));
		} catch (e) {
			console.error(`Error while unzipping: ${e}`);
			reject();
		}
	});
}

export function zipFileBuffers(
	files: (Uint8Array | ArrayBuffer)[],
	level: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 = 6,
): Uint8Array {
	let result: Uint8Array;
	const resultChunks: Uint8Array[] = [];

	// for (const file of files) {
	//     resultChunks.push(fflate.zlibSync(new Uint8Array(file), { level: level }));
	// }

	// result = concatUint8Arrays(resultChunks);

	// const deflateStream = new fflate.Zlib({ level: level });
	const deflateStream = new fflate.Deflate({ level: level });
	deflateStream.ondata = (chunk, final) => {
		resultChunks.push(chunk);
		if (final) {
			result = concatUint8Arrays(resultChunks);
		}
	};

	for (let i = 0; i < files.length - 1; i++) {
		deflateStream.push(new Uint8Array(files[i]), false);
	}
	deflateStream.push(new Uint8Array(files[files.length - 1]), true);

	while (true) {
		if (result) break;
	}

	return result;
}

export function unzipBuffer(zip: Uint8Array | ArrayBuffer): Uint8Array {
	let result: Uint8Array;

	result = fflate.decompressSync(new Uint8Array(zip));

	// const resultChunks: Uint8Array[] = [];

	// const inflateStream = new fflate.Decompress();
	// inflateStream.ondata = (chunk, final) => {
	//     console.log("UZBF ONDATA:", chunk, final);
	//     resultChunks.push(chunk);
	//     // if (final) {
	//     result = concatUint8Arrays(resultChunks);
	//     // }
	// };

	// const uInt = new Uint8Array(zip);
	// inflateStream.push(uInt);

	// while (true) {
	//     if (result) break;
	// }

	return result;
}

const concatUint8Arrays = (arrays: Uint8Array[]): Uint8Array => {
	const totalLength = arrays.reduce((acc, value) => acc + value.length, 0);
	if (!arrays.length) return new Uint8Array(0);

	const mergedArray = new Uint8Array(totalLength);
	let offset = 0;
	for (const array of arrays) {
		mergedArray.set(array, offset);
		offset += array.length;
	}
	return mergedArray;
};

/* ------------------------------------------------------
--------------------- JSZIP & PAKO ----------------------
-------------------------------------------------------*/

async function getZippedFilesPromise(files: NamedBlob[]): Promise<Uint8Array> {
	let zip = new JSZip();
	for (let i = 0; i < files.length; i++) {
		zip.file(files[i].name, await files[i].blob.arrayBuffer(), {
			// Use always the same date so the final ZIP is always the same.
			date: new Date('2024-01-01'),
		});
	}

	const uInt8 = await zip.generateAsync({
		type: 'uint8array',
		compression: 'DEFLATE',
		compressionOptions: {
			level: 6,
		},
	});

	return uInt8;
}

function getZippedFiles(
	files: NamedBlob[],
	zippedCallback: (content: Blob) => void,
): void {
	getZippedFilesPromise(files).then((content: Uint8Array) => {
		zippedCallback(new Blob([content]));
	});
}

async function loadZip(zippedFile: Blob): Promise<NamedBlob[]> {
	let jsZip = new JSZip();

	const arrayBuffer = await zippedFile.arrayBuffer();

	const result: any = await jsZip.loadAsync(arrayBuffer);
	const zippedFiles = (Object as any).values(result.files);

	const filePromises: Promise<NamedBlob>[] = zippedFiles.map(
		async (zf: any, i: number) => {
			const blob: Blob = await zf.async('blob');
			const namedBlob = new NamedBlob(blob, zf.name);

			return namedBlob;
		},
	);

	let files = await Promise.all(filePromises);
	// filter out files from "__MACOSX" folder, which is a magic metadata folder added to zip on mac devices...
	files = files.filter(file => !file.name.startsWith('__MACOSX'));

	return files;
}

function getZippedFilesPako(
	files: (Uint8Array | ArrayBuffer)[],
	level: -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | undefined = 6,
): Uint8Array {
	let pakoDeflate = new pako.Deflate({ level: level });

	for (let i = 0; i < files.length - 1; i++) {
		pakoDeflate.push(files[i], false);
	}

	pakoDeflate.push(files[files.length - 1], true);

	if (pakoDeflate.err) {
		throw new Error(pakoDeflate.err.toString());
	}

	return pakoDeflate.result as Uint8Array;
}

function loadZipPako(zip: Uint8Array | ArrayBuffer | string): ArrayBuffer {
	let pakoInflate = new pako.Inflate();
	pakoInflate.push(zip, true);

	if (pakoInflate.err) {
		throw new Error(pakoInflate.err.toString());
	}

	if (pakoInflate.result instanceof Uint8Array) {
		return pakoInflate.result.buffer;
	} else {
		throw new Error('pako should always unzip to Uint8Array here');
	}
}

export async function createHDRZip(fileData: FileData): Promise<Blob> {
	const hdr = new NamedBlob(
		fileData.file,
		fileData.name + '.' + fileData.extension,
	);
	return await unifiedZipFilesPromise([hdr]);
}
