import {
	vctrFetch,
	Cloud,
	fetchRequest,
	fetchResult,
} from '@vctr-libs/vctr-utils';
import { ClientApi } from '../clientApi';
import { GetLogQuery, LogRow } from './interface';

const API_URL = '/api/vctr-log/v1';

export const sendPageView = async (
	source: string,
	userId: string,
	info: any,
	cloud?: Cloud,
): Promise<void> =>
	vctrFetch(
		`${API_URL}/log/pv/${userId}/${source}?event=pv`,
		fetchRequest('POST', JSON.stringify(info)),
		cloud,
	)
		.then(r => fetchResult<void>(r))
		.catch(e => {
			console.info('Error posting the event view page!', e);
		});

export const sendPageEvent = async (
	source: string,
	userId: string,
	event: string,
	info: any,
	cloud?: Cloud,
): Promise<void> =>
	vctrFetch(
		`${API_URL}/log/pa/${userId}/${source}?event=${event}`,
		fetchRequest('POST', JSON.stringify(info)),
		cloud,
	)
		.then(r => fetchResult<void>(r))
		.catch(e => {
			console.info('Error posting the engine event!', e);
		});

export const getLogs = async (
	query: GetLogQuery,
	cloud?: Cloud,
): Promise<LogRow[]> =>
	vctrFetch(
		`${API_URL}/logs?uuid=${query.uuid}&event=${query.event}&limit=${
			query.limit || 5
		}`,
		fetchRequest(),
		cloud,
	)
		.then(r => fetchResult<LogRow[]>(r))
		.catch(e => {
			throw `Error getting the logs!\n${e}`;
		});

export class LogApi extends ClientApi {
	async sendPageView(source: string, userId: string, info: any) {
		return sendPageView(source, userId, info, this.cloud);
	}

	async sendPageEvent(
		source: string,
		userId: string,
		event: string,
		info: any,
	) {
		return sendPageEvent(source, userId, event, info, this.cloud);
	}

	async getLogs(query: GetLogQuery) {
		return getLogs(query, this.cloud);
	}
}
