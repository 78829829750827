import React, { FC } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Reset } from 'styled-reset';
import { Theme } from './theme';

/** Resets 62.5% font size on html — legacy approach that gets into the way now */

export const GlobalStylerFix: FC = ({ children }) => (
	<>
		<Reset />
		<_GlobalStyleFix />
		{children}
	</>
);
const _GlobalStyleFix = createGlobalStyle<{ theme: Theme }>` 
    html, body, #root {
    font-size: 
        100%;
    }
`;
