import React, { ComponentProps, FC, useEffect, useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';
// import { Clear } from '@mui/icons-material';
import {
	InvitationPermission,
	EmailInvitation,
	EmailInvitationWithPossibleError,
	WorkspaceUserSeat,
	Permission,
} from '@vctr-libs/vctr-api-client';
import { Spinner } from '..';
import { getEmailsFromString } from './getEmailsFromString';
import { EmailInputError, validateEmails } from './validateEmails';
import { MemberRoleSelect } from './MemberRoleSelect';
import { usePermissionsSelectHelper } from './usePermissionsSelectHelper';

export const NO_EMAILS_MESSAGE = 'No emails to send';
export const INCORRECT_EMAILS_MESSAGE = 'Incorrect emails';
export const EMAIL_IN_USE_MESSAGE = 'Email is already in use';
const defaultInvitationPermission = InvitationPermission.VIEW_2024;

export type AddUserInvitation = {
	email: string;
	permission: InvitationPermission;
};

export type AddUserModalProps = {
	handleSubmit: (invitationsToSend: AddUserInvitation) => Promise<void>;
	users: WorkspaceUserSeat[];
	emailInvitations: EmailInvitation[] | EmailInvitationWithPossibleError[];
	isDisabledPermission: (p: Permission) => boolean;
	isHiddenPermission: (p: Permission) => boolean;
	availableInvitationPermissions: InvitationPermission[];
};

export const AddUserModal: FC<AddUserModalProps> = props => {
	const {
		handleSubmit,
		users,
		emailInvitations,
		availableInvitationPermissions,
		isDisabledPermission,
		isHiddenPermission,
	} = props;

	const [inputValue, setInputValue] = useState<string>('');
	const [emailsToInvite, setEmailsToInvite] = useState<string[]>([]);
	const [invitePermission, setInvitePermission] =
		useState<InvitationPermission>(defaultInvitationPermission);
	const [errors, setErrors] = useState<EmailInputError[]>([]);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { enabledPermissions } = usePermissionsSelectHelper({
		permissions: availableInvitationPermissions,
		isDisabledPermission,
		isHiddenPermission,
	});

	useEffect(() => {
		if (
			emailsToInvite?.length > 0 &&
			validateEmails({
				emails: emailsToInvite,
				users,
				emailInvitations,
				onError: errors => setErrors(errors),
			})
		) {
			setErrors([]);
		}
		return () => {
			setErrors([]);
		};
	}, [emailsToInvite]);

	useEffect(() => {
		const hasErrors = errors?.length > 0;
		const emptyInputValue = inputValue?.length === 0;
		const isDisabled = hasErrors && emptyInputValue;
		setIsSubmitDisabled(isDisabled);
		return () => {
			setIsSubmitDisabled(true);
		};
	}, [emailsToInvite, errors, inputValue]);

	useEffect(() => {
		return () => {
			clearData();
		};
	}, []);

	// const allowedInvitationPermissions: InvitationPermission[] = Object.values(
	// 	InvitationPermission,
	// ).filter(p => !isDisabledPermission(p));

	function clearData() {
		setErrors([]);
		setInvitePermission(defaultInvitationPermission);
		setEmailsToInvite([]);
		setInputValue('');
	}

	async function handleSubmitInvitations() {
		const invitationToSend: AddUserInvitation = {
			email: inputValue,
			permission: invitePermission,
		};
		setInputValue('');

		try {
			setIsLoading(true);
			await handleSubmit(invitationToSend);
			clearData();
		} catch (error) {
			console.log(error);
			// const _errors: Array<EmailInputError> = [
			// 	...errors,
			// 	{
			// 		message: "Couldn't send invitations. Please try again",
			// 		value: null,
			// 	},
			// ];
			// setErrors(_errors);
		} finally {
			setIsLoading(false);
		}
	}

	const handleInputChange: ComponentProps<
		typeof TextField
	>['onChange'] = event => {
		const target = event.target;
		const value: string = target?.value;
		// setInputValue(value);

		const emails: string[] = getEmailsFromString(value);

		if (emails.length > 1) {
			setEmailsToInvite([...emailsToInvite, emails[0]]);
			setInputValue('');
		} else {
			setInputValue(value);
		}
		// setInputElementValue(emails[0]);

		if (!value || value === '') {
			target.setAttribute('value', '');
		}
	};

	function handleChangePermission(value: InvitationPermission) {
		// const value = event.target?.value as InvitationPermission;
		// if (!value || !Object.values(InvitationPermission).includes(value)) {
		if (!value) {
			return;
		}
		setInvitePermission(value);
	}

	return (
		<>
			<Grid container>
				<Grid item xs={6} pr={2}>
					{/* <AddUserAutocomplete
						{...{
							users,
							emailInvitations,
							emailsToInvite,
							setEmailsToInvite,
							inputValue,
							setInputValue,
							handleInputChange,
							handleInputKeydown,
							handlePaste,
							errors,
							setErrors,
						}}
					/> */}

					<TextField
						name="email"
						type="email"
						size="medium"
						value={inputValue}
						onChange={handleInputChange}
						placeholder="Invite by email"
						// error={errors?.length > 0}
						fullWidth
						style={{
							fontSize: 14,
						}}
						// helperText={
						// 	<>
						// 		{errors?.map(e => (
						// 			<>
						// 				{e.message}
						// 				<br />
						// 			</>
						// 		))}
						// 	</>
						// }
					>
						{inputValue}
					</TextField>
				</Grid>
				<Grid item xs={4} pr={2}>
					<MemberRoleSelect
						permissions={availableInvitationPermissions}
						selectedPermission={invitePermission}
						handleChange={p =>
							handleChangePermission(p as InvitationPermission)
						}
						isDisabledPermission={isDisabledPermission}
						isHiddenPermission={isHiddenPermission}
						width="full"
					/>
				</Grid>
				<Grid
					item
					xs={2}
					display={'flex'}
					flexDirection={'row'}
					alignContent={'center'}
					alignItems={'center'}
				>
					<Button
						size="large"
						fullWidth
						variant="contained"
						onClick={handleSubmitInvitations}
						// disabled={isSubmitDisabled || errors?.length > 0}
						disabled={
							isSubmitDisabled || isLoading || enabledPermissions.length === 0
						}
					>
						Invite
					</Button>
				</Grid>
			</Grid>

			{/* <ButtonWrapper>
						<Button
							variant={ButtonEnumVariant.DEFAULT}
							onClick={() => setShowAddUserModal(false)}
						>
							Close
						</Button>
					</ButtonWrapper> */}
			{isLoading && <Spinner />}
		</>
	);
};
