import React from 'react';
import {
	EmailInvitation,
	EmailInvitationWithPossibleError,
} from '@vctr-libs/vctr-api-client';
import {
	MemberTableItemName,
	MemberTableItemImage,
	MembersTable__Item__NameBlock,
} from './membersTable/styledComponents';
import { defaultUserPicture } from './defaultUserPicture';

export function MemberTable__Item__EmailInvitation(
	props: Pick<EmailInvitation | EmailInvitationWithPossibleError, 'email'>,
) {
	const { email } = props;
	return (
		<MembersTable__Item__NameBlock>
			<MemberTableItemImage src={defaultUserPicture} />
			<MemberTableItemName>
				{/* <Paragraph>{email}</Paragraph> */}
				{email}
			</MemberTableItemName>
		</MembersTable__Item__NameBlock>
	);
}
