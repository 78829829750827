import { init as initApm } from "@elastic/apm-rum";


export function initElkApm() {
    initApm({
        // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
        serviceName: "vectary-website",
        // Set custom APM Server URL (default: http://localhost:8200)
        serverUrl: "https://elk.vectary.com:18200/",
        // Set the service version (required for source map feature)
        serviceVersion: '',
        environment: process.env.ENV
    });
}
