import { UserBase } from '../../user/interface';
import { Plan } from '../../plan/interface';

import { Env } from '@vctr-libs/vctr-utils';
import { Access, ModelBase } from '../../repo/interface';
import {
	MemberPermissionEnum,
	PermissionEnum,
	PermissionType,
} from '@vctr-api/vctr-resources/sdk';
import { Permission } from './permissionRoles';

export enum Visibility {
	PUBLIC = 'public',
	PRIVATE = 'private',
}

export interface WorkspacePlan extends Plan {}

/**
 * Representation of the settings of a workspace.
 */
export type WorkspaceBase = {
	uuid: string;
	name: string;
	owner_id: string;
	picture?: string;
	root_name: string;
	visibility: Visibility;
	plan?: WorkspacePlan;
	customDomain?: string;
	rolePermissions?: PermissionType[];
};

export interface CreateWorkspaceRequest {
	name: string;
	owner?: string;
	picture?: string;
}

export interface UpdateWorkspaceRequest {
	name?: string;
	owner?: string;
	picture?: string;
	// TODO per graphql errors, it should be a number
	permissionsWorkspaceId?: string | number;
	root_name?: string;
	customDomainId?: string;
}
/**
 * Representation of a folder of a workspace.
 */
export interface WorkspaceFolder {
	id?: string;
	name?: string;
	models?: string[];
	isHomeFolder?: boolean;
}

export interface WorkspaceFolderRequest {
	name: string;
	models: string[];
}

export interface PlanBase {
	id: string;
}

/**
 * Representation of the settings of a seat.
 */
export interface WorkspaceSeat {
	permission: Permission;
	user_id: string;
	workspace_id?: string;
}

/**
 * Representation of the email invitation.
 */
export interface EmailInvitation {
	email: string;
	id: string;
	permission: MemberPermissionEnum;
	// TODO status should be EmailInvitationStatusEnum from vctr-resources
	status: string;
	workspaceId: string;
	createdAt: string;
}

export interface EmailInvitationWithPossibleError extends EmailInvitation {
	error?: {
		message: string;
		status: number;
		type: string;
	};
}

/**
 * Representation of a link–based invitation of workspace (one link — one seat, will be archived in Aug–Sep 2024)
 */
export interface WorkspaceInvitation {
	uuid: string;
	permission: Permission;
}

export interface GetWorkspaceResponse
	extends Record<PermissionEnum, WorkspaceBase[]> {}

export interface WorkspaceUserSeat extends UserBase, WorkspaceSeat {}

export interface WorkspacePayment {
	id: number;
	receipt_url: string;
}

export interface GetWorkspaceParams {
	includeDeleted: boolean;
}

export interface ModelBaseDashboard extends ModelBase {
	inactive: boolean;
	publicAccess: Access;
	vctrEnvironment?: Env;
	hasLibraryItem?: boolean;
}

// TODO: Check if the following is still needed.
export interface WorkspaceBaseDashboard extends WorkspaceBase {
	currentUserRole: Permission;
	folders: WorkspaceFolderWithOrder[];
	invitations: WorkspaceInvitation[];
	emailInvitations: EmailInvitationWithPossibleError[];
	numberOfProjects: number;
	projects: ModelBaseDashboard[];
	projectsWereLoaded: boolean;
	users: WorkspaceUserSeat[];
	usersWereLoaded: boolean;
	ordering: number;
}

export interface WorkspaceFolderWithOrder
	extends Omit<WorkspaceFolder, 'models'> {
	ordering?: number;
}

export type CreateEmailInvitationItem = {
	email: string;
	permission: Permission;
	workspaceId: string;
};
